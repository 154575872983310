import { STATUS } from "../../../../constants/server/api";
import { employeeHandler } from "../../../../redux/handlers";

export const SubmitForm = async (dispatch, empID, businessSlug, values) => {
  try {
    let result = null;
    if (empID) {
      delete values.email;
      delete values.password;
      delete values.empPin;
    }
    delete values.emp_work_is_all_branch;
    let formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (values[key] !== undefined) {
        formData.append(key, values[key] ?? "");
      }
    });
    if (empID) {
      result = await dispatch(employeeHandler.updateEmployee(empID, formData));
    } else {
      result = await dispatch(
        employeeHandler.createEmployee(businessSlug, formData)
      );
    }
    if (result == STATUS.OK) {
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};
