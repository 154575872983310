import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import ConfirmDeleteModal from "./components/ConfirmDeleteModal";
import SuccessDeleteModal from "./components/SuccessDeleteModal";
import { branchEmpHandler } from "../../../redux/handlers";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, FormLabel, FormControl, FormText } from "react-bootstrap";
export function EmpManage() {
  //Hook setup
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { branchID, permissionID } = useParams();
  //States setup
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [modalsuccessShow, setModalsuccessShow] = useState(false);
  const [formdisabled, setFormdisabled] = useState(false);
  const [selectValidate, setSelectValidate] = useState(true);
  const [pdata, setPdata] = useState({
    id: "",
    code: "",
    first_name: "",
    last_name: "",
    email: "",
    permission: "",
    usePin: "",
    business_group_id: "",
  });
  // Redux selector
  const employeeData = useSelector((state) => state.branchEmp?.getById);
  const roleData = useSelector((state) => state.branchEmp?.dataRole);
  const empdata = useSelector((state) => state.branchEmp?.allEmployees);
  // Setup Options
  const optionsRole = Array.isArray(roleData)
    ? roleData.map((role) => ({
        value: role.id,
        label: role.name,
      }))
    : [];
  // Yup schema
  const schema = yup.object().shape({
    code: yup.string().required("กรุณาระบุรหัสพนักงาน"),
    first_name: yup.string().required("กรุณาระบุชื่อ"),
    email: yup.string().required("กรุณาระบุอีเมล"),
  });
  // useEffect
  useEffect(() => {
    dispatch(branchEmpHandler.getBranchRole);
  }, [dispatch]);

  useEffect(() => {
    dispatch(branchEmpHandler.allEmployees(branchID));
  }, [dispatch, branchID]);

  useEffect(() => {
    if (permissionID) {
      dispatch(branchEmpHandler.getEmployee(permissionID));
    }
  }, [dispatch, permissionID]);

  useEffect(() => {
    if (employeeData && permissionID) {
      if (employeeData.is_add_business) {
        navigate(-1);
      } else {
        setPdata((prev) => ({
          ...prev,
          id: employeeData?.id,
          code: employeeData.userBusinessGroup?.code,
          first_name:
            employeeData?.userBusinessGroup?.first_name +
            " " +
            employeeData?.userBusinessGroup?.last_name,
          email: employeeData?.userBusinessGroup?.email,
          business_group_id: employeeData?.user_business_group_id,
          permission: employeeData?.branch_role_id,
        }));
      }
    }
  }, [employeeData]);
  //Functions
  const updateEmployeeRole = async (data) => {
    try {
      dispatch(branchEmpHandler.updateEmployeeRole(permissionID, data));
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  };
  const createEmployeeRole = async (data) => {
    try {
      dispatch(branchEmpHandler.createEmployeeRole(data));
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmDelete = () => {
    try {
      dispatch(branchEmpHandler.deleteEmployeeRole(permissionID));
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnSelect = (item) => {
    setPdata((prev) => ({
      ...prev,
      id: item.id,
      code: item.code,
      first_name: item.first_name + " " + item.last_name,
      email: item.email,
      business_group_id: item.business_group_id,
    }));
  };

  const formatResult = (item) => {
    return (
      <div className="d-flex align-items-center" key={item.code}>
        <div
          className="me-2 fs-14"
          style={{ display: "block", color: "#2DC76D" }}
        >
          {item.code}
        </div>
        <div>
          <p>{`${item.first_name} ${item.last_name}`}</p>
        </div>
      </div>
    );
  };

  return (
    <div style={{ padding: "30px 33px 33px 31px" }}>
      <div className="card card-st-2 mb-4">
        <div className="card-body px-xl-4 py-4">
          <Formik
            enableReinitialize
            initialValues={pdata}
            validationSchema={schema}
            onSubmit={async (values) => {
              if (values.permission != "") {
                let updateForm = {
                  branchId: branchID,
                  userBusinessGroupId: values.id,
                  branchRoleId: values.permission,
                };
                if (permissionID !== undefined) {
                  await updateEmployeeRole(updateForm);
                } else {
                  await createEmployeeRole(updateForm);
                }
              } else {
                setSelectValidate(false);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
              isInvalid,
              isValid,
            }) => (
              <Form
                className=" "
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="d-flex align-items-center  mb-4 ">
                  <div
                    className="fs-18 fw-bold me-auto"
                    style={{ color: "#2DC76D" }}
                  >
                    {" "}
                    {permissionID ? "แก้ไขข้อมูลพนักงาน" : "ดึงข้อมูลพนักงาน"}
                  </div>
                </div>
                <div className="">
                  <div className="row">
                    <div className="col-12">
                      <fieldset disabled={formdisabled}>
                        <div className="row">
                          <div className="col-xl-4">
                            <Form.Group className="mb-3 form-st-3 pb-1">
                              <Form.Label
                                className="mb-1"
                                style={{ zIndex: "12" }}
                              >
                                ค้นหาพนักงาน{" "}
                              </Form.Label>
                              <ReactSearchAutocomplete
                                items={empdata}
                                placeholder="ค้นหาพนักงาน"
                                onSelect={handleOnSelect}
                                autoFocus
                                formatResult={formatResult}
                                fuseOptions={{
                                  keys: ["id", "code", "first_name"],
                                }}
                                resultStringKeyName="first_name"
                              />
                            </Form.Group>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-4">
                            <FormGroup className="mb-3 form-st-3 pb-1">
                              <FormLabel className="mb-1">
                                รหัสพนักงาน 123{" "}
                                <span className="text-danger">*</span>
                              </FormLabel>
                              <FormControl
                                type="text"
                                name="code"
                                className="form-control"
                                placeholder="รหัสพนักงาน"
                                value={values.code}
                                onChange={handleChange}
                                isInvalid={!!errors.code && touched.code}
                                disabled={true}
                              />
                              <FormControl.Feedback type="invalid">
                                {errors.code}
                              </FormControl.Feedback>
                            </FormGroup>
                          </div>
                          <div className="col-xl-4">
                            <Form.Group className="mb-3 form-st-3 pb-1  ">
                              <Form.Label className="mb-1">
                                ชื่อพนักงาน
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="first_name"
                                className="form-control"
                                placeholder="ชื่อพนักงาน"
                                value={values.first_name}
                                onChange={handleChange}
                                isInvalid={!!errors.first_name}
                                disabled={true}
                              />
                              <FormControl.Feedback type="invalid">
                                {errors.first_name}
                              </FormControl.Feedback>
                            </Form.Group>
                          </div>
                          <div className="col-xl-4">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">E-mail </Form.Label>
                              <Form.Control
                                type="text"
                                name="email"
                                className="form-control"
                                placeholder="E-mail "
                                value={values.email}
                                onChange={handleChange}
                                isInvalid={!!errors.email}
                                disabled={true}
                              />
                              <FormControl.Feedback type="invalid">
                                {errors.email}
                              </FormControl.Feedback>
                            </Form.Group>
                          </div>
                        </div>

                        <div className="card card-st-1 mt-4 mb-4">
                          <div className="card-body px-0">
                            <div
                              className="fs-18 px-3 fw-bold me-auto mb-3"
                              style={{ color: "#2DC76D" }}
                            >
                              สิทธิการเข้าถึง
                            </div>
                            <table className="table table-st-1">
                              <thead>
                                <tr>
                                  <th>ตำแหน่ง</th>
                                  <th className="text-center">ครัว</th>
                                  <th className="text-center">แคชเชียร์</th>
                                  <th className="text-center">พนักงานเสริฟ</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>POS</td>
                                  <td>
                                    <div className="icon-p2"></div>
                                  </td>
                                  <td>
                                    <div className="icon-p1"></div>
                                  </td>
                                  <td>
                                    <div className="icon-p1"></div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>หน้าจอครัว</td>
                                  <td>
                                    <div className="icon-p1"></div>
                                  </td>
                                  <td>
                                    <div className="icon-p2"></div>
                                  </td>
                                  <td>
                                    <div className="icon-p1"></div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Mobile</td>
                                  <td>
                                    <div className="icon-p1"></div>
                                  </td>
                                  <td>
                                    <div className="icon-p1"></div>
                                  </td>
                                  <td>
                                    <div className="icon-p2"></div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-4">
                            <Form.Group className="mb-3 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                สิทธิผู้ใช้งานระดับสาขา
                              </Form.Label>
                              <Select
                                as="select"
                                name="permission"
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "permission",
                                    selectedOption ? selectedOption.value : null
                                  );
                                  setSelectValidate(true);
                                }}
                                className={`w-100 select-st-1 ${
                                  selectValidate == true
                                    ? ""
                                    : "border-red-500 border-1 rounded-lg"
                                }`}
                                options={optionsRole}
                                placeholder="ค้นหาสิทธิผู้ใช้งาน"
                                value={
                                  optionsRole.find(
                                    (item) => item.value === values.permission
                                  ) || null
                                }
                              />
                              <p className="text-red-500 text-sm mt-[4px]">{`${
                                selectValidate == true
                                  ? ""
                                  : "กรุณาระบุสิทธิผู้ใช้งาน"
                              }`}</p>
                            </Form.Group>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>

                <div className="text-end pb-4 pt-4 pe-3">
                  {formdisabled === true ? (
                    <Button
                      type="button"
                      className="btn-st-3 mx-3"
                      onClick={(e) => {
                        setFormdisabled(false);
                      }}
                    >
                      แก้ไข
                    </Button>
                  ) : (
                    <div className=" pt-3 d-flex btn-group-control w-100">
                      {permissionID ? (
                        <Button
                          type="button"
                          className="btn-st-5 me-auto "
                          onClick={() => setModalDeleteShow(true)}
                        >
                          นำออกจากข้อมูล
                        </Button>
                      ) : (
                        <div className="me-auto"></div>
                      )}

                      <Button
                        onClick={() => navigate(-1)}
                        className="btn-st-6 me-3 btn text-decoration-none "
                      >
                        ยกเลิก
                      </Button>
                      <Button type="submit" className="btn-st-3 ">
                        บันทึก
                      </Button>
                    </div>
                  )}
                </div>

                <ConfirmDeleteModal
                  show={modalDeleteShow}
                  onHide={() => setModalDeleteShow(false)}
                  onConfirm={() => setModalDeleteShow(false)}
                  onSuccess={() => {
                    setModalsuccessShow(true);
                    setDeleteStatus(true);
                    handleConfirmDelete();
                    navigate(-1);
                  }}
                  pdata={pdata}
                />
                <SuccessDeleteModal
                  show={modalsuccessShow}
                  onHide={() => setModalsuccessShow(false)}
                  modalObj={{
                    modalsuccessShow,
                    setModalsuccessShow,
                    deleteStatus,
                  }}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
