import { ENDPOINT } from "../../constants/server/api";
import { fetchApiData } from "../../utils/apiUtils";

export const createBranch = (branchData) => async (dispatch) => {
  const formData = new FormData();

  formData.append("businessSlug", branchData.businessSlug);
  formData.append("branchName", branchData.branchName);
  formData.append("code", branchData.code);
  formData.append("taxableEntityType", branchData.taxableEntityType);
  formData.append("vatCode", branchData.vatCode || "");
  formData.append("address", branchData.address);
  formData.append("subDistrictId", branchData.subDistrictId);
  formData.append("phoneNumber", branchData.phoneNumber);
  formData.append("tel", branchData.tel);
  formData.append("pin", branchData.pin);
  formData.append("date", branchData.date);
  formData.append("isStatus", branchData.isStatus);

  if (branchData.imagePath) {
    if (branchData.imagePath instanceof File) {
      formData.append("imagePath", branchData.imagePath);
    } else if (
      typeof branchData.imagePath === "string" &&
      branchData.imagePath.startsWith("data:image")
    ) {
      const blob = await fetch(branchData.imagePath).then((r) => r.blob());
      formData.append("imagePath", blob, "image.jpg");
    }
  }

  const data = await fetchApiData({
    method: "post",
    endpoint: `${ENDPOINT.BRANCH}`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const getBranchById = (id) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.BRANCH}/${id}`,
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const getBranchList = (params) => async (dispatch) => {
  const {
    businessSlug,
    page = 1,
    perPage = 10,
    s = "",
    sortBy = "created_at",
    sortOrder = "desc",
  } = params;

  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.BRANCH}/pagination/${businessSlug}/list`,
    params: {
      page,
      perPage,
      s,
      sortBy,
      sortOrder,
    },
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const getBranchAllList = (businessSlug) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.BRANCH}/all/${businessSlug}/list`,
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const updateBranch = (id, branchData) => async (dispatch) => {
  const formData = new FormData();

  formData.append("businessSlug", branchData.businessSlug);
  formData.append("code", branchData.code);
  formData.append("branchName", branchData.branchName);
  formData.append("taxableEntityType", branchData.taxableEntityType);
  formData.append("vatCode", branchData.vatCode || "");
  formData.append("address", branchData.address);
  formData.append("subDistrictId", branchData.subDistrictId);
  formData.append("phoneNumber", branchData.phoneNumber);
  formData.append("tel", branchData.tel);
  formData.append("pin", branchData.pin);
  formData.append("date", branchData.date);
  formData.append("isStatus", branchData.isStatus);

  if (branchData.imagePath) {
    if (branchData.imagePath instanceof File) {
      formData.append("imagePath", branchData.imagePath);
    } else if (
      typeof branchData.imagePath === "string" &&
      branchData.imagePath.startsWith("data:image")
    ) {
      const blob = await fetch(branchData.imagePath).then((r) => r.blob());
      formData.append("imagePath", blob, "image.jpg");
    }
  }

  const data = await fetchApiData({
    method: "put",
    endpoint: `${ENDPOINT.BRANCH}/${id}`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const deleteBranch = (id) => async (dispatch) => {
  const data = await fetchApiData({
    method: "delete",
    endpoint: `${ENDPOINT.BRANCH}/${id}`,
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const exportBranchToExcel =
  (businessSlug, searchTerm) => async (dispatch) => {
    const data = await fetchApiData({
      method: "post",
      endpoint: `${ENDPOINT.BRANCH}/excel/${businessSlug}`,
      params: {
        s: searchTerm || "",
      },
      responseType: "blob",
      dispatch,
    });

    if (data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "รายการสาขา.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }
  };

export const createBranchPOS = (branchPOSData) => async (dispatch) => {
  const data = await fetchApiData({
    method: "post",
    endpoint: `${ENDPOINT.BRANCH_POS}`,
    data: branchPOSData,
    headers: {
      "Content-Type": "application/json",
    },
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const getBranchPOSById = (id) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.BRANCH_POS}/${id}`,
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const getAllBranchPOS = (branchId) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.BRANCH_POS}`,
    params: {
      branchId,
    },
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const updateBranchPOS = (id, branchPOSData) => async (dispatch) => {
  const data = await fetchApiData({
    method: "put",
    endpoint: `${ENDPOINT.BRANCH_POS}/${id}`,
    data: branchPOSData,
    headers: {
      "Content-Type": "application/json",
    },
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const deleteBranchPOS = (id) => async (dispatch) => {
  const data = await fetchApiData({
    method: "delete",
    endpoint: `${ENDPOINT.BRANCH_POS}/${id}`,
    dispatch,
  });

  if (data) {
    return data;
  }
};
