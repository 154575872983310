import { STATUS } from "../../../../constants/server/api";
import { employeeHandler } from "../../../../redux/handlers";

export const SubmitChangePassword = async (dispatch, empID, values) => {
  try {
    const result = await dispatch(
      employeeHandler.updateEmployeePassword(empID, values)
    );
    if (result == STATUS.OK) {
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};
