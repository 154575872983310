import { STATUS } from "../../../constants/server/api";
import { businessHandler } from "../../../redux/handlers";

export const HanddleLogin = async (
  dispatch,
  { email, password, businessSlug }
) => {
  try {
    const result = await dispatch(
      businessHandler.login(businessSlug, email, password)
    );
    if (result === STATUS.OK) {
      return STATUS.OK;
    }
    return STATUS.ERROR;
  } catch (error) {
    console.log(error);
    return STATUS.ERROR;
  }
};
