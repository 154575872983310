import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams, NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import Form from "react-bootstrap/Form";

import Collapse from "react-bootstrap/Collapse";
import { utils, writeFileXLSX } from "xlsx";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AutoTabProvider } from "react-auto-tab";
import {
  ThailandAddressTypeahead,
  ThailandAddressValue,
} from "react-thailand-address-typeahead";

import { ModalAllder } from "layouts/ModalAllder";
import { DataGrid } from "@mui/x-data-grid";
import { SubmitChangeEmail } from "../../hook/SubmitChangeEmail";
import { useDispatch } from "react-redux";

export default function ChangeEmailModal({
  modal,
  setModal,
  onSubmit,
  formaccount,
  empID,
}) {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    email: "",
  });

  const schema = yup.object().shape({
    email: yup.string().required(),
  });

  useEffect(() => {
    setForm({
      email: formaccount.email,
    });
  }, [formaccount]);

  return (
    <>
      <ModalAllder
        modal={modal}
        setModal={setModal}
        htitle={"เปลี่ยนอีเมล"}
        hbtn={true}
      >
        <div className="py-3">
          <div className="">
            <Formik
              enableReinitialize
              initialValues={form}
              validationSchema={schema}
              onSubmit={async (values) => {
                setModal((prev) => ({ ...prev, open: false }));
                onSubmit(values);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                touched,
                errors,
                values,
                setFieldValue,
                isInvalid,
                isValid,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <div className="py-1"></div>
                  <Form.Group className="mb-3 form-st-3 pb-1">
                    <Form.Label className="mb-1">
                      อีเมล <span className="text-danger">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      className="form-control"
                      placeholder="อีเมล"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                  </Form.Group>

                  <div className="text-center pt-4 pb-3  btn-group-control">
                    <Button
                      size="large"
                      type="button"
                      className="btn-st-6 me-3 !border-[#6D788D] !rounded-lg !text-[#6D788D]"
                      style={{ width: "100px" }}
                      variant={"outlined"}
                      onClick={() =>
                        setModal((prev) => ({ ...prev, open: false }))
                      }
                    >
                      ยกเลิก
                    </Button>
                    <Button
                      size="large"
                      type="submit"
                      variant={"contained"}
                      className="btn-st-3  !rounded-lg"
                      style={{ width: "100px" }}
                    >
                      ยืนยัน
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </ModalAllder>
    </>
  );
}
