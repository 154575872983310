
import React, { useState,useEffect  } from 'react';
import Select from 'react-select';

export default function SelectCategory({handleChange,selectedID,errors,isDisabled,...props}){

    const optionsstoreHouse= [
        { value: 1, label: 'คลัง 1' },
        { value: 2, label: 'คลัง 2' },
        { value: 3, label: 'คลัง 3' }
    ];

    return (
        <Select as="select"
            name="storeH"
            onChange={(e) => handleChange(e) }
            className={`w-100 ${ errors.storeHouse ? 'isInvalid' : '' } select-st-1`}
            value={
                optionsstoreHouse.filter(
                    item => item.value === selectedID
                )
            }
            options={optionsstoreHouse}
            placeholder="เลือกคลังสินค้า"
            isDisabled={isDisabled}
        />
    )
}


