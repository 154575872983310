import React from 'react';
import Modal from 'react-bootstrap/Modal';
import 'react-datepicker/dist/react-datepicker.css';

export default function SuccessDeleteModal({ modalObj, ...props }) {
  return (
    <Modal {...props} size="sm" centered>
      <Modal.Body>
        <div className="px-3 py-4">
          <div className="justify-items-center w-full mb-4">
            <svg
              width="84"
              height="83"
              viewBox="0 0 84 83"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="42" cy="41.5" r="41.5" fill="#2DC76D" />
              <path
                d="M56.0123 30.9902L36.7444 50.2581L27.9863 41.5"
                stroke="white"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="text-center fs-22 tc-7">ลบรายการสำเร็จ</div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
