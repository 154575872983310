import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { productHandler } from "../../../../redux/handlers";
import { STATUS } from "../../../../constants/server/api";
import {
  OptionStandardModal,
  ConfirmDeleteModal,
  SuccessModal,
} from "./modals";

const INITIAL_MODAL_STATE = {
  open: false,
  x: 0,
  y: 0,
  width: 500,
  height: 340,
  pin: false,
  mode: "normal",
  modalname: "modal_edit_option_product",
};

export function OptionStandard() {
  const { productID, businessSlug } = useParams();
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product.product);

  const [optionOpen, setOptionOpen] = useState(product?.is_option || false);
  const [listOptionStandard, setListOptionStandard] = useState([]);
  const [optionStandard, setOptionStandard] = useState([]);
  const [modalEdit, setModalEdit] = useState(INITIAL_MODAL_STATE);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [formdisabled, setFormdisabled] = useState(true);

  useEffect(() => {
    const fetchInitialData = async () => {
      if (!businessSlug || !productID) return;

      await fetchOptionRelations();
      await fetchOptionStandard();
    };

    fetchInitialData();
  }, [productID, businessSlug, dispatch]);

  const fetchOptionStandard = async () => {
    const optionsResponse = await dispatch(
      productHandler.getAllProductOptions({
        businessSlug,
        productId: productID,
        pageQuery: "productInclude",
      })
    );

    if (optionsResponse) {
      setOptionStandard(optionsResponse);
    }
  };
  useEffect(() => {
    if (product) {
      setOptionOpen(product.is_option);
    }
  }, [product]);

  const fetchOptionRelations = async () => {
    if (!productID) return;

    const relationResponse = await dispatch(
      productHandler.getProductOptionRelations(productID)
    );

    if (!relationResponse) return;

    const formattedData = relationResponse.map((item) => ({
      id: item.id,
      name: item.productOption.name,
      selecttype: item.productOption.is_multiple_select,
      product_option_detail: item.productOption.productOptionItem || [],
    }));

    setListOptionStandard(formattedData);
    if (formattedData.length > 0) {
      setOptionOpen(true);
    }
  };

  const handleOptionSwitchChange = async (e) => {
    const isChecked = e.target.checked;
    setOptionOpen(isChecked);

    try {
      await dispatch(
        productHandler.updateProductOptionStatus(productID, isChecked)
      );
    } catch (error) {
      console.error("Error updating product option status:", error);

      setOptionOpen(!isChecked);
    }
  };

  const handleSubmitOption = async (selectedOption) => {
    if (formdisabled) return;

    try {
      const response = await dispatch(
        productHandler.createProductOptionRelation({
          product_id: productID,
          product_option_id: selectedOption.id,
        })
      );

      if (response) {
        await fetchOptionRelations();
        await fetchOptionStandard();
        setSuccessMessage("เพิ่มตัวเลือกมาตรฐานสำเร็จ");
        setShowSuccessModal(true);
      }
    } catch (error) {
      console.error("Error creating product option relation:", error);
    }
  };

  const handleDeleteConfirm = (id) => {
    if (formdisabled) return;

    setDeleteTarget({ id, type: "option" });
    setShowDeleteConfirm(true);
  };

  const handleDeleteConfirmed = async () => {
    if (!deleteTarget) return;

    try {
      const response = await dispatch(
        productHandler.deleteProductOptionRelation(deleteTarget.id)
      );

      if (response === STATUS.OK) {
        await fetchOptionRelations();
        await fetchOptionStandard();
        setShowDeleteConfirm(false);
        setSuccessMessage("ลบตัวเลือกมาตรฐานสำเร็จ");
        setShowSuccessModal(true);
      }
    } catch (error) {
      console.error("Error deleting product option relation:", error);
    }
  };

  const renderOptionDetails = (details = []) => {
    return details.map((item, index) => (
      <div className="col-xl-6 row gx-4 align-items-center" key={index}>
        <div className="col">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">รายการ</Form.Label>
            <Form.Control
              type="text"
              name="listName"
              className="form-control"
              placeholder="รายการ"
              value={item.name}
              disabled
            />
          </Form.Group>
        </div>
        <div className="col-auto">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">ราคา</Form.Label>
            <Form.Control
              type="text"
              name="note"
              className="form-control"
              placeholder="ราคา"
              value={item.price}
              disabled
            />
          </Form.Group>
        </div>
      </div>
    ));
  };

  const renderOptionList = () => {
    return listOptionStandard.map((item, index) => (
      <div className="row gx-4 align-items-center mb-5" key={index}>
        <div className="col-xl-6 col-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">ตัวเลือกมาตรฐาน</Form.Label>
            <Form.Control
              type="text"
              name="note"
              className="form-control"
              placeholder="ตัวเลือกมาตรฐาน"
              value={item.name}
              disabled
            />
          </Form.Group>
        </div>
        <div className="col-xl-4 col-6">
          <Form.Check
            type="switch"
            id={`custom-switch-standard${index}`}
            label="เลือกได้มากกว่า 1 รายการ"
            name={`selecttype[${index}]`}
            className="custom-switch-st-1 tc-10 s1 mb-1"
            checked={item.selecttype}
            disabled
          />
        </div>
        <div className="col-xl-2 col-6">
          <button
            type="button"
            className="btn btn-st-3"
            onClick={() => handleDeleteConfirm(item.id)}
            disabled={formdisabled}
            style={{ background: "#F95057", borderColor: "#F95057" }}
          >
            ลบตัวเลือกสินค้า
          </button>
        </div>
        {renderOptionDetails(item.product_option_detail)}
      </div>
    ));
  };

  return (
    <div className="card card-st-3 mb-3">
      <div className="card-body px-xl-3">
        <div className="pt-2 pb-1 px-3">
          <Form.Check
            type="switch"
            id="custom-sd"
            label="กำหนดตัวเลือกมาตรฐาน"
            name="optionstandardStatus"
            className="custom-switch-st-1 s1 mb-1"
            checked={optionOpen}
            onChange={handleOptionSwitchChange}
          />
          <span className="ms-2" style={{ color: "#858796" }}>
            ( ใช้ข้อมูลจากตัวเลือกมาตรฐานที่สร้างไว้ )
          </span>
        </div>

        {optionOpen && (
          <div className="px-5 py-4">
            <div className="row">
              <div className="col-xl-6 col">
                <div className="mb-2 mt-4">
                  <button
                    type="button"
                    className="btn btn-st-3 mb-4"
                    onClick={() =>
                      setModalEdit((prev) => ({ ...prev, open: true }))
                    }
                    disabled={formdisabled}
                  >
                    เพิ่มตัวเลือกมาตรฐาน
                  </button>
                </div>
              </div>
            </div>

            <div className="ms-xl-4 mt-2 ps-2">{renderOptionList()}</div>
          </div>
        )}
        {optionOpen && (
          <div className="text-end pe-3 mt-4 pb-4">
            {formdisabled ? (
              <Button
                type="button"
                className="btn-st-3"
                onClick={() => setFormdisabled(false)}
              >
                แก้ไข
              </Button>
            ) : (
              <Button
                type="button"
                className="btn-st-6"
                onClick={() => setFormdisabled(true)}
              >
                ยกเลิก
              </Button>
            )}
          </div>
        )}
      </div>

      <OptionStandardModal
        modal={modalEdit}
        setModal={setModalEdit}
        setListOptionStandard={setListOptionStandard}
        optionstandard={optionStandard}
        onSubmit={handleSubmitOption}
        disabled={formdisabled}
      />

      <ConfirmDeleteModal
        show={showDeleteConfirm}
        onHide={() => setShowDeleteConfirm(false)}
        onSuccess={handleDeleteConfirmed}
        deleteTarget={deleteTarget}
      />

      <SuccessModal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        textsuccess={successMessage}
      />
    </div>
  );
}
