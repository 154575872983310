import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";

export function PaginatedItems({
  fetchData,
  itemsPerPage = 10,
  filterParams = {},
  loading,
  totalRows,
}) {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await fetchData({
          page: currentPage + 1,
          per_page: itemsPerPage,
          ...filterParams,
        });

        if (response?.data) {
          setCurrentItems(response.data);
          const total = response.meta?.total || 0;
          setPageCount(Math.ceil(total / itemsPerPage));
        }
      } catch (error) {
        console.error("Error fetching paginated data:", error);
      }
    };

    loadData();
  }, [currentPage, itemsPerPage, fetchData, filterParams]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleImageError = (e) => {
    e.target.onerror = null;
    e.target.src = "/images/img-default.jpeg";
  };

  return (
    <>
      <div className="px-4 py-4 d-flex flex-wrap">
        {loading ? (
          <div className="w-100 text-center py-4">Loading...</div>
        ) : currentItems?.length === 0 ? (
          <div className="w-100 text-center py-4">
            <div className="fs-5 text-secondary">ไม่พบข้อมูล</div>
          </div>
        ) : (
          currentItems?.map((item, index) => (
            <div className="card card-food me-3 mb-3" key={item.id || index}>
              <div className="position-relative">
                <img
                  src={item.image_path || "/images/img-default.jpeg"}
                  className="food-img"
                  alt={item.name || "Product image"}
                  onError={handleImageError}
                />
                <div
                  className="position-absolute"
                  style={{ top: "10px", right: "10px" }}
                >
                  {item.is_buffet && (
                    <div className="tag-img buffet">เป็นแพคเกจบุฟเฟต์</div>
                  )}
                  {item.is_sale_per_weight && (
                    <div className="tag-img saleperweight">ขายตามน้ำหนัก</div>
                  )}
                  {item.product_type === "SET" && (
                    <div className="tag-img productset">สินค้าจัดชุด</div>
                  )}
                </div>
              </div>
              <div className="card-body pt-1 px-3">
                <div className="card-name">{item.name}</div>
                <div className="card-price mb-1">฿{item.price}.-</div>
                <div className="card-barcode mb-2">{item.sku}</div>
                <div className="d-flex flex-wrap">
                  <div className="tag-category me-2">
                    {item.productCategory?.name || ""}
                  </div>
                  <div className="tag-display me-2"></div>
                  {item.product_vat_type !== "NO_VAT" && (
                    <div className="tag-vat me-2">Vat</div>
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <div className="d-flex justify-content-center">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
          forcePage={currentPage}
        />
      </div>
    </>
  );
}
