import { Formik } from "formik";
import * as yup from "yup";
import { Button, Form } from "react-bootstrap";
import { ModalAllder } from "layouts/ModalAllder";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useState } from "react";
import SuccessModal from "../modals/SuccessModal";

const initialFormState = {
  date: dayjs().format("YYYY-MM-DD"),
  order: 1,
  posId: "",
  name: "",
  posStatus: "ยังไม่มีการซื้อขาย",
  taxInvoice: 0,
  isTaxInvoiceABB: 0,
  taxNumber: "",
  approvedDate: "",
  isStatus: 1,
};

function POSDeviceFormModal({
  modal,
  setModal,
  branchID,
  deviceData,
  onSubmit,
  onDelete,
  isReadOnly = false,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formInitialValues = {
    ...initialFormState,
    ...deviceData,
  };

  const isEditMode = deviceData?.id != null;

  const schema = yup.object().shape({
    name: yup.string().required("กรุณากรอกชื่อ POS"),
    posId: yup.string().required("กรุณากรอก POS ID"),
    taxNumber: yup.string().test({
      name: "taxNumber",
      test: function (value) {
        return this.parent.isTaxInvoiceABB !== 1 || (value && value.length > 0);
      },
      message: "กรุณากรอกเลขจากสรรพากร",
    }),
  });

  const showSuccessModal = (message) => {
    setModalMessage(message);
    setIsModalVisible(true);

    setTimeout(() => {
      setIsModalVisible(false);
      setModal((prev) => ({ ...prev, open: false }));
    }, 1500);
  };

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    if (isReadOnly || isSubmitting) return;

    try {
      setIsSubmitting(true);

      const formattedData = {
        branchId: branchID,
        date: dayjs(values.date).format("YYYY-MM-DD"),
        order: Number(values.order),
        posId: values.posId,
        name: values.name,
        isTaxInvoiceABB: values.isTaxInvoiceABB,
        taxNumber: values.taxNumber || "",
        approvedDate: values.approvedDate
          ? dayjs(values.approvedDate).format("YYYY-MM-DD")
          : null,
        isStatus: values.isStatus,
      };

      await onSubmit?.(values.id, formattedData);

      showSuccessModal(
        isEditMode ? "แก้ไขข้อมูล POS สำเร็จ" : "เพิ่มข้อมูล POS สำเร็จ"
      );

      resetForm();
    } catch (error) {
      console.error("Error saving POS:", error);
    } finally {
      setIsSubmitting(false);
      setSubmitting(false);
    }
  };

  const handleDelete = () => {
    if (isReadOnly || !deviceData?.id) return;

    setModal((prev) => ({ ...prev, open: false }));
    onDelete?.(deviceData.id);
  };

  // ฟังก์ชันป้องกันการส่งฟอร์มซ้ำเมื่อกด Enter
  const preventMultipleSubmits = (e) => {
    if (isSubmitting) {
      e.preventDefault();
    }
  };

  return (
    <>
      <ModalAllder
        modal={modal}
        setModal={setModal}
        htitle={
          isEditMode ? (isReadOnly ? "ดู POS" : "แก้ไข POS") : "เพิ่ม POS"
        }
        hbtn={true}
      >
        <div className="pos-form-container">
          <Formik
            enableReinitialize
            initialValues={formInitialValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
              isSubmitting: formikSubmitting,
            }) => (
              <Form
                noValidate
                onSubmit={handleSubmit}
                onKeyDown={preventMultipleSubmits}
              >
                <Form.Group className="mt-3">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      readOnly={isReadOnly}
                      disabled={isReadOnly || isSubmitting || formikSubmitting}
                      slotProps={{
                        textField: {
                          InputLabelProps: { shrink: true },
                          error: !!errors.date,
                        },
                      }}
                      label="วันที่สร้าง"
                      className={`w-full ${isReadOnly ? "bg-[#F8F8F9]" : ""}`}
                      sx={{
                        ".MuiInputBase-formControl": {
                          height: "53px",
                          borderRadius: "8px",
                        },
                        ".MuiFormLabel-root": {
                          fontFamily: "Sarabun",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          border: "1px solid rgba(76, 78, 100, 0.22)",
                        },
                        ".MuiPopper-root.MuiPickersPopper-root": {
                          zIndex: 1700
                        }
                      }}
                      name="date"
                      value={values.date ? dayjs(values.date) : null}
                      onChange={(date) =>
                        !isReadOnly &&
                        !isSubmitting &&
                        !formikSubmitting &&
                        setFieldValue("date", date)
                      }
                    />
                  </LocalizationProvider>
                </Form.Group>

                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">ลำดับที่ POS</Form.Label>
                  <Form.Control
                    type="text"
                    name="order"
                    placeholder="ลำดับที่ POS"
                    value={values.order}
                    onChange={handleChange}
                    isInvalid={!!errors.order}
                    disabled={true}
                  />
                  {errors.order && (
                    <Form.Control.Feedback type="invalid">
                      {errors.order}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    POS ID (รหัสเครื่อง) <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="posId"
                    placeholder="POS ID (รหัสเครื่อง)"
                    value={values.posId || ""}
                    onChange={handleChange}
                    disabled={isReadOnly || isSubmitting || formikSubmitting}
                    isInvalid={touched.posId && !!errors.posId}
                  />
                  {touched.posId && errors.posId && (
                    <Form.Control.Feedback type="invalid">
                      {errors.posId}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ชื่อ POS <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="ชื่อ POS"
                    value={values.name || ""}
                    onChange={handleChange}
                    disabled={isReadOnly || isSubmitting || formikSubmitting}
                    isInvalid={touched.name && !!errors.name}
                  />
                  {touched.name && errors.name && (
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">สถานะ</Form.Label>
                  <Form.Control
                    type="text"
                    name="posStatus"
                    placeholder="สถานะ"
                    value={values.posStatus || ""}
                    onChange={handleChange}
                    isInvalid={!!errors.posStatus}
                    disabled={true}
                  />
                </Form.Group>

                <Form.Check
                  type="switch"
                  id="tax-invoice-switch"
                  label="จดทะเบียนใบกำกับภาษีอย่างย่อ"
                  name="isTaxInvoiceABB"
                  className="custom-switch-st-1 mb-4"
                  checked={values.isTaxInvoiceABB === 1}
                  disabled={isReadOnly || isSubmitting || formikSubmitting}
                  onChange={(e) => {
                    if (!isReadOnly && !isSubmitting && !formikSubmitting) {
                      setFieldValue(
                        "isTaxInvoiceABB",
                        e.target.checked ? 1 : 0
                      );
                      if (!e.target.checked) {
                        setFieldValue("taxNumber", "");
                        setFieldValue("approvedDate", "");
                      }
                    }
                  }}
                />

                {values.isTaxInvoiceABB === 1 && (
                  <div className="tax-fields">
                    <Form.Group className="mb-3 form-st-3 pb-2">
                      <Form.Label className="mb-1">
                        เลขจากสรรพากร <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="taxNumber"
                        placeholder="เลขจากสรรพากร"
                        value={values.taxNumber || ""}
                        onChange={handleChange}
                        disabled={
                          isReadOnly || isSubmitting || formikSubmitting
                        }
                        isInvalid={touched.taxNumber && !!errors.taxNumber}
                      />
                      {touched.taxNumber && errors.taxNumber && (
                        <Form.Control.Feedback type="invalid">
                          {errors.taxNumber}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group className="mt-3">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          readOnly={isReadOnly}
                          disabled={
                            isReadOnly || isSubmitting || formikSubmitting
                          }
                          slotProps={{
                            textField: {
                              InputLabelProps: { shrink: true },
                              error: !!errors.approvedDate,
                            },
                          }}
                          label="วันที่อนุมัติ"
                          className={`w-full ${isReadOnly ? "bg-[#F8F8F9]" : ""
                            }`}
                          sx={{
                            ".MuiInputBase-formControl": {
                              height: "53px",
                              borderRadius: "8px",
                            },
                            ".MuiFormLabel-root": {
                              fontFamily: "Sarabun",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                              border: "1px solid rgba(76, 78, 100, 0.22)",
                            },
                          }}
                          name="approvedDate"
                          value={
                            values.approvedDate
                              ? dayjs(values.approvedDate)
                              : null
                          }
                          onChange={(date) =>
                            !isReadOnly &&
                            !isSubmitting &&
                            !formikSubmitting &&
                            setFieldValue("approvedDate", date)
                          }
                        />
                      </LocalizationProvider>
                    </Form.Group>
                  </div>
                )}

                <Form.Label className="mb-2 tc-10">สถานะการใช้งาน</Form.Label>
                <div className="mb-3">
                  <Form.Check
                    inline
                    label="เปิดใช้งาน"
                    name="isStatus"
                    type="radio"
                    id="status-active"
                    className="form-check-st-2 tc-7"
                    checked={values.isStatus === 1}
                    disabled={isReadOnly || isSubmitting || formikSubmitting}
                    onChange={() =>
                      !isReadOnly &&
                      !isSubmitting &&
                      !formikSubmitting &&
                      setFieldValue("isStatus", 1)
                    }
                  />
                  <Form.Check
                    inline
                    label="ปิดใช้งาน"
                    name="isStatus"
                    type="radio"
                    id="status-inactive"
                    className="form-check-st-2 tc-7"
                    checked={values.isStatus === 0}
                    disabled={isReadOnly || isSubmitting || formikSubmitting}
                    onChange={() =>
                      !isReadOnly &&
                      !isSubmitting &&
                      !formikSubmitting &&
                      setFieldValue("isStatus", 0)
                    }
                  />
                </div>

                <div className="d-flex pt-4 pb-3 btn-group-control">
                  {isEditMode && !isReadOnly && (
                    <Button
                      type="button"
                      className="btn-st-5 me-auto"
                      style={{ width: "80px" }}
                      onClick={handleDelete}
                      disabled={isSubmitting || formikSubmitting}
                    >
                      ลบ
                    </Button>
                  )}
                  <div
                    className={
                      isEditMode && !isReadOnly ? "" : "ms-auto text-end w-100"
                    }
                  >
                    <Button
                      type="button"
                      className="btn-st-6"
                      style={{ width: "120px" }}
                      onClick={() =>
                        setModal((prev) => ({ ...prev, open: false }))
                      }
                      disabled={isSubmitting || formikSubmitting}
                    >
                      {isReadOnly ? "ปิด" : "ยกเลิก"}
                    </Button>
                    {!isReadOnly && (
                      <Button
                        type="submit"
                        className="btn-st-3 ms-3"
                        style={{ width: "120px" }}
                        disabled={isSubmitting || formikSubmitting}
                      >
                        {isSubmitting || formikSubmitting
                          ? "กำลังบันทึก..."
                          : "บันทึก"}
                      </Button>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ModalAllder>

      <SuccessModal
        show={isModalVisible}
        onHide={() => setIsModalVisible(false)}
        textsuccess={modalMessage}
      />
    </>
  );
}

export default POSDeviceFormModal;
