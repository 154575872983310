import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {
    data: [],
  },
  getById: [],
  dataRole: [],
  allEmployees: [],
};

const branchEmpSlice = createSlice({
  name: 'branchEmp',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    getById: (state, action) => {
      state.getById = action.payload;
    },
    deleteItem: (state, action) => {
      state.data.data = state.data.data.filter(
        (item) => item.id !== action.payload
      );
    },
    setRole: (state, action) => {
      state.dataRole = action.payload;
    },
    setAllEmployees: (state, action) => {
      state.allEmployees = action.payload;
    },
  },
});

export const { getById, setData, deleteItem, setRole, setAllEmployees } =
  branchEmpSlice.actions;

export default branchEmpSlice.reducer;
