import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import KitchenCategoryManagement from "./components/KitchenCategoryManagement";
import KitchenMenuManagement from "./components/KitchenMenuManagement";
import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { branchKitchenHandler } from "../../../redux/handlers";
import SuccessModal from "./components/modals/SuccessModal";
import Select from "react-select";
import { KitchenRefreshProvider } from "./components/KitchenRefreshContext";

export function KitchenForm() {
  const { branchID, kitchenID, businessSlug } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [availablePrinters, setAvailablePrinters] = useState([]);
  const [kitchenFormData, setKitchenFormData] = useState({
    branchId: branchID,
    name: "",
    branchPrinterId: "",
    kitchenDisplayMode: "ALL_ITEM",
  });

  const validationSchema = yup.object().shape({
    name: yup.string().required("กรุณากรอกชื่อห้องครัว"),
    branchPrinterId: yup.string().required("กรุณาเลือกเครื่องพิมพ์"),
  });

  useEffect(() => {
    const loadInitialData = async () => {
      const printerList = await dispatch(
        branchKitchenHandler.getPrinter(branchID)
      );
      if (printerList) {
        setAvailablePrinters(printerList);
      }

      if (kitchenID) {
        await fetchKitchenDetails();
      }
    };

    loadInitialData();
  }, [dispatch, kitchenID, branchID]);

  const fetchKitchenDetails = async () => {
    const kitchenDetails = await dispatch(
      branchKitchenHandler.getKitchen(kitchenID)
    );
    if (kitchenDetails) {
      setKitchenFormData({
        branchId: branchID,
        name: kitchenDetails.name,
        branchPrinterId: kitchenDetails.branch_printer_id,
        kitchenDisplayMode: kitchenDetails.kitchen_display_mode,
      });
    }
  };

  const printerOptions = Array.isArray(availablePrinters)
    ? availablePrinters.map((printer) => ({
        value: printer.id,
        label: printer.name,
      }))
    : [];

  const handleCreateKitchen = async (formData) => {
    try {
      const result = await dispatch(
        branchKitchenHandler.createKitchen(formData)
      );

      if (result) {
        const newKitchenID = result.id;
        showSuccessAndRedirect("เพิ่มครัวสำเร็จ", () => {
          navigate(
            `/${businessSlug}/branch/setting/kitchen/${branchID}/manage/${newKitchenID}`,
            { replace: true }
          );
        });
      }
    } catch (error) {
      console.error("Error creating kitchen:", error);
    }
  };

  const handleUpdateKitchen = async (formData) => {
    try {
      const result = await dispatch(
        branchKitchenHandler.updadteKitchen(kitchenID, formData)
      );

      if (result) {
        showSuccessAndRedirect("แก้ไขครัวสำเร็จ", async () => {
          // navigate(-1);
          if (kitchenID) {
            await fetchKitchenDetails();
          }
        });
      }
    } catch (error) {
      console.error("Error updating kitchen:", error);
    }
  };

  const showSuccessAndRedirect = (message, redirectCallback) => {
    setModalMessage(message);
    setIsModalVisible(true);
    setTimeout(() => {
      setIsModalVisible(false);
      redirectCallback();
    }, 1500);
  };

  const handleFormSubmit = async (values) => {
    if (kitchenID !== undefined) {
      await handleUpdateKitchen(values);
    } else {
      await handleCreateKitchen(values);
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <div className="card card-st-2 mb-4">
        <div className="card-body px-0 py-4">
          <Formik
            enableReinitialize
            initialValues={kitchenFormData}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
            }) => (
              <Form className="px-4" noValidate onSubmit={handleSubmit}>
                <div className="d-flex align-items-center mb-4">
                  <div className="tc-7 fs-20 fw-6 me-auto">
                    {kitchenID !== undefined ? "แก้ไขครัว" : "เพิ่มครัว"}
                  </div>
                  <Button
                    type="submit"
                    className="btn-st-3"
                    style={{ width: "100px" }}
                  >
                    บันทึก
                  </Button>
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    <Form.Group className="mb-3 form-st-3 pb-1">
                      <Form.Label className="mb-1">
                        ชื่อห้องครัว <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="กรอกชื่อห้องครัว"
                        value={values.name}
                        onChange={handleChange}
                        isInvalid={!!errors.name && touched.name}
                      />
                      {errors.name && touched.name && (
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-xl-6">
                    <Form.Group className="mb-2 form-st-3 pb-1">
                      <Form.Label className="mb-1">
                        ชื่อเครื่องพิมพ์ <span className="text-danger">*</span>
                      </Form.Label>
                      <Select
                        name="branchPrinterId"
                        onChange={(option) => {
                          setFieldValue("branchPrinterId", option.value);
                        }}
                        className={`w-100 ${
                          errors.branchPrinterId && touched.branchPrinterId
                            ? "isInvalid"
                            : ""
                        } select-st-1`}
                        value={printerOptions.find(
                          (option) => option.value === values.branchPrinterId
                        )}
                        options={printerOptions}
                        placeholder="กรอกชื่อเครื่องพิมพ์"
                      />
                      {errors.branchPrinterId && touched.branchPrinterId && (
                        <div className="invalid-feedback d-block">
                          {errors.branchPrinterId}
                        </div>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <Form.Label className="mb-2 tc-10">
                  กำหนดรายการอาหารที่จะปรากฏในหน้าจอครัว
                </Form.Label>
                <div className="mb-3">
                  <Form.Check
                    inline
                    label="ทุกรายการอาหาร"
                    name="kitchenDisplayMode"
                    type="radio"
                    id="inline-radio-21"
                    className="form-check-st-2 tc-7"
                    checked={values.kitchenDisplayMode === "ALL_ITEM"}
                    onChange={() => {
                      setFieldValue("kitchenDisplayMode", "ALL_ITEM");
                    }}
                  />
                  <Form.Check
                    inline
                    label="กำหนดรายการเอง"
                    name="kitchenDisplayMode"
                    type="radio"
                    id="inline-radio-22"
                    className="form-check-st-2 tc-7"
                    checked={values.kitchenDisplayMode === "CUSTOM_ITEM"}
                    onChange={() => {
                      setFieldValue("kitchenDisplayMode", "CUSTOM_ITEM");
                    }}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      {kitchenFormData.kitchenDisplayMode === "CUSTOM_ITEM" && (
        <div className="mt-8">
          {/* Wrap the components with KitchenRefreshProvider */}
          <KitchenRefreshProvider>
            <KitchenCategoryManagement />
            <KitchenMenuManagement />
          </KitchenRefreshProvider>
        </div>
      )}

      <SuccessModal
        show={isModalVisible}
        onHide={() => setIsModalVisible(false)}
        textsuccess={modalMessage}
      />
    </div>
  );
}
