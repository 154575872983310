import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams, NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import Form from "react-bootstrap/Form";

import Collapse from "react-bootstrap/Collapse";
import { utils, writeFileXLSX } from "xlsx";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AutoTabProvider } from "react-auto-tab";
import {
  ThailandAddressTypeahead,
  ThailandAddressValue,
} from "react-thailand-address-typeahead";

import { ModalAllder } from "layouts/ModalAllder";
import { DataGrid } from "@mui/x-data-grid";

export default function ChangePinModal({ modal, setModal, onSubmit }) {
  const schema = yup.object().shape({
    pin: yup.string().required("กรุณากรอกรหัส PIN"),
    confirm_pin: yup
      .string()
      .required("กรุณากรอกยืนยันรหัส PIN")
      .oneOf([yup.ref("pin"), null], "รหัส PIN ไม่ตรงกัน"),
  });

  const [inputtype, setInputtype] = useState("password");
  const [inputtype2, setInputtype2] = useState("password");

  return (
    <>
      <ModalAllder
        modal={modal}
        setModal={setModal}
        htitle={"เปลี่ยนรหัส PIN"}
        hbtn={true}
      >
        <div className="py-4">
          <div className="">
            <Formik
              enableReinitialize
              initialValues={{ password: "", confirm_password: "" }}
              validationSchema={schema}
              onSubmit={async (values) => {
                setModal((prev) => ({ ...prev, open: false }));
                onSubmit(values);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                touched,
                errors,
                values,
                setFieldValue,
                isInvalid,
                isValid,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="mb-3 form-st-3 pb-1">
                    <Form.Label className="mb-1">
                      รหัส PIN 4 หลัก <span className="text-danger">*</span>{" "}
                    </Form.Label>

                    <div className="position-relative">
                      <Form.Control
                        type={inputtype}
                        name="pin"
                        className="form-control"
                        placeholder="รหัส PIN 4 หลัก"
                        value={values.pin}
                        onChange={handleChange}
                        isInvalid={!!errors.pin}
                        maxLength={4}
                      />
                      {errors.pin && (
                        <Form.Text className="text-danger">
                          {errors.pin}
                        </Form.Text>
                      )}
                      <Button
                        type="button"
                        className="btn btn-change-type-password"
                        onClick={() =>
                          inputtype == "password"
                            ? setInputtype("text")
                            : setInputtype("password")
                        }
                      ></Button>
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-3 form-st-3 pb-1">
                    <Form.Label className="mb-1">
                      ยืนยันรหัส PIN 4 หลัก{" "}
                      <span className="text-danger">*</span>{" "}
                    </Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type={inputtype2}
                        name="confirm_pin"
                        className="form-control"
                        placeholder="ยืนยันรหัส PIN 4 หลัก"
                        value={values.confirm_pin}
                        onChange={handleChange}
                        isInvalid={!!errors.confirm_pin}
                        maxLength={4}
                      />
                      {errors.confirm_pin && (
                        <Form.Text className="text-danger">
                          {errors.confirm_pin}
                        </Form.Text>
                      )}
                      <Button
                        type="button"
                        className="btn btn-change-type-password"
                        onClick={() =>
                          inputtype2 == "password"
                            ? setInputtype("text")
                            : setInputtype("password")
                        }
                      ></Button>
                    </div>
                  </Form.Group>

                  <div className="text-center pt-4 pb-3  btn-group-control">
                    <Button
                      size="large"
                      type="button"
                      className="btn-st-6 me-3 !border-[#6D788D] !rounded-lg !text-[#6D788D]"
                      style={{ width: "100px" }}
                      variant={"outlined"}
                      onClick={() =>
                        setModal((prev) => ({ ...prev, open: false }))
                      }
                    >
                      ยกเลิก
                    </Button>
                    <Button
                      size="large"
                      type="submit"
                      variant={"contained"}
                      className="btn-st-3  !rounded-lg"
                      style={{ width: "100px" }}
                    >
                      ยืนยัน
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </ModalAllder>
    </>
  );
}
