import React, { useState, useEffect, useCallback } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams } from "react-router-dom";
import DataTableLayout from "layouts/DatatableLayout";
import DownloadExcel from "utils/DownloadExcel";
import Button from "react-bootstrap/Button";
import AllderTable from "../../../components/AllderTable";
import { createTableColumns } from "../../../utils/createTableColumns";
import { useNavigate } from "react-router-dom";
import { branchEmpHandler } from "../../../redux/handlers";
import { useDispatch, useSelector } from "react-redux";
import { useGlobalTable } from "../../../hooks/useGlobalTable";
import ConfirmDeleteModal from "./components/ConfirmDeleteModal";
import SuccessDeleteModal from "./components/SuccessDeleteModal";
export function Emp() {
  // setup hook
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { branchID } = useParams();
  // state
  const [btnmenu, setBtnmenu] = useState(1);
  const [empdata, setEmpdata] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [optionsRoleMap, setOptionsRoleMap] = useState(null);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [modalsuccessShow, setModalsuccessShow] = useState(false);
  //redux selector
  const roleData = useSelector((state) => state.branchEmp?.dataRole);
  //useEffect
  useEffect(() => {
    dispatch(branchEmpHandler.getBranchRole);
  }, [dispatch]);

  useEffect(() => {
    let isArray = Array.isArray(roleData);
    if (isArray) {
      let temp = new Map(roleData.map((role) => [role.id, role.name]));
      setOptionsRoleMap(temp);
    }
  }, [roleData]);
  // setup columns
  const columns = createTableColumns(
    [
      {
        field: "id",
        headerName: "รหัสพนักงาน",
        // flex: 1,
        width: 120,
        renderCell: ({ row }) => (
          <div>
            <p>{`${row.userBusinessGroup.business_id
              ? row.userBusinessGroup.code
              : ""
              }`}</p>
          </div>
        ),
      },
      {
        field: "userBusinessGroup",
        headerName: "ชื่อพนักงาน",
        width: 350,
        renderCell: ({ row }) => (
          <div>
            <p>{`${row.userBusinessGroup.first_name} ${row.userBusinessGroup.last_name}`}</p>
          </div>
        ),
      },
      {
        field: "branch_role_id",
        headerName: "ตำแหน่ง",
        minWidth: 160,
        // flex: 2,
        renderCell: ({ row }) => (
          <div>
            <p>{`${optionsRoleMap.get(row.branch_role_id) == undefined
              ? `${row.userBusinessGroup.business_id
                ? row.userBusinessGroup?.empWorkBusinessEmployeePosition?.name_th
                : ""
              }`
              : optionsRoleMap.get(row.branch_role_id)
              }`}</p>
          </div>
        ),
      },
      {
        field: "permission",
        headerName: "สิทธิ์การใช้งาน",
        minWidth: 160,
        // flex: 2,
        renderCell: ({ row }) => (
          <div>
            {row.is_add_business
              ? `${row.userBusinessGroup?.empBusinessRole?.name || "Super Admin"
              }`
              : `${optionsRoleMap.get(row.branch_role_id) || "Super Admin"}`}
          </div>
        ),
      },
      {
        field: "actions",
        headerName: "จัดการ",
        width: 120,
        sortable: false,
        type: "actions",
      },
    ],
    (row) => (
      <div className="">
        {row.is_add_business ? (
          ""
        ) : (
          <div className="space-x-1 ">
            <Button
              className="btn-edit-st-1"
              onClick={() => navigate(`manage/${row.id}`)}
            ></Button>
            <Button
              type="button"
              className="btn-delete-st-3"
              onClick={() => [
                setModalDeleteShow(true),
                setItemToDelete(row.id),
              ]}
            ></Button>
          </div>
        )}
      </div>
    )
  );
  //Functions
  const filteredItems = empdata.filter(
    (item) =>
      item.categoryName &&
      item.categoryName.toLowerCase().includes(filterText.toLowerCase()),
    (item) =>
      item.categoryCode &&
      item.categoryCode.toLowerCase().includes(filterText.toLowerCase())
  );
  const handleConfirmDelete = () => {
    try {
      dispatch(branchEmpHandler.deleteEmployeeRole(itemToDelete));
      refreshBranchEmployee();
    } catch (error) {
      console.error(error);
    }
  };
  const fetchBranchEmployee = useCallback(
    (params) => {
      return dispatch(
        branchEmpHandler.employeePagination({ branchId: branchID, ...params })
      );
    },
    [branchID, dispatch]
  );
  // setup props for data table
  const {
    data: branchEmployee,
    loading,
    totalRows,
    paginationModel,
    setPaginationModel,
    handleSort,
    debouncedSearch,
    filterParams,
    refreshData: refreshBranchEmployee,
  } = useGlobalTable({
    fetchData: fetchBranchEmployee,
    defaultSort: "created_at",
    defaultOrder: "asc",
    defaultPageSize: 20,
  });

  return (
    <div style={{ padding: "30px 33px 33px 31px" }}>
      <div className="fs-24 tc-7  mb-4 lh-1">จัดการพนักงาน </div>

      <div className="mb-3">
        <button
          className={`btn btn-category me-3 ${btnmenu == 1 ? "active" : ""} `}
          onClick={() => setBtnmenu(1)}
        >
          จัดการพนักงาน
        </button>

        <button
          className={`btn btn-category ${btnmenu == 2 ? "active" : ""}`}
          onClick={() => setBtnmenu(2)}
        >
          จัดการสิทธิระดับสาขา
        </button>
      </div>

      {btnmenu == 1 && (
        <div >
          <div className="card card-st-2 " >
            <div className="card-body px-0 py-4">
              <div className="d-flex justify-end px-4 mb-4">
                <div className="me-3">
                  <input
                    type="text"
                    className="form-control form-st-4"
                    placeholder="ค้นหา ชื่อ, รหัสพนักงาน"
                    onKeyUp={(e) => debouncedSearch(e.target.value)}
                  />
                </div>
                <Button
                  onClick={() => navigate("manage")}
                  className="btn btn btn-st-7 text-decoration-none"
                >
                  ดึงข้อมูล
                </Button>
              </div>
              <div >
                <AllderTable
                  data={branchEmployee}
                  loading={loading}
                  columns={columns}
                  totalRows={totalRows}
                  paginationModel={paginationModel}
                  setPaginationModel={setPaginationModel}
                  handleSort={handleSort}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <ConfirmDeleteModal
        show={modalDeleteShow}
        onHide={() => setModalDeleteShow(false)}
        onConfirm={() => setModalDeleteShow(false)}
        onSuccess={() => {
          setModalsuccessShow(true);
          setDeleteStatus(true);
          handleConfirmDelete();
        }}
        pdata={{ permissionID: itemToDelete }}
      />
      <SuccessDeleteModal
        show={modalsuccessShow}
        onHide={() => setModalsuccessShow(false)}
        modalObj={{
          modalsuccessShow,
          setModalsuccessShow,
          deleteStatus,
        }}
      />
    </div>
  );
}
