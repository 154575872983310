import { useState } from "react";

const useInitialData = (dispatch, businessSlug) => {
  const [branch, setBranch] = useState({
    imagePath: "",
    businessName: "",
    branchName: "",
    branchVatCode: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    potalcode: "",
    address: "",
    phoneNumber: "",
    vatCode: "",
    tel: "",
    email: "",
    website: "",
  });

  return {
    branch,
    setBranch,
  };
};

export default useInitialData;
