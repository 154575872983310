import React, { useState, useEffect, useCallback } from "react";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  settingBusinessHandler,
  masterHandler,
} from "../../../../redux/handlers";
import { useParams } from "react-router-dom";

const NUMBERS_ONLY_REGEX = /^[0-9]+$/;

const defaultValues = {
  settingCurrencyId: "",
  settingVatCalculationId: "",
  settingServiceChargePercen: "",
  settingVatPercen: "",
  settingIsVat: false,
  settingIsServiceCharge: false,
};

const validationSchema = yup.object().shape({
  settingCurrencyId: yup.string().required("กรุณาเลือกสกุลเงิน"),
  settingServiceChargePercen: yup.string().when("settingIsServiceCharge", {
    is: true,
    then: () =>
      yup
        .string()
        .required("กรุณากรอกค่าบริการ")
        .matches(NUMBERS_ONLY_REGEX, "กรุณากรอกเฉพาะตัวเลข")
        .max(2, "ค่าบริการต้องไม่เกิน 2 หลัก"),
    otherwise: () => yup.string().nullable(),
  }),
  settingVatPercen: yup.string().when("settingIsVat", {
    is: true,
    then: () =>
      yup
        .string()
        .required("กรุณากรอก VAT")
        .matches(NUMBERS_ONLY_REGEX, "กรุณากรอกเฉพาะตัวเลข")
        .max(2, "VAT ต้องไม่เกิน 2 หลัก"),
    otherwise: () => yup.string().nullable(),
  }),
  settingVatCalculationId: yup.string().when("settingIsVat", {
    is: true,
    then: () => yup.string().required("กรุณาเลือกวิธีคิดภาษีมูลค่าเพิ่ม"),
    otherwise: () => yup.string().nullable(),
  }),
});

const BusinessSettingsTabSale = ({ formDisabled, setFormDisabled }) => {
  const dispatch = useDispatch();
  const { businessSlug } = useParams();
  const currencies = useSelector((state) => state.master.currencies);
  const vatCalculations = useSelector((state) => state.master.vatCalculations);
  const selectedBusiness = useSelector(
    (state) => state.business.selectedBusiness
  );
  const [initialValues, setInitialValues] = useState(defaultValues);

  const createOptions = useCallback(
    (data = [], nameField = "name", extraFields = {}) => {
      return (
        data?.map((item) => ({
          value: item.id.toString(),
          label: item[nameField],
          ...extraFields(item),
        })) || []
      );
    },
    []
  );

  const currencyOptions = createOptions(currencies, "name", (item) => ({
    code: item.code,
    symbol: item.symbol,
    label: `${item.name} (${item.symbol})`,
  }));

  const vatCalcOptions = createOptions(vatCalculations, "name", (item) => ({
    description: item.description,
    formula: item.formula,
    rate: item.rate,
  }));

  useEffect(() => {
    const loadMasterData = async () => {
      await Promise.all([
        dispatch(masterHandler.getCurrencies()),
        dispatch(masterHandler.getVatCalculations()),
      ]);
    };
    loadMasterData();
  }, [dispatch]);

  useEffect(() => {
    if (!selectedBusiness?.business) return;

    const {
      setting_currency_id,
      setting_is_service_charge,
      setting_is_vat,
      setting_service_charge_percen,
      setting_vat_calculation_id,
      setting_vat_percen,
    } = selectedBusiness.business;

    setInitialValues({
      settingCurrencyId: setting_currency_id?.toString() || "",
      settingVatCalculationId: setting_vat_calculation_id?.toString() || "",
      settingServiceChargePercen: setting_service_charge_percen || "",
      settingVatPercen: setting_vat_percen || "",
      settingIsVat: setting_is_vat || false,
      settingIsServiceCharge: setting_is_service_charge || false,
    });
  }, [selectedBusiness]);

  const handleSubmit = async (values) => {
    try {
      const businessId = window.location.pathname.split("/business/")[1];
      const result = await dispatch(
        settingBusinessHandler.updateBusinessSlugSettings(businessSlug, values)
      );
      if (result === "OK") {
        setFormDisabled(true);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        errors,
        touched,
        values,
        setFieldValue,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <fieldset disabled={formDisabled}>
            <div className="card-body px-xl-4">
              <div className="py-3">
                <div className="row gx-xl-4">
                  <div className="col-xl-6">
                    <Form.Group className="mb-2 form-st-3 pb-1">
                      <Form.Label className="mb-1">
                        สกุลเงิน <span className="text-danger">*</span>
                      </Form.Label>
                      <Select
                        name="settingCurrencyId"
                        onChange={(e) =>
                          setFieldValue("settingCurrencyId", e.value)
                        }
                        className={
                          !!errors.settingCurrencyId &&
                          touched.settingCurrencyId
                            ? "w-100 select-st-1 isInvalid"
                            : "w-100 select-st-1"
                        }
                        options={currencyOptions}
                        placeholder="สกุลเงิน"
                        value={currencyOptions.filter(
                          (item) => item.value === values.settingCurrencyId
                        )}
                        isLoading={!currencies}
                      />
                      {errors.settingCurrencyId &&
                        touched.settingCurrencyId && (
                          <div className="invalid-feedback d-block">
                            {errors.settingCurrencyId}
                          </div>
                        )}
                    </Form.Group>

                    <div className="d-flex align-items-center">
                      <Form.Check
                        type="switch"
                        id="service-charge-switch"
                        label=""
                        name="settingIsServiceCharge"
                        className="custom-switch-st-1"
                        onChange={(e) => {
                          setFieldValue(
                            "settingIsServiceCharge",
                            e.target.checked
                          );
                          if (!e.target.checked) {
                            setFieldValue("settingServiceChargePercen", "");
                          }
                        }}
                        checked={values.settingIsServiceCharge}
                      />
                      <Form.Group className="mb-2 form-st-3 pb-1 ms-4 flex-fill percent">
                        <Form.Label className="mb-1">
                          ค่าบริการ (Service Charge){" "}
                          {values.settingIsServiceCharge && (
                            <span className="text-danger">*</span>
                          )}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="settingServiceChargePercen"
                          className="form-control"
                          placeholder="ค่าบริการ (Service Charge)"
                          value={values.settingServiceChargePercen}
                          onChange={handleChange}
                          isInvalid={
                            !!errors.settingServiceChargePercen &&
                            touched.settingServiceChargePercen
                          }
                          disabled={!values.settingIsServiceCharge}
                          onKeyPress={(e) => {
                            if (!NUMBERS_ONLY_REGEX.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          maxLength={2}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.settingServiceChargePercen}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className="d-flex align-items-center">
                      <Form.Check
                        type="switch"
                        id="vat-switch"
                        label=""
                        name="settingIsVat"
                        className="custom-switch-st-1"
                        onChange={(e) => {
                          setFieldValue("settingIsVat", e.target.checked);
                          if (!e.target.checked) {
                            setFieldValue("settingVatPercen", "");
                            setFieldValue("settingVatCalculationId", "");
                          } else {
                            setFieldValue("settingVatPercen", "7");
                          }
                        }}
                        checked={values.settingIsVat}
                      />
                      <Form.Group className="mb-2 form-st-3 pb-1 ms-4 flex-fill percent">
                        <Form.Label className="mb-1">
                          ภาษีมูลค่าเพิ่ม (VAT){" "}
                          {values.settingIsVat && (
                            <span className="text-danger">*</span>
                          )}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="settingVatPercen"
                          className="form-control"
                          placeholder="ภาษีมูลค่าเพิ่ม (VAT)"
                          value={values.settingVatPercen}
                          onChange={handleChange}
                          isInvalid={
                            !!errors.settingVatPercen &&
                            touched.settingVatPercen
                          }
                          onKeyPress={(e) => {
                            if (!NUMBERS_ONLY_REGEX.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          maxLength={2}
                          disabled={true}
                        />
                        <Form.Control.Feedback type="invalid" disabled={true}>
                          {errors.settingVatPercen}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <Form.Group className="mb-2 form-st-3 pb-1">
                      <Form.Label className="mb-1">
                        วิธีคิดภาษีมูลค่าเพิ่ม{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Select
                        name="settingVatCalculationId"
                        onChange={(e) =>
                          setFieldValue("settingVatCalculationId", e.value)
                        }
                        className={
                          !!errors.settingVatCalculationId &&
                          touched.settingVatCalculationId
                            ? "w-100 select-st-1 isInvalid"
                            : "w-100 select-st-1"
                        }
                        options={vatCalcOptions.map((item) => ({
                          ...item,
                          isDisabled: !values.settingIsVat,
                        }))}
                        placeholder="วิธีคิดภาษีมูลค่าเพิ่ม"
                        value={vatCalcOptions.filter(
                          (item) =>
                            item.value === values.settingVatCalculationId
                        )}
                        // disabled={!values.settingIsVat}
                        disabled={true}
                        isLoading={!vatCalculations}
                      />
                      {touched.settingVatCalculationId && (
                        <div className="invalid-feedback d-block">
                          {errors.settingVatCalculationId}
                        </div>
                      )}
                      {values.settingVatCalculationId && (
                        <small className="text-muted d-block mt-1">
                          {
                            vatCalcOptions.find(
                              (item) =>
                                item.value === values.settingVatCalculationId
                            )?.description
                          }
                        </small>
                      )}
                    </Form.Group>
                  </div>

                  <div className="col-xl-6 text-center d-flex align-items-center justify-content-center">
                    <Image src="/images/img2.png" width={143} />
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <div className="text-end pb-4 pe-3">
            {formDisabled ? (
              <Button
                type="button"
                className="btn-st-3 mx-3"
                onClick={() => setFormDisabled(false)}
              >
                แก้ไข
              </Button>
            ) : (
              <div className="text-end pt-3 btn-group-control">
                <Button
                  type="button"
                  className="btn-st-5 me-3"
                  onClick={() => setFormDisabled(true)}
                >
                  ยกเลิก
                </Button>
                <Button type="submit" className="btn-st-3">
                  บันทึก
                </Button>
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default BusinessSettingsTabSale;
