import React from "react";
import { useParams, Link } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { ProductForm } from "./components/ProductForm";
import { ProductHistory } from "./components/ProductHistory";
import { ProductRecipes } from "./components/ProductRecipes";
import { ProductSetting } from "./components/ProductSetting";
import { useBreadcrumb } from "../../../hooks/useBreadcrumb";
import Breadcrumb from "../../../components/Breadcrumb";

const ProductTabs = ({ productID }) => {
  const tabs = [
    { key: "product", title: "ข้อมูลสินค้า", component: ProductForm },
    ...(productID
      ? [
          { key: "foodrecipes", title: "สูตรอาหาร", component: ProductRecipes },
          {
            key: "setting",
            title: "ตั้งค่าขั้นสูง",
            component: ProductSetting,
          },
          { key: "history", title: "ประวัติ", component: ProductHistory },
        ]
      : []),
  ];

  return (
    <>
      <Nav variant="pills" className="mb-4 pills-st-1">
        {tabs.map(({ key, title }) => (
          <Nav.Item key={key}>
            <Nav.Link eventKey={key}>{title}</Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      <Tab.Content>
        {tabs.map(({ key, component: Component }) => (
          <Tab.Pane key={key} eventKey={key}>
            <Component />
          </Tab.Pane>
        ))}
      </Tab.Content>
    </>
  );
};

export function ProductManageForm() {
  const { productID } = useParams();
  const pageTitle = productID ? "แก้ไขสินค้า" : "สร้างสินค้า";
  const breadcrumbConfig = [
    { label: "หน้าหลัก", path: "/" },
    { label: "สินค้าและบริการ", path: "/products" },
    { label: productID ? "แก้ไขสินค้า" : "สร้างสินค้า" },
  ];

  useBreadcrumb(breadcrumbConfig);

  return (
    <div>
      <Breadcrumb />
      <div className="fs-24 tc-7 mb-4 lh-1 pt-2">{pageTitle}</div>
      <Tab.Container id="product-tabs" defaultActiveKey="product">
        <ProductTabs productID={productID} />
      </Tab.Container>
    </div>
  );
}
