import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import { ModalAllder } from "layouts/ModalAllder";

export function PositionModal({
  modal,
  setModal,
  onSubmit,
  isEdit = false,
  positionData,
  onDelete,
  ...props
}) {
  const schema = yup.object().shape({
    code: yup.string().required("กรุณากรอกรหัสตำแหน่งงาน"),
    name_th: yup.string().required("กรุณากรอกชื่อตำแหน่งงาน (TH)"),
    name_en: yup.string().required("กรุณากรอกชื่อตำแหน่งงาน (ENG)"),
  });

  const [initialValues, setInitialValues] = useState({
    code: "",
    name_th: "",
    name_en: "",
    is_status: true,
  });

  useEffect(() => {
    if (isEdit && positionData) {
      setInitialValues({
        id: positionData.id,
        code: positionData.code || "",
        name_th: positionData.name_th || "",
        name_en: positionData.name_en || "",
        is_status: positionData.is_status ?? true,
      });
    } else {
      setInitialValues({
        code: "",
        name_th: "",
        name_en: "",
        is_status: true,
      });
    }
  }, [isEdit, positionData]);

  return (
    <ModalAllder
      modal={modal}
      setModal={setModal}
      htitle={isEdit ? "แก้ไขตำแหน่งงาน" : "เพิ่มตำแหน่งงาน"}
      hbtn={true}
    >
      <div className="py-3">
        <div className="">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={async (values) => {
              try {
                await onSubmit({
                  id: values.id,
                  code: values.code,
                  name_th: values.name_th,
                  name_en: values.name_en,
                  is_status: values.is_status,
                });
              } catch (error) {
                console.error("Error submitting form:", error);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              touched,
              errors,
              values,
              isValid,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    รหัสตำแหน่งงาน <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="code"
                    className="form-control"
                    placeholder="รหัสตำแหน่งงาน"
                    value={values.code}
                    onChange={handleChange}
                    isInvalid={touched.code && !!errors.code}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.code}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ชื่อตำแหน่งงาน (TH) <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name_th"
                    className="form-control"
                    placeholder="ชื่อตำแหน่งงาน"
                    value={values.name_th}
                    onChange={handleChange}
                    isInvalid={touched.name_th && !!errors.name_th}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name_th}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ชื่อตำแหน่งงาน (ENG) <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name_en"
                    className="form-control"
                    placeholder="ชื่อตำแหน่งงาน"
                    value={values.name_en}
                    onChange={handleChange}
                    isInvalid={touched.name_en && !!errors.name_en}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name_en}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Label className="mb-2 tc-10">สถานะ</Form.Label>
                <div className="mb-3">
                  <Form.Check
                    inline
                    label="เปิดใช้งาน"
                    name="is_status"
                    type="radio"
                    id="status-active"
                    className="form-check-st-2 tc-7"
                    checked={values.is_status === true}
                    onChange={() => setFieldValue("is_status", true)}
                  />
                  <Form.Check
                    inline
                    label="ปิดใช้งาน"
                    name="is_status"
                    type="radio"
                    id="status-inactive"
                    className="form-check-st-2 tc-7"
                    checked={values.is_status === false}
                    onChange={() => setFieldValue("is_status", false)}
                  />
                </div>

                <div className="d-flex pt-4 pb-3 btn-group-control">
                  {isEdit && (
                    <Button
                      type="button"
                      className="btn-st-5 me-auto"
                      style={{ width: "80px" }}
                      onClick={() => {
                        onDelete();
                        props.onHide();
                      }}
                    >
                      ลบ
                    </Button>
                  )}
                  <div className="ms-auto">
                    <Button
                      type="button"
                      className="btn-st-6 me-3"
                      style={{ width: "100px" }}
                      onClick={props.onHide}
                    >
                      ยกเลิก
                    </Button>
                    <Button
                      type="submit"
                      className="btn-st-3"
                      style={{ width: "100px" }}
                      disabled={!isValid}
                    >
                      {isEdit ? "อัปเดต" : "บันทึก"}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModalAllder>
  );
}
