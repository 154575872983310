import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { branchPaymentHandler } from "../../../../../redux/handlers";
import SettingDeliveryModal from "../modals/SettingDeliveryModal";
import ConfirmDeleteDeliveryModal from "../modals/ConfirmDeleteDeliveryModal";
import SuccessDeleteModal from "../modals/SuccessDeleteModal";

export default function PaymentDeliverySetting() {
  const dispatch = useDispatch();
  const { branchID } = useParams();
  const [deliveries, setDeliveries] = useState([]);

  const paymentForm = useSelector(
    (state) => state.branchPayment.paymentForm.payment
  );
  const [enableDelivery, setEnableDelivery] = useState(
    paymentForm?.is_delivery_received || false
  );

  useEffect(() => {
    setEnableDelivery(paymentForm?.is_delivery_received || false);
  }, [paymentForm?.is_delivery_received]);

  const fetchDeliverySettings = async () => {
    try {
      const response = await dispatch(
        branchPaymentHandler.getBranchDeliverySettings(branchID)
      );
      if (response) {
        const responseData = Array.isArray(response) ? response : [response];

        const transformedData = responseData.map((item) => ({
          id: item.id,
          deliveryID: item.id,
          deliveryName: item.name,
          imagePath: item.image_path,
          status: item.is_status ? 1 : 0,
          is_active: item.is_status,
        }));

        setDeliveries(transformedData);
      }
    } catch (error) {
      console.error("Error fetching delivery settings:", error);
    }
  };

  useEffect(() => {
    if (branchID) {
      fetchDeliverySettings();
    }
  }, [branchID]);

  const handleSubmit = async () => {
    try {
      const paymentPayload = {
        ...paymentForm,
        is_delivery_received: enableDelivery,
      };

      await dispatch(
        branchPaymentHandler.updateBranchPaymentSetting(
          branchID,
          paymentPayload
        )
      );
    } catch (error) {
      console.error("Failed to update delivery settings:", error);
    }
  };

  const [formdata, setFormdata] = useState();
  const [modalcreateShow, setModalcreateShow] = useState(false);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [modalsuccessShow, setModalsuccessShow] = useState(false);

  return (
    <div>
      <div className="mb-4">
        <Form.Check
          type="switch"
          id="delivery-status"
          label="ตั้งค่าเดลิเวอรี่"
          name="status"
          className="custom-switch-st-1 tc-10 s1 mb-1"
          checked={enableDelivery}
          onChange={(e) => setEnableDelivery(e.target.checked)}
        />
      </div>

      {enableDelivery && (
        <>
          <Button
            type="button"
            className="btn btn btn-st-7 icon-plus mb-4"
            onClick={() => {
              setModalcreateShow(true);
              setFormdata({
                id: null,
                deliveryID: "",
                deliveryName: "",
                imagePath: "",
                status: 1,
              });
            }}
          >
            เพิ่มเดลิเวอรี่
          </Button>

          <div className="d-flex flex-wrap">
            {deliveries.map((item) => (
              <div
                className={
                  item.status === 0
                    ? "deliveryStatus0 d-flex align-items-center px-3 py-2 me-3 mb-3"
                    : "d-flex align-items-center px-3 py-2 me-3 mb-3"
                }
                key={item.id}
                style={{
                  minWidth: "283px",
                  borderRadius: "5px",
                  border: "1px solid rgba(76, 78, 100, 0.22)",
                }}
              >
                <div className="logo-delivery me-3">
                  <img
                    src={item.imagePath}
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "15%",
                      objectFit: "cover",
                    }}
                    alt={item.deliveryName}
                    onError={(e) => {
                      e.target.src = "/assets/images/default-delivery.png";
                    }}
                  />
                </div>
                <div className="flex-fill">
                  <div style={{ color: "#2C2C2C" }}>{item.deliveryName}</div>
                </div>
                <div className="ms-2">
                  <button
                    type="button"
                    className="btn-edit-st-1"
                    onClick={() => {
                      setModalcreateShow(true);
                      setFormdata(item);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      <div className="text-end mt-4 pt-5 pb-2 btn-group-control">
        <Button
          type="submit"
          className="btn-st-3"
          style={{ width: "100px" }}
          onClick={handleSubmit}
        >
          บันทึก
        </Button>
      </div>

      <SettingDeliveryModal
        show={modalcreateShow}
        onHide={() => setModalcreateShow(false)}
        handleDelete={() => {
          setModalDeleteShow(true);
          setModalcreateShow(false);
        }}
        onSuccess={() => {
          setModalcreateShow(false);
          fetchDeliverySettings();
        }}
        formdata={formdata}
        branchID={branchID}
      />

      <ConfirmDeleteDeliveryModal
        show={modalDeleteShow}
        onHide={() => setModalDeleteShow(false)}
        onSuccess={() => {
          setModalsuccessShow(true);
          fetchDeliverySettings();
        }}
        formdata={formdata}
      />

      <SuccessDeleteModal
        show={modalsuccessShow}
        onHide={() => setModalsuccessShow(false)}
      />
    </div>
  );
}
