import React, { useEffect } from "react";
import { ModalAllder } from "layouts/ModalAllder";

export function SuccessDeleteModal({ modal, setModal, msg, onHide }) {
  useEffect(() => {
    // ถ้า modal เปิดอยู่
    if (modal.open) {
      // ตั้ง timeout 2 วินาที แล้วปิด modal
      const timer = setTimeout(() => {
        setModal(prev => ({ ...prev, open: false }));
      }, 2000);

      // cleanup function เพื่อป้องกัน memory leak
      return () => clearTimeout(timer);
    }
  }, [modal.open]); // trigger effect เมื่อ modal.open มีการเปลี่ยนแปลง

  return (
    <ModalAllder
      modal={modal}
      setModal={setModal}
      htitle={""}
      hbtn={false}
    >
      <div className="px-3 py-4">
        <div className="text-center mb-4">
          <svg
            width="84"
            height="83"
            className="mx-auto"
            viewBox="0 0 84 83"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="42" cy="41.5" r="41.5" fill="#2DC76D" />
            <path
              d="M56.0123 30.9902L36.7444 50.2581L27.9863 41.5"
              stroke="white"
              strokeWidth="5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="text-center fs-22 tc-7">
          {msg || "ลบแผนกสำเร็จ"}
        </div>
      </div>
    </ModalAllder>
  );
}