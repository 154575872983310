import React, { useMemo, useCallback } from "react";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  gridPageSizeSelector,
  useGridApiContext,
  useGridSelector,
  gridPaginationRowRangeSelector,
  gridPaginationRowCountSelector,



} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from "react-select";

const pageSizeOption = [
  {
    value: '5',
    label: '5',
  },
  {
    value: '10',
    label: '10',
  },
  {
    value: '15',
    label: '15',
  },
  {
    value: '20',
    label: '20',
  },
  {
    value: '50',
    label: '50',
  },
  {
    value: '100',
    label: '100',
  }
];



function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  const pageRange = useGridSelector(apiRef, gridPaginationRowRangeSelector);

  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const RowCount = useGridSelector(apiRef, gridPaginationRowCountSelector);

  // const itemCount = useGridSelector(apiRef, gridItemCountSelector);

  return (
    <div className='flex  px-4 items-center'>
      <div className='text-[#4C4E64]/[0.67] font-normal mr-4'>จำนวนรายการต่อหน้า</div>
      <div className='mr-4'>

        <Select
          menuPlacement="top"
          name="pageSize"
          onChange={(selectedOption) => {
            apiRef.current.setPageSize(selectedOption.value)
          }}

          className={`w-100 `}
          options={pageSizeOption}
          placeholder=""
          value={pageSizeOption.find((item) => item.value == pageSize)}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              border: 0,
            }),
            indicatorSeparator: (baseStyles, state) => ({
              ...baseStyles,
              display: 'none',

            }),
          }}

        />
      </div>

      <div className='text-[#4C4E64]/[0.67] font-normal mr-4'>{pageRange && pageRange.firstRowIndex + 1}-{pageRange && pageRange.lastRowIndex + 1} of {RowCount}</div>

      <Pagination
        sx={(theme) => ({ padding: theme.spacing(1.5, 0), '.MuiPaginationItem-root.Mui-selected': { backgroundColor: "#2DC76D", color: "#FFF" } })}

        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    </div>
  );
}

export const AllderTable = React.memo(function AllderTable({
  data,
  loading,
  columns,
  totalRows,
  paginationModel,
  setPaginationModel,
  handleSort,
  pageSizeOptions = [5, 20, 30],
  customStyles = {},
  rowSelectionEnabled = true,
  ...props
}) {
  const styles = useMemo(
    () => ({
      fontFamily: "Sarabun",
      fontSize: "17px",
      ".MuiDataGrid-columnHeaderTitle": {
        fontFamily: "Sarabun",
        fontSize: "17px",
      },
      ".MuiDataGrid-cell": {
        display: "flex",
        alignItems: "center",
        paddingTop: "5px",
        paddingBottom: "5px",
      },
      ...customStyles,
    }),
    [customStyles]
  );

  const paginationProps = useMemo(
    () => ({
      paginationModel,
      onPaginationModelChange: setPaginationModel,
      pageSizeOptions,
      paginationMode: "server",
      rowCount: totalRows,
    }),
    [paginationModel, setPaginationModel, pageSizeOptions, totalRows]
  );

  const slotProps = useMemo(
    () => ({
      pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
    }),
    []
  );

  const getRowHeight = useCallback(() => "auto", []);

  const getRowId = useCallback((row) => row.id, []);


  // const [paginationModel, setPaginationModel] = React.useState({
  //   pageSize: 10,
  //   page: 0,
  // });


  return (
    <DataGrid
      loading={loading}
      getRowId={getRowId}
      getRowHeight={getRowHeight}
      rows={data}
      columns={columns}
      {...paginationProps}
      sortingMode="server"
      onSortModelChange={handleSort}
      checkboxSelection={rowSelectionEnabled}
      disableRowSelectionOnClick={rowSelectionEnabled}
      slotProps={slotProps}
      sx={styles}
      slots={{
        pagination: CustomPagination,
      }}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      {...props}
    />
  );
});

AllderTable.displayName = "AllderTable";

export default AllderTable;
