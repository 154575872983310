import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { ModalAllder } from "layouts/ModalAllder";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import { useDispatch } from "react-redux";
import { userBusinessGroupHandler } from "../../../../../redux/handlers";

dayjs.extend(buddhistEra);
dayjs.locale("th");

const formatThaiDate = (date) => {
  return dayjs(date).format("DD/MM/BBBB");
};

const schema = yup.object().shape({
  subcategoryCode: yup.string().required("กรุณากรอกรหัสหมวดหมู่ย่อย"),
  subcategoryName: yup.string().required("กรุณากรอกชื่อหมวดหมู่ย่อย"),
  productCategoryId: yup.string().required("กรุณาเลือกหมวดหมู่หลัก"),
});

const DEFAULT_INITIAL_VALUES = {
  subcategoryCode: "",
  subcategoryName: "",
  productCategoryId: "",
  isDisplay: true,
  id: null,
  dateCreate: formatThaiDate(new Date()),
  userCreate: "",
};

export default function SubCategoryModal({
  modal,
  setModal,
  initialData,
  mode = "create",
  onSubmit,
  categoryOptions = [],
  ...props
}) {
  const dispatch = useDispatch();
  const isEditMode = mode === "edit";
  const [userName, setUserName] = useState("");

  const fetchUserProfile = async () => {
    try {
      const response = await dispatch(
        userBusinessGroupHandler.getUserBusinessProfile()
      );
      if (response) {
        const { first_name, last_name } = response;
        setUserName(`${first_name} ${last_name}`);
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  useEffect(() => {
    if (!isEditMode) {
      fetchUserProfile();
    }
  }, [isEditMode]);

  const initialValues = isEditMode
    ? {
        subcategoryCode: initialData?.code || "",
        subcategoryName: initialData?.name || "",
        productCategoryId: initialData?.productCategory?.id || "",
        isDisplay: initialData?.is_display ?? true,
        id: initialData?.id || null,
        dateCreate: formatThaiDate(initialData?.created_at),
        userCreate: initialData?.userBusinessGroup
          ? `${initialData.userBusinessGroup.first_name} ${initialData.userBusinessGroup.last_name}`
          : "",
      }
    : {
        ...DEFAULT_INITIAL_VALUES,
        userCreate: userName,
      };

  return (
    <ModalAllder
      modal={modal}
      setModal={setModal}
      htitle={isEditMode ? "แก้ไขหมวดหมู่ย่อย" : "เพิ่มหมวดหมู่ย่อย"}
      hbtn={true}
    >
      <div className="py-3">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            const submissionData = {
              ...values,
              date: new Date().toISOString(),
            };
            await onSubmit(submissionData);
            if (!isEditMode) {
              resetForm({ values: DEFAULT_INITIAL_VALUES });
            }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            touched,
            errors,
            values,
            setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3 form-st-3 pb-1">
                <Form.Label className="mb-1">
                  รหัสหมวดหมู่ย่อย <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="subcategoryCode"
                  placeholder="รหัสหมวดหมู่ย่อย"
                  value={values.subcategoryCode}
                  onChange={handleChange}
                  isInvalid={touched.subcategoryCode && errors.subcategoryCode}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.subcategoryCode}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3 form-st-3 pb-1">
                <Form.Label className="mb-1">
                  เลือกหมวดหมู่หลัก <span className="text-danger">*</span>
                </Form.Label>
                <Select
                  name="productCategoryId"
                  value={categoryOptions.find(
                    (option) => option.value === values.productCategoryId
                  )}
                  onChange={(selected) =>
                    setFieldValue("productCategoryId", selected.value)
                  }
                  options={categoryOptions}
                  className="select-st-1"
                  placeholder="เลือกหมวดหมู่หลัก"
                  isInvalid={
                    touched.productCategoryId && errors.productCategoryId
                  }
                />
                {touched.productCategoryId && errors.productCategoryId && (
                  <div className="invalid-feedback d-block">
                    {errors.productCategoryId}
                  </div>
                )}
              </Form.Group>

              <Form.Group className="mb-3 form-st-3 pb-1">
                <Form.Label className="mb-1">
                  ชื่อหมวดหมู่ย่อย <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="subcategoryName"
                  placeholder="ชื่อหมวดหมู่ย่อย"
                  value={values.subcategoryName}
                  onChange={handleChange}
                  isInvalid={touched.subcategoryName && errors.subcategoryName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.subcategoryName}
                </Form.Control.Feedback>
              </Form.Group>

              <div className="mb-3">
                <Form.Check
                  type="switch"
                  id="display-switch"
                  label="แสดงหน้าจอลูกค้า"
                  name="isDisplay"
                  className="custom-switch-st-1 tc-10 s2"
                  checked={values.isDisplay}
                  onChange={(e) => {
                    setFieldValue("isDisplay", e.target.checked);
                  }}
                />
              </div>

              <div className="row">
                <div className="col-6">
                  <Form.Group className="mb-3 form-st-3 pb-1">
                    <Form.Label className="mb-1">วันที่สร้าง</Form.Label>
                    <Form.Control
                      type="text"
                      value={values.dateCreate}
                      disabled
                    />
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="mb-3 form-st-3 pb-1">
                    <Form.Label className="mb-1">ชื่อผู้สร้าง</Form.Label>
                    <Form.Control
                      type="text"
                      value={values.userCreate}
                      disabled
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="d-flex justify-content-between pt-2 pb-3 btn-group-control">
                {isEditMode && (
                  <Button
                    type="button"
                    className="btn-st-5"
                    style={{ width: "80px" }}
                    onClick={() => {
                      props.onDelete();
                      props.onHide();
                    }}
                  >
                    ลบ
                  </Button>
                )}
                <div className="ms-auto">
                  <Button
                    type="button"
                    className="btn-st-6 me-3"
                    style={{ width: "100px" }}
                    onClick={props.onHide}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="submit"
                    className="btn-st-3"
                    style={{ width: "100px" }}
                  >
                    {isEditMode ? "บันทึก" : "เพิ่ม"}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </ModalAllder>
  );
}
