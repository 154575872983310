import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useGlobalTable } from "../../../hooks/useGlobalTable";
import { AllderTable } from "../../../components/AllderTable";
import { createTableColumns } from "../../../utils/createTableColumns";
import { UnitFormModal } from "./components/UnitFormModal";
import { ConfirmDeleteModal } from "./components/ConfirmDeleteModal";
import { SuccessDeleteModal } from "./components/SuccessDeleteModal";
import { settingProductUnitHandler } from "../../../redux/handlers";
import { useBreadcrumb } from "../../../hooks/useBreadcrumb";
import Breadcrumb from "../../../components/Breadcrumb";

const INITIAL_SELECTED_UNIT = {
  id: "",
  name: "",
};

const INITIAL_MODAL_STATE = {
  open: false,
  x: 0,
  y: 0,
  width: 470,
  pin: false,
  mode: "normal",
};

const MODAL_HEIGHTS = {
  FORM: 280,
  CONFIRM: 320,
  SUCCESS: 300,
};

const MODAL_STATES = {
  CREATE: { ...INITIAL_MODAL_STATE, height: MODAL_HEIGHTS.FORM },
  EDIT: { ...INITIAL_MODAL_STATE, height: MODAL_HEIGHTS.FORM },
  CONFIRM: { ...INITIAL_MODAL_STATE, height: MODAL_HEIGHTS.CONFIRM },
  SUCCESS: { ...INITIAL_MODAL_STATE, height: MODAL_HEIGHTS.SUCCESS },
};

export function ProductSaleUnit() {
  const dispatch = useDispatch();
  const { businessSlug } = useParams();
  const [selectedUnit, setSelectedUnit] = useState(INITIAL_SELECTED_UNIT);
  const [successMsg, setSuccessMsg] = useState("");
  const [modalCreate, setModalCreate] = useState(MODAL_STATES.CREATE);
  const [modalEdit, setModalEdit] = useState(MODAL_STATES.EDIT);
  const [modalConfirmDelete, setModalConfirmDelete] = useState(
    MODAL_STATES.CONFIRM
  );
  const [modalSuccess, setModalSuccess] = useState(MODAL_STATES.SUCCESS);
  const breadcrumbConfig = [
      { label: "หน้าหลัก", path: "/" },
      { label: "ตั้งค่า", path: "#" },
      { label: "ตั้งค่าคลังสินค้า", path: "#" },
      { label: "หน่วยขาย" },
    ];
    useBreadcrumb(breadcrumbConfig);

  const fetchUnits = useCallback(
    (params) =>
      dispatch(
        settingProductUnitHandler.getProductSaleUnits({
          business_slug: businessSlug,
          ...params,
        })
      ),
    [businessSlug, dispatch]
  );

  const {
    data: units,
    loading,
    totalRows,
    paginationModel,
    setPaginationModel,
    handleSort,
    debouncedSearch,
    refreshData: refreshUnits,
    filterParams,
  } = useGlobalTable({
    fetchData: fetchUnits,
    defaultSort: "created_at",
    defaultOrder: "desc",
    defaultPageSize: 20,
  });

  const handleCreateUnit = async (values) => {
    try {
      await dispatch(
        settingProductUnitHandler.createProductSaleUnit({
          businessSlug,
          name: values.unitName,
        })
      );
      refreshUnits();
      setSuccessMsg("บันทึกหน่วยขายสำเร็จ");
      setModalCreate((prev) => ({ ...prev, open: false }));
      setModalSuccess((prev) => ({ ...prev, open: true }));
      setSelectedUnit(INITIAL_SELECTED_UNIT);
    } catch (error) {
      console.error("Error creating unit:", error);
    }
  };

  const handleUpdateUnit = async (values) => {
    try {
      await dispatch(
        settingProductUnitHandler.updateProductSaleUnit(values.id, {
          businessSlug,
          name: values.unitName,
        })
      );
      refreshUnits();
      setSuccessMsg("แก้ไขหน่วยขายสำเร็จ");
      setModalEdit((prev) => ({ ...prev, open: false }));
    } catch (error) {
      console.error("Error updating unit:", error);
    }
  };

  const handleDelete = (row) => {
    setSuccessMsg("ลบหน่วยขายสำเร็จ");
    setModalEdit((prev) => ({ ...prev, open: false }));
    setSelectedUnit(row);
    setModalConfirmDelete((prev) => ({ ...prev, open: true }));
  };

  const handleConfirmDelete = async () => {
    try {
      await dispatch(
        settingProductUnitHandler.deleteProductSaleUnit(selectedUnit.id)
      );
      refreshUnits();
      setModalConfirmDelete((prev) => ({ ...prev, open: false }));
      setModalSuccess((prev) => ({ ...prev, open: true }));
    } catch (error) {
      console.error("Error deleting unit:", error);
    }
  };

  const handleExport = async () => {
    try {
      await dispatch(
        settingProductUnitHandler.exportProductSaleToExcel(
          businessSlug,
          filterParams.s
        )
      );
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const columns = createTableColumns(
    [
      {
        field: "name",
        headerName: "ชื่อหน่วยขาย",
        width: 170,
        sortable: true,
        flex: 1,
        renderCell: ({ row }) => (
          <div style={{ color: "#2DC76D", fontWeight: 500 }}>{row.name}</div>
        ),
      },
      {
        field: "productQty",
        headerName: "จำนวนสินค้าที่ใช้งาน",
        flex: 1,
        width: 140,
        sortable: false,
        align: "center",
        headerAlign: "center",
        renderCell: ({ row }) => <div>{row.productQty || 0}</div>,
      },
      {
        field: "is_active",
        headerName: "สถานะ",
        width: 180,
        sortable: false,
        align: "center",
        headerAlign: "center",
        type: "status",
      },
      {
        field: "actions",
        headerName: "จัดการ",
        width: 250,
        sortable: false,
        type: "actions",
      },
    ],
    (row) => (
      <div>
        <Button
          type="button"
          className="btn-edit-st-1"
          onClick={() => {
            setSelectedUnit(row);
            setModalEdit((prev) => ({ ...prev, open: true }));
          }}
        />
        <Button
          type="button"
          className="btn-delete-st-3 ml-2"
          onClick={() => handleDelete(row)}
        />
      </div>
    )
  );

  return (
    <div>
      <Breadcrumb />
      <div className="fs-24 tc-7 mb-4 lh-1">จัดการหน่วยขาย</div>
      <div className="card card-st-2">
        <div className="card-body px-0 py-4">
          <div className="d-flex align-items-center px-4 mb-4">
            <button
              className="btn btn-export-st-1 me-auto"
              onClick={handleExport}
            >
              Export
            </button>
            <div className="me-3">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหาชื่อหน่วยขาย"
                onChange={(e) => debouncedSearch(e.target.value)}
              />
            </div>
            <Button
              type="button"
              className="btn btn-st-7"
              onClick={() => {
                setModalCreate((prev) => ({ ...prev, open: true }));
              }}
            >
              เพิ่มหน่วยขาย
            </Button>
          </div>

          <AllderTable
            data={units}
            loading={loading}
            columns={columns}
            totalRows={totalRows}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            handleSort={handleSort}
          />
        </div>
      </div>

      <UnitFormModal
        modal={modalCreate}
        setModal={setModalCreate}
        isEdit={false}
        onSubmit={handleCreateUnit}
      />

      <UnitFormModal
        modal={modalEdit}
        setModal={setModalEdit}
        isEdit={true}
        unitFormData={selectedUnit}
        onSubmit={handleUpdateUnit}
        onDelete={() => handleDelete(selectedUnit)}
      />

      <ConfirmDeleteModal
        modal={modalConfirmDelete}
        setModal={setModalConfirmDelete}
        onHide={() =>
          setModalConfirmDelete((prev) => ({ ...prev, open: false }))
        }
        onConfirm={handleConfirmDelete}
        onSuccess={() => setModalSuccess((prev) => ({ ...prev, open: true }))}
        unitFormData={selectedUnit}
      />

      <SuccessDeleteModal
        modal={modalSuccess}
        setModal={setModalSuccess}
        msg={successMsg}
        onHide={() => setModalSuccess((prev) => ({ ...prev, open: false }))}
      />
    </div>
  );
}
