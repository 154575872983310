import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
import { ModalAllder } from "layouts/ModalAllder";

export default function ProductAnnotationModal({
  modal,
  setModal,
  productOptionDetail = {
    optionDetail: { name: "", isVisible: false },
  },
  setProductOptionDetail,
  onSubmit,
  ...props
}) {
  const handleSave = () => {
    onSubmit(productOptionDetail);
  };

  return (
    <ModalAllder
      modal={modal}
      setModal={setModal}
      htitle={"แก้ไขรายการหมายเหตุ"}
      hbtn={true}
    >
      <fieldset>
        <div className="row">
          <div className="col-12">
            <Form.Group className="mb-4 form-st-3 pb-1">
              <Form.Label className="mb-1">ชื่อรายการ</Form.Label>
              <Form.Control
                type="text"
                name="listName"
                className="form-control"
                placeholder="กรอกชื่อรายการ"
                value={productOptionDetail?.optionDetail?.name || ""}
                disabled={true}
              />
            </Form.Group>
          </div>

          <div className="col-12">
            <div className="pt-2 pb-1 px-3">
              <Form.Check
                type="switch"
                id="display-annotation-menu"
                label="แสดงในเมนู"
                name="annotationStatus"
                className="custom-switch-st-1 s1 mb-1"
                checked={productOptionDetail?.optionDetail?.isVisible || false}
                onChange={(e) => {
                  setProductOptionDetail((prev) => ({
                    ...prev,
                    optionDetail: {
                      ...prev?.optionDetail,
                      isVisible: e.target.checked,
                    },
                  }));
                }}
              />
            </div>
          </div>
        </div>
      </fieldset>

      <div className="text-end pb-4 pt-4 pe-3">
        <div className="d-flex justify-content-end align-items-center pt-3 btn-group-control">
          <Button
            className="btn btn-st-6 ml-auto me-3 bg-transparent"
            onClick={() => setModal((prev) => ({ ...prev, open: false }))}
          >
            ยกเลิก
          </Button>
          <Button type="button" className="btn-st-3" onClick={handleSave}>
            บันทึก
          </Button>
        </div>
      </div>
    </ModalAllder>
  );
}
