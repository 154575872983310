import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { ModalAllder } from "layouts/ModalAllder";
import { Formik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  name: yup.string().required("กรุณากรอกชื่อตัวเลือก"),
});

export default function OptionProductSpecificModal({
  modal,
  setModal,
  onSubmit,
  productOptionDetail,
}) {
  const [initialValues, setInitialValues] = useState({
    name: "",
  });

  useEffect(() => {
    if (modal.mode === "edit" && productOptionDetail) {
      setInitialValues({
        name: productOptionDetail.name,
      });
    } else {
      setInitialValues({
        name: "",
      });
    }
  }, [modal.mode, productOptionDetail]);

  const handleClose = () => {
    setModal((prev) => ({ ...prev, open: false }));
  };

  const handleSubmit = (values, { setSubmitting }) => {
    if (modal.mode === "edit") {
      onSubmit({
        id: productOptionDetail.id,
        name: values.name,
      });
    } else {
      onSubmit(values);
    }
    handleClose();
    setSubmitting(false);
  };

  return (
    <ModalAllder
      modal={modal}
      setModal={setModal}
      htitle={
        modal.mode === "edit" ? "แก้ไขตัวเลือกเฉพาะ" : "เพิ่มตัวเลือกเฉพาะ"
      }
      hbtn={true}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          isSubmitting,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="px-4">
              <div className="text-end pb-3 pt-4 mt-4 pe-3">
                <Form.Group className="mb-2 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ชื่อตัวเลือก <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="กรอกชื่อตัวเลือก"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.name && !!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="text-end pb-4 pt-4 pe-3">
                <div className="d-flex justify-end align-items-center pt-3 btn-group-control">
                  <Button
                    type="button"
                    className={`btn btn-st-6 me-3 ${
                      modal.mode === "create" ? "ms-auto" : ""
                    } bg-transparent`}
                    onClick={handleClose}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="submit"
                    className="btn-st-3"
                    disabled={isSubmitting}
                  >
                    บันทึก
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ModalAllder>
  );
}
