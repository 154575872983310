import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams, NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import Form from "react-bootstrap/Form";

import Collapse from "react-bootstrap/Collapse";
import { utils, writeFileXLSX } from "xlsx";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AutoTabProvider } from "react-auto-tab";
import {
  ThailandAddressTypeahead,
  ThailandAddressValue,
} from "react-thailand-address-typeahead";

import { ModalAllder } from "layouts/ModalAllder";
import { DataGrid } from "@mui/x-data-grid";

export default function SuccessModal(props) {
  return (
    <Modal {...props} size="sm" centered>
      <Modal.Body>
        <div className="px-3 py-4">
          <div className="text-center mb-4">
            <svg
              width="84"
              height="83"
              className="mx-auto"
              viewBox="0 0 84 83"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="42" cy="41.5" r="41.5" fill="#2DC76D" />
              <path
                d="M56.0123 30.9902L36.7444 50.2581L27.9863 41.5"
                stroke="white"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="text-center fs-18 tc-7">{props.textsuccess}</div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
