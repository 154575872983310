import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { productHandler } from "../../../../redux/handlers";
import { STATUS } from "../../../../constants/server/api";
import {
  OptionAnnotationModal,
  ConfirmDeleteModal,
  SuccessModal,
} from "./modals";

const INITIAL_MODAL_STATE = {
  open: false,
  x: 0,
  y: 0,
  width: 500,
  height: 340,
  pin: false,
  mode: "normal",
  modalname: "modal_edit_annotation_product",
};

export function OptionAnnotation() {
  const { productID, businessSlug } = useParams();
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product.product);

  const [optionOpen, setOptionOpen] = useState(product?.is_annotation || false);
  const [annotations, setAnnotations] = useState([]);
  const [listAnnotations, setListAnnotations] = useState([]);
  const [modalEdit, setModalEdit] = useState(INITIAL_MODAL_STATE);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [formdisabled, setFormdisabled] = useState(true);

  useEffect(() => {
    const fetchInitialData = async () => {
      if (!businessSlug || !productID) return;

      await fetchAnnotationRelations();
      await fetchAnnotation();
    };

    fetchInitialData();
  }, [productID, businessSlug, dispatch]);

  const fetchAnnotation = async () => {
    const annotationsResponse = await dispatch(
      productHandler.getAllProductAnnotationOptions({
        businessSlug,
        productId: productID,
        pageQuery: "productInclude",
      })
    );

    if (annotationsResponse) {
      setAnnotations(annotationsResponse);
    }
  };
  useEffect(() => {
    if (product) {
      setOptionOpen(product.is_annotation);
    }
  }, [product]);

  const fetchAnnotationRelations = async () => {
    if (!productID) return;

    const relationResponse = await dispatch(
      productHandler.getProductAnnotationRelations(productID)
    );

    if (!relationResponse) return;

    const formattedData = relationResponse.map((item) => ({
      id: item.id,
      name: item.productAnnotation.name,
      annotation_items: item.productAnnotation.productAnnotationItem || [],
    }));

    setListAnnotations(formattedData);
    if (formattedData.length > 0) {
      setOptionOpen(true);
    }
  };

  const handleOptionSwitchChange = async (e) => {
    const isChecked = e.target.checked;
    setOptionOpen(isChecked);

    try {
      await dispatch(
        productHandler.updateProductAnnotationStatus(productID, isChecked)
      );
    } catch (error) {
      console.error("Error updating product annotation status:", error);

      setOptionOpen(!isChecked);
    }
  };

  const handleSubmitAnnotation = async (selectedAnnotation) => {
    if (formdisabled) return;

    try {
      const response = await dispatch(
        productHandler.createProductAnnotationRelation({
          product_id: productID,
          product_annotation_id: selectedAnnotation.id,
        })
      );

      if (response) {
        await fetchAnnotationRelations();
        await fetchAnnotation();
        setSuccessMessage("เพิ่มคำอธิบายมาตรฐานสำเร็จ");
        setShowSuccessModal(true);
      }
    } catch (error) {
      console.error("Error creating product annotation relation:", error);
    }
  };

  const handleDeleteConfirm = (id) => {
    if (formdisabled) return;

    setDeleteTarget({ id });
    setShowDeleteConfirm(true);
  };

  const handleDeleteConfirmed = async () => {
    if (!deleteTarget) return;

    try {
      const response = await dispatch(
        productHandler.deleteProductAnnotationRelation(deleteTarget.id)
      );

      if (response === STATUS.OK) {
        await fetchAnnotationRelations();
        setShowDeleteConfirm(false);
        await fetchAnnotation();
        setSuccessMessage("ลบคำอธิบายมาตรฐานสำเร็จ");
        setShowSuccessModal(true);
      }
    } catch (error) {
      console.error("Error deleting product annotation relation:", error);
    }
  };

  const renderAnnotationItems = (items = []) => {
    return items.map((item, index) => (
      <div className="col-xl-4 row gx-4 align-items-center" key={index}>
        <div className="col">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">รายการ</Form.Label>
            <Form.Control
              type="text"
              name="listName"
              className="form-control"
              placeholder="รายการ"
              value={item.name}
              disabled
            />
          </Form.Group>
        </div>
      </div>
    ));
  };

  const renderAnnotationList = () => {
    return listAnnotations.map((item, index) => (
      <div className="row gx-4 align-items-center mb-5" key={index}>
        <div className="col-xl-8 col-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">คำอธิบายมาตรฐาน</Form.Label>
            <Form.Control
              type="text"
              name="annotation"
              className="form-control"
              placeholder="คำอธิบายมาตรฐาน"
              value={item.name}
              disabled
            />
          </Form.Group>
        </div>
        <div className="col-xl-4 col-6">
          <button
            type="button"
            className="btn btn-st-3"
            onClick={() => handleDeleteConfirm(item.id)}
            style={{ background: "#F95057", borderColor: "#F95057" }}
            disabled={formdisabled}
          >
            ลบคำอธิบายสินค้า
          </button>
        </div>
        {renderAnnotationItems(item.annotation_items)}
      </div>
    ));
  };

  return (
    <div className="card card-st-3 mb-3">
      <div className="card-body px-xl-3">
        <div className="pt-2 pb-1 px-3">
          <Form.Check
            type="switch"
            id="custom-sdx"
            label="กำหนดตัวเลือกหมายเหตุ"
            name="annotationStatus"
            className="custom-switch-st-1 s1 mb-1"
            checked={optionOpen}
            onChange={handleOptionSwitchChange}
          />
          <span className="ms-2" style={{ color: "#858796" }}>
            ( ใช้ข้อมูลจากตัวเลือกหมายเหตุที่สร้างไว้ )
          </span>
        </div>

        {optionOpen && (
          <div className="px-5 py-4">
            <div className="row">
              <div className="col-xl-6 col">
                <div className="mb-2 mt-4">
                  <button
                    type="button"
                    className="btn btn-st-3 mb-4"
                    onClick={() =>
                      setModalEdit((prev) => ({ ...prev, open: true }))
                    }
                    disabled={formdisabled}
                  >
                    เพิ่มคำอธิบายมาตรฐาน
                  </button>
                </div>
              </div>
            </div>

            <div className="ms-xl-4 mt-2 ps-2">{renderAnnotationList()}</div>
          </div>
        )}

        {optionOpen && (
          <div className="text-end pe-3 mt-4 pb-4">
            {formdisabled ? (
              <Button
                type="button"
                className="btn-st-3"
                onClick={() => setFormdisabled(false)}
              >
                แก้ไข
              </Button>
            ) : (
              <Button
                type="button"
                className="btn-st-6"
                onClick={() => setFormdisabled(true)}
              >
                ยกเลิก
              </Button>
            )}
          </div>
        )}
      </div>

      <OptionAnnotationModal
        modal={modalEdit}
        setModal={setModalEdit}
        optionannotation={annotations}
        onSubmit={handleSubmitAnnotation}
        disabled={formdisabled}
      />

      <ConfirmDeleteModal
        show={showDeleteConfirm}
        onHide={() => setShowDeleteConfirm(false)}
        onSuccess={handleDeleteConfirmed}
        deleteTarget={deleteTarget}
      />

      <SuccessModal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        textsuccess={successMessage}
      />
    </div>
  );
}
