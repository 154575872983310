import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import ProductSelectModal from "layouts/ModalProductSelect";
import SelectCategory from "fragments/SelectCategory";
import { DataGrid } from "@mui/x-data-grid";
export default function ProductDataList() {
  const [modalproductlistShow, setModalproductlistShow] = useState({
    open: false,
    x: 0,
    y: 0,
    width: 1270,
    height: 680,
    pin: false,
    mode: "normal",
    modalname: "edit",
  });
  const [productdata, setProductdata] = useState([
    {
      productID: 1,
      productCode: "16586235641",
      productName: "ต้มยำกุ้ง",
      category: "กับข้าว",
      subcategory: "กับข้าว",
    },
    {
      productID: 1,
      productCode: "16586235641",
      productName: "กระเพราหมูสับ",
      category: "ราดข้าว",
      subcategory: "กับข้าว",
    },
    {
      productID: 1,
      productCode: "16586235641",
      productName: "หมูแดดเดียวทอด",
      category: "กับข้าว",
      subcategory: "กับข้าว",
    },
  ]);

  const columnsd = [
    {
      field: "productCode",
      headerName: "รหัสสินค้า",
      width: 170,
      sortable: false,
      renderCell: ({ row }) => (
        <div style={{ fontWeight: 500 }}>{row.productCode}</div>
      ),
    },

    {
      field: "productName",
      headerName: "ชื่อรายการ",
      width: 340,
      sortable: false,
      renderCell: ({ row }) => (
        <div style={{ fontWeight: 500 }}>{row.productName}</div>
      ),
    },
    {
      field: "category",
      headerName: "หมวดหมู่หลัก",
      width: 180,
      sortable: false,
    },
    {
      field: "subcategory",
      headerName: "หมวดหมู่ย่อย",
      width: 180,
      sortable: false,
    },
    {
      field: "productID",
      headerName: "จัดการ",
      width: 120,

      sortable: false,
      renderCell: ({ row }) => (
        <div>
          <Button
            type="button"
            className="btn-delete-st-3 "
            onClick={() => handleDelete(row)}
          ></Button>
        </div>
      ),
    },
  ];

  const columns = [
    {
      name: "รหัสสินค้า",
      selector: (row) => row.productCode,
    },
    {
      name: "ชื่อรายการ",
      selector: (row) => row.productName,
    },
    {
      name: "หมวดหมู่หลัก",
      selector: (row) => row.category,
    },
    {
      name: "หมวดหมู่ย่อย",
      selector: (row) => row.subcategory,
    },

    {
      name: "จัดการ",
      selector: (row) => row.categoryID,
      center: "true",
      cell: (row, index, column, id) => (
        <div>
          <Button
            type="button"
            className="btn-delete-st-3 "
            onClick={() => handleDelete(row)}
          ></Button>
        </div>
      ),
    },
  ];

  const [filterText, setFilterText] = useState("");
  const filteredItems = productdata.filter(
    (item) =>
      item.productCode &&
      item.productCode.toLowerCase().includes(filterText.toLowerCase()),
    (item) =>
      item.productName &&
      item.productName.toLowerCase().includes(filterText.toLowerCase())
  );

  const AddProduct = (product) => {
    setModalproductlistShow(false);
  };

  const handleDelete = (item) => {};

  return (
    <div>
      <div className="card card-st-2">
        <div className="card-body px-0 py-4">
          <div className="d-flex align-items-center px-4 mb-4">
            <div className="tc-7 fs-20 fw-6   me-auto">
              สินค้าที่ไม่ร่วมรายการ
            </div>
            <Button
              type="button"
              className="btn btn btn-st-7"
              onClick={() =>
                setModalproductlistShow((prev) => ({ ...prev, open: true }))
              }
            >
              เพิ่มสินค้า
            </Button>
          </div>

          <div className="d-flex align-items-center  px-4  mb-4 ">
            <div className="me-auto" style={{ flex: "0 0 220px" }}>
              <SelectCategory setFilterText={setFilterText} />
            </div>
            <div className="" style={{ flex: "0 0 220px" }}>
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหาหมวดหมู่"
                value={filterText}
                onKeyUp={(e) => setFilterText(e.target.value)}
              />
            </div>
          </div>

          <DataGrid
            getRowId={(row) => row.productID}
            getRowHeight={() => "auto"}
            rows={filteredItems}
            columns={columnsd}
            // autoHeight={true}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 20, 30]}
            checkboxSelection
            disableRowSelectionOnClick
            slotProps={{
              pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
            }}
            sx={{
              fontFamily: "Sarabun",
              fontSize: "17px",
              ".MuiDataGrid-columnHeaderTitle": {
                fontFamily: "Sarabun",
                fontSize: "17px",
              },
              ".MuiDataGrid-cell": {
                display: "flex",
                alignItems: "center",
                paddingTop: "5px",
                paddingBottom: "5px",
              },
            }}
          />
        </div>
      </div>

      <ProductSelectModal
        show={modalproductlistShow}
        onHide={() =>
          setModalproductlistShow((prev) => ({ ...prev, open: false }))
        }
        handleSubmit={(product) => AddProduct(product)}
        modal={modalproductlistShow}
        setModal={setModalproductlistShow}
      />
    </div>
  );
}
