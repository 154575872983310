import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { branchPaymentHandler } from "../../../../../redux/handlers";
import ConfirmDeleteCreditModal from "../modals/ConfirmDeleteCreditModal";
import SettingBankCreditModal from "../modals/SettingBankCreditModal";
import SuccessDeleteModal from "../modals/SuccessDeleteModal";

export default function PaymentCreditSetting() {
  const dispatch = useDispatch();
  const { branchID } = useParams();
  const [creditCards, setCreditCards] = useState([]);

  const paymentForm = useSelector(
    (state) => state.branchPayment.paymentForm.payment
  );

  const [enableCreditCard, setEnableCreditCard] = useState(
    paymentForm?.is_credit_received || false
  );

  useEffect(() => {
    setEnableCreditCard(paymentForm?.is_credit_received || false);
  }, [paymentForm?.is_credit_received]);

  const fetchCreditSettings = async () => {
    try {
      const response = await dispatch(
        branchPaymentHandler.getBranchCreditSettings(branchID)
      );
      if (response) {
        const responseData = Array.isArray(response) ? response : [response];

        const transformedData = responseData.map((item) => ({
          id: item.id,
          bankID: item.bank_id,
          bankAccountName: item.account_name,
          bankAccountNumber: item.account_number,
          useFee: item.is_system_fee ? 1 : 0,
          fee: item.fee || "",
          feeType: item.fee_type || "percent",
          isCustomFee: item.is_custom_fee,
          remark: item.remark || "",
          status: item.is_status ? 1 : 0,
          bankLogo: item.bank?.image_path,
          bankName: item.bank?.name,
        }));

        setCreditCards(transformedData);
      }
    } catch (error) {
      console.error("Error fetching credit card settings:", error);
    }
  };

  useEffect(() => {
    if (branchID) {
      fetchCreditSettings();
    }
  }, [branchID]);

  const handleSubmit = async () => {
    try {
      const paymentPayload = {
        ...paymentForm,
        is_credit_received: enableCreditCard,
      };

      const response = await dispatch(
        branchPaymentHandler.updateBranchPaymentSetting(
          branchID,
          paymentPayload
        )
      );
    } catch (error) {
      console.error("Failed to update credit card settings:", error);
    }
  };

  const [formdata, setFormdata] = useState();
  const [modalcreateShow, setModalcreateShow] = useState(false);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [modalsuccessShow, setModalsuccessShow] = useState(false);

  return (
    <div>
      <div className="mb-4">
        <Form.Check
          type="switch"
          id="credit-status"
          label="จัดการข้อมูล บัตรเครดิต/เดบิต"
          name="status"
          className="custom-switch-st-1 tc-10 s1 mb-1"
          checked={enableCreditCard}
          onChange={(e) => setEnableCreditCard(e.target.checked)}
        />
      </div>

      {enableCreditCard && (
        <>
          <Button
            type="button"
            className="btn btn btn-st-7 icon-plus mb-4"
            onClick={() => {
              setModalcreateShow(true);
              setFormdata({
                id: null,
                bankID: "",
                bankLogo: "",
                bankName: "",
                bankAccountName: "",
                bankAccountNumber: "",
                useFee: 1,
                fee: "",
                feeType: "percent",
                remark: "",
                status: 1,
              });
            }}
          >
            เพิ่มบัตรเครดิต/เดบิต
          </Button>

          <div className="d-flex flex-wrap">
            {creditCards.map((item) => (
              <div
                className={
                  item.status === 0
                    ? "bankStatus0 d-flex align-items-center px-3 py-2 me-3 mb-3"
                    : "d-flex align-items-center px-3 py-2 me-3 mb-3"
                }
                key={item.id}
                style={{
                  minWidth: "283px",
                  borderRadius: "5px",
                  border: "1px solid rgba(76, 78, 100, 0.22)",
                }}
              >
                <div className="logo-bank me-3">
                  <Image
                    src={item.bankLogo}
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                    alt={item.bankName || ""}
                  />
                </div>
                <div className="flex-fill">
                  <div style={{ color: "#2C2C2C" }}>{item.bankName}</div>
                  <div className="fs-14 fw-light" style={{ color: "#6D788D" }}>
                    {item.bankAccountName}
                  </div>
                </div>
                <div className="ms-2">
                  <button
                    type="button"
                    className="btn-edit-st-1"
                    onClick={() => {
                      setModalcreateShow(true);
                      setFormdata(item);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      <div className="text-end mt-4 pt-5 pb-2 btn-group-control">
        <Button
          type="button"
          className="btn-st-3"
          style={{ width: "100px" }}
          onClick={handleSubmit}
        >
          บันทึก
        </Button>
      </div>

      <SettingBankCreditModal
        show={modalcreateShow}
        onHide={() => setModalcreateShow(false)}
        handleDelete={() => {
          setModalDeleteShow(true);
          setModalcreateShow(false);
        }}
        onSuccess={() => {
          setModalcreateShow(false);
          fetchCreditSettings();
        }}
        formdata={formdata}
        branchID={branchID}
      />

      <ConfirmDeleteCreditModal
        show={modalDeleteShow}
        onHide={() => setModalDeleteShow(false)}
        onSuccess={() => {
          setModalsuccessShow(true);
          fetchCreditSettings();
        }}
        formdata={formdata}
      />

      <SuccessDeleteModal
        show={modalsuccessShow}
        onHide={() => setModalsuccessShow(false)}
      />
    </div>
  );
}
