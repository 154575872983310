import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
export default function ConfirmDeleteModal(props) {
  const { onSuccess, onHide, ...rest } = props;

  const handleDelete = (categoryID) => {
    props.onHide();
    props.onSuccess();
  };

  return (
    <Modal {...rest} size="md" centered>
      <Modal.Body>
        <div className="px-3 py-4">
          {props.optiondata.productQty > 0 ? (
            <div>
              <div>
                <div className="text-center fs-22 tc-7 mb-2">
                  ขออภัยไม่สามารถลบได้
                </div>
                <div className="text-center fs-22 tc-7">
                  เนื่องจากมีสินค้าอยู่ในตัวเลือกมาตราฐาน
                </div>
              </div>
              <div className="text-center pt-4 mt-3  btn-group-control">
                <Button
                  type="button"
                  className="btn-st-3 mx-3 "
                  style={{ width: "100px" }}
                  onClick={props.onHide}
                >
                  ตกลง
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <div>
                <div className="text-center fs-22 tc-7 mb-2">คุณต้องการลบ</div>
                <div className="text-center fs-22 tc-7">
                  ตัวเลือกมาตราฐานนี้ใช่หรือไม่
                </div>
              </div>

              <div className="text-center pt-4 mt-3  btn-group-control">
                <Button
                  type="button"
                  className="btn-st-3 mx-3  "
                  style={{ width: "100px" }}
                  onClick={() => handleDelete(props.optiondata.optionID)}
                >
                  ใช่
                </Button>
                <Button
                  type="button"
                  className="btn-st-6 mx-3 "
                  style={{ width: "100px" }}
                  onClick={props.onHide}
                >
                  ไม่ใช่
                </Button>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
