import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { productHandler } from "../../../../redux/handlers";
import { STATUS } from "../../../../constants/server/api";
import {
  OptionProductSpecificModal,
  OptionProductSpecificItemModal,
  ConfirmDeleteModal,
  SuccessModal,
} from "./modals";

const INITIAL_MODAL_STATE = {
  open: false,
  x: 0,
  y: 0,
  width: 560,
  height: 380,
  pin: false,
  mode: "create",
  modalname: "modal_edit_option_product",
};

export function OptionProductSpecific() {
  const { productID } = useParams();
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product.product);

  const [optionOpen, setOptionOpen] = useState(
    product?.is_specific_option || false
  );
  const [specificOptions, setSpecificOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [formdisabled, setFormdisabled] = useState(true);

  const [modalCreateOption, setModalCreateOption] =
    useState(INITIAL_MODAL_STATE);
  const [modalCreateItem, setModalCreateItem] = useState({
    ...INITIAL_MODAL_STATE,
    height: 480,
    modalname: "modal_create_option_item",
  });

  useEffect(() => {
    if (productID) {
      fetchSpecificOptionRelations();
    }
  }, [productID]);

  useEffect(() => {
    if (product) {
      setOptionOpen(product.is_specific_option);
    }
  }, [product]);

  const fetchSpecificOptionRelations = async () => {
    try {
      const relationResponse = await dispatch(
        productHandler.getProductSpecificOptionRelations(productID)
      );

      if (relationResponse) {
        const sortedOptions = [...relationResponse].sort((a, b) => a.id - b.id);
        setSpecificOptions(sortedOptions);
        if (relationResponse.length > 0) {
          setOptionOpen(true);
        }
      }
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const handleOptionSwitchChange = async (e) => {
    const isChecked = e.target.checked;
    setOptionOpen(isChecked);

    try {
      await dispatch(
        productHandler.updateProductSpecificOptionStatus(productID, isChecked)
      );
    } catch (error) {
      console.error("Error updating product specific option status:", error);

      setOptionOpen(!isChecked);
    }
  };

  const handleCreateOption = async (optionData) => {
    if (formdisabled) return;

    try {
      const response = await dispatch(
        productHandler.createProductSpecificOptionRelation({
          product_id: productID,
          name: optionData.name,
        })
      );

      if (response) {
        await fetchSpecificOptionRelations();
        setModalCreateOption((prev) => ({ ...prev, open: false }));
        setSuccessMessage("เพิ่มตัวเลือกสินค้าสำเร็จ");
        setShowSuccessModal(true);
      }
    } catch (error) {
      console.error("Error creating option:", error);
    }
  };

  const handleUpdateOption = async (optionData) => {
    if (formdisabled) return;

    try {
      const response = await dispatch(
        productHandler.updateProductSpecificOptionRelation(optionData.id, {
          product_id: productID,
          name: optionData.name,
        })
      );

      if (response) {
        await fetchSpecificOptionRelations();
        setModalCreateOption((prev) => ({ ...prev, open: false }));
        setSuccessMessage("แก้ไขตัวเลือกสินค้าสำเร็จ");
        setShowSuccessModal(true);
      }
    } catch (error) {
      console.error("Error updating option:", error);
    }
  };

  const handleDeleteConfirm = (id, type = "option", itemIndex = null) => {
    if (formdisabled) return;

    setDeleteTarget({ id, type, itemIndex });
    setShowDeleteConfirm(true);
  };

  const handleDeleteConfirmed = async () => {
    if (!deleteTarget) return;

    try {
      let response;
      if (deleteTarget.type === "option") {
        response = await dispatch(
          productHandler.deleteProductSpecificOptionRelation(deleteTarget.id)
        );
      } else {
        response = await dispatch(
          productHandler.deleteProductSpecificOptionRelationItem(
            deleteTarget.itemIndex,
            deleteTarget.id
          )
        );
      }

      if (response === STATUS.OK) {
        await fetchSpecificOptionRelations();
        setShowDeleteConfirm(false);
        setSuccessMessage(
          deleteTarget.type === "option"
            ? "ลบตัวเลือกสินค้าสำเร็จ"
            : "ลบรายการสำเร็จ"
        );
        setShowSuccessModal(true);
      }
    } catch (error) {
      console.error("Error deleting:", error);
    }
  };

  const handleCreateItem = async (itemData) => {
    if (formdisabled || !selectedOption) return;

    try {
      await dispatch(
        productHandler.createProductSpecificOptionRelationItem({
          product_specific_option_relation_id: selectedOption.id,
          name: itemData.name,
          price: parseFloat(itemData.price) || 0,
        })
      );
      await fetchSpecificOptionRelations();
      setModalCreateItem((prev) => ({ ...prev, open: false }));
      setSuccessMessage("เพิ่มรายการสำเร็จ");
      setShowSuccessModal(true);
    } catch (error) {
      console.error("Error creating item:", error);
    }
  };

  const handleUpdateItem = async (itemData) => {
    if (formdisabled) return;

    try {
      await dispatch(
        productHandler.updateProductSpecificOptionRelationItem(
          selectedOption.id,
          {
            product_specific_option_relation_id: selectedOption.id,
            name: itemData.name,
            price: parseFloat(itemData.price) || 0,
          }
        )
      );
      await fetchSpecificOptionRelations();
      setModalCreateItem((prev) => ({ ...prev, open: false }));
      setSuccessMessage("แก้ไขรายการสำเร็จ");
      setShowSuccessModal(true);
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  const renderOptionItems = (items = [], optionId) => {
    return items.map((item, index) => (
      <div className="col-xl-6 row gx-4 align-items-center" key={index}>
        <div className="col">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">รายการ</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              placeholder="รายการ"
              value={item.name}
              disabled
            />
          </Form.Group>
        </div>
        <div className="col">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">ราคา</Form.Label>
            <Form.Control
              type="number"
              className="form-control"
              placeholder="ราคา"
              value={item.price}
              disabled
            />
          </Form.Group>
        </div>
        <div className="col-auto d-flex align-items-center">
          <button
            type="button"
            className="btn d-block btn-edit-st-1 me-2"
            onClick={() => {
              setSelectedOption({
                id: item.id,
                optionDetail: item,
              });
              setModalCreateItem((prev) => ({
                ...prev,
                open: true,
                mode: "edit",
              }));
            }}
            disabled={formdisabled}
          />
          <button
            type="button"
            className="btn d-block btn-delete-st-3"
            onClick={() => handleDeleteConfirm(optionId, "item", item.id)}
            disabled={formdisabled}
          />
        </div>
      </div>
    ));
  };

  const renderOptionList = () => {
    return specificOptions.map((option, index) => (
      <div className="row gx-4 align-items-center mb-5" key={index}>
        <div className="col-xl-6 col-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">ตัวเลือกเฉพาะสินค้า</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              placeholder="ตัวเลือกเฉพาะสินค้า"
              value={option.name}
              disabled
            />
          </Form.Group>
        </div>
        <div className="col-xl-6 col-6 d-flex align-items-center gap-2">
          <button
            type="button"
            className="btn d-block btn-edit-st-1"
            onClick={() => {
              setSelectedOption(option);
              setModalCreateOption((prev) => ({
                ...prev,
                open: true,
                mode: "edit",
              }));
            }}
            disabled={formdisabled}
          />
          <button
            type="button"
            className="btn btn-st-3"
            onClick={() => handleDeleteConfirm(option.id)}
            style={{ background: "#F95057", borderColor: "#F95057" }}
            disabled={formdisabled}
          >
            ลบตัวเลือกสินค้า
          </button>
        </div>
        {renderOptionItems(option.productSpecificOptionItem, option.id)}
        <div className="col-12 mt-3">
          <button
            type="button"
            className="btn btn-st-3"
            style={{ background: "#FF731D", borderColor: "#FF731D" }}
            onClick={() => {
              setSelectedOption(option);
              setModalCreateItem((prev) => ({
                ...prev,
                open: true,
                mode: "create",
              }));
            }}
            disabled={formdisabled}
          >
            เพิ่มรายการ
          </button>
        </div>
      </div>
    ));
  };

  return (
    <div className="card card-st-3 mb-3">
      <div className="card-body px-xl-3">
        <div className="pt-2 pb-1 px-3">
          <Form.Check
            type="switch"
            id="custom-sdp"
            label="ตัวเลือกเฉพาะสินค้า"
            name="optionstandardStatus"
            className="custom-switch-st-1 s1 mb-1"
            checked={optionOpen}
            onChange={handleOptionSwitchChange}
          />
          <span className="ms-2" style={{ color: "#858796" }}>
            (เพิ่มตัวเลือกเฉพาะสินค้านี้เท่านั้น เช่น เพิ่มไข่ดาว, Size S M L,
            อื่นๆ)
          </span>
        </div>

        {optionOpen && (
          <div className="px-5 py-4">
            <div className="row">
              <div className="col-xl-6 col">
                <div className="mb-2 mt-4">
                  <button
                    type="button"
                    className="btn btn-st-3 mb-4"
                    onClick={() =>
                      setModalCreateOption((prev) => ({
                        ...prev,
                        open: true,
                        mode: "create",
                      }))
                    }
                    disabled={formdisabled}
                  >
                    เพิ่มตัวเลือกสินค้า
                  </button>
                </div>
              </div>
            </div>

            <div className="ms-xl-4 mt-2 ps-2">{renderOptionList()}</div>
          </div>
        )}

        {optionOpen && (
          <div className="text-end pe-3 mt-4 pb-4">
            {formdisabled ? (
              <Button
                type="button"
                className="btn-st-3"
                onClick={() => setFormdisabled(false)}
              >
                แก้ไข
              </Button>
            ) : (
              <Button
                type="button"
                className="btn-st-6"
                onClick={() => setFormdisabled(true)}
              >
                ยกเลิก
              </Button>
            )}
          </div>
        )}
      </div>

      <OptionProductSpecificModal
        modal={modalCreateOption}
        setModal={setModalCreateOption}
        onSubmit={
          modalCreateOption.mode === "edit"
            ? handleUpdateOption
            : handleCreateOption
        }
        productOptionDetail={selectedOption}
        disabled={formdisabled}
      />

      {selectedOption && (
        <OptionProductSpecificItemModal
          modal={modalCreateItem}
          setModal={setModalCreateItem}
          productOptionDetail={selectedOption}
          mode={modalCreateItem.mode}
          onSubmit={
            modalCreateItem.mode === "edit"
              ? handleUpdateItem
              : handleCreateItem
          }
          onDelete={
            selectedOption?.optionDetail?.id
              ? () =>
                  handleDeleteConfirm(
                    selectedOption.id,
                    "item",
                    selectedOption.optionDetail.id
                  )
              : undefined
          }
          disabled={formdisabled}
        />
      )}

      <ConfirmDeleteModal
        show={showDeleteConfirm}
        onHide={() => setShowDeleteConfirm(false)}
        onSuccess={handleDeleteConfirmed}
        deleteTarget={deleteTarget}
      />

      <SuccessModal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        textsuccess={successMessage}
      />
    </div>
  );
}
