import { setData } from "../slices/settingGeneral.slice";
import { ENDPOINT, STATUS } from "../../constants/server/api";
import { fetchApiData } from "../../utils/apiUtils";

export const getCodeSettings =
  (businessId, codeSettingTypeCode) => async (dispatch) => {
    const data = await fetchApiData({
      method: "get",
      endpoint: `${ENDPOINT.CODE_SETTING}/${businessId}`,
      params: codeSettingTypeCode
        ? { code_setting_type_code: codeSettingTypeCode }
        : null,
      dispatch,
    });

    if (data) {
      dispatch(setData(data));
      return data;
    }
  };

export const updateCodeSettings =
  (businessId, codeSettings) => async (dispatch) => {
    const data = {
      codeSettings: [
        {
          code_master_id: codeSettings.code_master_id,
          code_setting_type_id: codeSettings.code_setting_type_id,
          code_setting_year_id: codeSettings.code_setting_year_id,
          code_setting_month_id: codeSettings.code_setting_month_id,
          code_setting_day_id: codeSettings.code_setting_day_id,
          counter_setting_master_id: codeSettings.counter_setting_master_id,
          prefix: codeSettings.prefix,
          symbol_text: codeSettings.symbol_text,
          digit: codeSettings.digit,
        },
      ],
    };

    await fetchApiData({
      method: "put",
      endpoint: `${ENDPOINT.CODE_SETTING}/${businessId}`,
      data,
      dispatch,
    });
    return STATUS.OK;
  };

export const getGenerateExample = (params) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.CODE_SETTING}/generate/example`,
    params: params,
    dismissLoading: true,
    dispatch,
  });
  return data;
};
