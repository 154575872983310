import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { employeeHandler } from "../../../redux/handlers";
import { useGlobalTable } from "../../../hooks/useGlobalTable";
import { createTableColumns } from "../../../utils/createTableColumns";
import { AllderTable } from "../../../components/AllderTable";
import { PositionModal } from "./modals/PositionModal";
import { SuccessDeleteModal } from "./modals/SuccessDeleteModal";
import { ConfirmDeletePositionModal } from "./modals/ConfirmDeletePositionModal";
import { useBreadcrumb } from "../../../hooks/useBreadcrumb";
import Breadcrumb from "../../../components/Breadcrumb";

const INITIAL_SELECTED_POSITION = {
  id: "",
  code: "",
  name_th: "",
  name_en: "",
  is_status: true,
};

const INITIAL_MODAL_STATE = {
  open: false,
  x: 0,
  y: 0,
  width: 470,
  pin: false,
  mode: "normal",
};

const MODAL_HEIGHTS = {
  FORM: 510,
  CONFIRM: 320,
  SUCCESS: 300,
};

const MODAL_STATES = {
  CREATE: { ...INITIAL_MODAL_STATE, height: MODAL_HEIGHTS.FORM },
  EDIT: { ...INITIAL_MODAL_STATE, height: MODAL_HEIGHTS.FORM },
  CONFIRM: { ...INITIAL_MODAL_STATE, height: MODAL_HEIGHTS.CONFIRM },
  SUCCESS: { ...INITIAL_MODAL_STATE, height: MODAL_HEIGHTS.SUCCESS },
};

export function PositionManagement() {
  const dispatch = useDispatch();
  const { businessSlug } = useParams();
  const [selectedPosition, setSelectedPosition] = useState(
    INITIAL_SELECTED_POSITION
  );
  const [successMsg, setSuccessMsg] = useState("");
  const [modalCreate, setModalCreate] = useState(MODAL_STATES.CREATE);
  const [modalEdit, setModalEdit] = useState(MODAL_STATES.EDIT);
  const [modalSuccess, setModalSuccess] = useState(MODAL_STATES.SUCCESS);
  const [modalConfirmDelete, setModalConfirmDelete] = useState(
    MODAL_STATES.CONFIRM
  );

  const fetchPositions = useCallback(
    (params) =>
      dispatch(
        employeeHandler.getEmployeePositions({
          business_slug: businessSlug,
          ...params,
        })
      ),
    [businessSlug, dispatch]
  );

  const {
    data: positions,
    loading,
    totalRows,
    paginationModel,
    setPaginationModel,
    handleSort,
    debouncedSearch,
    refreshData: refreshPositions,
    filterParams,
  } = useGlobalTable({
    fetchData: fetchPositions,
    defaultSort: "created_at",
    defaultOrder: "desc",
    defaultPageSize: 20,
  });

  const breadcrumbConfig = [
    { label: "หน้าหลัก", path: "/" },
    { label: "พนักงาน", path: "#" },
    { label: "ตำแหน่งงาน" },
  ];
  useBreadcrumb(breadcrumbConfig);

  const handleCreatePosition = async (values) => {
    try {
      await dispatch(
        employeeHandler.createEmployeePosition({
          businessSlug,
          code: values.code,
          name_th: values.name_th,
          name_en: values.name_en,
          is_status: values.is_status,
        })
      );
      refreshPositions();
      setSuccessMsg("บันทึกตำแหน่งสำเร็จ");
      setModalCreate((prev) => ({ ...prev, open: false }));
      setModalSuccess((prev) => ({ ...prev, open: true }));
      setSelectedPosition(INITIAL_SELECTED_POSITION);
    } catch (error) {
      console.error("Error creating position:", error);
    }
  };

  const handleUpdatePosition = async (values) => {
    try {
      await dispatch(
        employeeHandler.updateEmployeePosition(values.id, {
          businessSlug,
          code: values.code,
          name_th: values.name_th,
          name_en: values.name_en,
          is_status: values.is_status,
        })
      );
      refreshPositions();
      setSuccessMsg("แก้ไขตำแหน่งสำเร็จ");
      setModalEdit((prev) => ({ ...prev, open: false }));
      setModalSuccess((prev) => ({ ...prev, open: true }));
    } catch (error) {
      console.error("Error updating position:", error);
    }
  };

  const handleDeletePosition = async () => {
    try {
      await dispatch(
        employeeHandler.deleteEmployeePosition(selectedPosition.id)
      );
      refreshPositions();
      setSuccessMsg("ลบตำแหน่งสำเร็จ");
      setModalConfirmDelete((prev) => ({ ...prev, open: false }));
      setModalSuccess((prev) => ({ ...prev, open: true }));
      setSelectedPosition(INITIAL_SELECTED_POSITION);
    } catch (error) {
      console.error("Error deleting position:", error);
    }
  };

  const showDeleteConfirmation = () => {
    setModalEdit((prev) => ({ ...prev, open: false }));
    setModalConfirmDelete((prev) => ({ ...prev, open: true }));
  };

  const handleExport = async () => {
    try {
      await dispatch(
        employeeHandler.exportEmployeePositionToExcel(
          businessSlug,
          filterParams.s
        )
      );
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const columns = createTableColumns(
    [
      {
        field: "code",
        headerName: "รหัสตำแหน่ง",
        width: 170,
        sortable: true,
        renderCell: ({ row }) => (
          <div
            style={{
              color: "#2DC76D",
              fontWeight: 500,
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => {
              setSelectedPosition(row);
              setModalEdit((prev) => ({ ...prev, open: true }));
            }}
          >
            {row.code}
          </div>
        ),
      },
      {
        field: "name_th",
        headerName: "ชื่อตำแหน่งงาน",
        width: 340,
        sortable: true,
        flex: 1,
        renderCell: ({ row }) => (
          <div style={{ fontWeight: 500 }}>{row.name_th}</div>
        ),
      },
      {
        field: "emp_count",
        headerName: "จำนวนพนักงาน",
        width: 180,
        sortable: false,
      },
      {
        field: "actions",
        headerName: "จัดการ",
        width: 120,
        sortable: false,
        type: "actions",
      },
    ],
    (row) => (
      <div>
        <Button
          type="button"
          className="btn-edit-st-1"
          onClick={() => {
            setSelectedPosition(row);
            setModalEdit((prev) => ({ ...prev, open: true }));
          }}
        />
      </div>
    )
  );

  return (
    <div>
      <Breadcrumb />
      <div className="card card-st-2">
        <div className="card-body px-0 py-4">
          <div className="text-lg font-bold mb-4 lh-1 px-4 mb-4 text-[#2DC76D]">
            ตำแหน่งงาน
          </div>
          <div className="d-flex align-items-center px-4 mb-4">
            <button
              className="btn btn-export-st-1 me-auto"
              onClick={handleExport}
            >
              Export
            </button>
            <div className="me-3">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหาชื่อตำแหน่งงาน"
                onChange={(e) => debouncedSearch(e.target.value)}
              />
            </div>
            <Button
              type="button"
              className="btn btn-st-7"
              onClick={() => {
                setModalCreate((prev) => ({ ...prev, open: true }));
              }}
            >
              เพิ่มตำแหน่งงาน
            </Button>
          </div>

          <AllderTable
            data={positions}
            loading={loading}
            columns={columns}
            totalRows={totalRows}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            handleSort={handleSort}
          />
        </div>
      </div>

      <PositionModal
        modal={modalCreate}
        setModal={setModalCreate}
        onHide={() => setModalCreate((prev) => ({ ...prev, open: false }))}
        onSubmit={handleCreatePosition}
      />

      <PositionModal
        modal={modalEdit}
        setModal={setModalEdit}
        isEdit={true}
        positionData={selectedPosition}
        onHide={() => setModalEdit((prev) => ({ ...prev, open: false }))}
        onSubmit={handleUpdatePosition}
        onDelete={showDeleteConfirmation}
      />

      <ConfirmDeletePositionModal
        modal={modalConfirmDelete}
        setModal={setModalConfirmDelete}
        positionData={selectedPosition}
        onConfirm={handleDeletePosition}
        onHide={() =>
          setModalConfirmDelete((prev) => ({ ...prev, open: false }))
        }
      />

      <SuccessDeleteModal
        modal={modalSuccess}
        setModal={setModalSuccess}
        msg={successMsg}
        onHide={() => setModalSuccess((prev) => ({ ...prev, open: false }))}
      />
    </div>
  );
}
