import * as branchEmp from '../slices/branchEmp.slice';
import { ENDPOINT } from '../../constants/server/api';
import { fetchApiData } from '../../utils/apiUtils';
export const getBranchRole = async (dispatch) => {
  const data = await fetchApiData({
    method: 'get',
    endpoint: `${ENDPOINT.BRANCH_ROLE}/list`,
    dispatch,
  });
  if (data) {
    dispatch(branchEmp.setRole(data));
    return data;
  }
};
export const getEmployee = (permissionID) => async (dispatch) => {
  const data = await fetchApiData({
    method: 'get',
    endpoint: `${ENDPOINT.BRANCH_EMPLOYEE_ROLE}/${permissionID}`,
    dispatch,
  });
  if (data) {
    dispatch(branchEmp.getById(data));
    return data;
  }
};
export const employeePagination = (params) => async (dispatch) => {
  const data = await fetchApiData({
    method: 'get',
    endpoint: `${ENDPOINT.BRANCH_EMPLOYEE_ROLE}/pagination/${params.branchId}/list`,
    params: {
      page: params.page,
      perPage: params.per_page,
      s: params.s,
      sortBy: params.sortBy,
      sortOrder: params.sortOrder,
    },
    dispatch,
  });
  if (data) {
    dispatch(branchEmp.setData(data));
    return data;
  }
};

export const allEmployees = (branchId) => async (dispatch) => {
  const data = await fetchApiData({
    method: 'get',
    endpoint: `${ENDPOINT.BRANCH_EMPLOYEE_ROLE}/all/${branchId}/list`,
    dispatch,
  });
  if (data) {
    dispatch(branchEmp.setAllEmployees(data));
    return data;
  }
};

export const createEmployeeRole = (requestData) => async (dispatch) => {
  const result = await fetchApiData({
    method: 'post',
    endpoint: `${ENDPOINT.BRANCH_EMPLOYEE_ROLE}`,
    data: requestData,
    dispatch,
  });
  if (result) {
    return result;
  }
};

export const updateEmployeeRole = (id, requestData) => async (dispatch) => {
  const result = await fetchApiData({
    method: 'put',
    endpoint: `${ENDPOINT.BRANCH_EMPLOYEE_ROLE}/${id}`,
    data: requestData,
    dispatch,
  });
  if (result) {
    return result;
  }
};

export const deleteEmployeeRole = (id) => async (dispatch) => {
  const result = await fetchApiData({
    method: 'delete',
    endpoint: `${ENDPOINT.BRANCH_EMPLOYEE_ROLE}/${id}`,
    dispatch,
  });
  dispatch(branchEmp.deleteItem(id));
  if (result) {
    return result;
  }
};
