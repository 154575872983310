import React, { useEffect } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  masterHandler,
  branchPaymentHandler,
} from "../../../../../redux/handlers";

export default function SettingBankCreditModal(props) {
  const { handleDelete, formdata, branchID, ...rest } = props;
  const dispatch = useDispatch();
  const banks = useSelector((state) => state.master.banks);

  useEffect(() => {
    dispatch(masterHandler.getBanks());
  }, [dispatch]);

  const schema = yup.object().shape({
    bankID: yup.mixed().required("กรุณาเลือกธนาคาร"),
    bankAccountName: yup.string().required("กรุณากรอกชื่อบัญชี"),
    fee: yup.string().when("useFee", {
      is: (val) => val === 1,
      then: () => yup.string().required("กรุณากรอกค่าธรรมเนียม"),
      otherwise: () => yup.string().notRequired(),
    }),
    feeType: yup.string().when("useFee", {
      is: (val) => val === 1,
      then: () => yup.string().required("กรุณาเลือกประเภทการเก็บค่าธรรมเนียม"),
      otherwise: () => yup.string().notRequired(),
    }),
  });

  const optionsBank =
    banks?.map((bank) => ({
      value: bank.id,
      label: bank.name,
    })) || [];

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const isSystemFee = values.useFee === 1;
      const isCustomFee = values.useFee === 0;

      let feeValue = null;
      if (values.fee) {
        feeValue = parseFloat(values.fee);
        if (isNaN(feeValue)) {
          throw new Error("ค่าธรรมเนียมต้องเป็นตัวเลขเท่านั้น");
        }
      }

      const formattedData = {
        branchId: Number(branchID),
        bankId: Number(values.bankID),
        accountName: values.bankAccountName,
        accountNumber: values.bankAccountNumber || "",
        isSystemFee,
        isCustomFee,
        fee: isSystemFee ? feeValue : null,
        feeType: isSystemFee ? values.feeType : null,
        remark: values.remark || "",
        isStatus: values.status === 1,
      };

      let response;
      if (values.id) {
        response = await dispatch(
          branchPaymentHandler.updateBranchCreditSetting(
            values.id,
            formattedData
          )
        );
      } else {
        response = await dispatch(
          branchPaymentHandler.createBranchCreditSetting(formattedData)
        );
      }

      if (response) {
        if (props.onSuccess) {
          props.onSuccess();
        }
        resetForm();
      }
    } catch (error) {
      console.error("Error saving credit card:", error);
      alert(error.message || "เกิดข้อผิดพลาดในการบันทึกข้อมูล");
    }
  };

  return (
    <Modal {...rest} size="md" centered>
      <Modal.Body>
        <div className="d-flex align-items-center px-3 py-2 mb-4 pb-3">
          <div className="fs-24 fw-bold me-auto" style={{ color: "#2DC76D" }}>
            จัดการข้อมูล บัตรเครดิต/เดบิต
          </div>
          <button
            onClick={props.onHide}
            className="btn btn-close-modal"
          ></button>
        </div>
        <div className="px-3">
          <Formik
            enableReinitialize
            initialValues={formdata}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ธนาคาร <span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    name="bankID"
                    className={
                      errors.bankID && touched.bankID
                        ? "w-100 invalid select-st-1"
                        : "w-100 select-st-1"
                    }
                    options={optionsBank}
                    placeholder="เลือกธนาคาร"
                    onChange={(e) => {
                      setFieldValue("bankID", e.value);
                      const selectedBank = banks.find(
                        (bank) => bank.id === e.value
                      );
                      if (selectedBank) {
                        setFieldValue("bankName", selectedBank.name);
                        setFieldValue("bankLogo", selectedBank.image_path);
                      }
                    }}
                    value={optionsBank.filter(
                      (item) => item.value === values.bankID
                    )}
                  />
                  {errors.bankID && touched.bankID && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {errors.bankID}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ชื่อบัญชี <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="bankAccountName"
                    placeholder="กรอกชื่อบัญชี"
                    value={values.bankAccountName}
                    onChange={handleChange}
                    isInvalid={
                      !!errors.bankAccountName && touched.bankAccountName
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.bankAccountName}
                  </Form.Control.Feedback>
                </Form.Group>

                {values.useFee === 1 && (
                  <>
                    <Form.Group className="mb-3 form-st-3 mt-4 pb-1">
                      <Form.Label className="mb-1">
                        ค่าธรรมเนียม <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="fee"
                        placeholder="กรอกค่าธรรมเนียม"
                        value={values.fee}
                        onChange={handleChange}
                        isInvalid={!!errors.fee && touched.fee}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.fee}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 form-st-3 pb-1">
                      <Form.Label className="mb-1">
                        ประเภทการเก็บค่าธรรมเนียม{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        name="feeType"
                        value={values.feeType || "percent"}
                        onChange={handleChange}
                        isInvalid={!!errors.feeType && touched.feeType}
                        disabled={true}
                      >
                        <option value="">เลือกประเภท</option>
                        <option value="percent">%</option>
                        <option value="amount">บาท</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.feeType}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </>
                )}
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">หมายเหตุ</Form.Label>
                  <Form.Control
                    type="text"
                    name="remark"
                    placeholder="กรอกหมายเหตุ"
                    value={values.remark}
                    onChange={handleChange}
                    isInvalid={!!errors.remark && touched.remark}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.remark}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Label className="mb-2 tc-10">สถานะ</Form.Label>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="เปิดใช้งาน"
                      name="status"
                      type={type}
                      id={`inline-${type}-21`}
                      className="form-check-st-2 tc-7"
                      checked={values.status === 1}
                      onChange={() => setFieldValue("status", 1)}
                    />
                    <Form.Check
                      inline
                      label="ปิดใช้งาน"
                      name="status"
                      type={type}
                      id={`inline-${type}-22`}
                      className="form-check-st-2 tc-7"
                      checked={values.status === 0}
                      onChange={() => setFieldValue("status", 0)}
                    />
                  </div>
                ))}
                <div className="d-flex align-items-center pt-2 pb-3 btn-group-control">
                  {values.id !== null ? (
                    <Button
                      type="button"
                      className="btn-st-5 me-auto px-4"
                      style={{ width: "90px" }}
                      onClick={handleDelete}
                    >
                      ลบ
                    </Button>
                  ) : (
                    <div className="me-auto"></div>
                  )}
                  <Button
                    type="button"
                    className="btn-st-6 me-3"
                    style={{ width: "100px" }}
                    onClick={props.onHide}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="submit"
                    className="btn-st-3"
                    style={{ width: "100px" }}
                  >
                    {values.id ? "แก้ไข" : "บันทึก"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}
