import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import { ModalAllder } from "layouts/ModalAllder";

export default function OptionAnnotationModal({
  modal,
  setModal,
  optionannotation = [],
  onSubmit,
}) {
  const [annotationSelected, setAnnotationSelected] = useState(null);

  const selectOptions = optionannotation.map((option) => ({
    id: option.id,
    value: option.id,
    label: option.name,
  }));

  const handleChangeAnnotation = (selectedOption) => {
    setAnnotationSelected(selectedOption);
  };

  const handleClose = () => {
    setModal((prev) => ({ ...prev, open: false }));
    setAnnotationSelected(null);
  };

  const handleSave = () => {
    if (annotationSelected) {
      onSubmit(annotationSelected);
      handleClose();
    }
  };

  return (
    <ModalAllder
      modal={modal}
      setModal={setModal}
      htitle={"เพิ่มคำอธิบายมาตรฐาน"}
      hbtn={true}
    >
      <div className="px-4">
        <div className="text-end pb-3 pt-4 mt-4 pe-3">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">
              ชื่อรายการ <span className="text-danger">*</span>
            </Form.Label>
            <Select
              name="productAnnotation"
              onChange={handleChangeAnnotation}
              className="w-100 select-st-1 text-start"
              options={selectOptions}
              value={annotationSelected}
              placeholder="ชื่อรายการ"
              isClearable
            />
          </Form.Group>
        </div>
        <div className="text-end pb-4 pt-4 pe-3">
          <div className="d-flex justify-end align-items-center pt-3 btn-group-control">
            <Button
              className="btn btn-st-6 me-3 bg-transparent"
              onClick={handleClose}
            >
              ยกเลิก
            </Button>
            <Button
              type="button"
              className="btn-st-3"
              onClick={handleSave}
              disabled={!annotationSelected}
            >
              บันทึก
            </Button>
          </div>
        </div>
      </div>
    </ModalAllder>
  );
}
