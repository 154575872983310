import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { employeeHandler } from "../../../../redux/handlers";

export function ConfirmDeleteModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { businessSlug } = useParams();

  const handleDelete = async (id) => {
    try {
      const result = await dispatch(employeeHandler.deleteEmployeeRole(id));
      if (result) {
        props.onHide();
        props.onSuccess();
        navigate(`/${businessSlug}/emp/business`);
      }
    } catch (error) {
      console.error("Error deleting employee role:", error);
      // Could add error handling UI here if needed
    }
  };

  return (
    <Modal {...props} size="md" centered>
      <Modal.Body>
        <div className="px-3 py-4">
          <div>
            <div>
              <div className="text-center fs-22 tc-7 mb-2">คุณต้องการลบ</div>
              <div className="text-center fs-22 tc-7">
                สิทธิผู้ใช้งานธุรกิจนี้ใช่หรือไม่
              </div>
            </div>

            <div className="text-center pt-4 mt-3 btn-group-control">
              <Button
                type="button"
                className="btn-st-3 mx-3"
                style={{ width: "100px" }}
                onClick={() => handleDelete(props.pdata.user_business_group_id)}
              >
                ใช่
              </Button>
              <Button
                type="button"
                className="btn-st-6 mx-3"
                style={{ width: "100px" }}
                onClick={props.onHide}
              >
                ไม่ใช่
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
