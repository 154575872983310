import React from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function ConfirmCancelModal(props) {
  return (
    <Modal {...props} size="md" centered>
      <Modal.Body>
        <div className="px-3 py-4">
          <div>
            <div>
              <div className="text-center fs-22 tc-7 mb-2">
                คุณต้องการยกเลิก
              </div>
              <div className="text-center fs-22 tc-7">
                การสร้างรายการนี้ใช่หรือไม่
              </div>
            </div>
            <div className="text-center pt-4 mt-3  btn-group-control">
              <Link
                to="/products"
                className="btn btn-st-3 mx-3 text-decoration-none"
                style={{ width: "100px" }}
              >
                ใช่
              </Link>
              <Button
                type="button"
                className="btn-st-6 mx-3 "
                style={{ width: "100px" }}
                onClick={props.onHide}
              >
                ไม่ใช่
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
