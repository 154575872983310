import React, { useState,useEffect,useCallback  } from 'react';
import {Link,useParams,NavLink} from "react-router-dom";
import DataTable from 'react-data-table-component';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

import { utils, writeFileXLSX } from 'xlsx';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Formik } from 'formik';
import * as yup from 'yup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ReactPaginate from 'react-paginate';

import { nanoid } from 'nanoid'

import { ModalAllder } from "layouts/ModalAllder";
import { DataGrid } from '@mui/x-data-grid';

export function ProductRecipes(){
    return (
        <div>
            <div className="card card-st-2" style={{height:'400px'}}>
                <div className="card-body px-0 py-4 d-flex align-items-center justify-content-center">
                    <div>
                    <div className='text-center mb-3'>
                        <img src="/images/tdesign_system-setting.png" width={100} />
                    </div>
                    <div className="fs-20 tc-12">กำลังพัฒนาระบบ</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
