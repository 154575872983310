import React from "react";
import Form from "react-bootstrap/Form";
import { Trash2 } from "lucide-react";
import "react-datepicker/dist/react-datepicker.css";

export default function AnnotationItems(props) {
  React.useEffect(() => {
    props.setFieldValue(`optionList[${props.n}].name`, props.item.name);
    props.setFieldValue(`optionList[${props.n}].price`, props.item.price);
  }, [props.item]);

  const handleDeleteClick = () => {
    if (props.onDeleteRequest && !props.disabled) {
      props.onDeleteRequest(props.item);
    }
  };

  return (
    <div className="row gx-xl-3 align-items-center">
      {props.values.optionList[props.n] ? (
        <>
          <div className="col-xl-6 col-lg-6 col-8">
            <div className="row">
              <div className="col-12">
                <Form.Group className="mb-2 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ชื่อรายการ <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name={`optionList[${props.n}].name`}
                    className="form-control"
                    placeholder="กรอกชื่อตัวเลือกหมายเหตุ"
                    value={props.values.optionList[props.n].name}
                    disabled={true}
                    onChange={(e) =>
                      props.setFieldValue(
                        `optionList[${props.n}].name`,
                        e.target.value
                      )
                    }
                  />
                </Form.Group>
              </div>
            </div>
          </div>          
          <div className="col-xl-6 col-4 flex items-center">
            <button
              type="button"
              className={`btn me-2 d-block btn-edit-st-1 !w-[20px] ${
                props.disabled ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              onClick={props.editHandeler}
              disabled={props.disabled}
            />
            <button
              type="button"
              className={`btn-delete-st-3 ml-2 ${
                props.disabled ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              onClick={handleDeleteClick}
              disabled={props.disabled}
            />
          </div>
        </>
      ) : null}
    </div>
  );
}