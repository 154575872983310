import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams, NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

import { utils, writeFileXLSX } from "xlsx";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ReactPaginate from "react-paginate";

import { nanoid } from "nanoid";

import { ModalAllder } from "layouts/ModalAllder";
import { DataGrid } from "@mui/x-data-grid";

const getproductData = () => {
  const options = [];
  fetch("/json/product.json")
    .then((response) => response.json())
    .then((result) => {
      for (var i = 0; i < result.length; i++) {
        var obj = {};
        obj["value"] = result[i].productID;
        obj["label"] = result[i].productName;
        obj["productPrice"] = result[i].productPrice;
        options.push(obj);
      }
    })
    .catch((error) => console.log("error", error));

  return options;
};

export function OptionProductSet() {
  const [optionOpen, setOptionOpen] = useState(false);
  const [listoptionstandard, setListOptionstandard] = useState([]);
  const [productset, setProductset] = useState([]);

  const [optionsProduct, setOptionsProduct] = useState([]);

  useEffect(() => {
    setOptionsProduct(getproductData());
  }, []);

  return (
    <div className="card card-st-3 mb-3">
      <div className="card-body px-xl-3">
        <div className="pt-2 pb-1 px-3">
          <Form.Check
            type="switch"
            id="custom-sdset"
            label="ตัวเลือกสินค้าจัดชุด"
            name="optionstandardStatus"
            className="custom-switch-st-1  s1 mb-1"
            checked={optionOpen === 1}
            onChange={(e) => {
              if (e.target.checked === true) {
                setOptionOpen(1);
              } else {
                setOptionOpen(0);
              }
            }}
          />
          <span className="ms-2" style={{ color: "#858796" }}>
            {" "}
            (เช่น ชุดอาหารกลางวัน Combo ข้าว+น้ำ){" "}
          </span>
        </div>
        {optionOpen === 1 ? (
          <OptionProductSetEdit
            listoptionstandard={listoptionstandard}
            setListOptionstandard={setListOptionstandard}
            setProductset={setProductset}
            productset={productset}
            optionsProduct={optionsProduct}
            values={{}}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export function OptionProductSetEdit(props) {
  const addHandeler = () => {
    props.setProductset((optionproduct) => [
      ...optionproduct,
      {
        id: nanoid(),
        productID: "",
        productName: "",
        productNum: 1,
        productPrice: 0,
        productsumPrice: 0,
      },
    ]);
  };

  const handleDeleteoption = (id) => {
    props.setProductset((optionlist) =>
      optionlist.filter((item) => item.id !== id)
    );
  };

  const handleproductChange = (e, id) => {
    props.setProductset((prev) =>
      prev.map((el) =>
        el.id === id
          ? {
              ...el,
              productPrice: (el.productPrice = e.productPrice),
              productID: (el.productID = e.value),
              productNum: (el.productNum =
                el.productNum > 1 ? el.productNum : 1),
            }
          : el
      )
    );
  };

  const handleproductNumChange = (id, value) => {
    props.setProductset((prev) =>
      prev.map((el) =>
        el.id === id
          ? {
              ...el,
              productNum: (el.productNum = value),
            }
          : el
      )
    );
  };

  const sumPrice =
    props.productset &&
    props.productset.length > 0 &&
    props.productset
      .map((pd) => pd.productPrice * pd.productNum)
      .reduce((prev, curr) => prev + curr);

  const sumNum =
    props.productset &&
    props.productset.length > 0 &&
    props.productset
      .map((pd) => pd.productNum)
      .reduce((prev, curr) => parseInt(prev) + parseInt(curr));

  return (
    <div className="px-5 py-4">
      <div className=" mt-2 ps-2">
        {props.productset.map((item, index) => (
          <div key={item.id}>
            <div className="row gx-4 align-items-center">
              <div className="col">
                <Form.Group className="mb-2 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ชื่อสินค้า {item.productID}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    as="select"
                    name="product"
                    className="w-100 select-st-1"
                    options={props.optionsProduct}
                    placeholder="เลือกสินค้า"
                    onChange={(e) => handleproductChange(e, item.id)}
                    value={props.optionsProduct.filter(
                      (i) => i.value === item.productID
                    )}
                  />
                </Form.Group>
              </div>
              <div className="col-auto">
                <Form.Group
                  className="mb-2 form-st-3 pb-1"
                  style={{ width: "150px" }}
                >
                  <Form.Label className="mb-1">จำนวน</Form.Label>
                  <Form.Control
                    type="text"
                    name="optionName"
                    className="form-control"
                    placeholder="จำนวน"
                    defaultValue={item.productNum}
                    onChange={(e) =>
                      handleproductNumChange(item.id, e.target.value)
                    }
                  />
                </Form.Group>
              </div>
              <div className="col-auto">
                <Form.Group
                  className="mb-2 form-st-3 pb-1"
                  style={{ width: "150px" }}
                >
                  <Form.Label className="mb-1">ราคาขาย</Form.Label>
                  <Form.Control
                    type="text"
                    name="optionName"
                    className="form-control"
                    placeholder="ราคาขาย"
                    value={item.productPrice}
                    disabled={true}
                  />
                </Form.Group>
              </div>
              <div className="col-auto">
                <Form.Group
                  className="mb-2 form-st-3 pb-1"
                  style={{ width: "150px" }}
                >
                  <Form.Label className="mb-1">ราคารวม</Form.Label>
                  <Form.Control
                    type="text"
                    name="optionName"
                    className="form-control"
                    placeholder="ราคารวม"
                    value={(item.productPrice * item.productNum).toFixed(2)}
                    disabled={true}
                  />
                </Form.Group>
              </div>
              <div className="col-auto">
                <div style={{ width: "50px" }}>
                  {index > 0 ? (
                    <button
                      type="button"
                      className="btn  btn-delete-st-1"
                      onClick={() => handleDeleteoption(item.id)}
                    ></button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="row gx-4 align-items-center">
          <div className="col">
            <button
              type="button"
              className="btn btn-st-3 mb-4"
              onClick={addHandeler}
            >
              เพิ่มสินค้าจัดชุด
            </button>
          </div>
          <div className="col-auto">
            <div style={{ width: "150px" }}>
              <Form.Group
                className="mb-2 form-st-3 pb-1"
                style={{ width: "150px" }}
              >
                <Form.Label className="mb-1">จำนวน</Form.Label>
                <Form.Control
                  type="text"
                  name="optionName"
                  className="form-control"
                  placeholder="จำนวน"
                  value={sumNum ? sumNum : 0}
                  disabled={true}
                />
              </Form.Group>
            </div>
          </div>
          <div className="col-auto">
            <div style={{ width: "150px" }}></div>
          </div>
          <div className="col-auto">
            <div style={{ width: "150px" }}>
              <Form.Group
                className="mb-2 form-st-3 pb-1"
                style={{ width: "150px" }}
              >
                <Form.Label className="mb-1">ราคารวม</Form.Label>
                <Form.Control
                  type="text"
                  name="optionName"
                  className="form-control"
                  placeholder="ราคารวม"
                  value={sumPrice ? sumPrice.toFixed(2) : 0}
                  disabled={true}
                />
              </Form.Group>
            </div>
          </div>
          <div className="col-auto">
            <div style={{ width: "50px" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
