import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
import { ModalAllder } from "layouts/ModalAllder";
import * as Yup from 'yup';

const productValidationSchema = Yup.object().shape({
  price: Yup.number()
    .required('กรุณากรอกราคา')
    .min(0, 'ราคาต้องไม่ต่ำกว่า 0')
    .typeError('กรุณากรอกราคาเป็นตัวเลข')
});

export default function ProductSpecificModal({
  modal,
  setModal,
  productOptionDetail = {
    optionDetail: { name: "", price: "", isVisible: false },
  },
  setProductOptionDetail,
  onSubmit,
  ...props
}) {
  const [errors, setErrors] = useState({});

  const validatePrice = async (price) => {
    try {
      await productValidationSchema.validateAt('price', { price });
      setErrors(prev => ({ ...prev, price: '' }));
      return true;
    } catch (err) {
      setErrors(prev => ({ ...prev, price: err.message }));
      return false;
    }
  };

  const handleSave = async () => {
    const isValid = await validatePrice(productOptionDetail?.optionDetail?.price);
    if (isValid) {
      onSubmit(productOptionDetail);
    }
  };

  return (
    <ModalAllder
      modal={modal}
      setModal={setModal}
      htitle={"แก้ไขรายการตัวเลือกเฉพาะ"}
      hbtn={true}
    >
      <fieldset>
        <div className="row">
          <div className="col-12">
            <Form.Group className="mb-4 form-st-3 pb-1">
              <Form.Label className="mb-1">ชื่อรายการ</Form.Label>
              <Form.Control
                type="text"
                name="listName"
                className="form-control"
                placeholder="กรอกชื่อรายการ"
                defaultValue={productOptionDetail?.optionDetail?.name || ""}
                disabled={true}
                onChange={(e) => {
                  setProductOptionDetail((prev) => ({
                    ...prev,
                    optionDetail: {
                      ...prev?.optionDetail,
                      name: e.target.value,
                    },
                  }));
                }}
              />
            </Form.Group>
          </div>
          <div className="col-12">
            <Form.Group className="mb-4 form-st-3 pb-1">
              <Form.Label className="mb-1">
                ราคา <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="number"
                name="listPrice"
                className={`form-control ${errors.price ? 'is-invalid' : ''}`}
                placeholder="กรอกราคา"
                value={productOptionDetail?.optionDetail?.price ?? ""}
                onChange={async (e) => {
                  const newValue = e.target.value;
                  setProductOptionDetail((prev) => ({
                    ...prev,
                    optionDetail: {
                      ...prev?.optionDetail,
                      price: newValue,
                    },
                  }));
                  await validatePrice(newValue);
                }}
              />
              {errors.price && (
                <Form.Control.Feedback type="invalid">
                  {errors.price}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </div>

          <div className="col-12">
            <div className="pt-2 pb-1 px-3">
              <Form.Check
                type="switch"
                id="display-specific-menu"
                label="แสดงในเมนู"
                name="optionspecificStatus"
                className="custom-switch-st-1 s1 mb-1"
                checked={productOptionDetail?.optionDetail?.isVisible || false}
                onChange={(e) => {
                  setProductOptionDetail((prev) => ({
                    ...prev,
                    optionDetail: {
                      ...prev?.optionDetail,
                      isVisible: e.target.checked,
                    },
                  }));
                }}
              />
            </div>
          </div>
        </div>
      </fieldset>
      <div className="text-end pb-4 pt-4 pe-3">
        <div className="d-flex justify-end align-items-center pt-3 btn-group-control">
          <Button
            className="btn btn-st-6 ml-auto me-3 bg-transparent"
            onClick={() => setModal((prev) => ({ ...prev, open: false }))}
          >
            ยกเลิก
          </Button>
          <Button type="button" className="btn-st-3" onClick={handleSave}>
            บันทึก
          </Button>
        </div>
      </div>
    </ModalAllder>
  );
}