import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import SelectCategory from "fragments/SelectCategory";
import DataTableLayout from "layouts/DatatableLayout";

import { ModalAllder } from "layouts/ModalAllder";
import { DataGrid } from "@mui/x-data-grid";

export default function ProductSelectModal({ handleSubmit, modal, setModal, ...props }) {
  const [productSelected, setProductSelected] = useState([]);
  const [productdata, setProductdata] = useState([]);
  const getproductData = () => {
    fetch("/json/product.json")
      .then((response) => response.json())
      .then((result) => {
        setProductdata(result);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getproductData();
  }, []);

  const columnsd = [
    {
      field: "productCode",
      headerName: "รหัสสินค้า",
      width: 170,
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => <div style={{ color: "#2DC76D", fontWeight: 500 }}>{row.productCode}</div>,
    },

    {
      field: "productName",
      headerName: "ชื่อสินค้า",
      flex: 1,
      minWidth: 240,
      sortable: false,
      renderCell: ({ row }) => (
        <div className="pimage !items-center text-base">
          {row.productImage ? (
            <img src={row.productImage} alt={row.productName} />
          ) : (
            <div className="text-center mr-2 mt-1">
              <div
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "#F4F4F4",
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span style={{ color: "#6D788D" }}>{row.name.slice(0, 1).toUpperCase()}</span>
              </div>
            </div>
          )}
          <div>
            <div style={{ color: "rgba(76, 78, 100, 0.87)", fontWeight: 400 }}>{row.productName}</div>
          </div>
        </div>
      ),
    },
    {
      field: "productCategory",
      headerName: "หมวดหมู่หลัก",
      width: 180,
      sortable: false,
    },
    {
      field: "productsubCategory",
      headerName: "หมวดหมู่ย่อย",
      width: 180,
      sortable: false,
    },
    {
      field: "productTax",
      headerName: "ภาษี",
      width: 180,
      sortable: false,
    },
    {
      field: "productPrice",
      headerName: "ราคา",
      width: 170,
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <div className="w-100">
          <div className="text-end mb-1">{row.productPrice}</div>
          <div className="text-end " style={{ color: "#2DC76D", fontSize: "12px" }}>
            {row.productType}
          </div>
        </div>
      ),
    },
    {
      field: "productStatus",
      headerName: "สถานะ",
      width: 170,
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <div>
          {" "}
          <div className={"product-status status" + row.productStatus}></div>
          {row.productStatus == 2 ? (
            <div className="text-center">
              <div className="tc-7 fs-14">โดย {row.userCancel}</div>
              <div className="tc-7 fs-14">{row.dateCancel}</div>
            </div>
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];

  const [filterText, setFilterText] = useState("");
  const filteredItems = productdata.filter(
    (item) =>
      (item.productName && item.productName.toLowerCase().includes(filterText.toLowerCase()) && item.productStatus == 1) ||
      (item.productCategory && item.productCategory.toLowerCase().includes(filterText.toLowerCase()) && item.productStatus == 1)
  );

  return (
    <ModalAllder modal={modal} setModal={setModal} htitle={"ค้นหาสินค้า"} hbtn={true}>
      <div className="d-flex align-items-start  mb-4">
        <div
          className="fs-18 fw-6  mb-3 d-inline-block me-auto text-white"
          style={{
            borderRadius: "8px",
            background: "#2DC76D",
            padding: "6.5px 25px",
          }}
        >
          สินค้าทั้งหมด
        </div>
        <div className="d-flex align-items-center pt-2 pb-2 btn-group-control">
          <Button type="button" className="btn-st-5 me-auto " style={{ width: "100px" }} onClick={props.onHide}>
            ยกเลิก
          </Button>
          <div className="bx-count mx-3">เลือกไว้ {productSelected ? productSelected.length : 0} รายการ</div>
          <Button
            type="button"
            className="btn-st-3"
            style={{ width: "130px" }}
            disabled={!productSelected || productSelected.length == 0}
            onClick={() => {
              handleSubmit(productSelected);
            }}
          >
            เพิ่มรายการ
          </Button>
        </div>
      </div>

      <div className="d-flex align-items-center mb-3">
        <div className="me-auto" style={{ flex: "0 0 220px" }}>
          <SelectCategory setFilterText={setFilterText} size="sm" />
        </div>
        <div className="" style={{ flex: "0 0 180px" }}>
          <input type="text" className="form-control form-st-4" placeholder="ค้นหาชื่อ,รหัสสินค้า" onKeyUp={(e) => setFilterText(e.target.value)} />
        </div>
      </div>

      {/* <DataTableLayout 
        item={filteredItems}
        columns={columns}
        isSelectRows={true}
        PerPage={5}
        handleRowSelected={handleRowSelected}
        />
 */}

      <DataGrid
        getRowId={(row) => row.productID}
        getRowHeight={() => "auto"}
        rows={filteredItems}
        columns={columnsd}
        // autoHeight={true}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10, 20, 30]}
        checkboxSelection
        onRowSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRows = filteredItems.filter((row) => selectedIDs.has(row.productID));

          setProductSelected(selectedRows);
        }}
        slotProps={{
          pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
        }}
        sx={{
          fontFamily: "Sarabun",
          fontSize: "17px",
          ".MuiDataGrid-columnHeaderTitle": {
            fontFamily: "Sarabun",
            fontSize: "17px",
          },
          ".MuiDataGrid-cell": {
            display: "flex",
            alignItems: "center",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
        }}
      />
    </ModalAllder>
  );
}
