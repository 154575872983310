import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Chip from "@mui/material/Chip";
import { useGlobalTable } from "../../../hooks/useGlobalTable";
import { createTableColumns } from "../../../utils/createTableColumns";
import { AllderTable } from "../../../components/AllderTable";
import { branchHandler } from "../../../redux/handlers";
import { useBreadcrumb } from "../../../hooks/useBreadcrumb";
import Breadcrumb from "../../../components/Breadcrumb";

export function BranchManagement() {
  const dispatch = useDispatch();
  const { businessSlug } = useParams();

  const fetchBranches = useCallback(
    (params) =>
      dispatch(
        branchHandler.getBranchList({
          businessSlug,
          ...params,
        })
      ),
    [businessSlug, dispatch]
  );

  const {
    data: branches,
    loading,
    totalRows,
    paginationModel,
    setPaginationModel,
    handleSort,
    debouncedSearch,
    filterParams,
  } = useGlobalTable({
    fetchData: fetchBranches,
    defaultSort: "created_at",
    defaultOrder: "desc",
    defaultPageSize: 20,
  });

  const breadcrumbConfig = [
    { label: "หน้าหลัก", path: "/" },
    { label: "จัดการสาขา", path: "/branchs" },
    { label: "รายชื่อสาขา" },
  ];
  useBreadcrumb(breadcrumbConfig);

  const handleExport = async () => {
    try {
      await dispatch(
        branchHandler.exportBranchToExcel(businessSlug, filterParams.s)
      );
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const columns = createTableColumns(
    [
      {
        field: "code",
        headerName: "รหัสสาขา",
        width: 170,
        sortable: true,
        renderCell: ({ row }) => (
          <div style={{ color: "#2DC76D" }}>{row.code}</div>
        ),
      },
      {
        field: "branch_name",
        headerName: "ชื่อสาขา",
        width: 170,
        sortable: true,
        flex: 1,
        renderCell: ({ row }) => <div>{row.branch_name}</div>,
      },
      {
        field: "vat_code",
        headerName: "Vat Code",
        width: 180,
        sortable: true,
      },
      {
        field: "created_at",
        headerName: "วันที่สร้าง",
        width: 180,
        sortable: true,
        renderCell: ({ row }) => (
          <div>{new Date(row.created_at).toLocaleDateString("th-TH")}</div>
        ),
      },
      {
        field: "branchPOS",
        headerName: "จำนวน POS",
        width: 140,
        sortable: true,
        renderCell: ({ row }) => <div>{row.branchPOS.length}</div>,
      },
      {
        field: "is_status",
        headerName: "สถานะ",
        width: 180,
        sortable: true,
        renderCell: ({ row }) =>
          row.is_status ? (
            <Chip
              label="เปิดใช้งาน"
              className="!bg-[#EEFBE5] !text-[#72E128] !text-base"
            />
          ) : (
            <Chip
              label="ปิดใช้งาน"
              className="!bg-[#F4F4F4] !text-[#6D788D]/[0.87] !text-base"
            />
          ),
      },
      {
        field: "actions",
        headerName: "จัดการ",
        width: 250,
        sortable: false,
        type: "actions",
      },
    ],
    (row) => (
      <div>
        <Link
          className="btn btn-edit-st-1 me-2"
          to={`/${businessSlug}/branchs/edit/${row.id}`}
        />
        <Link
          className="btn btn-shop-st-1"
          to={`/${businessSlug}/branch/setting/info/${row.id}`}
        />
      </div>
    )
  );

  return (
    <div>
      <Breadcrumb />
      <div className="fs-24 tc-7 mb-4 lh-1">รายชื่อสาขา</div>
      <div className="card card-st-2">
        <div className="card-body px-0 py-4">
          <div className="d-flex align-items-center px-4 mb-4">
            <button
              className="btn btn-export-st-1 me-auto"
              onClick={handleExport}
            >
              Export
            </button>
            <div className="me-3">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหา ชื่อสาขา, คลังสินค้าที่เชื่อม"
                onChange={(e) => debouncedSearch(e.target.value)}
              />
            </div>
            <Link
              to={`/${businessSlug}/branchs/create`}
              className="btn btn-st-7"
            >
              เพิ่มสาขา
            </Link>
          </div>

          <AllderTable
            data={branches}
            loading={loading}
            columns={columns}
            totalRows={totalRows}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            handleSort={handleSort}
          />
        </div>
      </div>
    </div>
  );
}
