
import React, { useState,useEffect  } from 'react';
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


const getcategoryData = () => {
    const options = [];
    fetch('/json/category.json')
      .then((response) => response.json())
      .then((result) => {
        obj = {}
        obj['value'] = "";
        obj['label'] = "ทั้งหมด";
        options.push(obj);
        for(var i = 0; i < result.length; i++) {
            var obj = {};
            obj['value'] = result[i].categoryID;
            obj['label'] = result[i].categoryName;
            options.push(obj);
        }
      } )
      .catch((error) => {});

      return options;
};

export default function SelectCategory({setFilterText,...props}){

    const [optionsCategory, setOptionsCategory] = useState([]);

    useEffect(() => {
        setOptionsCategory(getcategoryData());
    }, []);
    return (
        <Select as="select"
            name="caregory"
            onChange={(e) => {
                ( e.label != 'ทั้งหมด' ? setFilterText(e.label) : setFilterText("")  )
            }}
            className={`w-100 select-st-1 pd ${props.size}`}
            options={optionsCategory}
            placeholder="เลือกหมวดหมู่"
        />
    )
}