import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setBreadcrumb,
  clearBreadcrumb,
} from "../redux/slices/breadcrumb.slice";

export const useBreadcrumb = (items) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBreadcrumb(items));
    return () => {
      dispatch(clearBreadcrumb());
    };
  }, [dispatch, items]);
};
