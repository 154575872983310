import { ENDPOINT, STATUS } from '../../constants/server/api';
import { fetchApiData } from '../../utils/apiUtils';

export const updateBusinessInfo = (businessId, formValues) => async (dispatch) => {
  const formData = new FormData();
  if (formValues.settingSubDistrictId) formData.append('subDistrictId', Number(formValues.settingSubDistrictId));
  if (formValues.settingRestaurantTypeId) formData.append('restaurantTypeId', Number(formValues.settingRestaurantTypeId));
  if (formValues.settingShopProductCategoryTypeId) formData.append('shopProductCategoryTypeId', Number(formValues.settingShopProductCategoryTypeId));
  if (formValues.settingEntityTypeId) formData.append('entityTypeId', Number(formValues.settingEntityTypeId));
  if (formValues.settingPrefixNameId) formData.append('prefixNameId', Number(formValues.settingPrefixNameId));
  if (formValues.settingPrefixNameOther) formData.append('prefixNameOther', formValues.settingPrefixNameOther);
  if (formValues.settingTaxNumber) formData.append('taxNumber', formValues.settingTaxNumber);
  if (formValues.settingFirstName) formData.append('firstName', formValues.settingFirstName);
  if (formValues.settingLastName) formData.append('lastName', formValues.settingLastName);
  if (formValues.settingAddress) formData.append('address', formValues.settingAddress);
  if (formValues.settingSlug) formData.append('slug', formValues.settingSlug);
  if (formValues.settingCompanyName) formData.append('companyName', formValues.settingCompanyName);
  if (formValues.settingImagePath) {
    if (formValues.settingImagePath instanceof File) {
      formData.append('imagePath', formValues.settingImagePath);
    } else if (typeof formValues.settingImagePath === 'string' && formValues.settingImagePath.startsWith('data:image')) {
      const blob = await fetch(formValues.settingImagePath).then((r) => r.blob());
      formData.append('imagePath', blob, 'image.jpg');
    }
  }
  await fetchApiData({
    method: 'put',
    endpoint: `${ENDPOINT.BUSINESS}/${businessId}/info`,
    data: formData,
    dispatch,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return STATUS.OK;
};

export const updateBusinessContact = (businessId, businessData) => async (dispatch) => {
  await fetchApiData({
    method: 'put',
    endpoint: `${ENDPOINT.BUSINESS}/${businessId}/contact`,
    data: businessData,
    dispatch,
  });
  return STATUS.OK;
};

export const updateBusinessSettings = (businessId, businessData) => async (dispatch) => {
  await fetchApiData({
    method: 'put',
    endpoint: `${ENDPOINT.BUSINESS}/${businessId}/settings`,
    data: businessData,
    dispatch,
  });
  return STATUS.OK;
};
export const updateBusinessSlugInfo = (businessSlug, formValues) => async (dispatch) => {
  const formData = new FormData();
  if (formValues.settingSubDistrictId) formData.append('subDistrictId', Number(formValues.settingSubDistrictId));
  if (formValues.settingRestaurantTypeId) formData.append('restaurantTypeId', Number(formValues.settingRestaurantTypeId));
  if (formValues.settingShopProductCategoryTypeId) formData.append('shopProductCategoryTypeId', Number(formValues.settingShopProductCategoryTypeId));
  if (formValues.settingEntityTypeId) formData.append('entityTypeId', Number(formValues.settingEntityTypeId));
  if (formValues.settingPrefixNameId) formData.append('prefixNameId', Number(formValues.settingPrefixNameId));
  if (formValues.settingPrefixNameOther) formData.append('prefixNameOther', formValues.settingPrefixNameOther);
  if (formValues.settingTaxNumber) formData.append('taxNumber', formValues.settingTaxNumber);
  if (formValues.settingFirstName) formData.append('firstName', formValues.settingFirstName);
  if (formValues.settingLastName) formData.append('lastName', formValues.settingLastName);
  if (formValues.settingAddress) formData.append('address', formValues.settingAddress);
  if (formValues.settingSlug) formData.append('slug', formValues.settingSlug);
  if (formValues.settingCompanyName) formData.append('companyName', formValues.settingCompanyName);
  if (formValues.settingImagePath) {
    if (formValues.settingImagePath instanceof File) {
      formData.append('imagePath', formValues.settingImagePath);
    } else if (typeof formValues.settingImagePath === 'string' && formValues.settingImagePath.startsWith('data:image')) {
      const blob = await fetch(formValues.settingImagePath).then((r) => r.blob());
      formData.append('imagePath', blob, 'image.jpg');
    }
  }
  await fetchApiData({
    method: 'put',
    endpoint: `${ENDPOINT.BUSINESS}/slug/${businessSlug}/info`,
    data: formData,
    dispatch,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return STATUS.OK;
};

export const updateBusinessSlugContact = (businessSlug, businessData) => async (dispatch) => {
  await fetchApiData({
    method: 'put',
    endpoint: `${ENDPOINT.BUSINESS}/slug/${businessSlug}/contact`,
    data: businessData,
    dispatch,
  });
  return STATUS.OK;
};

export const updateBusinessSlugSettings = (businessSlug, businessData) => async (dispatch) => {
  await fetchApiData({
    method: 'put',
    endpoint: `${ENDPOINT.BUSINESS}/slug/${businessSlug}/settings`,
    data: businessData,
    dispatch,
  });
  return STATUS.OK;
};
