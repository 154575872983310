import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import ProductDataList from "./ProductDataList";
export default function SettingReceivePoint() {
  const { branchID, kitchenID } = useParams();

  const [formdata, setFormdata] = useState({});

  const schema = yup.object().shape({
    kitchenName: yup.string().required(),
    storeHouse: yup.string().required(),
  });

  const getSettingPoint = () => {
    setFormdata({
      conversionType: 1,
      calculationFormat: "คำนวณจากมูลค่ายอดทั้งหมด",
      purchaseAmount: 100,
      receivePoint: 1,
    });
  };

  useEffect(() => {
    getSettingPoint();
  }, []);

  return (
    <div>
      <div className="card card-st-2 mb-4">
        <div className="card-body px-0 py-4">
          <Formik
            enableReinitialize
            initialValues={formdata}
            validationSchema={schema}
            onSubmit={async (values) => {}}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
              isInvalid,
              isValid,
            }) => (
              <Form className="px-4" noValidate onSubmit={handleSubmit}>
                <div className="d-flex align-items-center  mb-4">
                  <div className="tc-8 fs-20 fw-6   me-auto">
                    การตั้งค่าการได้รับคะแนน และ เงื่อนไข
                  </div>
                </div>

                <div className="row align-items-center mb-3">
                  <div className="col-3">
                    <div>การแปลงมูลค่ายอดซื้อทั้งหมดเป็นคะแนน</div>
                  </div>

                  <div className="col-2"></div>
                </div>
                <div className="row align-items-center">
                  <div className="col-3">
                    <Form.Group className="mb-3 form-st-3 pb-1">
                      <Form.Label className="mb-1">รูปแบบการคำนวณ </Form.Label>
                      <Form.Control
                        type="text"
                        name="calculationFormat"
                        className="form-control"
                        placeholder="รูปแบบการคำนวณ"
                        value={values.calculationFormat}
                        onChange={handleChange}
                        isInvalid={!!errors.calculationFormat}
                        disabled={true}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-2">
                    <Form.Group className="mb-3 form-st-3 pb-1">
                      <Form.Label className="mb-1">มูลค่ายอดซื้อ </Form.Label>
                      <Form.Control
                        type="number"
                        name="purchaseAmount"
                        className="form-control"
                        placeholder="มูลค่ายอดซื้อ"
                        value={values.purchaseAmount}
                        onChange={handleChange}
                        isInvalid={!!errors.price}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-1 text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="24"
                      viewBox="0 0 32 24"
                      fill="none"
                    >
                      <path
                        d="M31.0607 13.0607C31.6464 12.4749 31.6464 11.5251 31.0607 10.9393L21.5147 1.3934C20.9289 0.807611 19.9792 0.807611 19.3934 1.3934C18.8076 1.97919 18.8076 2.92893 19.3934 3.51472L27.8787 12L19.3934 20.4853C18.8076 21.0711 18.8076 22.0208 19.3934 22.6066C19.9792 23.1924 20.9289 23.1924 21.5147 22.6066L31.0607 13.0607ZM0 13.5H30V10.5H0V13.5Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                  <div className="col-2">
                    <Form.Group className="mb-3 form-st-3 pb-1">
                      <Form.Label className="mb-1">คะแนนที่ได้รับ </Form.Label>
                      <Form.Control
                        type="number"
                        name="receivePoint"
                        className="form-control"
                        placeholder="คะแนนที่ได้รับ"
                        value={values.receivePoint}
                        onChange={handleChange}
                        isInvalid={!!errors.receivePoint}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-2">
                    <Form.Group className="mb-3 form-st-3 pb-1">
                      <Form.Label className="mb-1">ผลลัพธ์ </Form.Label>
                      <Form.Control
                        type="text"
                        name="result"
                        className="form-control ะแ-5"
                        placeholder="ผลลัพธ์"
                        value={
                          values.purchaseAmount +
                          " บาท ได้รับ " +
                          values.receivePoint +
                          " คะแนน"
                        }
                        onChange={handleChange}
                        isInvalid={!!errors.result}
                        disabled={true}
                        style={{ color: "#F95057" }}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="text-end">
                  <Button
                    type="submit"
                    className="btn-st-3  "
                    style={{ width: "100px" }}
                  >
                    {" "}
                    บันทึก
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <ProductDataList />
    </div>
  );
}
