import Button from "react-bootstrap/Button";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
export default function ButtonHideOption({ handleClick, label }) {
  return (
    <div className="flex items-center">
      <Button
        type="button"
        className="btn  btn-st-3 !w-[27px] !h-[27px] !border-[#E3E8F0] !bg-[#E3E8F0] !px-[2px]  !py-0  !rounded-full"
        onClick={() => {
          handleClick();
        }}
      >
        <VisibilityOffOutlinedIcon className="!text-[#93A1B8] !text-[18px] !mt-[-4px]" />
      </Button>
      {label ? <div className="text-[#FF0000] ml-3">ซ่อนตัวเลือก</div> : ""}
    </div>
  );
}
