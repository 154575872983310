import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Chip from "@mui/material/Chip";
import Select from "react-select";
import { useGlobalTable } from "../../../hooks/useGlobalTable";
import { createTableColumns } from "../../../utils/createTableColumns";
import { AllderTable } from "../../../components/AllderTable";
import {
  productBranchHandler,
  productCategoryHandler,
} from "../../../redux/handlers";
import {
  ConfirmCancelSaleModal,
  SuccessModal,
  ProductIncludeModal,
} from "./components/modals";
import { PaginatedItems } from "./components/PaginationItems";

export function ProductBranchManagement() {
  const dispatch = useDispatch();
  const { branchID, businessSlug } = useParams();
  const [productStatus, setProductStatus] = useState("all");
  const [btnproductstatus, setBtnproductstatus] = useState(1);
  const [unavailableCount, setUnavailableCount] = useState(0);
  const [availableCount, setAvailableCount] = useState(0);
  const [displayMode, setDisplayMode] = useState("table");
  const [optionsCategory, setOptionsCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [textSuccess, setTextSuccess] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [modalproductlistShow, setModalproductlistShow] = useState({
    open: false,
    x: 0,
    y: 0,
    width: 1270,
    height: 680,
    pin: false,
    mode: "normal",
    modalname: "edit",
  });

  const fetchCategories = async () => {
    try {
      const response = await dispatch(
        productCategoryHandler.getAllProductCategories(businessSlug)
      );
      if (response) {
        const options = [
          { value: "all", label: "ทั้งหมด" },
          ...response.map((category) => ({
            value: category.id,
            label: category.name,
          })),
        ];
        setOptionsCategory(options);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
    setFilterParams((prev) => ({
      ...prev,
      product_category_id:
        selectedOption?.value === "all" ? "" : selectedOption?.value,
    }));
  };

  const fetchProducts = useCallback(
    (params) =>
      dispatch(
        productBranchHandler.getBranchProducts({
          branch_id: branchID,
          product_category_id: params.product_category_id,
          is_already_sale: productStatus === "all" ? true : false,
          page: params.page ?? 1,
          perPage: params.per_page,
          s: params.s,
          sortBy: params.sortBy,
          sortOrder: params.sortOrder,
        })
      ),
    [branchID, dispatch, productStatus]
  );

  const {
    data: products,
    loading,
    totalRows,
    paginationModel,
    setPaginationModel,
    handleSort,
    debouncedSearch,
    filterParams,
    setFilterParams,
  } = useGlobalTable({
    fetchData: fetchProducts,
    defaultSort: "created_at",
    defaultOrder: "desc",
    defaultPageSize: 20,
    dependencies: [productStatus],
  });

  useEffect(() => {
    const fetchProductCounts = async () => {
      try {
        const unavailableResponse = await dispatch(
          productBranchHandler.getBranchProducts({
            branch_id: branchID,
            product_category_id: filterParams?.product_category_id,
            is_already_sale: false,
            page: 1,
            perPage: 1,
            s: filterParams?.s,
            sortBy: filterParams?.sortBy,
            sortOrder: filterParams?.sortOrder,
          })
        );
        setUnavailableCount(unavailableResponse?.meta?.total || 0);

        const availableResponse = await dispatch(
          productBranchHandler.getBranchProducts({
            branch_id: branchID,
            product_category_id: filterParams?.product_category_id,
            is_already_sale: true,
            page: 1,
            perPage: 1,
            s: filterParams?.s,
            sortBy: filterParams?.sortBy,
            sortOrder: filterParams?.sortOrder,
          })
        );
        setAvailableCount(availableResponse?.meta?.total || 0);
      } catch (error) {
        console.error("Error fetching product counts:", error);
      }
    };

    fetchProductCounts();
  }, [branchID, dispatch, filterParams]);

  const handleConfirmDelete = async () => {
    try {
      if (selectedProduct) {
        await dispatch(
          productBranchHandler.deleteBranchProduct(selectedProduct.id)
        );
        setTextSuccess("ยกเลิกการขายสำเร็จ");
        setModalSuccess(true);

        window.location.reload();

        // await fetchProducts(filterParams);

        // const unavailableResponse = await dispatch(
        //   productBranchHandler.getBranchProducts({
        //     branch_id: branchID,
        //     product_category_id: filterParams?.product_category_id,
        //     is_already_sale: false,
        //     page: 1,
        //     perPage: 1,
        //     s: filterParams?.s,
        //     sortBy: filterParams?.sortBy,
        //     sortOrder: filterParams?.sortOrder,
        //   })
        // );
        // setUnavailableCount(unavailableResponse?.meta?.total || 0);

        // const availableResponse = await dispatch(
        //   productBranchHandler.getBranchProducts({
        //     branch_id: branchID,
        //     product_category_id: filterParams?.product_category_id,
        //     is_already_sale: true,
        //     page: 1,
        //     perPage: 1,
        //     s: filterParams?.s,
        //     sortBy: filterParams?.sortBy,
        //     sortOrder: filterParams?.sortOrder,
        //   })
        // );
        // setAvailableCount(availableResponse?.meta?.total || 0);
      }
      setShowConfirmModal(false);
      setSelectedProduct(null);
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const columns = createTableColumns(
    [
      {
        field: "product.sku",
        headerName: "รหัสสินค้า",
        width: 150,
        sortable: true,
        renderCell: ({ row }) => (
          <div style={{ color: "#2DC76D", fontWeight: 500 }}>
            {row.product.sku}
          </div>
        ),
      },
      {
        field: "product.name",
        headerName: "ชื่อสินค้า",
        flex: 1,
        minWidth: 350,
        sortable: true,
        renderCell: ({ row }) => (
          <div className="pimage !items-center text-base">
            {row.product.image_path ? (
              <img
                src={row.product.image_path}
                alt={row.product.image_path}
                className="w-12 h-12 object-cover rounded mr-3"
              />
            ) : (
              <div className="text-center mr-2 mt-1">
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    backgroundColor: "#F4F4F4",
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span style={{ color: "#6D788D" }}>
                    {row.product?.name.slice(0, 1).toUpperCase()}
                  </span>
                </div>
              </div>
            )}
            <div style={{ color: "rgba(76, 78, 100, 0.87)", fontWeight: 400 }}>
              {row.product?.name}
            </div>
          </div>
        ),
      },
      {
        field: "productCategory.name",
        headerName: "หมวดหมู่หลัก",
        width: 150,
        sortable: true,
        renderCell: ({ row }) => <div>{row.product.productCategory?.name}</div>,
      },
      {
        field: "productSubCategory.name",
        headerName: "หมวดหมู่ย่อย",
        width: 150,
        sortable: true,
        renderCell: ({ row }) => (
          <div>{row.product.productSubCategory?.name}</div>
        ),
      },
      {
        field: "productUnitName",
        headerName: "หน่วย",
        width: 100,
        sortable: true,
        renderCell: ({ row }) => <div>{row.product.productSaleUnit?.name}</div>,
      },
      {
        field: "productPrice",
        headerName: "ราคา",
        width: 120,
        sortable: true,
        renderCell: ({ row }) => (
          <div className="text-[#2DC76D]">
            {row.data?.price || row.product?.price}
          </div>
        ),
      },
      {
        field: "productStatus",
        headerName: "สถานะ",
        width: 140,
        sortable: true,
        renderCell: ({ row }) =>
          row.data?.is_available == null ? (
            row.product?.is_available ? (
              <Chip
                label="พร้อมขาย"
                className="!bg-[#EEFBE5] !text-[#72E128] !text-base"
              />
            ) : (
              <Chip
                label="ไม่พร้อมขาย"
                className="!bg-[#F4F4F4] !text-[#6D788D]/[0.87] !text-base"
              />
            )
          ) : row.data?.is_available ? (
            <Chip
              label="พร้อมขาย"
              className="!bg-[#EEFBE5] !text-[#72E128] !text-base"
            />
          ) : (
            <Chip
              label="ไม่พร้อมขาย"
              className="!bg-[#F4F4F4] !text-[#6D788D]/[0.87] !text-base"
            />
          ),
      },
      {
        field: "actions",
        headerName: "จัดการ",
        width: 80,
        sortable: false,
        type: "actions",
      },
    ],
    (row) => (
      <div className="space-x-1">
        <Link
          to={`/${businessSlug}/branch/setting/product/${branchID}/manage/${row.id}`}
          className="btn btn-edit-st-1"
        />
        <button
          className="btn btn-delete-st-3"
          onClick={(e) => {
            e.preventDefault();
            setSelectedProduct(row);
            setShowConfirmModal(true);
          }}
        />
      </div>
    )
  );

  const handleSubmit = async (selectedProducts) => {
    try {
      const productIds = selectedProducts.map((product) => product.id);
      await dispatch(
        productBranchHandler.createBranchProduct({
          branch_id: parseInt(branchID),
          productIds: productIds,
        })
      );

      // Refresh the page to show updated data
      window.location.reload();

      // await fetchProducts(filterParams);

      // const unavailableResponse = await dispatch(
      //   productBranchHandler.getBranchProducts({
      //     branch_id: branchID,
      //     product_category_id: filterParams?.product_category_id,
      //     is_already_sale: false,
      //     page: 1,
      //     perPage: 1,
      //     s: filterParams?.s,
      //     sortBy: filterParams?.sortBy,
      //     sortOrder: filterParams?.sortOrder,
      //   })
      // );
      // setUnavailableCount(unavailableResponse?.meta?.total || 0);

      // const availableResponse = await dispatch(
      //   productBranchHandler.getBranchProducts({
      //     branch_id: branchID,
      //     product_category_id: filterParams?.product_category_id,
      //     is_already_sale: true,
      //     page: 1,
      //     perPage: 1,
      //     s: filterParams?.s,
      //     sortBy: filterParams?.sortBy,
      //     sortOrder: filterParams?.sortOrder,
      //   })
      // );
      // setAvailableCount(availableResponse?.meta?.total || 0);

      setModalproductlistShow((prev) => ({ ...prev, open: false }));
    } catch (error) {
      console.error("Error creating branch products:", error);
    }
  };

  return (
    <div className="p-3">
      <div className="mb-3">
        <Button
          type="button"
          className={
            btnproductstatus == 1
              ? "btn-category px-4 active"
              : "btn-category px-4"
          }
          onClick={() => {
            setBtnproductstatus(1);
            setProductStatus("all");
          }}
        >
          สินค้าพร้อมขาย (<span>{availableCount}</span>)
        </Button>

        <Button
          type="button"
          className={
            btnproductstatus == 0
              ? "btn-category px-4 active"
              : "btn-category px-4"
          }
          onClick={() => {
            setBtnproductstatus(0);
            setProductStatus("unavailable");
          }}
        >
          สินค้าไม่พร้อมขาย (<span>{unavailableCount}</span>)
        </Button>
      </div>

      <div className="bg-white rounded-lg shadow">
        <div className="p-4">
          <div className="flex items-center mb-4 flex-wrap">
            <div className="flex items-center space-x-3 mb-2">
              <button
                className={`btn ${displayMode === "table"
                  ? "btn-display-product d1 active"
                  : "btn-display-product d1"
                  }`}
                onClick={() => setDisplayMode("table")}
              />
              <button
                className={`btn ${displayMode === "grid"
                  ? "btn-display-product d2 active"
                  : "btn-display-product d2"
                  }`}
                onClick={() => setDisplayMode("grid")}
              />
              <div className="w-48 ml-2">
                <Select
                  name="category"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  className="select-st-1 pd"
                  options={optionsCategory}
                  placeholder="เลือกหมวดหมู่"
                />
              </div>
            </div>

            <div className="flex items-center space-x-3 mb-2 ml-auto">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหารหัสสินค้า ชื่อสินค้า"
                onChange={(e) => debouncedSearch(e.target.value)}
              />
              <Button
                onClick={() =>
                  setModalproductlistShow((prev) => ({ ...prev, open: true }))
                }
                className="btn btn-st-7 px-4 w-[200px]"
              >
                นำเข้าสินค้า
              </Button>
            </div>
          </div>

          {displayMode === "table" && (
            <AllderTable
              data={products}
              loading={loading}
              columns={columns}
              totalRows={totalRows}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              handleSort={handleSort}
            />
          )}
        </div>
      </div>

      {displayMode === "grid" ? (
        <PaginatedItems
          fetchData={fetchProducts}
          itemsPerPage={10}
          filterParams={filterParams}
          loading={loading}
          totalRows={totalRows}
        />
      ) : null}

      <ProductIncludeModal
        show={modalproductlistShow}
        onHide={() =>
          setModalproductlistShow((prev) => ({ ...prev, open: false }))
        }
        handleSubmit={handleSubmit}
        modal={modalproductlistShow}
        setModal={setModalproductlistShow}
        businessSlug={businessSlug}
        branchId={branchID}
      />

      <ConfirmCancelSaleModal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        formdata={selectedProduct?.product}
        onSuccess={handleConfirmDelete}
      />

      <SuccessModal
        show={modalSuccess}
        onHide={() => setModalSuccess(false)}
        textsuccess={textSuccess}
      />
    </div>
  );
}

export default ProductBranchManagement;
