import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
import ImageUpload from "../ImageUpload";
import { OptionStandard } from "../form-section/OptionStandard";
import { OptionProductSpecific } from "../form-section/OptionProductSpecific";
import { OptionAnnotation } from "../form-section/OptionAnnotation";
import { ConfirmCancelSaleModal, SuccessModal } from "../modals";
import {
  productHandler,
  productCategoryHandler,
  settingProductUnitHandler,
  userBusinessGroupHandler,
  productBranchHandler,
} from "../../../../../redux/handlers";

const initialProductState = {
  sku: "",
  name: "",
  barcode: "",
  image_path: "",
  product_category_id: "",
  product_sub_category_id: "",
  product_sale_unit_id: "",
  price: "",
  price_delivery: "",
  cost: "",
  product_vat_type: "NO_VAT",
  is_active: true,
  is_available: false,
  is_visible: false,
  is_excluded_from_promotions: false,
  is_recommended: false,
  is_weight_based: false,
  option_standard_status: false,
  option_standard: "",
  option_product_status: false,
  option_products: [],
  option_note_status: false,
  option_notes: [],
  product_set_status: false,
  product_sets: [],
  annotation: "",
  created_by: "",
  created_at: new Date().toLocaleDateString("th-TH"),
  setting_is_vat: true,
};

const productValidationSchema = yup.object().shape({
  price: yup
    .number()
    .typeError("กรุณากรอกราคาสินค้าเป็นตัวเลข")
    .required("กรุณากรอกราคาสินค้า")
    .min(0, "ราคาสินค้าต้องไม่ติดลบ"),
  price_delivery: yup
    .number()
    .typeError("กรุณากรอกราคาเดลิเวอรี่เป็นตัวเลข")
    .nullable()
    .min(0, "ราคาเดลิเวอรี่ต้องไม่ติดลบ"),
  cost: yup
    .number()
    .typeError("กรุณากรอกต้นทุนเป็นตัวเลข")
    .nullable()
    .min(0, "ต้นทุนต้องไม่ติดลบ"),
});

const ProductInformation = () => {
  const { branchID, productID, businessSlug } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const [selectOptions, setSelectOptions] = useState({
    categories: [],
    subCategories: [],
    saleUnits: [],
  });

  const [vatRateOptions, setVatRateOptions] = useState([
    { value: "NO_VAT", label: "ไม่มีภาษี" },
    { value: "ZERO_VAT", label: "VAT 0%" },
    { value: "HAS_VAT", label: "VAT 1%" },
  ]);

  const [productData, setProductData] = useState({
    ...initialProductState,
    businessSlug,
  });

  const fetchProductCategories = async () => {
    try {
      const response = await dispatch(
        productCategoryHandler.getAllProductCategories(productData.businessSlug)
      );
      if (response) {
        setSelectOptions((prevOptions) => ({
          ...prevOptions,
          categories: response.map((category) => ({
            value: category.id,
            label: category.name,
          })),
        }));
      }
    } catch (error) {
      console.error("Error fetching product categories:", error);
    }
  };

  const fetchProductSubCategories = async (categoryId) => {
    if (!categoryId) {
      setSelectOptions((prevOptions) => ({
        ...prevOptions,
        subCategories: [],
      }));
      return;
    }

    try {
      const response = await dispatch(
        productCategoryHandler.getAllProductSubCategoriesByMainCatId(
          productData.businessSlug,
          categoryId
        )
      );
      if (response) {
        setSelectOptions((prevOptions) => ({
          ...prevOptions,
          subCategories: response.map((subCategory) => ({
            value: subCategory.id,
            label: subCategory.name,
          })),
        }));
      }
    } catch (error) {
      console.error("Error fetching product subcategories:", error);
    }
  };

  const fetchSaleUnits = async () => {
    try {
      const response = await dispatch(
        settingProductUnitHandler.getAllProductSaleUnits(businessSlug)
      );
      if (response) {
        setSelectOptions((prevOptions) => ({
          ...prevOptions,
          saleUnits: response.map((unit) => ({
            value: unit.id,
            label: unit.name,
          })),
        }));
      }
    } catch (error) {
      console.error("Error fetching sale units:", error);
    }
  };

  const fetchUserProfile = async () => {
    try {
      const response = await dispatch(
        userBusinessGroupHandler.getUserBusinessProfile()
      );

      let creatorName;
      let vatPercentage;
      creatorName = `${response.first_name} ${response.last_name}`;

      if (response.business) {
        if (response.setting_is_vat) {
          setProductData((prevData) => ({ ...prevData, setting_is_vat: true }));
          vatPercentage = response.setting_vat_percen;
        } else {
          setProductData((prevData) => ({
            ...prevData,
            setting_is_vat: false,
          }));
        }
      } else if (response.businessGroup?.business.length > 0) {
        const currentBusiness = response.businessGroup.business.find(
          (business) => business.slug === businessSlug
        );
        if (currentBusiness) {
          if (currentBusiness.setting_is_vat) {
            setProductData((prevData) => ({
              ...prevData,
              setting_is_vat: true,
            }));
            vatPercentage = currentBusiness.setting_vat_percen;
          } else {
            setProductData((prevData) => ({
              ...prevData,
              setting_is_vat: false,
            }));
          }
        }
      }

      if (vatPercentage) {
        setVatRateOptions([
          { value: "NO_VAT", label: "ไม่มีภาษี" },
          { value: "ZERO_VAT", label: "VAT 0%" },
          { value: "HAS_VAT", label: `VAT ${vatPercentage}%` },
        ]);
      }

      setProductData((prevData) => ({ ...prevData, created_by: creatorName }));
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  useEffect(() => {
    const initializeProductForm = async () => {
      try {
        await Promise.all([fetchProductCategories(), fetchSaleUnits()]);

        if (productID) {
          const branchProductResponse = await dispatch(
            productBranchHandler.getBranchProductById(productID)
          );

          if (branchProductResponse) {
            const productResponse = await dispatch(
              productHandler.getProductById(branchProductResponse.product_id)
            );

            if (productResponse) {
              let formattedProductData = {
                ...productData,
                ...productResponse,
                barcode:
                  productResponse.barcode === "null"
                    ? ""
                    : productResponse.barcode,
                created_at: productResponse.created_at
                  ? new Date(productResponse.created_at).toLocaleDateString(
                      "th-TH"
                    )
                  : "",
              };

              if (
                branchProductResponse?.data &&
                Object.keys(branchProductResponse.data).length > 0
              ) {
                const branchData = branchProductResponse.data;
                formattedProductData = {
                  ...formattedProductData,
                  price: branchData.price,
                  price_delivery: branchData.price_delivery,
                  cost: branchData.cost,
                  is_visible: branchData.is_visible,
                  is_available: branchData.is_available,
                  is_recommended: branchData.is_recommended,
                  is_excluded_from_promotions:
                    branchData.is_excluded_from_promotions,
                  product_vat_type: branchData.product_vat_type,
                };
              }

              if (productResponse.product_category_id) {
                await fetchProductSubCategories(
                  productResponse.product_category_id
                );
              }

              setProductData(formattedProductData);
              setIsFormDisabled(true);
            }
          }
        }
        await fetchUserProfile();
      } catch (error) {
        console.error("Error initializing product form:", error);
      }
    };

    initializeProductForm();
  }, [productID, dispatch]);

  const handleProductUpdate = async (values) => {
    try {
      if (!productID) {
        return;
      }

      const updatePayload = {
        is_available: values.is_available,
        is_visible: values.is_visible,
        is_excluded_from_promotions: values.is_excluded_from_promotions,
        is_recommended: values.is_recommended,
        price: parseFloat(values.price),
        price_delivery: values.price_delivery
          ? parseFloat(values.price_delivery)
          : null,
        cost: values.cost ? parseFloat(values.cost) : null,
        product_vat_type: values.product_vat_type,
      };

      const response = await dispatch(
        productBranchHandler.updateBranchProduct(productID, updatePayload)
      );

      if (response) {
        setSuccessMessage("บันทึกข้อมูลสำเร็จ");
        setShowSuccessModal(true);
        setIsFormDisabled(true);
      }
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  const handleProductDelete = async (setFieldValue) => {
    try {
      await dispatch(productBranchHandler.deleteBranchProduct(productID));
      setSuccessMessage("ยกเลิกการขายสำเร็จ");
      setFieldValue("is_active", false);
      setShowSuccessModal(true);
      setTimeout(() => {
        navigate(`/${businessSlug}/branch/setting/product/${branchID}`);
      }, 1000);
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={productData}
        validationSchema={productValidationSchema}
        onSubmit={handleProductUpdate}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          setFieldValue,
          isSubmitting,
        }) => (
          <>
            <Form noValidate onSubmit={handleSubmit}>
              <div className="card card-st-3 mb-3">
                <div className="card-body px-xl-3">
                  <div className="text-[#2DC76D] fs-18 fw-bold px-3">
                    ข้อมูลสินค้า
                  </div>

                  <fieldset disabled={isFormDisabled || isSubmitting}>
                    <ImageUpload
                      setFieldValue={setFieldValue}
                      values={values}
                      isCreate={!productID}
                      errors={errors}
                      touched={touched}
                    />

                    <div className="pt-3 px-3">
                      <div className="row gx-xl-4">
                        <div className="col-xl-4">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">รหัสสินค้า</Form.Label>
                            <Form.Control
                              type="text"
                              name="sku"
                              placeholder="ชื่อสินค้า"
                              value={values.sku}
                              disabled
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.name}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-xl-4">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">
                              หมวดหมู่หลัก{" "}
                              <span className="text-danger ms-1">*</span>
                            </Form.Label>
                            <Select
                              name="product_category_id"
                              options={selectOptions.categories}
                              value={selectOptions.categories.find(
                                (option) =>
                                  option.value === values.product_category_id
                              )}
                              isDisabled={true}
                              placeholder="เลือกหมวดหมู่หลัก"
                              className="w-100 select-st-1"
                            />
                          </Form.Group>
                        </div>

                        <div className="col-xl-4">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">
                              หมวดหมู่ย่อย
                            </Form.Label>
                            <Select
                              name="product_sub_category_id"
                              options={selectOptions.subCategories}
                              value={selectOptions.subCategories.find(
                                (option) =>
                                  option.value ===
                                  values.product_sub_category_id
                              )}
                              isDisabled={true}
                              placeholder="เลือกหมวดหมู่ย่อย"
                              className="w-100 select-st-1"
                            />
                          </Form.Group>
                        </div>

                        <div className="col-xl-4">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">หน่วยขาย</Form.Label>
                            <Select
                              name="product_sale_unit_id"
                              options={selectOptions.saleUnits}
                              value={selectOptions.saleUnits.find(
                                (option) =>
                                  option.value === values.product_sale_unit_id
                              )}
                              isDisabled={true}
                              placeholder="เลือกหน่วยขาย"
                              className="w-100 select-st-1"
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>

                    <div className="mb-4 mt-4">
                      <div className="text-[#2DC76D] fs-18 fw-bold px-3 mb-4 pt-1">
                        ตั้งค่าการขาย
                      </div>
                      <div className="d-flex flex-wrap px-3">
                        <div className="mb-2 me-7">
                          <Form.Check
                            type="switch"
                            id="is_available"
                            label="พร้อมจำหน่าย"
                            checked={values.is_available}
                            onChange={(e) =>
                              setFieldValue("is_available", e.target.checked)
                            }
                            className="tc-10 s2 mb-1"
                          />
                        </div>
                        <div className="mb-2 me-7">
                          <Form.Check
                            type="switch"
                            id="is_visible"
                            label="แสดงหน้าจอลูกค้า"
                            checked={values.is_visible}
                            onChange={(e) =>
                              setFieldValue("is_visible", e.target.checked)
                            }
                            className="tc-10 s2 mb-1"
                          />
                        </div>
                        <div className="mb-2 me-7">
                          <Form.Check
                            type="switch"
                            id="is_excluded_from_promotions"
                            label="ไม่เข้าร่วมทุกโปรโมชั่น"
                            checked={values.is_excluded_from_promotions}
                            onChange={(e) =>
                              setFieldValue(
                                "is_excluded_from_promotions",
                                e.target.checked
                              )
                            }
                            className="tc-10 s2 mb-1"
                          />
                        </div>
                        <div className="mb-2 me-7">
                          <Form.Check
                            type="switch"
                            id="is_recommended"
                            label="เมนูแนะนำ"
                            checked={values.is_recommended}
                            onChange={(e) =>
                              setFieldValue("is_recommended", e.target.checked)
                            }
                            className="tc-10 s2 mb-1"
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="text-[#2DC76D] fs-18 fw-bold px-3 mb-2 pt-1">
                        ราคาขาย
                      </div>
                      <div className="px-xl-3 row">
                        <div className="col-xl-3">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">
                              ราคาสินค้า{" "}
                              <span className="text-danger ms-1">*</span>
                            </Form.Label>
                            <Form.Control
                              type="number"
                              name="price"
                              placeholder="ราคาสินค้า"
                              value={values.price}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.price && errors.price}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.price}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-xl-3">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">
                              ราคาขายเดลิเวอรี่
                            </Form.Label>
                            <Form.Control
                              type="number"
                              name="price_delivery"
                              placeholder="ราคาขายเดลิเวอรี่"
                              value={values.price_delivery}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.price_delivery && errors.price_delivery
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.price_delivery}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-xl-3">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">
                              ต้นทุนมาตรฐาน
                            </Form.Label>
                            <Form.Control
                              type="number"
                              name="cost"
                              placeholder="ต้นทุนมาตรฐาน"
                              value={values.cost}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.cost && errors.cost}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.cost}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        {values.setting_is_vat && (
                          <div className="col-xl-3">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                รูปแบบภาษีมูลค่าเพิ่ม
                              </Form.Label>
                              <Select
                                name="product_vat_type"
                                options={vatRateOptions}
                                value={vatRateOptions.find(
                                  (option) =>
                                    option.value === values.product_vat_type
                                )}
                                onChange={(e) =>
                                  setFieldValue(
                                    "product_vat_type",
                                    e?.value || "NO_VAT"
                                  )
                                }
                                placeholder="เลือกรูปแบบภาษีมูลค่าเพิ่ม"
                                className="w-100 select-st-1"
                              />
                            </Form.Group>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="px-xl-3 mt-3">
                      <div className="text-[#4C4E64]/[0.87] mb-2">
                        หมายเหตุภายใน :
                      </div>
                      <div className="row">
                        <div className="col-xl-6">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">หมายเหตุ</Form.Label>
                            <Form.Control
                              type="text"
                              name="annotation"
                              placeholder="หมายเหตุ"
                              value={values.annotation}
                              disabled
                            />
                          </Form.Group>
                        </div>

                        <div className="col-xl-3">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">
                              วันที่สร้าง
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="created_at"
                              placeholder="วันที่สร้าง"
                              value={values.created_at}
                              disabled
                            />
                          </Form.Group>
                        </div>

                        <div className="col-xl-3">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">
                              ผู้แก้ไข : {values.created_by}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="created_by"
                              placeholder="ผู้แก้ไข"
                              value={values.created_by}
                              disabled
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </fieldset>

                  <div className="flex justify-between items-center pt-3 w-full">
                    <div>
                      <Button
                        type="button"
                        className="btn btn-st-3 ml-4"
                        onClick={() => setShowConfirmDeleteModal(true)}
                        disabled={isSubmitting}
                        style={{
                          background: "#F95057",
                          borderColor: "#F95057",
                        }}
                      >
                        ยกเลิกการขาย
                      </Button>
                    </div>

                    <div className="text-end pt-3 btn-group-control ml-auto">
                      {isFormDisabled ? (
                        <Button
                          type="button"
                          className="btn-st-3 mr-3"
                          onClick={() => setIsFormDisabled(false)}
                          disabled={isSubmitting}
                        >
                          แก้ไข
                        </Button>
                      ) : (
                        <>
                          <Button
                            type="button"
                            className="btn-st-6 me-3 btn"
                            onClick={() => setIsFormDisabled(true)}
                            disabled={isSubmitting}
                          >
                            ยกเลิก
                          </Button>
                          <Button
                            type="submit"
                            className="btn-st-3"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? "กำลังบันทึก..." : "บันทึก"}
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Form>

            <ConfirmCancelSaleModal
              show={showConfirmDeleteModal}
              onHide={() => setShowConfirmDeleteModal(false)}
              formdata={productData}
              onSuccess={() => handleProductDelete(setFieldValue)}
            />

            <SuccessModal
              show={showSuccessModal}
              onHide={() => setShowSuccessModal(false)}
              textsuccess={successMessage}
            />
          </>
        )}
      </Formik>

      {productID && (
        <>
          <OptionStandard />
          <OptionProductSpecific
           
          />
          <OptionAnnotation
            productID={productID}
            optionStatus={productData.option_note_status}
            optionNote={productData.option_notes}
          />
        </>
      )}

      <div className="py-7" />
    </div>
  );
};

export default ProductInformation;
