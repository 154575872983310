import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { branchPaymentHandler } from "../../../../../redux/handlers";
import { Formik } from "formik";
import * as yup from "yup";

export default function PaymentQrSetting() {
  const { branchID } = useParams();
  const dispatch = useDispatch();
  const paymentForm = useSelector(
    (state) => state.branchPayment.paymentForm.payment
  );
  const qrcodeForm = useSelector(
    (state) => state.branchPayment.paymentForm.qrcode
  );

  const schema = yup.object().shape({
    merchantID: yup.string().when("status", (status, schema) => {
      if (status) return schema.required("กรุณากรอก Merchant ID");
      return schema;
    }),
    publicID: yup.string().when("status", (status, schema) => {
      if (status) return schema.required("กรุณากรอก Public ID");
      return schema;
    }),
    secretID: yup.string().when("status", (status, schema) => {
      if (status) return schema.required("กรุณากรอก Secret Key ID");
      return schema;
    }),
    token: yup.string().when("status", (status, schema) => {
      if (status) return schema.required("กรุณากรอก Token");
      return schema;
    }),
  });

  const initialValues = {
    status: paymentForm?.is_qrcode_received || false,
    merchantID: qrcodeForm?.qrcode_merchant_id || "",
    publicID: qrcodeForm?.qrcode_public_id || "",
    secretID: qrcodeForm?.qrcode_secret_key_id || "",
    token: qrcodeForm?.qrcode_token || "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const paymentPayload = {
        ...paymentForm,
        is_qrcode_received: values.status,
      };
      await dispatch(
        branchPaymentHandler.updateBranchPaymentSetting(
          branchID,
          paymentPayload
        )
      );

      if (values.status) {
        const qrcodePayload = {
          qrcode_merchant_id: values.merchantID,
          qrcode_public_id: values.publicID,
          qrcode_secret_key_id: values.secretID,
          qrcode_token: values.token,
        };
        await dispatch(
          branchPaymentHandler.updateBranchPaymentQRCode(
            branchID,
            qrcodePayload
          )
        );
      }
    } catch (error) {
      console.error("Failed to update settings:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          touched,
          errors,
          values,
          setFieldValue,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Check
              type="switch"
              id="qrpayment"
              label="ตั้งค่าระบบ QR (Auto Check)"
              name="status"
              className="custom-switch-st-1 tc-10 s1 mb-1"
              checked={values.status}
              onChange={(e) => {
                setFieldValue("status", e.target.checked);
              }}
            />

            {values.status && (
              <>
                <div className="d-flex align-items-center mb-4 pb-2">
                  <img
                    src="/images/payment/gbpay.png"
                    className="me-4"
                    width={100}
                  />
                  <div
                    style={{
                      color: "rgba(76, 78, 100, 0.50)",
                      fontWeight: 500,
                    }}
                  >
                    <div className="mb-1">
                      กรุณากรอกข้อมูลที่ได้รับจากระบบ GB PAY
                    </div>
                    <div>หากไม่ทราบข้อมูลกรุณาติดต่อเจ้าหน้าที่</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    <Form.Group className="mb-3 form-st-3 pb-1">
                      <Form.Label className="mb-1">
                        Merchant ID <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="merchantID"
                        placeholder="Merchant ID"
                        value={values.merchantID}
                        onChange={handleChange}
                        isInvalid={touched.merchantID && !!errors.merchantID}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.merchantID}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3 form-st-3 pb-1">
                      <Form.Label className="mb-1">
                        Public ID <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="publicID"
                        placeholder="Public ID"
                        value={values.publicID}
                        onChange={handleChange}
                        isInvalid={touched.publicID && !!errors.publicID}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.publicID}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3 form-st-3 pb-1">
                      <Form.Label className="mb-1">
                        Secret Key ID <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="secretID"
                        placeholder="Secret Key ID"
                        value={values.secretID}
                        onChange={handleChange}
                        isInvalid={touched.secretID && !!errors.secretID}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.secretID}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3 form-st-3 pb-1">
                      <Form.Label className="mb-1">
                        Token <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="token"
                        placeholder="Token"
                        value={values.token}
                        onChange={handleChange}
                        isInvalid={touched.token && !!errors.token}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.token}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </>
            )}

            <div className="text-end mt-5 pt-5 pb-2 btn-group-control">
              <Button
                type="submit"
                className="btn-st-3"
                style={{ width: "100px" }}
              >
                บันทึก
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
