import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams, NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import Form from "react-bootstrap/Form";

import Collapse from "react-bootstrap/Collapse";
import { utils, writeFileXLSX } from "xlsx";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AutoTabProvider } from "react-auto-tab";
import {
  ThailandAddressTypeahead,
  ThailandAddressValue,
} from "react-thailand-address-typeahead";

import { ModalAllder } from "layouts/ModalAllder";
import { DataGrid } from "@mui/x-data-grid";

export default function ConfirmChangePinModal({ onConfirm, ...props }) {
  return (
    <Modal {...props} size="md" centered>
      <Modal.Body>
        <div className="px-3 py-4">
          <div>
            <div>
              <div className="text-center fs-22 tc-7 mb-2">
                คุณต้องการเปลี่ยน
              </div>
              <div className="text-center fs-22 tc-7">PIN ใช่หรือไม่</div>
            </div>
            <div className="text-center pt-4 mt-3  btn-group-control">
              <Button
                type="button"
                className="btn btn-st-3 mx-3 text-decoration-none"
                style={{ width: "100px" }}
                onClick={onConfirm}
              >
                ใช่
              </Button>
              <Button
                type="button"
                className="btn-st-6 mx-3 "
                style={{ width: "100px" }}
                onClick={props.onHide}
              >
                ไม่ใช่
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
