import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { branchPaymentHandler } from "../../../../../redux/handlers";

export default function PaymentDebtorsSetting() {
  const dispatch = useDispatch();
  const { branchID } = useParams();

  const paymentForm = useSelector(
    (state) => state.branchPayment.paymentForm.payment
  );

  const [enableDebtors, setEnableDebtors] = useState(
    paymentForm?.is_debtor_received || false
  );

  useEffect(() => {
    setEnableDebtors(paymentForm?.is_debtor_received || false);
  }, [paymentForm?.is_debtor_received]);

  const handleSubmit = async () => {
    try {
      const paymentPayload = {
        ...paymentForm,
        is_debtor_received: enableDebtors,
      };

      await dispatch(
        branchPaymentHandler.updateBranchPaymentSetting(
          branchID,
          paymentPayload
        )
      );
    } catch (error) {
      console.error("Failed to update debtors settings:", error);
    }
  };

  return (
    <div>
      <Form.Check
        type="switch"
        id="debtors-status"
        label="รับชำระด้วยบัญชีลูกหนี้"
        name="status"
        className="custom-switch-st-1 tc-10 s1 mb-1"
        checked={enableDebtors}
        onChange={(e) => setEnableDebtors(e.target.checked)}
      />

      <div className="text-end pt-5 pb-3 btn-group-control">
        <Button
          type="submit"
          className="btn-st-3"
          style={{ width: "100px" }}
          onClick={handleSubmit}
        >
          บันทึก
        </Button>
      </div>
    </div>
  );
}
