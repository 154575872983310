import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productBranch: null,
};

export const productBranchSlice = createSlice({
  name: "productBranch",
  initialState,
  reducers: {
    setProductBranch: (state, action) => {
      state.productBranch = action.payload;
    },
  },
});

export const { setProductBranch } = productBranchSlice.actions;
export default productBranchSlice.reducer;
