import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { ConfirmDeleteModal } from "./components/ConfirmDeleteModal";
import { SuccessDeleteModal } from "./components/SuccessDeleteModal";
import {
  employeeHandler,
  masterHandler,
  branchHandler,
} from "../../../redux/handlers";
import _ from "lodash";
import { useBreadcrumb } from "../../../hooks/useBreadcrumb";
import Breadcrumb from "../../../components/Breadcrumb";

const validationSchema = yup.object().shape({
  user_business_group_id: yup.string().required("กรุณากรอกรหัสพนักงาน"),
  code: yup.string().required("กรุณากรอกรหัสพนักงาน"),
  name: yup.string().required("กรุณากรอกชื่อพนักงาน"),
  email: yup.string().required("กรุณากรอกอีเมล"),
  emp_business_role_id: yup.string().required("กรุณากรอกสิทธิผู้ใช้งาน"),
  userBusinessGroupBranch: yup
    .array()
    .when(["emp_work_is_all_branch", "optionsBranchLength"], {
      is: (emp_work_is_all_branch, optionsBranchLength) =>
        !emp_work_is_all_branch && optionsBranchLength > 0,
      then: () => yup.array().min(1, "กรุณาเลือกสาขาอย่างน้อย 1 สาขา"),
      otherwise: () => yup.array(),
    }),
});

const initialFormState = {
  code: "",
  name: "",
  email: "",
  user_business_group_id: "",
  emp_business_role_id: 1,
  emp_work_is_all_branch: true,
  userBusinessGroupBranch: [],
};

const formatEmployeeResult = (item) => (
  <div className="d-flex align-items-center" key={item.id}>
    <div className="me-2 fs-14" style={{ color: "#2DC76D" }}>
      {item.code}
    </div>
    <div>{`${item.first_name} ${item.last_name}`}</div>
  </div>
);

const formatBusinessRoles = (businessRoles) =>
  businessRoles.map((role) => ({
    value: role.id,
    label: role.name,
  }));

export function EmpManageBusinessRoleForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { empID, businessSlug } = useParams();

  const businessRoles = useSelector((state) => state.master.businessRoles);
  const permissions = useSelector((state) => state.master.permissions);

  const [employeesHasNotRole, setEmployeesHasNotRole] = useState([]);
  const [optionsBranch, setOptionsBranch] = useState([]);
  const [optionsPermission, setOptionsPermission] = useState([]);
  const [branchselected, setBranchselected] = useState([]);
  const [formdisabled, setFormdisabled] = useState(false);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [modalsuccessShow, setModalsuccessShow] = useState(false);
  const [formData, setFormData] = useState(initialFormState);
  const breadcrumbConfig = [
    { label: "หน้าหลัก", path: "/" },
    { label: "พนักงาน", path: "/emp/business" },
    { label: "จัดการผู้ใช้งานธุรกิจ", path: "/emp/business" },
    {
      label: empID
        ? "แก้ไขสิทธิผู้ใช้งานธุรกิจ"
        : "เพิ่มไขสิทธิผู้ใช้งานธุรกิจ",
    },
  ];
  useBreadcrumb(breadcrumbConfig);

  const fetchBranchData = useCallback(async () => {
    try {
      const response = await dispatch(
        branchHandler.getBranchAllList(businessSlug)
      );
      if (response) {
        // Remove duplicates based on branch.id
        const uniqueBranches = _.uniqBy(response, "id");
        const branchOptions = uniqueBranches.map((branch) => ({
          value: branch.id,
          label: branch.branch_name,
        }));
        setOptionsBranch(branchOptions);
        setFormData((prevFormData) => ({
          ...prevFormData,
          optionsBranchLength: branchOptions.length,
        }));
      }
    } catch (error) {
      console.error("Error fetching branch data:", error);
    }
  }, [dispatch, businessSlug]);

  const fetchInitialData = useCallback(async () => {
    await fetchBranchData();
    try {
      const [resEmployeesHasNotRole, resBusinessRoles, resPermission] =
        await Promise.all([
          dispatch(employeeHandler.getEmployeesHasNotRole(businessSlug)),
          dispatch(masterHandler.getBusinessRoles()),
          dispatch(masterHandler.getPermission()),
        ]);
      setEmployeesHasNotRole(resEmployeesHasNotRole);
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  }, [dispatch, businessSlug]);

  const loadEmployeeData = useCallback(
    async (id) => {
      try {
        const response = await dispatch(employeeHandler.getEmployeeById(id));
        const selectedBranchIds = response.userBusinessGroupBranch.map(
          (item) => item.branch_id
        );

        setFormData({
          user_business_group_id: response.id,
          code: response.code,
          name: `${response.first_name} ${response.last_name}`,
          email: response.email,
          emp_business_role_id: response.emp_business_role_id,
          emp_work_is_all_branch: response.emp_work_is_all_branch,
          userBusinessGroupBranch: selectedBranchIds,
          optionsBranchLength: optionsBranch.length,
        });
        setBranchselected(selectedBranchIds);
      } catch (error) {
        console.error("Error loading employee data:", error);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    fetchInitialData();
    if (empID) {
      loadEmployeeData(empID);
      setFormdisabled(true);
    }
  }, [fetchBranchData, fetchInitialData, empID, loadEmployeeData]);

  useEffect(() => {
    setOptionsPermission(formatBusinessRoles(businessRoles));
  }, [businessRoles]);

  const handleEmployeeSelect = (item) => {
    setFormData({
      ...formData,
      user_business_group_id: item.id,
      code: item.code,
      name: `${item.first_name} ${item.last_name}`,
      email: item.email,
      optionsBranchLength: optionsBranch.length,
    });
  };

  const handleBranchChange = (selectedOptions, setFieldValue) => {
    if (!formdisabled) {
      const selectedBranchIds = selectedOptions.map((option) => option.value);
      setBranchselected(selectedBranchIds);
      setFieldValue("userBusinessGroupBranch", selectedBranchIds);
    }
  };

  const handleSubmit = async (values) => {
    setFormdisabled(true);
    const formattedBranches = values.userBusinessGroupBranch.map(
      (branchId) => ({
        branch_id: branchId,
      })
    );

    const roleData = {
      user_business_group_id: values.user_business_group_id,
      emp_business_role_id: values.emp_business_role_id,
      emp_work_is_all_branch: values.emp_work_is_all_branch,
      userBusinessGroupBranch: formattedBranches,
    };

    try {
      if (empID) {
        await dispatch(
          employeeHandler.updateEmployeeRole(empID, {
            ...roleData,
            user_business_group_id: undefined,
          })
        );
      } else {
        await dispatch(employeeHandler.createEmployeeRole(roleData));
      }
      navigate(`/${businessSlug}/emp/business`);
    } catch (error) {
      console.error("Error submitting form:", error);
      setFormdisabled(false);
    }
  };

  return (
    <div>
      <Breadcrumb />
      <div className="card card-st-2 mb-4">
        <div className="card-body px-xl-4 py-4">
          <Formik
            enableReinitialize
            initialValues={formData}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              handleSubmit,
              handleChange,
              errors,
              touched,
              values,
              setFieldValue,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <div className="d-flex align-items-center mb-4">
                  <div
                    className="fs-18 fw-bold me-auto"
                    style={{ color: "#2DC76D" }}
                  >
                    {empID ? "แก้ไขสิทธิผู้ใช้งาน" : "เพิ่มสิทธิผู้ใช้งาน"}
                  </div>
                </div>

                <fieldset disabled={formdisabled}>
                  <div className="row">
                    <div className="col-xl-4">
                      <Form.Group className="mb-3 form-st-3 pb-1">
                        {/* <Form.Label className="mb-1">ค้นหาพนักงาน</Form.Label> */}
                        <ReactSearchAutocomplete
                          items={employeesHasNotRole}
                          placeholder="ค้นหาพนักงาน"
                          onSelect={handleEmployeeSelect}
                          autoFocus
                          disabled={empID}
                          formatResult={formatEmployeeResult}
                          fuseOptions={{
                            keys: ["code", "first_name", "last_name"],
                          }}
                          resultStringKeyName="first_name"
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-4">
                      <Form.Group className="mb-3 form-st-3 pb-1">
                        <Form.Label className="mb-1">
                          รหัสพนักงาน <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="code"
                          placeholder="รหัสพนักงาน"
                          value={values.code}
                          onChange={handleChange}
                          isInvalid={!!errors.code}
                          disabled={true}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.code}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className="col-xl-4">
                      <Form.Group className="mb-2 mt-3 form-st-3 pb-1">
                        <Form.Label className="mb-1">ชื่อพนักงาน</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="ชื่อพนักงาน"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={!!errors.name}
                          disabled={true}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className="col-xl-4">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">E-mail</Form.Label>
                        <Form.Control
                          type="text"
                          name="email"
                          placeholder="E-mail"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                          disabled={true}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="card card-st-1 mt-4 mb-4">
                    <div className="card-body px-0">
                      <div
                        className="fs-18 px-3 fw-bold me-auto mb-3"
                        style={{ color: "#2DC76D" }}
                      >
                        สิทธิการเข้าถึง Back Office
                      </div>
                      <table className="table table-st-1">
                        <thead>
                          <tr>
                            <th>ชื่อสิทธิ</th>
                            {businessRoles.map((role, index) => (
                              <th className="text-center" key={index}>
                                {role.name}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {permissions?.map((permission, index) => (
                            <tr key={index}>
                              <td>{permission.name}</td>
                              {permission.businessRolePermission.map(
                                (role, index) => (
                                  <td key={index}>
                                    {role.permission.has_not_permission ? (
                                      <div className="icon-p1" />
                                    ) : role.permission.has_some_permission ? (
                                      <div className="text-center fs-12">
                                        สิทธิบางส่วน
                                      </div>
                                    ) : (
                                      <div className="icon-p2" />
                                    )}
                                  </td>
                                )
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-4">
                      <Form.Group className="mb-3 form-st-3 pb-1">
                        <Form.Label className="mb-1">
                          สิทธิผู้ใช้งาน <span className="text-danger">*</span>
                        </Form.Label>
                        <Select
                          name="emp_business_role_id"
                          onChange={(e) =>
                            setFieldValue("emp_business_role_id", e.value)
                          }
                          className="w-100 select-st-1"
                          options={optionsPermission}
                          placeholder="สิทธิผู้ใช้งาน"
                          value={optionsPermission.filter(
                            (item) => item.value === values.emp_business_role_id
                          )}
                        />
                        {touched?.emp_business_role_id &&
                          errors?.emp_business_role_id && (
                            <div className="invalid-feedback d-block">
                              {errors.emp_business_role_id}
                            </div>
                          )}
                      </Form.Group>
                    </div>
                  </div>
                  <div>
                    <Form.Label className="mb-2 tc-10">
                      สิทธิผู้ใช้งาน
                    </Form.Label>
                    <div className="mb-3">
                      <Form.Check
                        inline
                        label="ใช้ได้ทุกสาขา"
                        name="emp_work_is_all_branch"
                        type="radio"
                        id="inline-radio-p1"
                        className="form-check-st-2 tc-7"
                        checked={values.emp_work_is_all_branch}
                        onChange={() => {
                          setFieldValue("emp_work_is_all_branch", true);
                          setFieldValue("userBusinessGroupBranch", []);
                        }}
                      />
                      {optionsBranch.length > 0 && (
                        <Form.Check
                          inline
                          label="กำหนดสาขาเอง"
                          name="emp_work_is_all_branch"
                          type="radio"
                          id="inline-radio-p2"
                          className="form-check-st-2 tc-7"
                          checked={!values.emp_work_is_all_branch}
                          onChange={() =>
                            setFieldValue("emp_work_is_all_branch", false)
                          }
                        />
                      )}
                    </div>
                  </div>
                  {!values.emp_work_is_all_branch &&
                    optionsBranch.length > 0 && (
                      <div className="row">
                        <div className="col-xl-12">
                          <Form.Group className="mb-2 mt-3 form-st-3">
                            <Form.Label className="mb-1">
                              ตัวเลือก <span className="text-danger">*</span>
                            </Form.Label>
                            <Select
                              isMulti
                              name="userBusinessGroupBranch"
                              onChange={(selectedOptions) =>
                                handleBranchChange(
                                  selectedOptions,
                                  setFieldValue
                                )
                              }
                              className="w-100 select-st-1 select-multi"
                              options={optionsBranch}
                              disabled={formdisabled}
                              placeholder="เลือกสาขา"
                              value={optionsBranch.filter((i) =>
                                branchselected.includes(i.value)
                              )}
                            />
                            {touched?.userBusinessGroupBranch &&
                              errors?.userBusinessGroupBranch && (
                                <div className="invalid-feedback d-block">
                                  {errors.userBusinessGroupBranch}
                                </div>
                              )}
                          </Form.Group>
                        </div>
                      </div>
                    )}
                </fieldset>

                <div className="text-end pb-4 pt-4 pe-3">
                  {formdisabled ? (
                    <Button
                      type="button"
                      className="btn-st-3 mx-3"
                      onClick={() => setFormdisabled(false)}
                    >
                      แก้ไข
                    </Button>
                  ) : (
                    <div className="pt-3 d-flex btn-group-control w-100">
                      {empID ? (
                        <Button
                          type="button"
                          className="btn-st-5 me-auto"
                          style={{ width: "80px" }}
                          onClick={() => setModalDeleteShow(true)}
                        >
                          ลบ
                        </Button>
                      ) : (
                        <div className="me-auto" />
                      )}

                      <Link
                        to={`/${businessSlug}/emp/business`}
                        className="btn-st-6 me-3 btn text-decoration-none"
                      >
                        ยกเลิก
                      </Link>
                      <Button type="submit" className="btn-st-3">
                        บันทึก
                      </Button>
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <ConfirmDeleteModal
        show={modalDeleteShow}
        onHide={() => setModalDeleteShow(false)}
        onConfirm={() => setModalDeleteShow(false)}
        onSuccess={() => setModalsuccessShow(true)}
        pdata={formData}
      />
      <SuccessDeleteModal
        show={modalsuccessShow}
        onHide={() => setModalsuccessShow(false)}
      />
    </div>
  );
}
