import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import ImageUpload from "../fragments/ImageUpload";
import Province from "../fragments/Province";
import District from "../fragments/District";
import SubDistrict from "../fragments/SubDistrict";
import Potalcode from "../fragments/Potalcode";
import SelectStoreHouse from "fragments/SelectStoreHouse";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { branchHandler, masterHandler } from "../../../../../redux/handlers";
import SuccessModal from "../modals/SuccessModal";

export const schema = yup.object().shape({
  businessSlug: yup.string().required("กรุณากรอกชื่อกิจการ"),
  code: yup.string().required("กรุณากรอกรหัสสาขา"),
  branchName: yup.string().required("กรุณากรอกชื่อสาขา"),
  taxableEntityType: yup.string().required("กรุณาเลือกรูปแบบสาขา"),
  vatCode: yup.string().when("taxableEntityType", ([taxableEntityType]) => {
    if (taxableEntityType === "BRANCH") {
      return yup.string().required("กรุณากรอก VAT Code");
    }
    return yup.string().nullable();
  }),
  address: yup.string().required("กรุณากรอกที่อยู่"),
  provinceId: yup.string().required("กรุณาเลือกจังหวัด"),
  districtId: yup.string().required("กรุณาเลือกอำเภอ"),
  subDistrictId: yup.number().required("กรุณาเลือกตำบล"),
  phoneNumber: yup.string().required("กรุณากรอกเบอร์โทรศัพท์"),
  tel: yup
    .string()
    .required("กรุณากรอกเบอร์มือถือ")
    .matches(/^[0-9]{10}$/, "กรุณากรอกเบอร์มือถือให้ถูกต้อง"),
  pin: yup
    .string()
    .required("กรุณากรอก PIN")
    .matches(/^[0-9]{4}$/, "PIN ต้องเป็นตัวเลข 4 หลัก"),
  date: yup.string().required("กรุณาเลือกวันที่"),
  isStatus: yup.boolean().required("กรุณาเลือกสถานะ"),
});

const BranchInfoForm = ({ businessSlug, isReadOnly }) => {
  const { branchID } = useParams();
  const [formdisabled, setFormdisabled] = useState(branchID);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isEditMode = Boolean(branchID);

  const taxBranchesFromRedux = useSelector((state) => state.master.taxBranches);
  const taxBranches = taxBranchesFromRedux ? [{ name: "ไม่ระบุ", id: 0, is_active: true }, ...taxBranchesFromRedux] : [];

  const [initialValues, setInitialValues] = useState({
    businessSlug: businessSlug,
    code: "",
    branchName: "",
    taxableEntityType: "UNSPECIFIED",
    vatCode: "",
    imagePath: "",
    address: "",
    subDistrictId: "",
    phoneNumber: "",
    tel: "",
    pin: "",
    date: dayjs(),
    isStatus: true,
    provinceId: "",
    districtId: "",
    postalCode: "",
  });

  useEffect(() => {
    const fetchBranchData = async () => {
      if (isEditMode) {
        try {
          const response = await dispatch(branchHandler.getBranchById(branchID));
          if (response) {
            const { subDistrict } = response;
            setInitialValues({
              businessSlug: businessSlug,
              branchName: response.branch_name,
              taxableEntityType: response.taxable_entity_type,
              vatCode: response.vat_code,
              imagePath: response.image_path,
              address: response.address,
              subDistrictId: response.sub_district_id,
              phoneNumber: response.phone_number,
              tel: response.tel,
              pin: response.pin,
              date: dayjs(response.date),
              isStatus: response.is_status,
              provinceId: subDistrict?.district?.province?.id?.toString() || "",
              districtId: subDistrict?.district?.id?.toString() || "",
              postalCode: subDistrict?.zip_code || "",
              code: response.code,
            });

            if (subDistrict?.district?.province?.id) {
              await dispatch(masterHandler.getDistricts(subDistrict.district.province.id));
            }

            if (subDistrict?.district?.id) {
              await dispatch(masterHandler.getSubDistricts(subDistrict.district.id));
            }
          }
        } catch (error) {
          console.error("Error fetching branch data:", error);
        }
      }
      setIsLoading(false);
    };

    fetchBranchData();
  }, [branchID, dispatch, isEditMode, businessSlug]);

  const getTaxableEntityType = (branchName) => {
    switch (branchName) {
      case "สำนักงานใหญ่":
        return "HEADQUARTERS";
      case "สาขา":
        return "BRANCH";
      default:
        return "UNSPECIFIED";
    }
  };

  const showSuccessAndRedirect = (message, newBranchID) => {
    setModalMessage(message);
    setIsModalVisible(true);
    setFormdisabled(true);
    setTimeout(() => {
      setIsModalVisible(false);
      navigate(`/${businessSlug}/branchs/edit/${newBranchID}`);
    }, 1500);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="card card-st-1 mb-4">
      <div className="card-body">
        <div className="tc-8 fs-18 fw-bold px-3">{isEditMode ? "แก้ไขข้อมูลสาขา" : "เพิ่มข้อมูลสาขา"}</div>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={async (values) => {
            try {
              const submitData = {
                ...values,
                date: dayjs(values.date).format("YYYY-MM-DD"),
              };

              if (isEditMode) {
                const response = await dispatch(branchHandler.updateBranch(branchID, submitData));

                if (response) {
                  showSuccessAndRedirect("แก้ไขข้อมูลสาขาสำเร็จ", branchID);
                }
              } else {
                const response = await dispatch(branchHandler.createBranch(submitData));

                if (response && response.id) {
                  showSuccessAndRedirect("เพิ่มข้อมูลสาขาสำเร็จ", response.id);
                }
              }
            } catch (error) {
              console.error(isEditMode ? "Error updating branch:" : "Error creating branch:", error);
            }
          }}
        >
          {({ handleSubmit, handleChange, touched, errors, values, setFieldValue }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <fieldset disabled={formdisabled}>
                <div className="card-body px-xl-3">
                  <div>
                    <ImageUpload
                      setFieldValue={setFieldValue}
                      values={values}
                      onUploadSuccess={(fileInfo) => {
                        setFieldValue("imagePath", fileInfo.base64);
                      }}
                      onUploadError={(error) => {}}
                      isReadOnly={isReadOnly}
                    />

                    <div className="pt-3">
                      <div className="row gx-xl-4">
                        <div className="col-xl-3">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">
                              รหัสสาขา <span className="text-danger ms-1">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="code"
                              placeholder="รหัสสาขา"
                              value={values.code ?? ""}
                              onChange={handleChange}
                              isInvalid={touched.code && !!errors.code}
                            />
                            <Form.Control.Feedback type="invalid">{errors.code}</Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-xl-3">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">
                              ชื่อสาขา <span className="text-danger ms-1">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="branchName"
                              placeholder="กรอกชื่อสาขา"
                              value={values.branchName ?? ""}
                              onChange={handleChange}
                              isInvalid={touched.branchName && !!errors.branchName}
                              disabled={isReadOnly}
                            />
                            <Form.Control.Feedback type="invalid">{errors.branchName}</Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-xl-3 align-self-center">
                          <Form.Label className="mb-0 mt-2 tc-10">
                            รูปแบบสาขา <span className="text-danger ms-1">*</span>
                          </Form.Label>
                          <div className="mb-3">
                            {taxBranches.map((branch) => (
                              <Form.Check
                                key={branch.id}
                                inline
                                label={branch.name}
                                name="taxableEntityType"
                                type="radio"
                                id={`type-${branch.id}`}
                                checked={values.taxableEntityType === getTaxableEntityType(branch.name)}
                                onChange={() => {
                                  const entityType = getTaxableEntityType(branch.name);
                                  setFieldValue("taxableEntityType", entityType);
                                  if (entityType !== "BRANCH") {
                                    setFieldValue("vatCode", "");
                                  }
                                }}
                                isInvalid={touched.taxableEntityType && !!errors.taxableEntityType}
                                disabled={isReadOnly}
                              />
                            ))}
                            {touched.taxableEntityType && errors.taxableEntityType && <div className="invalid-feedback d-block">{errors.taxableEntityType}</div>}
                          </div>
                        </div>

                        <div className="col-xl-3">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">
                              VAT Code <span className="text-danger ms-1">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="vatCode"
                              placeholder="กรอก VAT Code"
                              value={values.vatCode ?? ""}
                              onChange={handleChange}
                              isInvalid={touched.vatCode && !!errors.vatCode}
                              disabled={values.taxableEntityType !== "BRANCH" || isReadOnly}
                            />
                            <Form.Control.Feedback type="invalid">{errors.vatCode}</Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-xl-12">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">
                              ที่อยู่ <span className="text-danger ms-1">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="address"
                              placeholder="กรอกที่อยู่"
                              value={values.address ?? ""}
                              onChange={handleChange}
                              isInvalid={touched.address && !!errors.address}
                              disabled={isReadOnly}
                            />
                            <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-xl-6">
                          <Province isReadOnly={isReadOnly} setFieldValue={setFieldValue} values={values} errors={errors} touched={touched} />
                        </div>

                        <div className="col-xl-6">
                          <District isReadOnly={isReadOnly} setFieldValue={setFieldValue} values={values} errors={errors} touched={touched} />
                        </div>

                        <div className="col-xl-6">
                          <SubDistrict isReadOnly={isReadOnly} setFieldValue={setFieldValue} values={values} errors={errors} touched={touched} />
                        </div>

                        <div className="col-xl-6">
                          <Potalcode setFieldValue={setFieldValue} values={values} errors={errors} />
                        </div>

                        <div className="col-xl-3">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">
                              เบอร์โทรศัพท์ <span className="text-danger ms-1">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="phoneNumber"
                              placeholder="กรอกเบอร์โทรศัพท์"
                              value={values.phoneNumber ?? ""}
                              onChange={handleChange}
                              isInvalid={touched.phoneNumber && !!errors.phoneNumber}
                              disabled={isReadOnly}
                            />
                            <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-xl-3">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">
                              เบอร์มือถือ <span className="text-danger ms-1">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="tel"
                              placeholder="กรอกเบอร์มือถือ"
                              value={values.tel ?? ""}
                              onChange={handleChange}
                              isInvalid={touched.tel && !!errors.tel}
                              maxLength={10}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={isReadOnly}
                            />
                            <Form.Control.Feedback type="invalid">{errors.tel}</Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-xl-3">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">
                              PIN สาขา (4 หลัก) <span className="text-danger ms-1">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="pin"
                              placeholder="Pin สาขา"
                              value={values.pin ?? ""}
                              onChange={handleChange}
                              isInvalid={touched.pin && !!errors.pin}
                              maxLength={4}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={isReadOnly}
                            />
                            <Form.Control.Feedback type="invalid">{errors.pin}</Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-xl-3">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">คลังสินค้าที่เชื่อม</Form.Label>
                            <SelectStoreHouse
                              handleChange={(e) => {
                                setFieldValue("storeHouse", e.value);
                              }}
                              errors={errors}
                              isDisabled={true}
                            />
                          </Form.Group>
                        </div>
                        {!isReadOnly && (
                          <div className="col-xl-3">
                            <Form.Group className="mt-3">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  format="DD/MM/YYYY"
                                  slotProps={{
                                    textField: {
                                      InputLabelProps: { shrink: true },
                                      error: touched.date && !!errors.date,
                                    },
                                  }}
                                  label="วันที่สร้าง"
                                  className={`w-full ${isReadOnly ? "bg-[#F8F8F9]" : ""}`}
                                  sx={{
                                    ".MuiInputBase-formControl": {
                                      height: "53px",
                                      borderRadius: "8px",
                                    },
                                    ".MuiFormLabel-root": {
                                      fontFamily: "Sarabun",
                                    },
                                    ".MuiOutlinedInput-notchedOutline": {
                                      border: "1px solid rgba(76, 78, 100, 0.22)",
                                    },
                                  }}
                                  name="date"
                                  value={values.date ? dayjs(values.date) : null}
                                  disabled={isReadOnly}
                                  onChange={(date) => {
                                    setFieldValue("date", date);
                                  }}
                                />
                              </LocalizationProvider>
                              {touched.date && errors.date && <div className="invalid-feedback d-block">{errors.date}</div>}
                            </Form.Group>
                          </div>
                        )}

                        {!isReadOnly && (
                          <div className="col-xl-3 align-self-center">
                            <Form.Label className="mb-0 mt-2 tc-10">
                              สถานะ <span className="text-danger ms-1">*</span>
                            </Form.Label>
                            <div className="mb-3">
                              <Form.Check
                                inline
                                label="เปิดใช้งาน"
                                name="isStatus"
                                type="radio"
                                id="status-active"
                                checked={values.isStatus === true}
                                onChange={() => {
                                  setFieldValue("isStatus", true);
                                }}
                                isInvalid={touched.isStatus && !!errors.isStatus}
                                disabled={isReadOnly}
                              />
                              <Form.Check
                                inline
                                label="ปิดใช้งาน"
                                name="isStatus"
                                type="radio"
                                id="status-inactive"
                                checked={values.isStatus === false}
                                onChange={() => {
                                  setFieldValue("isStatus", false);
                                }}
                                isInvalid={touched.isStatus && !!errors.isStatus}
                                disabled={isReadOnly}
                              />
                              {touched.isStatus && errors.isStatus && <div className="invalid-feedback d-block">{errors.isStatus}</div>}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>

              {!isReadOnly && (
                <div className="text-end pb-4 pe-3">
                  {formdisabled ? (
                    <Button type="button" className="btn-st-3 mx-3" onClick={() => setFormdisabled(false)}>
                      แก้ไข
                    </Button>
                  ) : (
                    <div className="text-end pt-3 btn-group-control">
                      <Button
                        to={`/${businessSlug}/branchs`}
                        className="btn-st-5 btn text-decoration-none me-3"
                        onClick={() => {
                          setFormdisabled(true);
                          navigate(`/${businessSlug}/branchs`);
                        }}
                      >
                        ยกเลิก
                      </Button>
                      <Button type="submit" className="btn-st-3">
                        {isEditMode ? "อัปเดต" : "บันทึก"}
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>

      <SuccessModal show={isModalVisible} onHide={() => setIsModalVisible(false)} textsuccess={modalMessage} />
    </div>
  );
};

export default BranchInfoForm;
