import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import ModalEdit from "./components/modal/ModalEdit";
import StockModal from "./components/modal/StockModal";
import ModalAdd from "./components/modal/ModalAdd";
import Optionlist from "./components/Optionlist";
import { FormControl } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  productStandardHandler,
  userBusinessGroupHandler,
} from "../../../redux/handlers";
import { useDispatch, useSelector } from "react-redux";
import getCurrentDate from "../../../utils/getCurrentDate";
import { useBreadcrumb } from "../../../hooks/useBreadcrumb";
import Breadcrumb from "../../../components/Breadcrumb";

export function OptionCreate() {
  const { businessSlug } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    optionName: yup.string().required("กรุณาระบุชื่อมาตราฐาน"),
  });

  const [formdisabled, setFormdisabled] = useState(false);
  const [optionlist, setOptionlist] = useState([]);
  const [listID, setListID] = useState(null);
  const [editOptionID, setEditOptionID] = useState();
  const [onSuccess, setOnSuccess] = useState(false);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [optiondata, setOptiondata] = useState({
    optionName: "",
    dateCreate: getCurrentDate("/"),
    userCreate: "",
    optionStatus: 1,
    displayCustomer: false,
    multipleSelection: false,
    optionList: [],
  });
  const [productOptionDetail, setProductOptionDetail] = useState({
    id: null,
    name: null,
    price: null,
  });
  const [stock, setStock] = useState({
    name: "",
    storeHouse: "",
    productID: null,
    qty: null,
    unit: "",
  });
  const [modaledit, setModaledit] = useState({
    open: false,
    x: 0,
    y: 0,
    width: 680,
    height: 410,
    pin: false,
    mode: "normal",
    modalname: "modal_edit_option_standard",
  });
  const [modaladd, setModaladd] = useState({
    open: false,
    x: 0,
    y: 0,
    width: 680,
    height: 410,
    pin: false,
    mode: "normal",
    modalname: "modal_add_option_standard",
  });
  const [modalStock, setModalStock] = useState({
    open: false,
    x: 0,
    y: 0,
    width: 520,
    height: 610,
    pin: false,
    mode: "normal",
    modalname: "modal_stock_option_standard",
  });

  const resData = useSelector((state) => state.productStandard?.createResponse);

  const breadcrumbConfig = [
    { label: "หน้าหลัก", path: "/" },
    { label: "สินค้าและบริการ", path: "/product/options/standard" },
    { label: "จัดการตัวเลือกมาตราฐาน", path: "/product/options/standard" },
    { label: "เพิ่มตัวเลือกมาตราฐาน" },
  ];
  useBreadcrumb(breadcrumbConfig);

  const fetchUserProfile = async () => {
    try {
      const response = await dispatch(
        userBusinessGroupHandler.getUserBusinessProfile()
      );
      if (response) {
        const { first_name, last_name } = response;
        setOptiondata((prev) => ({
          ...prev,
          userCreate: `${first_name} ${last_name}`,
        }));
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  useEffect(() => {
    setOptionlist(optiondata.optionList);
  }, [optiondata]);

  useEffect(() => {
    if (isDataUpdated && resData) {
      setIsDataUpdated(false);
    }
    if (resData && resData.id != undefined) {
      navigate(`/${businessSlug}/product/options/standard/edit/${resData.id}`);
    }
  }, [resData, isDataUpdated]);

  const deleteHandeler = (removeId) => {
    setOptionlist((optionlist) =>
      optionlist.filter((item) => item.id !== removeId)
    );
  };

  const editHandeler = (item, optionID) => {
    setProductOptionDetail(item);
    setEditOptionID(optionID);
    setModaledit((prev) => ({ ...prev, open: true }));
  };

  const handleCreateStandardOption = async (data) => {
    try {
      await dispatch(productStandardHandler.createProductOption(data));
      setIsDataUpdated(true);
    } catch (error) {
      console.error("Error create Option", error);
    }
  };

  function convertDateFormat(dateString) {
    const [day, month, year] = dateString.split("/");
    const paddedDay = day.padStart(2, "0");
    const paddedMonth = month.padStart(2, "0");
    return `${year}-${paddedMonth}-${paddedDay}`;
  }

  return (
    <div>
      <div>
        <Breadcrumb />
        <div className="fs-24 tc-7  mb-4 lh-1">จัดการตัวเลือกมาตราฐาน </div>
        <div className="card card-st-1 mb-4">
          <div className="card-body p-4">
            <div className="fs-22 fw-500 tc-7  mb-4 lh-1">
              สร้างตัวเลือกมาตราฐาน{" "}
            </div>
            <Formik
              initialValues={optiondata}
              validationSchema={schema}
              onSubmit={async (values) => {
                let updateForm = {
                  businessSlug: businessSlug,
                  name: values.optionName,
                  date: convertDateFormat(values.dateCreate),
                  isDisplay: values.displayCustomer,
                  isMultipleSelect: values.multipleSelection,
                };
                await handleCreateStandardOption(updateForm);
              }}
              enableReinitialize
            >
              {({
                handleSubmit,
                handleChange,
                touched,
                errors,
                values,
                setFieldValue,
                isInvalid,
                isValid,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <fieldset disabled={formdisabled}>
                    <div className="row gx-xl-3">
                      <div className="col-xl-6 col-lg-6 col">
                        <Form.Group className="mb-2 form-st-3 pb-1">
                          <Form.Label className="mb-1">
                            ชื่อมาตราฐาน <span className="text-danger">*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="optionName"
                            className="form-control"
                            placeholder="กรอกชื่อมาตราฐาน"
                            value={values.optionName}
                            onChange={handleChange}
                            isInvalid={!!errors.optionName}
                          />
                          <FormControl.Feedback type="invalid">
                            {errors.optionName}
                          </FormControl.Feedback>
                        </Form.Group>
                      </div>
                    </div>

                    {optionlist?.map((item, index) => (
                      <Optionlist
                        item={item}
                        key={item.id}
                        n={index}
                        editHandeler={() => {
                          editHandeler(item, values.optionID);
                        }}
                        deleteHandeler={() => {
                          values.optionList.splice(index, 1);
                          deleteHandeler(item.id);
                        }}
                        settingHandeler={() => {
                          setModalStock((prev) => ({ ...prev, open: true }));
                          if (item.stock && item.stock != "") {
                            setStock(item.stock);
                          } else {
                            setStock({
                              name: "",
                              storeHouse: "",
                              productID: null,
                              qty: null,
                              unit: "",
                            });
                          }
                          setListID(item.id);
                        }}
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                    ))}

                    <div className="row gx-xl-3">
                      <div className="col-xl-6 col-lg-6 col">
                        <div className="mb-2 flex space-x-6">
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="แสดงหน้าจอลูกค้า"
                            name="displayCustomer"
                            className="custom-switch-st-1 tc-10 s2 mb-3"
                            defaultChecked={values.displayCustomer}
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                setFieldValue("displayCustomer", true);
                              } else {
                                setFieldValue("displayCustomer", false);
                              }
                            }}
                          />

                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="เลือกได้มากกว่า 1 รายการ"
                            name="multipleSelection"
                            className="custom-switch-st-1 tc-10 s2 mb-3"
                            defaultChecked={values.multipleSelection}
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                setFieldValue("multipleSelection", true);
                              } else {
                                setFieldValue("multipleSelection", false);
                              }
                            }}
                          />
                        </div>

                        <div className="row">
                          <div className="col-6">
                            <Form.Group className="mb-3 form-st-3 pb-1 ">
                              <Form.Label className="mb-1">
                                วันที่สร้าง{" "}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="dateCreate"
                                className="form-control"
                                placeholder="วันที่สร้าง"
                                value={values.dateCreate}
                                onChange={handleChange}
                                isInvalid={!!errors.dateCreate}
                                disabled={true}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-6">
                            <Form.Group className="mb-3 form-st-3 pb-1 ">
                              <Form.Label className="mb-1">
                                ชื่อผู้สร้าง{" "}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="userCreate"
                                className="form-control"
                                placeholder="ชื่อผู้สร้าง"
                                value={values.userCreate}
                                onChange={handleChange}
                                isInvalid={!!errors.userCreate}
                                disabled={true}
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <div className="text-end pb-4 pe-3">
                    {formdisabled === true ? (
                      <Button
                        type="button"
                        className="btn-st-3 mx-3"
                        onClick={(e) => {
                          setFormdisabled(false);
                        }}
                      >
                        แก้ไข
                      </Button>
                    ) : (
                      <div className="text-end pt-3 btn-group-control">
                        <Button
                          onClick={() =>
                            navigate(
                              `/${businessSlug}/product/options/standard`
                            )
                          }
                          className="btn btn-st-5 me-3"
                        >
                          ยกเลิก
                        </Button>
                        <Button type="submit" className="btn-st-3 ">
                          บันทึก
                        </Button>
                      </div>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>

      <ModalEdit
        modal={modaledit}
        setModal={setModaledit}
        productOptionDetail={productOptionDetail}
        setProductOptionDetail={setProductOptionDetail}
        optionID={editOptionID}
        optiondata={optiondata}
        setOptiondata={setOptiondata}
        onSuccess={() => {
          setOnSuccess(true);
        }}
      />
      <ModalAdd
        modal={modaladd}
        setModal={setModaladd}
        optiondata={optiondata}
        setOptiondata={setOptiondata}
        onSuccess={() => {
          setOnSuccess(true);
        }}
      />
      <StockModal
        modal={modalStock}
        setModal={setModalStock}
        onHide={() => setModalStock((prev) => ({ ...prev, open: false }))}
        stock={stock}
        setStock={setStock}
        listID={listID}
        optiondata={optiondata}
        setOptiondata={setOptiondata}
        deletestock={() => {
          setModalStock((prev) => ({ ...prev, open: false }));
        }}
      />
    </div>
  );
}
