import { setUser } from "../slices/userBusinessGroup.slice";
import { ENDPOINT, STATUS } from "../../constants/server/api";
import { ERROR_CODES } from "../../constants/server/error";
import { fetchApiData } from "../../utils/apiUtils";

export const userBusinessLogin = (email, password) => async (dispatch) => {
  const data = await fetchApiData({
    method: "post",
    endpoint: `${ENDPOINT.USER_BUSINESS_GROUP}/login`,
    data: { email, password },
    dispatch,
    handleAuthResponse: true,
  });

  if (data === ERROR_CODES.NOT_VERIFIED) {
    return ERROR_CODES.NOT_VERIFIED;
  }

  if (data) {
    dispatch(setUser({ user: data.user }));
    return STATUS.OK;
  }
};

export const userBusinessGoogleLogin =
  (googleId, email) => async (dispatch) => {
    const data = await fetchApiData({
      method: "post",
      endpoint: `${ENDPOINT.USER_BUSINESS_GROUP}/google-login`,
      data: { googleId, email },
      dispatch,
      handleAuthResponse: true,
    });

    if ([ERROR_CODES.NOT_VERIFIED, ERROR_CODES.USER_NOT_FOUND].includes(data)) {
      return data;
    }

    if (data) {
      dispatch(setUser({ user: data.user }));
      return STATUS.OK;
    }
  };

const prepareRegisterData = (registerData, includeGoogle = false) => {
  const baseData = {
    prefix_name_id: parseInt(registerData.prefix_name_id),
    firstName: registerData.firstName,
    lastName: registerData.lastName,
    email: registerData.email,
    phoneNumber: registerData.phoneNumber,
  };

  if (includeGoogle) {
    return {
      ...baseData,
      googleId: registerData.googleId,
    };
  }

  return {
    ...baseData,
    password: registerData.password,
  };
};

export const userBusinessRegister = (registerData) => async (dispatch) => {
  const data = await fetchApiData({
    method: "post",
    endpoint: `${ENDPOINT.USER_BUSINESS_GROUP}/register`,
    data: prepareRegisterData(registerData),
    dispatch,
    handleAuthResponse: true,
  });

  if (data) {
    dispatch(setUser({ user: data }));
    return STATUS.OK;
  }
};

export const userBusinessGoogleRegister =
  (registerData) => async (dispatch) => {
    const data = await fetchApiData({
      method: "post",
      endpoint: `${ENDPOINT.USER_BUSINESS_GROUP}/google-register`,
      data: prepareRegisterData(registerData, true),
      dispatch,
      handleAuthResponse: true,
    });

    if (data) {
      dispatch(setUser({ user: data }));
      return STATUS.OK;
    }
  };

export const resendVerificationEmail = (email) => async (dispatch) => {
  await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.USER_BUSINESS_GROUP}/resend-verification/${email}`,
    dispatch,
  });
  return STATUS.OK;
};

export const verifyEmail = (verifyCode) => async (dispatch) => {
  await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.USER_BUSINESS_GROUP}/verify-email`,
    params: { verifyCode },
    dispatch,
  });
  return STATUS.OK;
};

export const checkVerificationStatus = (verifyCode) => async (dispatch) => {
  return await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.USER_BUSINESS_GROUP}/check-verification-status`,
    params: { verifyCode },
    dispatch,
  });
};

export const checkBusinessGuard = (slug) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.BUSINESS_AUTH}/check-guard/${slug}`,
    dispatch,
    useAuth: false,
  });

  if (data) {
    return data;
  }
};
export const checkBusinessGuardAuth = (slug) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.BUSINESS}/check-guard-auth/${slug}`,
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const getUserBusinessProfile = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.USER_BUSINESS_GROUP}/profile`,
    dispatch,
  });

  if (data) {    
    return data;
  }
};