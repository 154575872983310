import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { ModalAllder } from "layouts/ModalAllder";
import { Formik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  name: yup.string().required("กรุณากรอกชื่อรายการ"),
  price: yup
    .number()
    .typeError("กรุณากรอกราคาเป็นตัวเลข")
    .required("กรุณากรอกราคา")
    .min(0, "ราคาต้องไม่ติดลบ"),
});

export default function OptionProductSpecificItemModal({
  modal,
  setModal,
  productOptionDetail,
  mode = "create",
  onSubmit,
  onDelete,
}) {
  const [initialValues, setInitialValues] = useState({
    name: "",
    price: "",
  });

  useEffect(() => {
    if (mode === "edit" && productOptionDetail?.optionDetail) {
      setInitialValues({
        name: productOptionDetail.optionDetail.name,
        price: productOptionDetail.optionDetail.price,
      });
    } else {
      setInitialValues({
        name: "",
        price: "",
      });
    }
  }, [mode, productOptionDetail]);

  const handleClose = () => {
    setModal((prev) => ({ ...prev, open: false }));
  };

  const handleSubmit = (values, { setSubmitting }) => {
    if (onSubmit) {
      onSubmit({
        name: values.name,
        price: parseFloat(values.price) || 0,
      });
      handleClose();
    }
    setSubmitting(false);
  };

  return (
    <ModalAllder
      modal={modal}
      setModal={setModal}
      htitle={mode === "edit" ? "แก้ไขรายการตัวเลือก" : "เพิ่มรายการตัวเลือก"}
      hbtn={true}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          isSubmitting,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="px-4">
              {mode === "create" && productOptionDetail && (
                <Form.Group className="mb-4 form-st-3 pb-1">
                  <Form.Label className="mb-1">ชื่อตัวเลือก</Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    value={productOptionDetail.name || ""}
                    disabled
                  />
                </Form.Group>
              )}

              <Form.Group className="mb-4 form-st-3 pb-1">
                <Form.Label className="mb-1">
                  ชื่อรายการ <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="กรอกชื่อรายการ"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.name && !!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-4 form-st-3 pb-1">
                <Form.Label className="mb-1">
                  ราคา (ส่วนที่จ่ายเพิ่ม) <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  name="price"
                  className="form-control"
                  placeholder="กรอกราคา"
                  value={values.price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.price && !!errors.price}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.price}
                </Form.Control.Feedback>
              </Form.Group>

              <div className="text-end pb-4 pt-4 pe-3">
                <div className="d-flex justify-end align-items-center pt-3 btn-group-control">
                  <Button
                    type="button"
                    className={`btn btn-st-6 me-3 ${
                      mode === "create" ? "ms-auto" : ""
                    } bg-transparent`}
                    onClick={handleClose}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="submit"
                    className="btn-st-3"
                    disabled={isSubmitting}
                  >
                    บันทึก
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ModalAllder>
  );
}
