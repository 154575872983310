import React, { useState } from "react";
import { Routes, Route, Navigate, useParams, Outlet } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import { Layout } from "./layouts/layout";
import { BusinessSettings } from "./pages/settings/business/BusinessSettings";
import { ProductCountUnit } from "./pages/settings/products/ProductCountUnit";
import { ProductSaleUnit } from "./pages/settings/products/ProductSaleUnit";
import { GeneralNumberSettings } from "./pages/settings/general/GeneralNumberSettings";
import { NotFound } from "./pages/NotFound";
import { PublicRoute } from "./components/PublicRoute";
import { DepartmentManagement } from "./pages/employee/department/DepartmentManagement";
import { PositionManagement } from "./pages/employee/position/PositionManagement";
import { EmployeeManagement } from "./pages/employee/employee-list/EmployeeManagement";
import { EmployeeForm } from "./pages/employee/employee-list/EmployeeForm";
import { EmployeeRoleManagement } from "./pages/employee/employee-role/EmployeeRoleManagement";
import { EmpManageBusinessRoleForm } from "./pages/employee/employee-role/EmpManageBusinessRoleForm";
import { Login } from "./pages/login/Login";
import { BranchForm } from "./pages/branch/branch-list/BranchForm";
import { BranchManagement } from "./pages/branch/branch-list/BranchManagement";
import { BranchSettingMenu } from "./layouts/layoutbranch";
import { ProductBranchManagement } from "./pages/branch/products/ProductBranchManagement";
import { ProductBranchForm } from "./pages/branch/products/ProductBranchForm";
import { Kitchen } from "./pages/branch/kitchen/Kitchen";
import { KitchenForm } from "./pages/branch/kitchen/KitchenForm";
import { Branchgeneralsetting } from "./pages/branch/general";
import { Workingtime } from "./pages/branch/workingtime";
import { Payment } from "./pages/branch/payment/Payment";
import { Printer } from "./pages/branch/printer";
import { DocumentSetting } from "./pages/branch/document";
import { Notification } from "./pages/branch/notification";
import { Pos } from "./pages/branch/pos";
import { Emp } from "./pages/branch/emp/Emp";
import { EmpManage } from "./pages/branch/emp/EmpManage";
import { TableSetting } from "./pages/branch/tables";
import { Delivery } from "./pages/branch/delivery";
import { SalesModel } from "./pages/branch/sales-model/SalesModel";
import { MarketingPoint } from "./pages/marketing/point/MarketingPoint";
import { Customer, CustomerCreate } from "pages/customers/customer";
import {
  CustomerGroup,
  CustomerGroupList,
} from "pages/customers/customergroup";
import { MainCategoryManagement } from "./pages/product-managements/categories/MainCategoryManagement";
import { SubCategoryManagement } from "./pages/product-managements/categories/SubCategoryManagement";
import { ProductManagement } from "./pages/product-managements/products/ProductManagement";
import { ProductManageForm } from "./pages/product-managements/products/ProductManageForm";
import { ProductSetForm } from "./pages/product-managements/products/ProductSetForm";
import { AnnotationManagement } from "./pages/product-managements/annotation/AnnotationManagement";
import { AnnotationForm } from "./pages/product-managements/annotation/AnnotationForm";
import { OptionStandard } from "./pages/product-managements/standard/OptionStandard";
import { OptionEdit } from "./pages/product-managements/standard/OptionEdit";
import { OptionCreate } from "./pages/product-managements/standard/OptionCreate";

function App() {
  const [selectbranchID, setSelectBranchID] = useState(1);
  const getSlug = () => {
    const urlParts = window.location.href.split("/").filter(Boolean);
    return urlParts[2];
  };
  const slug = getSlug();

  return (
    <Routes>
      <Route path="/" element={<NotFound />} />

      <Route
        path="/:businessSlug/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      ></Route>

      <Route
        path="/:businessSlug"
        element={
          <ProtectedRoute>
            <Layout
              setSelectBranchID={setSelectBranchID}
              selectbranchID={selectbranchID}
            />
          </ProtectedRoute>
        }
      >
        <Route path="setting/business" element={<BusinessSettings />} />
        <Route path="setting/product/unit" element={<ProductCountUnit />} />
        <Route path="setting/product/saleunit" element={<ProductSaleUnit />} />
        <Route path="setting/document" element={<GeneralNumberSettings />} />

        <Route path="emp/department" element={<DepartmentManagement />} />
        <Route path="emp/position" element={<PositionManagement />} />
        <Route path="emps" element={<EmployeeManagement />} />
        <Route path="emps/create" element={<EmployeeForm />} />
        <Route path="emps/edit/:empID" element={<EmployeeForm />} />

        <Route path="emp/business" element={<EmployeeRoleManagement />} />
        <Route
          path="emp/business/create"
          element={<EmpManageBusinessRoleForm />}
        />
        <Route
          path="emp/business/edit/:empID"
          element={<EmpManageBusinessRoleForm />}
        />

        <Route path="branchs" element={<BranchManagement />} />
        <Route path="branchs/create" element={<BranchForm />} />
        <Route path="branchs/edit/:branchID" element={<BranchForm />} />

        <Route
          path={`branch`}
          element={
            <Navigate to={`/${slug}/branch/setting/info/${selectbranchID}`} />
          }
        />

        <Route
          path={`branch/setting`}
          element={
            <BranchSettingMenu
              setSelectBranchID={setSelectBranchID}
              selectbranchID={selectbranchID}
            >
              <Outlet />
            </BranchSettingMenu>
          }
        >
          <Route path={`info/:branchID`} element={<BranchForm />} />
          <Route path={`kitchen/:branchID`} element={<Kitchen />} />
          <Route path={"kitchen/:branchID/manage"} element={<KitchenForm />} />
          <Route
            path={"kitchen/:branchID/manage/:kitchenID"}
            element={<KitchenForm />}
          />
          <Route path="general/:branchID" element={<Branchgeneralsetting />} />
          <Route
            path="general/:branchID/workingtime"
            element={<Workingtime />}
          />

          <Route path="salesmodel/:branchID" element={<SalesModel />} />

          <Route path="payment/:branchID" element={<Payment />} />
          <Route path="printer/:branchID" element={<Printer />} />
          <Route path="document/:branchID" element={<DocumentSetting />} />
          <Route path="notification/:branchID" element={<Notification />} />
          <Route path="pos/:branchID" element={<Pos />} />
          <Route path="emp/:branchID" element={<Emp />} />
          <Route path="emp/:branchID/manage" element={<EmpManage />} />
          <Route
            path="emp/:branchID/manage/:permissionID"
            element={<EmpManage />}
          />
          <Route path="table/:branchID" element={<TableSetting />} />
          <Route path="delivery/:branchID" element={<Delivery />} />
          <Route
            path="product/:branchID"
            element={<ProductBranchManagement />}
          />
          <Route
            path="product/:branchID/manage/:productID"
            element={<ProductBranchForm />}
          />
        </Route>
        {/* Marketing */}
        <Route path={`marketing/point`} element={<MarketingPoint />} />
        <Route path="customer" element={<Customer />} />
        <Route path="customer/create" element={<CustomerCreate />} />
        <Route path="customer/edit/:customerID" element={<CustomerCreate />} />

        <Route path="customer-group" element={<CustomerGroup />} />
        <Route path="customer-group/:id" element={<CustomerGroupList />} />

        <Route path="products" element={<ProductManagement />} />
        <Route path="products/create" element={<ProductManageForm />} />
        <Route path="products/:pset/create" element={<ProductSetForm />} />
        <Route
          path="products/edit/:productID"
          element={<ProductManageForm />}
        />
        <Route
          path="products/:pset/edit/:productID"
          element={<ProductSetForm />}
        />
        <Route path="product/category" element={<MainCategoryManagement />} />
        <Route path="product/subcategory" element={<SubCategoryManagement />} />
        <Route path="product/options/standard" element={<OptionStandard />} />
        <Route
          path="product/options/standard/create"
          element={<OptionCreate />}
        />
        <Route
          path="product/options/standard/edit/:optionID"
          element={<OptionEdit />}
        />
        <Route
          path="product/options/annotation"
          element={<AnnotationManagement />}
        />
        <Route
          path="product/options/annotation/create"
          element={<AnnotationForm />}
        />
        <Route
          path="product/options/annotation/edit/:optionID"
          element={<AnnotationForm />}
        />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
