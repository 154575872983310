import React, { useState, useCallback, useEffect } from "react";
import Button from "react-bootstrap/Button";
import AllderTable from "../../../../components/AllderTable";
import { createTableColumns } from "../../../../utils/createTableColumns";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { branchKitchenHandler } from "../../../../redux/handlers";
import { useGlobalTable } from "../../../../hooks/useGlobalTable";
import ConfirmDeleteModal from "./modals/ConfirmDeleteModal";
import SuccessModal from "./modals/SuccessModal";
import ProductIncludeModal from "./modals/ProductIncludeModal";
import { useKitchenRefresh } from "./KitchenRefreshContext";

export default function KitchenMenuManagement() {
  const dispatch = useDispatch();
  const { branchID, kitchenID, businessSlug } = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);

  const { refreshTrigger, refreshAllKitchenData } = useKitchenRefresh();

  const [modalproductlistShow, setModalproductlistShow] = useState({
    open: false,
    x: 0,
    y: 0,
    width: 1270,
    height: 680,
    pin: false,
    mode: "normal",
    modalname: "edit",
  });

  const fetchKitchenProducts = useCallback(
    (params) =>
      dispatch(
        branchKitchenHandler.getBranchKitchenProducts(kitchenID, {
          page: params.page ?? 1,
          perPage: params.per_page ?? 10,
          s: params.s,
          sortBy: params.sortBy,
          sortOrder: params.sortOrder,
          timestamp: Date.now(),
        })
      ),
    [kitchenID, dispatch]
  );

  const {
    data: products,
    loading,
    totalRows,
    paginationModel,
    setPaginationModel,
    handleSort,
    debouncedSearch,
    filterParams,
    setFilterParams,
    refreshData,
  } = useGlobalTable({
    fetchData: fetchKitchenProducts,
    defaultSort: "created_at",
    defaultOrder: "desc",
    defaultPageSize: 10,
  });

  useEffect(() => {
    refreshData();
  }, [refreshTrigger, refreshData]);

  const forceRefresh = useCallback(() => {
    refreshAllKitchenData();
  }, [refreshAllKitchenData]);

  const columns = createTableColumns(
    [
      {
        field: "sku",
        headerName: "รหัสรายการ",
        width: 150,
        sortable: false,
        renderCell: ({ row }) => (
          <div style={{ color: "#2DC76D", fontWeight: 500 }}>
            {row.product?.sku}
          </div>
        ),
      },
      {
        field: "name",
        headerName: "ชื่อรายการ",
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <div>{row.product?.name}</div>,
      },
      {
        field: "category",
        headerName: "หมวดหมู่ย่อยสินค้า",
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <div>{row.product?.productSubCategory?.name || ""}</div>
        ),
      },
      {
        field: "actions",
        headerName: "จัดการ",
        width: 100,
        sortable: false,
        type: "actions",
      },
    ],
    (row) => (
      <div className="">
        <Button
          type="button"
          className="btn-delete-st-3"
          onClick={() => handleDelete(row)}
        />
      </div>
    )
  );

  const handleDelete = (product) => {
    const productName = product?.product?.name || "รายการนี้";
    setSelectedProduct({
      id: product.id,
      name: productName,
    });
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    if (selectedProduct) {
      try {
        await dispatch(
          branchKitchenHandler.deleteBranchKitchenProduct(selectedProduct.id)
        );
        setSuccessMessage("ลบรายการครัวสำเร็จ");
        setShowSuccessModal(true);

        setTimeout(() => {
          forceRefresh();
          window.location.reload();
        }, 300);
      } catch (error) {
        console.error("Error deleting product:", error);
      }
    }
    setShowDeleteModal(false);
  };

  const handleSubmit = async (selectedProducts) => {
    try {
      await dispatch(
        branchKitchenHandler.createBranchKitchenProduct({
          branchKitchenId: parseInt(kitchenID),
          productIds: selectedProducts.map((product) => product.id).join(","),
        })
      );

      setModalproductlistShow((prev) => ({ ...prev, open: false }));
      setSuccessMessage("เพิ่มรายการสำเร็จ");
      setShowSuccessModal(true);

      setTimeout(() => {
        forceRefresh();
        window.location.reload();
      }, 500);
    } catch (error) {
      console.error("Error adding products to kitchen:", error);
    }
  };

  const dataWithIndex = products?.map((item, index) => ({
    ...item,
    index: index + 1,
  }));

  return (
    <div>
      <div className="card card-st-2 mt-4">
        <div className="card-body px-0 py-4">
          <div className="d-flex align-items-center px-4 mb-4">
            <div className="tc-7 fs-20 fw-6 me-auto">รายการเมนู</div>
            <div className="me-3">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหาชื่อเมนู"
                onChange={(e) => debouncedSearch(e.target.value)}
              />
            </div>
            <Button
              type="button"
              className="btn btn-st-7"
              onClick={() =>
                setModalproductlistShow((prev) => ({ ...prev, open: true }))
              }
            >
              เพิ่มรายการ
            </Button>
          </div>

          <AllderTable
            data={dataWithIndex || []}
            loading={loading}
            columns={columns}
            totalRows={totalRows}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            handleSort={handleSort}
            pageSizeOptions={[5, 10, 20]}
          />
        </div>
      </div>

      <ProductIncludeModal
        show={modalproductlistShow}
        onHide={() =>
          setModalproductlistShow((prev) => ({ ...prev, open: false }))
        }
        handleSubmit={handleSubmit}
        modal={modalproductlistShow}
        setModal={setModalproductlistShow}
        businessSlug={businessSlug}
        triggerRefresh={forceRefresh}
        branchId={branchID}
        branchKitchenId={kitchenID}
      />

      <ConfirmDeleteModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
        onSuccess={handleConfirmDelete}
        displayName={selectedProduct?.name}
        kitchenContext={true}
      />

      <SuccessModal
        show={showSuccessModal}
        onHide={() => {
          setShowSuccessModal(false);
          forceRefresh();
        }}
        textsuccess={successMessage}
      />
    </div>
  );
}
