import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export const UserInfo = ({ values, handleChange, errors, touched, empID, setModalEmail, setModalChangePassword, setModalChangePin, formdisabled }) => {
  const [inputtype, setInputtype] = useState("password");
  const [inputtype2, setInputtype2] = useState("password");

  return (
    <div className="card-body px-xl-3">
      <div className="flex items-center">
        <div className="flex items-center">
          {/* Action Buttons - Only show when editing existing employee */}
          {empID && (
            <>
              <div className="mr-3">
                <Button
                  type="button"
                  className="btn-st-3 !bg-[#FF6B00] !border-0 w-[140px] !rounded-lg !text-white px-4"
                  variant="contained"
                  onClick={() => setModalEmail((prev) => ({ ...prev, open: true }))}
                >
                  เปลี่ยนอีเมล
                </Button>
              </div>
              <div className="mr-3">
                <Button
                  type="button"
                  className="btn-st-3 !bg-[#409FFB] !border-0 w-[160px] !rounded-lg !text-white px-4"
                  variant="contained"
                  onClick={() => setModalChangePassword((prev) => ({ ...prev, open: true }))}
                >
                  เปลี่ยนรหัสผ่าน
                </Button>
              </div>
              <div className="mr-3">
                <Button
                  type="button"
                  className="btn-st-3 !bg-[#2DC76D] !border-0 w-[140px] !rounded-lg !text-white px-4"
                  variant="contained"
                  onClick={() => setModalChangePin((prev) => ({ ...prev, open: true }))}
                >
                  เปลี่ยน PIN
                </Button>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="pt-3 px-3">
        <div className="row gx-xl-4">
          {/* Email Input */}
          <div className="col-xl-6 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">
                E-mail สำหรับเข้าใช้งาน <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="ระบุ E-mail สำหรับเข้าใช้งาน"
                onChange={handleChange}
                isValid={touched?.email && !errors?.email}
                isInvalid={!!errors?.email}
                className="form-st-1 mb-4"
                value={values?.email}
                autoComplete="off"
                disabled={empID}
              />
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>
          </div>

          {/* Password Input */}
          {/* <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">
                รหัสผ่าน <span className="text-danger">*</span>
              </Form.Label>
              <div className="position-relative">
                <Form.Control
                  type={inputtype}
                  name="password"
                  placeholder="ระบุรหัสผ่าน"
                  onChange={handleChange}
                  isValid={touched?.password && !errors?.password}
                  isInvalid={!!errors?.password}
                  className="form-st-1 mb-4"
                  value={values?.password}
                  autoComplete="off"
                  disabled={empID}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>

                <Button
                  type="button"
                  className="btn btn-change-type-password"
                  onClick={() =>
                    setInputtype(inputtype === "password" ? "text" : "password")
                  }
                >
                  <i
                    className={`bi bi-eye${
                      inputtype === "password" ? "" : "-slash"
                    }`}
                  ></i>
                </Button>
              </div>
            </Form.Group>
          </div> */}

          {/* PIN Input */}
          <div className="col-xl-6 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">
                รหัส PIN 4 หลัก <span className="text-danger">*</span>
              </Form.Label>
              <div className="position-relative">
                <Form.Control
                  type={inputtype2}
                  name="empPin"
                  placeholder="ระบุ PIN"
                  onChange={handleChange}
                  isValid={touched?.empPin && !errors?.empPin}
                  isInvalid={!!errors?.empPin}
                  className="form-st-1 mb-4"
                  value={values?.empPin}
                  autoComplete="off"
                  maxLength={4}
                  disabled={empID}
                />
                <Form.Control.Feedback type="invalid">{errors.empPin}</Form.Control.Feedback>
                <Button type="button" className="btn btn-change-type-password" onClick={() => setInputtype2(inputtype2 === "password" ? "text" : "password")}>
                  <i className={`bi bi-eye${inputtype2 === "password" ? "" : "-slash"}`}></i>
                </Button>
              </div>
            </Form.Group>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
