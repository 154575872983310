import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function ConfirmDeleteModal(props) {
  const {
    onSuccess,
    onHide,
    displayName,
    categoryContext,
    kitchenContext,
    ...rest
  } = props;

  const handleDelete = () => {
    onHide();
    if (onSuccess) {
      onSuccess();
    }
  };

  const itemName = displayName || "รายการนี้";
  let contextMessage = "ออกจากรายการ";

  if (kitchenContext) {
    contextMessage = "ออกจากเมนูครัว";
  } else if (categoryContext) {
    contextMessage = "ออกจากหมวดหมู่ครัว";
  }

  return (
    <Modal {...rest} size="md" centered>
      <Modal.Body>
        <div className="px-3 py-4">
          <div>
            <div>
              <div className="text-center fs-22 tc-7 mb-2">
                คุณต้องการลบ {itemName}
              </div>
              <div className="text-center fs-22 tc-7">
                {contextMessage}หรือไม่
              </div>
            </div>

            <div className="text-center pt-4 mt-3 btn-group-control">
              <Button
                type="button"
                className="btn-st-3 mx-3"
                style={{ width: "100px" }}
                onClick={handleDelete}
              >
                ใช่
              </Button>
              <Button
                type="button"
                className="btn-st-6 mx-3"
                style={{ width: "100px" }}
                onClick={onHide}
              >
                ไม่ใช่
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
