import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  product: null,
  productBranch: null,
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setProductBranch: (state, action) => {
      state.productBranch = action.payload;
    },
  },
});

export const { setProduct } = productSlice.actions;
export default productSlice.reducer;
