import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { employeeHandler } from "../../../redux/handlers";
import { useGlobalTable } from "../../../hooks/useGlobalTable";
import { createTableColumns } from "../../../utils/createTableColumns";
import { AllderTable } from "../../../components/AllderTable";
import { useBreadcrumb } from "../../../hooks/useBreadcrumb";
import Breadcrumb from "../../../components/Breadcrumb";

export function EmployeeManagement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { businessSlug } = useParams();
  const [activeTab, setActiveTab] = useState("ALL");
  let fetchEmployees = useCallback(
    (params) =>
      dispatch(
        employeeHandler.getEmployees({
          business_slug: businessSlug,
          statusResign: activeTab,
          ...params,
        })
      ),
    [businessSlug, dispatch, activeTab]
  );

  const TABS = [
    {
      key: "ALL",
      label: "พนักงานทั้งหมด",
    },
    {
      key: "RESIGNED",
      label: "พนักงานที่ลาออก",
    },
  ];

  const {
    data: employees,
    loading,
    totalRows,
    paginationModel,
    setPaginationModel,
    handleSort,
    debouncedSearch,
    refreshData: refreshEmployees,
    filterParams,
  } = useGlobalTable({
    fetchData: fetchEmployees,
    defaultSort: "created_at",
    defaultOrder: "asc",
    defaultPageSize: 20,
  });

  const breadcrumbConfig = [
    { label: "หน้าหลัก", path: "/" },
    { label: "พนักงาน", path: "#" },
    { label: "รายชื่อพนักงาน" },
  ];
  useBreadcrumb(breadcrumbConfig);

  const handleExport = async () => {
    try {
      await dispatch(
        employeeHandler.exportEmployeeToExcel(
          businessSlug,
          filterParams.s,
          activeTab
        )
      );
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const columns = createTableColumns(
    [
      {
        field: "code",
        headerName: "รหัสพนักงาน",
        width: 200,
        sortable: true,
        renderCell: ({ row }) => (
          <div
            style={{
              color: "#2DC76D",
              fontWeight: 500,
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => navigate(`/${businessSlug}/emps/edit/${row.id}`)}
          >
            {row.business_id == null ? "" : row.code}
          </div>
        ),
      },
      {
        field: "first_name",
        headerName: "ชื่อพนักงาน (ชื่อเล่น)",
        flex: 1,
        sortable: true,
        renderCell: ({ row }) => (
          <div style={{ fontWeight: 500 }}>
            {`${row.first_name} ${row.last_name} ${
              row.emp_nickname ? `(${row.emp_nickname})` : ""
            }`}
          </div>
        ),
      },
      {
        field: "department",
        headerName: "แผนก",
        width: 200,
        sortable: true,
        renderCell: ({ row }) => (
          <div>
            {row.business_id == null
              ? "เจ้าของธุรกิจ"
              : `${row.empWorkBusinessEmployeeDepartment?.name_th ?? ""} `}
          </div>
        ),
      },
      {
        field: "position",
        headerName: "ตำแหน่ง",
        width: 200,
        sortable: true,
        renderCell: ({ row }) => (
          <div
            className={
              row.emp_work_business_employee_position_id === 1 // Assuming 1 is owner position ID
                ? "py-1 px-3 text-red-500 bg-red-50 rounded-lg"
                : "ps-3"
            }
          >
            {row.business_id == null
              ? "เจ้าของธุรกิจ"
              : `${row.empWorkBusinessEmployeePosition?.name_th ?? ""} `}
          </div>
        ),
      },
      {
        field: "phone",
        headerName: "เบอร์โทร",
        width: 200,
        sortable: true,
        renderCell: ({ row }) => <div>{row.phone_number}</div>,
      },
      {
        field: "email",
        headerName: "E-MAIL",
        width: 250,
        sortable: true,
        renderCell: ({ row }) => <div>{row.email}</div>,
      },
      {
        field: "actions",
        headerName: "จัดการ",
        width: 200,
        sortable: false,
        type: "actions",
      },
    ],
    (row) => (
      <div>
        {row.business_id == null ? (
          ""
        ) : (
          <Button
            type="button"
            className="btn-edit-st-1"
            onClick={() => {
              navigate(`/${businessSlug}/emps/edit/${row.id}`);
            }}
          />
        )}
      </div>
    )
  );

  const TabNavigation = ({ activeTab, isEditMode, onTabChange }) => (
    <nav>
      <div className="nav nav-tabs nav-tabs-st-2" role="tablist">
        {TABS.map(({ key, label }) => (
          <button
            key={key}
            className={`nav-link ${activeTab == key ? "active" : ""}`}
            type="button"
            onClick={() => isEditMode && onTabChange(key)}
            disabled={!isEditMode}
          >
            {label}
          </button>
        ))}
      </div>
    </nav>
  );

  return (
    <div>
      <Breadcrumb />
      <div className="fs-24 tc-7 mb-4 lh-1">รายชื่อพนักงาน</div>
      <div className="card card-st-1">
        <div className="card-header">
          <TabNavigation
            activeTab={activeTab}
            isEditMode={true}
            onTabChange={(key) => {
              setActiveTab(key);
            }}
          />
        </div>
        <div className="card-body px-0 py-4">
          <div className="d-flex align-items-center px-4 mb-4">
            <button
              className="btn btn-export-st-1 me-auto"
              onClick={handleExport}
            >
              Export
            </button>
            <div className="me-3">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหาชื่อ, ชื่อเล่น, รหัสพนักงาน, เบอร์โทร"
                onChange={(e) => debouncedSearch(e.target.value)}
              />
            </div>
            <Button
              type="button"
              className="btn btn-st-7"
              onClick={() => {
                navigate(`/${businessSlug}/emps/create`);
              }}
            >
              เพิ่มพนักงาน
            </Button>
          </div>

          <AllderTable
            data={employees}
            loading={loading}
            columns={columns}
            totalRows={totalRows}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            handleSort={handleSort}
          />
        </div>
      </div>
    </div>
  );
}
