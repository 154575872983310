import { useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { employeeHandler } from "../../../redux/handlers";
import { useGlobalTable } from "../../../hooks/useGlobalTable";
import { useDispatch } from "react-redux";
import { AllderTable } from "../../../components/AllderTable";
import _ from "lodash";
import { useBreadcrumb } from "../../../hooks/useBreadcrumb";
import Breadcrumb from "../../../components/Breadcrumb";

export function EmployeeRoleManagement() {
  const dispatch = useDispatch();
  const { businessSlug } = useParams();
  const fetchEmployeeRole = useCallback(
    (params) =>
      dispatch(
        employeeHandler.getEmployeesRole({
          business_slug: businessSlug,
          ...params,
        })
      ),
    [businessSlug, dispatch]
  );

  const {
    data: role,
    loading,
    totalRows,
    paginationModel,
    setPaginationModel,
    handleSort,
    debouncedSearch,
    refreshData: refreshEmployees,
    filterParams,
  } = useGlobalTable({
    fetchData: fetchEmployeeRole,
    defaultSort: "created_at",
    defaultOrder: "asc",
    defaultPageSize: 20,
  });

  const breadcrumbConfig = [
    { label: "หน้าหลัก", path: "/" },
    { label: "พนักงาน", path: "#" },
    { label: "จัดการผู้ใช้งานธุรกิจ" },
  ];
  useBreadcrumb(breadcrumbConfig);

  const columns = [
    {
      field: "code",
      headerName: "รหัสพนักงาน",
      width: 170,
      sortable: false,
      renderCell: ({ row }) => (
        <Link
          to={`/${businessSlug}/emp/business/edit/${row.id}`}
          style={{
            color: "#2DC76D",
            fontWeight: 500,
            textDecoration: "underline",
          }}
          className="hover:underline"
        >
          {row.business_id == null ? "" : row.code}
        </Link>
      ),
    },

    {
      field: "name",
      headerName: "ชื่อพนักงาน (ชื่อเล่น)",
      flex: 2,
      sortable: false,
      renderCell: ({ row }) => (
        <div>
          {row.first_name} {row.last_name}{" "}
          {row.nickname ? `(${row.nickname})` : ""}{" "}
        </div>
      ),
    },

    {
      field: "position",
      headerName: "ตำแหน่ง",
      width: 200,
      sortable: false,
      renderCell: ({ row }) => (
        <div>
          {row.empWorkBusinessEmployeePosition
            ? row.empWorkBusinessEmployeePosition.name_th
            : ""}
        </div>
      ),
    },
    {
      field: "permission",
      headerName: "สิทธิ์การใช้งาน",
      width: 200,
      sortable: false,
      renderCell: ({ row }) => {
        const roleStyles = {
          แอดมินธุรกิจสูงสุด: {
            color: "#2DC76D",
            background: "#EEF4FF",
          },
          ผู้จัดการทั่วไป: {
            color: "#000000",
            background: "#FFFFFF",
          },
          ผู้จัดการสาขา: {
            color: "#000000",
            background: "#FFFFFF",
          },
        };

        const defaultStyle = {
          color: "#FF0000",
          background: "#FFEEEE",
        };

        const { color, background } =
          roleStyles[row.empBusinessRole?.name] || defaultStyle;
        return (
          <div
            className="py-1 px-3"
            style={{
              color: color,
              background: background,
              borderRadius: "8px",
            }}
          >
            {row.empBusinessRole ? row.empBusinessRole.name : "Super Admin"}
          </div>
        );
      },
    },
    {
      field: "permissionBranch",
      headerName: "สิทธิ์ใช้งานสาขา",
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => (
        <div>
          {row.business_id == null ? (
            "ทุกสาขา"
          ) : (
            <>
              {row.emp_work_is_all_branch === true
                ? "ทุกสาขา"
                : _.uniqBy(row.userBusinessGroupBranch || [], "branch.id")
                    .map((item) => item.branch.branch_name)
                    .join(", ")}
            </>
          )}
        </div>
      ),
    },
    {
      field: "empID",
      headerName: "จัดการ",
      width: 120,

      sortable: false,
      renderCell: ({ row }) => (
        <div>
          {row.business_id == null ? (
            ""
          ) : (
            <Link
              className="btn btn-edit-st-1 me-2"
              to={`/${businessSlug}/emp/business/edit/${row.id}`}
            ></Link>
          )}
        </div>
      ),
    },
  ];

  const downloadExcel = async () => {
    try {
      await dispatch(
        employeeHandler.exportEmployeeRoleToExcel(businessSlug, filterParams.s)
      );
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  return (
    <div>
      <Breadcrumb />
      <div className="card card-st-2">
        <div className="card-body px-0 py-4">
          <div className="text-lg font-bold  lh-1  px-4 mb-10 text-[#2DC76D]">
            {" "}
            จัดการผู้ใช้งานธุรกิจ
          </div>
          <div className="d-flex align-items-center px-4 mb-4">
            <button
              className="btn btn-export-st-1 me-auto"
              onClick={downloadExcel}
            >
              Export
            </button>
            <div className="me-3">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหาชื่อ"
                onChange={(e) => debouncedSearch(e.target.value)}
              />
            </div>
            <Link
              to={`/${businessSlug}/emp/business/create`}
              className="btn btn btn-st-7 text-decoration-none"
            >
              เพิ่มพนักงาน
            </Link>
          </div>

          <AllderTable
            data={role}
            loading={loading}
            columns={columns}
            totalRows={totalRows}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            handleSort={handleSort}
          />
        </div>
      </div>
    </div>
  );
}
