import Form from "react-bootstrap/Form";
const Potalcode = (props) => {
  return (
    <Form.Group className="mb-2 form-st-3 pb-1">
      <Form.Label className="mb-1">รหัสไปรษณีย์ </Form.Label>
      <Form.Control
        type="text"
        name="potalcode"
        className="form-control"
        placeholder="รหัสไปรษณีย์"
        value={props.values.potalcode}
        disabled={true}
      />
    </Form.Group>
  );
};

export default Potalcode;
