import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import ButtonHideOption from "../ButtonHideOption";
import ButtonShowOption from "../ButtonShowOption";
import { ProductAnnotationModal } from "../modals";
import { productBranchHandler } from "../../../../../redux/handlers";

export function OptionAnnotation() {
  const dispatch = useDispatch();
  const { productID } = useParams();
  const product = useSelector((state) => state.product.product);
  const productBranch = useSelector(
    (state) => state.productBranch.productBranch
  );

  const [isAnnotationEnabled, setIsAnnotationEnabled] = useState(false);
  const [annotationOptions, setAnnotationOptions] = useState([]);
  const [productOptionDetail, setProductOptionDetail] = useState({
    id: null,
    optionDetail: null,
  });
  const [editModalConfig, setEditModalConfig] = useState({
    open: false,
    x: 0,
    y: 0,
    width: 500,
    height: 340,
    pin: false,
    mode: "normal",
    modalname: "modal_edit_option_product",
  });

  useEffect(() => {
    setIsAnnotationEnabled(
      productBranch?.data?.is_annotation ?? product?.is_annotation
    );
    if (product?.productAnnotationRelation) {
      const baseOptions = product.productAnnotationRelation.map((relation) => {
        const annotation = relation.productAnnotation;
        return {
          id: annotation.id,
          name: annotation.name,
          isVisible: annotation.is_display,
          product_option_detail: annotation.productAnnotationItem.map(
            (item) => ({
              id: item.id,
              name: item.name,
              isVisible: true,
            })
          ),
        };
      });

      if (productBranch?.data?.data?.product_annotation_relation) {
        const branchAnnotations =
          productBranch.data.data.product_annotation_relation;

        const updatedOptions = baseOptions.map((baseOption) => {
          const matchingBranchAnnotations = branchAnnotations.filter(
            (branchAnnotation) =>
              baseOption.id === branchAnnotation.product_annotation_id
          );

          if (matchingBranchAnnotations.length > 0) {
            return {
              ...baseOption,
              product_option_detail: baseOption.product_option_detail.map(
                (detail) => {
                  const matchingBranchAnnotation =
                    matchingBranchAnnotations.find(
                      (ba) => ba.product_annotation_item_id === detail.id
                    );

                  if (matchingBranchAnnotation) {
                    return {
                      ...detail,
                      isVisible: !matchingBranchAnnotation.is_hidden,
                    };
                  }
                  return detail;
                }
              ),
            };
          }
          return baseOption;
        });
        setAnnotationOptions(updatedOptions);
      } else {
        setAnnotationOptions(baseOptions);
      }
    }
  }, [product, productBranch]);

  const handleAnnotationEnableToggle = async (newStatus) => {
    try {
      await dispatch(
        productBranchHandler.updateIsAnnotation(productID, newStatus)
      );
      setIsAnnotationEnabled(newStatus);
    } catch (error) {
      console.error("Error updating annotation status:", error);
    }
  };

  const handleAnnotationVisibilityToggle = async (annotationId, isVisible) => {
    try {
      const targetAnnotation = annotationOptions.find(
        (option) => option.id === annotationId
      );
      if (!targetAnnotation) return;

      const updatePayload = targetAnnotation.product_option_detail.map(
        (optionDetail) => ({
          product_id: productID,
          product_annotation_id: annotationId,
          product_annotation_item_id: optionDetail.id,
          is_hidden: !isVisible,
        })
      );

      await dispatch(
        productBranchHandler.updateAnnotationRelationToggleHidden(
          productID,
          updatePayload
        )
      );

      setAnnotationOptions((currentOptions) =>
        currentOptions.map((option) =>
          option.id === annotationId
            ? {
                ...option,
                isVisible: isVisible,
                product_option_detail: option.product_option_detail.map(
                  (detail) => ({
                    ...detail,
                    isVisible: isVisible,
                  })
                ),
              }
            : option
        )
      );
    } catch (error) {
      console.error("Error updating annotation visibility:", error);
    }
  };

  const handleAnnotationItemVisibilityToggle = async (
    annotationId,
    itemId,
    isVisible
  ) => {
    try {
      const targetAnnotation = annotationOptions.find(
        (option) => option.id === annotationId
      );
      if (!targetAnnotation) return;

      const updatePayload = {
        product_id: productID,
        product_annotation_id: annotationId,
        product_annotation_item_id: itemId,
        is_hidden: !isVisible,
      };

      await dispatch(
        productBranchHandler.updateAnnotationRelationToggleHidden(productID, [
          updatePayload,
        ])
      );

      setAnnotationOptions((currentOptions) =>
        currentOptions.map((option) =>
          option.id === annotationId
            ? {
                ...option,
                product_option_detail: option.product_option_detail.map(
                  (detail) =>
                    detail.id === itemId
                      ? {
                          ...detail,
                          isVisible: isVisible,
                        }
                      : detail
                ),
              }
            : option
        )
      );
    } catch (error) {
      console.error("Error updating annotation item visibility:", error);
    }
  };

  const handleOptionDetailUpdate = async (updatedOptionData) => {
    try {
      setAnnotationOptions((currentOptions) =>
        currentOptions.map((option) =>
          option.id === updatedOptionData.id
            ? {
                ...option,
                product_option_detail: option.product_option_detail.map(
                  (detail) =>
                    detail.id === updatedOptionData.optionDetail.id
                      ? {
                          ...detail,
                          isVisible: updatedOptionData.optionDetail.isVisible,
                        }
                      : detail
                ),
              }
            : option
        )
      );

      setEditModalConfig((currentConfig) => ({
        ...currentConfig,
        open: false,
      }));
    } catch (error) {
      console.error("Error updating annotation details:", error);
    }
  };

  return (
    <div className="card card-st-3 mb-3">
      <div className="card-body px-xl-3">
        <div className="pt-2 pb-1 px-3">
          <Form.Check
            type="switch"
            id="custom-sdx"
            label="กำหนดหมายเหตุ"
            name="optionNoteStatus"
            className="custom-switch-st-1 s1 mb-1"
            checked={isAnnotationEnabled}
            onChange={() => handleAnnotationEnableToggle(!isAnnotationEnabled)}
          />
          <span className="ms-2" style={{ color: "#858796" }}>
            (เพิ่มหมายเหตุในรายการอาหาร)
          </span>
        </div>

        {isAnnotationEnabled && (
          <div className="px-3 py-4">
            <div className="mt-0 px-4">
              <div className="w-full">
                {annotationOptions?.map((item, index) => (
                  <div className="row gx-4 align-items-center mb-4" key={index}>
                    <div className="col-xl-6 col-6">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">ตัวเลือก</Form.Label>
                        <Form.Control
                          type="text"
                          name="note"
                          className="form-control"
                          placeholder="ตัวเลือก"
                          value={item.name}
                          disabled={true}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-xl-4 col-6">
                      {item.isVisible ? (
                        <ButtonShowOption
                          handleClick={() =>
                            handleAnnotationVisibilityToggle(item.id, false)
                          }
                          label={true}
                        />
                      ) : (
                        <ButtonHideOption
                          handleClick={() =>
                            handleAnnotationVisibilityToggle(item.id, true)
                          }
                          label={true}
                        />
                      )}
                    </div>

                    {item.product_option_detail?.map((item2, index2) => (
                      <div
                        className="col-xl-4 row gx-4 align-items-center"
                        key={index2}
                      >
                        <div className="col">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">รายการ</Form.Label>
                            <Form.Control
                              type="text"
                              name="listName"
                              className="form-control"
                              placeholder="รายการ"
                              value={item2.name}
                              disabled={true}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-auto">
                          <div
                            className="d-flex align-items-center"
                            style={{ width: "80px" }}
                          >
                            <button
                              type="button"
                              className="btn d-block btn-edit-st-1 mr-2"
                              onClick={() => {
                                setProductOptionDetail({
                                  id: item.id,
                                  optionDetail: item2,
                                });
                                setEditModalConfig((prev) => ({
                                  ...prev,
                                  open: true,
                                }));
                              }}
                            />
                            {item2.isVisible ? (
                              <ButtonShowOption
                                handleClick={() =>
                                  handleAnnotationItemVisibilityToggle(
                                    item.id,
                                    item2.id,
                                    false
                                  )
                                }
                                label={false}
                              />
                            ) : (
                              <ButtonHideOption
                                handleClick={() =>
                                  handleAnnotationItemVisibilityToggle(
                                    item.id,
                                    item2.id,
                                    true
                                  )
                                }
                                label={false}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>

            <ProductAnnotationModal
              modal={editModalConfig}
              setModal={setEditModalConfig}
              productOptionDetail={productOptionDetail}
              setProductOptionDetail={setProductOptionDetail}
              onSubmit={handleOptionDetailUpdate}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default OptionAnnotation;
