import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import 'react-datepicker/dist/react-datepicker.css';
import { Link, useParams, useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import AlacartBuffetModel from './components/AlacartBuffetModel';
import AlacartModel from './components/AlacartModel';
export function SalesModel() {
  const { branchID } = useParams();

  const [selectSaleModel, setSelectsalemodell] = useState(null);
  const [saleModel, setSalemodel] = useState(null);

  const getModel = (branchID) => {
    fetch('/json/saleModel.json')
      .then((response) => response.json())
      .then((result) => {
        setSelectsalemodell(result.salemodelID);
        setSalemodel(result.salemodelID);
      })
      .catch((error) => console.log('error', error));
  };

  const handleSave = () => {
    setSalemodel(selectSaleModel);
  };

  useEffect(() => {
    getModel(branchID);
  }, []);

  return (
    <div style={{ padding: '30px 33px 33px 31px' }}>
      <div className="card card-st-1 mb-4">
        <div className="card-body pb-4">
          <div className="tc-8 fs-18 fw-bold px-3 mb-4">รูปแบบการขาย</div>
          <div className="d-flex px-3 flex-wrap mb-2">
            <div className="selectmodel mb-3 me-4">
              <input
                type="radio"
                className="hide-input"
                name="selectmodel"
                id="type1"
                onChange={() => setSelectsalemodell(1)}
                checked={selectSaleModel == 1}
              />
              <label htmlFor="type1">
                <div className="check mb-2"> A La Cart </div>
                <div>คิดราคาอาหารต่อจาน</div>
              </label>
            </div>
            <div className="selectmodel mb-3">
              <input
                type="radio"
                className="hide-input"
                name="selectmodel"
                id="type2"
                onChange={() => setSelectsalemodell(2)}
                checked={selectSaleModel == 2}
              />
              <label htmlFor="type2">
                <div className="check mb-2"> A La Cart + Buffet </div>
                <div>คิดราคาอาหารต่อจาน + ราคาเหมาจ่ายตามรายการด้านล่าง</div>
              </label>
            </div>
          </div>
          <div className="text-end pt-3 btn-group-control">
            <Button
              type="submit"
              className="btn-st-3 "
              onClick={() => handleSave()}
              disabled={!saleModel}
            >
              {saleModel ? 'แก้ไข' : 'บันทึก'}
            </Button>
          </div>
        </div>
      </div>
      {/* 
      {selectSaleModel == 1 ? <AlacartModel saleModel={saleModel} /> : ''}

      {selectSaleModel == 2 ? <AlacartBuffetModel saleModel={saleModel} /> : ''} */}
      {selectSaleModel == 2 ? <AlacartBuffetModel saleModel={saleModel} /> : ''}
    </div>
  );
}
