import React, { useEffect, useRef, useState } from 'react';

import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import { Formik } from 'formik';
import * as yup from 'yup';
import 'react-datepicker/dist/react-datepicker.css';

import { ModalAllder } from 'layouts/ModalAllder';
const StockModal = ({
  modal,
  setModal,
  setStock,
  stock,
  listID,
  optiondata,
  setOptiondata,
  ...props
}) => {
  const schema = yup.object().shape({
    productID: yup.string().required(),
  });

  const optionsStoreType = [{ value: 1, label: 'คลังสินค้าวัตถุดิบ' }];

  const optionsProduct = [
    { value: 1, label: 'ไข่ไก่สด' },
    { value: 2, label: 'ไก่สด' },
  ];

  const optionList = optiondata.optionList.filter((item) => item.id === listID);

  return (
    <ModalAllder
      modal={modal}
      setModal={setModal}
      htitle={'ตั้งค่าเชื่อมต่อสต๊อกสินค้า'}
      hbtn={true}
    >
      <div className="px-3">
        <Formik
          enableReinitialize
          initialValues={stock}
          validationSchema={schema}
          onSubmit={async (values) => {
            setOptiondata((prev) => ({
              ...prev,
              optionList: prev.optionList.map((el) =>
                el.id == listID
                  ? {
                      ...el,
                      stock: values,
                    }
                  : el
              ),
            }));

            props.onHide();
          }}
        >
          {({
            handleSubmit,
            handleChange,
            touched,
            errors,
            values,
            setFieldValue,
            isInvalid,
            isValid,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3 form-st-3 pb-1">
                <Form.Label className="mb-1">ชื่อรายการ </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="ชื่อรายการ"
                  value={
                    optionList && optionList.length > 0
                      ? optionList[0].name
                      : ''
                  }
                  isInvalid={!!errors.name}
                  disabled
                />
              </Form.Group>

              <Form.Group className="mb-2 form-st-3 pb-1">
                <Form.Label className="mb-1">
                  ประเภทคลังสินค้าที่ตัดสต๊อก
                </Form.Label>
                <Select
                  as="select"
                  name="storeHouse"
                  onChange={(e) => {
                    setFieldValue('storeHouse', e.value);
                  }}
                  value={optionsStoreType.filter(
                    (item) => item.value === values.storeHouse
                  )}
                  className="w-100 select-st-1 text-left"
                  options={optionsStoreType}
                  placeholder="ประเภทคลังสินค้าที่ตัดสต๊อก"
                />
              </Form.Group>

              <Form.Group className="mb-2 form-st-3 pb-1">
                <Form.Label className="mb-1">สินค้าที่ตัดสต๊อก</Form.Label>
                <Select
                  as="select"
                  name="productID"
                  onChange={(e) => {
                    setFieldValue('productID', e.value);
                  }}
                  value={optionsProduct.filter(
                    (item) => item.value === values.productID
                  )}
                  className="w-100 select-st-1 text-left"
                  options={optionsProduct}
                  placeholder="สินค้าที่ตัดสต๊อก"
                />
              </Form.Group>

              <Form.Group className="mb-3 form-st-3 pb-1">
                <Form.Label className="mb-1">จำนวน </Form.Label>
                <Form.Control
                  type="text"
                  name="qty"
                  className="form-control"
                  placeholder="จำนวน"
                  value={values.qty}
                  onChange={handleChange}
                  isInvalid={!!errors.qty}
                />
              </Form.Group>
              <Form.Group className="mb-3 form-st-3 pb-1">
                <Form.Label className="mb-1">หน่วยวัตถุดิบ / UMS </Form.Label>
                <Form.Control
                  type="text"
                  name="unit"
                  className="form-control"
                  placeholder="หน่วยวัตถุดิบ / UMS"
                  value={values.unit}
                  onChange={handleChange}
                  isInvalid={!!errors.unit}
                  disabled={true}
                />
              </Form.Group>

              <div className="d-flex align-items-center pt-2 pb-3 btn-group-control">
                {optionList && optionList.length > 0 && optionList[0].stock ? (
                  <Button
                    type="button"
                    className="btn-st-5 me-auto "
                    style={{ width: '80px' }}
                    onClick={props.deletestock}
                  >
                    ลบ
                  </Button>
                ) : (
                  <div className="me-auto"></div>
                )}

                <Button
                  type="button"
                  className="btn-st-6 me-3 "
                  style={{ width: '100px' }}
                  onClick={props.onHide}
                >
                  ยกเลิก
                </Button>
                <Button
                  type="submit"
                  className="btn-st-3  "
                  style={{ width: '100px' }}
                >
                  บันทึก
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </ModalAllder>
  );
};
export default StockModal;
