import React, { createContext, useContext, useState } from "react";

const KitchenRefreshContext = createContext();

export const KitchenRefreshProvider = ({ children }) => {
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const refreshAllKitchenData = () => {
    setRefreshTrigger((prev) => prev + 1);
  };

  return (
    <KitchenRefreshContext.Provider
      value={{ refreshTrigger, refreshAllKitchenData }}
    >
      {children}
    </KitchenRefreshContext.Provider>
  );
};

export const useKitchenRefresh = () => {
  const context = useContext(KitchenRefreshContext);
  if (!context) {
    throw new Error(
      "useKitchenRefresh must be used within a KitchenRefreshProvider"
    );
  }
  return context;
};
