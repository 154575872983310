import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import ModalEdit from "./components/modal/ModalEdit";
import StockModal from "./components/modal/StockModal";
import ModalAdd from "./components/modal/ModalAdd";
import Optionlist from "./components/Optionlist";
import ConfirmDeleteModal from "./components/modal/ConfirmDeleteModal";
import SuccessDeleteModal from "./components/modal/SuccessDeleteModal";
import { useNavigate } from "react-router-dom";
import {
  productStandardHandler,
  userBusinessGroupHandler,
} from "../../../redux/handlers";
import { useDispatch, useSelector } from "react-redux";
import { FormControl } from "react-bootstrap";
import getCurrentDate from "../../../utils/getCurrentDate";
import { useBreadcrumb } from "../../../hooks/useBreadcrumb";
import Breadcrumb from "../../../components/Breadcrumb";

export function OptionEdit() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { businessSlug, optionID } = useParams();
  const formikRef = useRef(null);
  const [listID, setListID] = useState(null);
  const [formdisabled, setFormdisabled] = useState(true);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [modalsuccessShow, setModalsuccessShow] = useState(false);
  const [editOptionID, setEditOptionID] = useState();
  const [onSuccess, setOnSuccess] = useState(false);
  const [mode, setMode] = useState("main");
  const [itemToDelete, setItemToDelete] = useState(optionID);
  let refresh = false;

  const [optiondata, setOptiondata] = useState({
    optionName: "",
    dateCreate: getCurrentDate("/"),
    userCreate: "",
    optionStatus: 1,
    displayCustomer: false,
    multipleSelection: false,
    optionList: [],
  });

  const [initialData, setInitialData] = useState({
    optionName: "",
    dateCreate: getCurrentDate("/"),
    userCreate: "",
    optionStatus: 1,
    displayCustomer: false,
    multipleSelection: false,
    optionList: [],
  });

  const [stock, setStock] = useState({
    name: "",
    storeHouse: "",
    productID: null,
    qty: null,
    unit: "",
  });

  const [modaledit, setModaledit] = useState({
    open: false,
    x: 0,
    y: 0,
    width: 680,
    height: 410,
    pin: false,
    mode: "normal",
    modalname: "modal_edit_option_standard",
  });

  const [modaladd, setModaladd] = useState({
    open: false,
    x: 0,
    y: 0,
    width: 680,
    height: 410,
    pin: false,
    mode: "normal",
    modalname: "modal_add_option_standard",
  });

  const [modalStock, setModalStock] = useState({
    open: false,
    x: 0,
    y: 0,
    width: 520,
    height: 610,
    pin: false,
    mode: "normal",
    modalname: "modal_stock_option_standard",
  });

  const [productOptionDetail, setProductOptionDetail] = useState({
    id: null,
    name: null,
    price: null,
  });

  const schema = yup.object().shape({});

  const productOption = useSelector((state) => state.productStandard?.getById);
  const productOptionItems = useSelector(
    (state) => state.productStandard?.getListItem
  );

  const breadcrumbConfig = [
    { label: "หน้าหลัก", path: "/" },
    { label: "สินค้าและบริการ", path: "/product/options/standard" },
    { label: "จัดการตัวเลือกมาตราฐาน", path: "/product/options/standard" },
    { label: "แก้ไขตัวเลือกมาตราฐาน" },
  ];
  useBreadcrumb(breadcrumbConfig);

  const fetchUserProfile = async () => {
    try {
      const response = await dispatch(
        userBusinessGroupHandler.getUserBusinessProfile()
      );
      if (response) {
        const { first_name, last_name } = response;
        setOptiondata((prev) => ({
          ...prev,
          userCreate: `${first_name} ${last_name}`,
        }));
        setInitialData((prev) => ({
          ...prev,
          userCreate: `${first_name} ${last_name}`,
        }));
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        product_option_id: optionID,
      };

      try {
        await Promise.all([
          dispatch(productStandardHandler.getProductOptionById(optionID)),
          dispatch(productStandardHandler.getListProductOptionItemByid(params)),
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (optionID) {
      fetchData();
    }
    refresh = false;
  }, [optionID, dispatch, refresh]);

  useEffect(() => {
    if (productOption && Array.isArray(productOptionItems)) {
      const userCreate = productOption.userBusinessGroup
        ? `${productOption.userBusinessGroup.first_name} ${productOption.userBusinessGroup.last_name}`
        : optiondata.userCreate;

      setOptiondata((prev) => ({
        ...prev,
        optionName: productOption.name,
        displayCustomer: productOption.is_display,
        multipleSelection: productOption.is_multiple_select,
        optionList: productOptionItems,
        userCreate: userCreate,
      }));

      setInitialData((prev) => ({
        ...prev,
        optionName: productOption.name,
        displayCustomer: productOption.is_display,
        multipleSelection: productOption.is_multiple_select,
        optionList: productOptionItems,
        userCreate: userCreate,
      }));
    }
  }, [productOption, productOptionItems]);

  const formatDateGMT7 = () => {
    const date = new Date();
    const options = {
      timeZone: "Asia/Bangkok",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const formattedDate = new Intl.DateTimeFormat("en-CA", options).format(
      date
    );
    return formattedDate;
  };

  const checkForUpdates = async (values) => {
    let temp = {};
    if (
      values.optionName != initialData.optionName ||
      values.displayCustomer != initialData.displayCustomer ||
      values.multipleSelection != initialData.multipleSelection
    ) {
      temp = {
        name: values.optionName,
        businessSlug: businessSlug,
        isDisplay: values.displayCustomer,
        isMultipleSelect: values.multipleSelection,
        date: formatDateGMT7(),
      };
      dispatch(productStandardHandler.updateProductOption(optionID, temp));
    }

    navigate(`/${businessSlug}/product/options/standard`);
  };

  const editHandeler = (item, optionID) => {
    setProductOptionDetail(item);
    setEditOptionID(optionID);
    setModaledit((prev) => ({ ...prev, open: true }));
  };

  const handleDelete = () => {
    setModalsuccessShow(true);
    if (mode === "main") {
      dispatch(productStandardHandler.deleteProductOption(itemToDelete));
    } else {
      if (typeof itemToDelete === "number") {
        dispatch(productStandardHandler.deleteProductOptionItem(itemToDelete));
        refresh = true;
      }
    }
  };

  const handleSuccess = () => {
    setModalsuccessShow(false);
    if (mode == "main") {
      navigate(`/${businessSlug}/product/options/standard`);
    }
  };

  const deleteHandeler = (id) => {
    setMode("list");
    setItemToDelete(id);
    setModalDeleteShow(true);
  };

  return (
    <div>
      <div>
        <Breadcrumb />
        <div className="fs-24 tc-7 mb-4 lh-1">จัดการตัวเลือกมาตราฐาน</div>
        <div className="card card-st-1 mb-4">
          <div className="card-body p-4">
            <div className="fs-22 fw-500 tc-7 mb-4 lh-1">
              แก้ไขตัวเลือกมาตราฐาน{" "}
            </div>
            <Formik
              enableReinitialize
              innerRef={formikRef}
              initialValues={optiondata}
              validationSchema={schema}
              onSubmit={async (values) => {
                await checkForUpdates(values);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                touched,
                errors,
                values,
                setFieldValue,
                isInvalid,
                isValid,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <fieldset disabled={formdisabled}>
                    <div className="row gx-xl-3">
                      <div className="col-xl-6 col-lg-6 col">
                        <Form.Group className="mb-2 form-st-3 pb-1">
                          <Form.Label className="mb-1">
                            ชื่อมาตราฐาน <span className="text-danger">*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="optionName"
                            className="form-control"
                            placeholder={values.optionName}
                            value={values.optionName}
                            onChange={handleChange}
                            isInvalid={!!errors.optionName}
                          />
                          <FormControl.Feedback type="invalid">
                            {errors.optionName}
                          </FormControl.Feedback>
                        </Form.Group>
                      </div>
                    </div>

                    {optiondata?.optionList?.map((item, index) => (
                      <Optionlist
                        item={item}
                        key={item.id}
                        n={index}
                        editHandeler={() => {
                          editHandeler(item, values.optionID);
                        }}
                        deleteHandeler={async () => {
                          await deleteHandeler(item.id);
                        }}
                        settingHandeler={() => {
                          setModalStock((prev) => ({ ...prev, open: true }));
                          if (item.stock && item.stock != "") {
                            setStock(item.stock);
                          } else {
                            setStock({
                              name: "",
                              storeHouse: "",
                              productID: null,
                              qty: null,
                              unit: "",
                            });
                          }
                          setListID(item.id);
                        }}
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                    ))}

                    <div className="row gx-xl-3">
                      <div className="col-xl-6 col-lg-6 col">
                        <div className="mb-2">
                          <button
                            type="button"
                            className="btn btn-st-3 mb-4"
                            onClick={() =>
                              setModaladd((prev) => ({ ...prev, open: true }))
                            }
                          >
                            เพิ่ม
                          </button>
                        </div>
                        <div className="mb-2 space-x-6">
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="แสดงหน้าจอลูกค้า"
                            name="displayCustomer"
                            className="custom-switch-st-1 tc-10 s2 mb-3"
                            checked={values.displayCustomer}
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                setFieldValue("displayCustomer", true);
                              } else {
                                setFieldValue("displayCustomer", false);
                              }
                            }}
                          />

                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="เลือกได้มากกว่า 1 รายการ"
                            name="multipleSelection"
                            className="custom-switch-st-1 tc-10 s2 mb-3"
                            checked={values.multipleSelection}
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                setFieldValue("multipleSelection", true);
                              } else {
                                setFieldValue("multipleSelection", false);
                              }
                            }}
                          />
                        </div>

                        <div className="row">
                          <div className="col-6">
                            <Form.Group className="mb-3 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                วันที่สร้าง
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="dateCreate"
                                className="form-control"
                                placeholder="วันที่สร้าง"
                                value={values.dateCreate}
                                onChange={handleChange}
                                isInvalid={!!errors.dateCreate}
                                disabled={true}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-6">
                            <Form.Group className="mb-3 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                ชื่อผู้สร้าง
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="userCreate"
                                className="form-control"
                                placeholder="ชื่อผู้สร้าง"
                                value={values.userCreate}
                                onChange={handleChange}
                                isInvalid={!!errors.userCreate}
                                disabled={true}
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <div className="text-end pb-4 pe-3">
                    {formdisabled === true ? (
                      <Button
                        type="button"
                        className="btn-st-3 mx-3"
                        onClick={(e) => {
                          setFormdisabled(false);
                        }}
                      >
                        แก้ไข
                      </Button>
                    ) : (
                      <div className="d-flex align-items-center pt-3 btn-group-control">
                        <Button
                          type="button"
                          className="btn-st-5 me-auto px-4"
                          style={{ width: "90px" }}
                          onClick={() => setModalDeleteShow(true)}
                        >
                          ลบ
                        </Button>
                        <Button
                          onClick={() =>
                            navigate(
                              `/${businessSlug}/product/options/standard`
                            )
                          }
                          className="btn btn-st-6 me-3"
                        >
                          ยกเลิก
                        </Button>
                        <Button type="submit" className="btn-st-3">
                          บันทึก
                        </Button>
                      </div>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>

      <ConfirmDeleteModal
        show={modalDeleteShow}
        onHide={() => setModalDeleteShow(false)}
        onSuccess={() => handleDelete()}
        optiondata={optiondata}
      />
      <SuccessDeleteModal
        show={modalsuccessShow}
        onHide={() => handleSuccess()}
      />

      <ModalEdit
        modal={modaledit}
        setModal={setModaledit}
        productOptionDetail={productOptionDetail}
        setProductOptionDetail={setProductOptionDetail}
        optionID={editOptionID}
        optiondata={optiondata}
        setOptiondata={setOptiondata}
        onSuccess={() => {
          setOnSuccess(true);
        }}
      />
      <ModalAdd
        modal={modaladd}
        setModal={setModaladd}
        optiondata={optiondata}
        setOptiondata={setOptiondata}
        onSuccess={() => {
          setOnSuccess(true);
        }}
      />
      <StockModal
        modal={modalStock}
        setModal={setModalStock}
        onHide={() => setModalStock((prev) => ({ ...prev, open: false }))}
        stock={stock}
        setStock={setStock}
        listID={listID}
        optiondata={optiondata}
        setOptiondata={setOptiondata}
        deletestock={() => {
          setModalStock((prev) => ({ ...prev, open: false }));
        }}
      />
    </div>
  );
}
