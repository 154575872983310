import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  branchPaymentSettings: null,
  selectedBranchPayment: null,
  paymentForm: {
    delivery: {
      name: "",
      imagePath: "",
      imageSize: 100,
      isStatus: true,
    },
    credit: {
      bankId: "",
      accountName: "",
      accountNumber: "",
      isSystemFee: 1,
      fee: 1,
      feeType: "percentage",
      isCustomFee: true,
      remark: "",
      isStatus: true,
    },
    bank: {
      bankId: "",
      accountNumber: "",
      accountName: "",
      branchName: "",
      isStatus: true,
    },
    payment: {
      id: null,
      branch_id: null,
      is_cash_received: false,
      is_qrcode_received: false,
      is_bank_received: false,
      is_delivery_received: false,
      is_credit_received: false,
      is_debtor_received: false,
      is_discount_received: false,
      is_active: false,
    },
    qrcode: {
      qrcode_merchant_id: "",
      qrcode_public_id: "",
      qrcode_secret_key_id: "",
      qrcode_token: "",
    },
    rounding: {
      rounding_setting_id: 1,
    },
  },
};

const slice = createSlice({
  name: "branchPayments",
  initialState,
  reducers: {
    resetState: () => initialState,

    setBranchPaymentSettings: (state, action) => {
      state.branchPaymentSettings = action.payload;
    },

    setSelectedBranchPayment: (state, action) => {
      state.selectedBranchPayment = action.payload;
    },

    setPaymentFormDelivery: (state, action) => {
      state.paymentForm.delivery = {
        ...state.paymentForm.delivery,
        ...action.payload,
      };
    },

    setPaymentFormCredit: (state, action) => {
      state.paymentForm.credit = {
        ...state.paymentForm.credit,
        ...action.payload,
      };
    },

    setPaymentFormBank: (state, action) => {
      state.paymentForm.bank = {
        ...state.paymentForm.bank,
        ...action.payload,
      };
    },

    setPaymentFormSettings: (state, action) => {
      state.paymentForm.payment = {
        ...state.paymentForm.payment,
        ...action.payload,
      };
    },

    setPaymentFormQRCode: (state, action) => {
      state.paymentForm.qrcode = {
        ...state.paymentForm.qrcode,
        ...action.payload,
      };
    },

    setPaymentFormRounding: (state, action) => {
      state.paymentForm.rounding = {
        ...state.paymentForm.rounding,
        ...action.payload,
      };
    },

    resetPaymentForm: (state) => {
      state.paymentForm = initialState.paymentForm;
    },

    resetDeliveryForm: (state) => {
      state.paymentForm.delivery = initialState.paymentForm.delivery;
    },

    resetCreditForm: (state) => {
      state.paymentForm.credit = initialState.paymentForm.credit;
    },

    resetBankForm: (state) => {
      state.paymentForm.bank = initialState.paymentForm.bank;
    },

    resetPaymentSettingsForm: (state) => {
      state.paymentForm.payment = initialState.paymentForm.payment;
    },

    resetQRCodeForm: (state) => {
      state.paymentForm.qrcode = initialState.paymentForm.qrcode;
    },

    resetRoundingForm: (state) => {
      state.paymentForm.rounding = initialState.paymentForm.rounding;
    },
  },
});

export const {
  resetState,
  setBranchPaymentSettings,
  setSelectedBranchPayment,
  setPaymentFormDelivery,
  setPaymentFormCredit,
  setPaymentFormBank,
  setPaymentFormSettings,
  setPaymentFormQRCode,
  setPaymentFormRounding,
  resetPaymentForm,
  resetDeliveryForm,
  resetCreditForm,
  resetBankForm,
  resetPaymentSettingsForm,
  resetQRCodeForm,
  resetRoundingForm,
} = slice.actions;

export default slice.reducer;
