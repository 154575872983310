import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import POSDeviceCard from "../fragments/POSDeviceCard";
import POSDeviceFormModal from "../modals/POSDeviceFormModal";
import ConfirmDeleteDeviceModal from "../modals/ConfirmDeleteDeviceModal";
import SuccessModal from "../modals/SuccessModal";
import dayjs from "dayjs";
import { branchHandler } from "../../../../../redux/handlers";

const POSManagementForm = ({ businessSlug, isReadOnly = false }) => {
  const dispatch = useDispatch();
  const { branchID } = useParams();
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(getInitialDeviceState());
  const [formDisabled, setFormDisabled] = useState(true);
  const [formModal, setFormModal] = useState({
    open: false,
    x: 0,
    y: 0,
    width: 460,
    height: 820,
    pin: false,
    mode: "normal",
  });
  const [confirmDeleteModal, setConfirmDeleteModal] = useState({
    open: false,
    x: 0,
    y: 0,
    width: 460,
    height: 320,
    pin: false,
    mode: "normal",
  });
  const [successModal, setSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  function getInitialDeviceState() {
    return {
      date: dayjs().format("YYYY-MM-DD"),
      order: 1,
      posId: "",
      name: "",
      posStatus: "ยังไม่มีการซื้อขาย",
      taxInvoice: 0,
      isTaxInvoiceABB: 0,
      taxNumber: "",
      approvedDate: "",
      isStatus: 1,
    };
  }

  const handleDeviceSubmit = async (id, deviceData) => {
    if (isReadOnly) return;

    try {
      const response = id
        ? await dispatch(branchHandler.updateBranchPOS(id, deviceData))
        : await dispatch(branchHandler.createBranchPOS(deviceData));

      if (response) {
        const updatedDevices = await dispatch(
          branchHandler.getAllBranchPOS(branchID)
        );
        if (updatedDevices) {
          setDevices(mapDeviceData(updatedDevices));
        }
      }
    } catch (error) {
      console.error("Error managing POS device:", error);
    }
  };

  const showDeleteConfirmation = (id) => {
    setConfirmDeleteModal((prev) => ({ ...prev, open: true }));
  };

  const handleDeleteDevice = async () => {
    try {
      const response = await dispatch(
        branchHandler.deleteBranchPOS(selectedDevice.id)
      );
      if (response) {
        const updatedDevices = await dispatch(
          branchHandler.getAllBranchPOS(branchID)
        );
        if (updatedDevices) {
          setDevices(mapDeviceData(updatedDevices));
        }
        setConfirmDeleteModal((prev) => ({ ...prev, open: false }));
        setSuccessMessage("ลบข้อมูล POS สำเร็จ");
        setSuccessModal(true);

        // ปิด success modal หลังจาก 1.5 วินาที
        setTimeout(() => {
          setSuccessModal(false);
        }, 1500);
      }
    } catch (error) {
      console.error("Error deleting POS device:", error);
    }
  };

  const handleAddDevice = () => {
    if (isReadOnly || formDisabled) return;

    setSelectedDevice({
      ...getInitialDeviceState(),
      order: (devices?.length || 0) + 1,
    });
    setFormModal((prev) => ({ ...prev, open: true }));
  };

  const handleEditDevice = (device) => {
    if (formDisabled && !isReadOnly) return;

    setSelectedDevice(device);
    setFormModal((prev) => ({ ...prev, open: true }));
  };

  const mapDeviceData = (data) => {
    return data.map((item) => ({
      id: item.id,
      date: item.date,
      order: item.order,
      posId: item.pos_id,
      name: item.name,
      posStatus: "ยังไม่มีการซื้อขาย",
      isTaxInvoiceABB: item.is_tax_invoice_abb ? 1 : 0,
      taxNumber: item.tax_number || "",
      approvedDate: item.approved_date || "",
      isStatus: item.is_status ? 1 : 0,
      is_active: item.is_active,
    }));
  };

  useEffect(() => {
    const fetchDevices = async () => {
      if (branchID) {
        try {
          const response = await dispatch(
            branchHandler.getAllBranchPOS(branchID)
          );
          if (response) {
            setDevices(mapDeviceData(response));
          }
        } catch (error) {
          console.error("Error fetching POS devices:", error);
        }
      }
    };

    fetchDevices();
  }, [branchID, dispatch]);

  const handleFormCancel = () => {
    setFormDisabled(true);
  };

  const handleFormSave = async () => {
    try {
      setFormDisabled(true);
    } catch (error) {
      console.error("Error saving form:", error);
    }
  };

  return (
    <div className="card card-st-1 mb-4">
      <div className="card-body">
        <div className="tc-8 fs-18 fw-bold px-3">POS อุปกรณ์</div>
        <fieldset disabled={formDisabled && !isReadOnly}>
          <div className="px-4 pb-5 mt-4">
            {devices?.map((device, index) => (
              <POSDeviceCard
                key={device.id || index}
                device={{
                  ...device,
                  posName: device.name,
                  dateCreate: dayjs(device.date).format("DD/MM/YYYY"),
                }}
                onEdit={() => handleEditDevice(device)}
                isReadOnly={isReadOnly || formDisabled}
              />
            ))}
            {!isReadOnly && !formDisabled && (
              <button
                type="button"
                className="btn btn-add-pos"
                onClick={handleAddDevice}
              >
                เพิ่ม POS
              </button>
            )}
          </div>
        </fieldset>

        {!isReadOnly && (
          <div className="text-end pb-4 pe-3">
            {formDisabled ? (
              <Button
                className="btn-st-3 mx-3"
                onClick={() => setFormDisabled(false)}
              >
                แก้ไข
              </Button>
            ) : (
              <>
                <Button
                  className="btn btn-st-6 me-3"
                  onClick={handleFormCancel}
                >
                  อัพเดต
                </Button>
              </>
            )}
          </div>
        )}
      </div>

      <POSDeviceFormModal
        modal={formModal}
        setModal={setFormModal}
        branchID={branchID}
        deviceData={selectedDevice}
        onSubmit={handleDeviceSubmit}
        onDelete={showDeleteConfirmation}
        isReadOnly={isReadOnly || formDisabled}
      />

      <ConfirmDeleteDeviceModal
        modal={confirmDeleteModal}
        setModal={setConfirmDeleteModal}
        deviceData={selectedDevice}
        onConfirm={handleDeleteDevice}
        onHide={() =>
          setConfirmDeleteModal((prev) => ({ ...prev, open: false }))
        }
      />

      <SuccessModal
        show={successModal}
        onHide={() => setSuccessModal(false)}
        textsuccess={successMessage}
      />
    </div>
  );
};

export default POSManagementForm;
