import Button from "react-bootstrap/Button";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

export default function ButtonShowOption({ handleClick, label }) {
  return (
    <div className="flex items-center">
      <Button
        type="button"
        className="btn  btn-st-3 !w-[27px] !h-[27px] !border-[#2CC66D] !bg-[#2CC66D] !px-[2px] !py-0 !rounded-full"
        onClick={() => {
          handleClick();
        }}
      >
        <RemoveRedEyeOutlinedIcon className="!text-[#FFF] !text-[18px] !mt-[-4px]" />
      </Button>

      {label ? <div className="text-[#636578] ml-3">แสดงตัวเลือก</div> : ""}
    </div>
  );
}
