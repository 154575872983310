import React from "react";
import { Link, useParams } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { ProductForm } from "./components/ProductSetForm";
import { ProductHistory } from "./components/ProductHistory";
import { ProductRecipes } from "./components/ProductRecipes";
import { ProductSetting } from "./components/ProductSetting";

const Breadcrumb = ({ productID, businessSlug }) => (
  <nav aria-label="breadcrumb" className="mt-1">
    <ol className="breadcrumb fs-16 ps-0">
      <li className="breadcrumb-item">
        <Link
          to={`/${businessSlug}/products`}
          className="tc-7 text-decoration-none"
        >
          สินค้า/บริการ
        </Link>
      </li>
      <li className="breadcrumb-item">
        <Link
          to={`/${businessSlug}/products/list`}
          className="tc-7 text-decoration-none"
        >
          รายการสินค้าและบริการ
        </Link>
      </li>
      <li className="breadcrumb-item active tc-1" aria-current="page">
        {productID ? "แก้ไขสินค้า" : "สร้างสินค้า"}
      </li>
    </ol>
  </nav>
);

const ProductTabs = ({ productID }) => {
  const tabs = [
    { key: "product", title: "ข้อมูลสินค้า", component: ProductForm },
    ...(productID
      ? [
          { key: "foodrecipes", title: "สูตรอาหาร", component: ProductRecipes },
          {
            key: "setting",
            title: "ตั้งค่าขั้นสูง",
            component: ProductSetting,
          },
          { key: "history", title: "ประวัติ", component: ProductHistory },
        ]
      : []),
  ];

  return (
    <>
      <Nav variant="pills" className="mb-4 pills-st-1">
        {tabs.map(({ key, title }) => (
          <Nav.Item key={key}>
            <Nav.Link eventKey={key}>{title}</Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      <Tab.Content>
        {tabs.map(({ key, component: Component }) => (
          <Tab.Pane key={key} eventKey={key}>
            <Component />
          </Tab.Pane>
        ))}
      </Tab.Content>
    </>
  );
};

export function ProductSetForm() {
  const { productID, businessSlug } = useParams();
  const pageTitle = productID ? "แก้ไขสินค้าจัดชุด" : "สร้างสินค้าจัดชุด";

  return (
    <div>
      <Breadcrumb productID={productID} businessSlug={businessSlug} />
      <div className="fs-24 tc-7 mb-4 lh-1 pt-2">{pageTitle}</div>
      <Tab.Container id="product-tabs" defaultActiveKey="product">
        <ProductTabs productID={productID} />
      </Tab.Container>
    </div>
  );
}
