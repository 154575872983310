import React from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { AutoTabProvider } from "react-auto-tab";

export const GeneralInfo = ({
  values,
  handleChange,
  setFieldValue,
  errors,
  startDate,
  setStartDate,
  empCardNumber,
  formdisabled,
  handleinputTab,
  prefixType,
  optionsGender,
  optionsMarital,
  optionsMilitary,
}) => {
  const _calculateAge = (dateOfBirth) => {
    if (dateOfBirth == null) return 0;
    if (!dateOfBirth) return 0;
    const birthDate = new Date(dateOfBirth);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      return age - 1;
    }
    return age;
  };
  return (
    <div className="pt-3 px-3">
      <div className="row gx-xl-4">
        {/* Employee Code */}
        <div className="col-xl-3 col-md-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">
              รหัสพนักงาน <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="code"
              className="form-control"
              placeholder="รหัสพนักงาน"
              value={values.code ?? ""}
              onChange={handleChange}
              isInvalid={!!errors.code}
            />
            <Form.Control.Feedback type="invalid">
              {errors.code}
            </Form.Control.Feedback>
          </Form.Group>
        </div>

        {/* Prefix */}
        <div className="col-xl-3 col-md-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">
              คำนำหน้า <span className="text-danger">*</span>
            </Form.Label>
            <Select
              name="prefixNameId"
              onChange={(e) => setFieldValue("prefixNameId", e.value)}
              className={`w-100 select-st-1 ${
                errors.prefixNameId ? "is-invalid" : ""
              }`}
              options={prefixType}
              placeholder="เลือกคำนำหน้า"
              value={prefixType?.filter(
                (item) => item.value === values.prefixNameId
              )}
              isInvalid={!!errors.prefixNameId}
            />
            {errors.prefixNameId && (
              <div className="invalid-feedback d-block">
                {errors.prefixNameId}
              </div>
            )}
          </Form.Group>
        </div>

        {/* Thai Name */}
        <div className="col-xl-3 col-md-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">
              ชื่อ (TH) <span className="text-danger ms-1">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              className="form-control"
              placeholder="กรอกชื่อภาษาไทย"
              value={values.firstName}
              onChange={handleChange}
              isInvalid={!!errors.firstName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firstName}
            </Form.Control.Feedback>
          </Form.Group>
        </div>

        {/* Thai Surname */}
        <div className="col-xl-3 col-md-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">
              นามสกุล (TH) <span className="text-danger ms-1">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              className="form-control"
              placeholder="กรอกนามสกุลภาษาไทย"
              value={values.lastName}
              onChange={handleChange}
              isInvalid={!!errors.lastName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.lastName}
            </Form.Control.Feedback>
          </Form.Group>
        </div>

        {/* English Name */}
        <div className="col-xl-3 col-md-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">ชื่อ (ENG)</Form.Label>
            <Form.Control
              type="text"
              name="empFirstNameEn"
              className="form-control"
              placeholder="กรอกชื่อภาษาอังกฤษ"
              value={values.empFirstNameEn}
              onChange={handleChange}
              isInvalid={!!errors.empFirstNameEn}
            />
            {errors.empFirstNameEn && (
              <Form.Text className="text-danger">
                {errors.empFirstNameEn.message}
              </Form.Text>
            )}
          </Form.Group>
        </div>

        {/* English Surname */}
        <div className="col-xl-3 col-md-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">นามสกุล (ENG)</Form.Label>
            <Form.Control
              type="text"
              name="empLastNameEn"
              className="form-control"
              placeholder="กรอกนามสกุลภาษาอังกฤษ"
              value={values.empLastNameEn}
              onChange={handleChange}
              isInvalid={!!errors.empLastNameEn}
            />
            {errors.empLastNameEn && (
              <Form.Text className="text-danger">
                {errors.empLastNameEn.message}
              </Form.Text>
            )}
          </Form.Group>
        </div>

        {/* ID Card Number */}
        <div className="col-xl-6 col-md-12">
          <Form.Label className="mb-1 tc-10 fs-12">
            หมายเลขบัตรประชาชน (13 หลัก)
          </Form.Label>
          <div className="my-0 form-st-3 identitycard">
            <AutoTabProvider>
              <span>
                <input
                  type="text"
                  settings={{ placement: 1 }}
                  maxLength={1}
                  tabbable="true"
                  value={values.empCardNumber?.[0] ?? ""}
                  className="form-tab first"
                  onChange={(e) => handleinputTab(e, 0)}
                />
              </span>
              <span>
                {[1, 2, 3, 4].map((index) => (
                  <input
                    key={index}
                    type="text"
                    settings={{ placement: index + 1 }}
                    maxLength={1}
                    tabbable="true"
                    value={values.empCardNumber?.[index] ?? ""}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, index)}
                  />
                ))}
              </span>
              <span>
                {[5, 6, 7, 8, 9].map((index) => (
                  <input
                    key={index}
                    type="text"
                    settings={{ placement: index + 1 }}
                    maxLength={1}
                    tabbable="true"
                    value={values.empCardNumber?.[index] ?? ""}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, index)}
                  />
                ))}
              </span>
              <span>
                {[10, 11, 12].map((index) => (
                  <input
                    key={index}
                    type="text"
                    settings={{ placement: index + 1 }}
                    maxLength={1}
                    tabbable="true"
                    value={values.empCardNumber?.[index] ?? ""}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, index)}
                  />
                ))}
              </span>
            </AutoTabProvider>
          </div>
        </div>

        {/* Nickname */}
        <div className="col-xl-3 col-md-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">ชื่อเล่น</Form.Label>
            <Form.Control
              type="text"
              name="empNickname"
              className="form-control"
              placeholder="กรอกชื่อเล่น"
              value={values.empNickname}
              onChange={handleChange}
              isInvalid={!!errors.empNickname}
            />
            {errors.empNickname && (
              <Form.Text className="text-danger">
                {errors.empNickname.message}
              </Form.Text>
            )}
          </Form.Group>
        </div>

        {/* Gender */}
        <div className="col-xl-3 col-md-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">เพศ</Form.Label>
            <Select
              name="empGenderId"
              onChange={(e) => setFieldValue("empGenderId", e.value)}
              className="w-100 select-st-1"
              options={optionsGender}
              placeholder="เพศ"
              value={optionsGender?.filter(
                (item) => item.value === values.empGenderId
              )}
            />
            {errors.empGenderId && (
              <Form.Text className="text-danger">
                {errors.empGenderId.message}
              </Form.Text>
            )}
          </Form.Group>
        </div>

        {/* Birthday */}
        <div className="col-xl-3 col-md-6">
          <Form.Group className=" mt-[12px]">
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              className="h-[40px] "
            >
              <DatePicker
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    InputLabelProps: { shrink: true },
                    error: !!errors.empBirthDate,
                  },
                }}
                label="วันเกิด"
                className={`h-[40px] w-full `}
                sx={{
                  ".MuiInputBase-formControl": {
                    height: "53px",
                    borderRadius: "8px",
                    backgroundColor: formdisabled ? "#F8F8F9" : "",
                  },
                  ".MuiFormLabel-root": {
                    fontFamily: "Sarabun",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "1px solid rgba(76, 78, 100, 0.22)",
                  },
                  "& .MuiInputBase-input": {
                    backgroundColor: formdisabled ? "#F8F8F9" : "",
                  },
                }}
                name="empBirthDate"
                value={values.empBirthDate ? dayjs(values.empBirthDate) : null}
                onChange={(date) => {
                  setFieldValue("empBirthDate", date);
                }}
              />
            </LocalizationProvider>
          </Form.Group>
        </div>

        {/* Age */}
        <div className="col-xl-3 col-md-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">อายุ</Form.Label>
            <Form.Control
              type="text"
              name="age"
              className="form-control"
              placeholder="กรอกอายุ"
              value={_calculateAge(values.empBirthDate)}
              onChange={handleChange}
              disabled={true}
              isInvalid={!!errors.age}
            />
          </Form.Group>
        </div>

        {/* Ethnicity */}
        <div className="col-xl-3 col-md-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">เชื้อชาติ</Form.Label>
            <Form.Control
              type="text"
              name="empRace"
              className="form-control"
              placeholder="กรอกเชื้อชาติ"
              value={values.empRace}
              onChange={handleChange}
              isInvalid={!!errors.empRace}
            />
            {errors.empRace && (
              <Form.Text className="text-danger">
                {errors.empRace.message}
              </Form.Text>
            )}
          </Form.Group>
        </div>

        {/* Nationality */}
        <div className="col-xl-3 col-md-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">สัญชาติ</Form.Label>
            <Form.Control
              type="text"
              name="empNationality"
              className="form-control"
              placeholder="กรอกสัญชาติ"
              value={values.empNationality}
              onChange={handleChange}
              isInvalid={!!errors.empNationality}
            />
            {errors.empNationality && (
              <Form.Text className="text-danger">
                {errors.empNationality.message}
              </Form.Text>
            )}
          </Form.Group>
        </div>

        {/* Marital Status */}
        <div className="col-xl-3 col-md-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">สถานะสมรส</Form.Label>
            <Select
              name="empMaritalId"
              onChange={(e) => setFieldValue("empMaritalId", e.value)}
              className="w-100 select-st-1"
              options={optionsMarital}
              placeholder="ระบุสถานะสมรส"
              value={optionsMarital?.filter(
                (item) => item.value === values.empMaritalId
              )}
            />
            {errors.empMaritalId && (
              <Form.Text className="text-danger">
                {errors.empMaritalId.message}
              </Form.Text>
            )}
          </Form.Group>
        </div>

        {/* Military Status */}
        <div className="col-xl-3 col-md-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">สถานะทางทหาร</Form.Label>
            <Select
              name="empMilitaryId"
              onChange={(e) => setFieldValue("empMilitaryId", e.value)}
              className="w-100 select-st-1"
              options={optionsMilitary}
              placeholder="ระบุสถานะทางทหาร"
              value={optionsMilitary?.filter(
                (item) => item.value === values.empMilitaryId
              )}
            />
            {errors.empMilitaryId && (
              <Form.Text className="text-danger">
                {errors.empMilitaryId.message}
              </Form.Text>
            )}
          </Form.Group>
        </div>
      </div>
    </div>
  );
};

export default GeneralInfo;
