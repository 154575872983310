import React from "react";
import { ModalAllder } from "layouts/ModalAllder";
import Button from "react-bootstrap/Button";

export function ConfirmDeleteDeviceModal({
  modal,
  setModal,
  deviceData,
  onConfirm,
  ...props
}) {
  return (
    <ModalAllder
      modal={modal}
      setModal={setModal}
      htitle="ลบอุปกรณ์ POS"
      hbtn={true}
    >
      <div className="py-3">
        <div className="text-center">
          <div>
            <div className="text-center fs-22 tc-7 mb-2">คุณต้องการลบ</div>
            <div className="text-center fs-22 tc-7">
              อุปกรณ์ POS "{deviceData?.name}" ใช่หรือไม่
            </div>
          </div>
          <div className="text-center pt-2 pb-3 btn-group-control mt-6">
            <Button
              type="button"
              className="btn-st-6 me-3"
              style={{ width: "100px" }}
              onClick={props.onHide}
            >
              ยกเลิก
            </Button>
            <Button
              type="button"
              className="btn-st-5"
              style={{ width: "100px" }}
              onClick={onConfirm}
            >
              ลบ
            </Button>
          </div>
        </div>
      </div>
    </ModalAllder>
  );
}

export default ConfirmDeleteDeviceModal;
