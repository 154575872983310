import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";

import ProductSelectModal from "layouts/ModalProductSelect";
import BranchProductList from "layouts/BranchProductList";
import SelectCategory from "fragments/SelectCategory";
export default function SalePackageList({
  packageModalObj,
  formdataObj,
  salepackage,
  modal,
  setModal,
  ...props
}) {
  const [modalproductlistShow, setModalproductlistShow] = useState({
    open: false,
    x: 0,
    y: 0,
    width: 1270,
    height: 680,
    pin: false,
    mode: "normal",
    modalname: "create",
  });

  const [packagedata, setPackagedata] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [packageselect, setPackageselect] = useState(null);

  const getpackageData = (packageID) => {
    fetch("/json/product.json")
      .then((response) => response.json())
      .then((result) => {
        setPackagedata(result);
      })
      .catch((error) => console.log("error", error));
  };

  const filteredItems = packagedata.filter(
    (item) =>
      (item.productCode &&
        item.productCode.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.productName &&
        item.productName.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.productCategory &&
        item.productCategory.toLowerCase().includes(filterText.toLowerCase()))
  );

  useEffect(() => {
    setPackageselect(salepackage[0]);
    getpackageData();
  }, []);

  const AddProduct = (product) => {
    setModalproductlistShow((prev) => ({ ...prev, open: false }));
  };

  const deleteProduct = (item) => {};

  return (
    <div className="">
      <div className="d-flex align-items-end px-4 mb-4">
        {salepackage &&
          salepackage.map((item, index) => (
            <button
              className={`btn btn-tab-st-1 me-3 fw-500 tc-7 ${
                packageselect && packageselect.packageID == item.packageID
                  ? "active"
                  : ""
              }`}
              key={index}
              onClick={() => setPackageselect(item)}
            >
              {item.packageName}
            </button>
          ))}
      </div>

      <div>
        <div className="px-4 mb-3">
          <Button
            type="button"
            className="btn btn btn-st-7 me-3  "
            onClick={() => {
              formdataObj.setFormdata(packageselect);
              setModal((prev) => ({ ...prev, open: true }));
            }}
          >
            ตั้งค่าข้อมูลแพ็คเกจ
          </Button>

          <Button
            type="button"
            className="btn btn btn-st-7 "
            style={{ background: "#FF731D" }}
            onClick={() =>
              setModalproductlistShow((prev) => ({ ...prev, open: true }))
            }
          >
            เพิ่มรายการสินค้าที่อยู่ใน บุฟเฟ่ต์
          </Button>
        </div>
        <div className="d-flex align-items-center px-4 mb-4">
          <div className="me-auto" style={{ flex: "0 0 200px" }}>
            <SelectCategory setFilterText={setFilterText} />
          </div>
          <div className="">
            <input
              type="text"
              className="form-control form-st-4"
              placeholder="ค้นหา ชื่อ, ประเภท,หมวดหมู่"
              onKeyUp={(e) => setFilterText(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="">
        <BranchProductList
          filteredItems={filteredItems}
          handleDelete={(product) => deleteProduct(product)}
        />
      </div>

      <ProductSelectModal
        show={modalproductlistShow}
        onHide={() =>
          setModalproductlistShow((prev) => ({ ...prev, open: false }))
        }
        handleSubmit={(product) => AddProduct(product)}
        modal={modalproductlistShow}
        setModal={setModalproductlistShow}
      />
    </div>
  );
}
