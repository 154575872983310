import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import { ModalAllder } from "layouts/ModalAllder";
import { nanoid } from "nanoid";
import { useDispatch } from "react-redux";
import { productAnnotationHandler } from "../../../../../redux/handlers";

export default function AnnotationItemModal({
  modal,
  optionID,
  setModal,
  optionData,
  setModalDeleteShow,
  onSuccess,
  ...props
}) {
  const dispatch = useDispatch();
  const isEditMode = modal.mode == "edit";
  const schemaFormModal = yup.object().shape({
    name: yup.string().required("กรุณากรอกชื่อรายการ"),
  });
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      console.log("isEditMode : ", isEditMode);

      if (isEditMode) {
        const updatedItem = {
          name: values.name,
          productAnnotationId: values.productAnnotationId,
        };

        await dispatch(
          productAnnotationHandler.updateProductAnnotationItem(
            values.id,
            updatedItem
          )
        );
      } else {
        const newItem = {
          name: values.name,
          productAnnotationId: optionID,
        };
        const response = await dispatch(
          productAnnotationHandler.createProductAnnotationItem(newItem)
        );
      }
      resetForm();
      setModal((prev) => ({ ...prev, open: false }));
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      console.error("Error submitting annotation item:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = {
    id: modal.editData?.id ?? null,
    name: modal.editData?.name ?? "",
    productAnnotationId: modal.editData?.product_annotation_id ?? "",
  };

  return (
    <ModalAllder
      modal={modal}
      setModal={setModal}
      htitle={
        isEditMode
          ? "แก้ไขรายการตัวเลือกหมายเหตุ"
          : "เพิ่มรายการตัวเลือกหมายเหตุ"
      }
      hbtn={true}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schemaFormModal}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          touched,
          errors,
          values,
          isSubmitting,
        }) => (
          <Form noValidate onSubmit={handleSubmit} className="p-lg-4">
            <fieldset disabled={isSubmitting}>
              <div className="row">
                <div className="col-12">
                  <Form.Group className="mb-4 form-st-3 pb-1">
                    <Form.Label className="mb-1">
                      ชื่อรายการ <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="กรอกชื่อรายการ"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!(errors.name && touched.name)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
            </fieldset>
            <div className="text-end pb-4 pt-4 pe-3">
              <div className="d-flex justify-end align-items-center pt-3 btn-group-control">
                <Button
                  className="btn btn-st-6 me-3 bg-transparent"
                  onClick={() => setModal((prev) => ({ ...prev, open: false }))}
                  disabled={isSubmitting}
                >
                  ยกเลิก
                </Button>
                <Button
                  type="submit"
                  className="btn-st-3"
                  disabled={isSubmitting}
                >
                  บันทึก
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ModalAllder>
  );
}
