import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import { ModalAllder } from "layouts/ModalAllder";
import { AllderTable } from "components/AllderTable";
import { createTableColumns } from "utils/createTableColumns";
import { useGlobalTable } from "hooks/useGlobalTable";
import { productCategoryHandler } from "../../../../../redux/handlers";

export default function CategoryIncludeModal({
  handleSubmit,
  modal = {},
  setModal = () => {},
  businessSlug,
  triggerRefresh,
  branchId,
  branchKitchenId,
  ...props
}) {
  const dispatch = useDispatch();
  const [categorySelected, setCategorySelected] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [optionsMainCategory, setOptionsMainCategory] = useState([]);
  const [selectedMainCategory, setSelectedMainCategory] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const safeBusinessSlug = businessSlug || "";

  const fetchSubCategories = useCallback(
    (params) =>
      dispatch(
        productCategoryHandler.getProductSubCategories({
          business_slug: safeBusinessSlug,
          product_category_id:
            selectedMainCategory?.value === "all"
              ? ""
              : selectedMainCategory?.value,
          pageQuery: "branchKitchenInclude",
          branch_id: branchId,
          branch_kitchen_id: branchKitchenId,
          ...params,
        })
      ),
    [safeBusinessSlug, selectedMainCategory, dispatch]
  );

  const {
    data: subCategories,
    loading,
    totalRows,
    paginationModel,
    setPaginationModel,
    handleSort,
    debouncedSearch,
    filterParams,
    setFilterParams,
    refreshData,
  } = useGlobalTable({
    fetchData: fetchSubCategories,
    defaultSort: "created_at",
    defaultOrder: "desc",
    defaultPageSize: 20,
  });

  const fetchMainCategories = async () => {
    if (!safeBusinessSlug) return;

    try {
      const response = await dispatch(
        productCategoryHandler.getAllProductCategories(safeBusinessSlug)
      );

      if (response) {
        const options = [
          { value: "all", label: "ทั้งหมด" },
          ...response.map((category) => ({
            value: category.id,
            label: category.name,
          })),
        ];
        setOptionsMainCategory(options);
      }
    } catch (error) {
      console.error("Error fetching main categories:", error);
    }
  };

  useEffect(() => {
    fetchMainCategories();
  }, [safeBusinessSlug]);

  useEffect(() => {
    if (modal && typeof modal.open === "boolean") {
      if (modal.open) {
        setCategorySelected([]);
        setSelectionModel([]);
      }
    }
  }, [modal?.open]);

  const handleMainCategoryChange = (selectedOption) => {
    setSelectedMainCategory(selectedOption);

    setFilterParams((prev) => ({
      ...prev,
      product_category_id:
        selectedOption?.value === "all" ? "" : selectedOption?.value,
    }));
  };

  const columns = createTableColumns([
    {
      field: "name",
      headerName: "หมวดหมู่ย่อย",
      flex: 1,
      sortable: true,
    },
    {
      field: "productCategory",
      headerName: "หมวดหมู่หลัก",
      width: 180,
      sortable: false,
      renderCell: ({ row }) => <div>{row.productCategory?.name || ""}</div>,
    },
    {
      field: "product_count",
      headerName: "จำนวนรายการสินค้า",
      width: 180,
      sortable: true,
      renderCell: ({ row }) => (
        <div>{row.branchProductCount ? row.branchProductCount.length : 0}</div>
      ),
    },
  ]);

  const dataWithIndex =
    subCategories?.map((item, index) => ({
      ...item,
      index: index + 1,
    })) || [];

  const handleFormSubmit = async () => {
    if (isSubmitting || !categorySelected || categorySelected.length === 0) {
      return;
    }

    setIsSubmitting(true);

    try {
      if (typeof handleSubmit === "function") {
        await handleSubmit(categorySelected);
      }

      setCategorySelected([]);
      setSelectionModel([]);

      if (typeof triggerRefresh === "function") {
        try {
          setTimeout(() => {
            triggerRefresh();
            console.log("Data refresh triggered from modal");
          }, 500);
        } catch (err) {
          console.error("Error triggering refresh:", err);
        }
      }
    } catch (error) {
      console.error("Error submitting categories:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleHide = () => {
    setCategorySelected([]);
    setSelectionModel([]);
    if (props.onHide) {
      props.onHide();
    }
  };

  return (
    <ModalAllder
      modal={modal}
      setModal={setModal}
      htitle="ค้นหาหมวดหมู่"
      hbtn={true}
    >
      <div className="d-flex align-items-start mb-4">
        <div
          className="fs-18 fw-6 mb-3 d-inline-block me-auto text-white"
          style={{
            borderRadius: "8px",
            background: "#2DC76D",
            padding: "6.5px 25px",
          }}
        >
          หมวดหมู่ทั้งหมด
        </div>
        <div className="d-flex align-items-center pt-2 pb-2 btn-group-control">
          <Button
            type="button"
            className="btn-st-5 me-auto"
            style={{ width: "100px" }}
            onClick={handleHide}
            disabled={isSubmitting}
          >
            ยกเลิก
          </Button>
          <div className="bx-count mx-3">
            เลือกไว้ {categorySelected ? categorySelected.length : 0} รายการ
          </div>
          <Button
            type="button"
            className="btn-st-3"
            style={{ width: "130px" }}
            disabled={
              !categorySelected || categorySelected.length === 0 || isSubmitting
            }
            onClick={handleFormSubmit}
          >
            {isSubmitting ? "กำลังเพิ่ม..." : "เพิ่มรายการ"}
          </Button>
        </div>
      </div>

      <div className="d-flex align-items-center mb-3">
        <div className="me-3" style={{ flex: "0 0 220px" }}>
          <Select
            value={selectedMainCategory}
            onChange={handleMainCategoryChange}
            className="w-100 select-st-1 pd"
            options={optionsMainCategory}
            placeholder="เลือกหมวดหมู่หลัก"
          />
        </div>
        <div style={{ flex: "0 0 180px" }}>
          <input
            type="text"
            className="form-control form-st-4"
            placeholder="ค้นหาชื่อหมวดหมู่"
            onChange={(e) => debouncedSearch(e.target.value)}
          />
        </div>
      </div>

      <AllderTable
        data={dataWithIndex}
        loading={loading}
        columns={columns}
        totalRows={totalRows}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        handleSort={handleSort}
        checkboxSelection
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={(ids) => {
          setSelectionModel(ids);
          const selectedIDs = new Set(ids);
          const selectedRows = dataWithIndex.filter((row) =>
            selectedIDs.has(row.id)
          );
          setCategorySelected(selectedRows);
        }}
      />
    </ModalAllder>
  );
}
