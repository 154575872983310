import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import { ModalAllder } from "layouts/ModalAllder";
import { AllderTable } from "components/AllderTable";
import { createTableColumns } from "utils/createTableColumns";
import { useGlobalTable } from "hooks/useGlobalTable";
import { productHandler, productCategoryHandler } from "../../../../../redux/handlers";

export default function ProductIncludeModal({ handleSubmit, modal, setModal, businessSlug, branchId, productKitchenId, ...props }) {
  const dispatch = useDispatch();
  const [productSelected, setProductSelected] = useState([]);
  const [optionsCategory, setOptionsCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const fetchProducts = useCallback(
    (params) =>
      dispatch(
        productHandler.getProductList({
          businessSlug,
          productStatus: "active",
          pageQuery: "branchProductInclude",
          branch_id: branchId,
          product_kitchen_id: productKitchenId,
          ...params,
        })
      ),
    [businessSlug]
  );

  const {
    data: products,
    loading,
    totalRows,
    paginationModel,
    setPaginationModel,
    handleSort,
    debouncedSearch,
    filterParams,
    setFilterParams,
  } = useGlobalTable({
    fetchData: fetchProducts,
    defaultSort: "created_at",
    defaultOrder: "desc",
    defaultPageSize: 10,
  });

  const fetchCategories = async () => {
    try {
      const response = await dispatch(productCategoryHandler.getAllProductCategories(businessSlug));
      if (response) {
        const options = [
          { value: "all", label: "ทั้งหมด" },
          ...response.map((category) => ({
            value: category.id,
            label: category.name,
          })),
        ];
        setOptionsCategory(options);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
    setFilterParams((prev) => ({
      ...prev,
      productCategoryId: selectedOption?.value === "all" ? "" : selectedOption?.value,
    }));
  };

  const columns = createTableColumns([
    {
      field: "sku",
      headerName: "รหัสสินค้า",
      width: 160,
      sortable: true,
      renderCell: ({ row }) => <div style={{ color: "#2DC76D", fontWeight: 500 }}>{row.sku}</div>,
    },
    {
      field: "name",
      headerName: "ชื่อสินค้า",
      flex: 1,
      sortable: true,
      renderCell: ({ row }) => (
        <div className="pimage">
          {row.image_path ? (
            <img src={row.image_path} alt={row.name} />
          ) : (
            <div className="text-center mr-2 mt-1">
              <div
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "#F4F4F4",
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span style={{ color: "#6D788D" }}>{row.name.slice(0, 1).toUpperCase()}</span>
              </div>
            </div>
          )}
          <div>
            <div className="text-success fw-medium mb-1">{row.name}</div>
            <div className="fs-12">{row.sku}</div>
          </div>
        </div>
      ),
    },
    {
      field: "productCategory",
      headerName: "หมวดหมู่หลัก",
      width: 180,
      sortable: false,
      renderCell: ({ row }) => <div>{row.productCategory?.name || ""}</div>,
    },
    {
      field: "productSubCategory",
      headerName: "หมวดหมู่ย่อย",
      width: 180,
      sortable: false,
      renderCell: ({ row }) => <div>{row.productSubCategory?.name || ""}</div>,
    },
    {
      field: "product_vat_type",
      headerName: "ภาษี",
      width: 140,
      sortable: true,
      renderCell: ({ row }) => {
        const vatTypeLabel = {
          NO_VAT: "ไม่มีภาษี",
          ZERO_VAT: "ภาษี 0%",
          HAS_VAT: "มีภาษี",
        };
        return vatTypeLabel[row.product_vat_type] || row.product_vat_type;
      },
    },
    {
      field: "price",
      headerName: "ราคา",
      width: 140,
      sortable: true,
    },
  ]);

  const dataWithIndex = products?.map((item, index) => ({
    ...item,
    index: index + 1,
  }));

  return (
    <ModalAllder modal={modal} setModal={setModal} htitle="ค้นหาสินค้า" hbtn={true}>
      <div className="d-flex align-items-start mb-4">
        <div
          className="fs-18 fw-6 mb-3 d-inline-block me-auto text-white"
          style={{
            borderRadius: "8px",
            background: "#2DC76D",
            padding: "6.5px 25px",
          }}
        >
          สินค้าทั้งหมด
        </div>
        <div className="d-flex align-items-center pt-2 pb-2 btn-group-control">
          <Button type="button" className="btn-st-5 me-auto" style={{ width: "100px" }} onClick={props.onHide}>
            ยกเลิก
          </Button>
          <div className="bx-count mx-3">เลือกไว้ {productSelected ? productSelected.length : 0} รายการ</div>
          <Button
            type="button"
            className="btn-st-3"
            style={{ width: "130px" }}
            disabled={!productSelected || productSelected.length === 0}
            onClick={() => {
              handleSubmit(productSelected);
            }}
          >
            เพิ่มรายการ
          </Button>
        </div>
      </div>

      <div className="d-flex align-items-center mb-3">
        <div className="me-3" style={{ flex: "0 0 220px" }}>
          <Select value={selectedCategory} onChange={handleCategoryChange} className="w-100 select-st-1 pd" options={optionsCategory} placeholder="เลือกหมวดหมู่" />
        </div>
        <div style={{ flex: "0 0 180px" }}>
          <input type="text" className="form-control form-st-4" placeholder="ค้นหาชื่อ,รหัสสินค้า" onChange={(e) => debouncedSearch(e.target.value)} />
        </div>
      </div>

      <AllderTable
        data={dataWithIndex}
        loading={loading}
        columns={columns}
        totalRows={totalRows}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        handleSort={handleSort}
        checkboxSelection
        onRowSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRows = dataWithIndex.filter((row) => selectedIDs.has(row.id));
          setProductSelected(selectedRows);
        }}
      />
    </ModalAllder>
  );
}
