import React, { useEffect, useState, useCallback } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import InfoIcon from "@mui/icons-material/Info";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import BusinessImageUpload from "./BusinessImageUpload";
import { settingBusinessHandler, masterHandler } from "../../../../redux/handlers";
import { useParams } from "react-router-dom";
import { env } from "../../../../env";

export const domain = env.REACT_APP_URL;

const validationSchema = yup.object().shape({
  settingTaxNumber: yup.string().required("กรุณากรอกเลขประจำตัวผู้เสียภาษี"),
  settingSlug: yup
    .string()
    .required("กรุณากรอกลิงค์กิจการ")
    .test("length-validation", "URL ธุรกิจต้องมีความยาวระหว่าง 6-25 ตัวอักษร", (value) => {
      if (!value) return true;

      if (/[\u0E00-\u0E7F\u0600-\u06FF\u3040-\u30FF\u4E00-\u9FFF]/.test(value)) {
        return true;
      }

      const slugValue = value
        .trim()
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(/[^a-z0-9-]/g, "")
        .replace(/-+/g, "-")
        .replace(/^-+/g, "")
        .replace(/-+$/g, "");

      if (slugValue.length < 6) {
        return new yup.ValidationError("URL ธุรกิจหลังจากแปลงแล้วต้องมีความยาวอย่างน้อย 6 ตัวอักษร", value, "settingSlug");
      }
      if (slugValue.length > 25) {
        return new yup.ValidationError("URL ธุรกิจหลังจากแปลงแล้วต้องมีความยาวไม่เกิน 25 ตัวอักษร", value, "settingSlug");
      }
      return true;
    }),
  settingEntityTypeId: yup.string().required("กรุณาเลือกประเภทกิจการ"),
  settingEntityStructureId: yup.string().required("กรุณาเลือกรูปแบบกิจการ"),
  settingPrefixNameId: yup.string().required("กรุณาเลือกคำนำหน้า"),
  settingFirstName: yup.string().when(["settingEntityTypeId", "settingEntityStructureId"], {
    is: (entityTypeId, entityStructureId) => entityTypeId === "2" && entityStructureId === "1",
    then: () => yup.string().required("กรุณากรอกชื่อจริง"),
    otherwise: () => yup.string().nullable(),
  }),
  settingCompanyName: yup.string().when(["settingEntityTypeId", "settingEntityStructureId"], {
    is: (entityTypeId, entityStructureId) => entityTypeId === "1" || (entityTypeId === "2" && entityStructureId !== "1"),
    then: () => yup.string().required("กรุณากรอกชื่อบริษัท"),
    otherwise: () => yup.string().nullable(),
  }),
  settingLastName: yup.string().when(["settingEntityTypeId", "settingEntityStructureId"], {
    is: (entityTypeId, entityStructureId) => entityTypeId === "2" && entityStructureId === "1",
    then: () => yup.string().required("กรุณากรอกนามสกุล"),
    otherwise: () => yup.string().nullable(),
  }),
  settingRestaurantTypeId: yup.string().required("กรุณาเลือกประเภทร้านอาหาร"),
  settingShopProductCategoryTypeId: yup.string().required("กรุณาเลือกประเภทสินค้า"),
});

const defaultInitialValues = {
  settingImagePath: "",
  settingSlug: "",
  settingTaxNumber: "",
  settingEntityTypeId: "",
  settingEntityStructureId: "",
  settingPrefixNameId: "",
  settingCompanyName: "",
  settingFirstName: "",
  settingLastName: "",
  settingCompanyLastName: "",
  settingAddress: "",
  settingSubDistrictId: "",
  settingDistrict: "",
  settingProvince: "",
  settingPostalCode: "",
  settingRestaurantTypeId: "",
  settingShopProductCategoryTypeId: "",
};

const BusinessSettingsTabInfo = ({ formDisabled, setFormDisabled }) => {
  const dispatch = useDispatch();
  const { businessSlug } = useParams();

  const selectedBusiness = useSelector((state) => state.business.selectedBusiness?.business);
  const { entityTypes, entityStructures, entityStructuresByType, prefixNames, shopProductCategories, restaurantTypes, provinces, districts, subDistricts } = useSelector(
    (state) => state.master
  );

  const [initialValues, setInitialValues] = useState(defaultInitialValues);

  const [prefixNamesList, setPrefixNamesList] = useState([]);

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        await Promise.all([
          dispatch(masterHandler.getEntityTypes()),
          dispatch(masterHandler.getRestaurantTypes()),
          dispatch(masterHandler.getShopProductCategories()),
          dispatch(masterHandler.getProvinces()),
        ]);
      } catch (error) {
        console.error("Error loading initial data:", error);
      }
    };
    loadInitialData();
  }, [dispatch]);

  useEffect(() => {
    if (selectedBusiness) {
      const {
        image_path,
        tax_number,
        company_name,
        first_name,
        last_name,
        address: businessAddress,
        slug,
        entityType,
        prefixName,
        restaurantType,
        shopProductCategory,
        sub_district_id,
        prefix_name_id,
        subDistrict,
      } = selectedBusiness;

      const district = subDistrict?.district;
      const province = district?.province;

      const companyLastName = prefixNames?.find((e) => e.id == prefix_name_id)?.last_name || "";

      setInitialValues({
        ...defaultInitialValues,
        settingImagePath: image_path || "",
        settingSlug: slug || "",
        settingTaxNumber: tax_number || "",
        settingEntityTypeId: entityType?.id?.toString() || "",
        settingEntityStructureId: prefixName?.entity_structure_id?.toString() || "",
        settingPrefixNameId: prefix_name_id?.toString() || "",
        settingCompanyName: company_name || "",
        settingFirstName: first_name || "",
        settingLastName: last_name || "",
        settingCompanyLastName: companyLastName || "",
        settingAddress: businessAddress || "",
        settingSubDistrictId: sub_district_id?.toString() || "",
        settingDistrict: district?.id?.toString() || "",
        settingProvince: province?.id?.toString() || "",
        settingPostalCode: subDistrict?.zip_code || "",
        settingRestaurantTypeId: restaurantType?.id?.toString() || "",
        settingShopProductCategoryTypeId: shopProductCategory?.id?.toString() || "",
      });

      if (entityType?.code) {
        dispatch(masterHandler.getEntityStructuresByEntityType(entityType.code)).then((res) => {
          if (prefixName?.entity_structure_id) {
            dispatch(masterHandler.getPrefixNamesByTypeAndStructure(entityType.code, res?.find((es) => es.id === prefixName?.entity_structure_id)?.code || "")).then(
              (_prefixNames) => {
                setPrefixNamesList(_prefixNames);
              }
            );
          }
        });
      }

      const loadAddressData = async () => {
        try {
          await Promise.all([
            dispatch(masterHandler.getProvinces()),
            province?.id && dispatch(masterHandler.getDistricts(province.id)),
            district?.id && dispatch(masterHandler.getSubDistricts(district.id)),
          ]);
        } catch (error) {
          console.error("Error loading address data:", error);
        }
      };
      loadAddressData();
    }
  }, [selectedBusiness, dispatch]);

  const createOptions = useCallback((data = [], labelKey = "name", valueKey = "id") => {
    return data?.map((item) => ({
      value: item[valueKey].toString(),
      label: item[labelKey],
      code: item.code,
      first_name: item.first_name,
      last_name: item.last_name,
      entity_structure_id: item.entity_structure_id,
      ...item,
    }));
  }, []);

  const businessTypeOptions = createOptions(entityTypes);
  const entityStructureOptions = createOptions(entityStructuresByType || []);
  const prefixTypeOptions = createOptions(prefixNames);
  const productTypeOptions = createOptions(shopProductCategories);
  const restaurantTypeOptions = createOptions(restaurantTypes);

  const validatePreviewSlug = (value) => {
    const pattern = /^[a-z0-9]+(?:-[a-z0-9]+)*-?$/;
    return pattern.test(value);
  };

  const convertToSlug = (value) => {
    return value
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^a-z0-9-]/g, "")
      .replace(/-+/g, "-")
      .replace(/^-+/g, "")
      .replace(/-+$/g, "");
  };

  const handleSubmit = async (values) => {
    try {
      const slug = convertToSlug(values.settingSlug);
      const formattedValues = {
        ...values,
        settingSlug: slug,
      };
      const result = await dispatch(settingBusinessHandler.updateBusinessSlugInfo(businessSlug, formattedValues));
      if (result === "OK") {
        window.location.replace(`${domain}/${slug}/setting/business`);
        setFormDisabled(true);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleEntityTypeChange = async (e, setFieldValue, setFieldTouched, validateForm) => {
    try {
      const entityTypeCode = e.code;
      const entityTypeId = e.value;

      setFieldValue("settingEntityTypeId", entityTypeId);
      setFieldValue("settingEntityStructureId", "");
      setFieldValue("settingPrefixNameId", "");

      setFieldTouched("settingEntityTypeId", true);
      setFieldTouched("settingEntityStructureId", true);
      setFieldTouched("settingPrefixNameId", true);

      await dispatch(masterHandler.getEntityStructuresByEntityType(entityTypeCode));

      validateForm();
    } catch (error) {
      console.error("Error handling entity type change:", error);
    }
  };

  const handleEntityStructureChange = async (e, setFieldValue, setFieldTouched, validateForm, values) => {
    try {
      const entityStructureCode = e.code;
      const entityStructureId = e.value;

      setFieldValue("settingEntityStructureId", entityStructureId);
      setFieldValue("settingPrefixNameId", "");

      setFieldTouched("settingEntityStructureId", true);
      setFieldTouched("settingPrefixNameId", true);
      setFieldTouched("settingLastName", true);
      setFieldTouched("settingCompanyLastName", true);
      setFieldTouched("settingFirstName", true);
      setFieldTouched("settingCompanyName", true);

      const currentEntityType = businessTypeOptions?.find((option) => option.value === values.settingEntityTypeId);

      if (currentEntityType?.code) {
        const _prefixNames = await dispatch(masterHandler.getPrefixNamesByTypeAndStructure(currentEntityType.code, entityStructureCode));

        setPrefixNamesList(_prefixNames || []);

        if (_prefixNames && _prefixNames.length === 1) {
          const singlePrefix = _prefixNames[0];
          setFieldValue("settingPrefixNameId", singlePrefix.id.toString());

          const selectedEntityType = businessTypeOptions.find((option) => option.value === values.settingEntityTypeId);

          const isPersonalByCode = selectedEntityType?.code === "NATURAL" && entityStructureCode === "NATURAL";

          if (!isPersonalByCode) {
            setFieldValue("settingFirstName", singlePrefix.first_name || "");
            setFieldValue("settingCompanyLastName", singlePrefix.last_name || "");
          } else if (singlePrefix.last_name) {
            setFieldValue("settingLastName", singlePrefix.last_name || "");
          }

          setTimeout(() => {
            setFieldTouched("settingPrefixNameId", true);
            validateForm();
          }, 100);
        }
      }

      validateForm();
    } catch (error) {
      console.error("Error handling entity structure change:", error);
    }
  };

  const handlePrefixChange = (e, values, setFieldValue, setFieldTouched, validateForm) => {
    try {
      setFieldValue("settingPrefixNameId", e.value);
      setFieldTouched("settingPrefixNameId", true);

      const selectedEntityType = businessTypeOptions.find((option) => option.value === values.settingEntityTypeId);

      const selectedEntityStructure = entityStructureOptions.find((option) => option.value === values.settingEntityStructureId);

      const isPersonalByCode = selectedEntityType?.code === "NATURAL" && selectedEntityStructure?.code === "NATURAL";

      setFieldTouched("settingFirstName", false);
      setFieldTouched("settingLastName", false);
      setFieldTouched("settingCompanyName", false);
      setFieldTouched("settingCompanyLastName", false);

      let selectedPrefix = prefixTypeOptions.find((option) => option.value === e.value);

      if (!selectedPrefix || !selectedPrefix.last_name) {
        selectedPrefix = prefixNamesList.find((prefix) => prefix.id.toString() === e.value);
      }

      if (!isPersonalByCode) {
        setFieldValue("settingFirstName", e.first_name || selectedPrefix?.first_name || "");
        setFieldValue("settingCompanyLastName", e.last_name || selectedPrefix?.last_name || "");
        setFieldTouched("settingFirstName", true);
        setFieldTouched("settingCompanyLastName", true);
      } else if (selectedPrefix && selectedPrefix.last_name) {
        setFieldValue("settingLastName", selectedPrefix.last_name);
        setFieldTouched("settingLastName", true);
      } else if (e.last_name) {
        setFieldValue("settingLastName", e.last_name);
        setFieldTouched("settingLastName", true);
      }

      setTimeout(() => validateForm(), 0);
    } catch (error) {
      console.error("Error handling prefix change:", error);
    }
  };

  const handleProvinceChange = async (e, setFieldValue, setFieldTouched, validateForm) => {
    try {
      setFieldValue("settingProvince", e.value);
      setFieldValue("settingDistrict", "");
      setFieldValue("settingSubDistrictId", "");
      setFieldValue("settingPostalCode", "");

      setFieldTouched("settingProvince", true);
      setFieldTouched("settingDistrict", true);
      setFieldTouched("settingSubDistrictId", true);
      setFieldTouched("settingPostalCode", true);

      await dispatch(masterHandler.getDistricts(e.value));

      validateForm();
    } catch (error) {
      console.error("Error loading districts:", error);
    }
  };

  const handleDistrictChange = async (e, setFieldValue, setFieldTouched, validateForm) => {
    try {
      setFieldValue("settingDistrict", e.value);
      setFieldValue("settingSubDistrictId", "");
      setFieldValue("settingPostalCode", "");

      setFieldTouched("settingDistrict", true);
      setFieldTouched("settingSubDistrictId", true);
      setFieldTouched("settingPostalCode", true);

      await dispatch(masterHandler.getSubDistricts(e.value));

      validateForm();
    } catch (error) {
      console.error("Error loading sub-districts:", error);
    }
  };

  const handleSubDistrictChange = (e, setFieldValue, setFieldTouched, validateForm) => {
    setFieldValue("settingSubDistrictId", e.value);
    const subDistrict = subDistricts?.find((s) => s.id.toString() === e.value);
    if (subDistrict) {
      setFieldValue("settingPostalCode", subDistrict.zip_code);
    }

    setFieldTouched("settingSubDistrictId", true);
    setFieldTouched("settingPostalCode", true);

    validateForm();
  };

  const handleRestaurantTypeChange = (e, setFieldValue, setFieldTouched, validateForm) => {
    setFieldValue("settingRestaurantTypeId", e.value);
    setFieldTouched("settingRestaurantTypeId", true);
    validateForm();
  };

  const handleProductCategoryChange = (e, setFieldValue, setFieldTouched, validateForm) => {
    setFieldValue("settingShopProductCategoryTypeId", e.value);
    setFieldTouched("settingShopProductCategoryTypeId", true);
    validateForm();
  };

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} validateOnChange={true}>
      {({ handleSubmit, handleChange, touched, errors, values, setFieldValue, setFieldTouched, validateForm }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <fieldset disabled={formDisabled}>
            <div className="card-body px-xl-4">
              <div>
                <BusinessImageUpload
                  setFieldValue={setFieldValue}
                  values={values}
                  onUploadSuccess={(fileInfo) => {
                    setFieldValue("settingImagePath", fileInfo.base64);
                  }}
                  onUploadError={(error) => {}}
                />

                <div className="pt-3">
                  <div className="row gx-xl-4">
                    <div className="col-xl-4">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">เลขที่ประจำตัวผู้เสียภาษี</Form.Label>
                        <Form.Control
                          type="text"
                          name="settingTaxNumber"
                          className="form-control"
                          placeholder="เลขที่ประจำตัวผู้เสียภาษี"
                          value={values.settingTaxNumber}
                          onChange={handleChange}
                          isInvalid={touched.settingTaxNumber && !!errors.settingTaxNumber}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          maxLength={13}
                        />
                        <Form.Control.Feedback type="invalid">{errors.settingTaxNumber}</Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-xl-4">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">ตั้งชื่อ Username ธุรกิจของคุณ</Form.Label>
                        <Form.Control
                          type="text"
                          name="settingSlug"
                          className="form-control"
                          placeholder="กรอก URL ของคุณ"
                          value={values.settingSlug}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (
                              !/[\u0E00-\u0E7F\u0600-\u06FF\u3040-\u30FF\u4E00-\u9FFF]/.test(value) &&
                              value
                                .trim()
                                .toLowerCase()
                                .replace(/\s+/g, "-")
                                .replace(/[^a-z0-9-]/g, "")
                                .replace(/-+/g, "-") === value
                            ) {
                              setFieldValue("settingSlug", value);
                              setFieldTouched("settingSlug", true);
                              validateForm();
                            }
                          }}
                          isInvalid={touched.settingSlug && !!errors.settingSlug}
                        />
                        <Form.Control.Feedback type="invalid">{errors.settingSlug}</Form.Control.Feedback>
                      </Form.Group>
                      {/* <div className="form-text text-blue mt-[-4px] mb-2 self-start align-self-center"></div> */}

                      <div className="form-text text-blue mt-[-4px] mb-2 self-start align-self-center">
                        <p>
                          ตัวอย่าง URL ของคุณ : <br />
                          <span className="text-blue-700 font-bold text-lg">
                            {domain}/{validatePreviewSlug(convertToSlug(values.settingSlug)) ? convertToSlug(values.settingSlug) : ""}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-xl-4 align-self-center mb-8">
                      <div className="bg-[#F95057] bg-opacity-50 py-1 px-2 text-[#FF0000] text-[14px] flex items-center rounded font-semibold">
                        <InfoIcon className="text-[#FF0000] text-[12px] mr-2" />
                        รองรับเฉพาะตัวอักษร A-Z , 0-9 และเครื่องหมาย "-"
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">ประเภทกิจการ</Form.Label>
                        <Select
                          name="settingEntityTypeId"
                          onChange={(e) => handleEntityTypeChange(e, setFieldValue, setFieldTouched, validateForm)}
                          className={touched.settingEntityTypeId && !!errors.settingEntityTypeId ? "w-100 select-st-1 isInvalid" : "w-100 select-st-1"}
                          options={businessTypeOptions}
                          placeholder="เลือกประเภทกิจการ"
                          value={businessTypeOptions?.filter((item) => item.value === values.settingEntityTypeId)}
                        />
                        {touched.settingEntityTypeId && errors.settingEntityTypeId && <div className="invalid-feedback d-block">{errors.settingEntityTypeId}</div>}
                      </Form.Group>
                    </div>
                    <div className="col-xl-6">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">รูปแบบกิจการ</Form.Label>
                        <Select
                          name="settingEntityStructureId"
                          onChange={(e) => handleEntityStructureChange(e, setFieldValue, setFieldTouched, validateForm, values)}
                          className={touched.settingEntityStructureId && !!errors.settingEntityStructureId ? "w-100 select-st-1 isInvalid" : "w-100 select-st-1"}
                          options={entityStructureOptions}
                          placeholder="เลือกรูปแบบกิจการ"
                          value={entityStructureOptions?.filter((item) => item.value === values.settingEntityStructureId)}
                          isDisabled={!values.settingEntityTypeId}
                        />
                        {touched.settingEntityStructureId && errors.settingEntityStructureId && <div className="invalid-feedback d-block">{errors.settingEntityStructureId}</div>}
                      </Form.Group>
                    </div>
                    <div className="col-xl-4">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">คำนำหน้า</Form.Label>
                        <Select
                          name="settingPrefixNameId"
                          onChange={(e) => handlePrefixChange(e, values, setFieldValue, setFieldTouched, validateForm)}
                          className={touched.settingPrefixNameId && !!errors.settingPrefixNameId ? "w-100 select-st-1 isInvalid" : "w-100 select-st-1"}
                          options={prefixTypeOptions}
                          placeholder="เลือกคำนำหน้า"
                          value={prefixTypeOptions?.filter((item) => item.value === values.settingPrefixNameId)}
                          isDisabled={prefixTypeOptions ? prefixTypeOptions.length == 1 : false}
                        />
                        {touched.settingPrefixNameId && errors.settingPrefixNameId && <div className="invalid-feedback d-block">{errors.settingPrefixNameId}</div>}
                      </Form.Group>
                    </div>
                    <div className="col-xl-4">
                      {(() => {
                        const selectedEntityType = businessTypeOptions?.find((option) => option.value === values.settingEntityTypeId);

                        const selectedEntityStructure = entityStructureOptions?.find((option) => option.value === values.settingEntityStructureId);

                        const isPersonalByCode = selectedEntityType?.code === "NATURAL" && selectedEntityStructure?.code === "NATURAL";

                        if (isPersonalByCode) {
                          return (
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">ชื่อจริง *</Form.Label>
                              <Form.Control
                                type="text"
                                name="settingFirstName"
                                className="form-control"
                                placeholder="ชื่อจริง"
                                value={values.settingFirstName}
                                onChange={(e) => {
                                  handleChange(e);
                                  setFieldTouched("settingFirstName", true);
                                  validateForm();
                                }}
                                isInvalid={touched.settingFirstName && !!errors.settingFirstName}
                              />
                              <Form.Control.Feedback type="invalid">{errors.settingFirstName}</Form.Control.Feedback>
                            </Form.Group>
                          );
                        } else {
                          return (
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">ชื่อกิจการ *</Form.Label>
                              <Form.Control
                                type="text"
                                name="settingCompanyName"
                                className="form-control"
                                placeholder="ชื่อกิจการ"
                                value={values.settingCompanyName}
                                onChange={(e) => {
                                  handleChange(e);
                                  setFieldTouched("settingCompanyName", true);
                                  validateForm();
                                }}
                                isInvalid={touched.settingCompanyName && !!errors.settingCompanyName}
                              />
                              <Form.Control.Feedback type="invalid">{errors.settingCompanyName}</Form.Control.Feedback>
                            </Form.Group>
                          );
                        }
                      })()}
                    </div>
                    <div className="col-xl-4">
                      {(() => {
                        const selectedEntityType = businessTypeOptions?.find((option) => option.value === values.settingEntityTypeId);

                        const selectedEntityStructure = entityStructureOptions?.find((option) => option.value === values.settingEntityStructureId);

                        const isPersonalByCode = selectedEntityType?.code === "NATURAL" && selectedEntityStructure?.code === "NATURAL";

                        if (isPersonalByCode) {
                          return (
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">นามสกุล</Form.Label>
                              <Form.Control
                                type="text"
                                name="settingLastName"
                                className="form-control"
                                placeholder="นามสกุล"
                                value={values.settingLastName}
                                onChange={(e) => {
                                  handleChange(e);
                                  setFieldTouched("settingLastName", true);
                                  validateForm();
                                }}
                                isInvalid={touched.settingLastName && !!errors.settingLastName}
                              />
                              <Form.Control.Feedback type="invalid">{errors.settingLastName}</Form.Control.Feedback>
                            </Form.Group>
                          );
                        } else {
                          return (
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">คำลงท้าย</Form.Label>
                              <Form.Control
                                type="text"
                                name="settingCompanyLastName"
                                className="form-control"
                                placeholder="คำลงท้าย"
                                value={values.settingCompanyLastName}
                                onChange={(e) => {
                                  handleChange(e);
                                  setFieldTouched("settingCompanyLastName", true);
                                  validateForm();
                                }}
                                disabled={true}
                                isInvalid={touched.settingCompanyLastName && !!errors.settingCompanyLastName}
                              />
                              <Form.Control.Feedback type="invalid">{errors.settingCompanyLastName}</Form.Control.Feedback>
                            </Form.Group>
                          );
                        }
                      })()}
                    </div>
                    <div className="col-xl-12">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">ที่อยู่</Form.Label>
                        <Form.Control
                          type="text"
                          name="settingAddress"
                          className="form-control"
                          placeholder="กรอกที่อยู่"
                          value={values.settingAddress}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldTouched("settingAddress", true);
                            validateForm();
                          }}
                          isInvalid={touched.settingAddress && !!errors.settingAddress}
                        />
                        <Form.Control.Feedback type="invalid">{errors.settingAddress}</Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-xl-6">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">จังหวัด</Form.Label>
                        <Select
                          name="settingProvince"
                          onChange={(e) => handleProvinceChange(e, setFieldValue, setFieldTouched, validateForm)}
                          className={touched.settingProvince && !!errors.settingProvince ? "w-100 select-st-1 isInvalid" : "w-100 select-st-1"}
                          options={
                            provinces?.map((p) => ({
                              value: p.id.toString(),
                              label: p.name_th,
                            })) || []
                          }
                          placeholder="เลือกจังหวัด"
                          value={
                            provinces
                              ?.filter((p) => p.id.toString() === values.settingProvince)
                              .map((p) => ({
                                value: p.id.toString(),
                                label: p.name_th,
                              })) || null
                          }
                        />
                        {touched.settingProvince && errors.settingProvince && <div className="invalid-feedback d-block">{errors.settingProvince}</div>}
                      </Form.Group>
                    </div>
                    <div className="col-xl-6">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">เขต/อำเภอ</Form.Label>
                        <Select
                          name="settingDistrict"
                          onChange={(e) => handleDistrictChange(e, setFieldValue, setFieldTouched, validateForm)}
                          className={touched.settingDistrict && !!errors.settingDistrict ? "w-100 select-st-1 isInvalid" : "w-100 select-st-1"}
                          options={
                            districts?.map((d) => ({
                              value: d.id.toString(),
                              label: d.name_th,
                            })) || []
                          }
                          placeholder="เลือกเขต/อำเภอ"
                          value={
                            districts
                              ?.filter((d) => d.id.toString() === values.settingDistrict)
                              .map((d) => ({
                                value: d.id.toString(),
                                label: d.name_th,
                              })) || null
                          }
                          isDisabled={!values.settingProvince}
                        />
                        {touched.settingDistrict && errors.settingDistrict && <div className="invalid-feedback d-block">{errors.settingDistrict}</div>}
                      </Form.Group>
                    </div>
                    <div className="col-xl-6">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">แขวง/ตำบล</Form.Label>
                        <Select
                          name="settingSubDistrictId"
                          onChange={(e) => handleSubDistrictChange(e, setFieldValue, setFieldTouched, validateForm)}
                          className={touched.settingSubDistrictId && !!errors.settingSubDistrictId ? "w-100 select-st-1 isInvalid" : "w-100 select-st-1"}
                          options={
                            subDistricts?.map((s) => ({
                              value: s.id.toString(),
                              label: s.name_th,
                              zip_code: s.zip_code,
                            })) || []
                          }
                          placeholder="เลือกแขวง/ตำบล"
                          value={
                            subDistricts
                              ?.filter((s) => s.id.toString() === values.settingSubDistrictId)
                              .map((s) => ({
                                value: s.id.toString(),
                                label: s.name_th,
                              })) || null
                          }
                          isDisabled={!values.settingDistrict}
                        />
                        {touched.settingSubDistrictId && errors.settingSubDistrictId && <div className="invalid-feedback d-block">{errors.settingSubDistrictId}</div>}
                      </Form.Group>
                    </div>
                    <div className="col-xl-6">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">รหัสไปรษณีย์</Form.Label>
                        <Form.Control
                          type="text"
                          name="settingPostalCode"
                          className="form-control"
                          placeholder="รหัสไปรษณีย์"
                          value={values.settingPostalCode}
                          disabled
                          isInvalid={touched.settingPostalCode && !!errors.settingPostalCode}
                        />
                        <Form.Control.Feedback type="invalid">{errors.settingPostalCode}</Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-xl-6">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">ประเภทร้านอาหาร</Form.Label>
                        <Select
                          name="settingRestaurantTypeId"
                          onChange={(e) => handleRestaurantTypeChange(e, setFieldValue, setFieldTouched, validateForm)}
                          className={touched.settingRestaurantTypeId && !!errors.settingRestaurantTypeId ? "w-100 select-st-1 isInvalid" : "w-100 select-st-1"}
                          options={restaurantTypeOptions}
                          placeholder="เลือกประเภทร้านอาหาร"
                          value={restaurantTypeOptions?.filter((item) => item.value === values.settingRestaurantTypeId)}
                        />
                        {touched.settingRestaurantTypeId && errors.settingRestaurantTypeId && <div className="invalid-feedback d-block">{errors.settingRestaurantTypeId}</div>}
                      </Form.Group>
                    </div>
                    <div className="col-xl-6">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">เลือกประเภทสินค้า</Form.Label>
                        <Select
                          name="settingShopProductCategoryTypeId"
                          onChange={(e) => handleProductCategoryChange(e, setFieldValue, setFieldTouched, validateForm)}
                          className={touched.settingShopProductCategoryTypeId && !!errors.settingShopProductCategoryTypeId ? "w-100 select-st-1 isInvalid" : "w-100 select-st-1"}
                          options={productTypeOptions}
                          placeholder="เลือกประเภทสินค้า"
                          value={productTypeOptions?.filter((item) => item.value === values.settingShopProductCategoryTypeId)}
                        />
                        {touched.settingShopProductCategoryTypeId && errors.settingShopProductCategoryTypeId && (
                          <div className="invalid-feedback d-block">{errors.settingShopProductCategoryTypeId}</div>
                        )}
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <div className="text-end pb-4 pe-3">
            {formDisabled ? (
              <Button type="button" className="btn-st-3 mx-3" onClick={() => setFormDisabled(false)}>
                แก้ไข
              </Button>
            ) : (
              <div className="text-end pt-3 btn-group-control">
                <Button type="button" className="btn-st-5 me-3" onClick={() => setFormDisabled(true)}>
                  ยกเลิก
                </Button>
                <Button type="submit" className="btn-st-3">
                  บันทึก
                </Button>
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default BusinessSettingsTabInfo;
