import { combineReducers } from "@reduxjs/toolkit";
import common from "./common.slice";
import breadcrumb from "./breadcrumb.slice";
import master from "./master.slice";
import userBusinessGroupReducer from "./userBusinessGroup.slice";
import business from "./business.slice";
import codeSetting from "./settingGeneral.slice";
import branchKitchen from "./branchKitchen.slice";
import branchPayment from "./branchPayment.slice";
import branchEmp from "./branchEmp.slice";
import productStandard from "./productStandard.slice";
import product from "./product.slice";
import productBranch from "./productBranch.slice";

const appReducer = combineReducers({
  common,
  breadcrumb,
  master,
  userBusinessGroupReducer,
  business,
  codeSetting,
  branchKitchen,
  branchPayment,
  branchEmp,
  productStandard,
  product,
  productBranch,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_STATE") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
