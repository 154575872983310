export default function ProductFoodRecipes() {
  return (
    <div>
      <div className="card card-st-2" style={{ height: '400px' }}>
        <div className="card-body px-0 py-4 d-flex align-items-center justify-content-center">
          <div>
            <div className="text-center mb-3">
              <img src="/images/tdesign_system-setting.png" width={100} />
            </div>
            <div className="fs-20 tc-12">กำลังพัฒนาระบบ</div>
          </div>
        </div>
      </div>
    </div>
  );
}
