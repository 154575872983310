import React from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Formik } from 'formik';
import * as yup from 'yup';
import 'react-datepicker/dist/react-datepicker.css';

import { ModalAllder } from 'layouts/ModalAllder';
import { productStandardHandler } from '../../../../../redux/handlers';
import { useDispatch, useSelector } from 'react-redux';
const ModalEdit = ({
  modal,
  setModal,
  productOptionDetail,
  setProductOptionDetail,
  setModalDeleteShow,
  onSuccess,
  optionID,
  optiondata,
  setOptiondata,
  ...props
}) => {
  const schemaFormModal = yup.object().shape({
    name: yup.string().required('กรุณากรอกชื่อรายการ'),
    price: yup.number().required('กรุณากรอกราคา'),
  });
  const dispatch = useDispatch();
  return (
    <ModalAllder
      modal={modal}
      setModal={setModal}
      htitle={'แก้ไขรายการตัวเลือกมาตราฐาน'}
      hbtn={true}
    >
      <Formik
        enableReinitialize
        initialValues={productOptionDetail}
        validationSchema={schemaFormModal}
        onSubmit={async (values) => {
          setOptiondata((prev) => ({
            ...prev,
            optionList: prev.optionList.map((el) =>
              el.id == values.id
                ? {
                    ...el,
                    name: values.name,
                    price: values.price,
                  }
                : el
            ),
          }));
          let data = {
            name: values.name,
            price: values.price,
            productOptionId: optionID,
          };
          dispatch(
            productStandardHandler.updateProductOptionItem(values.id, data)
          );
          setModal((prev) => ({ ...prev, open: false }));
        }}
      >
        {({
          handleSubmit,
          handleChange,
          touched,
          errors,
          values,
          setFieldValue,
          isInvalid,
          isValid,
        }) => (
          <Form noValidate onSubmit={handleSubmit} className="p-lg-4">
            <fieldset>
              <div className="row ">
                <div className="col-12">
                  <Form.Group className="mb-4 form-st-3 pb-1">
                    <Form.Label className="mb-1">
                      ชื่อรายการ <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name={`name`}
                      className="form-control"
                      placeholder="กรอกชื่อรายการ"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!(errors.name && touched.name)}
                    />
                  </Form.Group>
                </div>
                <div className="col-12">
                  <Form.Group className="mb-4 form-st-3 pb-1">
                    <Form.Label className="mb-1">
                      ราคา (ส่วนที่จ่ายเพิ่ม){' '}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name={`price`}
                      className="form-control"
                      placeholder="กรอกราคา"
                      onChange={handleChange}
                      value={values.price}
                      isInvalid={!!(errors.price && touched.price)}
                    />
                  </Form.Group>
                </div>
              </div>
            </fieldset>
            <div className="text-end pb-4 pt-4 pe-3">
              <div className="d-flex justify-end align-items-center pt-3 btn-group-control">
                <Button
                  type="button"
                  className="btn-st-5 me-auto px-4 "
                  style={{ width: '90px' }}
                  onClick={async () => {
                    setOptiondata((prev) => ({
                      ...prev,
                      optionList: prev.optionList.filter(
                        (item) => item.id !== productOptionDetail.id
                      ),
                    }));
                    setModal((prev) => ({ ...prev, open: false }));
                  }}
                >
                  ลบ
                </Button>
                <Button
                  className="btn btn-st-6 me-3 bg-transparent"
                  onClick={() => setModal((prev) => ({ ...prev, open: false }))}
                >
                  ยกเลิก
                </Button>
                <Button type="submit" className="btn-st-3 ">
                  บันทึก
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ModalAllder>
  );
};
export default ModalEdit;
