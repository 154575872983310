import { ENDPOINT, STATUS } from "../../constants/server/api";
import { fetchApiData } from "../../utils/apiUtils";

export const getProductAnnotations = (params) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.PRODUCT_ANNOTATION}/pagination/list`,
    params: {
      business_slug: params.business_slug,
      page: params.page,
      perPage: params.per_page,
      s: params.s,
      sortBy: params.sortBy,
      sortOrder: params.sortOrder,
      business_id: params.business_id,
    },
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const getAllProductAnnotations =
  (businessSlug, productId) => async (dispatch) => {
    const data = await fetchApiData({
      method: "get",
      endpoint: `${ENDPOINT.PRODUCT_ANNOTATION}/all/list`,
      params: {
        business_slug: businessSlug,
        product_id: productId,
      },
      dispatch,
    });
    return data;
  };

export const getProductAnnotationById = (id) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.PRODUCT_ANNOTATION}/${id}`,
    dispatch,
  });
  return data;
};

export const createProductAnnotation = (annotationData) => async (dispatch) => {
  const data = await fetchApiData({
    method: "post",
    endpoint: ENDPOINT.PRODUCT_ANNOTATION,
    data: annotationData,
    dispatch,
  });
  return data;
};

export const updateProductAnnotation =
  (id, annotationData) => async (dispatch) => {
    const data = await fetchApiData({
      method: "put",
      endpoint: `${ENDPOINT.PRODUCT_ANNOTATION}/${id}`,
      data: annotationData,
      dispatch,
    });
    return data;
  };

export const deleteProductAnnotation = (id) => async (dispatch) => {
  await fetchApiData({
    method: "delete",
    endpoint: `${ENDPOINT.PRODUCT_ANNOTATION}/${id}`,
    dispatch,
  });
  return STATUS.OK;
};

export const exportProductAnnotationsToExcel =
  (businessSlug, searchTerm) => async (dispatch) => {
    const data = await fetchApiData({
      method: "post",
      endpoint: `${ENDPOINT.PRODUCT_ANNOTATION}/excel`,
      params: {
        business_slug: businessSlug,
        s: searchTerm || "",
      },
      responseType: "blob",
      dispatch,
    });

    if (data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "รายการคำอธิบายสินค้า.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }
  };

export const createProductAnnotationItem = (itemData) => async (dispatch) => {
  const data = await fetchApiData({
    method: "post",
    endpoint: `${ENDPOINT.PRODUCT_ANNOTATION_ITEM}`,
    data: itemData,
    dispatch,
  });
  return data;
};

export const updateProductAnnotationItem =
  (id, itemData) => async (dispatch) => {
    const data = await fetchApiData({
      method: "put",
      endpoint: `${ENDPOINT.PRODUCT_ANNOTATION_ITEM}/${id}`,
      data: itemData,
      dispatch,
    });
    return data;
  };

export const deleteProductAnnotationItem = (id) => async (dispatch) => {
  await fetchApiData({
    method: "delete",
    endpoint: `${ENDPOINT.PRODUCT_ANNOTATION_ITEM}/${id}`,
    dispatch,
  });
  return STATUS.OK;
};

export const getProductAnnotationItemById = (id) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.PRODUCT_ANNOTATION_ITEM}?product_annotation_id=${id}`,
    dispatch,
  });
  return data;
};

export const getAllProductAnnotationItems = (params) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.PRODUCT_ANNOTATION_ITEM}/all/list`,
    params: {
      business_slug: params.business_slug,
      s: params.s || "",
    },
    dispatch,
  });
  return data;
};
