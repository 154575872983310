import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams, NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import Form from "react-bootstrap/Form";

import Collapse from "react-bootstrap/Collapse";
import { utils, writeFileXLSX } from "xlsx";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useDispatch } from "react-redux";
import { employeeHandler } from "../../../../../redux/handlers";

function getCurrentDate(separator = "", dateb = new Date()) {
  let newDate = dateb;
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${year}-${month < 10 ? `0${month}` : `${month}`}-${
    date < 10 ? `0${date}` : `${date}`
  }`;
}

export default function ConfirmCancelEmpModal(props) {
  const dispatch = useDispatch();
  const { setTextsuccess, empID, onSuccess, setFieldValue, ...rest } = props;

  const schema = yup.object().shape({
    dateEnd: yup.string().required(),
  });

  return (
    <Modal {...rest} size="md" centered>
      <Modal.Body>
        <div className="px-3 py-4">
          <Formik
            enableReinitialize
            initialValues={{
              dateEnd: getCurrentDate(),
            }}
            validationSchema={schema}
            onSubmit={async (values) => {
              dispatch(
                employeeHandler.updateEmployeeResign(props.empID, {
                  empWorkEndDate: values.dateEnd,
                  empIsResign: true,
                  empResignDate: values.dateEnd,
                })
              );
              props.onSuccess();
            }}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
              isInvalid,
              isValid,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <div>
                  <div>
                    <div className="text-center fs-22 tc-7 mb-4">
                      คุณยืนยันการลาออกใช่หรือไม่
                    </div>
                  </div>

                  <Form.Group className="mb-3 form-st-3 pb-1">
                    <Form.Label className="mb-1">วันที่ลาออก </Form.Label>
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      onChange={(date) => {
                        setFieldValue("dateEnd", getCurrentDate("-", date));
                      }}
                      value={values.dateEnd}
                      placeholder="DD/MM/YYYY"
                      className="w-100 datepicker2"
                      isInvalid={!!errors.dateEnd}
                    />
                  </Form.Group>

                  <div className="text-center pt-4 mt-3  btn-group-control">
                    <Button
                      type="button"
                      className="btn-st-6 mx-3 "
                      style={{ width: "150px" }}
                      onClick={props.onHide}
                    >
                      ไม่ยกเลิก
                    </Button>
                    <Button
                      type="submit"
                      className="btn btn-st-3 mx-3 text-decoration-none"
                      style={{ width: "150px" }}
                    >
                      ยืนยันการลาออก
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}
