import React, { useState, useEffect } from "react";
import { GeneralNumberSettingsTab } from "./components/GeneralNumberSettingsTab";
import { useDispatch } from "react-redux";
import { masterHandler, settingGeneralHandler } from "../../../redux/handlers";
import { useParams } from "react-router-dom";
import { useBreadcrumb } from "../../../hooks/useBreadcrumb";
import Breadcrumb from "../../../components/Breadcrumb";

const SETTING_TYPES = {
  SYSTEM: "SYS",
  DOCUMENT: "DOC",
};

const TABS = [
  { id: 1, label: "ตั้งค่าเลขระบบ", type: SETTING_TYPES.SYSTEM },
  { id: 2, label: "ตั้งค่าเลขเอกสาร", type: SETTING_TYPES.DOCUMENT },
];

const TabNavigation = ({ activeTab, onTabChange }) => (
  <nav>
    <div className="nav nav-tabs nav-tabs-st-2" id="nav-tab" role="tablist">
      {TABS.map(({ id, label, type }) => (
        <button
          key={id}
          className={`nav-link ${activeTab === id ? "active" : ""}`}
          type="button"
          onClick={() => onTabChange(id, type)}
        >
          {label}
        </button>
      ))}
    </div>
  </nav>
);

export function GeneralNumberSettings() {
  const { businessSlug } = useParams();
  const [activeTab, setActiveTab] = useState(1);
  const [settingType, setSettingType] = useState(SETTING_TYPES.SYSTEM);
  const dispatch = useDispatch();
  const breadcrumbConfig = [
    { label: "หน้าหลัก", path: "/" },
    { label: "ตั้งค่า", path: "#" },
    { label: "ตั้งค่าทั่วไป", path: "#" },
    { label: "ตั้งค่าเลขที่" },
  ];
  useBreadcrumb(breadcrumbConfig);

  const handleTabChange = (tabId, type) => {
    setActiveTab(tabId);
    setSettingType(type);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        await Promise.all([
          dispatch(masterHandler.getCodeSettingDayList()),
          dispatch(masterHandler.getCodeSettingMonthList()),
          dispatch(masterHandler.getCodeSettingYearList()),
          dispatch(masterHandler.getCounterSettingMaster()),
          dispatch(
            settingGeneralHandler.getCodeSettings(businessSlug, settingType)
          ),
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchInitialData();
  }, [dispatch, businessSlug, settingType]);

  return (
    <div>
      <Breadcrumb />
      <div className="fs-24 tc-7 mb-4 lh-1">ตั้งค่าเลขที่</div>
      <div className="card card-st-1">
        <div className="card-header">
          <TabNavigation activeTab={activeTab} onTabChange={handleTabChange} />
        </div>
        <GeneralNumberSettingsTab />
      </div>
    </div>
  );
}
