import React from "react";
import DataTable from "react-data-table-component";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import Datatablenoresults from "layouts/Datatablenoresults";
import CheckboxTable from "fragments/CheckboxTable";
import * as styleTable from "styles/datatable";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

import { ModalAllder } from "layouts/ModalAllder";
import { DataGrid } from "@mui/x-data-grid";

export default function BranchProductList({
  filteredItems,
  handleDelete,
  btnedit,
  branchID,
  ...props
}) {
  const columnsd = [
    {
      field: "productCode",
      headerName: "รหัสสินค้า",
      width: 170,
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <div style={{ color: "#2DC76D", fontWeight: 500 }}>
          {row.productCode}
        </div>
      ),
    },

    {
      field: "productName",
      headerName: "ชื่อสินค้า",
      flex: 1,
      minWidth: 240,
      sortable: false,
      renderCell: ({ row }) => (
        <div className="pimage !items-center text-base">
          {row.productImage ? (
            <img src={row.productImage} alt={row.productName} />
          ) : (
            <div className="text-center mr-2 mt-1">
              <div
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "#F4F4F4",
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span style={{ color: "#6D788D" }}>
                  {row.name.slice(0, 1).toUpperCase()}
                </span>
              </div>
            </div>
          )}
          <div>
            <div style={{ color: "rgba(76, 78, 100, 0.87)", fontWeight: 400 }}>
              {row.productName}
            </div>
          </div>
        </div>
      ),
    },
    {
      field: "productCategory",
      headerName: "หมวดหมู่หลัก",
      width: 180,
      sortable: false,
    },
    {
      field: "productsubCategory",
      headerName: "หมวดหมู่ย่อย",
      width: 180,
      sortable: false,
    },
    {
      field: "productUnitName",
      headerName: "หน่วย",
      width: 180,
      sortable: false,
    },
    {
      field: "productPrice",
      headerName: "ราคา",
      width: 170,
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <div className="w-100">
          <div className="text-[#2DC76D] mb-1">{row.productPrice}</div>
        </div>
      ),
    },
  ];

  return (
    <>
      <DataGrid
        getRowId={(row) => row.productID}
        getRowHeight={() => "auto"}
        rows={filteredItems}
        columns={columnsd}
        // autoHeight={true}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10, 20, 30]}
        checkboxSelection
        disableRowSelectionOnClick
        slotProps={{
          pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
        }}
        sx={{
          fontFamily: "Sarabun",
          fontSize: "17px",
          ".MuiDataGrid-columnHeaderTitle": {
            fontFamily: "Sarabun",
            fontSize: "17px",
          },
          ".MuiDataGrid-cell": {
            display: "flex",
            alignItems: "center",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
        }}
      />
      {/* <DataTable
                columns={columns}
                data={filteredItems}
                customStyles={styleTable.customStyles}
                selectableRows
                selectableRowsComponent={CheckboxTable}
                pagination
                paginationComponentOptions={styleTable.paginationComponentOptions}
                noDataComponent={ <Datatablenoresults  />}
                persistTableHead={true}
                paginationPerPage="5"
                paginationRowsPerPageOptions={[5,10, 25, 50, 100]}
            /> */}
    </>
  );
}
