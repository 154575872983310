import React, { useState, useEffect } from "react";
import { Link, useParams, NavLink, Outlet } from "react-router-dom";
import { useBreadcrumb } from "../hooks/useBreadcrumb";
import Breadcrumb from "../components/Breadcrumb";
import { useDispatch } from "react-redux";
import { branchHandler } from "../redux/handlers";
export function BranchSettingMenu({ selectbranchID, setSelectBranchID }) {
  const { businessSlug, branchID } = useParams();
  const [breadcrumb, setBreadcrumb] = useState("ข้อมูลสาขา");
  const dispatch = useDispatch();
  const breadcrumbConfig = [{ label: "หน้าแรก", path: "/" }, { label: "ตั้งค่า", path: "#" }, { label: breadcrumb }];
  useBreadcrumb(breadcrumbConfig);
  useEffect(() => {
    const fetchData = async () => {
      if (branchID) {
        const response = await dispatch(branchHandler.getBranchById(branchID));
        if (response) {
          setBreadcrumb(`ข้อมูลสาขา - ${response.branch_name}`);
        }
      }
    };
    fetchData();
  }, [branchID]);
  return (
    <>
      <Breadcrumb />
      <div className="fs-24 tc-7  mb-4 lh-1">{breadcrumb} </div>

      <div
        className="d-flex mb-4 overflow-hidden"
        style={{
          boxShadow: " 0px 2px 10px 0px rgba(76, 78, 100, 0.22)",
          borderRadius: "6px",
        }}
      >
        <div
          className="py-4"
          style={{
            flex: "0 0 260px",
            borderRadius: "10px 0px 0px 10px",
            background: "#FFF",
          }}
        >
          <div
            className="mb-4 px-3"
            style={{
              color: "rgba(76, 78, 100, 0.87)",
              letterSpacing: "0.15px",
            }}
          >
            จัดการสาขา
          </div>

          <ul className="side-menu side-menu-branch">
            <li className="slide">
              <NavLink to={`/${businessSlug}/branch/setting/info/${branchID}`} className="side-menu__item py-2 " onClick={() => setBreadcrumb("ข้อมูลสาขา")}>
                <div className="side-menu_icon">
                  <div className="side-menu__icon icon-b1"></div>
                </div>
                <span className="side-menu__label"> ข้อมูลสาขา</span>
              </NavLink>
            </li>
            <li className="slide">
              <NavLink to={`/${businessSlug}/branch/setting/product/${branchID}`} className="side-menu__item py-2 " onClick={() => setBreadcrumb("จัดการเมนูขาย")}>
                <div className="side-menu_icon">
                  <div className="side-menu__icon icon-b2"></div>
                </div>
                <span className="side-menu__label"> จัดการเมนูขาย</span>
              </NavLink>
            </li>
            <li className="slide">
              <NavLink to={`/${businessSlug}/branch/setting/general/${branchID}`} className="side-menu__item py-2 " onClick={() => setBreadcrumb("ตั้งค่าทั่วไป")}>
                <div className="side-menu_icon">
                  <div className="side-menu__icon icon-b3"></div>
                </div>
                <span className="side-menu__label"> ตั้งค่าทั่วไป</span>
              </NavLink>
            </li>

            <li className="slide">
              <NavLink to={`/${businessSlug}/branch/setting/salesmodel/${branchID}`} className="side-menu__item py-2 " onClick={() => setBreadcrumb("รูปแบบการขาย")}>
                <div className="side-menu_icon">
                  <div className="side-menu__icon icon-b4"></div>
                </div>
                <span className="side-menu__label"> รูปแบบการขาย</span>
              </NavLink>
            </li>
            <li className="slide">
              <NavLink to={`/${businessSlug}/branch/setting/table/${branchID}`} className="side-menu__item py-2 " onClick={() => setBreadcrumb("จัดการโซน/โต๊ะ")}>
                <div className="side-menu_icon">
                  <div className="side-menu__icon icon-b5"></div>
                </div>
                <span className="side-menu__label"> จัดการโซน/โต๊ะ</span>
              </NavLink>
            </li>
            <li className="slide">
              <NavLink to={`/${businessSlug}/branch/setting/payment/${branchID}`} className="side-menu__item py-2 " onClick={() => setBreadcrumb("ตั้งค่าการชำระเงิน")}>
                <div className="side-menu_icon">
                  <div className="side-menu__icon icon-b6"></div>
                </div>
                <span className="side-menu__label"> ตั้งค่าการชำระเงิน</span>
              </NavLink>
            </li>
            <li className="slide">
              <NavLink to={`/${businessSlug}/branch/setting/delivery/${branchID}`} className="side-menu__item py-2 " onClick={() => setBreadcrumb("ตั้งค่า Delivery")}>
                <div className="side-menu_icon">
                  <div className="side-menu__icon icon-b7"></div>
                </div>
                <span className="side-menu__label"> ตั้งค่า Delivery</span>
              </NavLink>
            </li>
            <li className="slide">
              <NavLink to={`/${businessSlug}/branch/setting/kitchen/${branchID}`} className="side-menu__item py-2 " onClick={() => setBreadcrumb("จัดการครัว")}>
                <div className="side-menu_icon">
                  <div className="side-menu__icon icon-b8"></div>
                </div>
                <span className="side-menu__label"> จัดการครัว</span>
              </NavLink>
            </li>
            <li className="slide">
              <NavLink to={`/${businessSlug}/branch/setting/emp/${branchID}`} className="side-menu__item py-2 " onClick={() => setBreadcrumb("จัดการพนักงาน")}>
                <div className="side-menu_icon">
                  <div className="side-menu__icon icon-b9"></div>
                </div>
                <span className="side-menu__label"> จัดการพนักงาน</span>
              </NavLink>
            </li>
            <li className="slide">
              <NavLink to={`/${businessSlug}/branch/setting/document/${branchID}`} className="side-menu__item py-2 " onClick={() => setBreadcrumb("ตั้งค่าเอกสาร")}>
                <div className="side-menu_icon">
                  <div className="side-menu__icon icon-b10"></div>
                </div>
                <span className="side-menu__label"> ตั้งค่าเอกสาร</span>
              </NavLink>
            </li>
            <li className="slide">
              <NavLink to={`/${businessSlug}/branch/setting/printer/${branchID}`} className="side-menu__item py-2 " onClick={() => setBreadcrumb("ตั้งค่าเครื่องพิมพ์")}>
                <div className="side-menu_icon">
                  <div className="side-menu__icon icon-b11"></div>
                </div>
                <span className="side-menu__label"> ตั้งค่าเครื่องพิมพ์</span>
              </NavLink>
            </li>
            <li className="slide">
              <NavLink to={`/${businessSlug}/branch/setting/notification/${branchID}`} className="side-menu__item py-2 " onClick={() => setBreadcrumb("ตั้งแจ้งเตือน")}>
                <div className="side-menu_icon">
                  <div className="side-menu__icon icon-b12"></div>
                </div>
                <span className="side-menu__label"> ตั้งแจ้งเตือน</span>
              </NavLink>
            </li>
            <li className="slide">
              <NavLink to={`/${businessSlug}/branch/setting/pos/${branchID}`} className="side-menu__item py-2 " onClick={() => setBreadcrumb("ตั้งค่า POS")}>
                <div className="side-menu_icon">
                  <div className="side-menu__icon icon-b13"></div>
                </div>
                <span className="side-menu__label"> ตั้งค่า POS</span>
              </NavLink>
            </li>
          </ul>
        </div>
        {/* <div
          className="flex-fill"
          style={{ borderRadius: "0px 10px 10px 0px" }}
        > */}
        <div className="flex-[0_0_calc(100%-260px)] w-[calc(100%-260px)]" style={{ borderRadius: "0px 10px 10px 0px" }}>
          <Outlet />
        </div>
      </div>
    </>
  );
}
