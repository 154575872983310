import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";

import Select from "react-select";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import getCurrentDate from "../../../../utils/getCurrentDate";

import { ModalAllder } from "layouts/ModalAllder";
export default function PackageModal({ modal, setModal, ...props }) {
  const { setModalDeleteShow, onSuccess, formdata, ...rest } = props;

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const schema = yup.object().shape({
    packageName: yup.string().required(),
    menuConnect: yup.string().required(),
    hour: yup.string().when("useTime", (useTime, schema) => {
      if (useTime != 1) return schema.required();
      return schema;
    }),
    minute: yup.string().when("useTime", (useTime, schema) => {
      if (useTime != 1) return schema.required();
      return schema;
    }),
  });

  const optionMenu = [
    { value: 1, label: "Premium Buffet บุฟเฟ่ต์ชาบู 699" },
    { value: 2, label: "Buffet 199" },
  ];

  return (
    <ModalAllder
      modal={modal}
      setModal={setModal}
      htitle={"เพิ่มแพ็คเกจ"}
      hbtn={true}
    >
      <div className="">
        <Formik
          enableReinitialize
          initialValues={formdata}
          validationSchema={schema}
          onSubmit={async (values) => {
            props.onSuccess();
          }}
        >
          {({
            handleSubmit,
            handleChange,
            touched,
            errors,
            values,
            setFieldValue,
            isInvalid,
            isValid,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3 form-st-3 pb-1">
                <Form.Label className="mb-1">
                  วันที่สร้าง <span className="text-danger">*</span>{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="dateCreate"
                  className="form-control"
                  placeholder="วันที่สร้าง"
                  value={getCurrentDate("/")}
                  onChange={handleChange}
                  isInvalid={!!errors.dateCreate}
                  disabled={true}
                />
              </Form.Group>
              <Form.Group className="mb-3 form-st-3 pb-1">
                <Form.Label className="mb-1">
                  ชื่อแพ็คเกจ <span className="text-danger">*</span>{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="packageName"
                  className="form-control"
                  placeholder="ชื่อแพ็คเกจ"
                  value={values.packageName}
                  onChange={handleChange}
                  isInvalid={!!errors.packageName}
                />
              </Form.Group>

              <Form.Group className={`mb-2 form-st-3 pb-1`}>
                <Form.Label className="mb-1">
                  เชื่อมต่อกับเมนู <span className="text-danger">*</span>
                </Form.Label>
                <Select
                  as="select"
                  name="menuConnect"
                  onChange={(e) => {
                    setFieldValue("menuConnect", e.value);
                  }}
                  value={optionMenu.filter(
                    (item) => item.value === values.menuConnect
                  )}
                  className={`w-100 ${
                    errors.menuConnect ? "isInvalid" : ""
                  } select-st-1`}
                  options={optionMenu}
                  placeholder="เชื่อมต่อกับเมนู"
                />
              </Form.Group>

              <div className="row">
                <div className="col-6">
                  <Form.Group className="mb-3 form-st-3 pb-1 ">
                    <Form.Label className="mb-1">
                      ชั่วโมง <span className="text-danger">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="hour"
                      className="form-control"
                      placeholder="ชั่วโมง"
                      value={values.hour}
                      onChange={handleChange}
                      isInvalid={!!errors.hour}
                      disabled={values.useTime}
                    />
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="mb-3 form-st-3 pb-1 ">
                    <Form.Label className="mb-1">
                      นาที <span className="text-danger">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="minute"
                      className="form-control"
                      placeholder="นาที"
                      value={values.minute}
                      onChange={handleChange}
                      isInvalid={!!errors.minute}
                      disabled={values.useTime}
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="mb-4">
                <Form.Check
                  type="switch"
                  id="useTime"
                  label="ไม่กำหนดเวลา"
                  name="useTime"
                  className="custom-switch-st-1  tc-10 s1 mb-1"
                  checked={values.useTime == 1}
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      setFieldValue("useTime", 1);
                    } else {
                      setFieldValue("useTime", 0);
                    }
                  }}
                />
              </div>

              <Form.Group className="mb-3 form-st-3 pb-1 ">
                <Form.Label className="mb-1">หมายเหตุ </Form.Label>
                <Form.Control
                  type="text"
                  name="remark"
                  className="form-control"
                  placeholder="หมายเหตุ"
                  value={values.remark}
                  onChange={handleChange}
                  isInvalid={!!errors.remark}
                />
              </Form.Group>

              <Form.Label className="mb-2  tc-10">สถานะ</Form.Label>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    inline
                    label="เปิดใช้งาน"
                    name="packageStatus"
                    type={type}
                    id={`inline-${type}-21`}
                    className="form-check-st-2 tc-7"
                    checked={values.packageStatus === 1}
                    onChange={(e) => {
                      setFieldValue("packageStatus", 1);
                    }}
                  />
                  <Form.Check
                    inline
                    label="ปิดใช้งาน"
                    name="packageStatus"
                    type={type}
                    id={`inline-${type}-22`}
                    className="form-check-st-2 tc-7"
                    checked={values.packageStatus === 0}
                    onChange={(e) => {
                      setFieldValue("packageStatus", 0);
                    }}
                  />
                </div>
              ))}

              <div className="d-flex align-items-center pt-2 pb-3 btn-group-control">
                {values.packageID ? (
                  <Button
                    type="button"
                    className="btn-st-5 me-auto px-4 "
                    style={{ width: "90px" }}
                    onClick={() => {
                      props.onHide();
                    }}
                  >
                    ลบ
                  </Button>
                ) : (
                  <div className="me-auto"></div>
                )}
                <Button
                  type="button"
                  className="btn-st-6 me-3 "
                  style={{ width: "100px" }}
                  onClick={props.onHide}
                >
                  ยกเลิก
                </Button>
                <Button
                  type="submit"
                  className="btn-st-3  "
                  style={{ width: "140px" }}
                >
                  {" "}
                  {values.bankID ? "แก้ไข" : "เพิ่มแพ็คเกจ"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </ModalAllder>
  );
}
