import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import "react-datepicker/dist/react-datepicker.css";
const Optionlist = (props) => {
  useEffect(() => {
    props.setFieldValue(`optionList[${props.n}].name`, props?.item?.name);
    props.setFieldValue(`optionList[${props.n}].price`, props?.item?.price);
    //Do not delete this comment
    // if (props.item.stock?.name != '') {
    //   props.setFieldValue(`optionList[${props.n}].stock`, props?.item.stock);
    // } else {
    //   props.setFieldValue(`optionList[${props.n}].stock`, null);
    // }
  }, [props.item]);

  return (
    <div className="row gx-xl-3 align-items-center">
      {props.values.optionList[props.n] ? (
        <>
          <div className="col-xl-6 col-lg-6 col-8">
            <fieldset className="row " disabled={true}>
              <div className="col-6">
                <Form.Group className="mb-2 form-st-3 pb-1">
                  <Form.Label className="mb-1">ชื่อรายการ </Form.Label>
                  <Form.Control
                    type="text"
                    name={`optionList[${props.n}].name`}
                    className="form-control"
                    placeholder="กรอกชื่อมาตราฐาน"
                    value={props.values.optionList[props.n]?.name}
                    onChange={(e) =>
                      props.setFieldValue(
                        `optionList[${props.n}].name`,
                        e.target.value
                      )
                    }
                  />
                  <div>
                    <p className="text-red-500 text-sm ">
                      {props.values.optionList[props.n]?.name == ""
                        ? "ชื่อไม่ควรเว้นว่าง"
                        : ""}
                    </p>
                  </div>
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-2 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ราคา (ส่วนที่จ่ายเพิ่ม){" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name={`optionList[${props.n}].price`}
                    className="form-control"
                    placeholder="กรอกชื่อมาตราฐาน"
                    value={props.values.optionList[props.n].price}
                    onChange={(e) =>
                      props.setFieldValue(
                        `optionList[${props.n}].price`,
                        e.target.value
                      )
                    }
                  />
                  <div>
                    <p className="text-red-500 text-sm ">
                      {props.values.optionList[props.n].price == ""
                        ? "ชื่อไม่ควรเว้นว่าง"
                        : ""}
                    </p>
                  </div>
                </Form.Group>
              </div>
            </fieldset>
          </div>
          <div className="col-xl-6 col-4 flex  items-center">
            <button
              type="button"
              className="btn me-2 d-block btn-edit-st-1 !w-[20px] "
              onClick={props.editHandeler}
            ></button>
            <button
              className="btn-delete-st-3 ml-2"
              type="button"
              onClick={props.deleteHandeler}
            ></button>
            {/* do not delete this comment */}
            {/* <button
              type="button"
              className="btn btn-setting-st-1 me-2"
              onClick={props.settingHandeler}
            ></button>

            {props.item.stock ? (
              props.item.stock && props.item.stock.productID != '' ? (
                <span
                  className="fs-16"
                  style={{ color: '#2DC76D', lineHeight: '24px' }}
                >
                  ตั้งค่าแล้ว
                </span>
              ) : (
                <span
                  className="fs-16"
                  style={{ color: '#B6BBC6', lineHeight: '24px' }}
                >
                  ยังไม่ได้ตั้งค่า
                </span>
              )
            ) : (
              <span
                className="fs-16"
                style={{ color: '#B6BBC6', lineHeight: '24px' }}
              >
                ยังไม่ได้ตั้งค่า
              </span>
            )} */}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};
export default Optionlist;
