import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function ConfirmDeleteModal({
  show,
  onHide,
  onSuccess,
  deleteTarget,
}) {
  const getDeleteMessage = () => {
    if (!deleteTarget) return "";
    return deleteTarget.type === "option"
      ? "คุณต้องการลบตัวเลือกสินค้านี้ใช่หรือไม่"
      : "คุณต้องการลบรายการนี้ใช่หรือไม่";
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Body>
        <div className="px-3 py-4">
          <div>
            <div className="text-center fs-22 tc-7 mb-2">
              {getDeleteMessage()}
            </div>
            <div className="text-center pt-4 mt-3 btn-group-control">
              <Button
                type="button"
                className="btn-st-3 mx-3"
                style={{
                  width: "100px",
                  background: "#F95057",
                  borderColor: "#F95057",
                }}
                onClick={onSuccess}
              >
                ใช่
              </Button>
              <Button
                type="button"
                className="btn-st-6 mx-3"
                style={{ width: "100px" }}
                onClick={onHide}
              >
                ไม่ใช่
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
