import React, { useState, useEffect, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import { Formik } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';
export default function ProductSettings() {
  const [optionsUnit, setOptionsUnit] = useState([]);

  useEffect(() => {
    // setOptionsUnit(getunitData());
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ratio: '',
        umlLabel: '',
        ums: '',
      }}
      validationSchema=""
      onSubmit={async (values) => {}}
    >
      {({ handleSubmit, handleChange, touched, errors, values, setFieldValue, isInvalid, isValid }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <fieldset>
            <div className="card card-st-3 mb-3">
              <div className="card-body px-xl-3">
                <div className="tc-8 fs-18 fw-bold px-3 mb-4 pt-2">ตั้งค่าขั้นสูง</div>
                <div className="px-3">
                  <div className="pt-2 pb-1 mb-3">
                    <Form.Check
                      type="switch"
                      id="custom-switch2"
                      label="ตั้งค่าสต๊อก"
                      name="settingStock"
                      className="custom-switch-st-1 tc-10  s1 mb-1"
                      defaultChecked={values.settingStock}
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          setFieldValue('settingStock', 1);
                        } else {
                          setFieldValue('settingStock', 0);
                        }
                      }}
                    />
                    <span className="ms-2" style={{ color: '#858796' }}>
                      {' '}
                      ( สินค้ามีการตัดสต๊อก ){' '}
                    </span>
                  </div>
                  {values.settingStock === 1 ? (
                    <div>
                      <div className="pb-2">
                        <Form.Label className="mb-4 pt-2 tc-10">ตั้งค่าประเภทการตัดสต๊อก</Form.Label>
                        <div className="mb-3">
                          <Form.Check
                            inline
                            label="ตัดสต๊อกสินค้าสำเร็จรูป"
                            name="stockType"
                            type="radio"
                            id={`stockType-1`}
                            className="form-check-st-2 tc-7"
                            checked={values.stockType === 1}
                            onChange={(e) => {
                              setFieldValue('stockType', 1);
                            }}
                          />
                          <Form.Check
                            inline
                            label="ตัดสต๊อกตามสตรอาหาร"
                            name="stockType"
                            type="radio"
                            id={`stockType-2`}
                            className="form-check-st-2 tc-7"
                            checked={values.stockType === 2}
                            onChange={(e) => {
                              setFieldValue('stockType', 2);
                            }}
                          />
                        </div>
                      </div>

                      {values.stockType == 1 ? (
                        <div className="row">
                          <div className="col-md-2">
                            <Form.Group className="mb-3 form-st-3 pb-1">
                              <Form.Label className="mb-1">หน่วยเล็ก (UMS) </Form.Label>
                              <Form.Control
                                type="text"
                                name="ums"
                                className="form-control"
                                placeholder="หน่วยเล็ก"
                                value={values.ums}
                                onChange={handleChange}
                                isInvalid={!!errors.ums}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group className="mb-2 mt-3 form-st-3  ">
                              <Form.Label className="mb-1">หน่วยใหญ่ (UML) </Form.Label>
                              <Select
                                as="select"
                                name="uml"
                                onChange={(e) => {
                                  setFieldValue('uml', e.value);
                                  setFieldValue('umlLabel', e.label);
                                }}
                                className="w-100 select-st-1"
                                options={optionsUnit}
                                placeholder="หน่วยใหญ่ (UML) "
                                value={optionsUnit.filter((item) => item.value === values.uml)}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-3">
                            <Form.Group className="mb-3 form-st-3 pb-1">
                              <Form.Label className="mb-1">อัตราส่วนต่อหน่วยย่อย (Ratio) </Form.Label>
                              <Form.Control
                                type="text"
                                name="ratio"
                                className="form-control"
                                placeholder="อัตราส่วนต่อหน่วยย่อย"
                                value={values.ratio}
                                onChange={handleChange}
                                isInvalid={!!errors.ratio}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-5">
                            <Form.Group className="mb-3 form-st-3 pb-1">
                              <Form.Label className="mb-1">ผลลัพธ์ </Form.Label>
                              <Form.Control
                                type="text"
                                name="result"
                                className="form-control"
                                placeholder="ผลลัพธ์"
                                value={`${values.ratio} X ${values.ums} เท่ากับ 1 ${values.umlLabel}`}
                                onChange={handleChange}
                                isInvalid={!!errors.result}
                                disabled={true}
                              />
                            </Form.Group>
                          </div>
                        </div>
                      ) : values.stockType == 2 ? (
                        <div style={{ color: '#F00' }}>กำหนดรายการที่ต้องการตัดสต๊อกตามเมนูสตรอาหาร</div>
                      ) : (
                        ''
                      )}

                      <div className="text-end pt-2 pb-3 btn-group-control ">
                        <Button type="button" className="btn-st-6 me-3 " style={{ width: '100px' }}>
                          ยกเลิก
                        </Button>
                        <Button type="submit" className="btn-st-3  " style={{ width: '100px' }}>
                          บันทึก
                        </Button>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </fieldset>
        </Form>
      )}
    </Formik>
  );
}
