import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import moment from "moment";

import AnnotationItems from "./components/AnnotationItems";
import {
  productAnnotationHandler,
  userBusinessGroupHandler,
} from "../../../redux/handlers";
import {
  SuccessDeleteModal,
  ConfirmDeleteItemModal,
  ConfirmDeleteModal,
  AnnotationItemModal,
} from "./components/modals";
import getCurrentDate from "../../../utils/getCurrentDate";
import { useBreadcrumb } from "../../../hooks/useBreadcrumb";
import Breadcrumb from "../../../components/Breadcrumb";

const INITIAL_FORM_STATE = {
  name: "",
  dateCreate: "",
  userCreate: "",
  isDisplay: 0,
  optionList: [],
};

const validationSchema = yup.object().shape({
  name: yup.string().required("กรุณากรอกชื่อตัวเลือกหมายเหตุ"),
});

const INITIAL_MODAL_STATE = {
  showConfirmModal: false,
  showSuccessModal: false,
  data: null,
};

const INITIAL_ANNOTATION_MODAL_STATE = {
  open: false,
  x: 0,
  y: 0,
  width: 680,
  height: 330,
  pin: false,
  mode: "normal",
  modalname: "modal_add_option_note",
};

export function AnnotationForm() {
  const { businessSlug, optionID } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    ...INITIAL_FORM_STATE,
    dateCreate: getCurrentDate("/"),
  });
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [optionList, setOptionList] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState(INITIAL_MODAL_STATE);
  const [deleteAnnotationModal, setDeleteAnnotationModal] =
    useState(INITIAL_MODAL_STATE);
  const [annotationModal, setAnnotationModal] = useState(
    INITIAL_ANNOTATION_MODAL_STATE
  );
  const breadcrumbConfig = [
    { label: "หน้าหลัก", path: "/" },
    { label: "สินค้าและบริการ", path: "/product/options/annotation" },
    { label: "จัดการตัวเลือกหมายเหตุ", path: "/product/options/annotation" },
    { label: optionID ? "แก้ไขตัวเลือกหมายเหตุ" : "สร้างตัวเลือกหมายเหตุ" },
  ];
  useBreadcrumb(breadcrumbConfig);

  const fetchUserProfile = async () => {
    try {
      const response = await dispatch(
        userBusinessGroupHandler.getUserBusinessProfile()
      );
      if (response) {
        const { first_name, last_name } = response;
        setFormData((prev) => ({
          ...prev,
          userCreate: `${first_name} ${last_name}`,
        }));
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  const fetchAnnotationItems = async () => {
    if (!optionID) return;

    try {
      const response = await dispatch(
        productAnnotationHandler.getProductAnnotationItemById(optionID)
      );

      if (!response) return;

      const mappedItems = response.map(
        ({ id, name, product_annotation_id, is_active }) => ({
          id,
          name,
          product_annotation_id,
          is_active,
        })
      );

      setOptionList(mappedItems);
      setFormData((prev) => ({ ...prev, optionList: mappedItems }));
    } catch (error) {
      console.error("Error fetching annotation items:", error);
    }
  };

  const fetchAnnotationData = async () => {
    if (!optionID) return;

    try {
      const response = await dispatch(
        productAnnotationHandler.getProductAnnotationById(optionID)
      );

      if (!response) return;

      const userName = response.userBusinessGroup
        ? `${response.userBusinessGroup.first_name} ${response.userBusinessGroup.last_name}`
        : formData.userCreate;

      setFormData((prev) => ({
        ...prev,
        name: response.name || "",
        dateCreate: response.date
          ? getCurrentDate("/", new Date(response.date))
          : getCurrentDate("/"),
        userCreate: userName,
        isDisplay: response.is_display ? 1 : 0,
        optionList: [],
      }));

      setIsFormDisabled(true);
      await fetchAnnotationItems();
    } catch (error) {
      console.error("Error fetching annotation data:", error);
    }
  };

  useEffect(() => {
    if (!optionID) {
      fetchUserProfile();
    }
    fetchAnnotationData();
  }, [optionID]);

  useEffect(() => {
    if (isSuccess && optionID) {
      fetchAnnotationItems();
      setIsSuccess(false);
    }
  }, [isSuccess, optionID]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const formattedDate = moment(values.dateCreate, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );

      const payload = {
        name: values.name,
        isDisplay: values.isDisplay === 1,
        date: formattedDate,
      };

      if (optionID) {
        await dispatch(
          productAnnotationHandler.updateProductAnnotation(optionID, payload)
        );
        navigate(`/${businessSlug}/product/options/annotation`);
      } else {
        const response = await dispatch(
          productAnnotationHandler.createProductAnnotation({
            businessSlug,
            ...payload,
          })
        );
        navigate(
          `/${businessSlug}/product/options/annotation/edit/${response.id}`
        );
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteAnnotation = async () => {
    try {
      await dispatch(
        productAnnotationHandler.deleteProductAnnotation(optionID)
      );
      setDeleteAnnotationModal((prev) => ({
        ...prev,
        showConfirmModal: false,
        showSuccessModal: true,
      }));

      setTimeout(() => {
        navigate(`/${businessSlug}/product/options/annotation`);
      }, 1500);
    } catch (error) {
      console.error("Error deleting annotation:", error);
      setDeleteAnnotationModal(INITIAL_MODAL_STATE);
    }
  };

  const handleDeleteItem = async () => {
    if (!deleteModalState.itemToDelete) return;

    try {
      await dispatch(
        productAnnotationHandler.deleteProductAnnotationItem(
          deleteModalState.itemToDelete.id
        )
      );

      setDeleteModalState((prev) => ({
        ...prev,
        showConfirmModal: false,
        showSuccessModal: true,
      }));

      await fetchAnnotationItems();

      setTimeout(() => {
        setDeleteModalState(INITIAL_MODAL_STATE);
      }, 1500);
    } catch (error) {
      console.error("Error deleting annotation item:", error);
      setDeleteModalState(INITIAL_MODAL_STATE);
    }
  };

  const renderFormButtons = ({ handleSubmit }) => (
    <div className="text-end pb-4 pe-3">
      <div className="d-flex justify-content-end pt-2 pb-3 btn-group-control">
        {isFormDisabled ? (
          <Button
            type="button"
            className="btn-st-3"
            style={{ width: "80px" }}
            onClick={() => setIsFormDisabled(false)}
          >
            แก้ไข
          </Button>
        ) : (
          <div className="d-flex justify-content-between w-100">
            <div>
              {optionID && (
                <Button
                  type="button"
                  className="btn-st-5"
                  style={{ width: "80px" }}
                  onClick={() =>
                    setDeleteAnnotationModal((prev) => ({
                      ...prev,
                      showConfirmModal: true,
                      data: formData,
                    }))
                  }
                >
                  ลบ
                </Button>
              )}
            </div>
            <div className="ms-auto">
              <Button
                className="btn btn-st-6"
                style={{ width: "100px", marginRight: "4px" }}
                onClick={() => setIsFormDisabled(true)}
              >
                ยกเลิก
              </Button>
              <Button
                type="submit"
                className="btn-st-3"
                style={{ width: "100px" }}
                onClick={handleSubmit}
              >
                บันทึก
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div>
      <Breadcrumb />
      <div className="fs-24 tc-7 mb-4 lh-1">
        {optionID ? "แก้ไขตัวเลือกหมายเหตุ" : "สร้างตัวเลือกหมายเหตุ"}
      </div>

      <div className="card card-st-1 mb-4">
        <div className="card-body p-4">
          <div className="fs-22 fw-500 tc-7 mb-4 lh-1">
            {optionID ? "แก้ไขตัวเลือกหมายเหตุ" : "สร้างตัวเลือกหมายเหตุ"}
          </div>

          <Formik
            initialValues={formData}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({
              handleSubmit,
              handleChange,
              errors,
              values,
              setFieldValue,
              touched,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <div className="row gx-xl-3">
                  <div className="col-xl-6 col-lg-6 col">
                    <Form.Group className="mb-2 form-st-3 pb-1">
                      <Form.Label className="mb-1">
                        ชื่อตัวเลือกหมายเหตุ{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        className={`form-control ${
                          touched.name && errors.name ? "is-invalid" : ""
                        }`}
                        placeholder="กรอกชื่อตัวเลือกหมายเหตุ"
                        value={values.name}
                        onChange={handleChange}
                        disabled={isFormDisabled}
                      />
                      {touched.name && errors.name && (
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </div>
                </div>
                {optionList.map((item, index) => (
                  <AnnotationItems
                    key={item.id}
                    item={item}
                    n={index}
                    editHandeler={() => {
                      setAnnotationModal((prev) => ({
                        ...prev,
                        open: true,
                        mode: "edit",
                        editData: item,
                      }));
                    }}
                    onDeleteRequest={(item) =>
                      setDeleteModalState({
                        showConfirmModal: true,
                        showSuccessModal: false,
                        itemToDelete: item,
                      })
                    }
                    setFieldValue={setFieldValue}
                    values={values}
                    disabled={isFormDisabled}
                  />
                ))}

                <div className="row gx-xl-3">
                  <div className="col-xl-6 col-lg-6 col">
                    {optionID && (
                      <div className="mb-2">
                        <button
                          type="button"
                          className="btn btn-st-3 mb-4"
                          onClick={() =>
                            setAnnotationModal((prev) => ({
                              ...prev,
                              open: true,
                              mode: "add",
                              editData: null,
                            }))
                          }
                          disabled={isFormDisabled}
                        >
                          เพิ่ม
                        </button>
                      </div>
                    )}

                    <Form.Check
                      type="switch"
                      id="display-switch"
                      label="แสดงหน้าจอลูกค้า"
                      name="isDisplay"
                      className="custom-switch-st-1 tc-10 s2 mb-3"
                      checked={values.isDisplay === 1}
                      onChange={(e) =>
                        setFieldValue("isDisplay", e.target.checked ? 1 : 0)
                      }
                      disabled={isFormDisabled}
                    />

                    <div className="row">
                      <div className="col-6">
                        <Form.Group className="mb-3 form-st-3 pb-1">
                          <Form.Label className="mb-1">วันที่สร้าง</Form.Label>
                          <Form.Control
                            type="text"
                            name="dateCreate"
                            value={values.dateCreate}
                            disabled
                          />
                        </Form.Group>
                      </div>
                      <div className="col-6">
                        <Form.Group className="mb-3 form-st-3 pb-1">
                          <Form.Label className="mb-1">ชื่อผู้สร้าง</Form.Label>
                          <Form.Control
                            type="text"
                            name="userCreate"
                            value={values.userCreate}
                            disabled
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>

                {renderFormButtons({ handleSubmit })}
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <AnnotationItemModal
        optionID={optionID}
        modal={annotationModal}
        setModal={setAnnotationModal}
        optionData={formData}
        onSuccess={() => setIsSuccess(true)}
      />

      <ConfirmDeleteItemModal
        show={deleteModalState.showConfirmModal}
        onHide={() => setDeleteModalState(INITIAL_MODAL_STATE)}
        onConfirm={handleDeleteItem}
      />

      <SuccessDeleteModal
        show={deleteModalState.showSuccessModal}
        onHide={() => setDeleteModalState(INITIAL_MODAL_STATE)}
      />

      <ConfirmDeleteModal
        show={deleteAnnotationModal.showConfirmModal}
        onHide={() => setDeleteAnnotationModal(INITIAL_MODAL_STATE)}
        onConfirm={handleDeleteAnnotation}
      />

      <SuccessDeleteModal
        show={deleteAnnotationModal.showSuccessModal}
        onHide={() => setDeleteAnnotationModal(INITIAL_MODAL_STATE)}
      />
    </div>
  );
}
