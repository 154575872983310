import * as productAction from "../slices/product.slice";
import { ENDPOINT, STATUS } from "../../constants/server/api";
import { fetchApiData } from "../../utils/apiUtils";

export const getProductAnnotations = (params) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.PRODUCT_ANNOTATION}/pagination/list`,
    params: {
      business_slug: params.business_slug,
      page: params.page,
      perPage: params.per_page,
      s: params.s,
      sortBy: params.sortBy,
      sortOrder: params.sortOrder,
      business_id: params.business_id,
    },
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const getAllProductAnnotations =
  (businessSlug, productId) => async (dispatch) => {
    const data = await fetchApiData({
      method: "get",
      endpoint: `${ENDPOINT.PRODUCT_ANNOTATION}/all/list`,
      params: {
        business_slug: businessSlug,
        product_id: productId,
      },
      dispatch,
    });
    return data;
  };

export const getProductAnnotationById = (id) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.PRODUCT_ANNOTATION}/${id}`,
    dispatch,
  });
  return data;
};

export const createProductAnnotation = (annotationData) => async (dispatch) => {
  const data = await fetchApiData({
    method: "post",
    endpoint: ENDPOINT.PRODUCT_ANNOTATION,
    data: annotationData,
    dispatch,
  });
  return data;
};

export const updateProductAnnotation =
  (id, annotationData) => async (dispatch) => {
    const data = await fetchApiData({
      method: "put",
      endpoint: `${ENDPOINT.PRODUCT_ANNOTATION}/${id}`,
      data: annotationData,
      dispatch,
    });
    return data;
  };

export const deleteProductAnnotation = (id) => async (dispatch) => {
  await fetchApiData({
    method: "delete",
    endpoint: `${ENDPOINT.PRODUCT_ANNOTATION}/${id}`,
    dispatch,
  });
  return STATUS.OK;
};

export const exportProductAnnotationsToExcel =
  (businessSlug, searchTerm) => async (dispatch) => {
    const data = await fetchApiData({
      method: "post",
      endpoint: `${ENDPOINT.PRODUCT_ANNOTATION}/excel`,
      params: {
        business_slug: businessSlug,
        s: searchTerm || "",
      },
      responseType: "blob",
      dispatch,
    });

    if (data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "รายการคำอธิบายสินค้า.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }
  };

export const createProductAnnotationItem = (itemData) => async (dispatch) => {
  const data = await fetchApiData({
    method: "post",
    endpoint: `${ENDPOINT.PRODUCT_ANNOTATION_ITEM}`,
    data: itemData,
    dispatch,
  });
  return data;
};

export const updateProductAnnotationItem =
  (id, itemData) => async (dispatch) => {
    const data = await fetchApiData({
      method: "put",
      endpoint: `${ENDPOINT.PRODUCT_ANNOTATION_ITEM}/${id}`,
      data: itemData,
      dispatch,
    });
    return data;
  };

export const deleteProductAnnotationItem = (id) => async (dispatch) => {
  await fetchApiData({
    method: "delete",
    endpoint: `${ENDPOINT.PRODUCT_ANNOTATION_ITEM}/${id}`,
    dispatch,
  });
  return STATUS.OK;
};

export const getProductAnnotationItemById = (id) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.PRODUCT_ANNOTATION_ITEM}?product_annotation_id=${id}`,
    dispatch,
  });
  return data;
};

export const getAllProductAnnotationItems = (params) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.PRODUCT_ANNOTATION_ITEM}/all/list`,
    params: {
      business_slug: params.business_slug,
      s: params.s || "",
    },
    dispatch,
  });
  return data;
};

export const getProductList = (params) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.PRODUCT}/pagination/${params.businessSlug}/list`,
    params: {
      page: params.page,
      perPage: params.per_page,
      s: params.s,
      sortBy: params.sortBy,
      sortOrder: params.sortOrder,
      productStatus: params.productStatus || "active", //active|inactive
      productCategoryId: params.productCategoryId || "",
      pageQuery: params.pageQuery ?? "",
      branch_id: params.branch_id || "",
      branch_kitchen_id: params.branch_kitchen_id || "",
    },
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const exportProductsToExcel =
  (businessSlug, searchTerm) => async (dispatch) => {
    const data = await fetchApiData({
      method: "post",
      endpoint: `${ENDPOINT.PRODUCT}/excel/${businessSlug}`,
      params: {
        s: searchTerm || "",
      },
      responseType: "blob",
      dispatch,
    });

    if (data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "รายการสินค้า.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }
  };

export const createProduct = (productData) => async (dispatch) => {
  const formData = new FormData();

  formData.append("businessSlug", productData.businessSlug);
  formData.append("product_category_id", productData.product_category_id);
  formData.append(
    "product_sub_category_id",
    productData.product_sub_category_id
  );
  formData.append("product_sale_unit_id", productData.product_sale_unit_id);
  formData.append("sku", productData.sku);
  formData.append("name", productData.name);
  formData.append("is_weight_based", productData.is_weight_based);
  formData.append("is_available", productData.is_available);
  formData.append("is_visible", productData.is_visible);
  formData.append(
    "is_excluded_from_promotions",
    productData.is_excluded_from_promotions
  );
  formData.append("is_recommended", productData.is_recommended);
  formData.append("price", productData.price);
  formData.append("price_delivery", productData.price_delivery);
  formData.append("cost", productData.cost);
  formData.append("annotation", productData.annotation);
  formData.append("product_type", productData.product_type);
  formData.append("product_vat_type", productData.product_vat_type);
  formData.append("is_status", productData.is_status);

  if (productData.imagePath) {
    if (productData.imagePath instanceof File) {
      formData.append("imagePath", productData.imagePath);
    } else if (
      typeof productData.imagePath === "string" &&
      productData.imagePath.startsWith("data:image")
    ) {
      const blob = await fetch(productData.imagePath).then((r) => r.blob());
      formData.append("imagePath", blob, "image.jpg");
    }
  }

  const data = await fetchApiData({
    method: "post",
    endpoint: `${ENDPOINT.PRODUCT}`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const getProductById = (id) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.PRODUCT}/${id}`,
    dispatch,
  });

  if (data) {
    dispatch(productAction.setProduct(data));
    return data;
  }
};

export const updateProduct = (id, productData) => async (dispatch) => {
  const formData = new FormData();

  formData.append("businessSlug", productData.businessSlug);
  formData.append("product_category_id", productData.product_category_id);
  formData.append(
    "product_sub_category_id",
    productData.product_sub_category_id
  );
  formData.append("product_sale_unit_id", productData.product_sale_unit_id);
  formData.append("sku", productData.sku);
  formData.append("barcode", productData.barcode);
  formData.append("name", productData.name);
  formData.append("is_weight_based", productData.is_weight_based);
  formData.append("is_available", productData.is_available);
  formData.append("is_visible", productData.is_visible);
  formData.append(
    "is_excluded_from_promotions",
    productData.is_excluded_from_promotions
  );
  formData.append("is_recommended", productData.is_recommended);
  formData.append("price", productData.price);
  formData.append("price_delivery", productData.price_delivery);
  formData.append("cost", productData.cost);
  formData.append("annotation", productData.annotation);
  formData.append("product_type", productData.product_type);
  formData.append("product_vat_type", productData.product_vat_type);
  formData.append("is_status", productData.is_status);
  formData.append("is_active", productData.is_active);

  if (productData.imagePath) {
    if (productData.imagePath instanceof File) {
      formData.append("imagePath", productData.imagePath);
    } else if (
      typeof productData.imagePath === "string" &&
      productData.imagePath.startsWith("data:image")
    ) {
      const blob = await fetch(productData.imagePath).then((r) => r.blob());
      formData.append("imagePath", blob, "image.jpg");
    }
  }

  const data = await fetchApiData({
    method: "put",
    endpoint: `${ENDPOINT.PRODUCT}/${id}`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const deleteProduct = (id) => async (dispatch) => {
  const data = await fetchApiData({
    method: "delete",
    endpoint: `${ENDPOINT.PRODUCT}/${id}`,
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const recoverProduct = (id) => async (dispatch) => {
  const data = await fetchApiData({
    method: "put",
    endpoint: `${ENDPOINT.PRODUCT}/${id}/recover`,
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const getAllProductOptions = (params) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.PRODUCT_OPTION_STANDARD}/all/list`,
    params: {
      business_slug: params.businessSlug,
      pageQuery: params.pageQuery,
      product_id: params.productId,
    },
    dispatch,
  });
  return data;
};

export const getProductOptionRelations = (productId) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.PRODUCT_OPTION_STANDARD_RELATION}/all/list`,
    params: {
      product_id: productId,
    },
    dispatch,
  });
  return data;
};

export const createProductOptionRelation =
  (relationData) => async (dispatch) => {
    const data = await fetchApiData({
      method: "post",
      endpoint: `${ENDPOINT.PRODUCT_OPTION_STANDARD_RELATION}`,
      data: relationData,
      dispatch,
    });
    return data;
  };

export const deleteProductOptionRelation = (id) => async (dispatch) => {
  await fetchApiData({
    method: "delete",
    endpoint: `${ENDPOINT.PRODUCT_OPTION_STANDARD_RELATION}/${id}`,
    dispatch,
  });
  return STATUS.OK;
};

export const getProductAnnotationRelations =
  (productId) => async (dispatch) => {
    const data = await fetchApiData({
      method: "get",
      endpoint: `${ENDPOINT.PRODUCT_ANNOTATION_RELATION}/all/list`,
      params: {
        product_id: productId,
      },
      dispatch,
    });
    return data;
  };

export const createProductAnnotationRelation =
  (relationData) => async (dispatch) => {
    const data = await fetchApiData({
      method: "post",
      endpoint: `${ENDPOINT.PRODUCT_ANNOTATION_RELATION}`,
      data: relationData,
      dispatch,
    });
    return data;
  };

export const deleteProductAnnotationRelation = (id) => async (dispatch) => {
  await fetchApiData({
    method: "delete",
    endpoint: `${ENDPOINT.PRODUCT_ANNOTATION_RELATION}/${id}`,
    dispatch,
  });
  return STATUS.OK;
};

export const getAllProductAnnotationOptions = (params) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.PRODUCT_ANNOTATION}/all/list`,
    params: {
      business_slug: params.businessSlug,
      product_id: params.productId,
      pageQuery: params.pageQuery,
    },
    dispatch,
  });
  return data;
};

export const getProductSpecificOptionRelations =
  (productId) => async (dispatch) => {
    const data = await fetchApiData({
      method: "get",
      endpoint: `${ENDPOINT.PRODUCT_SPECIFIC_OPTION_RELATION}/all/list`,
      params: {
        product_id: productId,
      },
      dispatch,
    });
    return data;
  };

export const createProductSpecificOptionRelation =
  (relationData) => async (dispatch) => {
    const data = await fetchApiData({
      method: "post",
      endpoint: ENDPOINT.PRODUCT_SPECIFIC_OPTION_RELATION,
      data: relationData,
      dispatch,
    });
    return data;
  };

export const updateProductSpecificOptionRelation =
  (id, relationData) => async (dispatch) => {
    const data = await fetchApiData({
      method: "put",
      endpoint: `${ENDPOINT.PRODUCT_SPECIFIC_OPTION_RELATION}/${id}`,
      data: relationData,
      dispatch,
    });
    return data;
  };

export const deleteProductSpecificOptionRelation = (id) => async (dispatch) => {
  await fetchApiData({
    method: "delete",
    endpoint: `${ENDPOINT.PRODUCT_SPECIFIC_OPTION_RELATION}/${id}`,
    dispatch,
  });
  return STATUS.OK;
};

export const createProductSpecificOptionRelationItem =
  (itemData) => async (dispatch) => {
    const data = await fetchApiData({
      method: "post",
      endpoint: ENDPOINT.PRODUCT_SPECIFIC_OPTION_RELATION_ITEM,
      data: itemData,
      dispatch,
    });
    return data;
  };

export const updateProductSpecificOptionRelationItem =
  (id, itemData) => async (dispatch) => {
    const data = await fetchApiData({
      method: "put",
      endpoint: `${ENDPOINT.PRODUCT_SPECIFIC_OPTION_RELATION_ITEM}/${id}`,
      data: itemData,
      dispatch,
    });
    return data;
  };

export const deleteProductSpecificOptionRelationItem =
  (id, productSpecificOptionId) => async (dispatch) => {
    await fetchApiData({
      method: "delete",
      endpoint: `${ENDPOINT.PRODUCT_SPECIFIC_OPTION_RELATION_ITEM}/${id}?product_specific_option_relation_id=${productSpecificOptionId}`,
      dispatch,
    });
    return STATUS.OK;
  };

export const getProductSpecificOptionRelationItems =
  (relationId) => async (dispatch) => {
    const data = await fetchApiData({
      method: "get",
      endpoint: `${ENDPOINT.PRODUCT_SPECIFIC_OPTION_RELATION_ITEM}/all/list`,
      params: {
        product_specific_option_relation_id: relationId,
      },
      dispatch,
    });
    return data;
  };

export const getProductLogs = (params) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.PRODUCT_LOG}/pagination/list`,
    params: {
      business_slug: params.business_slug,
      type: params.type,
      start_date: params.start_date || "2024-01-01",
      end_date: params.end_date || "2026-01-01",
      page: params.page,
      perPage: params.per_page,
      sortBy: params.sortBy,
      sortOrder: params.sortOrder,
    },
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const exportProductLogsToExcel = (params) => async (dispatch) => {
  const data = await fetchApiData({
    method: "post",
    endpoint: `${ENDPOINT.PRODUCT_LOG}/excel`,
    params: {
      business_slug: params.business_slug,
      type: params.type,
      start_date: params.start_date || "2024-01-01",
      end_date: params.end_date || "2026-01-01",
      business_group_id: params.business_group_id,
    },
    responseType: "blob",
    dispatch,
  });

  if (data) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "รายการ_log_สินค้า.xlsx");
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  }

  return STATUS.OK;
};

export const updateProductSpecificOptionStatus =
  (id, isSpecificOption) => async (dispatch) => {
    const data = await fetchApiData({
      method: "put",
      endpoint: `${ENDPOINT.PRODUCT}/${id}/is-specific-option`,
      data: {
        is_specific_option: isSpecificOption,
      },
      dispatch,
    });

    if (data) {
      return data;
    }
  };

export const updateProductOptionStatus = (id, isOption) => async (dispatch) => {
  const data = await fetchApiData({
    method: "put",
    endpoint: `${ENDPOINT.PRODUCT}/${id}/is-option`,
    data: {
      is_option: isOption,
    },
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const updateProductAnnotationStatus =
  (id, isAnnotation) => async (dispatch) => {
    const data = await fetchApiData({
      method: "put",
      endpoint: `${ENDPOINT.PRODUCT}/${id}/is-annotation`,
      data: {
        is_annotation: isAnnotation,
      },
      dispatch,
    });

    if (data) {
      return data;
    }
  };
