import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ConfirmCancelSaleModal = ({ show, formdata, onSuccess, onHide, ...rest }) => {
  const handleConfirm = () => {
    onSuccess();
    onHide();
  };

  return (
    <Modal show={show} size="md" centered {...rest}>
      <Modal.Body>
        <div className="px-3 py-4">
          <div>
            <div className="text-center fs-22 tc-7 mb-2">
              คุณต้องยืนยันยกเลิกขายสินค้า
            </div>
            <div className="text-center fs-22 tc-7">
              <span style={{ color: "#2DC76D" }}>{formdata?.name}</span> ใช่หรือไม่
            </div>
          </div>
          <div className="text-center pt-4 mt-3 btn-group-control">
            <Button
              type="button"
              className="btn btn-st-3 mx-3 text-decoration-none"
              style={{
                width: "150px",
                background: "#F95057",
                borderColor: "#F95057",
              }}
              onClick={handleConfirm}
            >
              ยกเลิกการขาย
            </Button>
            <Button
              type="button"
              className="btn-st-6 mx-3"
              style={{ width: "150px" }}
              onClick={onHide}
            >
              ไม่ยกเลิก
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmCancelSaleModal;