import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import "react-datepicker/dist/react-datepicker.css";
import ButtonHideOption from "../ButtonHideOption";
import ButtonShowOption from "../ButtonShowOption";
import { ProductSpecificModal } from "../modals";
import { productBranchHandler } from "../../../../../redux/handlers";

export function OptionProductSpecific() {
  const dispatch = useDispatch();
  const { productID } = useParams();
  const product = useSelector((state) => state.product.product);
  const productBranch = useSelector(
    (state) => state.productBranch.productBranch
  );

  const [isOptionEnabled, setIsOptionEnabled] = useState(false);
  const [specificOptions, setSpecificOptions] = useState([]);
  const [productOptionDetail, setProductOptionDetail] = useState({
    id: null,
    optionDetail: null,
  });
  const [editModalConfig, setEditModalConfig] = useState({
    open: false,
    x: 0,
    y: 0,
    width: 560,
    height: 420,
    pin: false,
    mode: "normal",
    modalname: "modal_edit_option_product",
  });

  useEffect(() => {
    setIsOptionEnabled(
      productBranch?.data?.is_specific_option ?? product?.is_specific_option
    );
    if (product?.productSpecificOptionRelation) {
      const baseOptions = product.productSpecificOptionRelation.map(
        (relation) => {
          return {
            id: relation.id,
            name: relation.name,
            selecttype: 0,
            isVisible: true,
            isNewOption: false,
            product_option_detail: relation.productSpecificOptionItem.map(
              (item, index) => ({
                id: item.id,
                name: item.name,
                price: item.price,
                isVisible: true,
                isNewOption: false,
                stock: null,
              })
            ),
          };
        }
      );

      if (productBranch?.data?.data?.product_specific_option_relation) {
        const branchOptions =
          productBranch.data.data.product_specific_option_relation;

        const updatedOptions = baseOptions.map((baseOption) => {
          const matchingBranchOption = branchOptions.find(
            (branchOption) =>
              baseOption.id === branchOption.product_specific_option_id
          );

          if (matchingBranchOption) {
            return {
              ...baseOption,
              isVisible: !matchingBranchOption.is_hidden,
              product_option_detail: baseOption.product_option_detail.map(
                (detail, index) => ({
                  ...detail,
                  isVisible: !matchingBranchOption.is_hidden,
                  price: matchingBranchOption.price || detail.price,
                  index: matchingBranchOption.index || index,
                })
              ),
            };
          }
          return baseOption;
        });

        setSpecificOptions(updatedOptions);
      } else {
        setSpecificOptions(baseOptions);
      }
    }
  }, [product, productBranch]);

  const handleOptionEnableToggle = async (newStatus) => {
    try {
      await dispatch(
        productBranchHandler.updateIsSpecificOption(productID, newStatus)
      );
      setIsOptionEnabled(newStatus);
    } catch (error) {
      console.error("Error updating specific option status:", error);
    }
  };

  const handleOptionVisibilityToggle = async (optionId, isVisible) => {
    try {
      const targetOption = specificOptions.find(
        (option) => option.id === optionId
      );
      if (!targetOption) return;

      const updatePayload = targetOption.product_option_detail.map(
        (optionDetail) => ({
          product_id: productID,
          product_specific_option_id: optionId,
          product_specific_option_item_id: optionDetail.id,
          price: optionDetail.price,
          is_show: !isVisible,
        })
      );

      await dispatch(
        productBranchHandler.updateSpecificOptionRelationToggleHidden(
          productID,
          updatePayload
        )
      );

      setSpecificOptions((currentOptions) =>
        currentOptions.map((option) =>
          option.id === optionId
            ? {
                ...option,
                isVisible: isVisible,
                product_option_detail: option.product_option_detail.map(
                  (detail) => ({
                    ...detail,
                    isVisible: isVisible,
                  })
                ),
              }
            : option
        )
      );
    } catch (error) {
      console.error("Error updating option visibility:", error);
    }
  };

  const handleOptionItemVisibilityToggle = async (
    optionId,
    itemId,
    isVisible
  ) => {
    try {
      const targetOption = specificOptions.find(
        (option) => option.id === optionId
      );
      if (!targetOption) return;

      const targetDetail = targetOption.product_option_detail.find(
        (detail) => detail.id === itemId
      );
      if (!targetDetail) return;

      const updatePayload = {
        product_id: productID,
        product_specific_option_id: optionId,
        product_specific_option_item_id: itemId,
        price: targetDetail.price,
        is_show: !isVisible,
      };

      await dispatch(
        productBranchHandler.updateSpecificOptionRelation(
          productID,
          updatePayload
        )
      );

      setSpecificOptions((currentOptions) =>
        currentOptions.map((option) =>
          option.id === optionId
            ? {
                ...option,
                product_option_detail: option.product_option_detail.map(
                  (detail) =>
                    detail.id === itemId
                      ? {
                          ...detail,
                          isVisible: isVisible,
                        }
                      : detail
                ),
              }
            : option
        )
      );
    } catch (error) {
      console.error("Error updating option item visibility:", error);
    }
  };

  const handleOptionDetailUpdate = async (updatedOptionData) => {
    try {
      const updatePayload = {
        product_id: productID,
        product_specific_option_id: updatedOptionData.id,
        product_specific_option_item_id: updatedOptionData.optionDetail.id,
        price: Number(updatedOptionData.optionDetail.price),
        is_show: updatedOptionData.optionDetail.isVisible,
      };

      await dispatch(
        productBranchHandler.updateSpecificOptionRelation(
          productID,
          updatePayload
        )
      );

      setSpecificOptions((currentOptions) =>
        currentOptions.map((option) =>
          option.id === updatedOptionData.id
            ? {
                ...option,
                product_option_detail: option.product_option_detail.map(
                  (detail) =>
                    detail.id === updatedOptionData.optionDetail.id
                      ? {
                          ...detail,
                          price: updatedOptionData.optionDetail.price,
                          name: updatedOptionData.optionDetail.name,
                          isVisible: updatedOptionData.optionDetail.isVisible,
                        }
                      : detail
                ),
              }
            : option
        )
      );

      setEditModalConfig((currentConfig) => ({
        ...currentConfig,
        open: false,
      }));
    } catch (error) {
      console.error("Error updating option details:", error);
    }
  };

  return (
    <div className="card card-st-3 mb-3">
      <div className="card-body px-xl-3">
        <div className="pt-2 pb-1 px-3">
          <Form.Check
            type="switch"
            id="custom-sdp"
            label="ตัวเลือกเฉพาะสินค้า"
            name="optionstandardStatus"
            className="custom-switch-st-1 s1 mb-1"
            checked={isOptionEnabled}
            onChange={() => handleOptionEnableToggle(!isOptionEnabled)}
          />
          <span className="ms-2" style={{ color: "#858796" }}>
            (เพิ่มตัวเลือกเฉพาะสินค้านี้เท่านั้น เช่น เพิ่มไข่ดาว, Size S M L,
            อื่นๆ)
          </span>
        </div>

        {isOptionEnabled && (
          <div className="px-5 py-4">
            <div className="mt-2 ps-2">
              {specificOptions?.map((item, index) => (
                <div key={item.id}>
                  <div className="row gx-4 align-items-center">
                    <div className="col-xl-6 col-6 d-flex align-items-center">
                      <Form.Group className="mb-2 form-st-3 pb-1 flex-fill">
                        <Form.Label className="mb-1">
                          ตัวเลือกเฉพาะสินค้า
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="optionName"
                          className="form-control"
                          placeholder="ตัวเลือกเฉพาะสินค้า"
                          value={item.name}
                          disabled={true}
                        />
                      </Form.Group>
                    </div>
                    {/* <div className="col-xl-4 col-6">
                      <Form.Check
                        type="switch"
                        id={`custom-switch-product${index}`}
                        label="เลือกได้มากกว่า 1 รายการ"
                        name={`selecttype[${index}]`}
                        className="custom-switch-st-1 tc-10 s1 mb-1"
                        checked={item.selecttype === 1}
                        disabled={true}
                      />
                    </div> */}
                    <div className="col-xl-2 col-6">
                      {item.isVisible ? (
                        <ButtonShowOption
                          handleClick={() =>
                            handleOptionVisibilityToggle(item.id, false)
                          }
                          label={true}
                        />
                      ) : (
                        <ButtonHideOption
                          handleClick={() =>
                            handleOptionVisibilityToggle(item.id, true)
                          }
                          label={true}
                        />
                      )}
                    </div>

                    {item.product_option_detail?.map((item2) => (
                      <div
                        className="col-xxl-6 row gx-4 align-items-center"
                        key={item2.id}
                      >
                        <div className="col">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">รายการ</Form.Label>
                            <Form.Control
                              type="text"
                              name="listName"
                              className="form-control"
                              placeholder="รายการ"
                              value={item2.name}
                              disabled={true}
                            />
                          </Form.Group>
                        </div>
                        <div className="col">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">ราคา</Form.Label>
                            <Form.Control
                              type="text"
                              name="listPrice"
                              className="form-control"
                              placeholder="ราคา"
                              value={item2.price}
                              disabled={true}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-auto">
                          <div
                            className="d-flex align-items-center"
                            style={{ width: "200px" }}
                          >
                            <button
                              type="button"
                              className="btn d-block btn-edit-st-1 mr-2"
                              onClick={() => {
                                setProductOptionDetail({
                                  id: item.id,
                                  optionDetail: item2,
                                });
                                setEditModalConfig((prev) => ({
                                  ...prev,
                                  open: true,
                                }));
                              }}
                            />
                            {item2.isVisible ? (
                              <ButtonShowOption
                                handleClick={() =>
                                  handleOptionItemVisibilityToggle(
                                    item.id,
                                    item2.id,
                                    false
                                  )
                                }
                                label={false}
                              />
                            ) : (
                              <ButtonHideOption
                                handleClick={() =>
                                  handleOptionItemVisibilityToggle(
                                    item.id,
                                    item2.id,
                                    true
                                  )
                                }
                                label={false}
                              />
                            )}
                            <button
                              type="button"
                              className="btn btn-setting-st-2 ml-2"
                              onClick={() => {}}
                            />
                            <span
                              className="fs-14 ml-2"
                              style={{
                                color: item2.stock ? "#2DC76D" : "#B6BBC6",
                                lineHeight: "24px",
                              }}
                            >
                              {item2.stock ? "ตั้งค่าแล้ว" : "ยังไม่ได้ตั้งค่า"}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <ProductSpecificModal
        modal={editModalConfig}
        setModal={setEditModalConfig}
        productOptionDetail={productOptionDetail}
        setProductOptionDetail={setProductOptionDetail}
        onSubmit={handleOptionDetailUpdate}
      />
    </div>
  );
}

export default OptionProductSpecific;
