import Form from "react-bootstrap/Form";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { masterHandler } from "../../../../../redux/handlers";

const District = (props) => {
  const dispatch = useDispatch();
  const districts = useSelector((state) => state.master.districts);
  const { required = false, errors = {}, touched = {} } = props;

  const handleSubDistrict = (districtID) => {
    try {
      dispatch(masterHandler.getSubDistricts(districtID));
    } catch (error) {
      console.error("Error loading sub-districts:", error);
    }
  };

  return (
    <Form.Group className="mb-2 form-st-3 pb-1">
      <Form.Label className="mb-1">
        อำเภอ/เขต <span className="text-danger ms-1">*</span>
      </Form.Label>
      <Select
        as="select"
        name="districtId"
        className="w-100 select-st-1"
        options={
          districts?.map((d) => ({
            value: d.id.toString(),
            label: d.name_th,
          })) || []
        }
        placeholder="เลือกอำเภอ/เขต"
        isDisabled={!props.values.provinceId || props.isReadOnly}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor:
              touched.districtId && errors.districtId
                ? "#dc3545"
                : baseStyles.borderColor,

            "&:hover": {
              borderColor:
                touched.districtId && errors.districtId
                  ? "#dc3545"
                  : baseStyles["&:hover"]?.borderColor,
            },
          }),
        }}
        onChange={(e) => {
          props.setFieldValue("districtId", e.value);
          handleSubDistrict(e.value);
          props.setFieldValue("subDistrictId", "");
        }}
        value={
          districts
            ?.filter((d) => d.id == props.values.districtId)
            .map((d) => ({
              value: d.id.toString(),
              label: d.name_th,
            })) || null
        }
      />
      {touched.districtId && errors.districtId && (
        <div className="invalid-feedback d-block">{errors.districtId}</div>
      )}
    </Form.Group>
  );
};

export default District;
