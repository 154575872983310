import { fetchApiData } from "../../utils/apiUtils";
import { ENDPOINT } from "../../constants/server/api";
import * as branchPaymentAction from "../../redux/slices/branchPayment.slice";

export const createBranchDeliverySetting = (data) => async (dispatch) => {
  const formData = new FormData();

  formData.append("branchId", data.branchId);
  formData.append("name", data.name);
  formData.append("isStatus", data.isStatus);

  if (data.imagePath) {
    if (data.imagePath instanceof File) {
      formData.append("imagePath", data.imagePath);
    } else if (
      typeof data.imagePath === "string" &&
      data.imagePath.startsWith("data:image")
    ) {
      const blob = await fetch(data.imagePath).then((r) => r.blob());
      formData.append("imagePath", blob, "image.jpg");
    }
  }

  const response = await fetchApiData({
    method: "post",
    endpoint: ENDPOINT.BRANCH_DELIVERY,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    dispatch,
  });

  if (response) {
    dispatch(branchPaymentAction.setPaymentFormDelivery(response));
    return response;
  }
};

export const getBranchDeliverySetting = (branchId) => async (dispatch) => {
  const response = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.BRANCH_DELIVERY}/${branchId}`,
    dispatch,
  });

  if (response) {
    dispatch(branchPaymentAction.setPaymentFormDelivery(response));
    return response;
  }
};

export const getBranchDeliverySettings = (branchId) => async (dispatch) => {
  const response = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.BRANCH_DELIVERY}/${branchId}/list`,
    dispatch,
  });

  if (response) {
    dispatch(branchPaymentAction.setPaymentFormDelivery(response));
    return response;
  }
};

export const updateBranchDeliverySetting =
  (branchId, data) => async (dispatch) => {
    const formData = new FormData();

    formData.append("branchId", data.branchId);
    formData.append("name", data.name);
    formData.append("isStatus", data.isStatus);

    if (data.imagePath) {
      if (data.imagePath instanceof File) {
        formData.append("imagePath", data.imagePath);
      } else if (
        typeof data.imagePath === "string" &&
        data.imagePath.startsWith("data:image")
      ) {
        const blob = await fetch(data.imagePath).then((r) => r.blob());
        formData.append("imagePath", blob, "image.jpg");
      }
    }

    const response = await fetchApiData({
      method: "put",
      endpoint: `${ENDPOINT.BRANCH_DELIVERY}/${branchId}`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      dispatch,
    });

    if (response) {
      dispatch(branchPaymentAction.setPaymentFormDelivery(response));
      return response;
    }
  };

export const deleteBranchDeliverySetting = (id) => async (dispatch) => {
  const response = await fetchApiData({
    method: "delete",
    endpoint: `${ENDPOINT.BRANCH_DELIVERY}/${id}`,
    dispatch,
  });

  if (response) {
    dispatch(branchPaymentAction.setPaymentFormDelivery(response));
    return response;
  }
};

export const createBranchCreditSetting = (data) => async (dispatch) => {
  const response = await fetchApiData({
    method: "post",
    endpoint: ENDPOINT.BRANCH_CREDIT,
    data,
    dispatch,
  });

  if (response) {
    dispatch(branchPaymentAction.setPaymentFormCredit(response));
    return response;
  }
};

export const getBranchCreditSetting = (branchId) => async (dispatch) => {
  const response = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.BRANCH_CREDIT}/${branchId}`,
    dispatch,
  });

  if (response) {
    dispatch(branchPaymentAction.setPaymentFormCredit(response));
    return response;
  }
};

export const getBranchCreditSettings = (branchId) => async (dispatch) => {
  const response = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.BRANCH_CREDIT}/${branchId}/list`,
    dispatch,
  });

  if (response) {
    dispatch(branchPaymentAction.setPaymentFormCredit(response));
    return response;
  }
};

export const updateBranchCreditSetting =
  (branchId, data) => async (dispatch) => {
    const response = await fetchApiData({
      method: "put",
      endpoint: `${ENDPOINT.BRANCH_CREDIT}/${branchId}`,
      data,
      dispatch,
    });

    if (response) {
      dispatch(branchPaymentAction.setPaymentFormCredit(response));
      return response;
    }
  };

export const deleteBranchCreditSetting = (id) => async (dispatch) => {
  const response = await fetchApiData({
    method: "delete",
    endpoint: `${ENDPOINT.BRANCH_CREDIT}/${id}`,
    dispatch,
  });

  if (response) {
    dispatch(branchPaymentAction.setPaymentFormCredit(response));
    return response;
  }
};

export const createBranchBankSetting = (data) => async (dispatch) => {
  const response = await fetchApiData({
    method: "post",
    endpoint: ENDPOINT.BRANCH_BANK,
    data,
    dispatch,
  });

  if (response) {
    dispatch(branchPaymentAction.setPaymentFormBank(response));
    return response;
  }
};

export const getBranchBankSetting = (branchId) => async (dispatch) => {
  const response = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.BRANCH_BANK}/${branchId}`,
    dispatch,
  });

  if (response) {
    dispatch(branchPaymentAction.setPaymentFormBank(response));
    return response;
  }
};

export const getBranchBankSettings = (branchId) => async (dispatch) => {
  const response = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.BRANCH_BANK}/${branchId}/list`,
    dispatch,
  });

  if (response) {
    dispatch(branchPaymentAction.setPaymentFormBank(response));
    return response;
  }
};

export const updateBranchBankSetting = (branchId, data) => async (dispatch) => {
  const response = await fetchApiData({
    method: "put",
    endpoint: `${ENDPOINT.BRANCH_BANK}/${branchId}`,
    data,
    dispatch,
  });

  if (response) {
    dispatch(branchPaymentAction.setPaymentFormBank(response));
    return response;
  }
};

export const deleteBranchBankSetting = (id) => async (dispatch) => {
  const response = await fetchApiData({
    method: "delete",
    endpoint: `${ENDPOINT.BRANCH_BANK}/${id}`,
    dispatch,
  });

  if (response) {
    dispatch(branchPaymentAction.setPaymentFormBank(response));
    return response;
  }
};

export const getBranchPaymentSetting = (branchId) => async (dispatch) => {
  const response = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.BRANCH_PAYMENT}/${branchId}`,
    dispatch,
  });

  if (response) {
    dispatch(branchPaymentAction.setBranchPaymentSettings(response));

    const paymentSettings = {
      id: response.id,
      branch_id: response.branch_id,
      is_cash_received: response.is_cash_received,
      is_qrcode_received: response.is_qrcode_received,
      is_bank_received: response.is_bank_received,
      is_delivery_received: response.is_delivery_received,
      is_credit_received: response.is_credit_received,
      is_debtor_received: response.is_debtor_received,
      is_discount_received: response.is_discount_received,
      is_active: response.is_active,
    };

    const qrcodeSettings = {
      qrcode_merchant_id: response.qrcode_merchant_id,
      qrcode_public_id: response.qrcode_public_id,
      qrcode_secret_key_id: response.qrcode_secret_key_id,
      qrcode_token: response.qrcode_token,
    };

    const roundingSettings = {
      rounding_setting_id: response.rounding_setting_id,
    };

    dispatch(branchPaymentAction.setPaymentFormSettings(paymentSettings));
    dispatch(branchPaymentAction.setPaymentFormQRCode(qrcodeSettings));
    dispatch(branchPaymentAction.setPaymentFormRounding(roundingSettings));

    return response;
  }
};

export const updateBranchPaymentSetting =
  (branchId, data) => async (dispatch) => {
    const filteredData = {
      is_cash_received: data.is_cash_received,
      is_qrcode_received: data.is_qrcode_received,
      is_bank_received: data.is_bank_received,
      is_delivery_received: data.is_delivery_received,
      is_credit_received: data.is_credit_received,
      is_debtor_received: data.is_debtor_received,
      is_discount_received: data.is_discount_received,
    };

    const response = await fetchApiData({
      method: "put",
      endpoint: `${ENDPOINT.BRANCH_PAYMENT}/${branchId}`,
      data: filteredData,
      dispatch,
    });

    if (response) {
      dispatch(branchPaymentAction.setPaymentFormSettings(response));
      return response;
    }
  };

export const updateBranchPaymentQRCode =
  (branchId, data) => async (dispatch) => {
    const response = await fetchApiData({
      method: "put",
      endpoint: `${ENDPOINT.BRANCH_PAYMENT}/${branchId}/qrcode`,
      data,
      dispatch,
    });

    if (response) {
      dispatch(branchPaymentAction.setPaymentFormQRCode(response));
      return response;
    }
  };

export const updateBranchPaymentRounding =
  (branchId, data) => async (dispatch) => {
    const response = await fetchApiData({
      method: "put",
      endpoint: `${ENDPOINT.BRANCH_PAYMENT}/${branchId}/rounding`,
      data,
      dispatch,
    });

    if (response) {
      dispatch(branchPaymentAction.setPaymentFormRounding(response));
      return response;
    }
  };
