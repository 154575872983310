export const formatBusinessName = (business) => {
  let businessName = '';
  if (business.entityType.code == 'JURISTIC') {
    businessName += `${business.prefixName?.first_name} ${
      business.company_name
    } ${business.prefixName?.last_name ?? ''} `;
  } else if (business.entityType.code == 'NATURAL') {
    businessName += `${business.prefixName?.first_name} ${business?.first_name} ${business?.last_name}`;
  }
  return businessName;
};

export default formatBusinessName;
