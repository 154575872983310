import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function ProductStatusChangeModal(props) {
  const { formdata, onConfirm, ...rest } = props;

  const handleConfirm = () => {
    onConfirm(formdata.is_status ? "delete" : "recover");
    props.onHide();
  };
  //TODO handle warning usage > 0
  return (
    <Modal {...rest} size="md" centered >
      <Modal.Body>
        <div className="px-3 py-4">
          <div>
            <div>
              <div className="text-center fs-22 tc-7 mb-2">
                <div>รายการนี้ถูกนี้ใช้งานอยู่</div>
                {formdata.is_status
                  ? "คุณต้องยืนยันยกเลิกขายสินค้า"
                  : "คุณต้องการเปิดขายสินค้าอีกครั้ง"}
              </div>
              <div className="text-center fs-22 tc-7">
                {" "}
                <span style={{ color: "#2DC76D" }}>{formdata.name}</span>{" "}
                ใช่หรือไม่
              </div>
            </div>
            <div className="text-center pt-4 mt-3 btn-group-control">
              <Button
                type="button"
                className="btn btn-st-3 mx-3 text-decoration-none"
                style={{
                  width: "150px",
                  background: formdata.is_status ? "#F95057" : "#2DC76D",
                }}
                onClick={handleConfirm}
              >
                {formdata.is_status ? "ยกเลิกการขาย" : "เปิดขายอีกครั้ง"}
              </Button>
              <Button
                type="button"
                className="btn-st-6 mx-3"
                style={{ width: "150px" }}
                onClick={props.onHide}
              >
                {formdata.is_status ? "ไม่ยกเลิก" : "ไม่เปิดขาย"}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
