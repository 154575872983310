import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { HanddleLogin } from "./hook/HanddleLogin";
import { useDispatch } from "react-redux";
import { STATUS } from "../../constants/server/api";
import { useEffect } from "react";
import { businessHandler } from "../../redux/handlers";
import { env } from "../../env";

export function Login() {
  console.log(env);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { businessSlug } = useParams();
  const [businessName, setBusinessName] = useState(null);
  const schema = yup.object().shape({
    email: yup.string().required("กรุณากรอกอีเมลหรือเบอร์โทรศัพท์"),
    password: yup.string().required("กรุณากรอกรหัสผ่าน"),
  });

  const [inputtype, setInputtype] = useState("password");

  const findBusiness = async () => {
    const business = await dispatch(businessHandler.getBusinessBySlug(businessSlug));
    if (business?.business) {
      setBusinessName(business?.business?.businessName);
    }
  };

  useEffect(() => {
    findBusiness();
  }, [dispatch, businessSlug]);

  return (
    <div className="loginpage" style={{ backgroundImage: `url('/images/login_bg.png')` }}>
      <div>
        <Card className=" mb-auto box-login">
          <Card.Body>
            <div className="text-center">
              <Image src="/logo.png" width={150} className="mb-4 mx-auto text-center" />
            </div>
            <div className="text-center fs-26 fw-5 tc-5 mb-4 flex flex-col justify-center">
              <p>Back Office</p>
              <p>({businessName})</p>
            </div>
            <Formik
              enableReinitialize
              initialValues={{ email: "", password: "", remember: false }}
              validationSchema={schema}
              onSubmit={async (values) => {
                let result = await HanddleLogin(dispatch, {
                  email: values.email,
                  password: values.password,
                  businessSlug: businessSlug,
                });
                if (result == STATUS.OK) {
                  navigate(`/${businessSlug}`);
                }
              }}
            >
              {({ handleSubmit, handleChange, touched, isValid, errors, values }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="อีเมลหรือเบอร์โทรศัพท์"
                    onChange={handleChange}
                    isValid={touched.email && !errors.email}
                    isInvalid={!!errors.email}
                    className="form-st-1 mb-0"
                  />
                  {errors.email && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
                  <div className="position-relative mt-3">
                    <Form.Control
                      type={inputtype}
                      name="password"
                      placeholder="รหัสผ่าน"
                      onChange={handleChange}
                      isValid={touched.password && !errors.password}
                      isInvalid={!!errors.password}
                      className="form-st-1 mb-0"
                    />

                    <Button
                      type="button"
                      className="btn btn-change-type-password"
                      onClick={() => (inputtype == "password" ? setInputtype("text") : setInputtype("password"))}
                    ></Button>
                    {errors.password && <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>}
                  </div>

                  <Button type="submit" className="btn-login w-100 my-4">
                    เข้าสู่ระบบ
                  </Button>
                </Form>
              )}
            </Formik>
            <div className="text-center mb-2 pt-2">
              <span className="tc-1 fw-5 me-2">ลืมรหัสผ่าน ? </span>
              <Link to="/forgot-password" className="tc-2 fw-5">
                รีเซ็ตรหัสผ่าน
              </Link>
            </div>

            <div className="text-center fs-14 tc-3 mb-2 d-flex align-items-end justify-content-center">
              <span className="me-2 ">Power By</span>
              <div className="pb-05">
                <Image src="/images/logo_allder.png" width={58} />
              </div>
            </div>

            <div className="text-center fs-14 tc-3 d-flex align-items-end justify-content-center">Backoffice V{env.REACT_APP_VERSION}</div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
