import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import 'react-datepicker/dist/react-datepicker.css';
import { Link, useParams, useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import PackageModal from './PackageModal';
import SalePackageList from './SalePackageList';
export default function AlacartBuffetModel({ salemodel, ...props }) {
  const { branchID } = useParams();

  const [salepackage, setSalepackage] = useState(null);
  const [packageModal, setPackageModal] = useState(false);

  const [formdata, setFormdata] = useState({});

  const getSalePackage = (branchID) => {
    fetch('/json/salepackage.json')
      .then((response) => response.json())
      .then((result) => {
        setSalepackage(result);
      })
      .catch((error) => console.log('error', error));
  };

  useEffect(() => {
    getSalePackage(branchID);
  }, []);

  const [modalPackage, setModalPackage] = useState({
    open: false,
    x: 0,
    y: 0,
    width: 470,
    height: 680,
    pin: false,
    mode: 'normal',
    modalname: 'edit',
  });

  return (
    <>
      {!salepackage ? (
        <div className="card card-st-2">
          <div className="card-body row align-items-end p-0">
            <div className="me-auto p-xl-5 p-4 col-xl-8">
              <div className="fs-24 tc-6 text-center ps-3 mb-3">
                สร้างแพ็คเกจร้านค้าที่เหมาะกับธุรกิจของคุณ 🎉
              </div>
              <div className="fs-14 tc-10 text-center mb-4">
                พร้อมแล้วสำหรับการขาย
                สร้างแพ็คเกจเพื่อเปิดขายบนหน้าร้านออนไลน์ของคุณ
              </div>
              <div className="text-center">
                <Button
                  type="button"
                  className="btn btn btn-st-7 "
                  onClick={() => {
                    setModalPackage((prev) => ({ ...prev, open: true }));
                  }}
                >
                  สร้างแพ็คเกจ
                </Button>
              </div>
            </div>
            <div className="col-xl-4 text-end">
              <img src="/images/image-1.png" width={300} />
            </div>
          </div>
        </div>
      ) : (
        <div className="card card-st-2">
          <div className="card-body px-0 py-3">
            <div className="d-flex align-items-center mb-3 px-4">
              <div className="fs-20 fw-bold tc-8  me-auto ">แพ็คเกจ</div>
              <Button
                type="button"
                className="btn btn btn-st-7 "
                onClick={() => {
                  setModalPackage((prev) => ({ ...prev, open: true }));
                }}
              >
                เพิ่มแพ็คเกจ
              </Button>
            </div>

            <SalePackageList
              salepackage={salepackage}
              packageModalObj={{
                packageModal,
                setPackageModal,
              }}
              modal={modalPackage}
              setModal={setModalPackage}
              formdataObj={{
                formdata,
                setFormdata,
              }}
            />
          </div>
        </div>
      )}

      <PackageModal
        modal={modalPackage}
        setModal={setModalPackage}
        show={packageModal}
        onHide={() => setModalPackage((prev) => ({ ...prev, open: false }))}
        onSuccess={() => setModalPackage((prev) => ({ ...prev, open: false }))}
        formdata={formdata}
      />
    </>
  );
}
