import * as masterAction from "../slices/master.slice";
import { ENDPOINT } from "../../constants/server/api";
import { fetchApiData } from "../../utils/apiUtils";

export const getPrefixNames = (entityTypeCode) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.PREFIX_NAME}/list`,
    params: { entity_type_code: entityTypeCode },
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setPrefixNames(data));
  }
};

export const getPrefixNamesByTypeAndStructure =
  (entityTypeCode, entityStructureCode) => async (dispatch) => {
    const data = await fetchApiData({
      method: "get",
      endpoint: `${ENDPOINT.MASTER.PREFIX_NAME}/list`,
      params: {
        entity_type_code: entityTypeCode,
        entity_structure_code: entityStructureCode,
      },
      dispatch,
      useAuth: false,
    });
    if (data) {
      dispatch(masterAction.setPrefixNames(data));
      return data;
    }
  };

export const getShopProductCategories = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.SHOP_PRODUCT}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setShopProductCategories(data));
  }
};

export const getRestaurantTypes = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.RESTAURANT}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setRestaurantTypes(data));
  }
};

export const getEntityTypes = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.ENTITY}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setEntityTypes(data));
  }
};

export const getProvinces = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.PROVINCE}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setProvinces(data));
    return data;
  }
};

export const getDistricts = (provinceId) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.DISTRICT}/list`,
    params: { province_id: provinceId },
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setDistricts(data));
    return data;
  }
};

export const getSubDistricts = (districtId) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.SUB_DISTRICT}/list`,
    params: { district_id: districtId },
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setSubDistricts(data));
    return data;
  }
};

export const getCurrencies = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.CURRENCY}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setCurrencies(data));
  }
};

export const getVatCalculations = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.VAT}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setVatCalculations(data));
  }
};

export const getCodeSettingDayList = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.CODE_SETTING_DAY}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setCodeSettingDays(data));
  }
};

export const getCodeSettingMonthList = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.CODE_SETTING_MONTH}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setCodeSettingMonths(data));
  }
};

export const getCodeSettingYearList = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.CODE_SETTING_YEAR}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setCodeSettingYears(data));
  }
};

export const getCounterSettingMaster = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.COUNTER_SETTING}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setCounterSettingMaster(data));
  }
};

export const getCountUnitTypes = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.COUNT_UNIT_TYPE}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setCountUnitTypes(data));
  }
};

export const getEmployeeStatuses = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.EMPLOYEE_STATUS}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setEmployeeStatuses(data));
  }
};

export const getEmployeeTypes = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.EMPLOYEE_TYPE}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setEmployeeTypes(data));
  }
};

export const getMaritalStatuses = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.MARITAL}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setMaritalStatuses(data));
  }
};

export const getMilitaryStatuses = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.MILITARY}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setMilitaryStatuses(data));
  }
};

export const getRelationshipTypes = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.RELATIONSHIP}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setRelationshipTypes(data));
  }
};

export const getSalaryTypes = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.SALARY_TYPE}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setSalaryTypes(data));
  }
};

export const getTaxBranches = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.TAX_BRANCH}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setTaxBranches(data));
  }
};

export const getVatTypes = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.VAT_TYPE}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setVatTypes(data));
  }
};

export const getAccountTypes = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.ACCOUNT_TYPE}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setAccountTypes(data));
  }
};

export const getBanks = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.BANK}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setBanks(data));
  }
};

export const getGenders = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.GENDER}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setGenders(data));
  }
};

export const getBusinessRoles = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.BUSINESS_ROLE}/list`,
    dispatch,
    useAuth: true,
  });

  if (data) {
    dispatch(masterAction.setBusinessRoles(data));
  }
};

export const getPermission = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.PERMISSION}?packageType=BUSINESS_PERMISSION`,
    dispatch,
    useAuth: true,
  });

  if (data) {
    dispatch(masterAction.setPermissions(data));
  }
};

export const getEmployeeDepartmentAll =
  ({ businessSlug }) =>
  async (dispatch) => {
    const data = await fetchApiData({
      method: "get",
      endpoint: `${ENDPOINT.EMPLOYEE_DEPARTMENT}/all/${businessSlug}/list`,
      dispatch,
    });

    if (data) {
      dispatch(masterAction.setEmployeeDepartments(data));
    }
  };

export const getEmployeePositionAll =
  ({ businessSlug }) =>
  async (dispatch) => {
    const data = await fetchApiData({
      method: "get",
      endpoint: `${ENDPOINT.EMPLOYEE_POSITION}/all/${businessSlug}/list`,
      dispatch,
    });

    if (data) {
      dispatch(masterAction.setEmployeePositions(data));
    }
  };

export const getRoundingSettings = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.ROUNDING_SETTING}/list`,
    dispatch,
  });

  if (data) {
    dispatch(masterAction.setRoundingList(data));
    return data;
  }
};

export const getEntityStructures = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.ENTITY_STRUCTURE}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setEntityStructures(data));
    return data;
  }
};

export const getEntityStructureById = (id) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.MASTER.ENTITY_STRUCTURE}/${id}`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setCurrentEntityStructure(data));
    return data;
  }
};

export const getEntityStructuresByEntityType =
  (entityTypeCode) => async (dispatch) => {
    const data = await fetchApiData({
      method: "get",
      endpoint: `${ENDPOINT.MASTER.ENTITY_STRUCTURE}/list`,
      params: { entity_type_code: entityTypeCode },
      dispatch,
      useAuth: false,
    });
    if (data) {
      dispatch(masterAction.setEntityStructuresByType(data));
      return data;
    }
  };
