import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TextField, MenuItem, Card } from "@mui/material";
import dayjs from "dayjs";

import { createTableColumns } from "../../../../utils/createTableColumns";
import { useGlobalTable } from "../../../../hooks/useGlobalTable";
import AllderTable from "../../../../components/AllderTable";
import { productHandler } from "../../../../redux/handlers";

const TYPE_ACTION_OPTIONS = [
  { value: "ALL", label: "ทั้งหมด" },
  { value: "CREATE", label: "สร้าง" },
  { value: "UPDATE", label: "แก้ไข" },
  { value: "DELETE", label: "ลบ" },
];

const TYPE_MAP = {
  CREATE: "สร้าง",
  UPDATE: "แก้ไข",
  DELETE: "ลบ",
};

const TEXTFIELD_STYLES = {
  InputProps: { sx: { borderRadius: "7px" } },
  sx: {
    ".Mui-disabled": {
      background: "#F8F8F9",
    },
  },
  className: "w-[200px] !ml",
  size: "small",
};

export function ProductHistory() {
  const { businessSlug } = useParams();
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState("ALL");

  const fetchHistoryData = useCallback(
    (params) => {
      const queryParams = {
        ...params,
        business_slug: businessSlug,
        type: actionType !== "ALL" ? actionType : undefined,
      };
      return dispatch(productHandler.getProductLogs(queryParams));
    },
    [dispatch, businessSlug, actionType]
  );

  const {
    data: historyData,
    loading,
    totalRows,
    paginationModel,
    setPaginationModel,
    handleSort,
  } = useGlobalTable({
    fetchData: fetchHistoryData,
    defaultSort: "created_at",
    defaultOrder: "desc",
    defaultPageSize: 20,
  });

  const handleExport = () => {
    const params = {
      business_slug: businessSlug,
      type: actionType !== "ALL" ? actionType : undefined,
    };
    dispatch(productHandler.exportProductLogsToExcel(params));
  };

  const columns = createTableColumns([
    {
      field: "created_at",
      headerName: "วันที่",
      width: 180,
      sortable: true,
      renderCell: ({ row }) => (
        <div className="text-[#2DC76D]">
          {dayjs(row.created_at).format("DD/MM/YYYY HH:mm")} น.
        </div>
      ),
    },
    {
      field: "type",
      headerName: "LOG",
      width: 120,
      sortable: false,
      renderCell: ({ row }) => <div>{TYPE_MAP[row.type] || row.type}</div>,
    },
    {
      field: "detail",
      headerName: "รายละเอียด",
      flex: 1,
      minWidth: 300,
      sortable: false,
      renderCell: ({ row }) => (
        <div dangerouslySetInnerHTML={{ __html: row.detail }} />
      ),
    },
    {
      field: "user",
      headerName: "สมาชิก",
      width: 250,
      sortable: false,
      renderCell: ({ row }) => {
        const user = row.user;
        if (!user) return "-";

        return (
          <div className="d-flex align-items-center">
            <span>
              {`${user.prefixName?.name || ""} ${user.first_name || ""} ${
                user.last_name || ""
              }`}
            </span>
          </div>
        );
      },
    },
  ]);

  return (
    <Card className="!rounded-lg mb-4">
      <div className="py-4">
        <div className="d-flex align-items-center px-4 mb-3 flex-wrap">
          <div className="me-3 mb-2" style={{ flex: "0 0 200px" }}>
            <TextField
              {...TEXTFIELD_STYLES}
              select
              label="เลือกดูตามประเภท"
              value={actionType}
              onChange={(e) => setActionType(e.target.value)}
              className="me-auto w-[200px]"
            >
              {TYPE_ACTION_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <button
            className="btn btn-export-st-1 mb-2 me-auto"
            onClick={handleExport}
          >
            Export
          </button>
        </div>

        <AllderTable
          data={historyData}
          loading={loading}
          columns={columns}
          totalRows={totalRows}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          handleSort={handleSort}
        />
      </div>
    </Card>
  );
}
