import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { useGlobalTable } from "../../../hooks/useGlobalTable";
import { createTableColumns } from "../../../utils/createTableColumns";
import { AllderTable } from "../../../components/AllderTable";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import { productHandler, userBusinessGroupHandler, productCategoryHandler } from "../../../redux/handlers";
import { PaginatedItems } from "./components/PaginatedItems";
import { useBreadcrumb } from "../../../hooks/useBreadcrumb";
import Breadcrumb from "../../../components/Breadcrumb";

export function ProductManagement() {
  const dispatch = useDispatch();
  const { businessSlug } = useParams();

  const fetchUserProfile = async () => {
    try {
      const response = await dispatch(userBusinessGroupHandler.getUserBusinessProfile());
      let vat = "";
      if (response.business) {
        vat = response.business.setting_vat_percen;
      } else if (response.businessGroup?.business.length > 0) {
        const business = response.businessGroup.business.find((e) => e.slug === businessSlug);
        if (business) {
          vat = business.setting_vat_percen;
        }
      }
      setVatPercent(vat);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await dispatch(productCategoryHandler.getAllProductCategories(businessSlug));
      if (response) {
        const options = [
          { value: "all", label: "ทั้งหมด" },
          ...response.map((category) => ({
            value: category.id,
            label: category.name,
          })),
        ];
        setOptionsCategory(options);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchProductCounts = async () => {
    try {
      const activeProducts = await dispatch(
        productHandler.getProductList({
          businessSlug: businessSlug,
          page: 1,
          per_page: 1,
          productStatus: "active",
        })
      );

      const inactiveProducts = await dispatch(
        productHandler.getProductList({
          businessSlug: businessSlug,
          page: 1,
          per_page: 1,
          productStatus: "inactive",
        })
      );

      setProductCounts({
        active: activeProducts?.meta?.total || 0,
        inactive: inactiveProducts?.meta?.total || 0,
      });
    } catch (error) {
      console.error("Error fetching product counts:", error);
    }
  };

  const fetchProducts = useCallback(
    (params) =>
      dispatch(
        productHandler.getProductList({
          businessSlug: businessSlug,
          ...params,
        })
      ),
    [businessSlug, dispatch]
  );

  const breadcrumbConfig = [{ label: "หน้าหลัก", path: "/" }, { label: "สินค้าและบริการ", path: "/products" }, { label: "จัดการข้อมูลสินค้า" }];

  useBreadcrumb(breadcrumbConfig);
  const {
    data: products,
    loading,
    totalRows,
    paginationModel,
    setPaginationModel,
    handleSort,
    debouncedSearch,
    filterParams,
    setFilterParams,
  } = useGlobalTable({
    fetchData: fetchProducts,
    defaultSort: "created_at",
    defaultOrder: "desc",
    defaultPageSize: 20,
  });

  const [btnproductstatus, setBtnproductstatus] = useState("active");
  const [optionsCategory, setOptionsCategory] = useState([]);
  const [displayproduct, setDisplayproduct] = useState(0);
  const [vatPercent, setVatPercent] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [productCounts, setProductCounts] = useState({
    active: 0,
    inactive: 0,
  });

  useEffect(() => {
    fetchUserProfile();
    fetchCategories();
    fetchProductCounts();
  }, []);
  useEffect(() => {
    console.log(vatPercent);
  }, [vatPercent]);

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
    setFilterParams((prev) => ({
      ...prev,
      productCategoryId: selectedOption?.value === "all" ? "" : selectedOption?.value,
    }));
  };

  const handleExport = async () => {
    try {
      await dispatch(productHandler.exportProductsToExcel(businessSlug, filterParams.s));
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const handleImageError = (e) => {
    e.target.onerror = null;
    e.target.src = "/images/img-default.jpeg";
  };

  const columns = createTableColumns(
    [
      {
        field: "sku",
        headerName: "รหัสสินค้า",
        width: 160,
        sortable: true,
        renderCell: ({ row }) => (
          <div
            style={{
              color: "#2DC76D",
              fontWeight: 500,
              cursor: "pointer",
            }}
          >
            {row.sku}
          </div>
        ),
      },
      {
        field: "name",
        headerName: "ชื่อสินค้า",
        flex: 1,
        minWidth: 350,
        sortable: true,
        renderCell: ({ row }) => (
          <div className="pimage">
            {row.image_path ? (
              <img src={row.image_path} alt={row.name || "Product image"} onError={handleImageError} />
            ) : (
              <div className="text-center mr-2 mt-1">
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    backgroundColor: "#F4F4F4",
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span style={{ color: "#6D788D" }}>{row.name ? row.name.slice(0, 1).toUpperCase() : "P"}</span>
                </div>
              </div>
            )}
            <div>
              <div className="text-success fw-medium mb-1">{row.name}</div>
              <div className="fs-12">{row.sku}</div>
            </div>
          </div>
        ),
      },
      {
        field: "productCategory",
        headerName: "หมวดหมู่หลัก",
        width: 180,
        sortable: false,
        renderCell: ({ row }) => <div>{row.productCategory?.name || ""}</div>,
      },
      {
        field: "productSubCategory",
        headerName: "หมวดหมู่ย่อย",
        width: 180,
        sortable: false,
        renderCell: ({ row }) => <div>{row.productSubCategory?.name || ""}</div>,
      },
      {
        field: "product_vat_type",
        headerName: "ภาษี",
        width: 100,
        sortable: true,
        renderCell: ({ row }) => {
          const vatTypeLabel = {
            NO_VAT: "ไม่มีภาษี",
            ZERO_VAT: "0%",
            HAS_VAT: `${vatPercent}%`,
          };
          return vatTypeLabel[row.product_vat_type] || row.product_vat_type;
        },
      },
      {
        field: "price",
        headerName: "ราคา",
        width: 140,
        sortable: true,
      },
      {
        field: "is_available",
        headerName: "สถานะ",
        width: 130,
        sortable: true,
        renderCell: ({ row }) => (
          <div>
            <div className={`product-status status${row.is_available ? 1 : 0}`}></div>
          </div>
        ),
      },
      {
        field: "actions",
        headerName: "จัดการ",
        width: 90,
        sortable: false,
        type: "actions",
      },
    ],
    (row) => (
      <div>
        <Link to={`/${businessSlug}/products/${row.product_type === "SET" ? "pset/" : ""}edit/${row.id}`} className="btn btn-edit-st-1" />
      </div>
    )
  );

  const dataWithIndex = products?.map((item, index) => ({
    ...item,
    index: index + 1,
  }));

  return (
    <div>
      <Breadcrumb />
      <div className="mb-3">
        <Button
          type="button"
          className={btnproductstatus === "active" ? "btn-category px-4 active" : "btn-category px-4"}
          onClick={() => {
            setBtnproductstatus("active");
            setFilterParams((prev) => ({
              ...prev,
              productStatus: "active",
            }));
          }}
        >
          สินค้าทั้งหมด (<span>{productCounts.active}</span>)
        </Button>
        <Button
          type="button"
          className={btnproductstatus === "inactive" ? "btn-category px-4 active" : "btn-category px-4"}
          onClick={() => {
            setBtnproductstatus("inactive");
            setFilterParams((prev) => ({
              ...prev,
              productStatus: "inactive",
            }));
          }}
        >
          สินค้าที่ยกเลิก (<span>{productCounts.inactive}</span>)
        </Button>
      </div>

      <div className="card card-st-2">
        <div className="card-body px-0 py-4">
          <div className="d-flex align-items-center px-4 mb-4">
            <button className={`btn btn-display-product d1 ${displayproduct === 0 ? "active" : ""} mb-2 me-2`} onClick={() => setDisplayproduct(0)} />
            <button className={`btn btn-display-product d2 ${displayproduct === 1 ? "active" : ""} mb-2 me-2`} onClick={() => setDisplayproduct(1)} />

            <div className="me-3 mb-2" style={{ flex: "0 0 200px" }}>
              <Select
                name="category"
                value={selectedCategory}
                onChange={handleCategoryChange}
                className="w-100 select-st-1 pd"
                options={optionsCategory}
                placeholder="เลือกหมวดหมู่"
              />
            </div>

            <button className="btn btn-export-st-1 mb-2 me-auto" onClick={handleExport}>
              Export
            </button>

            <div className="me-3 mb-2">
              <input type="text" className="form-control form-st-4" placeholder="ค้นหารหัสสินค้า ชื่อสินค้า" onChange={(e) => debouncedSearch(e.target.value)} />
            </div>

            <Link to={`/${businessSlug}/products/pset/create`} className="btn mb-2 btn-st-7 px-4 me-3" style={{ backgroundColor: "#FF731D" }}>
              เพิ่มสินค้าจัดชุด
            </Link>

            <Link to={`/${businessSlug}/products/create`} className="btn btn-st-7 mb-2 px-4">
              เพิ่มสินค้า
            </Link>
          </div>

          {displayproduct === 0 ? (
            <AllderTable
              data={dataWithIndex}
              loading={loading}
              columns={columns}
              totalRows={totalRows}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              handleSort={handleSort}
            />
          ) : (
            <PaginatedItems fetchData={fetchProducts} itemsPerPage={10} filterParams={filterParams} loading={loading} totalRows={totalRows} />
          )}
        </div>
      </div>
    </div>
  );
}
