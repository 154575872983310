import React from "react";
import { Link, useNavigate } from "react-router-dom";

export function ChangeBusinessSlug() {
  const getSlug = () => {
    const urlParts = window.location.href.split("/").filter(Boolean);
    return urlParts[2];
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center min-vh-100 bg-light">
      <div className="text-center py-5 px-28 bg-white rounded shadow">
        <h2 className="h3 mb-4"> คุณต้องการใช้ธุรกิจชื่อ {getSlug()}</h2>
        <Link
          onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}
          className="btn btn-primary px-4 py-2"
        >
          เข้าสู่ระบบ
        </Link>
      </div>
    </div>
  );
}
