import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userBusinessGroupHandler } from "../redux/handlers";
import { useState, useEffect } from "react";
import { NotFound } from "../pages/NotFound";
import { ChangeBusinessSlug } from "../pages/ChangeBusinessSlug";

const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const slug = getSlug();

  const [isShowPageLogin, setIsShowPageLogin] = useState(false);
  const [isShowPageChangeBusinessSlug, setIsShowPageChangeBusinessSlug] =
    useState(false);
  const [isShowNotFound, setIsShowNotFound] = useState(false);

  useEffect(() => {
    const checkBusinessGuardAccess = async () => {
      let response = null;
      if (token) {
        response = await dispatch(
          userBusinessGroupHandler.checkBusinessGuardAuth(slug)
        );
      } else {
        response = await dispatch(
          userBusinessGroupHandler.checkBusinessGuard(slug)
        );
      }

      if (!response) {
        setIsShowNotFound(true);
        return;
      }

      if (
        token &&
        response.business_unauthorized &&
        response.business == null
      ) {
        setIsShowPageChangeBusinessSlug(true);
      }

      if (!token && response.business) {
        setIsShowPageLogin(true);
      }
    };

    checkBusinessGuardAccess();
  }, [dispatch, slug, token]);

  if (isShowNotFound) {
    return <NotFound />;
  }

  if (isShowPageChangeBusinessSlug) {
    return <ChangeBusinessSlug />;
  }

  if (isShowPageLogin) {
    return <Navigate to={`/${slug}/login`} replace />;
  }

  return children;
};

const getSlug = () => {
  const urlParts = window.location.href.split("/").filter(Boolean);
  return urlParts[2];
};

export default ProtectedRoute;
