import React, { useState, useEffect, useCallback } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { settingBusinessHandler } from "../../../../redux/handlers";
import { useParams } from "react-router-dom";

const URL_REGEX =
  /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
const PHONE_REGEX = /^[0-9]+$/;
const MOBILE_PHONE_REGEX = /^0[689]\d{8}$/;
const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const LINE_ID_REGEX = /^[@\w\d_-]+$/;

const defaultValues = {
  contactTel: "",
  contactPhoneNumber: "",
  contactEmail: "",
  contactWebsite: "",
  contactFacebook: "",
  contactLine: "",
  contactGooglemapUrl: "",
};

const validationSchema = yup.object().shape({
  contactTel: yup
    .string()
    .matches(PHONE_REGEX, "กรุณากรอกเบอร์โทรศัพท์เป็นตัวเลขเท่านั้น")
    .min(9, "เบอร์โทรศัพท์ต้องมีอย่างน้อย 9 หลัก")
    .max(10, "เบอร์โทรศัพท์ต้องไม่เกิน 10 หลัก"),

  contactPhoneNumber: yup
    .string()
    .test("phone-format", "รูปแบบเบอร์มือถือไม่ถูกต้อง", (value) => {
      if (!value) return true;
      const digitsOnly = value.replace(/\D/g, "");
      return MOBILE_PHONE_REGEX.test(digitsOnly);
    })
    .transform((value) => (value ? value.replace(/\D/g, "") : value)),

  contactEmail: yup
    .string()
    .required("กรุณากรอกอีเมล")
    .email("รูปแบบอีเมลไม่ถูกต้อง")
    .matches(EMAIL_REGEX, "รูปแบบอีเมลไม่ถูกต้อง")
    .min(5, "อีเมลต้องมีความยาวอย่างน้อย 5 ตัวอักษร")
    .max(255, "อีเมลต้องมีความยาวไม่เกิน 255 ตัวอักษร")
    .test(
      "no-spaces",
      "อีเมลต้องไม่มีช่องว่าง",
      (value) => !value?.includes(" ")
    )
    .test("valid-tld", "โดเมนไม่ถูกต้อง", (value) => {
      if (!value) return true;
      const tld = value.split(".").pop();
      return tld && tld.length >= 2;
    }),

  contactWebsite: yup
    .string()
    .nullable()
    .matches(
      URL_REGEX,
      "รูปแบบ URL ไม่ถูกต้อง (ตัวอย่าง: https://www.example.com)"
    ),

  contactFacebook: yup
    .string()
    .nullable()
    .matches(
      URL_REGEX,
      "รูปแบบ URL ไม่ถูกต้อง (ตัวอย่าง: https://www.facebook.com/yourpage)"
    ),

  contactLine: yup
    .string()
    .nullable()
    .matches(
      LINE_ID_REGEX,
      "รูปแบบ Line ID ไม่ถูกต้อง (ใช้ตัวอักษร, ตัวเลข, _, - หรือ @ เท่านั้น)"
    ),

  contactGooglemapUrl: yup
    .string()
    .nullable()
    .matches(
      URL_REGEX,
      "รูปแบบ URL ไม่ถูกต้อง (ตัวอย่าง: https://goo.gl/maps/...)"
    ),
});

const BusinessSettingsTabContact = ({ formDisabled, setFormDisabled }) => {
  const dispatch = useDispatch();
  const { businessSlug } = useParams();
  const selectedBusiness = useSelector(
    (state) => state.business.selectedBusiness?.business
  );
  const [initialValues, setInitialValues] = useState(defaultValues);

  const formatPhoneNumber = useCallback((value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/\D/g, "");
    if (phoneNumber.length < 4) return phoneNumber;
    if (phoneNumber.length < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }, []);

  useEffect(() => {
    if (!selectedBusiness) return;

    const {
      contact_tel,
      contact_phone_number,
      contact_email,
      contact_website,
      contact_facebook,
      contact_line,
      contact_googlemap_url,
    } = selectedBusiness;

    setInitialValues({
      contactTel: contact_tel || "",
      contactPhoneNumber: contact_phone_number || "",
      contactEmail: contact_email || "",
      contactWebsite: contact_website || "",
      contactFacebook: contact_facebook || "",
      contactLine: contact_line || "",
      contactGooglemapUrl: contact_googlemap_url || "",
    });
  }, [selectedBusiness]);

  const handleSubmit = async (values) => {
    try {
      const result = await dispatch(
        settingBusinessHandler.updateBusinessSlugContact(businessSlug, values)
      );
      if (result === "OK") {
        setFormDisabled(true);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const renderField = ({
    name,
    label,
    type = "text",
    placeholder,
    value,
    onChange,
    error,
    maxLength,
    required,
  }) => (
    <Form.Group className="mb-2 form-st-3 pb-1">
      <Form.Label className="mb-1">
        {label} {required && <span className="text-danger">*</span>}
      </Form.Label>
      <Form.Control
        type={type}
        name={name}
        className="form-control"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        isInvalid={!!error}
        maxLength={maxLength}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, handleChange, errors, values, setFieldValue }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <fieldset disabled={formDisabled}>
            <div className="card-body px-xl-4">
              <div className="py-3">
                <div className="row gx-xl-4">
                  <div className="col-xl-6">
                    {renderField({
                      name: "contactTel",
                      label: "เบอร์โทรศัพท์",
                      placeholder: "กรอกเบอร์โทรศัพท์",
                      value: values.contactTel,
                      onChange: handleChange,
                      error: errors.contactTel,
                    })}
                  </div>

                  <div className="col-xl-6">
                    {renderField({
                      name: "contactPhoneNumber",
                      label: "เบอร์มือถือ",
                      placeholder: "กรอกเบอร์มือถือ",
                      value: formatPhoneNumber(values.contactPhoneNumber),
                      onChange: (e) => {
                        const value = e.target.value.replace(/\D/g, "");
                        setFieldValue("contactPhoneNumber", value);
                      },
                      error: errors.contactPhoneNumber,
                      maxLength: 12,
                    })}
                  </div>

                  <div className="col-xl-6">
                    {renderField({
                      name: "contactEmail",
                      label: "E-mail",
                      type: "email",
                      placeholder: "E-mail",
                      value: values.contactEmail,
                      onChange: handleChange,
                      error: errors.contactEmail,
                      required: true,
                    })}
                  </div>

                  <div className="col-xl-6">
                    {renderField({
                      name: "contactWebsite",
                      label: "Website",
                      placeholder: "Website",
                      value: values.contactWebsite,
                      onChange: handleChange,
                      error: errors.contactWebsite,
                    })}
                  </div>

                  <div className="col-xl-6">
                    {renderField({
                      name: "contactFacebook",
                      label: "Facebook",
                      placeholder: "Facebook",
                      value: values.contactFacebook,
                      onChange: handleChange,
                      error: errors.contactFacebook,
                    })}
                  </div>

                  <div className="col-xl-6">
                    {renderField({
                      name: "contactLine",
                      label: "Line",
                      placeholder: "Line",
                      value: values.contactLine,
                      onChange: handleChange,
                      error: errors.contactLine,
                    })}
                  </div>

                  <div className="col-xl-12">
                    {renderField({
                      name: "contactGooglemapUrl",
                      label: "URL Google Map",
                      placeholder: "URL Google Map",
                      value: values.contactGooglemapUrl,
                      onChange: handleChange,
                      error: errors.contactGooglemapUrl,
                    })}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <div className="text-end pb-4 pe-3">
            {formDisabled ? (
              <Button
                type="button"
                className="btn-st-3 mx-3"
                onClick={() => setFormDisabled(false)}
              >
                แก้ไข
              </Button>
            ) : (
              <div className="text-end pt-3 btn-group-control">
                <Button
                  type="button"
                  className="btn-st-5 me-3"
                  onClick={() => setFormDisabled(true)}
                >
                  ยกเลิก
                </Button>
                <Button type="submit" className="btn-st-3">
                  บันทึก
                </Button>
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default BusinessSettingsTabContact;
