import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    data: [],
  },
  getById: [],
  createResponse: [],
  getListItem: [],
};

const productStandard = createSlice({
  name: "productStandard",
  initialState,
  reducers: {
    resetState: () => initialState,
    setData: (state, action) => {
      state.data = action.payload;
    },
    getById: (state, action) => {
      state.getById = action.payload;
    },
    deleteItem: (state, action) => {
      state.data.data = state.data.data.filter(
        (item) => item.id !== action.payload
      );
    },
    deleteListItem: (state, action) => {
      state.getListItem = state.getListItem.filter(
        (item) => item.id !== action.payload
      );
    },
    setCreateResponse: (state, action) => {
      state.createResponse = action.payload;
    },
    setListItem: (state, action) => {
      state.getListItem = action.payload;
    },
  },
});

export const {
  getById,
  setData,
  deleteItem,
  setCreateResponse,
  setListItem,
  resetState,
  deleteListItem,
} = productStandard.actions;

export default productStandard.reducer;
