import { ENDPOINT, STATUS } from "../../constants/server/api";
import { fetchApiData } from "../../utils/apiUtils";

export const getProductCategories = (params) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.PRODUCT_CATEGORY}/pagination/list`,
    params: {
      business_slug: params.business_slug,
      page: params.page,
      perPage: params.per_page,
      s: params.s,
      sortBy: params.sortBy,
      sortOrder: params.sortOrder,
    },
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const getAllProductCategories = (businessSlug) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.PRODUCT_CATEGORY}/all/list`,
    params: {
      business_slug: businessSlug,
    },
    dispatch,
  });
  return data;
};

export const getProductCategoryById = (id) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.PRODUCT_CATEGORY}/${id}`,
    dispatch,
  });
  return data;
};

export const createProductCategory = (categoryData) => async (dispatch) => {
  const data = await fetchApiData({
    method: "post",
    endpoint: ENDPOINT.PRODUCT_CATEGORY,
    data: categoryData,
    dispatch,
  });
  return data;
};

export const updateProductCategory = (id, categoryData) => async (dispatch) => {
  const data = await fetchApiData({
    method: "put",
    endpoint: `${ENDPOINT.PRODUCT_CATEGORY}/${id}`,
    data: categoryData,
    dispatch,
  });
  return data;
};

export const deleteProductCategory = (id) => async (dispatch) => {
  await fetchApiData({
    method: "delete",
    endpoint: `${ENDPOINT.PRODUCT_CATEGORY}/${id}`,
    dispatch,
  });
  return STATUS.OK;
};

export const exportProductCategoriesToExcel =
  (businessSlug, searchTerm) => async (dispatch) => {
    const data = await fetchApiData({
      method: "post",
      endpoint: `${ENDPOINT.PRODUCT_CATEGORY}/excel`,
      params: {
        business_slug: businessSlug,
        s: searchTerm || "",
      },
      responseType: "blob",
      dispatch,
    });

    if (data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "รายการหมวดหมู่สินค้าหลัก.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }
  };

export const getProductSubCategories = (params) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.PRODUCT_SUB_CATEGORY}/pagination/list`,
    params: {
      business_slug: params.business_slug,
      page: params.page,
      perPage: params.per_page,
      s: params.s,
      sortBy: params.sortBy,
      sortOrder: params.sortOrder,
      business_id: params.business_id,
      product_category_id: params.product_category_id,
      pageQuery: params.pageQuery || "",
      branch_id: params.branch_id || "",
      branch_kitchen_id: params.branch_kitchen_id || "",
    },
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const getAllProductSubCategories =
  (businessSlug, searchTerm = "") =>
  async (dispatch) => {
    const data = await fetchApiData({
      method: "get",
      endpoint: `${ENDPOINT.PRODUCT_SUB_CATEGORY}/all/list`,
      params: {
        business_slug: businessSlug,
        s: searchTerm,
      },
      dispatch,
    });
    return data;
  };

export const getAllProductSubCategoriesByMainCatId =
  (businessSlug, productCategoryId) => async (dispatch) => {
    const data = await fetchApiData({
      method: "get",
      endpoint: `${ENDPOINT.PRODUCT_SUB_CATEGORY}/all/list`,
      params: {
        business_slug: businessSlug,
        product_category_id: productCategoryId,
      },
      dispatch,
    });
    return data;
  };

export const getProductSubCategoryById = (id) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.PRODUCT_SUB_CATEGORY}/${id}`,
    dispatch,
  });
  return data;
};

export const createProductSubCategory =
  (subCategoryData) => async (dispatch) => {
    const data = await fetchApiData({
      method: "post",
      endpoint: ENDPOINT.PRODUCT_SUB_CATEGORY,
      data: subCategoryData,
      dispatch,
    });
    return data;
  };

export const updateProductSubCategory =
  (id, subCategoryData) => async (dispatch) => {
    const data = await fetchApiData({
      method: "put",
      endpoint: `${ENDPOINT.PRODUCT_SUB_CATEGORY}/${id}`,
      data: subCategoryData,
      dispatch,
    });
    return data;
  };

export const deleteProductSubCategory = (id) => async (dispatch) => {
  await fetchApiData({
    method: "delete",
    endpoint: `${ENDPOINT.PRODUCT_SUB_CATEGORY}/${id}`,
    dispatch,
  });
  return STATUS.OK;
};

export const exportProductSubCategoriesToExcel =
  (businessSlug, searchTerm) => async (dispatch) => {
    const data = await fetchApiData({
      method: "post",
      endpoint: `${ENDPOINT.PRODUCT_SUB_CATEGORY}/excel`,
      params: {
        business_slug: businessSlug,
        s: searchTerm || "",
      },
      responseType: "blob",
      dispatch,
    });

    if (data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "รายการหมวดหมู่สินค้าย่อย.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }
  };
