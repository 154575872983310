import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import { Link, useParams, NavLink } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Collapse from "react-bootstrap/Collapse";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AutoTabProvider } from "react-auto-tab";
import {
  ThailandAddressTypeahead,
  ThailandAddressValue,
} from "react-thailand-address-typeahead";

import DataTableLayout from "layouts/DatatableLayout";
import Imageupload from "fragments/ImageUpload";

import DownloadExcel from "utils/DownloadExcel";
import { ModalAllder } from "layouts/ModalAllder";
import { DataGrid } from "@mui/x-data-grid";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

export function Customer() {
  const [customerdata, setCustomerdata] = useState([]);
  const getcustomerData = () => {
    fetch("/json/customer.json")
      .then((response) => response.json())
      .then((result) => {
        setCustomerdata(result);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getcustomerData();
  }, []);

  const columnsd = [
    {
      field: "customerCode",
      headerName: "เลขที่สมาชิก",
      width: 170,
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <Link
          to={`/customer/edit/${row.customerID}`}
          style={{ color: "#2DC76D", fontWeight: 500 }}
        >
          {row.customerCode}
        </Link>
      ),
    },

    {
      field: "customerName",
      headerName: "ชื่อสมาชิก",
      flex: 1,
      width: 140,
      sortable: false,
    },
    {
      field: "customerPhoneNumber",
      headerName: "เบอร์โทรศัพท์",
      flex: 1,
      width: 140,
      sortable: false,
    },
    {
      field: "customerEmail",
      headerName: "E-mail",
      flex: 1,
      width: 140,
      sortable: false,
    },
    {
      field: "customerLevel",
      headerName: "ระดับสมาชิก",
      flex: 1,
      width: 140,
      sortable: false,
    },
    {
      field: "customerPoint",
      headerName: "คะแนนสะสม",
      flex: 1,
      width: 140,
      sortable: false,
    },
    {
      field: "customerCN",
      headerName: "การเชื่อมต่อ",
      width: 250,

      sortable: false,
      renderCell: ({ row }) => (
        <div className="d-flex align-items-center justify-content-center">
          {row.facebook ? (
            <Link className="btn  me-2 icon-facebook" to={row.facebook}></Link>
          ) : (
            ""
          )}
          {row.line ? (
            <Link className="btn  me-2 icon-line" to={row.line}></Link>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      field: "customerID",
      headerName: "จัดการ",
      width: 250,

      sortable: false,
      renderCell: ({ row }) => (
        <Link
          className="btn btn-edit-st-1 me-2"
          to={"/customer/edit/" + row.customerID}
        ></Link>
      ),
    },
  ];

  const [filterText, setFilterText] = useState("");
  const filteredItems = customerdata.filter(
    (item) =>
      (item.customerCode &&
        item.customerCode.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.customerName &&
        item.customerName.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.customerEmail &&
        item.customerEmail.toLowerCase().includes(filterText.toLowerCase()))
  );

  return (
    <div>
      <div>
        <nav aria-label="breadcrumb" className="mt-2">
          <ol className="breadcrumb fs-16 ps-0">
            <li className="breadcrumb-item ">
              <a href="#" className="tc-7  text-decoration-none">
                สมาชิก/ลูกค้า
              </a>
            </li>
            <li className="breadcrumb-item active tc-1 " aria-current="page">
              {" "}
              รายชื่อลูกค้า{" "}
            </li>
          </ol>
        </nav>
      </div>

      <div className="card card-st-2">
        <div className="card-body px-0 pt-4 pb-0">
          <div
            className="fs-18 fw-bold me-auto px-4 mb-4"
            style={{ color: "#2DC76D" }}
          >
            รายชื่อลูกค้า/สมาชิก
          </div>

          <div className="d-flex align-items-center px-4 mb-3 flex-wrap">
            <DownloadExcel data={filteredItems} filename="รายชื่อลูกค้า" />
            <div className="me-3 mb-2">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหาชื่อ, รหัสสมาชิก"
                onKeyUp={(e) => setFilterText(e.target.value)}
              />
            </div>
          </div>

          <DataGrid
            getRowId={(row) => row.customerID}
            getRowHeight={() => "auto"}
            rows={filteredItems}
            columns={columnsd}
            // autoHeight={true}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 20, 30]}
            checkboxSelection
            disableRowSelectionOnClick
            slotProps={{
              pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
            }}
            sx={{
              fontFamily: "Sarabun",
              fontSize: "17px",
              ".MuiDataGrid-columnHeaderTitle": {
                fontFamily: "Sarabun",
                fontSize: "17px",
              },
              ".MuiDataGrid-cell": {
                display: "flex",
                alignItems: "center",
                paddingTop: "5px",
                paddingBottom: "5px",
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}

const getcustomerEditData = (productID) => {
  return fetch("/json/customer2.json")
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => console.log("error", error));
};

export function CustomerCreate() {
  const [tab, setTab] = useState(1);

  return (
    <>
      <div>
        <nav aria-label="breadcrumb" className="mt-2">
          <ol className="breadcrumb fs-16 ps-0">
            <li className="breadcrumb-item ">
              <a href="#" className="tc-7  text-decoration-none">
                สมาชิก/ลูกค้า
              </a>
            </li>
            <li className="breadcrumb-item active tc-1 " aria-current="page">
              {" "}
              รายชื่อลูกค้า{" "}
            </li>
          </ol>
        </nav>
      </div>
      <div className="mb-3">
        <Button
          type="button"
          className={
            tab == 1 ? "btn-category px-4 active" : "btn-category px-4"
          }
          onClick={() => {
            setTab(1);
          }}
        >
          ข้อมูลลูกค้า
        </Button>
        <Button
          type="button"
          className={
            tab == 2 ? "btn-category px-4 active" : "btn-category px-4"
          }
          onClick={() => {
            setTab(2);
          }}
        >
          ประวัติการซื้อ{" "}
        </Button>
      </div>
      {tab == 2 ? <CustomerHistory /> : <CustomerInfo />}
    </>
  );
}

export function CustomerInfo() {
  const { customerID } = useParams();

  const [modalsuccess, setModalsuccess] = useState(false);
  const [textsuccess, setTextsuccess] = useState(false);

  const schema = yup.object().shape({});

  const [formdisabled, setFormdisabled] = useState(false);

  const [formdata, setFormdata] = useState({
    customerType: 1,
    customerCode: "customer00001",
    customerImage: "",
    customerPrefix: "",
    customerNameTh: "",
    customerSurnameTh: "",
    customerNameEn: "",
    customerSurnameEn: "",
    identitycard: "",
    gender: "",
    birthday: "",
    age: "",
    customerGroup: "",
    phoneNumber: "",
    customerLevel: 0,
    point: 0,
    line: "",
    facebook: "",

    Businesstype: "",
    businessName: "",
    businessLastName: "",

    jpTaxIDNumber: "",
    jpType: "",
    jpBranchCode: "",
    jpJuristicPersonType: "",
    jpPrefix: "",
    jpName: "cccc",
    jpLastName: "cxczxczc",
    jpAddress: "",
    jpSubdistrict: "",
    jpDistrict: "",
    jpProvince: "",
    jpPostalcode: "",
    jpPhoneNumber: "",
    jpMobileNumber: "",
    jpEmail: "",

    npTaxIDNumber: "",
    npType: "",
    npPrefix: "",
    npName: "",
    npSurname: "",
    npTaxIDNumber: "",
    npAddress: "",
    npSubdistrict: "",
    npDistrict: "",
    npProvince: "",
    npPostalcode: "",
    npPhoneNumber: "",
    npMobileNumber: "",
    npEmail: "",
  });

  const [cardOpen1, setCardOpen1] = useState(true);
  const [cardOpen2, setCardOpen2] = useState(true);
  const [cardOpen3, setCardOpen3] = useState(true);

  const prefixType = [
    { value: 1, label: "นาย" },
    { value: 2, label: "นาง" },
    { value: 3, label: "นางสาว" },
    { value: 4, label: "ไม่ระบุ" },
  ];

  const optionsGender = [
    { value: 1, label: "หญิง" },
    { value: 2, label: "ชาย" },
    { value: 3, label: "อื่นๆ" },
  ];

  const [identitycard, setIdentitycard] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);

  const optionsBusinesstype = [
    { value: "2", label: "ร้านค้า", suffix: "" },
    { value: "3", label: "บริษัทจำกัด", suffix: "จำกัด" },
    { value: "4", label: "บริษัทจำกัดมหาชน", suffix: "จำกัดมหาชน" },
    { value: "5", label: "ห้างหุ้นส่วนสามัญ", suffix: "" },
    { value: "6", label: "ห้างหุ้นส่วนจำกัด", suffix: "จำกัด" },
    { value: "7", label: "มูลนิธิ", suffix: "" },
    { value: "8", label: "สมาคม", suffix: "" },
    { value: "9", label: "กิจการร่วมค้า", suffix: "" },
    { value: "10", label: "คณะบุลคล", suffix: "" },
    { value: "11", label: "อื่นๆ", suffix: "" },
  ];

  const optionsLevel = [
    { value: 1, label: "gold" },
    { value: 2, label: "silver" },
  ];

  const optionsGroup = [
    { value: 1, label: "Group 1" },
    { value: 2, label: "Group 2" },
  ];

  const customerType = [
    { value: 1, label: "บุคคลธรรมดา" },
    { value: 2, label: "นิติบุคคล" },
  ];

  const handleinputTab = (e, index) => {
    const updateItem = [...identitycard];
    updateItem[index] = e.target.value;
    setIdentitycard(updateItem);
  };

  const [address, setAddress] = useState(
    ThailandAddressValue.fromDatasourceItem({
      d: "",
      p: "",
      po: "",
      s: "",
    })
  );

  const [address2, setAddress2] = useState(
    ThailandAddressValue.fromDatasourceItem({
      d: "",
      p: "",
      po: "",
      s: "",
    })
  );

  useEffect(() => {
    if (customerID) {
      getcustomerEditData(customerID).then((response) => {
        setFormdata(response);
        setAddress({
          district: response.jpDistrict,
          subdistrict: response.jpSubdistrict,
          province: response.jpProvince,
          postalCode: response.jpPostalcode,
        });
        setAddress2({
          district: response.npDistrict,
          subdistrict: response.npSubdistrict,
          province: response.npProvince,
          postalCode: response.npPostalcode,
        });
        setFormdisabled(true);
        setIdentitycard(response.identitycard.split(""));
      });
    }
  }, [customerID]);

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={formdata}
        validationSchema={schema}
        onSubmit={async (values) => {
          setTextsuccess("สร้างรายการสินค้าสำเร็จ");
          setModalsuccess(true);
          setFormdisabled(true);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          touched,
          errors,
          values,
          setFieldValue,
          isInvalid,
          isValid,
        }) => (
          <Form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              var tx = "";
              for (var i = 0; i < identitycard.length; i++) {
                tx += identitycard[i];
              }
              setFieldValue("identitycard", tx);
              handleSubmit();
            }}
          >
            <fieldset disabled={formdisabled}>
              <div className="card card-st-3 mb-3">
                <div className="card-body px-xl-3">
                  <button
                    type="button"
                    className="btn w-100 text-start btn-collapse"
                    onClick={() => setCardOpen1(!cardOpen1)}
                    aria-controls="collapse1"
                    aria-expanded={cardOpen1}
                  >
                    <div className="tc-8  fs-18 fw-bold px-3">ข้อมูลทั่วไป</div>
                  </button>
                  <Collapse in={cardOpen1}>
                    <div className="mt-3" id="collapse1">
                      {/* <Imageupload 
                                    setFieldValue={setFieldValue} 
                                    values={values} 
                                    ImageData={values.customerImage}
                                    name='customerImage'
                                    /> */}
                      {/* 
                                    <div className="pt-3 px-3">
                                        <div className="row gx-xl-4">
                                            <div className="col-xl-3 col-md-6">
                                            <Form.Group className="mb-2 form-st-3 pb-1">
                                                <Form.Label className="mb-1">ประเภท สมาชิก/ลูกค้า</Form.Label>
                                                <Select as="select"
                                                    name="customerType"
                                                    onChange={(e) => {
                                                        setFieldValue("customerType", e.value)
                                                    }}
                                                    className="w-100 select-st-1"
                                                    options={customerType}
                                                    placeholder="เลือกประเภทนิติบุคคล"
                                                    
                                                    value={
                                                        customerType.filter(
                                                            item => item.value === values.customerType
                                                        )
                                                    }
                                                />
                                            </Form.Group>
                                        </div>
                                        </div>
                                    </div> */}

                      {values.customerType == 2 ? (
                        <JuristicPerson
                          setFieldValue={setFieldValue}
                          values={values}
                          prefixType={prefixType}
                          handleChange={handleChange}
                          errors={errors}
                          identitycard={identitycard}
                          handleinputTab={handleinputTab}
                          optionsLevel={optionsLevel}
                          optionsBusinesstype={optionsBusinesstype}
                        />
                      ) : (
                        <NormalPerson
                          setFieldValue={setFieldValue}
                          values={values}
                          prefixType={prefixType}
                          handleChange={handleChange}
                          errors={errors}
                          identitycard={identitycard}
                          handleinputTab={handleinputTab}
                          optionsGroup={optionsGroup}
                          optionsGender={optionsGender}
                          optionsLevel={optionsLevel}
                        />
                      )}
                    </div>
                  </Collapse>
                </div>
              </div>

              <div className="card card-st-3 mb-3">
                <div className="card-body px-xl-3">
                  <button
                    type="button"
                    className="btn w-100 text-start btn-collapse"
                    onClick={() => setCardOpen2(!cardOpen2)}
                    aria-controls="collapse2"
                    aria-expanded={cardOpen2}
                  >
                    <div className="tc-8  fs-18 fw-bold px-3">
                      ข้อมูลออกใบกำกับภาษี (นิติบุคคล)
                    </div>
                  </button>
                  <Collapse in={cardOpen2}>
                    <div id="collapse2">
                      <div>
                        <div className="pt-3 px-3">
                          <div className="row gx-xl-4">
                            <div className="col-xl-4 col-md-6">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  หมายเลขผู้เสียภาษี 13 หลัก{" "}
                                  <span className="text-danger ms-1"> *</span>{" "}
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="jpTaxIDNumber"
                                  className="form-control"
                                  placeholder="หมายเลขผู้เสียภาษี 13 หลัก"
                                  maxLength={13}
                                  value={values.jpTaxIDNumber}
                                  onChange={handleChange}
                                  isInvalid={!!errors.jpTaxIDNumber}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-xl-8 col-md-8 row">
                              <div className="mb-3 pt-3 col-auto mt-1">
                                <Form.Check
                                  inline
                                  label="ไม่ระบุ"
                                  name="jpType"
                                  type="radio"
                                  id={`inline-jpType-1`}
                                  className="form-check-st-2 tc-7"
                                  checked={values.jpType === 0}
                                  onChange={(e) => {
                                    setFieldValue("jpType", 0);
                                  }}
                                />
                                <Form.Check
                                  inline
                                  label="สำนักงานใหญ่"
                                  name="jpType"
                                  type="radio"
                                  id={`inline-jpType-2`}
                                  className="form-check-st-2 tc-7"
                                  checked={values.jpType === 1}
                                  onChange={(e) => {
                                    setFieldValue("jpType", 1);
                                  }}
                                />
                                <Form.Check
                                  inline
                                  label="สาขา"
                                  name="jpType"
                                  type="radio"
                                  id={`inline-jpType-3`}
                                  className="form-check-st-2 tc-7"
                                  checked={values.jpType === 2}
                                  onChange={(e) => {
                                    setFieldValue("jpType", 2);
                                  }}
                                />
                              </div>
                              <div className="col-auto">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    หมายเลขสาขา{" "}
                                    <span className="text-danger ms-1"> *</span>{" "}
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="jpBranchCode"
                                    className="form-control"
                                    placeholder="หมายเลขสาขา"
                                    value={values.jpBranchCode}
                                    onChange={handleChange}
                                    isInvalid={!!errors.jpBranchCode}
                                    disabled={true}
                                  />
                                </Form.Group>
                              </div>
                            </div>

                            <div className="col-xl-3 col-md-6">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  ประเภทนิติบุคคล
                                </Form.Label>
                                <Select
                                  as="select"
                                  name="jpJuristicPersonType"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "jpJuristicPersonType",
                                      e.value
                                    );
                                    setFieldValue("jpLastName", e.suffix);
                                    const item = optionsBusinesstype.find(
                                      (item) => item.value === e.value
                                    );
                                    let prefix = "";
                                    let suffix = "";
                                    if (item) {
                                      if (e.value == "1" || e.value == "11") {
                                        prefix = "";
                                      } else {
                                        prefix = item.label;
                                      }
                                      suffix = item.suffix;
                                    }

                                    setFieldValue("jpPrefix", prefix);
                                  }}
                                  className="w-100 select-st-1"
                                  options={optionsBusinesstype}
                                  placeholder="เลือกประเภทนิติบุคคล"
                                  value={optionsBusinesstype.filter(
                                    (item) =>
                                      item.value === values.jpJuristicPersonType
                                  )}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-xl-2 col-md-12">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  คำนำหน้า{" "}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="jpPrefix"
                                  className="form-control"
                                  placeholder="คำนำหน้า"
                                  value={values.jpPrefix}
                                  onChange={handleChange}
                                  isInvalid={!!errors.jpPrefix}
                                  disabled={true}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-xl-5 col-md-12">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  ชื่อกิจการ{" "}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="jpName"
                                  className="form-control"
                                  placeholder="ชื่อกิจการ"
                                  value={values.jpName}
                                  onChange={handleChange}
                                  isInvalid={!!errors.jpName}
                                />
                              </Form.Group>
                            </div>

                            <div className="col-xl-2 col-md-12">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  คำลงท้าย{" "}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="jpLastName"
                                  className="form-control"
                                  placeholder="คำลงท้าย"
                                  value={values.jpLastName}
                                  onChange={handleChange}
                                  isInvalid={!!errors.jpLastName}
                                  disabled={true}
                                />
                              </Form.Group>
                            </div>

                            <div className="col-xl-12 col-md-12">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  ที่อยู่{" "}
                                  <span className="text-danger ms-1"> *</span>{" "}
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="jpAddress"
                                  className="form-control"
                                  placeholder="ที่อยู่"
                                  value={values.jpAddress}
                                  onChange={handleChange}
                                  isInvalid={!!errors.jpAddress}
                                />
                              </Form.Group>
                            </div>

                            <div className="col-12">
                              <ThailandAddressTypeahead
                                value={address}
                                onValueChange={(val) => {
                                  setAddress(val);

                                  setFieldValue(
                                    "jpSubdistrict",
                                    val.subdistrict
                                  );
                                  setFieldValue("jpDistrict", val.district);
                                  setFieldValue("jpProvince", val.province);
                                  setFieldValue("jpPostalcode", val.postalCode);
                                }}
                              >
                                <div className="row gx-xl-4">
                                  <div className="col-xl-6">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                      <Form.Label className="mb-1">
                                        แขวง/ตำบล
                                      </Form.Label>
                                      <ThailandAddressTypeahead.SubdistrictInput
                                        type="text"
                                        className={`form-control ${
                                          values.subdistrict
                                            ? ""
                                            : !!errors.subdistrict
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="กรอก แขวง/ตำบล"
                                        name="subdistrict"
                                        value={values.subdistrict}
                                      />
                                    </Form.Group>
                                  </div>
                                  <div className="col-xl-6">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                      <Form.Label className="mb-1">
                                        อำเภอ/เขต
                                      </Form.Label>
                                      <ThailandAddressTypeahead.DistrictInput
                                        type="text"
                                        className={`form-control ${
                                          values.district
                                            ? ""
                                            : !!errors.district
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="กรอก อำเภอ/เขต"
                                        name="district"
                                        value={values.district}
                                      />
                                    </Form.Group>
                                  </div>
                                  <div className="col-xl-6">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                      <Form.Label className="mb-1">
                                        จังหวัด
                                      </Form.Label>
                                      <ThailandAddressTypeahead.ProvinceInput
                                        type="text"
                                        className={`form-control ${
                                          values.province
                                            ? ""
                                            : !!errors.province
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="กรอกจังหวัด"
                                        name="province"
                                        value={values.province}
                                      />
                                    </Form.Group>
                                  </div>
                                  <div className="col-xl-6">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                      <Form.Label className="mb-1">
                                        รหัสไปรษณีย์
                                      </Form.Label>
                                      <ThailandAddressTypeahead.PostalCodeInput
                                        type="text"
                                        className={`form-control ${
                                          values.postalcode
                                            ? ""
                                            : !!errors.postalcode
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="กรอกรหัสไปรษณีย์"
                                        name="postalcode"
                                        value={values.postalcode}
                                      />
                                    </Form.Group>
                                  </div>
                                </div>
                                <ThailandAddressTypeahead.Suggestion
                                  containerProps={{
                                    className: "suggestion-container",
                                  }}
                                  optionItcustomerrops={{
                                    className: "suggestion-option",
                                  }}
                                />
                              </ThailandAddressTypeahead>
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-md-6">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  เบอร์โทรศัพท์
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="jpPhoneNumber"
                                  className="form-control"
                                  placeholder="เบอร์โทรศัพท์"
                                  value={values.jpPhoneNumber}
                                  onChange={handleChange}
                                  isInvalid={!!errors.jpPhoneNumber}
                                />
                              </Form.Group>
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-md-6">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  เบอร์มือถือ
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="jpMobileNumber"
                                  className="form-control"
                                  placeholder="เบอร์มือถือ"
                                  value={values.jpMobileNumber}
                                  onChange={handleChange}
                                  isInvalid={!!errors.jpMobileNumber}
                                />
                              </Form.Group>
                            </div>

                            <div className="col-xxl-6 col-xl-3 col-md-6">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">E-mail</Form.Label>
                                <Form.Control
                                  type="email"
                                  name="jpEmail"
                                  className="form-control"
                                  placeholder="E-mail"
                                  value={values.jpEmail}
                                  onChange={handleChange}
                                  isInvalid={!!errors.jpEmail}
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>

              <div className="card card-st-3 mb-3">
                <div className="card-body px-xl-3">
                  <button
                    type="button"
                    className="btn w-100 text-start btn-collapse"
                    onClick={() => setCardOpen3(!cardOpen3)}
                    aria-controls="collapse3"
                    aria-expanded={cardOpen3}
                  >
                    <div className="tc-8  fs-18 fw-bold px-3">
                      ข้อมูลออกใบกำกับภาษี (บุคคลธรรมดา)
                    </div>
                  </button>
                  <Collapse in={cardOpen3}>
                    <div id="collapse3">
                      <div>
                        <div className="pt-3 px-3">
                          <div className="row gx-xl-4">
                            <div className="col-xl-4 col-md-6">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  หมายเลขผู้เสียภาษี 13 หลัก{" "}
                                  <span className="text-danger ms-1"> *</span>{" "}
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="npTaxIDNumber"
                                  className="form-control"
                                  placeholder="หมายเลขผู้เสียภาษี 13 หลัก"
                                  maxLength={13}
                                  value={values.npTaxIDNumber}
                                  onChange={handleChange}
                                  isInvalid={!!errors.npTaxIDNumber}
                                />
                              </Form.Group>
                            </div>

                            <div className="col-xl-2 col-md-4">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  คำนำหน้า
                                </Form.Label>
                                <Select
                                  as="select"
                                  name="npPrefix"
                                  onChange={(e) => {
                                    setFieldValue("npPrefix", e.value);
                                  }}
                                  className="w-100 select-st-1"
                                  options={prefixType}
                                  placeholder="เลือกคำนำหน้า"
                                  value={prefixType.filter(
                                    (item) => item.value === values.npPrefix
                                  )}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-xl-3 col-md-4">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">ชื่อ </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="npName"
                                  className="form-control"
                                  placeholder="กรอกชื่อภาษาไทย"
                                  value={values.npName}
                                  onChange={handleChange}
                                  isInvalid={!!errors.npName}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-xl-3 col-md-4">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  นามสกุล{" "}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="npSurname"
                                  className="form-control"
                                  placeholder="กรอกนามสกุลภาษาไทย"
                                  value={values.npSurname}
                                  onChange={handleChange}
                                  isInvalid={!!errors.npSurname}
                                />
                              </Form.Group>
                            </div>

                            <div className="col-xl-12 col-md-12">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  ที่อยู่{" "}
                                  <span className="text-danger ms-1"> *</span>{" "}
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="npAddress"
                                  className="form-control"
                                  placeholder="ที่อยู่"
                                  value={values.npAddress}
                                  onChange={handleChange}
                                  isInvalid={!!errors.npAddress}
                                />
                              </Form.Group>
                            </div>

                            <div className="col-12">
                              <ThailandAddressTypeahead
                                value={address2}
                                onValueChange={(val) => {
                                  setAddress2(val);

                                  setFieldValue(
                                    "npSubdistrict",
                                    val.subdistrict
                                  );
                                  setFieldValue("npDistrict", val.district);
                                  setFieldValue("npProvince", val.province);
                                  setFieldValue("npPostalcode", val.postalCode);
                                }}
                              >
                                <div className="row gx-xl-4">
                                  <div className="col-xl-6">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                      <Form.Label className="mb-1">
                                        แขวง/ตำบล
                                      </Form.Label>
                                      <ThailandAddressTypeahead.SubdistrictInput
                                        type="text"
                                        className={`form-control ${
                                          values.subdistrict
                                            ? ""
                                            : !!errors.subdistrict
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="กรอก แขวง/ตำบล"
                                        name="subdistrict"
                                        value={values.subdistrict}
                                      />
                                    </Form.Group>
                                  </div>
                                  <div className="col-xl-6">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                      <Form.Label className="mb-1">
                                        อำเภอ/เขต
                                      </Form.Label>
                                      <ThailandAddressTypeahead.DistrictInput
                                        type="text"
                                        className={`form-control ${
                                          values.district
                                            ? ""
                                            : !!errors.district
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="กรอก อำเภอ/เขต"
                                        name="district"
                                        value={values.district}
                                      />
                                    </Form.Group>
                                  </div>
                                  <div className="col-xl-6">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                      <Form.Label className="mb-1">
                                        จังหวัด
                                      </Form.Label>
                                      <ThailandAddressTypeahead.ProvinceInput
                                        type="text"
                                        className={`form-control ${
                                          values.province
                                            ? ""
                                            : !!errors.province
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="กรอกจังหวัด"
                                        name="province"
                                        value={values.province}
                                      />
                                    </Form.Group>
                                  </div>
                                  <div className="col-xl-6">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                      <Form.Label className="mb-1">
                                        รหัสไปรษณีย์
                                      </Form.Label>
                                      <ThailandAddressTypeahead.PostalCodeInput
                                        type="text"
                                        className={`form-control ${
                                          values.postalcode
                                            ? ""
                                            : !!errors.postalcode
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="กรอกรหัสไปรษณีย์"
                                        name="postalcode"
                                        value={values.postalcode}
                                      />
                                    </Form.Group>
                                  </div>
                                </div>
                                <ThailandAddressTypeahead.Suggestion
                                  containerProps={{
                                    className: "suggestion-container",
                                  }}
                                  optionItcustomerrops={{
                                    className: "suggestion-option",
                                  }}
                                />
                              </ThailandAddressTypeahead>
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-md-6">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  เบอร์โทรศัพท์
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="npPhoneNumber"
                                  className="form-control"
                                  placeholder="เบอร์โทรศัพท์"
                                  value={values.npPhoneNumber}
                                  onChange={handleChange}
                                  isInvalid={!!errors.npPhoneNumber}
                                />
                              </Form.Group>
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-md-6">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  เบอร์มือถือ
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="npMobileNumber"
                                  className="form-control"
                                  placeholder="เบอร์มือถือ"
                                  value={values.npMobileNumber}
                                  onChange={handleChange}
                                  isInvalid={!!errors.npMobileNumber}
                                />
                              </Form.Group>
                            </div>

                            <div className="col-xxl-6 col-xl-3 col-md-6">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">E-mail</Form.Label>
                                <Form.Control
                                  type="email"
                                  name="npEmail"
                                  className="form-control"
                                  placeholder="E-mail"
                                  value={values.npEmail}
                                  onChange={handleChange}
                                  isInvalid={!!errors.npEmail}
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
            </fieldset>

            <div className="text-end pb-4 pe-3">
              {formdisabled === true ? (
                <Button
                  type="button"
                  className="btn-st-3 mx-3"
                  onClick={(e) => {
                    setFormdisabled(false);
                  }}
                >
                  แก้ไข
                </Button>
              ) : (
                <div className="text-end pt-3 btn-group-control">
                  <Button type="button" className="btn-st-6 me-3 btn">
                    ยกเลิก
                  </Button>
                  <Button type="submit" className="btn-st-3 ">
                    บันทึก
                  </Button>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>

      <SuccessModal
        show={modalsuccess}
        onHide={() => setModalsuccess(false)}
        textsuccess={textsuccess}
      />
    </div>
  );
}

export function CustomerHistory() {
  const [customerdata, setCustomerdata] = useState([]);
  const getcustomerData = () => {
    fetch("/json/customer_history.json")
      .then((response) => response.json())
      .then((result) => {
        setCustomerdata(result);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getcustomerData();
  }, []);

  const columnsd = [
    {
      field: "date",
      headerName: "วันที่",
      width: 130,
      sortable: false,
    },

    {
      field: "branchName",
      headerName: "สาขา",
      width: 160,
      sortable: false,
    },
    {
      field: "receipt_number",
      headerName: "เลขที่ใบเสร็จ",
      width: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <Link
          to={`/customer/edit/${row.customerID}`}
          style={{
            color: "#2DC76D",
            fontWeight: 500,
            textDecoration: "underline",
          }}
        >
          {row.receipt_number}
        </Link>
      ),
    },
    {
      field: "net_total",
      headerName: "ยอดสุทธิ",

      width: 140,
      sortable: false,
    },
    {
      field: "product",
      headerName: "จำนวนรายการ",

      width: 140,
      sortable: false,
    },
    {
      field: "point_receive",
      headerName: "คะแนนที่ได้รับ",

      width: 140,
      sortable: false,
      renderCell: ({ row }) => (
        <div className="text-[#2DC76D] font-bold">{row.point_receive}</div>
      ),
    },
    {
      field: "point_used",
      headerName: "คะแนนที่ใช้",
      width: 140,
      sortable: false,
      renderCell: ({ row }) => (
        <div className="text-[#FF0000] font-bold">{row.point_used}</div>
      ),
    },
    {
      field: "points_remain",
      headerName: "คะแนนคงเหลือ",

      width: 140,
      sortable: false,
      renderCell: ({ row }) => (
        <div className="text-[#2DC76D] font-bold">{row.points_remain}</div>
      ),
    },
    {
      field: "salesperson",
      headerName: "พนักงานขาย",
      width: 200,
      sortable: false,
    },
  ];

  const [filterText, setFilterText] = useState("");
  const filteredItems = customerdata.filter(
    (item) =>
      item.receipt_number &&
      item.receipt_number.toLowerCase().includes(filterText.toLowerCase())
  );

  const getPageZoom = () => {
    const zoom = window.innerWidth / 1920;
    document.documentElement.style.setProperty("--zoom", zoom);
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", getPageZoom);
    getPageZoom();
    return () => window.removeEventListener("resize", getPageZoom);
  }, []);

  return (
    <div>
      <div className="card card-st-2 pagezoom mb-6">
        <div className="card-body !p-6">
          <div className="grid grid-cols-11 gap-7">
            <div className="col-span-2 border-r-[1px] border-[#E5E5E5]">
              <div className="font-bold text-[#636578] text-xl mb-3">
                คุณ บุญเสริม โชคดี
              </div>
              <div className="flex mb-3">
                <div className="flex-[0_0_81px]  text-end mr-2">
                  รหัสสมาชิก :
                </div>
                <div className="text-[#2684FC]"> CM2011040001</div>
              </div>
              <div className="flex">
                <div className="flex-[0_0_81px] text-end mr-2">เบอร์โทร :</div>
                <div className="text-[#2684FC]"> 081-5555555</div>
              </div>
            </div>
            <div className="col-span-3 border-r-[1px] border-[#E5E5E5]">
              <div className="flex  mb-3">
                <div className="flex-[0_0_150px]">ระยะเวลาเป็นสมาชิก</div>
                <div className="text-[#2DC76D]">4 ปี 2เดือน</div>
              </div>
              <div className="flex  mb-3">
                <div className="flex-[0_0_150px]">ระดับสมาชิก</div>
                <div>
                  <span className="rounded-full bg-[#0760FC] px-3 text-white">
                    SILVER
                  </span>
                </div>
              </div>
              <div className="flex  ">
                <div className="flex-[0_0_150px]">คะแนนสะสม</div>
                <div>
                  <span className="text-[#2DC76D]">10,520</span> คะแนน
                </div>
              </div>
            </div>
            <div className="col-span-2 border-r-[1px] border-[#E5E5E5]">
              <div className="font-bold text-[#636578] text-xl mb-3">
                ยอดซื้อสะสม
              </div>
              <div className="mb-2 text-2xl font-bold ">
                <span className="text-[#2DC76D] mr-3">79,000.00</span>
                <span className="text-[#636578]">บาท</span>
              </div>
              <div className="text-[#63657]">ยอดใช้จ่ายสะสม</div>
            </div>
            <div className="col-span-2 border-r-[1px] border-[#E5E5E5]">
              <div className="font-bold text-[#636578] text-xl mb-3">
                จำนวนบิลซื้อ
              </div>
              <div className="mb-2 text-2xl font-bold ">
                <span className="text-[#2DC76D] mr-3">1,200</span>
                <span className="text-[#636578]">บิล</span>
              </div>
              <div className="text-[#63657]">จำนวนครั้งที่มาซื้อสินค้า</div>
            </div>
            <div className="col-span-2">
              <div className="font-bold text-[#636578] text-xl mb-3">
                สถิติการซื้อ
              </div>
              <div className="mb-3 px-2">
                <div className="grid grid-cols-2 gap-4">
                  <div className="col-span-1 ">ซื้อครั้งแรก</div>
                  <div className="col-span-1 text-[#2DC76D]">1 ม.ค 2020</div>
                </div>
              </div>
              <div className=" px-2">
                <div className="grid grid-cols-2 gap-4">
                  <div className="col-span-1 ">ซื้อครั้งล่าสุด</div>
                  <div className="col-span-1 text-[#2DC76D]">20 ธ.ค 2023</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card card-st-2">
        <div className="card-body px-0 pt-4 pb-0">
          <div
            className="fs-18 fw-bold me-auto px-4 mb-2"
            style={{ color: "#767676" }}
          >
            ข้อมูลการซื้อ
          </div>

          <div className="d-flex align-items-center px-4 mb-0 flex-wrap">
            <DownloadExcel data={filteredItems} filename="ข้อมูลการซื้อ" />
            <div className="w-[300px] ml-auto">
              <Form.Group className="mb-3 form-st-3 pb-1">
                <Form.Label className="mb-1">เลือกช่วงวันที่ </Form.Label>
                <DateRangePicker
                  initialSettings={{
                    startDate: moment(),
                    endDate: moment().add(1, "days"),
                    showCustomRangeLabel: true,
                    isCustomDate: true,
                    ranges: {
                      Today: [moment(), moment()],
                      "Last 7 Days": [moment().subtract(6, "days"), moment()],
                      "Last 30 Days": [moment().subtract(29, "days"), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    },
                    locale: {
                      format: "DD/MM/YYYY",
                    },
                  }}
                >
                  <input
                    type="text"
                    className="form-control datepicker2 h-[44px]"
                  />
                </DateRangePicker>
              </Form.Group>
            </div>
            <div className=" mx-4 mb-2">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหาเลขที่ใบเสร็จ"
                onKeyUp={(e) => setFilterText(e.target.value)}
              />
            </div>
          </div>

          <DataGrid
            getRowId={(row) => row.id}
            getRowHeight={() => "auto"}
            rows={filteredItems}
            columns={columnsd}
            // autoHeight={true}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 20, 30]}
            checkboxSelection
            disableRowSelectionOnClick
            slotProps={{
              pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
            }}
            sx={{
              fontFamily: "Sarabun",
              fontSize: "17px",
              ".MuiDataGrid-columnHeaderTitle": {
                fontFamily: "Sarabun",
                fontSize: "17px",
              },
              ".MuiDataGrid-cell": {
                display: "flex",
                alignItems: "center",
                paddingTop: "5px",
                paddingBottom: "5px",
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}

function NormalPerson({
  setFieldValue,
  values,
  prefixType,
  handleChange,
  errors,
  identitycard,
  handleinputTab,
  optionsGroup,
  optionsGender,
  optionsLevel,
  ...props
}) {
  const [startDate, setStartDate] = useState("");
  return (
    <div>
      <div className=" px-3">
        <div className="row gx-xl-4">
          <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">
                รหัสลูกค้า <span className="text-danger ms-1"> *</span>{" "}
              </Form.Label>
              <Form.Control
                type="text"
                name="customerCode"
                className="form-control"
                placeholder="รหัสลูกค้า"
                value={values.customerCode}
                onChange={handleChange}
                isInvalid={!!errors.customerCode}
                disabled={true}
              />
            </Form.Group>
          </div>
          <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">คำนำหน้า</Form.Label>
              <Select
                as="select"
                name="customerPrefix"
                onChange={(e) => {
                  setFieldValue("customerPrefix", e.value);
                }}
                className="w-100 select-st-1"
                options={prefixType}
                placeholder="เลือกคำนำหน้า"
                value={prefixType.filter(
                  (item) => item.value === values.customerPrefix
                )}
              />
            </Form.Group>
          </div>
          <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">
                ชื่อ (TH) <span className="text-danger ms-1"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="customerNameTh"
                className="form-control"
                placeholder="กรอกชื่อภาษาไทย"
                value={values.customerNameTh}
                onChange={handleChange}
                isInvalid={!!errors.customerNameTh}
              />
            </Form.Group>
          </div>
          <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">
                นามสกุล (TH) <span className="text-danger ms-1"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="customerSurnameTh"
                className="form-control"
                placeholder="กรอกนามสกุลภาษาไทย"
                value={values.customerSurnameTh}
                onChange={handleChange}
                isInvalid={!!errors.customerSurnameTh}
              />
            </Form.Group>
          </div>
          <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">
                ชื่อ (EN) <span className="text-danger ms-1"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="customerNameEn"
                className="form-control"
                placeholder="กรอกชื่อภาษาอังกฤษ"
                value={values.customerNameEn}
                onChange={handleChange}
                isInvalid={!!errors.customerNameEn}
              />
            </Form.Group>
          </div>
          <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">
                นามสกุล (EN) <span className="text-danger ms-1"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="customerSurnameEn"
                className="form-control"
                placeholder="กรอกนามสกุลภาษาอังกฤษ"
                value={values.customerSurnameEn}
                onChange={handleChange}
                isInvalid={!!errors.customerSurnameEn}
              />
            </Form.Group>
          </div>
          <div className="col-xl-6 col-md-12">
            <Form.Label className="mb-1 tc-10  fs-12">
              หมายเลขบัตรประชาชน (13 หลัก)
            </Form.Label>
            <div className="my-0 form-st-3 identitycard ">
              <AutoTabProvider>
                <span>
                  <input
                    type="text"
                    settings={{ placement: 1 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[0]}
                    className="form-tab first"
                    onChange={(e) => handleinputTab(e, 0)}
                  />
                </span>
                <span>
                  <input
                    type="text"
                    settings={{ placement: 2 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[1]}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, 1)}
                  />
                  <input
                    type="text"
                    settings={{ placement: 3 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[2]}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, 2)}
                  />
                  <input
                    type="text"
                    settings={{ placement: 4 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[3]}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, 3)}
                  />
                  <input
                    type="text"
                    settings={{ placement: 5 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[4]}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, 4)}
                  />
                </span>
                <span>
                  <input
                    type="text"
                    settings={{ placement: 6 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[5]}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, 5)}
                  />
                  <input
                    type="text"
                    settings={{ placement: 7 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[6]}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, 6)}
                  />
                  <input
                    type="text"
                    settings={{ placement: 8 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[7]}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, 7)}
                  />
                  <input
                    type="text"
                    settings={{ placement: 9 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[8]}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, 8)}
                  />
                  <input
                    type="text"
                    settings={{ placement: 10 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[9]}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, 9)}
                  />
                </span>
                <span>
                  <input
                    type="text"
                    settings={{ placement: 11 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[10]}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, 10)}
                  />
                  <input
                    type="text"
                    settings={{ placement: 12 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[11]}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, 11)}
                  />
                  <input
                    type="text"
                    settings={{ placement: 13 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[12]}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, 12)}
                  />
                </span>
              </AutoTabProvider>
            </div>
          </div>

          <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">เพศ</Form.Label>
              <Select
                as="select"
                name="gender"
                onChange={(e) => {
                  setFieldValue("gender", e.value);
                }}
                className="w-100 select-st-1"
                options={optionsGender}
                placeholder="เพศ"
                value={optionsGender.filter(
                  (item) => item.value === values.gender
                )}
              />
            </Form.Group>
          </div>
          <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-3 form-st-3 pb-1">
              <Form.Label className="mb-1">วันเกิด </Form.Label>
              <DatePicker
                inputFormat="DD/MM/YYYY"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  setFieldValue("birthday", getCurrentDate("-", date));
                }}
                placeholder="DD/MM/YYYY"
                className="w-100 datepicker2"
                value={values.birthday}
              />
            </Form.Group>
          </div>
          <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">อายุ</Form.Label>
              <Form.Control
                type="number"
                name="age"
                className="form-control"
                placeholder="กรอกอายุ"
                value={values.age}
                onChange={handleChange}
                isInvalid={!!errors.age}
              />
            </Form.Group>
          </div>
          <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">กลุ่มลูกค้า</Form.Label>
              <Select
                as="select"
                name="customerGroup"
                onChange={(e) => {
                  setFieldValue("customerGroup", e.value);
                }}
                className="w-100 select-st-1"
                options={optionsGroup}
                placeholder="เลือกกลุ่มลูกค้า"
                value={optionsGroup.filter(
                  (item) => item.value === values.customerGroup
                )}
              />
            </Form.Group>
          </div>
          <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">เบอร์โทรศัพท์</Form.Label>
              <Form.Control
                type="number"
                name="phoneNumber"
                className="form-control"
                placeholder="กรอกเบอร์โทรศัพท์"
                value={values.phoneNumber}
                onChange={handleChange}
                isInvalid={!!errors.phoneNumber}
              />
            </Form.Group>
          </div>
          <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">E-mail</Form.Label>
              <Form.Control
                type="text"
                name="email"
                className="form-control"
                placeholder="กรอกE-mail"
                value={values.email}
                onChange={handleChange}
                isInvalid={!!errors.email}
              />
            </Form.Group>
          </div>
          <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">ระดับสมาชิก</Form.Label>
              <Select
                as="select"
                name="customerLevel"
                onChange={(e) => {
                  setFieldValue("customerLevel", e.value);
                }}
                className="w-100 select-st-1"
                options={optionsLevel}
                placeholder="ระบุระดับสมาชิก"
                value={optionsLevel.filter(
                  (item) => item.value === values.customerLevel
                )}
              />
            </Form.Group>
          </div>
          <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">คะแนน</Form.Label>
              <Form.Control
                type="text"
                name="point"
                className="form-control"
                placeholder="กรอกคะแนน"
                value={values.point}
                onChange={handleChange}
                isInvalid={!!errors.point}
              />
            </Form.Group>
          </div>
          <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">Line</Form.Label>
              <Form.Control
                type="text"
                name="line"
                className="form-control"
                placeholder=""
                value={values.line ? "เชื่อมต่อแล้ว" : "-"}
                onChange={handleChange}
                isInvalid={!!errors.line}
                disabled={true}
                styles={
                  values.line ? { color: "#2DC76D", fontSize: "20px" } : ""
                }
              />
            </Form.Group>
          </div>
          <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">Facebook</Form.Label>
              <Form.Control
                type="text"
                name="facebook"
                className="form-control"
                placeholder=""
                value={values.facebook ? "เชื่อมต่อแล้ว" : "-"}
                onChange={handleChange}
                isInvalid={!!errors.facebook}
                disabled={true}
                styles={
                  values.line ? { color: "#2DC76D", fontSize: "20px" } : ""
                }
              />
            </Form.Group>
          </div>
        </div>
      </div>
    </div>
  );
}

function JuristicPerson({
  setFieldValue,
  values,
  prefixType,
  handleChange,
  errors,
  identitycard,
  handleinputTab,
  optionsLevel,
  optionsBusinesstype,
  ...props
}) {
  return (
    <div>
      <div className=" px-3">
        <div className="row gx-xl-4">
          <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">
                รหัสลูกค้า <span className="text-danger ms-1"> *</span>{" "}
              </Form.Label>
              <Form.Control
                type="text"
                name="customerCode"
                className="form-control"
                placeholder="รหัสลูกค้า"
                value={values.customerCode}
                onChange={handleChange}
                isInvalid={!!errors.customerCode}
                disabled={true}
              />
            </Form.Group>
          </div>
          <div className="col-xl-8 col-md-12">
            <Form.Label className="mb-1 tc-10  fs-12">
              หมายเลขบัตรประชาชน (13 หลัก)
            </Form.Label>
            <div className="my-0 form-st-3 identitycard ">
              <AutoTabProvider>
                <span>
                  <input
                    type="text"
                    settings={{ placement: 1 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[0]}
                    className="form-tab first"
                    onChange={(e) => handleinputTab(e, 0)}
                  />
                </span>
                <span>
                  <input
                    type="text"
                    settings={{ placement: 2 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[1]}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, 1)}
                  />
                  <input
                    type="text"
                    settings={{ placement: 3 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[2]}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, 2)}
                  />
                  <input
                    type="text"
                    settings={{ placement: 4 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[3]}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, 3)}
                  />
                  <input
                    type="text"
                    settings={{ placement: 5 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[4]}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, 4)}
                  />
                </span>
                <span>
                  <input
                    type="text"
                    settings={{ placement: 6 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[5]}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, 5)}
                  />
                  <input
                    type="text"
                    settings={{ placement: 7 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[6]}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, 6)}
                  />
                  <input
                    type="text"
                    settings={{ placement: 8 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[7]}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, 7)}
                  />
                  <input
                    type="text"
                    settings={{ placement: 9 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[8]}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, 8)}
                  />
                  <input
                    type="text"
                    settings={{ placement: 10 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[9]}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, 9)}
                  />
                </span>
                <span>
                  <input
                    type="text"
                    settings={{ placement: 11 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[10]}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, 10)}
                  />
                  <input
                    type="text"
                    settings={{ placement: 12 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[11]}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, 11)}
                  />
                  <input
                    type="text"
                    settings={{ placement: 13 }}
                    maxLength={1}
                    tabbable="true"
                    defaultValue={identitycard[12]}
                    className="form-tab"
                    onChange={(e) => handleinputTab(e, 12)}
                  />
                </span>
              </AutoTabProvider>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">ประเภทกิจการ</Form.Label>
              <Select
                as="select"
                name="Businesstype"
                onChange={(e) => {
                  setFieldValue("Businesstype", e.value);
                  setFieldValue("businessLastname", e.suffix);
                }}
                className="w-100 select-st-1"
                options={optionsBusinesstype}
                placeholder="เลือกคำนำหน้า"
                value={optionsBusinesstype.filter(
                  (item) => item.value === values.Businesstype
                )}
              />
            </Form.Group>
          </div>
          <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">ชื่อ (TH)</Form.Label>
              <Form.Control
                type="text"
                name="businessName"
                className="form-control"
                placeholder="กรอกชื่อภาษาไทย"
                value={values.businessName}
                onChange={handleChange}
                isInvalid={!!errors.businessName}
              />
            </Form.Group>
          </div>
          <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">คำลงท้าย </Form.Label>
              <Form.Control
                type="text"
                name="businessLastname"
                className="form-control"
                placeholder="คำลงท้าย"
                value={values.businessLastname}
                onChange={handleChange}
                isInvalid={!!errors.businessLastname}
                disabled={true}
              />
            </Form.Group>
          </div>

          <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">เบอร์โทรศัพท์</Form.Label>
              <Form.Control
                type="number"
                name="phoneNumber"
                className="form-control"
                placeholder="กรอกเบอร์โทรศัพท์"
                value={values.phoneNumber}
                onChange={handleChange}
                isInvalid={!!errors.phoneNumber}
              />
            </Form.Group>
          </div>

          <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">ระดับสมาชิก</Form.Label>
              <Select
                as="select"
                name="customerLevel"
                onChange={(e) => {
                  setFieldValue("customerLevel", e.value);
                }}
                className="w-100 select-st-1"
                options={optionsLevel}
                placeholder="ระบุระดับสมาชิก"
                value={optionsLevel.filter(
                  (item) => item.value === values.customerLevel
                )}
              />
            </Form.Group>
          </div>
          <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">คะแนน</Form.Label>
              <Form.Control
                type="text"
                name="point"
                className="form-control"
                placeholder="กรอกคะแนน"
                value={values.point}
                onChange={handleChange}
                isInvalid={!!errors.point}
              />
            </Form.Group>
          </div>
          <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">Line</Form.Label>
              <Form.Control
                type="text"
                name="line"
                className="form-control"
                placeholder=""
                value={values.line ? "เชื่อมต่อแล้ว" : "-"}
                onChange={handleChange}
                isInvalid={!!errors.line}
                disabled={true}
                styles={
                  values.line ? { color: "#2DC76D", fontSize: "20px" } : ""
                }
              />
            </Form.Group>
          </div>
          <div className="col-xl-3 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">Facebook</Form.Label>
              <Form.Control
                type="text"
                name="facebook"
                className="form-control"
                placeholder=""
                value={values.facebook ? "เชื่อมต่อแล้ว" : "-"}
                onChange={handleChange}
                isInvalid={!!errors.facebook}
                disabled={true}
                styles={
                  values.line ? { color: "#2DC76D", fontSize: "20px" } : ""
                }
              />
            </Form.Group>
          </div>
        </div>
      </div>
    </div>
  );
}

function getCurrentDate(separator = "", dateb = new Date()) {
  let newDate = dateb;
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${date}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${year}`;
}

function SuccessModal(props) {
  return (
    <Modal {...props} size="sm" centered>
      <Modal.Body>
        <div className="px-3 py-4">
          <div className="text-center mb-4">
            <svg
              width="84"
              height="83"
              viewBox="0 0 84 83"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="42" cy="41.5" r="41.5" fill="#2DC76D" />
              <path
                d="M56.0123 30.9902L36.7444 50.2581L27.9863 41.5"
                stroke="white"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="text-center fs-18 tc-7">{props.textsuccess}</div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
