import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { branchPaymentHandler } from "../../../../../redux/handlers";
import SettingBankTransferModal from "../modals/SettingBankTransferModal";
import ConfirmDeleteTransferModal from "../modals/ConfirmDeleteTransferModal";
import SuccessDeleteModal from "../modals/SuccessDeleteModal";

export default function PaymentTransferSetting() {
  const dispatch = useDispatch();
  const { branchID } = useParams();
  const [bankAccounts, setBankAccounts] = useState([]);
  const paymentForm = useSelector(
    (state) => state.branchPayment.paymentForm.payment
  );

  const [enableBankTransfer, setEnableBankTransfer] = useState(
    paymentForm?.is_bank_received || false
  );

  useEffect(() => {
    setEnableBankTransfer(paymentForm?.is_bank_received || false);
  }, [paymentForm?.is_bank_received]);

  const fetchBankAccounts = async () => {
    try {
      const response = await dispatch(
        branchPaymentHandler.getBranchBankSettings(branchID)
      );
      if (response) {
        const transformedData = response.map((account) => ({
          id: account.id,
          bankID: account.bank_id,
          bankAccountName: account.account_name,
          bankAccountNumber: account.account_number,
          bankAccountBranch: account.branch_name,
          status: account.is_status ? 1 : 0,
          bankLogo: account.bank?.image_path,
        }));
        setBankAccounts(transformedData);
      }
    } catch (error) {
      console.error("Error fetching bank accounts:", error);
    }
  };

  useEffect(() => {
    if (branchID) {
      fetchBankAccounts();
    }
  }, [branchID]);

  const [formdata, setFormdata] = useState();
  const [modalcreateShow, setModalcreateShow] = useState(false);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [modalsuccessShow, setModalsuccessShow] = useState(false);  

  const handleSubmit = async () => {
    try {
      const paymentPayload = {
        ...paymentForm,
        is_bank_received: enableBankTransfer,
      };

      await dispatch(
        branchPaymentHandler.updateBranchPaymentSetting(
          branchID,
          paymentPayload
        )
      );
    } catch (error) {
      console.error("Failed to update settings:", error);
    }
  };

  return (
    <div>
      <div className="mb-4">
        <Form.Check
          type="switch"
          id="status"
          label="จัดการข้อมูลการโอนเงิน"
          name="status"
          className="custom-switch-st-1 tc-10 s1 mb-1"
          checked={enableBankTransfer}
          onChange={(e) => setEnableBankTransfer(e.target.checked)}
        />
      </div>

      {enableBankTransfer && (
        <>
          <Button
            type="button"
            className="btn btn btn-st-7 icon-plus mb-4"
            onClick={() => {
              setModalcreateShow(true);
              setFormdata({
                id: null,
                bankID: "",
                bankAccountName: "",
                bankAccountNumber: "",
                bankAccountBranch: "",
                status: 1,
              });
            }}
          >
            เพิ่มบัญชีธนาคาร
          </Button>

          <div className="d-flex flex-wrap">
            {bankAccounts.map((account) => (
              <div
                className={
                  account.status === 0
                    ? "bankStatus0 d-flex align-items-center px-3 py-2 me-3 mb-3"
                    : "d-flex align-items-center px-3 py-2 me-3 mb-3"
                }
                key={account.id}
                style={{
                  minWidth: "283px",
                  borderRadius: "5px",
                  border: "1px solid rgba(76, 78, 100, 0.22)",
                }}
              >
                <div className="logo-bank me-3">
                  <Image
                    src={account.bankLogo}
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                    alt={account.bankAccountName}
                  />
                </div>
                <div className="flex-fill">
                  <div style={{ color: " #2C2C2C" }}>
                    {account.bankAccountName}
                  </div>
                  <div className="fs-14 fw-light" style={{ color: "#6D788D" }}>
                    {account.bankAccountNumber}
                  </div>
                </div>
                <div className="ms-2">
                  <button
                    type="button"
                    className="btn-edit-st-1"
                    onClick={() => {
                      setModalcreateShow(true);
                      setFormdata(account);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      <div className="text-end mt-4 pt-5 pb-2 btn-group-control">
        <Button
          type="submit"
          className="btn-st-3"
          style={{ width: "100px" }}
          onClick={handleSubmit}
        >
          บันทึก
        </Button>
      </div>

      <SettingBankTransferModal
        show={modalcreateShow}
        onHide={() => setModalcreateShow(false)}
        handleDelete={() => {
          setModalDeleteShow(true);
          setModalcreateShow(false);
        }}
        onSuccess={() => {
          setModalcreateShow(false);
          fetchBankAccounts();
        }}
        formdata={formdata}
        branchID={branchID}
      />

      <ConfirmDeleteTransferModal
        show={modalDeleteShow}
        onHide={() => setModalDeleteShow(false)}
        onSuccess={() => {
          setModalsuccessShow(true);
          fetchBankAccounts();
        }}
        formdata={formdata}
      />

      <SuccessDeleteModal
        show={modalsuccessShow}
        onHide={() => setModalsuccessShow(false)}
      />
    </div>
  );
}
