import React from "react";
import Button from "react-bootstrap/Button";
import { ModalAllder } from "layouts/ModalAllder";

export default function ConfirmDeleteSubCATModal({
  modal,
  setModal,
  categoryData,
  ...props
}) {
  const handleDelete = () => {
    props.onHide();
    props.onConfirm();
  };

  const productCount = 0;
  // const productCount = categoryData?.product?.length || 0;

  return (
    <ModalAllder
      modal={modal}
      setModal={setModal}
      htitle={"ยืนยันการลบหมวดหมู่ย่อย"}
      hbtn={true}
    >
      <div className="py-3">
        <div className="">
          {productCount > 0 ? (
            <div>
              <div>
                <div className="text-center fs-22 tc-7 mb-2">
                  ขออภัยไม่สามารถลบได้
                </div>
                <div className="text-center fs-22 tc-7">
                  เนื่องจากมีสินค้าอยู่ในหมวดหมู่
                </div>
              </div>
              <div className="text-center pt-4 mt-3 btn-group-control">
                <Button
                  type="button"
                  className="btn-st-3 mx-3"
                  style={{ width: "100px" }}
                  onClick={props.onHide}
                >
                  ตกลง
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <div>
                <div className="text-center fs-22 tc-7 mb-2">คุณต้องการลบ</div>
                <div className="text-center fs-22 tc-7">
                  หมวดหมู่ย่อยนี้ใช่หรือไม่
                </div>
              </div>

              <div className="text-center pt-4 mt-3 btn-group-control">
                <Button
                  type="button"
                  className="btn-st-3 mx-3"
                  style={{ width: "100px" }}
                  onClick={handleDelete}
                >
                  ใช่
                </Button>
                <Button
                  type="button"
                  className="btn-st-6 mx-3"
                  style={{ width: "100px" }}
                  onClick={props.onHide}
                >
                  ไม่ใช่
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </ModalAllder>
  );
}
