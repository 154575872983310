import React from "react";
import { Nav, Tab } from "react-bootstrap";
import ProductInformation from "./components/tabs/ProductInfomation";
import ProductFoodRecipes from "./components/tabs/ProductFoodRecipes";
import ProductSettings from "./components/tabs/ProductSettings";
import ProductHistory from "./components/tabs/ProductHistory";

const TAB_ITEMS = [
  { key: "product", label: "ข้อมูลสินค้า", component: ProductInformation },
  { key: "foodrecipes", label: "สูตรอาหาร", component: ProductFoodRecipes },
  { key: "setting", label: "ตั้งค่าขั้นสูง", component: ProductSettings },
  { key: "history", label: "ประวัติ", component: ProductHistory },
];

export function ProductBranchForm() {
  return (
    <div className="p-2.5">
      <Tab.Container id="product-tabs" defaultActiveKey="product">
        <Nav variant="pills" className="mb-3 pills-st-1">
          {TAB_ITEMS.map(({ key, label }) => (
            <Nav.Item key={key}>
              <Nav.Link eventKey={key}>{label}</Nav.Link>
            </Nav.Item>
          ))}
        </Nav>

        <Tab.Content>
          {TAB_ITEMS.map(({ key, component: Component }) => (
            <Tab.Pane key={key} eventKey={key}>
              <Component />
            </Tab.Pane>
          ))}
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}
