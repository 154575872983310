import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { branchPaymentHandler } from "../../../../../redux/handlers";

export default function PaymentPointSetting() {
  const dispatch = useDispatch();
  const { branchID } = useParams();

  const paymentForm = useSelector(
    (state) => state.branchPayment.paymentForm.payment
  );

  const [enablePoints, setEnablePoints] = useState(false);

  useEffect(() => {
    setEnablePoints(paymentForm?.is_discount_received || false);
  }, [paymentForm?.is_discount_received]);

  const handleSubmit = async () => {
    try {
      const paymentPayload = {
        ...paymentForm,
        is_discount_received: enablePoints,
      };

      await dispatch(
        branchPaymentHandler.updateBranchPaymentSetting(
          branchID,
          paymentPayload
        )
      );
    } catch (error) {
      console.error("Failed to update point settings:", error);
    }
  };

  return (
    <div>
      <div
        className="d-flex align-items-center ps-2 pe-0 py-2 mb-4"
        style={{
          width: "283px",
          borderRadius: "5px",
          border: "1px solid rgba(76, 78, 100, 0.22)",
        }}
      >
        <div className="me-2">
          <div className="icon-star"></div>
        </div>
        <div className="flex-fill">
          <div style={{ color: "#2C2C2C" }}>ใช้คะแนนแลกส่วนลด</div>
        </div>
        <div className="ms-2">
          <Form.Check
            type="switch"
            id="usepoint"
            label=""
            name="usepoint"
            className="custom-switch-st-1 tc-10 me-0 s1 mb-1"
            checked={enablePoints}
            onChange={(e) => setEnablePoints(e.target.checked)}
          />
        </div>
      </div>

      <div className="text-end pt-5 pb-3 btn-group-control">
        <Button
          type="submit"
          className="btn-st-3"
          style={{ width: "100px" }}
          onClick={handleSubmit}
        >
          บันทึก
        </Button>
      </div>
    </div>
  );
}
