import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { branchPaymentHandler } from "../../../../../redux/handlers";

export default function PaymentCashSetting() {
  const dispatch = useDispatch();
  const { branchID } = useParams();

  const paymentForm = useSelector(
    (state) => state.branchPayment.paymentForm.payment
  );

  const [paymentsetting, setPaymentsetting] = useState({});

  useEffect(() => {
    setPaymentsetting({
      branchID: branchID,
      paymentChannelID: 1,
      status: paymentForm.is_cash_received || false,
    });
  }, [paymentForm]);

  const handleChange = (e) => {
    setPaymentsetting((prev) => ({ ...prev, status: e.target.checked }));
  };

  const handleSubmit = async () => {
    try {
      const payload = {
        ...paymentForm,
        is_cash_received: paymentsetting.status,
      };

      await dispatch(
        branchPaymentHandler.updateBranchPaymentSetting(branchID, payload)
      );
    } catch (error) {
      console.error("Failed to update payment settings:", error);
    }
  };

  return (
    <div>
      <Form.Check
        type="switch"
        id="cashpayment"
        label="เปิดการรับชำระเงินสด"
        name="cashpayment"
        className="custom-switch-st-1 tc-10 s1 mb-1"
        checked={paymentsetting.status}
        onChange={handleChange}
      />

      <div className="text-end mt-5 pt-5 pb-2 btn-group-control">
        <Button
          type="submit"
          className="btn-st-3"
          style={{ width: "100px" }}
          onClick={handleSubmit}
        >
          บันทึก
        </Button>
      </div>
    </div>
  );
}
