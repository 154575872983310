import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import SettingRoundOOffModal from "../modals/SettingRoundOOffModal";

export default function PaymentOtherSetting() {
  const { branchID } = useParams();
  const [modalShow, setModalShow] = useState(false);

  const paymentForm = useSelector((state) => state.branchPayment.paymentForm);

  const [roundoffSetting, setRoundoffSetting] = useState({
    roundoff: paymentForm?.rounding?.rounding_setting_id || 1,
    roundoffText: "ปัดเศษตามหลักสากล",
  });

  useEffect(() => {
    setRoundoffSetting((prev) => ({
      ...prev,
      roundoff: paymentForm?.rounding?.rounding_setting_id || 1,
    }));
  }, [paymentForm?.rounding?.rounding_setting_id]);

  return (
    <div>
      <div
        className="d-flex align-items-center ps-2 pe-3 py-2 mb-4"
        style={{
          width: "283px",
          borderRadius: "5px",
          border: "1px solid rgba(76, 78, 100, 0.22)",
        }}
      >
        <div className="me-2">
          <div className="icon-setting"></div>
        </div>
        <div className="flex-fill">
          <div style={{ color: "#2C2C2C" }}>{roundoffSetting.roundoffText}</div>
        </div>
        <div className="ms-2">
          <button
            type="button"
            className="btn-edit-st-1"
            onClick={() => setModalShow(true)}
          />
        </div>
      </div>

      <SettingRoundOOffModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setting={roundoffSetting}
        branchID={branchID}
        onSuccess={(newSetting) => {
          setRoundoffSetting(newSetting);
          setModalShow(false);
        }}
      />
    </div>
  );
}
