import React, { useState, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import { branchKitchenHandler } from "../../../redux/handlers";
import { AllderTable } from "../../../components/AllderTable";
import { createTableColumns } from "../../../utils/createTableColumns";
import { useGlobalTable } from "../../../hooks/useGlobalTable";
import ConfirmDeleteModal from "./components/modals/ConfirmDeleteModal";
import SuccessModal from "./components/modals/SuccessModal";

export function Kitchen() {
  const { branchID, businessSlug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const columns = createTableColumns(
    [
      {
        field: "name",
        headerName: "ชื่อห้องครัว",
        flex: 2,
      },
      {
        field: "branchKitchenProduct",
        headerName: "จำนวนรายการเมนู",
        flex: 2,
        renderCell: ({ row }) => (
          <div>
            {row.branchKitchenProduct ? row.branchKitchenProduct.length : 0}
          </div>
        ),
      },
      {
        field: "actions",
        headerName: "จัดการ",
        width: 120,
        sortable: false,
        type: "actions",
      },
    ],
    (row) => (
      <div>
        <Button
          className="btn-edit-st-1"
          onClick={() => navigate(`manage/${row.id}`)}
        ></Button>
        <Button
          className="btn-delete-st-3 ml-2"
          onClick={() => handleDelete(row.id)}
        ></Button>
      </div>
    )
  );

  const handleDelete = (id) => {
    const kitchenToDelete = kitchen?.find((item) => item.id === id);
    const kitchenName = kitchenToDelete?.name || "ครัวนี้";

    setItemToDelete({
      id: id,
      name: kitchenName,
    });
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(branchKitchenHandler.deleteKitchen(itemToDelete.id));
    refreshKitchen();
    setShowDeleteModal(false);
    setShowSuccessModal(true);
    setSuccessMessage("ลบครัวสำเร็จ");
  };

  const handleExport = () => {
    dispatch(
      branchKitchenHandler.downloadKitchenExcel(branchID, filterParams.s)
    );
  };

  const fetchKitchenList = useCallback(
    (params) => {
      return dispatch(
        branchKitchenHandler.listKitchen({ branchId: branchID, ...params })
      );
    },
    [branchID, dispatch]
  );

  const {
    data: kitchen,
    loading,
    totalRows,
    paginationModel,
    setPaginationModel,
    handleSort,
    debouncedSearch,
    filterParams,
    refreshData: refreshKitchen,
  } = useGlobalTable({
    fetchData: fetchKitchenList,
    defaultSort: "created_at",
    defaultOrder: "desc",
    defaultPageSize: 20,
  });

  return (
    <div style={{ padding: "20px 20px 20px 20px" }}>
      <div className="card card-st-2">
        <div className="card-body px-0 py-4">
          <div className="tc-7 fs-20 fw-6 px-4 mb-3">จัดการครัว</div>
          <div className="d-flex align-items-center px-4 mb-4">
            <button
              className="btn btn-export-st-1 me-auto"
              onClick={() => handleExport()}
            >
              Export
            </button>
            <div className="me-3">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหา ชื่อครัว"
                onKeyUp={(e) => debouncedSearch(e.target.value)}
              />
            </div>
            <Link
              to={`/${businessSlug}/branch/setting/kitchen/${branchID}/manage`}
              className="btn btn btn-st-7"
            >
              เพิ่มครัว
            </Link>
          </div>

          <AllderTable
            data={kitchen}
            loading={loading}
            columns={columns}
            totalRows={totalRows}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            handleSort={handleSort}
          />
        </div>
      </div>
      <ConfirmDeleteModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onSuccess={handleConfirmDelete}
        displayName={itemToDelete?.name}
        kitchenContext={true}
      />
      <SuccessModal
        show={showSuccessModal}
        onHide={() => {
          setShowSuccessModal(false);
          refreshKitchen();
        }}
        textsuccess={successMessage}
      />
    </div>
  );
}
