import Form from "react-bootstrap/Form";
import Select from "react-select";
import { useSelector } from "react-redux";

const SubDistrict = (props) => {
  const subDistricts = useSelector((state) => state.master.subDistricts);
  const { required = false, errors = {}, touched = {} } = props;

  return (
    <Form.Group className="mb-2 form-st-3 pb-1">
      <Form.Label className="mb-1">
        แขวง/ตำบล <span className="text-danger ms-1">*</span>
      </Form.Label>
      <Select
        as="select"
        name="subDistrictId"
        className="w-100 select-st-1"
        options={
          subDistricts?.map((sd) => ({
            value: sd.id.toString(),
            label: sd.name_th,
          })) || []
        }
        placeholder="เลือกแขวง/ตำบล"
        isDisabled={!props.values.districtId || props.isReadOnly}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor:
              touched.subDistrictId && errors.subDistrictId
                ? "#dc3545"
                : baseStyles.borderColor,
            "&:hover": {
              borderColor:
                touched.subDistrictId && errors.subDistrictId
                  ? "#dc3545"
                  : baseStyles["&:hover"]?.borderColor,
            },
          }),
        }}
        onChange={(e) => {
          props.setFieldValue("subDistrictId", e.value);

          const zipcode = subDistricts.find((sd) => sd.id == e.value).zip_code;
          props.setFieldValue("potalcode", zipcode);
        }}
        value={
          subDistricts
            ?.filter((sd) => sd.id == props.values.subDistrictId)
            .map((sd) => ({
              value: sd.id.toString(),
              label: sd.name_th,
            })) || null
        }
      />
      {touched.subDistrictId && errors.subDistrictId && (
        <div className="invalid-feedback d-block">{errors.subDistrictId}</div>
      )}
    </Form.Group>
  );
};

export default SubDistrict;
