import { setData } from "../slices/settingGeneral.slice";
import { ENDPOINT, STATUS } from "../../constants/server/api";
import { fetchApiData } from "../../utils/apiUtils";

export const getEmployeeDepartmentById = (id) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.EMPLOYEE_DEPARTMENT}/${id}`,
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const createEmployeeDepartment = (requestData) => async (dispatch) => {
  const result = await fetchApiData({
    method: "post",
    endpoint: ENDPOINT.EMPLOYEE_DEPARTMENT,
    data: requestData,
    dispatch,
  });

  if (result) {
    return result;
  }
};

export const updateEmployeeDepartment =
  (id, requestData) => async (dispatch) => {
    const result = await fetchApiData({
      method: "put",
      endpoint: `${ENDPOINT.EMPLOYEE_DEPARTMENT}/${id}`,
      data: requestData,
      dispatch,
    });

    if (result) {
      return STATUS.OK;
    }
  };

export const getEmployeeDepartments = (params) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.EMPLOYEE_DEPARTMENT}/pagination/${params.business_slug}/list`,
    params: {
      page: params.page,
      perPage: params.per_page,
      s: params.s,
      sortBy: params.sortBy,
      sortOrder: params.sortOrder,
    },
    dispatch,
  });

  if (data) {
    dispatch(setData(data.data));
    return data;
  }
};

export const exportEmployeeDepartmentToExcel =
  (businessSlug, searchTerm) => async (dispatch) => {
    const data = await fetchApiData({
      method: "post",
      endpoint: `${ENDPOINT.EMPLOYEE_DEPARTMENT}/${businessSlug}/excel`,
      params: {
        s: searchTerm || "",
      },
      responseType: "blob",
      dispatch,
    });

    if (data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "รายการแผนก.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }
  };

export const deleteEmployeeDepartment = (id) => async (dispatch) => {
  const result = await fetchApiData({
    method: "delete",
    endpoint: `${ENDPOINT.EMPLOYEE_DEPARTMENT}/${id}`,
    dispatch,
  });

  if (result) {
    return STATUS.OK;
  }
};

export const getEmployeePositionById = (id) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.EMPLOYEE_POSITION}/${id}`,
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const createEmployeePosition = (requestData) => async (dispatch) => {
  const result = await fetchApiData({
    method: "post",
    endpoint: ENDPOINT.EMPLOYEE_POSITION,
    data: requestData,
    dispatch,
  });

  if (result) {
    return result;
  }
};

export const updateEmployeePosition = (id, requestData) => async (dispatch) => {
  const result = await fetchApiData({
    method: "put",
    endpoint: `${ENDPOINT.EMPLOYEE_POSITION}/${id}`,
    data: requestData,
    dispatch,
  });

  if (result) {
    return STATUS.OK;
  }
};

export const deleteEmployeePosition = (id) => async (dispatch) => {
  const result = await fetchApiData({
    method: "delete",
    endpoint: `${ENDPOINT.EMPLOYEE_POSITION}/${id}`,
    dispatch,
  });

  if (result) {
    return STATUS.OK;
  }
};

export const getEmployeePositions = (params) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.EMPLOYEE_POSITION}/pagination/${params.business_slug}/list`,
    params: {
      page: params.page,
      perPage: params.per_page,
      s: params.s,
      sortBy: params.sortBy,
      sortOrder: params.sortOrder,
    },
    dispatch,
  });

  if (data) {
    dispatch(setData(data.data));
    return data;
  }
};

export const exportEmployeePositionToExcel =
  (businessSlug, searchTerm) => async (dispatch) => {
    const data = await fetchApiData({
      method: "post",
      endpoint: `${ENDPOINT.EMPLOYEE_POSITION}/${businessSlug}/excel`,
      params: {
        s: searchTerm || "",
      },
      responseType: "blob",
      dispatch,
    });

    if (data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "รายการตำแหน่งพนักงาน.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }
  };

export const getEmployeeById = (id) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.USER_BUSINESS_GROUP}/employee/${id}`,
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const exportEmployeeToExcel =
  (businessSlug, searchTerm, statusResign) => async (dispatch) => {
    const data = await fetchApiData({
      method: "post",
      endpoint: `${ENDPOINT.USER_BUSINESS_GROUP}/employee/excel/${businessSlug}`,
      params: {
        s: searchTerm || "",
        statusResign: statusResign || "",
      },
      responseType: "blob",
      dispatch,
    });

    if (data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        statusResign == "ALL"
          ? "รายชื่อพนักงาน.xlsx"
          : "รายชื่อพนักงานที่ลาออก.xlsx"
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }
  };

export const getEmployees = (params) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.USER_BUSINESS_GROUP}/employee/pagination/${params.business_slug}/list`,
    params: {
      page: params.page,
      perPage: params.per_page,
      s: params.s,
      sortBy: params.sortBy,
      sortOrder: params.sortOrder,
      statusResign: params.statusResign,
    },
    dispatch,
  });

  if (data) {
    dispatch(setData(data.data));
    return data;
  }
};

export const createEmployee =
  (businessSlug, requestData) => async (dispatch) => {
    const result = await fetchApiData({
      method: "post",
      endpoint: `${ENDPOINT.USER_BUSINESS_GROUP}/employee/${businessSlug}`,
      data: requestData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      dispatch,
    });

    if (result) {
      return STATUS.OK;
    }
  };

export const updateEmployee = (id, requestData) => async (dispatch) => {
  const result = await fetchApiData({
    method: "put",
    endpoint: `${ENDPOINT.USER_BUSINESS_GROUP}/employee/${id}`,
    data: requestData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    dispatch,
  });

  if (result) {
    return STATUS.OK;
  }
};

export const updateEmployeeEmail = (id, requestData) => async (dispatch) => {
  const result = await fetchApiData({
    method: "put",
    endpoint: `${ENDPOINT.USER_BUSINESS_GROUP}/employee/${id}/email`,
    data: requestData,
    dispatch,
  });

  if (result) {
    return STATUS.OK;
  }
};

export const updateEmployeePassword = (id, requestData) => async (dispatch) => {
  const result = await fetchApiData({
    method: "put",
    endpoint: `${ENDPOINT.USER_BUSINESS_GROUP}/employee/${id}/password`,
    data: requestData,
    dispatch,
  });

  if (result) {
    return STATUS.OK;
  }
};

export const updateEmployeePin = (id, requestData) => async (dispatch) => {
  const result = await fetchApiData({
    method: "put",
    endpoint: `${ENDPOINT.USER_BUSINESS_GROUP}/employee/${id}/pin`,
    data: requestData,
    dispatch,
  });

  if (result) {
    return STATUS.OK;
  }
};

export const updateEmployeeResign = (id, requestData) => async (dispatch) => {
  const result = await fetchApiData({
    method: "put",
    endpoint: `${ENDPOINT.USER_BUSINESS_GROUP}/employee/${id}/resign`,
    data: requestData,
    dispatch,
  });

  if (result) {
    return STATUS.OK;
  }
};

export const updateEmployeeUnResign = (id) => async (dispatch) => {
  const result = await fetchApiData({
    method: "put",
    endpoint: `${ENDPOINT.USER_BUSINESS_GROUP}/employee/${id}/unresign`,
    data: {
      empIsResign: false,
    },
    dispatch,
  });

  if (result) {
    return STATUS.OK;
  }
};

export const exportEmployeesToExcel =
  (businessSlug, searchTerm) => async (dispatch) => {
    const data = await fetchApiData({
      method: "post",
      endpoint: `${ENDPOINT.USER_BUSINESS_GROUP}/excel/${businessSlug}`,
      params: {
        s: searchTerm || "",
      },
      responseType: "blob",
      dispatch,
    });

    if (data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "รายการพนักงาน.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }
  };

export const getEmployeesRole = (params) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.USER_BUSINESS_GROUP}/role/pagination/${params.business_slug}/list`,
    params: {
      page: params.page,
      perPage: params.per_page,
      s: params.s,
      sortBy: params.sortBy,
      sortOrder: params.sortOrder,
    },
    dispatch,
  });

  if (data) {
    dispatch(setData(data.data));
    return data;
  }
};

export const getEmployeesHasNotRole = (businessSlug) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.USER_BUSINESS_GROUP}/role/all/${businessSlug}/list`,
    params: {},
    dispatch,
  });

  if (data) {
    dispatch(setData(data.data));
    return data;
  }
};

export const exportEmployeeRoleToExcel =
  (businessSlug, searchTerm) => async (dispatch) => {
    const data = await fetchApiData({
      method: "post",
      endpoint: `${ENDPOINT.USER_BUSINESS_GROUP}/role/excel/${businessSlug}`,
      params: {
        s: searchTerm || "",
      },
      responseType: "blob",
      dispatch,
    });

    if (data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "รายการผู้ใช้งานธุรกิจ.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }
  };

export const createEmployeeRole = (requestData) => async (dispatch) => {
  const result = await fetchApiData({
    method: "post",
    endpoint: `${ENDPOINT.USER_BUSINESS_GROUP}/role/create`,
    data: requestData,
    dispatch,
  });

  if (result) {
    return result;
  }
};

export const updateEmployeeRole = (id, requestData) => async (dispatch) => {
  const result = await fetchApiData({
    method: "put",
    endpoint: `${ENDPOINT.USER_BUSINESS_GROUP}/role/update/${id}`,
    data: requestData,
    dispatch,
  });

  if (result) {
    return result;
  }
};

export const deleteEmployeeRole = (id) => async (dispatch) => {
  const result = await fetchApiData({
    method: "delete",
    endpoint: `${ENDPOINT.USER_BUSINESS_GROUP}/role/delete/${id}`,
    dispatch,
  });

  if (result) {
    return STATUS.OK;
  }
};
