import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  prefixNames: null,
  shopProductCategories: null,
  restaurantTypes: null,
  entityTypes: null,
  provinces: null,
  districts: null,
  subDistricts: null,
  currencies: null,
  vatCalculations: null,
  codeSettingDays: [],
  codeSettingMonths: [],
  codeSettingYears: [],
  counterSettingMaster: [],
  countUnitTypes: [],
  employeeStatuses: null,
  employeeTypes: null,
  maritalStatuses: null,
  militaryStatuses: null,
  relationshipTypes: null,
  salaryTypes: null,
  taxBranches: null,
  vatTypes: null,
  accountTypes: null,
  banks: [],
  genders: [],
  businessRoles: [],
  employeeDepartments: [],
  employeePositions: [],
  roundingList: [],

  entityStructures: [],
  currentEntityStructure: null,
  entityStructuresByType: [],
};

const masterSlice = createSlice({
  name: "master",
  initialState,
  reducers: {
    setPrefixNames: (state, action) => {
      state.prefixNames = action.payload;
    },
    setShopProductCategories: (state, action) => {
      state.shopProductCategories = action.payload;
    },
    setRestaurantTypes: (state, action) => {
      state.restaurantTypes = action.payload;
    },
    setEntityTypes: (state, action) => {
      state.entityTypes = action.payload;
    },
    setProvinces: (state, action) => {
      state.provinces = action.payload;
    },
    setDistricts: (state, action) => {
      state.districts = action.payload;
    },
    setSubDistricts: (state, action) => {
      state.subDistricts = action.payload;
    },
    setCurrencies: (state, action) => {
      state.currencies = action.payload;
    },
    setVatCalculations: (state, action) => {
      state.vatCalculations = action.payload;
    },
    setCodeSettingDays: (state, action) => {
      state.codeSettingDays = action.payload;
    },
    setCodeSettingMonths: (state, action) => {
      state.codeSettingMonths = action.payload;
    },
    setCodeSettingYears: (state, action) => {
      state.codeSettingYears = action.payload;
    },
    setCounterSettingMaster: (state, action) => {
      state.counterSettingMaster = action.payload;
    },
    setCountUnitTypes: (state, action) => {
      state.countUnitTypes = action.payload;
    },
    setEmployeeStatuses: (state, action) => {
      state.employeeStatuses = action.payload;
    },
    setEmployeeTypes: (state, action) => {
      state.employeeTypes = action.payload;
    },
    setMaritalStatuses: (state, action) => {
      state.maritalStatuses = action.payload;
    },
    setMilitaryStatuses: (state, action) => {
      state.militaryStatuses = action.payload;
    },
    setRelationshipTypes: (state, action) => {
      state.relationshipTypes = action.payload;
    },
    setSalaryTypes: (state, action) => {
      state.salaryTypes = action.payload;
    },
    setTaxBranches: (state, action) => {
      state.taxBranches = action.payload;
    },
    setVatTypes: (state, action) => {
      state.vatTypes = action.payload;
    },
    setAccountTypes: (state, action) => {
      state.accountTypes = action.payload;
    },
    setBanks: (state, action) => {
      state.banks = action.payload;
    },
    setGenders: (state, action) => {
      state.genders = action.payload;
    },
    setBusinessRoles: (state, action) => {
      state.businessRoles = action.payload;
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    setEmployeeDepartments: (state, action) => {
      state.employeeDepartments = action.payload;
    },
    setEmployeePositions: (state, action) => {
      state.employeePositions = action.payload;
    },
    setRoundingList: (state, action) => {
      state.roundingList = action.payload;
    },
    etEntityStructures: (state, action) => {
      state.entityStructures = action.payload;
    },
    setCurrentEntityStructure: (state, action) => {
      state.currentEntityStructure = action.payload;
    },
    setEntityStructuresByType: (state, action) => {
      state.entityStructuresByType = action.payload;
    },
    resetState: () => initialState,
  },
});

export const {
  setPrefixNames,
  setShopProductCategories,
  setRestaurantTypes,
  setEntityTypes,
  setProvinces,
  setDistricts,
  setSubDistricts,
  setCurrencies,
  setVatCalculations,
  setCodeSettingDays,
  setCodeSettingMonths,
  setCodeSettingYears,
  setCounterSettingMaster,
  setCountUnitTypes,
  setEmployeeStatuses,
  setEmployeeTypes,
  setMaritalStatuses,
  setMilitaryStatuses,
  setRelationshipTypes,
  setSalaryTypes,
  setTaxBranches,
  setVatTypes,
  setAccountTypes,
  setBanks,
  setGenders,
  setBusinessRoles,
  setPermissions,
  setEmployeeDepartments,
  setEmployeePositions,
  setRoundingList,
  setEntityStructures,
  setCurrentEntityStructure,
  setEntityStructuresByType,
  resetState,
} = masterSlice.actions;

export default masterSlice.reducer;
