import React from "react";
import { ModalAllder } from "layouts/ModalAllder";
import Button from "react-bootstrap/Button";

export default function ConfirmDeleteModal({
  modal,
  setModal,
  categoryData,
  onConfirm,
  ...props
}) {
  const subCategoryCount = categoryData?.productSubCategory?.length || 0;
  const productCount = 0;
  // const productCount = categoryData?.product?.length || 0;

  return (
    <ModalAllder
      modal={modal}
      setModal={setModal}
      htitle="ลบหมวดหมู่หลัก"
      hbtn={true}
    >
      <div className="py-3">
        productCount : {productCount}
        {productCount > 0 ? (
          <div>
            <div>
              <div className="text-center fs-22 tc-7 mb-2">
                ขออภัยไม่สามารถลบได้
              </div>
              <div className="text-center fs-22 tc-7">
                เนื่องจากมีสินค้าอยู่ในหมวดหมู่
              </div>
            </div>
            <div className="text-center pt-4 mt-3 btn-group-control">
              <Button
                type="button"
                className="btn-st-3 mx-3"
                style={{ width: "100px" }}
                onClick={props.onHide}
              >
                ตกลง
              </Button>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <div className="mb-2">
              <i className="fas fa-trash-alt text-danger fa-3x"></i>
            </div>
            <h5 className="mb-3">คุณต้องการลบหมวดหมู่หลักนี้ใช่หรือไม่?</h5>
            <p className="mb-1">
              รหัสหมวดหมู่หลัก: {categoryData?.code || "-"}
            </p>
            <p className="mb-1">
              ชื่อหมวดหมู่หลัก: {categoryData?.name || "-"}
            </p>
            <p className="mb-1">จำนวนหมวดหมู่ย่อย: {subCategoryCount}</p>
            <p className="mb-3">จำนวนรายการสินค้า: {productCount}</p>
            <div className="text-center pt-2 pb-3 btn-group-control">
              <Button
                type="button"
                className="btn-st-6 me-3"
                style={{ width: "100px" }}
                onClick={props.onHide}
              >
                ยกเลิก
              </Button>
              <Button
                type="button"
                className="btn-st-5"
                style={{ width: "100px" }}
                onClick={onConfirm}
              >
                ลบ
              </Button>
            </div>
          </div>
        )}
      </div>
    </ModalAllder>
  );
}
