import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";

import { OptionStandard } from "./OptionStandard";
import { OptionProductSpecific } from "./OptionProductSpecific";
import { OptionAnnotation } from "./OptionAnnotation";
import { ConfirmCancelModal, ProductStatusChangeModal, SuccessModal } from "./modals";
import ImageUpload from "./ImageUpload";
import { productHandler, productCategoryHandler, settingProductUnitHandler, userBusinessGroupHandler } from "../../../../redux/handlers";

const INITIAL_FORM_STATE = {
  sku: "",
  barcode: "",
  product_category_id: "",
  product_sub_category_id: "",
  product_sale_unit_id: "",
  imagePath: "",
  name: "",
  is_weight_based: false,
  is_available: false,
  is_visible: false,
  is_excluded_from_promotions: false,
  is_recommended: false,
  price: "",
  price_delivery: "",
  cost: "",
  annotation: "",
  product_type: "GENERAL",
  product_vat_type: "NO_VAT",
  is_status: true,
  setting_is_vat: true,
  userCreate: "",
  dateCreate: new Date().toLocaleDateString("th-TH"),
};

const validationSchema = yup.object().shape({
  name: yup.string().required("กรุณากรอกชื่อสินค้า"),
  sku: yup.string().required("กรุณากรอกรหัสสินค้า"),
  product_category_id: yup.string().required("กรุณาเลือกหมวดหมู่"),
  price: yup.number().typeError("กรุณากรอกราคาสินค้าเป็นตัวเลข").required("กรุณากรอกราคาสินค้า").min(0, "ราคาสินค้าต้องไม่ติดลบ"),
  price_delivery: yup.number().typeError("กรุณากรอกราคาเดลิเวอรี่เป็นตัวเลข").nullable().min(0, "ราคาเดลิเวอรี่ต้องไม่ติดลบ"),
  cost: yup.number().typeError("กรุณากรอกต้นทุนเป็นตัวเลข").nullable().min(0, "ต้นทุนต้องไม่ติดลบ"),
});

export function ProductForm() {
  const { productID, businessSlug } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [modalStates, setModalStates] = useState({
    cancel: false,
    success: false,
    error: false,
    confirmCancelSale: false,
  });
  const [formdisabled, setFormdisabled] = useState(false);
  const [textsuccess, setTextsuccess] = useState("");
  const [formdata, setFormdata] = useState({
    ...INITIAL_FORM_STATE,
    businessSlug,
  });
  const [options, setOptions] = useState({
    category: [],
    subCategory: [],
    unit: [],
  });

  const [vatOptions, setVatOptions] = useState([
    { value: "NO_VAT", label: "ไม่มีภาษี" },
    { value: "ZERO_VAT", label: "VAT 0%" },
    { value: "HAS_VAT", label: "VAT 1%" },
  ]);

  const fetchCategories = async () => {
    try {
      const response = await dispatch(productCategoryHandler.getAllProductCategories(formdata.businessSlug));
      if (response) {
        setOptions((prev) => ({
          ...prev,
          category: response.map((category) => ({
            value: category.id,
            label: category.name,
          })),
        }));
      }
    } catch (error) {
      console.error("Error fetching categories:", error);

      setModalStates((prev) => ({ ...prev, error: true }));
    }
  };

  const fetchSubCategories = async (categoryId) => {
    if (!categoryId) {
      setOptions((prev) => ({ ...prev, subCategory: [] }));
      return;
    }

    try {
      const response = await dispatch(productCategoryHandler.getAllProductSubCategoriesByMainCatId(formdata.businessSlug, categoryId));
      if (response) {
        setOptions((prev) => ({
          ...prev,
          subCategory: response.map((subCategory) => ({
            value: subCategory.id,
            label: subCategory.name,
          })),
        }));
      }
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      setModalStates((prev) => ({ ...prev, error: true }));
    }
  };

  const fetchSaleUnits = async () => {
    try {
      const response = await dispatch(settingProductUnitHandler.getAllProductSaleUnits(businessSlug));
      if (response) {
        setOptions((prev) => ({
          ...prev,
          unit: response.map((unit) => ({
            value: unit.id,
            label: unit.name,
          })),
        }));
      }
    } catch (error) {
      console.error("Error fetching sale units:", error);
      setModalStates((prev) => ({ ...prev, error: true }));
    }
  };

  const fetchUserProfile = async () => {
    try {
      const response = await dispatch(userBusinessGroupHandler.getUserBusinessProfile());

      let userCreate;
      let vatPercent;
      console.log(response);
      userCreate = `${response.first_name} ${response.last_name}`;
      if (response.business) {
        if (response.business.setting_is_vat) {
          setFormdata((prev) => ({ ...prev, setting_is_vat: true }));
          vatPercent = response.business.setting_vat_percen;
        } else {
          setFormdata((prev) => ({ ...prev, setting_is_vat: false }));
        }
      } else if (response.businessGroup?.business.length > 0) {
        const business = response.businessGroup.business.find((e) => e.slug === businessSlug);
        if (business) {
          if (business.setting_is_vat) {
            setFormdata((prev) => ({ ...prev, setting_is_vat: true }));
            vatPercent = business.setting_vat_percen;
          } else {
            setFormdata((prev) => ({ ...prev, setting_is_vat: false }));
          }
        }
      }
      console.log(vatPercent);
      if (vatPercent) {
        setVatOptions([
          { value: "NO_VAT", label: "ไม่มีภาษี" },
          { value: "ZERO_VAT", label: "VAT 0%" },
          { value: "HAS_VAT", label: `VAT ${vatPercent}%` },
        ]);
      }

      setFormdata((prev) => ({ ...prev, userCreate }));
    } catch (error) {
      console.error("Error fetching user profile:", error);
      setModalStates((prev) => ({ ...prev, error: true }));
    }
  };

  useEffect(() => {
    const initializeData = async () => {
      try {
        await Promise.all([fetchCategories(), fetchSaleUnits()]);
        if (productID) {
          const response = await dispatch(productHandler.getProductById(productID));
          if (response) {
            const transformedData = {
              ...formdata,
              ...response,
              imagePath: response.image_path,
              barcode: response.barcode == "null" ? "" : response.barcode,
              dateCreate: response.created_at ? new Date(response.created_at).toLocaleDateString("th-TH") : "",
            };

            if (response.product_category_id) {
              await fetchSubCategories(response.product_category_id);
            }

            setFormdata(transformedData);
            setFormdisabled(true);
          }
        }

        await fetchUserProfile();
      } catch (error) {
        console.error("Error initializing data:", error);
        setModalStates((prev) => ({ ...prev, error: true }));
      }
    };

    initializeData();
  }, [productID, dispatch]);

  const handleCategoryChange = async (setFieldValue, selectedOption) => {
    setFieldValue("product_category_id", selectedOption?.value || "");
    setFieldValue("product_sub_category_id", "");
    await fetchSubCategories(selectedOption?.value);
  };

  const handleSubmit = async (values) => {
    try {
      const submitData = {
        ...values,
        is_active: true,
        businessSlug,
      };

      const response = productID ? await dispatch(productHandler.updateProduct(productID, submitData)) : await dispatch(productHandler.createProduct(submitData));

      if (response) {
        if (productID) {
          setTextsuccess("แก้ไขรายการสินค้าสำเร็จ");
          setModalStates((prev) => ({ ...prev, success: true }));
          setFormdisabled(true);
        } else {
          navigate(`/${businessSlug}/products/edit/${response.id}`);
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setModalStates((prev) => ({ ...prev, error: true }));
    }
  };

  const handleCancel = () => {
    if (productID) {
      setFormdisabled(true);
      setFormdata((prev) => ({ ...prev }));
    } else {
      navigate(`/${businessSlug}/products`);
    }
    setModalStates((prev) => ({ ...prev, cancel: false }));
  };

  const handleConfirmAction = async (actionType, setFieldValue) => {
    try {
      if (actionType === "delete") {
        await dispatch(productHandler.deleteProduct(formdata.id));
        setTextsuccess("ยกเลิกการขายสำเร็จ");
        setFieldValue("is_status", false);
      } else if (actionType === "recover") {
        await dispatch(productHandler.recoverProduct(formdata.id));
        setTextsuccess("เปิดการขายสินค้าสำเร็จ");
        setFieldValue("is_status", true);
      }
      setModalStates((prev) => ({
        ...prev,
        confirmCancelSale: false,
        success: true,
      }));
    } catch (error) {
      console.error("Error handling product action:", error);
      setModalStates((prev) => ({ ...prev, error: true }));
    }
  };

  return (
    <div>
      <Formik enableReinitialize initialValues={formdata} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ handleSubmit, handleChange, handleBlur, touched, errors, values, setFieldValue, isSubmitting }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="card card-st-3 mb-3">
              <div className="card-body px-xl-3">
                <div className="text-[#2DC76D] fs-18 fw-bold px-3">ข้อมูลสินค้า</div>
                {productID && !values.is_status && (
                  <div className="d-flex flex-wrap align-items-center align-items-center px-3 mt-4">
                    <div className="me-3 alert-st-1">
                      สินค้านี้ถูกยกเลิกขายไปเมื่อวันที่ {new Date(values.updated_at).toLocaleDateString("th-TH")}
                      {values.userCancel}{" "}
                    </div>
                    <button
                      type="button"
                      className="btn  btn-st-3"
                      onClick={() => {
                        setModalStates((prev) => ({
                          ...prev,
                          confirmCancelSale: true,
                        }));
                      }}
                      style={{
                        background: "#F95057",
                        borderColor: "#F95057",
                      }}
                    >
                      ขายอีกครั้ง
                    </button>
                  </div>
                )}

                <fieldset disabled={formdisabled || isSubmitting || !values.is_status}>
                  <div>
                    <ImageUpload
                      setFieldValue={setFieldValue}
                      values={values}
                      isCreate={!productID}
                      errors={errors}
                      touched={touched}
                      onUploadSuccess={(fileInfo) => {
                        setFieldValue("imagePath", fileInfo.base64);
                      }}
                      onUploadError={(error) => {
                        console.error("Upload error:", error);
                        setModalStates((prev) => ({ ...prev, error: true }));
                      }}
                    />

                    <div className="pt-3 px-3">
                      <div className="row gx-xl-4">
                        <div className="col-xl-3">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">
                              รหัสสินค้า <span className="text-danger ms-1">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="sku"
                              className="form-control"
                              placeholder="รหัสสินค้า"
                              value={values.sku}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.sku && !!errors.sku}
                            />
                            <Form.Control.Feedback type="invalid">{errors.sku}</Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-sm-3">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">บาร์โค้ด</Form.Label>
                            <Form.Control
                              type="text"
                              name="barcode"
                              className="form-control icon-barcode"
                              placeholder="บาร์โค้ด"
                              value={values.barcode}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.barcode && !!errors.barcode}
                            />
                            <Form.Control.Feedback type="invalid">{errors.barcode}</Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-xl-6">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">
                              ชื่อสินค้า <span className="text-danger ms-1">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="name"
                              className="form-control"
                              placeholder="ชื่อสินค้า"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.name && !!errors.name}
                            />
                            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-xl-3">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">
                              หมวดหมู่หลัก <span className="text-danger ms-1">*</span>
                            </Form.Label>
                            <Select
                              name="product_category_id"
                              onChange={(selectedOption) => {
                                handleCategoryChange(setFieldValue, selectedOption);
                              }}
                              onBlur={handleBlur}
                              className={`w-100 select-st-1 ${touched.product_category_id && errors.product_category_id ? "is-invalid" : ""}`}
                              options={options.category}
                              placeholder="เลือกหมวดหมู่หลัก"
                              value={options.category.find((item) => item.value === values.product_category_id)}
                            />
                            {touched.product_category_id && errors.product_category_id && <div className="invalid-feedback d-block">{errors.product_category_id}</div>}
                          </Form.Group>
                        </div>

                        <div className="col-xl-3">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">หมวดหมู่ย่อย</Form.Label>
                            <Select
                              name="product_sub_category_id"
                              onChange={(e) => {
                                setFieldValue("product_sub_category_id", e?.value || "");
                              }}
                              onBlur={handleBlur}
                              className="w-100 select-st-1"
                              options={options.subCategory}
                              placeholder="เลือกหมวดหมู่ย่อย"
                              isDisabled={!values.product_category_id}
                              value={options.subCategory.find((item) => item.value === values.product_sub_category_id)}
                            />
                          </Form.Group>
                        </div>

                        <div className="col-xl-3">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">หน่วยขาย</Form.Label>
                            <Select
                              name="product_sale_unit_id"
                              onChange={(e) => {
                                setFieldValue("product_sale_unit_id", e?.value || "");
                              }}
                              onBlur={handleBlur}
                              className="w-100 select-st-1"
                              options={options.unit}
                              placeholder="เลือกหน่วยขาย"
                              value={options.unit.find((item) => item.value === values.product_sale_unit_id)}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-4 mt-4">
                    <div className="text-[#2DC76D] fs-18 fw-bold px-3 mb-4 pt-1">ตั้งค่าการขาย</div>

                    <div className="d-flex flex-wrap px-3">
                      <div className="mb-2 me-7">
                        <Form.Check
                          type="switch"
                          id="is_available_switch"
                          label="พร้อมจำหน่าย"
                          name="is_available"
                          className="tc-10 s2 mb-1"
                          checked={values.is_available}
                          onChange={(e) => {
                            setFieldValue("is_available", e.target.checked);
                          }}
                        />
                      </div>

                      <div className="mb-2 me-7">
                        <Form.Check
                          type="switch"
                          id="is_visible_switch"
                          label="แสดงหน้าจอลูกค้า"
                          name="is_visible"
                          className="tc-10 s2 mb-1"
                          checked={values.is_visible}
                          onChange={(e) => {
                            setFieldValue("is_visible", e.target.checked);
                          }}
                        />
                      </div>

                      <div className="mb-2 me-7">
                        <Form.Check
                          type="switch"
                          id="is_excluded_promotions_switch"
                          label="ไม่เข้าร่วมทุกโปรโมชั่น"
                          name="is_excluded_from_promotions"
                          className="tc-10 s2 mb-1"
                          checked={values.is_excluded_from_promotions}
                          onChange={(e) => {
                            setFieldValue("is_excluded_from_promotions", e.target.checked);
                          }}
                        />
                      </div>

                      <div className="mb-2 me-7">
                        <Form.Check
                          type="switch"
                          id="is_recommended_switch"
                          label="เมนูแนะนำ"
                          name="is_recommended"
                          className="tc-10 s2 mb-1"
                          checked={values.is_recommended}
                          onChange={(e) => {
                            setFieldValue("is_recommended", e.target.checked);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="text-[#2DC76D] fs-18 fw-bold px-3 mb-2 pt-1">ราคาขาย</div>
                    <div className="px-xl-3 row">
                      <div className="col-xl-3">
                        <Form.Group className="mb-2 form-st-3 pb-1">
                          <Form.Label className="mb-1">
                            ราคาสินค้า <span className="text-danger ms-1">*</span>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name="price"
                            className="form-control"
                            placeholder="ราคาสินค้า"
                            value={values.price}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.price && !!errors.price}
                          />
                          <Form.Control.Feedback type="invalid">{errors.price}</Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <div className="col-xl-3">
                        <Form.Group className="mb-2 form-st-3 pb-1">
                          <Form.Label className="mb-1">ราคาขายเดลิเวอรี่</Form.Label>
                          <Form.Control
                            type="number"
                            name="price_delivery"
                            className="form-control"
                            placeholder="ราคาขายเดลิเวอรี่"
                            value={values.price_delivery}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.price_delivery && !!errors.price_delivery}
                          />
                          <Form.Control.Feedback type="invalid">{errors.price_delivery}</Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <div className="col-xl-3">
                        <Form.Group className="mb-2 form-st-3 pb-1">
                          <Form.Label className="mb-1">ต้นทุนมาตรฐาน</Form.Label>
                          <Form.Control
                            type="number"
                            name="cost"
                            className="form-control"
                            placeholder="ต้นทุนมาตรฐาน"
                            value={values.cost}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.cost && !!errors.cost}
                          />
                          <Form.Control.Feedback type="invalid">{errors.cost}</Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      {values.setting_is_vat && <></>}
                      <div className="col-xl-3">
                        <Form.Group className="mb-2 form-st-3 pb-1">
                          <Form.Label className="mb-1">รูปแบบภาษีมูลค่าเพิ่ม</Form.Label>
                          <Select
                            name="product_vat_type"
                            onChange={(e) => {
                              setFieldValue("product_vat_type", e?.value || "NO_VAT");
                            }}
                            className="w-100 select-st-1"
                            options={vatOptions}
                            placeholder="เลือกรูปแบบภาษีมูลค่าเพิ่ม"
                            value={vatOptions.find((item) => item.value === values.product_vat_type)}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>

                  <div className="px-xl-3 mt-3">
                    <div className="text-[#4C4E64]/[0.87] mb-2">หมายเหตุภายใน :</div>
                    <div className="row">
                      <div className="col-xl-6">
                        <Form.Group className="mb-2 form-st-3 pb-1">
                          <Form.Label className="mb-1">หมายเหตุ</Form.Label>
                          <Form.Control
                            type="text"
                            name="annotation"
                            className="form-control"
                            placeholder="หมายเหตุ"
                            value={values.annotation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.annotation && !!errors.annotation}
                          />
                          <Form.Control.Feedback type="invalid">{errors.annotation}</Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <div className="col-xl-3">
                        <Form.Group className="mb-2 form-st-3 pb-1">
                          <Form.Label className="mb-1">วันที่สร้าง</Form.Label>
                          <Form.Control type="text" name="dateCreate" className="form-control" placeholder="วันที่สร้าง" value={values.dateCreate} disabled={true} />
                        </Form.Group>
                      </div>

                      <div className="col-xl-3">
                        <Form.Group className="mb-2 form-st-3 pb-1">
                          <Form.Label className="mb-1">ผู้แก้ไข</Form.Label>
                          <Form.Control type="text" name="userCreate" className="form-control" placeholder="ผู้แก้ไข" value={values.userCreate} disabled={true} />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </fieldset>

                <div className="text-end flex items-center pb-4 pe-3 mt-4">
                  {productID && values.is_status && (
                    <button
                      type="button"
                      className="btn btn-st-3 ml-4 me-auto mt-2"
                      onClick={() =>
                        setModalStates((prev) => ({
                          ...prev,
                          confirmCancelSale: true,
                        }))
                      }
                      disabled={isSubmitting}
                      style={{
                        background: "#F95057",
                        borderColor: "#F95057",
                      }}
                    >
                      ยกเลิกการขาย
                    </button>
                  )}

                  {values.is_status &&
                    (formdisabled ? (
                      <Button type="button" className="btn-st-3 mr-3 ml-auto" onClick={() => setFormdisabled(false)} disabled={isSubmitting}>
                        แก้ไข
                      </Button>
                    ) : (
                      <div className="text-end pt-3 btn-group-control ml-auto">
                        <Button type="button" className="btn-st-6 me-3 btn" onClick={() => setFormdisabled(true)} disabled={isSubmitting}>
                          ยกเลิก
                        </Button>
                        <Button type="submit" className="btn-st-3" disabled={isSubmitting}>
                          {isSubmitting ? "กำลังบันทึก..." : "บันทึก"}
                        </Button>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <ConfirmCancelModal show={modalStates.cancel} onHide={() => setModalStates((prev) => ({ ...prev, cancel: false }))} onConfirm={handleCancel} />

            <ProductStatusChangeModal
              show={modalStates.confirmCancelSale}
              onHide={() =>
                setModalStates((prev) => ({
                  ...prev,
                  confirmCancelSale: false,
                }))
              }
              formdata={values}
              onConfirm={(actionType) => handleConfirmAction(actionType, setFieldValue)}
            />

            <SuccessModal show={modalStates.success} onHide={() => setModalStates((prev) => ({ ...prev, success: false }))} textsuccess={textsuccess} />
          </Form>
        )}
      </Formik>

      {productID && (
        <>
          <OptionStandard productID={productID} />
          <OptionProductSpecific productID={productID} />
          <OptionAnnotation productID={productID} />
        </>
      )}

      <div className="py-7"></div>
    </div>
  );
}

export default ProductForm;
