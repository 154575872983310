import React from "react";
import { ModalAllder } from "layouts/ModalAllder";
import Button from "react-bootstrap/Button";

export function ConfirmDeletePositionModal({
  modal,
  setModal,
  positionData,
  onConfirm,
  ...props
}) {
  const employeeCount = positionData?.emp_count || 0;

  return (
    <ModalAllder
      modal={modal}
      setModal={setModal}
      htitle="ลบตำแหน่งงาน"
      hbtn={true}
    >
      <div className="py-3">
        {employeeCount > 0 ? (
          <div>
            <div>
              <div className="text-center fs-22 tc-7 mb-2">
                ขออภัยไม่สามารถลบได้
              </div>
              <div className="text-center fs-22 tc-7">
                เนื่องจากมีพนักงานในตำแหน่งนี้
              </div>
            </div>
            <div className="text-center pt-4 mt-3 btn-group-control">
              <Button
                type="button"
                className="btn-st-3 mx-3"
                style={{ width: "100px" }}
                onClick={props.onHide}
              >
                ตกลง
              </Button>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <div>
              <div className="text-center fs-22 tc-7 mb-2">คุณต้องการลบ</div>
              <div className="text-center fs-22 tc-7">
                ตำแหน่งงานนี้ใช่หรือไม่
              </div>
            </div>
            <div className="text-center pt-2 pb-3 btn-group-control mt-6">
              <Button
                type="button"
                className="btn-st-6 me-3"
                style={{ width: "100px" }}
                onClick={props.onHide}
              >
                ยกเลิก
              </Button>
              <Button
                type="button"
                className="btn-st-5"
                style={{ width: "100px" }}
                onClick={onConfirm}
              >
                ลบ
              </Button>
            </div>
          </div>
        )}
      </div>
    </ModalAllder>
  );
}
