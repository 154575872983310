import React from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import _ from "lodash";

export const WorkInfo = ({
  values,
  handleChange,
  setFieldValue,
  errors,
  startDate,
  setStartDate,
  optionsempType,
  optionsempStatus,
  optionsDepartment,
  optionsPosition,
  optionsBranch,
  branchselected,
  handleChangeBranch,
}) => {
  const branchOptions =
    values.row?.emp_work_is_all_branch === true
      ? [{ value: "all", label: "ทุกสาขา" }]
      : _.uniqBy(
          values.userBusinessGroupBranch || [],
          (item) => item.branch.id
        ).map((item) => ({
          value: item.branch_id,
          label: item.branch.branch_name,
        }));
  return (
    <div className="pt-3 px-3">
      <div className="row gx-xl-4">
        {/* Start Date */}
        <div className="col-xl-3 col-md-6">
          <Form.Group className=" mt-[12px]  mb-3">
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              className="h-[40px] "
            >
              <DatePicker
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    InputLabelProps: { shrink: true },
                    error: !!errors.empWorkStartDate,
                  },
                }}
                label="วันที่เริ่มต้นทำงาน"
                className={`h-[40px] w-full `}
                sx={{
                  ".MuiInputBase-formControl": {
                    height: "53px",
                    borderRadius: "8px",
                  },
                  ".MuiFormLabel-root": {
                    fontFamily: "Sarabun",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "1px solid rgba(76, 78, 100, 0.22)",
                  },
                }}
                name="empWorkStartDate"
                value={
                  values.empWorkStartDate
                    ? dayjs(values.empWorkStartDate)
                    : null
                }
                onChange={(date) => {
                  setFieldValue("empWorkStartDate", date);
                }}
              />
            </LocalizationProvider>
          </Form.Group>
        </div>

        {/* End Date */}
        <div className="col-xl-3 col-md-6">
          <Form.Group className=" mt-[12px] mb-3">
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              className="h-[40px] "
            >
              <DatePicker
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    InputLabelProps: { shrink: true },
                    error: !!errors.empWorkEndDate,
                  },
                }}
                label="วันที่สิ้นสุด"
                className={`h-[40px] w-full `}
                disabled={true}
                sx={{
                  ".MuiInputBase-formControl": {
                    height: "53px",
                    borderRadius: "8px",
                  },
                  ".MuiFormLabel-root": {
                    fontFamily: "Sarabun",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "1px solid rgba(76, 78, 100, 0.22)",
                  },
                }}
                name="empWorkEndDate"
                value={
                  values.empWorkEndDate ? dayjs(values.empWorkEndDate) : null
                }
                onChange={(date) => {
                  setFieldValue("empWorkEndDate", date);
                }}
              />
            </LocalizationProvider>
          </Form.Group>
        </div>

        {/* Employee Type */}
        <div className="col-xl-3 col-md-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">ประเภทพนักงาน</Form.Label>
            <Select
              name="empWorkEmployeeTypeId"
              onChange={(e) => setFieldValue("empWorkEmployeeTypeId", e.value)}
              className="w-100 select-st-1"
              options={optionsempType}
              placeholder="ประเภทพนักงาน"
              value={optionsempType?.filter(
                (item) => item.value === values.empWorkEmployeeTypeId
              )}
            />
          </Form.Group>
        </div>

        {/* Employee Status */}
        <div className="col-xl-3 col-md-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">สถานะ</Form.Label>
            <Select
              name="empWorkEmployeeStatusId"
              onChange={(e) =>
                setFieldValue("empWorkEmployeeStatusId", e.value)
              }
              className="w-100 select-st-1"
              options={optionsempStatus}
              placeholder="สถานะ"
              value={optionsempStatus?.filter(
                (item) => item.value === values.empWorkEmployeeStatusId
              )}
            />
          </Form.Group>
        </div>

        {/* Department */}
        <div className="col-xl-3 col-md-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">
              แผนก <span className="text-danger">*</span>
            </Form.Label>
            <Select
              name="empWorkEmployeeDepartmentId"
              onChange={(e) =>
                setFieldValue("empWorkEmployeeDepartmentId", e.value)
              }
              className={`w-100 select-st-1 ${
                errors.empWorkEmployeeDepartmentId ? "is-invalid" : ""
              }`}
              options={optionsDepartment}
              placeholder="แผนก"
              value={optionsDepartment?.filter(
                (item) => item.value == values.empWorkEmployeeDepartmentId
              )}
            />
            {errors.empWorkEmployeeDepartmentId && (
              <div className="invalid-feedback d-block">
                {errors.empWorkEmployeeDepartmentId}
              </div>
            )}
          </Form.Group>
        </div>

        {/* Position */}
        <div className="col-xl-3 col-md-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">
              ตำแหน่ง <span className="text-danger">*</span>
            </Form.Label>
            <Select
              name="empWorkEmployeePositionId"
              onChange={(e) =>
                setFieldValue("empWorkEmployeePositionId", e.value)
              }
              className={`w-100 select-st-1 ${
                errors.empWorkEmployeePositionId ? "is-invalid" : ""
              }`}
              options={optionsPosition}
              placeholder="ตำแหน่ง"
              value={optionsPosition?.filter(
                (item) => item.value == values.empWorkEmployeePositionId
              )}
            />
            {errors.empWorkEmployeePositionId && (
              <div className="invalid-feedback d-block">
                {errors.empWorkEmployeePositionId}
              </div>
            )}
          </Form.Group>
        </div>

        {/* Social Security */}
        <div className="col-xl-6 col-md-12 align-self-center">
          <Form.Check
            name="empWorkIsSocialSecurityRegistration"
            type="checkbox"
            id="empWorkIsSocialSecurityRegistration"
            label="ขึ้นทะเบียนประกันสังคม"
            className="form-check-st-1"
            checked={values.empWorkIsSocialSecurityRegistration === 1}
            onChange={(e) => {
              setFieldValue(
                "empWorkIsSocialSecurityRegistration",
                e.target.checked ? 1 : 0
              );
            }}
          />
        </div>

        {/* Branch Selection */}
        <div className="col-12">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">สาขาที่ประจำ</Form.Label>
            <Select
              isMulti
              name="userBusinessGroupBranch"
              onChange={handleChangeBranch}
              className="w-100 select-st-1 select-multi"
              options={optionsBranch}
              isDisabled={true}
              placeholder="สาขาที่ประจำ"
              value={branchOptions}
              isClearable={false}
              isRemovable={false}
              components={{
                MultiValueRemove: () => null,
              }}
            />
          </Form.Group>
        </div>
      </div>
    </div>
  );
};

export default WorkInfo;
