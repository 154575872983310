import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { branchPaymentHandler } from "../../../../../redux/handlers";
import { useState } from "react";

export default function ConfirmDeleteTransferModal(props) {
  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    if (!props.formdata?.id) return;

    try {
      setIsDeleting(true);
      const response = await dispatch(
        branchPaymentHandler.deleteBranchBankSetting(props.formdata.id)
      );

      if (response) {
        props.onHide();
        props.onSuccess();
      }
    } catch (error) {
      console.error("Error deleting bank account:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Modal {...props} size="md" centered>
      <Modal.Body>
        <div className="px-3 py-4">
          <div>
            <div>
              <div className="text-center fs-22 tc-7 mb-2">คุณต้องการลบ</div>
              <div className="text-center fs-22 tc-7">รายการนี้ใช่หรือไม่</div>
            </div>

            <div className="text-center pt-4 mt-3 btn-group-control">
              <Button
                type="button"
                className="btn-st-3 mx-3"
                style={{ width: "100px" }}
                onClick={handleDelete}
                disabled={isDeleting}
              >
                {isDeleting ? "กำลังลบ..." : "ใช่"}
              </Button>
              <Button
                type="button"
                className="btn-st-6 mx-3"
                style={{ width: "100px" }}
                onClick={props.onHide}
                disabled={isDeleting}
              >
                ไม่ใช่
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
