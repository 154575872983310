import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import Modal from "react-bootstrap/Modal";
import ImageUpload from "../ImageUpload";
import { useDispatch } from "react-redux";
import { branchPaymentHandler } from "../../../../../redux/handlers";
import { useState } from "react";

export default function SettingDeliveryModal(props) {
  const { setModalDeleteShow, formdata, branchID, ...rest } = props;
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    deliveryName: yup.string().required("กรุณากรอกชื่อช่องทาง"),
  });

  const handleSubmit = async (values) => {
    try {
      const formattedData = {
        branchId: Number(branchID),
        name: values.deliveryName,
        imagePath: values.imagePath,
        imageSize: 100,
        isStatus: values.status === 1,
      };

      const response = values.id
        ? await dispatch(
            branchPaymentHandler.updateBranchDeliverySetting(
              values.id,
              formattedData
            )
          )
        : await dispatch(
            branchPaymentHandler.createBranchDeliverySetting(formattedData)
          );

      if (response) {
        props.onSuccess?.();
      }
    } catch (error) {
      console.error("Error saving delivery:", error);
    }
  };

  return (
    <Modal {...rest} size="md" centered>
      <Modal.Body>
        <div className="d-flex align-items-center px-3 py-2 mb-4 pb-3">
          <div className="fs-24 fw-bold me-auto" style={{ color: "#2DC76D" }}>
            ตั้งค่าเดลิเวอรี่
          </div>
          <button
            onClick={props.onHide}
            className="btn btn-close-modal"
          ></button>
        </div>

        <div className="px-3">
          <Formik
            enableReinitialize
            initialValues={formdata}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ชื่อช่องทาง <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="deliveryName"
                    className="form-control"
                    placeholder="กรอกชื่อช่องทาง"
                    value={values.deliveryName}
                    onChange={handleChange}
                    isInvalid={!!errors.deliveryName && touched.deliveryName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.deliveryName}
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="pt-1 pb-2">
                  <ImageUpload
                    setFieldValue={setFieldValue}
                    values={values}
                    onUploadSuccess={(fileInfo) => {
                      setFieldValue("imagePath", fileInfo.base64);
                    }}
                    onUploadError={(error) => {}}
                  />
                </div>

                <Form.Label className="mb-2 tc-10">สถานะ</Form.Label>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="เปิดใช้งาน"
                      name="status"
                      type={type}
                      id={`inline-${type}-21`}
                      className="form-check-st-2 tc-7"
                      checked={values.status === 1}
                      onChange={() => setFieldValue("status", 1)}
                    />
                    <Form.Check
                      inline
                      label="ปิดใช้งาน"
                      name="status"
                      type={type}
                      id={`inline-${type}-22`}
                      className="form-check-st-2 tc-7"
                      checked={values.status === 0}
                      onChange={() => setFieldValue("status", 0)}
                    />
                  </div>
                ))}

                <div className="d-flex align-items-center pt-5 pb-3 btn-group-control">
                  {values.id !== null ? (
                    <Button
                      type="button"
                      className="btn-st-5 me-auto px-4"
                      style={{ width: "90px" }}
                      onClick={props.handleDelete}
                    >
                      ลบ
                    </Button>
                  ) : (
                    <div className="me-auto"></div>
                  )}
                  <Button
                    type="button"
                    className="btn-st-6 me-3"
                    style={{ width: "100px" }}
                    onClick={props.onHide}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="submit"
                    className="btn-st-3"
                    style={{ width: "100px" }}
                  >
                    บันทึก
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}
