import { startLoading, stopLoading, setError } from "../redux/slices/common.slice";
import { http } from "../utils/http";

export const createApiConfig = ({ method = "get", endpoint, data = null, params = null, useAuth = true, contentType = "application/json", responseType = null }) => {
  const token = localStorage.getItem("accessToken");
  const headers = {
    "Content-Type": contentType,
    ...(useAuth && token && { Authorization: `Bearer ${token}` }),
  };

  return {
    method,
    url: endpoint,
    ...(data && { data }),
    ...(params && { params }),
    ...(responseType && { responseType }),
    headers,
  };
};

export const handleApiError = (error, dispatch) => {
  dispatch(setError(error.message ?? error.response?.data?.message));

  if (["NOT_HAS_VERIFY_EMAIL", "USER_NOT_FOUND"].includes(error.message)) {
    return error.message;
  }
  throw error;
};

export const fetchApiData = async ({ method, endpoint, data, params, dispatch, headers, useAuth = true, dismissLoading = false, responseType = null }) => {
  try {
    if (!dismissLoading) {
      dispatch(startLoading());
    }

    const config = createApiConfig({
      method,
      endpoint,
      data,
      params,
      useAuth,
      contentType: headers?.["Content-Type"] || "application/json",
      responseType,
    });

    const response = await http(config);

    if (response?.status === 200 || response?.status === 201) {
      if (responseType === "blob") {
        return response.data;
      }

      if (response.data.data?.access_token) {
        const token = response.data.data.access_token;
        localStorage.setItem("accessToken", token);
        localStorage.setItem("refreshToken", response.data.data.refresh_token);
        http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      }
      return response.data.data;
    }
  } catch (error) {
    return handleApiError(error, dispatch);
  } finally {
    if (!dismissLoading) {
      dispatch(stopLoading()); // Stop loading
    }
  }
};
