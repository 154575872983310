import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { useGlobalTable } from "../../../hooks/useGlobalTable";
import { createTableColumns } from "../../../utils/createTableColumns";
import { AllderTable } from "../../../components/AllderTable";
import { productAnnotationHandler } from "../../../redux/handlers";
import { useBreadcrumb } from "../../../hooks/useBreadcrumb";
import Breadcrumb from "../../../components/Breadcrumb";

export function AnnotationManagement() {
  const dispatch = useDispatch();
  const { businessSlug } = useParams();

  const fetchAnnotations = useCallback(
    (params) =>
      dispatch(
        productAnnotationHandler.getProductAnnotations({
          business_slug: businessSlug,
          ...params,
        })
      ),
    [businessSlug, dispatch]
  );

  const {
    data: annotations,
    loading,
    totalRows,
    paginationModel,
    setPaginationModel,
    handleSort,
    debouncedSearch,
    filterParams,
  } = useGlobalTable({
    fetchData: fetchAnnotations,
    defaultSort: "created_at",
    defaultOrder: "desc",
    defaultPageSize: 20,
  });

  const breadcrumbConfig = [
    { label: "หน้าหลัก", path: "/" },
    { label: "สินค้าและบริการ", path: "/product/options/annotation" },
    { label: "จัดการตัวเลือกหมายเหตุ" },
  ];
  useBreadcrumb(breadcrumbConfig);

  const handleExport = async () => {
    try {
      await dispatch(
        productAnnotationHandler.exportProductAnnotationsToExcel(
          businessSlug,
          filterParams.s
        )
      );
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const columns = createTableColumns(
    [
      {
        field: "code",
        headerName: "รหัสหมายเหตุ",
        width: 170,
        sortable: true,
        renderCell: ({ row }) => (
          <Link
            to={`/${businessSlug}/product/options/annotation/edit/${row.id}`}
            style={{
              color: "#2DC76D",
              fontWeight: 500,
              textDecoration: "underline",
            }}
          >
            {row.code}
          </Link>
        ),
      },
      {
        field: "name",
        headerName: "ชื่อตัวเลือก",
        width: 340,
        sortable: true,
        flex: 1,
        renderCell: ({ row }) => (
          <div style={{ color: "#2DC76D", fontWeight: 500 }}>{row.name}</div>
        ),
      },
      {
        field: "productAnnotationItem",
        headerName: "รายการ",
        width: 400,
        sortable: false,
        renderCell: ({ row }) => (
          <div>
            {row.productAnnotationItem?.map((item, index) => (
              <span key={index}>
                {index > 0 && <span className="px-1">,</span>}
                {item.name}
              </span>
            ))}
          </div>
        ),
      },
      {
        field: "actions",
        headerName: "จัดการ",
        width: 120,
        sortable: false,
        type: "actions",
      },
    ],
    (row) => (
      <div>
        <Link
          to={`/${businessSlug}/product/options/annotation/edit/${row.id}`}
          className="btn btn-edit-st-1"
        />
      </div>
    )
  );

  return (
    <div>
      <Breadcrumb />
      <div className="card card-st-2">
        <div className="card-body px-0 py-4">
          <div className="text-lg font-bold mb-4 lh-1 px-4 mb-4">
            จัดการตัวเลือกหมายเหตุ
          </div>
          <div className="d-flex align-items-center px-4 mb-4">
            <button
              className="btn btn-export-st-1 me-auto"
              onClick={handleExport}
            >
              Export
            </button>
            <div className="me-3">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหาตัวเลือกหมายเหตุ"
                onChange={(e) => debouncedSearch(e.target.value)}
              />
            </div>
            <Link
              to={`/${businessSlug}/product/options/annotation/create`}
              className="btn btn-st-7"
            >
              เพิ่มตัวเลือกหมายเหตุ
            </Link>
          </div>

          <AllderTable
            data={annotations}
            loading={loading}
            columns={columns}
            totalRows={totalRows}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            handleSort={handleSort}
          />
        </div>
      </div>
    </div>
  );
}
