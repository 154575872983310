import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import Form from "react-bootstrap/Form";
import { utils, writeFileXLSX } from "xlsx";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { ModalAllder } from "layouts/ModalAllder";
import { DataGrid } from "@mui/x-data-grid";

export function CustomerGroup() {
  const [groupdata, setGroupdata] = useState([]);
  const [editdata, setEditdata] = useState({});

  const getData = () => {
    fetch("/json/customer_group.json")
      .then((response) => response.json())
      .then((result) => setGroupdata(result))
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getData();
  }, []);

  const columnsd = [
    {
      field: "group_name",
      headerName: "ชื่อกลุ่มลูกค้า",
      width: 170,
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <Link
          to={`/customer-group/${row.id}`}
          style={{ color: "#2DC76D", fontWeight: 500 }}
        >
          {row.group_name}
        </Link>
      ),
    },

    {
      field: "customers",
      headerName: "จำนวนสมาชิก",
      flex: 1,
      width: 140,
      sortable: false,
    },
    {
      field: "status",
      headerName: "สถานะ",
      width: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <div className={"datalist-status status" + row.status}></div>
      ),
    },

    {
      field: "id",
      headerName: "จัดการ",
      width: 250,

      sortable: false,
      renderCell: ({ row }) => (
        <div>
          <Button
            type="button"
            className="btn-edit-st-1 "
            onClick={() => {
              setEditdata(row);
              setModalEdit((prev) => ({ ...prev, open: true }));
            }}
          ></Button>
          <Button
            type="button"
            className="btn-delete-st-3 ml-2 "
            onClick={() => {
              handleDelete(row);
            }}
          ></Button>
        </div>
      ),
    },
  ];

  const [filterText, setFilterText] = useState("");
  const filteredItems = groupdata.filter(
    (item) =>
      item.group_name &&
      item.group_name.toLowerCase().includes(filterText.toLowerCase())
  );

  const downloadExcel = () => {
    const ws = utils.json_to_sheet(filteredItems);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "กลุ่มลูกค้า");
    writeFileXLSX(wb, "กลุ่มลูกค้า.xlsx");
  };

  const handleDelete = (row) => {
    setModalConfirmDelete((prev) => ({ ...prev, open: true }));
  };

  const [modalCreate, setModalCreate] = useState({
    open: false,
    x: 0,
    y: 0,
    width: 460,
    height: 300,
    pin: false,
    mode: "normal",
    modalname: "create",
  });

  const [modalEdit, setModalEdit] = useState({
    open: false,
    x: 0,
    y: 0,
    width: 470,
    height: 300,
    pin: false,
    mode: "normal",
    modalname: "edit",
  });

  const [modalConfirmDelete, setModalConfirmDelete] = useState({
    open: false,
    x: 0,
    y: 0,
    width: 470,
    height: 320,
    pin: false,
    mode: "normal",
    modalname: "edit",
  });

  const [modalSuccess, setModalSuccess] = useState({
    open: false,
    x: 0,
    y: 0,
    width: 470,
    height: 300,
    pin: false,
    mode: "normal",
    modalname: "edit",
  });

  return (
    <div>
      <nav aria-label="breadcrumb" className="mt-2">
        <ol className="breadcrumb fs-16 ps-0">
          <li className="breadcrumb-item ">
            <a href="#" className="tc-7  text-decoration-none">
              สมาชิก/ลูกค้า
            </a>
          </li>
          <li className="breadcrumb-item active tc-1 " aria-current="page">
            {" "}
            จัดการกลุ่มลูกค้า{" "}
          </li>
        </ol>
      </nav>
      <div className="fs-24 tc-7  mb-4 lh-1">จัดการกลุ่มลูกค้า </div>

      <div className="card card-st-2">
        <div className="card-body px-0 py-4">
          <div className="d-flex align-items-center px-4 mb-4">
            <button
              className="btn btn-export-st-1 me-auto"
              onClick={downloadExcel}
            >
              Export
            </button>
            <div className="me-3">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหา"
                onKeyUp={(e) => setFilterText(e.target.value)}
              />
            </div>
            <Button
              type="button"
              className="btn btn btn-st-7"
              onClick={() =>
                setModalCreate((prev) => ({ ...prev, open: true }))
              }
            >
              เพิ่มกลุ่มลูกค้า
            </Button>
          </div>

          <DataGrid
            getRowId={(row) => row.id}
            getRowHeight={() => "auto"}
            rows={filteredItems}
            columns={columnsd}
            // autoHeight={true}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 20, 30]}
            checkboxSelection
            disableRowSelectionOnClick
            slotProps={{
              pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
            }}
            sx={{
              fontFamily: "Sarabun",
              fontSize: "17px",
              ".MuiDataGrid-columnHeaderTitle": {
                fontFamily: "Sarabun",
                fontSize: "17px",
              },
              ".MuiDataGrid-cell": {
                display: "flex",
                alignItems: "center",
                paddingTop: "5px",
                paddingBottom: "5px",
              },
            }}
          />
        </div>
      </div>

      <CreateModal modal={modalCreate} setModal={setModalCreate} />
      <EditModal
        modal={modalEdit}
        setModal={setModalEdit}
        onDelete={() => {
          setModalConfirmDelete((prev) => ({ ...prev, open: true }));
          setModalEdit((prev) => ({ ...prev, open: false }));
        }}
        editdata={editdata}
      />
      <ConfirmDeleteModal
        modal={modalConfirmDelete}
        setModal={setModalConfirmDelete}
        onHide={() =>
          setModalConfirmDelete((prev) => ({ ...prev, open: false }))
        }
        onConfirm={() =>
          setModalConfirmDelete((prev) => ({ ...prev, open: false }))
        }
        onSuccess={() => setModalSuccess((prev) => ({ ...prev, open: true }))}
        editdata={editdata}
      />

      <SuccessDeleteModal
        modal={modalSuccess}
        setModal={setModalSuccess}
        onHide={() => setModalSuccess((prev) => ({ ...prev, open: false }))}
      />
    </div>
  );
}

function CreateModal({ modal, setModal }) {
  const schema = yup.object().shape({
    group_name: yup.string().required(),
  });

  const [groupdata, setGroupdata] = useState({
    group_name: "",
  });

  return (
    <>
      <ModalAllder
        modal={modal}
        setModal={setModal}
        htitle={"เพิ่มกลุ่มลูกค้า"}
        hbtn={true}
      >
        <div className="py-4">
          <div className="">
            <Formik
              enableReinitialize
              initialValues={groupdata}
              validationSchema={schema}
              onSubmit={async (values) => {
                setModal((prev) => ({ ...prev, open: false }));
              }}
            >
              {({
                handleSubmit,
                handleChange,
                touched,
                errors,
                values,
                setFieldValue,
                isInvalid,
                isValid,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="mb-3 form-st-3 pb-1">
                    <Form.Label className="mb-1">
                      ชื่อกลุ่มลูกค้า <span className="text-danger">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="group_name"
                      className="form-control"
                      placeholder="ชื่อกลุ่มลูกค้า"
                      value={values.group_name}
                      onChange={handleChange}
                      isInvalid={!!errors.group_name}
                    />
                  </Form.Group>

                  <div className="text-center pt-3 pb-3 btn-group-control">
                    <Button
                      type="button"
                      className="btn-st-6 me-3 "
                      style={{ width: "100px" }}
                      onClick={() =>
                        setModal((prev) => ({ ...prev, open: false }))
                      }
                    >
                      ยกเลิก
                    </Button>
                    <Button
                      type="submit"
                      className="btn-st-3  "
                      style={{ width: "100px" }}
                    >
                      ยืนยัน
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </ModalAllder>
    </>
  );
}

function EditModal({ modal, setModal, editdata, onDelete }) {
  const schema = yup.object().shape({
    group_name: yup.string().required(),
  });

  return (
    <ModalAllder
      modal={modal}
      setModal={setModal}
      htitle={"แก้ไขกลุ่มลูกค้า"}
      hbtn={true}
    >
      <div className="py-4">
        <div className="">
          <Formik
            enableReinitialize
            initialValues={editdata}
            validationSchema={schema}
            onSubmit={async (values) => {
              setModal((prev) => ({ ...prev, open: false }));
            }}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
              isInvalid,
              isValid,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ชื่อกลุ่มลูกค้าสินค้า <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="group_name"
                    className="form-control"
                    placeholder="ชื่อกลุ่มลูกค้าสินค้า"
                    value={values.group_name}
                    onChange={handleChange}
                    isInvalid={!!errors.group_name}
                  />
                </Form.Group>

                <div className="d-flex align-items-center pt-4 pb-3 btn-group-control">
                  <Button
                    type="button"
                    className="btn-st-5 me-auto "
                    style={{ width: "80px" }}
                    onClick={() => {
                      onDelete();
                    }}
                  >
                    ลบ
                  </Button>
                  <Button
                    type="button"
                    className="btn-st-6 me-3 "
                    style={{ width: "100px" }}
                    onClick={() =>
                      setModal((prev) => ({ ...prev, open: false }))
                    }
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="submit"
                    className="btn-st-3  "
                    style={{ width: "100px" }}
                  >
                    ยืนยัน
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModalAllder>
  );
}

function ConfirmDeleteModal({ modal, setModal, ...props }) {
  const handleDelete = (id) => {
    props.onHide();
    props.onSuccess();
  };

  return (
    <ModalAllder
      modal={modal}
      setModal={setModal}
      htitle={"ยืนยันการลบ"}
      hbtn={false}
    >
      <div className="py-4">
        {props.editdata.customers > 0 ? (
          <div>
            <div>
              <div className="text-center fs-22 tc-7 mb-2">
                ขออภัยไม่สามารถลบได้
              </div>
              <div className="text-center fs-22 tc-7">
                เนื่องจากมีสินค้าอยู่ในกลุ่มลูกค้า
              </div>
            </div>
            <div className="text-center pt-4 mt-3  btn-group-control">
              <Button
                type="button"
                className="btn-st-3 mx-3 "
                style={{ width: "100px" }}
                onClick={props.onHide}
              >
                ตกลง
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div>
              <div className="text-center fs-22 tc-7 mb-2">คุณต้องการลบ</div>
              <div className="text-center fs-22 tc-7">
                จัดการกลุ่มลูกค้านี้ใช่หรือไม่
              </div>
            </div>

            <div className="text-center pt-4 mt-3  btn-group-control">
              <Button
                type="button"
                className="btn-st-3 mx-3  "
                style={{ width: "100px" }}
                onClick={() => handleDelete(props.editdata.id)}
              >
                ใช่
              </Button>
              <Button
                type="button"
                className="btn-st-6 mx-3 "
                style={{ width: "100px" }}
                onClick={props.onHide}
              >
                ไม่ใช่
              </Button>
            </div>
          </div>
        )}
      </div>
    </ModalAllder>
  );
}

function SuccessDeleteModal({ modal, setModal, ...props }) {
  return (
    <ModalAllder modal={modal} setModal={setModal} htitle={""} hbtn={false}>
      <div className="py-4">
        <div className="text-center mb-4">
          <svg
            className="mx-auto"
            width="84"
            height="83"
            viewBox="0 0 84 83"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="42" cy="41.5" r="41.5" fill="#2DC76D" />
            <path
              d="M56.0123 30.9902L36.7444 50.2581L27.9863 41.5"
              stroke="white"
              strokeWidth="5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="text-center fs-22 tc-7">ลบกลุ่มลูกค้าสำเร็จ</div>
      </div>
    </ModalAllder>
  );
}

export function CustomerGroupList() {
  const [groupdata, setGroupdata] = useState([]);

  const [editdata, setEditdata] = useState({});

  const getData = () => {
    fetch("/json/customer_group2.json")
      .then((response) => response.json())
      .then((result) => setGroupdata(result))
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getData();
  }, []);

  const columnsd = [
    {
      field: "customerCode",
      headerName: "รหัสลูกค้า",
      width: 170,
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <Link
          to={`/customer/edit/${row.customerID}`}
          style={{ color: "#2DC76D", fontWeight: 500 }}
        >
          {row.customerCode}
        </Link>
      ),
    },

    {
      field: "customerName",
      headerName: "ชื่อลูกค้า",
      flex: 1,
      width: 140,
      sortable: false,
    },
    {
      field: "customerPhoneNumber",
      headerName: "เบอร์โทรศัพท์",
      flex: 1,
      width: 140,
      sortable: false,
    },
    {
      field: "customerLevel",
      headerName: "ระดับสมาชิก",
      flex: 1,
      width: 140,
      sortable: false,
    },
    {
      field: "customerPoint",
      headerName: "คะแนนสะสม",
      flex: 1,
      width: 140,
      sortable: false,
    },

    {
      field: "customerID",
      headerName: "จัดการ",
      width: 250,

      sortable: false,
      renderCell: ({ row }) => (
        <div>
          <Button
            type="button"
            className="btn-delete-st-3 ml-2 "
            onClick={() => {
              handleDelete(row);
            }}
          ></Button>
        </div>
      ),
    },
  ];

  const [filterText, setFilterText] = useState("");
  const filteredItems =
    groupdata &&
    groupdata.customer_list &&
    groupdata.customer_list.filter(
      (item) =>
        item.customerName &&
        item.customerName.toLowerCase().includes(filterText.toLowerCase())
    );

  const downloadExcel = () => {
    const ws = utils.json_to_sheet(filteredItems);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "กลุ่มลูกค้า");
    writeFileXLSX(wb, "กลุ่มลูกค้า.xlsx");
  };

  const handleDelete = (row) => {};

  const [modalSuccess, setModalSuccess] = useState({
    open: false,
    x: 0,
    y: 0,
    width: 470,
    height: 300,
    pin: false,
    mode: "normal",
    modalname: "edit",
  });

  return (
    <div>
      <nav aria-label="breadcrumb" className="mt-2">
        <ol className="breadcrumb fs-16 ps-0">
          <li className="breadcrumb-item ">
            <a href="#" className="tc-7  text-decoration-none">
              สมาชิก/ลูกค้า
            </a>
          </li>
          <li className="breadcrumb-item active tc-1 " aria-current="page">
            {" "}
            จัดการกลุ่มลูกค้า{" "}
          </li>
        </ol>
      </nav>
      <div className="fs-24 tc-7  mb-4 lh-1">จัดการกลุ่มลูกค้า </div>

      <div className="card card-st-2">
        <div className="card-body px-0 py-4">
          <div className="d-flex align-items-center px-4 mb-4">
            <button
              className="btn btn-export-st-1 me-auto"
              onClick={downloadExcel}
            >
              Export
            </button>
            <div className="me-3">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหา"
                onKeyUp={(e) => setFilterText(e.target.value)}
              />
            </div>
          </div>

          <DataGrid
            getRowId={(row) => row.customerID}
            getRowHeight={() => "auto"}
            rows={filteredItems}
            columns={columnsd}
            // autoHeight={true}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 20, 30]}
            checkboxSelection
            disableRowSelectionOnClick
            slotProps={{
              pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
            }}
            sx={{
              fontFamily: "Sarabun",
              fontSize: "17px",
              ".MuiDataGrid-columnHeaderTitle": {
                fontFamily: "Sarabun",
                fontSize: "17px",
              },
              ".MuiDataGrid-cell": {
                display: "flex",
                alignItems: "center",
                paddingTop: "5px",
                paddingBottom: "5px",
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}
