import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Formik } from 'formik';
import * as yup from 'yup';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { masterHandler, branchPaymentHandler } from '../../../../../redux/handlers';

export default function SettingBankTransferModal(props) {
  const { setModalDeleteShow, formdata, branchID, ...rest } = props;
  const dispatch = useDispatch();
  const banks = useSelector((state) => state.master.banks);

  useEffect(() => {
    dispatch(masterHandler.getBanks());
  }, [dispatch]);

  const schema = yup.object().shape({
    bankID: yup.string().required('กรุณาเลือกธนาคาร'),
    bankAccountName: yup.string().required('กรุณากรอกชื่อบัญชีธนาคาร'),
    bankAccountNumber: yup.string().required('กรุณากรอกเลขบัญชีธนาคาร'),
    bankAccountBranch: yup.string().required('กรุณากรอกสาขา'),
  });
  
  const optionsBank = banks?.map(bank => ({
    value: bank.id,
    label: bank.name
  })) || [];

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const formattedData = {
        branchId: Number(branchID),
        bankId: Number(values.bankID),
        accountName: values.bankAccountName,
        accountNumber: values.bankAccountNumber,
        branchName: values.bankAccountBranch || '',
        isStatus: values.status === 1
      };

      const response = values.id
        ? await dispatch(branchPaymentHandler.updateBranchBankSetting(values.id, formattedData))
        : await dispatch(branchPaymentHandler.createBranchBankSetting(formattedData));

      if (response) {
        props.onSuccess?.();
        resetForm();
      }
    } catch (error) {
      console.error('Error saving bank account:', error);
    }
  };

  return (
    <Modal {...rest} size="md" centered>
      <Modal.Body>
        <div className="d-flex align-items-center px-3 py-2 mb-4 pb-3">
          <div className="fs-24 fw-bold me-auto" style={{ color: '#2DC76D' }}>
            จัดการบัญชีธนาคาร
          </div>
          <button onClick={props.onHide} className="btn btn-close-modal"></button>
        </div>
        <div className="px-3">
          <Formik
            enableReinitialize
            initialValues={formdata}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, handleChange, touched, errors, values, setFieldValue }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ธนาคาร <span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    name="bankID"
                    className={errors.bankID && touched.bankID ? 'w-100 invalid select-st-1' : 'w-100 select-st-1'}
                    options={optionsBank}
                    placeholder="เลือกธนาคาร"
                    onChange={(e) => setFieldValue('bankID', e.value)}
                    value={optionsBank.filter((item) => item.value === values.bankID)}
                  />
                  {errors.bankID && touched.bankID && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {errors.bankID}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ชื่อบัญชีธนาคาร <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="bankAccountName"
                    placeholder="กรอกชื่อบัญชีธนาคาร"
                    value={values.bankAccountName}
                    onChange={handleChange}
                    isInvalid={!!errors.bankAccountName && touched.bankAccountName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.bankAccountName}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    เลขบัญชีธนาคาร <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="bankAccountNumber"
                    placeholder="กรอกเลขบัญชีธนาคาร"
                    value={values.bankAccountNumber}
                    onChange={handleChange}
                    isInvalid={!!errors.bankAccountNumber && touched.bankAccountNumber}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.bankAccountNumber}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">สาขา <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="bankAccountBranch"
                    placeholder="กรอกสาขา"
                    value={values.bankAccountBranch}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Label className="mb-2 tc-10">สถานะ</Form.Label>
                {['radio'].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="เปิดใช้งาน"
                      name="status"
                      type={type}
                      id={`inline-${type}-21`}
                      className="form-check-st-2 tc-7"
                      checked={values.status === 1}
                      onChange={() => setFieldValue('status', 1)}
                    />
                    <Form.Check
                      inline
                      label="ปิดใช้งาน"
                      name="status"
                      type={type}
                      id={`inline-${type}-22`}
                      className="form-check-st-2 tc-7"
                      checked={values.status === 0}
                      onChange={() => setFieldValue('status', 0)}
                    />
                  </div>
                ))}

                <div className="d-flex align-items-center pt-2 pb-3 btn-group-control">
                  {values.id !== null ? (
                    <Button 
                      type="button" 
                      className="btn-st-5 me-auto px-4"
                      style={{ width: '90px' }}
                      onClick={props.handleDelete}
                    >
                      ลบ
                    </Button>
                  ) : (
                    <div className="me-auto"></div>
                  )}
                  <Button
                    type="button"
                    className="btn-st-6 me-3"
                    style={{ width: '100px' }}
                    onClick={props.onHide}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="submit"
                    className="btn-st-3"
                    style={{ width: '100px' }}
                  >
                    {values.id ? 'แก้ไข' : 'บันทึก'}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}