import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import DataTableLayout from "layouts/DatatableLayout";
import DownloadExcel from "utils/DownloadExcel";

export function TableSetting() {
  const { branchID } = useParams();

  const [empdata, setEmpdata] = useState([]);
  const [formdata, setFormdata] = useState({
    zoneID: null,
    zoneName: null,
    zoneAbbreviation: null,
    zoneTableQty: null,
    zoneStatus: 1,
  });
  const [modalcreateShow, setModalcreateShow] = useState(false);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [modalsuccessShow, setModalsuccessShow] = useState(false);
  const [modaleditShow, setModaleditShow] = useState(false);

  const getempData = () => {
    fetch("/json/table.json")
      .then((response) => response.json())
      .then((result) => setEmpdata(result))
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getempData();
  }, []);

  const columns = [
    {
      name: "ลำดับที่",
      selector: (row) => row.zoneNo,
      width: "150px",
      cell: (row, index, column, id) => (
        <div className="ps-3">{row.zoneNo}</div>
      ),
    },
    {
      name: "ชื่อโซน",
      selector: (row) => row.zoneName,
      cell: (row, index, column, id) => (
        <div style={{ color: "#2DC76D", fontWeight: 500 }}>{row.zoneName}</div>
      ),
    },
    {
      name: "ตัวย่อโซน",
      selector: (row) => row.zoneAbbreviation,
    },
    {
      name: "จำนวนโต๊ะ",
      selector: (row) => row.tableList.length,
      center: "true",
    },
    {
      name: "สถานะ",
      selector: (row) => row.zoneStatus,
      cell: (row, index, column, id) => (
        <div className={"category-status status" + row.zoneStatus}></div>
      ),
    },
    {
      name: "จัดการ",
      selector: (row) => row.zoneID,
      center: "true",
      width: "150px",
      cell: (row, index, column, id) => (
        <div>
          <Button
            type="button"
            className="btn-edit-st-1 "
            onClick={() => {
              setFormdata(row);
              setModaleditShow(true);
            }}
          ></Button>
        </div>
      ),
    },
  ];

  const [filterText, setFilterText] = useState("");
  const filteredItems = empdata.filter(
    (item) =>
      item.zoneName &&
      item.zoneName.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <div style={{ padding: "20px 20px 20px 20px" }}>
      <div className="card card-st-2">
        <div className="card-body px-0 py-3">
          <div className="fs-20 fw-bold tc-8 mb-3 px-4">จัดการโซน/โต๊ะ</div>
          <div className="d-flex align-items-center px-4 mb-4">
            <DownloadExcel data={filteredItems} filename="รายชื่อโซน" />
            <div className="me-3">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหา ชื่อโซน "
                onKeyUp={(e) => setFilterText(e.target.value)}
              />
            </div>
            <Button
              type="button"
              className="btn btn btn-st-7 "
              onClick={() => {
                setModalcreateShow(true);
              }}
            >
              เพิ่มโซน/โต๊ะ
            </Button>
          </div>

          <DataTableLayout
            item={filteredItems}
            columns={columns}
            isSelectRows={true}
          />
        </div>
      </div>

      <CreateModal
        show={modalcreateShow}
        onHide={() => setModalcreateShow(false)}
        handleDelete={() => {
          setModalDeleteShow(true);
          setModalcreateShow(false);
        }}
        onSuccess={() => {
          setModalcreateShow(false);
        }}
        formdata={formdata}
      />

      <EditModal
        show={modaleditShow}
        onHide={() => setModaleditShow(false)}
        handleDelete={() => {
          setModalDeleteShow(true);
          setModaleditShow(false);
        }}
        onSuccess={() => {
          setModaleditShow(false);
        }}
        formdata={formdata}
        setFormdata={setFormdata}
      />

      <ConfirmDeleteModal
        show={modalDeleteShow}
        onHide={() => setModalDeleteShow(false)}
        onConfirm={() => setModalDeleteShow(false)}
        onSuccess={() => {
          setModalsuccessShow(true);
        }}
        formdata={formdata}
      />
      <SuccessDeleteModal
        show={modalsuccessShow}
        onHide={() => setModalsuccessShow(false)}
      />
    </div>
  );
}

function CreateModal(props) {
  const { setModalDeleteShow, onSuccess, handleDelete, formdata, ...rest } =
    props;

  const schema = yup.object().shape({
    zoneName: yup.string().required(),
    zoneAbbreviation: yup.string().required(),
    zoneTableQty: yup.string().required(),
  });
  return (
    <Modal {...rest} size="md" centered>
      <Modal.Body>
        <div className="d-flex align-items-center px-3 py-2 mb-4 pb-3">
          <div className="fs-24 fw-bold me-auto" style={{ color: "#2DC76D" }}>
            จัดการโซน/โต๊ะ
          </div>
          <button
            onClick={props.onHide}
            className="btn btn-close-modal"
          ></button>
        </div>
        <div className="px-3">
          <Formik
            enableReinitialize
            initialValues={props.formdata}
            validationSchema={schema}
            onSubmit={async (values) => {
              props.onSuccess();
            }}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
              isInvalid,
              isValid,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ชื่อโซน <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="zoneName"
                    className="form-control"
                    placeholder="ชื่อโซน"
                    value={values.zoneName}
                    onChange={handleChange}
                    isInvalid={!!errors.zoneName}
                  />
                </Form.Group>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ตัวย่อโซน <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="zoneAbbreviation"
                    className="form-control"
                    placeholder="ตัวย่อโซน"
                    value={values.zoneAbbreviation}
                    onChange={handleChange}
                    isInvalid={!!errors.zoneAbbreviation}
                  />
                </Form.Group>

                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    จำนวนโต๊ะ <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="zoneTableQty"
                    className="form-control"
                    placeholder="จำนวนโต๊ะ"
                    value={values.zoneTableQty}
                    onChange={handleChange}
                    isInvalid={!!errors.zoneTableQty}
                  />
                </Form.Group>

                <Form.Label className="mb-2  tc-10">สถานะ</Form.Label>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="เปิดใช้งาน"
                      name="zoneStatus"
                      type={type}
                      id={`inline-${type}-21`}
                      className="form-check-st-2 tc-7"
                      checked={values.zoneStatus === 1}
                      onChange={(e) => {
                        setFieldValue("zoneStatus", 1);
                      }}
                    />
                    <Form.Check
                      inline
                      label="ปิดใช้งาน"
                      name="zoneStatus"
                      type={type}
                      id={`inline-${type}-22`}
                      className="form-check-st-2 tc-7"
                      checked={values.zoneStatus === 0}
                      onChange={(e) => {
                        setFieldValue("zoneStatus", 0);
                      }}
                    />
                  </div>
                ))}

                <div className="d-flex align-items-center pt-2 pb-3 btn-group-control">
                  {values.zoneID !== null ? (
                    <Button
                      type="button"
                      className="btn-st-5 me-auto px-4 "
                      style={{ width: "90px" }}
                      onClick={props.handleDelete}
                    >
                      ลบ
                    </Button>
                  ) : (
                    <div className="me-auto"></div>
                  )}
                  <Button
                    type="button"
                    className="btn-st-6 me-3 "
                    style={{ width: "100px" }}
                    onClick={props.onHide}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="submit"
                    className="btn-st-3  "
                    style={{ width: "100px" }}
                  >
                    {" "}
                    {values.bankID ? "แก้ไข" : "บันทึก"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function EditModal(props) {
  const {
    setModalDeleteShow,
    onSuccess,
    handleDelete,
    formdata,
    setFormdata,
    ...rest
  } = props;

  const [modaledittableShow, setModaledittableShow] = useState(false);
  const [formtabledata, setFormtabledata] = useState({
    tableID: null,
    tableName: null,
    tableSeat: null,
    tableStatus: null,
  });

  const schema = yup.object().shape({
    zoneName: yup.string().required(),
    zoneAbbreviation: yup.string().required(),
    zoneTableQty: yup.string().required(),
  });

  const removeTable = (tableID) => {
    props.setFormdata((prev) => ({
      ...prev,
      tableList: prev.tableList.filter((dx) => dx.tableID !== tableID),
    }));
    setModaledittableShow(false);
  };

  const updateTable = (values) => {
    props.setFormdata((prev) => ({
      ...prev,
      tableList: prev.tableList.map((el) =>
        el.tableID === values.tableID
          ? {
              ...el,
              tableName: values.tableName,
              tableSeat: values.tableSeat,
              tableStatus: values.tableStatus,
            }
          : el
      ),
    }));
    setModaledittableShow(false);
  };

  return (
    <Modal {...rest} size="lg" centered>
      <Modal.Body>
        <div className="d-flex align-items-center px-3  pb-2">
          <div className="fs-24 fw-bold me-auto" style={{ color: "#2DC76D" }}>
            จัดการโซน/โต๊ะ
          </div>
          <button
            onClick={props.onHide}
            className="btn btn-close-modal"
          ></button>
        </div>
        <div className="px-3">
          <Formik
            enableReinitialize
            initialValues={props.formdata}
            validationSchema={schema}
            onSubmit={async (values) => {
              props.onSuccess();
            }}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
              isInvalid,
              isValid,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <div className="row">
                  <Form.Group className="mb-3 form-st-3 pb-1 col-xl-3">
                    <Form.Label className="mb-1">
                      ชื่อโซน <span className="text-danger">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="zoneName"
                      className="form-control"
                      placeholder="ชื่อโซน"
                      value={values.zoneName}
                      onChange={handleChange}
                      isInvalid={!!errors.zoneName}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 form-st-3 pb-1 col-xl-3">
                    <Form.Label className="mb-1">
                      ตัวย่อโซน <span className="text-danger">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="zoneAbbreviation"
                      className="form-control"
                      placeholder="ตัวย่อโซน"
                      value={values.zoneAbbreviation}
                      onChange={handleChange}
                      isInvalid={!!errors.zoneAbbreviation}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 form-st-3 pb-1 col-xl-3">
                    <Form.Label className="mb-1">
                      จำนวนโต๊ะ <span className="text-danger">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="zoneTableQty"
                      className="form-control"
                      placeholder="จำนวนโต๊ะ"
                      value={values.zoneTableQty}
                      onChange={handleChange}
                      isInvalid={!!errors.zoneTableQty}
                    />
                  </Form.Group>
                  <div className="col-xl-3">
                    <Button
                      type="submit"
                      className="btn-st-3  mt-3"
                      style={{ width: "120px" }}
                    >
                      อัพเดทโต๊ะ
                    </Button>
                  </div>
                </div>
                <div className="bx-list-desk-m mb-4">
                  {values.tableList &&
                    values.tableList.map((item, index) => (
                      <div
                        className={`bx-table bx-table-${item.tableStatus}`}
                        key={item.tableID}
                      >
                        <div className="tc-4 fw-bold mb-auto ms-1">
                          {item.tableName}
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="iu me-auto">{item.tableSeat}</div>
                          <button
                            type="button"
                            className="btn-edit-st-2"
                            onClick={() => {
                              setModaledittableShow(true);
                              setFormtabledata(item);
                            }}
                          ></button>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="row align-items-center mb-2">
                  <div className="col-xl-6 d-flex align-items-center">
                    <Form.Label className="me-4 mt-2 tc-10">สถานะ</Form.Label>
                    {["radio"].map((type) => (
                      <div key={`inline-${type}`} className="">
                        <Form.Check
                          inline
                          label="เปิดใช้งาน"
                          name="zoneStatus"
                          type={type}
                          id={`inline-${type}-21`}
                          className="form-check-st-2 tc-7"
                          checked={values.zoneStatus === 1}
                          onChange={(e) => {
                            setFieldValue("zoneStatus", 1);
                          }}
                        />
                        <Form.Check
                          inline
                          label="ปิดใช้งาน"
                          name="zoneStatus"
                          type={type}
                          id={`inline-${type}-22`}
                          className="form-check-st-2 tc-7"
                          checked={values.zoneStatus === 0}
                          onChange={(e) => {
                            setFieldValue("zoneStatus", 0);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="col-xl-6">
                    <div className="d-flex align-items-center  pb-0 btn-group-control">
                      {values.zoneID !== null ? (
                        <Button
                          type="button"
                          className="btn-st-5 me-3 ms-auto px-4 "
                          style={{ width: "90px" }}
                          onClick={props.handleDelete}
                        >
                          ลบ
                        </Button>
                      ) : (
                        <div className="me-auto"></div>
                      )}
                      <Button
                        type="button"
                        className="btn-st-6 me-3 "
                        style={{ width: "100px" }}
                        onClick={props.onHide}
                      >
                        ยกเลิก
                      </Button>
                      <Button
                        type="submit"
                        className="btn-st-3  "
                        style={{ width: "100px" }}
                      >
                        {" "}
                        {values.bankID ? "แก้ไข" : "บันทึก"}
                      </Button>
                    </div>
                  </div>
                </div>

                <EditTableModal
                  show={modaledittableShow}
                  onHide={() => setModaledittableShow(false)}
                  handleDelete={(tableID) => removeTable(tableID)}
                  handleEdit={(tabledata) => updateTable(tabledata)}
                  formtabledata={formtabledata}
                />
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function EditTableModal(props) {
  const { setModalDeleteShow, handleEdit, handleDelete, formdata, ...rest } =
    props;

  const schema = yup.object().shape({
    tableName: yup.string().required(),
    tableSeat: yup.string().required(),
  });
  return (
    <Modal {...rest} size="md" centered>
      <Modal.Body>
        <div className="d-flex align-items-center px-3 py-2 mb-4 pb-3">
          <div className="fs-24 fw-bold me-auto" style={{ color: "#2DC76D" }}>
            จัดการโต๊ะ
          </div>
          <button
            onClick={props.onHide}
            className="btn btn-close-modal"
          ></button>
        </div>
        <div className="px-3">
          <Formik
            enableReinitialize
            initialValues={props.formtabledata}
            validationSchema={schema}
            onSubmit={async (values) => {
              props.handleEdit(values);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
              isInvalid,
              isValid,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ชื่อโต๊ะ <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="tableName"
                    className="form-control"
                    placeholder="ชื่อโต๊ะ"
                    value={values.tableName}
                    onChange={handleChange}
                    isInvalid={!!errors.tableName}
                  />
                </Form.Group>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    จำนวนที่นั่ง/โต๊ะ <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="tableSeat"
                    className="form-control"
                    placeholder="จำนวนที่นั่ง/โต๊ะ"
                    value={values.tableSeat}
                    onChange={handleChange}
                    isInvalid={!!errors.tableSeat}
                  />
                </Form.Group>

                <Form.Label className="mb-2  tc-10">สถานะ</Form.Label>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="เปิดใช้งาน"
                      name="tableStatus"
                      type={type}
                      id={`tableStatus-${type}-21`}
                      className="form-check-st-2 tc-7"
                      checked={values.tableStatus === 1}
                      onChange={(e) => {
                        setFieldValue("tableStatus", 1);
                      }}
                    />
                    <Form.Check
                      inline
                      label="ปิดใช้งาน"
                      name="tableStatus"
                      type={type}
                      id={`tableStatus-${type}-22`}
                      className="form-check-st-2 tc-7"
                      checked={values.tableStatus === 0}
                      onChange={(e) => {
                        setFieldValue("tableStatus", 0);
                      }}
                    />
                  </div>
                ))}

                <div className="d-flex align-items-center pt-2 pb-3 btn-group-control">
                  {values.tableID ? (
                    <Button
                      type="button"
                      className="btn-st-5 me-auto px-4 "
                      style={{ width: "90px" }}
                      onClick={() => props.handleDelete(values.tableID)}
                    >
                      ลบ
                    </Button>
                  ) : (
                    <div className="me-auto"></div>
                  )}
                  <Button
                    type="button"
                    className="btn-st-6 me-3 "
                    style={{ width: "100px" }}
                    onClick={props.onHide}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="submit"
                    className="btn-st-3  "
                    style={{ width: "100px" }}
                  >
                    {" "}
                    {values.bankID ? "แก้ไข" : "บันทึก"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function ConfirmDeleteModal(props) {
  const handleDelete = (bankID) => {
    props.onHide();
    props.onSuccess();
  };
  return (
    <Modal {...props} size="md" centered>
      <Modal.Body>
        <div className="px-3 py-4">
          <div>
            <div>
              <div className="text-center fs-22 tc-7 mb-2">คุณต้องการลบ</div>
              <div className="text-center fs-22 tc-7">รายการนี้ใช่หรือไม่</div>
            </div>

            <div className="text-center pt-4 mt-3  btn-group-control">
              <Button
                type="button"
                className="btn-st-3 mx-3  "
                style={{ width: "100px" }}
                onClick={() => handleDelete(props.formdata.bankID)}
              >
                ใช่
              </Button>
              <Button
                type="button"
                className="btn-st-6 mx-3 "
                style={{ width: "100px" }}
                onClick={props.onHide}
              >
                ไม่ใช่
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function SuccessDeleteModal(props) {
  return (
    <Modal {...props} size="sm" centered>
      <Modal.Body>
        <div className="px-3 py-4">
          <div className="text-center mb-4">
            <svg
              width="84"
              height="83"
              viewBox="0 0 84 83"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="42" cy="41.5" r="41.5" fill="#2DC76D" />
              <path
                d="M56.0123 30.9902L36.7444 50.2581L27.9863 41.5"
                stroke="white"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="text-center fs-22 tc-7">ทำรายการสำเร็จ</div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
