import React from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ChevronRight } from "lucide-react";

const Breadcrumb = () => {
  const { businessSlug } = useParams();
  const items = useSelector((state) => state.breadcrumb.items);

  if (!items.length) return null;

  const getPath = (path) => {
    if (!path) return null;
    return path.startsWith("/") ? `/${businessSlug}${path}` : path;
  };

  return (
    <nav aria-label="breadcrumb" className="mb-4">
      <ol className="flex flex-wrap items-center gap-2">
        {items.map((item, index) => (
          <li key={index} className="flex items-center">
            {item.path ? (
              <Link
                to={getPath(item.path)}
                className="text-decoration-none hover:text-blue-800 transition-colors"
              >
                {item.label}
              </Link>
            ) : (
              <span className="text-gray-900 font-medium">{item.label}</span>
            )}
            {index < items.length - 1 && (
              <ChevronRight className="mx-2 h-4 w-4 text-gray-500" />
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
