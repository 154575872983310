import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  PaymentTransferSetting,
  PaymentQrSetting,
  PaymentCreditSetting,
  PaymentDeliverySetting,
  PaymentCashSetting,
  PaymentDebtorsSetting,
  PaymentPointSetting,
  PaymentOtherSetting,
} from "./components/methods";
import { branchPaymentHandler } from "../../../redux/handlers";

const PAYMENT_TYPES = [
  { id: 1, name: "เงินสด", icon: "icon-1", component: PaymentCashSetting },
  { id: 2, name: "QR CODE", icon: "icon-2", component: PaymentQrSetting },
  { id: 3, name: "โอนเงิน", icon: "icon-3", component: PaymentTransferSetting },
  {
    id: 4,
    name: "Delivery",
    icon: "icon-4",
    component: PaymentDeliverySetting,
  },
  {
    id: 5,
    name: "บัตรเครดิต/เดบิต",
    icon: "icon-5",
    component: PaymentCreditSetting,
  },
  {
    id: 6,
    name: "บันทึกลูกหนี้",
    icon: "icon-6",
    component: PaymentDebtorsSetting,
  },
  {
    id: 7,
    name: "ช่องทางอื่นๆ",
    icon: "icon-7",
    component: PaymentPointSetting,
  },
  {
    id: 8,
    name: "ตั้งค่าอื่นๆ",
    icon: "icon-8",
    component: PaymentOtherSetting,
  },
];

export function Payment() {
  const { branchID } = useParams();
  const dispatch = useDispatch();
  const [selectedPaymentType, setSelectedPaymentType] = useState(1);

  useEffect(() => {
    const fetchPaymentSettings = async () => {
      try {
        await dispatch(branchPaymentHandler.getBranchPaymentSetting(branchID));
      } catch (error) {
        console.error("Error fetching payment settings:", error);
      }
    };

    fetchPaymentSettings();
  }, [branchID, dispatch]);

  const renderPaymentTypeOptions = () => {
    return PAYMENT_TYPES.map(({ id, name, icon }) => (
      <div key={id} className="selectpayment mb-3 me-3">
        <input
          type="radio"
          className="hide-input"
          name="selectpayment"
          id={`type${id}`}
          checked={selectedPaymentType === id}
          onChange={() => setSelectedPaymentType(id)}
        />
        <label htmlFor={`type${id}`}>
          <div className={`icon ${icon}`} />
          <div>{name}</div>
        </label>
      </div>
    ));
  };

  const renderSelectedPaymentComponent = () => {
    const selectedType = PAYMENT_TYPES.find(
      (type) => type.id === selectedPaymentType
    );
    if (!selectedType) return null;
    const PaymentComponent = selectedType.component;
    return <PaymentComponent />;
  };

  return (
    <div className="p-5">
      <div className="card card-st-1 mb-4">
        <div className="card-body pt-4 pb-4">
          <div className="d-flex align-items-center mb-2">
            <div className="tc-8 fs-18 fw-bold px-3 mb-3 me-auto">
              การชำระเงิน
            </div>
          </div>

          <div className="d-flex px-3 flex-wrap mb-2">
            {renderPaymentTypeOptions()}
          </div>

          {selectedPaymentType > 0 && (
            <div className="px-3">
              <div className="card card-st-1 mb-4">
                <div className="card-body p-4">
                  {renderSelectedPaymentComponent()}
                </div>
              </div>
            </div>
          )}

          <div className="py-5" />
        </div>
      </div>
    </div>
  );
}
