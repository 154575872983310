import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";

export default function ConfirmDeleteItemModal({ show, onHide, onConfirm }) {
  return (
    <Modal show={show} onHide={onHide} size="sm" centered >
      <Modal.Body>
        <div className="px-3 py-4">
          <div className="text-center mb-4">
            <svg
              width="84"
              height="83"
              className="mx-auto"
              viewBox="0 0 84 83"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="42" cy="41.5" r="41.5" fill="#2DC76D" />
              <path
                d="M56.0123 30.9902L36.7444 50.2581L27.9863 41.5"
                stroke="white"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="text-center fs-22 tc-7 mb-4">
            คุณต้องการลบรายการนี้ใช่หรือไม่?
          </div>
          <div className="text-center pt-2 pb-3 btn-group-control">
            <Button
              type="button"
              className="btn-st-6 me-3"
              style={{ width: "100px" }}
              onClick={onHide}
            >
              ยกเลิก
            </Button>
            <Button
              type="button"
              className="btn-st-3"
              style={{ width: "100px" }}
              onClick={onConfirm}
            >
              ลบ
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
