import { ENDPOINT } from "../../constants/server/api";
import { fetchApiData } from "../../utils/apiUtils";
import { setData, deleteItem, getById } from "../slices/branchKitchen.slice";

export const getPrinter = (branchId) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.PRINTER}?branchId=${branchId}`,
    dispatch,
  });
  if (data) {
    dispatch(setData(data));
    return data;
  }
};
export const createKitchen = (requestData) => async (dispatch) => {
  const result = await fetchApiData({
    method: "post",
    endpoint: `${ENDPOINT.KITCHEN}`,
    data: requestData,
    dispatch,
  });
  if (result) {
    return result;
  }
};
export const updadteKitchen = (kitchenId, requestData) => async (dispatch) => {
  const result = await fetchApiData({
    method: "put",
    endpoint: `${ENDPOINT.KITCHEN}/${kitchenId}`,
    data: requestData,
    dispatch,
  });
  if (result) {
    return result;
  }
};
export const getKitchen = (kitchenId) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.KITCHEN}/${kitchenId}`,
    dispatch,
  });
  if (data) {
    dispatch(getById(data));
    return data;
  }
};

export const listKitchen = (params) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.KITCHEN}/pagination/${params.branchId}/list`,
    params: {
      page: params.page,
      perPage: params.per_page,
      s: params.s,
      sortBy: params.sortBy,
      sortOrder: params.sortOrder,
    },
    dispatch,
  });

  if (data) {
    dispatch(setData(data));
    return data;
  }
};
export const deleteKitchen = (kitchenId) => async (dispatch) => {
  const result = await fetchApiData({
    method: "delete",
    endpoint: `${ENDPOINT.KITCHEN}/${kitchenId}`,
    dispatch,
  });
  dispatch(deleteItem(kitchenId));
  if (result) {
    return result;
  }
};
export const downloadKitchenExcel =
  (branchId, searchTerm) => async (dispatch) => {
    try {
      const data = await fetchApiData({
        method: "post",
        endpoint: `${ENDPOINT.BRANCH_KITCHEN}/excel/${branchId}`,
        params: {
          s: searchTerm || "",
        },
        responseType: "blob",
        dispatch,
      });

      if (data) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `รายการครัว.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("Failed to download Excel file:", error);
    }
  };

export const getBranchKitchenProducts =
  (branchKitchenId, params) => async (dispatch) => {
    const data = await fetchApiData({
      method: "get",
      endpoint: `${ENDPOINT.BRANCH_KITCHEN_PRODUCT}/pagination/${branchKitchenId}/list`,
      params: {
        page: params.page,
        perPage: params.perPage,
        s: params.s,
        sortBy: params.sortBy,
        sortOrder: params.sortOrder,
      },
      dispatch,
    });

    if (data) {
      dispatch(setData(data));
      return data;
    }
  };

export const createBranchKitchenProduct = (requestData) => async (dispatch) => {
  const result = await fetchApiData({
    method: "post",
    endpoint: `${ENDPOINT.BRANCH_KITCHEN_PRODUCT}`,
    data: requestData,
    dispatch,
  });

  if (result) {
    return result;
  }
};

export const updateBranchKitchenProduct =
  (productId, requestData) => async (dispatch) => {
    const result = await fetchApiData({
      method: "put",
      endpoint: `${ENDPOINT.BRANCH_KITCHEN_PRODUCT}/${productId}`,
      data: requestData,
      dispatch,
    });

    if (result) {
      return result;
    }
  };

export const getBranchKitchenProduct = (productId) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.BRANCH_KITCHEN_PRODUCT}/${productId}`,
    dispatch,
  });

  if (data) {
    dispatch(getById(data));
    return data;
  }
};

export const deleteBranchKitchenProduct = (productId) => async (dispatch) => {
  const result = await fetchApiData({
    method: "delete",
    endpoint: `${ENDPOINT.BRANCH_KITCHEN_PRODUCT}/${productId}`,
    dispatch,
  });

  dispatch(deleteItem(productId));
  if (result) {
    return result;
  }
};

export const getBranchKitchenStats = (branchKitchenId) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.KITCHEN_STATS}/${branchKitchenId}`,
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const assignProductsToKitchen =
  (kitchenId, productIds) => async (dispatch) => {
    const result = await fetchApiData({
      method: "post",
      endpoint: `${ENDPOINT.KITCHEN}/${kitchenId}/assign-products`,
      data: { productIds },
      dispatch,
    });

    if (result) {
      return result;
    }
  };

export const bulkUpdateKitchenProducts =
  (kitchenId, productsData) => async (dispatch) => {
    const result = await fetchApiData({
      method: "put",
      endpoint: `${ENDPOINT.KITCHEN}/${kitchenId}/bulk-update-products`,
      data: { products: productsData },
      dispatch,
    });

    if (result) {
      return result;
    }
  };

export const importKitchenProductsFromExcel =
  (kitchenId, fileData) => async (dispatch) => {
    const formData = new FormData();
    formData.append("file", fileData);

    const result = await fetchApiData({
      method: "post",
      endpoint: `${ENDPOINT.KITCHEN}/${kitchenId}/import-products`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      dispatch,
    });

    if (result) {
      return result;
    }
  };

export const getKitchenProductTemplateExcel = () => async (dispatch) => {
  try {
    const result = await fetchApiData({
      method: "get",
      endpoint: `${ENDPOINT.KITCHEN}/product-template`,
      dispatch,
      responseType: "blob",
    });

    if (result) {
      const url = window.URL.createObjectURL(new Blob([result]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "kitchen-product-template.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  } catch (error) {
    console.error("Failed to download template Excel file:", error);
  }
};

export const getBranchKitchenCategories =
  (branchKitchenId, params) => async (dispatch) => {
    const data = await fetchApiData({
      method: "get",
      endpoint: `${ENDPOINT.BRANCH_KITCHEN_CATEGORY}/pagination/${branchKitchenId}/list`,
      params: {
        page: params.page,
        perPage: params.perPage,
        s: params.s,
        sortBy: params.sortBy,
        sortOrder: params.sortOrder,
      },
      dispatch,
    });

    if (data) {
      dispatch(setData(data));
      return data;
    }
  };

export const createBranchKitchenCategory =
  (requestData) => async (dispatch) => {
    const result = await fetchApiData({
      method: "post",
      endpoint: `${ENDPOINT.BRANCH_KITCHEN_CATEGORY}`,
      data: requestData,
      dispatch,
    });

    if (result) {
      return result;
    }
  };

export const getBranchKitchenCategory = (categoryId) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.BRANCH_KITCHEN_CATEGORY}/${categoryId}`,
    dispatch,
  });

  if (data) {
    dispatch(getById(data));
    return data;
  }
};

export const updateBranchKitchenCategory =
  (categoryId, requestData) => async (dispatch) => {
    const result = await fetchApiData({
      method: "put",
      endpoint: `${ENDPOINT.BRANCH_KITCHEN_CATEGORY}/${categoryId}`,
      data: requestData,
      dispatch,
    });

    if (result) {
      return result;
    }
  };

export const deleteBranchKitchenCategory = (categoryId) => async (dispatch) => {
  const result = await fetchApiData({
    method: "delete",
    endpoint: `${ENDPOINT.BRANCH_KITCHEN_CATEGORY}/${categoryId}`,
    dispatch,
  });

  dispatch(deleteItem(categoryId));
  if (result) {
    return result;
  }
};

export const assignCategoriesToKitchen =
  (kitchenId, categoryIds) => async (dispatch) => {
    const result = await fetchApiData({
      method: "post",
      endpoint: `${ENDPOINT.KITCHEN}/${kitchenId}/assign-categories`,
      data: { categoryIds },
      dispatch,
    });

    if (result) {
      return result;
    }
  };

export const bulkUpdateKitchenCategories =
  (kitchenId, categoriesData) => async (dispatch) => {
    const result = await fetchApiData({
      method: "put",
      endpoint: `${ENDPOINT.KITCHEN}/${kitchenId}/bulk-update-categories`,
      data: { categories: categoriesData },
      dispatch,
    });

    if (result) {
      return result;
    }
  };

export const getKitchenCategoryStats =
  (branchKitchenId) => async (dispatch) => {
    const data = await fetchApiData({
      method: "get",
      endpoint: `${ENDPOINT.KITCHEN_CATEGORY_STATS}/${branchKitchenId}`,
      dispatch,
    });

    if (data) {
      return data;
    }
  };
