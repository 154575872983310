import axios from "axios";
import join from "url-join";
import { domain, ENDPOINT, STATUS } from "../constants/server/api";
import {
  NETWORK_CONNECTION_MESSAGE,
  NOT_CONNECT_NETWORK_CODE,
} from "../constants/server/error";

const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

axios.interceptors.request.use(async (config) => {
  try {
    if (!isAbsoluteURLRegex.test(config.url)) {
      config.url = join(domain, config.url);
    }
    config.timeout = 10 * 1000;
    return config;
  } catch (error) {
    return Promise.reject(error);
  }
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(({ resolve, reject }) => {
    error ? reject(error) : resolve(token);
  });
  failedQueue = [];
};

const handle401Error = async (originalRequest) => {
  if (isRefreshing) {
    return new Promise((resolve, reject) => {
      failedQueue.push({ resolve, reject });
    }).then((token) => {
      originalRequest.headers["Authorization"] = `Bearer ${token}`;
      return http(originalRequest);
    });
  }

  originalRequest._retry = true;
  isRefreshing = true;

  return new Promise((resolve, reject) => {
    axios
      .post(`${domain}${ENDPOINT.USER_BUSINESS_GROUP}/verify-refresh-token`, {
        refresh_token: localStorage.getItem("refreshToken"),
      })
      .then(({ data }) => {
        const { access_token, refresh_token } = data.data;
        localStorage.setItem("accessToken", access_token);
        localStorage.setItem("refreshToken", refresh_token);
        http.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${access_token}`;
        originalRequest.headers["Authorization"] = `Bearer ${access_token}`;
        processQueue(null, access_token);
        resolve(http(originalRequest));
      })
      .catch((err) => {
        processQueue(err, null);
        reject(err);
      })
      .finally(() => {
        isRefreshing = false;
      });
  });
};

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (axios.isCancel(error)) {
      return Promise.reject({ isCancelled: true });
    }

    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      return handle401Error(originalRequest);
    }

    if (!error.response) {
      return Promise.reject({
        code: NOT_CONNECT_NETWORK_CODE,
        message: NETWORK_CONNECTION_MESSAGE,
      });
    }

    return Promise.reject({
      code: error.response.status,
      message:
        error.response.data.error ||
        error.response.data.message ||
        "An error occurred",
    });
  }
);

export const http = axios;
