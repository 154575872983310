import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Formik } from 'formik';
import * as yup from 'yup';
import 'react-datepicker/dist/react-datepicker.css';
import { nanoid } from 'nanoid';
import { FormControl } from 'react-bootstrap';
import { ModalAllder } from 'layouts/ModalAllder';
import { productStandardHandler } from '../../../../../redux/handlers';
import { useDispatch, useSelector } from 'react-redux';
const ModalAdd = ({
  modal,
  setModal,
  setModalDeleteShow,
  optiondata,
  setOptiondata,
  onSuccess,
  ...props
}) => {
  let { optionID } = useParams();
  const resetFormRef = useRef(null);
  const dispatch = useDispatch();
  const resData = useSelector((state) => state.productStandard?.createResponse);
  const schemaFormModal = yup.object().shape({
    name: yup.string().required('กรุณากรอกชื่อรายการ'),
    price: yup.number().required('กรุณากรอกราคา'),
  });

  const [initialValues, setInitialValues] = useState({
    name: '',
    price: '',
  });

  useEffect(() => {
    if (modal.open && resetFormRef.current) {
      resetFormRef.current();
    }
  }, [modal.open]);
  useEffect(() => {
    if (resData?.id != undefined) {
      setOptiondata((prev) => ({
        ...prev,
        optionList: [
          ...prev.optionList,
          {
            id: resData.id,
            name: resData.name,
            price: resData.price,
          },
        ],
      }));
    }
  }, [resData]);
  return (
    <ModalAllder
      modal={modal}
      setModal={setModal}
      htitle={'เพิ่มรายการตัวเลือกมาตราฐาน'}
      hbtn={true}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schemaFormModal}
        onSubmit={async (values) => {
          let data = {
            productOptionId: optionID,
            name: values.name,
            price: values.price,
          };
          dispatch(productStandardHandler.createProductOptionItem(data));
          setModal((prev) => ({ ...prev, open: false }));
        }}
      >
        {({
          handleSubmit,
          handleChange,
          touched,
          errors,
          values,
          setFieldValue,
          isInvalid,
          isValid,
          resetForm,
        }) => {
          resetFormRef.current = resetForm;

          return (
            <Form noValidate onSubmit={handleSubmit} className="p-lg-4">
              <fieldset>
                <div className="row ">
                  <div className="col-12">
                    <Form.Group className="mb-4 form-st-3 pb-1">
                      <Form.Label className="mb-1">
                        ชื่อรายการ <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="กรอกชื่อรายการ"
                        value={values.name}
                        onChange={handleChange}
                        isInvalid={!!(errors.name && touched.name)}
                      />
                      <FormControl.Feedback type="invalid">
                        {errors.name}
                      </FormControl.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12">
                    <Form.Group className="mb-4 form-st-3 pb-1">
                      <Form.Label className="mb-1">
                        ราคา (ส่วนที่จ่ายเพิ่ม){' '}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="price"
                        className="form-control"
                        placeholder="กรอกราคา"
                        onChange={handleChange}
                        value={values.price}
                        isInvalid={!!(errors.price && touched.price)}
                      />
                      <FormControl.Feedback type="invalid">
                        {errors.price}
                      </FormControl.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </fieldset>
              <div className="text-end pb-4 pt-4 pe-3">
                <div className="d-flex justify-end align-items-center pt-3 btn-group-control">
                  <Button
                    className="btn btn-st-6 me-3 bg-transparent"
                    onClick={() => {
                      setModal((prev) => ({ ...prev, open: false }));
                      resetForm();
                    }}
                  >
                    ยกเลิก
                  </Button>
                  <Button type="submit" className="btn-st-3 ">
                    บันทึก
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </ModalAllder>
  );
};
export default ModalAdd;
