import React, { useEffect, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "react-bootstrap";
import { productStandardHandler } from "../../../redux/handlers";
import { useDispatch, useSelector } from "react-redux";
import { createTableColumns } from "../../../utils/createTableColumns";
import { useGlobalTable } from "../../../hooks/useGlobalTable";
import AllderTable from "../../../components/AllderTable";
import { useBreadcrumb } from "../../../hooks/useBreadcrumb";
import Breadcrumb from "../../../components/Breadcrumb";
import {setCreateResponse} from '../../../redux/slices/productStandard.slice';

export function OptionStandard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { businessSlug } = useParams();
  const breadcrumbConfig = [
    { label: "หน้าหลัก", path: "/" },
    { label: "สินค้าและบริการ", path: "/product/options/standard" },
    { label: "จัดการตัวเลือกมาตราฐาน" },
  ];
  useBreadcrumb(breadcrumbConfig);

  const column = createTableColumns(
    [
      {
        field: "code",
        headerName: "รหัสตัวเลือกมาตราฐาน",
        width: 170,
        sortable: true,
        renderCell: ({ row }) => (
          <div
            style={{
              color: "#2DC76D",
              fontWeight: 500,
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => navigate(`edit/${row.id}`)}
          >
            {row.code}
          </div>
        ),
      },
      {
        field: "name",
        headerName: "ชื่อตัวเลือกเพิ่มเติม",
        flex: 1,
        renderCell: ({ row }) => (
          <div style={{ color: "#2DC76D", fontWeight: 500 }}>{row.name}</div>
        ),
      },
      {
        field: "productOptionItem",
        headerName: "รายการ",
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <div>
            {row?.productOptionItem?.map((item, index) =>
              index == 0 ? (
                <span key={index}>{item.name}</span>
              ) : (
                <span key={index}>
                  {" "}
                  <span className="px-1">,</span> {item.name}
                </span>
              )
            )}
          </div>
        ),
      },
      {
        field: "actions",
        headerName: "จัดการ",
        width: 120,
        sortable: false,
        type: "actions",
      },
    ],
    (row) => (
      <div className="">
        <Button
          className="btn-edit-st-1"
          onClick={() => navigate(`edit/${row.id}`)}
        ></Button>
      </div>
    )
  );

  const fetchProductStandard = useCallback(
    (params) => {
      return dispatch(
        productStandardHandler.paginationProductOption({
          business_slug: businessSlug,
          ...params,
        })
      );
    },
    [businessSlug, dispatch]
  );
  const handleExport = () => {
    dispatch(
      productStandardHandler.downloadProductOptionExcel(
        businessSlug,
        filterParams.s
      )
    );
  };
  const {
    data: productStandard,
    loading,
    totalRows,
    paginationModel,
    setPaginationModel,
    handleSort,
    debouncedSearch,
    filterParams,
    refreshData: refreshProductStandard,
  } = useGlobalTable({
    fetchData: fetchProductStandard,
    defaultSort: "created_at",
    defaultOrder: "desc",
    defaultPageSize: 20,
  });

  useEffect(() => {
     dispatch(setCreateResponse(null))
  }, []);

  return (
    <div>
      <Breadcrumb />
      <div className="card card-st-2">
        <div className="card-body px-0 py-4">
          <div className="d-flex align-items-center px-4 mb-4">
            <button
              className="btn btn-export-st-1 me-auto"
              onClick={() => handleExport()}
            >
              Export
            </button>
            <div className="me-3">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหาตัวเลือกมาตราฐาน"
                onKeyUp={(e) => debouncedSearch(e.target.value)}
              />
            </div>
            <Button
              onClick={() => {navigate(`/${businessSlug}/product/options/standard/create`)}}
              className="btn btn btn-st-7"
            >
              เพิ่มตัวเลือกมาตราฐาน
            </Button>
          </div>

          <AllderTable
            data={productStandard}
            loading={loading}
            columns={column}
            totalRows={totalRows}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            handleSort={handleSort}
          />
        </div>
      </div>
    </div>
  );
}
