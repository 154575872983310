import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
export default function SettingUsagePoint() {
  const { branchID, kitchenID } = useParams();

  const [formdata, setFormdata] = useState({});

  const schema = yup.object().shape({
    kitchenName: yup.string().required(),
    storeHouse: yup.string().required(),
  });

  const getSettingPoint = () => {
    setFormdata({
      conversionType: 1,
      campaignFormat: "คำนวณจากมูลค่ายอดทั้งหมด",
      usagePoint: 100,
      discount: 8,
      point_expire: 2,
    });
  };

  const option_point_expire = [
    { value: 1, label: "ตัดคะแนนภายในปี" },
    { value: 2, label: "ตัดคะแนนภายในปีถัดไป" },
    { value: 3, label: "ไม่มีวันหมดอายุ" },
  ];

  useEffect(() => {
    getSettingPoint();
  }, []);

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={formdata}
        validationSchema={schema}
        onSubmit={async (values) => {}}
      >
        {({
          handleSubmit,
          handleChange,
          touched,
          errors,
          values,
          setFieldValue,
          isInvalid,
          isValid,
        }) => (
          <Form className="px-4" noValidate onSubmit={handleSubmit}>
            <div className="card card-st-2 mb-4">
              <div className="card-body !px-7 py-4">
                <div className="d-flex align-items-center  mb-4">
                  <div className="tc-8 fs-20 fw-6   me-auto">
                    ข้อกำหนดและเงื่อนไข
                  </div>
                </div>

                <div className="row align-items-center">
                  <div className="col-3">
                    <Form.Group className="mb-3 form-st-3 pb-1">
                      <Form.Label className="mb-1">รูปแบบแคมเปญ </Form.Label>
                      <Form.Control
                        type="text"
                        name="campaignFormat"
                        className="form-control"
                        placeholder="รูปแบบการคำนวณ"
                        value={values.campaignFormat}
                        onChange={handleChange}
                        isInvalid={!!errors.campaignFormat}
                        disabled={true}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-2">
                    <Form.Group className="mb-3 form-st-3 pb-1">
                      <Form.Label className="mb-1">คะแนนที่ใช้ </Form.Label>
                      <Form.Control
                        type="number"
                        name="usagePoint"
                        className="form-control"
                        placeholder="มูลค่ายอดซื้อ"
                        value={values.usagePoint}
                        onChange={handleChange}
                        isInvalid={!!errors.usagePoint}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-2">
                    <Form.Group className="mb-3 form-st-3 pb-1">
                      <Form.Label className="mb-1">ส่วนลดที่ได้รับ </Form.Label>
                      <Form.Control
                        type="number"
                        name="discount"
                        className="form-control"
                        placeholder="คะแนนที่ได้รับ"
                        value={values.discount}
                        onChange={handleChange}
                        isInvalid={!!errors.discount}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-4">
                    <Form.Group className="mb-3 form-st-3 pb-1">
                      <Form.Label className="mb-1">ผลลัพธ์ </Form.Label>
                      <Form.Control
                        type="text"
                        name="result"
                        className="form-control ะแ-5"
                        placeholder="ผลลัพธ์"
                        value={
                          values.usagePoint +
                          " คะแนน ได้รับส่วนลด " +
                          values.discount +
                          " บาท"
                        }
                        onChange={handleChange}
                        isInvalid={!!errors.result}
                        disabled={true}
                        style={{ color: "#F95057" }}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>

            <div className="card card-st-2 mb-4">
              <div className="card-body !px-7 py-4">
                <div className="d-flex align-items-center  mb-7">
                  <div className="tc-8 fs-20 fw-6   me-auto">
                    ตั้งค่าการหมดอายุของคะแนน
                  </div>
                </div>
                <div className="w-[400px]">
                  <Form.Group className="mb-2 form-st-3 pb-1">
                    <Form.Label className="mb-1">
                      ระยะเวลารอบการหมดอายุของคะแนน
                    </Form.Label>
                    <Select
                      as="select"
                      name="point_expire"
                      onChange={(e) => {
                        setFieldValue("point_expire", e.value);
                      }}
                      className="w-100 select-st-1"
                      options={option_point_expire}
                      placeholder="เลือกรูปแบบการตัดคะแนน"
                      value={option_point_expire.filter(
                        (item) => item.value === values.point_expire
                      )}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>

            <div className="card card-st-2 mb-4">
              <div className="card-body !px-7">
                <div className="text-end">
                  <Button
                    type="submit"
                    className="btn-st-3  "
                    style={{ width: "100px" }}
                  >
                    {" "}
                    บันทึก
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
