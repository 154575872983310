import React from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";

export const SalaryInfo = ({
  values,
  handleChange,
  setFieldValue,
  errors,
  optionsBank,
  optionsaccountType,
}) => {
  return (
    <div className="pt-3 px-3">
      <Form.Label className="mb-2 tc-10">ช่องทางรับเงินเดือน</Form.Label>

      {/* Salary Payment Method */}
      <div key="inline-radio" className="mb-3">
        <Form.Check
          inline
          label="บัญชีธนาคาร"
          name="empSalarySalaryTypeId"
          type="radio"
          id="radio-bank"
          className="form-check-st-2 tc-7"
          checked={values.empSalarySalaryTypeId === 1}
          onChange={() => setFieldValue("empSalarySalaryTypeId", 1)}
        />
        <Form.Check
          inline
          label="เงินสด"
          name="empSalarySalaryTypeId"
          type="radio"
          id="radio-cash"
          className="form-check-st-2 tc-7"
          checked={values.empSalarySalaryTypeId === 2}
          onChange={() => setFieldValue("empSalarySalaryTypeId", 2)}
        />
      </div>

      {/* Bank Account Details - Show only if bank transfer is selected */}
      {values.empSalarySalaryTypeId === 1 && (
        <div className="row gx-xl-4">
          {/* Bank Selection */}
          <div className="col-xl-4 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">ธนาคาร</Form.Label>
              <Select
                name="empSalaryBankId"
                onChange={(e) => setFieldValue("empSalaryBankId", e.value)}
                className="w-100 select-st-1"
                options={optionsBank}
                placeholder="ธนาคาร"
                value={optionsBank?.filter(
                  (item) => item.value === values.empSalaryBankId
                )}
              />
            </Form.Group>
          </div>

          {/* Account Type */}
          <div className="col-xl-4 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">ประเภทบัญชี</Form.Label>
              <Select
                name="empSalaryAccountTypeId"
                onChange={(e) =>
                  setFieldValue("empSalaryAccountTypeId", e.value)
                }
                className="w-100 select-st-1"
                options={optionsaccountType}
                placeholder="ประเภทบัญชี"
                value={optionsaccountType?.filter(
                  (item) => item.value === values.empSalaryAccountTypeId
                )}
              />
            </Form.Group>
          </div>

          {/* Account Name */}
          <div className="col-xl-4 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">ชื่อบัญชี</Form.Label>
              <Form.Control
                type="text"
                name="empSalaryAccountName"
                className="form-control"
                placeholder="ชื่อบัญชี"
                value={values.empSalaryAccountName}
                onChange={handleChange}
                isInvalid={!!errors.empSalaryAccountName}
              />
            </Form.Group>
          </div>

          {/* Account Number */}
          <div className="col-xl-4 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">เลขบัญชี</Form.Label>
              <Form.Control
                type="text"
                name="empSalaryAccountNumber"
                className="form-control"
                placeholder="เลขบัญชี"
                value={values.empSalaryAccountNumber}
                onChange={handleChange}
                isInvalid={!!errors.empSalaryAccountNumber}
              />
            </Form.Group>
          </div>

          {/* Bank Branch Name */}
          <div className="col-xl-4 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">ชื่อสาขา (ถ้ามี)</Form.Label>
              <Form.Control
                type="text"
                name="empSalaryBranchName"
                className="form-control"
                placeholder="กรอกชื่อสาขา"
                value={values.empSalaryBranchName}
                onChange={handleChange}
                isInvalid={!!errors.empSalaryBranchName}
              />
            </Form.Group>
          </div>

          {/* Bank Branch Number */}
          <div className="col-xl-4 col-md-6">
            <Form.Group className="mb-2 form-st-3 pb-1">
              <Form.Label className="mb-1">เลขที่สาขา (ถ้ามี)</Form.Label>
              <Form.Control
                type="text"
                name="empSalaryTaxNumber"
                className="form-control"
                placeholder="กรอกเลขที่สาขา"
                value={values.empSalaryTaxNumber}
                onChange={handleChange}
                isInvalid={!!errors.empSalaryTaxNumber}
              />
            </Form.Group>
          </div>
        </div>
      )}
    </div>
  );
};

export default SalaryInfo;
