import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    data: [],
  },
  getById: [],
  kitchenProducts: {
    data: [],
  },
  kitchenCategories: {
    data: [],
  },
};

const slice = createSlice({
  name: "kitchen",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    getById: (state, action) => {
      state.getById = action.payload;
    },
    deleteItem: (state, action) => {
      state.data.data = state.data.data.filter(
        (item) => item.id !== action.payload
      );
    },

    setKitchenProducts: (state, action) => {
      state.kitchenProducts = action.payload;
    },
    deleteKitchenProduct: (state, action) => {
      state.kitchenProducts.data = state.kitchenProducts.data.filter(
        (item) => item.id !== action.payload
      );
    },

    setKitchenCategories: (state, action) => {
      state.kitchenCategories = action.payload;
    },
    deleteKitchenCategory: (state, action) => {
      state.kitchenCategories.data = state.kitchenCategories.data.filter(
        (item) => item.id !== action.payload
      );
    },
  },
});

export const {
  getById,
  setData,
  deleteItem,
  setKitchenProducts,
  deleteKitchenProduct,
  setKitchenCategories,
  deleteKitchenCategory,
} = slice.actions;

export default slice.reducer;
