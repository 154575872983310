import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { Formik } from "formik";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import {
  masterHandler,
  branchPaymentHandler,
} from "../../../../../redux/handlers";
import * as yup from "yup";

export default function SettingRoundOOffModal(props) {
  const { branchID, ...rest } = props;
  const dispatch = useDispatch();

  const roundingList = useSelector((state) => state.master.roundingList || []);

  useEffect(() => {
    dispatch(masterHandler.getRoundingSettings());
  }, [dispatch]);

  const optionsRoundoff = roundingList.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const schema = yup.object().shape({
    roundoff: yup.number().required("กรุณาเลือกการปัดเศษ"),
  });

  const handleSubmit = async (values) => {
    try {
      const selectedOption = optionsRoundoff.find(
        (option) => option.value === values.roundoff
      );

      const response = await dispatch(
        branchPaymentHandler.updateBranchPaymentRounding(branchID, {
          rounding_setting_id: values.roundoff,
        })
      );

      if (response) {
        props.onSuccess?.({
          ...values,
          roundoffText: selectedOption?.label || "",
        });
        props.onHide();
      }
    } catch (error) {
      console.error("Error updating rounding settings:", error);
    }
  };

  return (
    <Modal {...rest} size="md" centered>
      <Modal.Body>
        <div className="d-flex align-items-center px-3 py-2 mb-4 pb-3">
          <div className="fs-24 fw-bold me-auto" style={{ color: "#2DC76D" }}>
            ตั้งค่าปัดเศษ
          </div>
          <button
            onClick={props.onHide}
            className="btn btn-close-modal"
          ></button>
        </div>
        <div className="px-3">
          <Formik
            enableReinitialize
            initialValues={props.setting}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ตัวเลือกการปัดเศษ <span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    name="roundoff"
                    className={
                      errors.roundoff && touched.roundoff
                        ? "w-100 invalid select-st-1"
                        : "w-100 select-st-1"
                    }
                    options={optionsRoundoff}
                    placeholder="ตัวเลือกการปัดเศษ"
                    onChange={(e) => {
                      setFieldValue("roundoff", e.value);
                    }}
                    value={optionsRoundoff.filter(
                      (item) => item.value === values.roundoff
                    )}
                  />
                  {errors.roundoff && touched.roundoff && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {errors.roundoff}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <div className="text-end pt-2 pb-3 btn-group-control">
                  <Button
                    type="button"
                    className="btn-st-6 me-3"
                    style={{ width: "100px" }}
                    onClick={props.onHide}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="submit"
                    className="btn-st-3"
                    style={{ width: "100px" }}
                  >
                    บันทึก
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}
