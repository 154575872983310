import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import { ModalAllder } from "layouts/ModalAllder";

export function DepartmentModal({
  modal,
  setModal,
  onSubmit,
  isEdit = false,
  departmentData,
  ...props
}) {
  const schema = yup.object().shape({
    code: yup.string().required("กรุณากรอกรหัสแผนก"),
    name_th: yup.string().required("กรุณากรอกชื่อแผนก (TH)"),
    name_en: yup.string().required("กรุณากรอกชื่อแผนก (ENG)"),
  });

  const [initialValues, setInitialValues] = useState({
    code: "",
    name_th: "",
    name_en: "",
  });

  useEffect(() => {
    if (isEdit && departmentData) {
      setInitialValues({
        id: departmentData.id,
        code: departmentData.code || "",
        name_th: departmentData.name_th || "",
        name_en: departmentData.name_en || "",
      });
    } else {
      setInitialValues({
        code: "",
        name_th: "",
        name_en: "",
      });
    }
  }, [isEdit, departmentData]);

  return (
    <ModalAllder
      modal={modal}
      setModal={setModal}
      htitle={isEdit ? "แก้ไขแผนกงาน" : "เพิ่มแผนกงาน"}
      hbtn={true}      
    >
      <div className="py-3">
        <div className="">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={async (values) => {
              try {
                await onSubmit({
                  id: values.id,
                  code: values.code,
                  name_th: values.name_th,
                  name_en: values.name_en,
                });
              } catch (error) {
                console.error("Error submitting form:", error);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              isValid,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    รหัสแผนก <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="code"
                    className="form-control"
                    placeholder="รหัสแผนก"
                    value={values.code}
                    onChange={handleChange}
                    isInvalid={touched.code && !!errors.code}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.code}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ชื่อแผนกงาน (TH) <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name_th"
                    className="form-control"
                    placeholder="ชื่อแผนกงาน"
                    value={values.name_th}
                    onChange={handleChange}
                    isInvalid={touched.name_th && !!errors.name_th}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name_th}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ชื่อแผนกงาน (ENG) <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name_en"
                    className="form-control"
                    placeholder="ชื่อแผนกงาน"
                    value={values.name_en}
                    onChange={handleChange}
                    isInvalid={touched.name_en && !!errors.name_en}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name_en}
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="text-end pt-4 pb-3 btn-group-control">
                  <Button
                    type="button"
                    className="btn-st-6 me-3"
                    style={{ width: "100px" }}
                    onClick={props.onHide}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="submit"
                    className="btn-st-3"
                    style={{ width: "100px" }}
                    disabled={!isValid}
                  >
                    {isEdit ? "อัปเดต" : "บันทึก"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModalAllder>
  );
}
