import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import { GeneralInfo } from "./components/form-sections/GeneralInfo";
import { ContactInfo } from "./components/form-sections/ContactInfo";
import { WorkInfo } from "./components/form-sections/WorkInfo";
import { SalaryInfo } from "./components/form-sections/SalaryInfo";
import { UserInfo } from "./components/form-sections/UserInfo";
import { ImageUpload } from "./components/ImageUpload";
import {
  ChangeEmailModal,
  ChangePasswordModal,
  ChangePinModal,
  ConfirmCancelModal,
  ConfirmChangeEmailModal,
  ConfirmChangePasswordModal,
  ConfirmChangePinModal,
  ConfirmCancelEmpModal,
  SuccessModal,
} from "./components/modals";
import { ThailandAddressValue } from "react-thailand-address-typeahead";
import { employeeHandler, masterHandler } from "../../../redux/handlers";
import { SubmitForm } from "./hook/SubmitForm";
import { SubmitChangeEmail } from "./hook/SubmitChangeEmail";
import { SubmitChangePin } from "./hook/SubmitChangePin";
import { SubmitChangePassword } from "./hook/SubmitChangePassword";
import ConfirmUnCancelEmpModal from "./components/modals/ConfirmUnCancelEmpModal";
import { InfoIcon } from "lucide-react";
import dayjs from "dayjs";
import { useBreadcrumb } from "../../../hooks/useBreadcrumb";
import Breadcrumb from "../../../components/Breadcrumb";

export function EmployeeForm() {
  const dispatch = useDispatch();
  const { empID, businessSlug } = useParams();
  const navigate = useNavigate();

  const employeeStatuses = useSelector(
    (state) => state.master.employeeStatuses
  );
  const employeeTypes = useSelector((state) => state.master.employeeTypes);
  const maritalStatuses = useSelector((state) => state.master.maritalStatuses);
  const militaryStatuses = useSelector(
    (state) => state.master.militaryStatuses
  );
  const relationshipTypes = useSelector(
    (state) => state.master.relationshipTypes
  );
  const salaryTypes = useSelector((state) => state.master.salaryTypes);
  const taxBranches = useSelector((state) => state.master.taxBranches);
  const vatTypes = useSelector((state) => state.master.vatTypes);
  const accountTypes = useSelector((state) => state.master.accountTypes);
  const prefixNames = useSelector((state) => state.master.prefixNames);
  const genders = useSelector((state) => state.master.genders);
  const banks = useSelector((state) => state.master.banks);
  const employeePositions = useSelector(
    (state) => state.master.employeePositions
  );
  const employeeDepartments = useSelector(
    (state) => state.master.employeeDepartments
  );

  const prefixType = prefixNames?.map((prefix) => ({
    value: prefix.id,
    label: prefix.name,
  }));

  const optionsGender = genders?.map((gender) => ({
    value: gender.id,
    label: gender.name,
  }));

  const optionsMarital = maritalStatuses?.map((status) => ({
    value: status.id,
    label: status.name,
  }));

  const optionsMilitary = militaryStatuses?.map((status) => ({
    value: status.id,
    label: status.name,
  }));

  const optionsempType = employeeTypes?.map((type) => ({
    value: type.id,
    label: type.name,
  }));

  const optionsempStatus = employeeStatuses?.map((status) => ({
    value: status.id,
    label: status.name,
  }));

  const optionsRelationship = relationshipTypes?.map((type) => ({
    value: type.id,
    label: type.name,
  }));

  const optionsaccountType = accountTypes?.map((type) => ({
    value: type.id,
    label: type.name,
  }));

  const optionsBank = banks?.map((bank) => ({
    value: bank.id,
    label: bank.name,
  }));

  const optionsPosition = employeePositions?.map((position) => ({
    value: position.id,
    label: position.name_th,
  }));

  const optionsDepartment = employeeDepartments?.map((department) => ({
    value: department.id,
    label: department.name_th,
  }));

  const [modalcancel, setModalcancel] = useState(false);
  const [modalsuccess, setModalsuccess] = useState(false);
  const [textsuccess, setTextsuccess] = useState("ทำรายการสำเร็จ");
  const [modalConfirmCancelEmp, setModalConfirmCancelEmp] = useState(false);
  const [modalConfirmUnCancelEmp, setModalConfirmUnCancelEmp] = useState(false);
  const [modalConfirmChangeEmail, setModalConfirmChangeEmail] = useState(false);
  const [modalConfirmChangePassword, setModalConfirmChangePassword] =
    useState(false);
  const [modalConfirmChangePin, setModalConfirmChangePin] = useState(false);
  const [formdisabled, setFormdisabled] = useState(false);
  const [cardOpen1, setCardOpen1] = useState(true);
  const [cardOpen2, setCardOpen2] = useState(true);
  const [cardOpen3, setCardOpen3] = useState(true);
  const [cardOpen4, setCardOpen4] = useState(true);
  const [cardOpen5, setCardOpen5] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [branchselected, setBranchselected] = useState([]);
  const [optionsBranch, setOptionsBranch] = useState([]);
  const [empCardNumber, setEmpCardNumber] = useState(Array(13).fill(""));
  const [formEmail, setFormEmail] = useState("");
  const [formPassword, setFormPassword] = useState("");
  const [formPin, setFormPin] = useState("");
  const [address, setAddress] = useState(
    ThailandAddressValue.fromDatasourceItem({
      d: "",
      p: "",
      po: "",
      s: "",
    })
  );

  const [modalEmail, setModalEmail] = useState({
    open: false,
    x: 0,
    y: 0,
    width: 460,
    height: 300,
    pin: false,
    mode: "normal",
    modalname: "change-email",
  });

  const [modalChangePassword, setModalChangePassword] = useState({
    open: false,
    x: 0,
    y: 0,
    width: 460,
    height: 360,
    pin: false,
    mode: "normal",
    modalname: "change-password",
  });

  const [modalChangePin, setModalChangePin] = useState({
    open: false,
    x: 0,
    y: 0,
    width: 460,
    height: 360,
    pin: false,
    mode: "normal",
    modalname: "change-pin-2",
  });

  const [formdata, setFormdata] = useState({
    businessSlug: businessSlug,
    code: "",
    imagePath: "",
    prefixNameId: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    empPin: "",
    empFirstNameEn: "",
    empLastNameEn: "",
    empCardNumber: "",
    empNickname: "",
    empGenderId: "",
    empBirthDate: "",
    age: "",
    empRace: "",
    empNationality: "",
    empMaritalId: 0,
    empMilitaryId: 0,
    empContactAddress: "",
    empContactSubDistrictId: "",
    empContactDistrictId: "",
    empContactProvinceId: "",
    empContactPostalCode: "",
    empContactEmail: "",
    empContactPhoneNumber: "",
    empContactLine: "",
    empContactFacebook: "",
    empContactIg: "",
    empContactEmergency: "",
    empContactEmergencyPhoneNumber: "",
    empContactRelationshipId: "",
    empWorkStartDate: "",
    empWorkEndDate: "",
    empWorkEmployeeTypeId: "",
    empWorkEmployeeStatusId: "",
    empWorkEmployeeDepartmentId: "",
    empWorkEmployeePositionId: "",
    empWorkIsSocialSecurityRegistration: "",
    userBusinessGroupBranch: [],
    empSalarySalaryTypeId: "",
    empSalaryBankId: "",
    empSalaryAccountTypeId: "",
    empSalaryAccountName: "",
    empSalaryAccountNumber: "",
    empSalaryBranchName: "",
    empSalaryTaxNumber: "",
  });

  const schema = yup.object().shape({
    firstName: yup.string().required("กรุณากรอกชื่อ"),
    lastName: yup.string().required("กรุณากรอกนามสกุล"),
    code: yup.string().required("กรุณากรอกรหัสพนักงาน"),
    prefixNameId: yup.string().required("กรุณากรอกคำนำหน้า"),
    empContactPhoneNumber: yup.string().required("กรุณากรอกเบอร์โทรศัพท์"),
    email: yup.string().required("กรุณากรอกอีเมล"),
    empWorkEmployeeDepartmentId: yup.string().required("กรุณากรอกแผนก"),
    empWorkEmployeePositionId: yup.string().required("กรุณากรอกตำแหน่ง"),
  });

  const breadcrumbConfig = [
    { label: "หน้าหลัก", path: "/" },
    { label: "พนักงาน", path: "/emps" },
    { label: "รายชื่อพนักงาน", path: "/emps" },
    { label: empID ? "แก้ไขรายชื่อพนักงาน" : "สร้างรายชื่อพนักงาน" },
  ];
  useBreadcrumb(breadcrumbConfig);

  if (empID) {
    schema.fields.email = yup
      .string()
      .required("กรุณากรอกอีเมล")
      .email("รูปแบบอีเมลไม่ถูกต้อง");
    schema.fields.password = yup.string().required("กรุณากรอกรหัสผ่าน");
    schema.fields.empPin = yup.string().required("กรุณากรอกรหัสผ่าน");
  }

  const handleinputTab = (setFieldValue, e, index) => {
    const updateItem = [...empCardNumber];
    updateItem[index] = e.target.value;
    setEmpCardNumber(updateItem);
    setFieldValue("empCardNumber", updateItem.join(""));
  };

  const handleChangeBranch = (options) => {
    const optionselect = options?.map((option) => option.value);
    setBranchselected(optionselect);
  };

  useEffect(() => {
    fetchInitialData();
    if (empID) {
      setFormdisabled(true);
      findEmpData(empID);
    }
  }, [dispatch, empID]);

  const fetchInitialData = async () => {
    try {
      await Promise.all([
        dispatch(masterHandler.getProvinces()),
        dispatch(masterHandler.getEmployeeStatuses()),
        dispatch(masterHandler.getEmployeeTypes()),
        dispatch(masterHandler.getMaritalStatuses()),
        dispatch(masterHandler.getMilitaryStatuses()),
        dispatch(masterHandler.getRelationshipTypes()),
        dispatch(masterHandler.getSalaryTypes()),
        dispatch(masterHandler.getTaxBranches()),
        dispatch(masterHandler.getVatTypes()),
        dispatch(masterHandler.getAccountTypes()),
        dispatch(masterHandler.getPrefixNames("NATURAL")),
        dispatch(masterHandler.getGenders()),
        dispatch(masterHandler.getBanks()),
        dispatch(masterHandler.getEmployeePositionAll({ businessSlug })),
        dispatch(masterHandler.getEmployeeDepartmentAll({ businessSlug })),
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const findEmpData = async (id) => {
    dispatch(employeeHandler.getEmployeeById(id)).then(async (response) => {
      if (response.emp_contact_sub_district_id) {
        const provinceId = response.empContactSubDistrict.district.province.id;
        await dispatch(masterHandler.getDistricts(provinceId));

        const districtId = response.empContactSubDistrict.district.id;
        await dispatch(masterHandler.getSubDistricts(districtId));
      }

      setFormdata({
        businessSlug: businessSlug,
        code: response.code,
        imagePath: response.image_path,
        prefixNameId: response.prefix_name_id,
        firstName: response.first_name,
        lastName: response.last_name,
        email: response.email,
        password: "",
        empPin: "",
        empFirstNameEn: response.emp_first_name_en,
        empLastNameEn: response.emp_last_name_en,
        empCardNumber: response.emp_card_number,
        empNickname: response.emp_nickname,
        empGenderId: response.emp_gender_id,
        empBirthDate: response.emp_birth_date
          ? dayjs(response.emp_birth_date).format("YYYY-MM-DD")
          : "",
        age: response.age,
        empRace: response.emp_race,
        empNationality: response.emp_nationality,
        empMaritalId: response.emp_marital_id,
        empMilitaryId: response.emp_military_id,
        empContactAddress: response.emp_contact_address,
        empContactSubDistrictId: response.emp_contact_sub_district_id,
        empContactDistrictId: response.emp_contact_sub_district_id
          ? response.empContactSubDistrict.district.id
          : null,
        empContactProvinceId: response.emp_contact_sub_district_id
          ? response.empContactSubDistrict.district.province.id
          : null,
        empContactPostalCode: response.empContactSubDistrict?.zip_code,
        empContactEmail: response.emp_contact_email,
        empContactPhoneNumber: response.emp_contact_phone_number,
        empContactLine: response.emp_contact_line,
        empContactFacebook: response.emp_contact_facebook,
        empContactIg: response.emp_contact_ig,
        empContactEmergency: response.emp_contact_emergency,
        empContactEmergencyPhoneNumber:
          response.emp_contact_emergency_phone_number,
        empContactRelationshipId: response.emp_contact_relationship_id,
        empWorkStartDate: response.emp_work_start_date
          ? dayjs(response.emp_work_start_date).format("YYYY-MM-DD")
          : "",
        empWorkEndDate: response.emp_work_end_date
          ? dayjs(response.emp_work_end_date).format("YYYY-MM-DD")
          : "",
        empWorkEmployeeTypeId: response.emp_work_employee_type_id,
        empWorkEmployeeStatusId: response.emp_work_employee_status_id,
        empWorkEmployeeDepartmentId:
          response.emp_work_business_employee_department_id,
        empWorkEmployeePositionId:
          response.emp_work_business_employee_position_id,
        empWorkIsSocialSecurityRegistration:
          response.emp_work_is_social_security_registration,
        userBusinessGroupBranch: response.userBusinessGroupBranch,
        empSalarySalaryTypeId: response.emp_salary_salary_type_id,
        empSalaryBankId: response.emp_salary_bank_id,
        empSalaryAccountTypeId: response.emp_salary_account_type_id,
        empSalaryAccountName: response.emp_salary_account_name,
        empSalaryAccountNumber: response.emp_salary_account_number,
        empSalaryBranchName: response.emp_salary_branch_name,
        empSalaryTaxNumber: response.emp_salary_tax_number,
        emp_work_is_all_branch: response.emp_work_is_all_branch,
      });
      setBranchselected(
        response.userBusinessGroupBranch?.map((i) => i.branch_id)
      );
      setEmpCardNumber(response.emp_card_number?.split("") ?? []);
    });
  };

  return (
    <div>
      <Breadcrumb />

      <Formik
        enableReinitialize
        initialValues={formdata}
        validationSchema={schema}
        onSubmit={async (values) => {
          const employeeData = {
            ...values,
            age: undefined,
            empContactDistrictId: undefined,
            empContactProvinceId: undefined,
            empContactPostalCode: undefined,
            userBusinessGroupBranch: undefined,
            empBranch: undefined,
          };
          let result = await SubmitForm(
            dispatch,
            empID,
            businessSlug,
            employeeData
          );
          if (result) {
            setFormdisabled(true);
            setTextsuccess("บันทึกข้อมูลสำเร็จ");
            setModalsuccess(true);
            navigate(`/${businessSlug}/emps`);
          }
        }}
      >
        {({
          handleSubmit,
          handleChange,
          touched,
          errors,
          values,
          setFieldValue,
        }) => (
          <Form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              const tx = empCardNumber.join("");
              setFieldValue("empCardNumber", tx);
              setFieldValue("empBranch", branchselected);
              handleSubmit();
            }}
          >
            <fieldset disabled={formdisabled}>
              <div className="card card-st-3 mb-3">
                <div className="card-body px-xl-3">
                  <button
                    type="button"
                    className="btn w-100 text-start btn-collapse"
                    onClick={() => setCardOpen1(!cardOpen1)}
                    aria-controls="collapse1"
                    aria-expanded={cardOpen1}
                  >
                    <div className="tc-8 fs-18 fw-bold px-3">ข้อมูลทั่วไป</div>
                  </button>
                  <Collapse in={cardOpen1}>
                    <div id="collapse1 ">
                      {values.empWorkEndDate && (
                        <div className="px-3">
                          <div className="bg-[#F95057] bg-opacity-20 py-1 px-2 text-[#FF0000] text-[16px] flex items-center rounded font-semibold w-fit">
                            <InfoIcon className="text-[#FF0000] text-[16px] mr-2" />
                            พนักงานลาออกไปไปเมื่อวันที่ {values.empWorkEndDate}
                          </div>
                        </div>
                      )}
                      <ImageUpload
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                      <GeneralInfo
                        values={values}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        empCardNumber={empCardNumber}
                        formdisabled={formdisabled}
                        handleinputTab={(value, index) =>
                          handleinputTab(setFieldValue, value, index)
                        }
                        prefixType={prefixType}
                        optionsGender={optionsGender}
                        optionsMarital={optionsMarital}
                        optionsMilitary={optionsMilitary}
                      />
                    </div>
                  </Collapse>
                </div>
              </div>

              <div className="card card-st-3 mb-3">
                <div className="card-body px-xl-3">
                  <button
                    type="button"
                    className="btn w-100 text-start btn-collapse"
                    onClick={() => setCardOpen2(!cardOpen2)}
                    aria-controls="collapse2"
                    aria-expanded={cardOpen2}
                  >
                    <div className="tc-8 fs-18 fw-bold px-3">
                      ข้อมูลการติดต่อ
                    </div>
                  </button>
                  <Collapse in={cardOpen2}>
                    <div id="collapse2">
                      <ContactInfo
                        values={values}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        address={address}
                        setAddress={setAddress}
                        optionsRelationship={optionsRelationship}
                      />
                    </div>
                  </Collapse>
                </div>
              </div>

              <div className="card card-st-3 mb-3">
                <div className="card-body px-xl-3">
                  <button
                    type="button"
                    className="btn w-100 text-start btn-collapse"
                    onClick={() => setCardOpen3(!cardOpen3)}
                    aria-controls="collapse3"
                    aria-expanded={cardOpen3}
                  >
                    <div className="tc-8 fs-18 fw-bold px-3">
                      ข้อมูลการทำงาน
                    </div>
                  </button>
                  <Collapse in={cardOpen3}>
                    <div id="collapse3">
                      <WorkInfo
                        values={values}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        optionsempType={optionsempType}
                        optionsempStatus={optionsempStatus}
                        optionsDepartment={optionsDepartment}
                        optionsPosition={optionsPosition}
                        optionsBranch={optionsBranch}
                        branchselected={branchselected}
                        handleChangeBranch={handleChangeBranch}
                      />
                    </div>
                  </Collapse>
                </div>
              </div>

              <div className="card card-st-3 mb-3">
                <div className="card-body px-xl-3">
                  <button
                    type="button"
                    className="btn w-100 text-start btn-collapse"
                    onClick={() => setCardOpen4(!cardOpen4)}
                    aria-controls="collapse4"
                    aria-expanded={cardOpen4}
                  >
                    <div className="tc-8 fs-18 fw-bold px-3">
                      ช่องทางการรับเงินเดือน
                    </div>
                  </button>
                  <Collapse in={cardOpen4}>
                    <div id="collapse4">
                      <SalaryInfo
                        values={values}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        optionsBank={optionsBank}
                        optionsaccountType={optionsaccountType}
                      />
                    </div>
                  </Collapse>
                </div>
              </div>
            </fieldset>

            <div className="card card-st-3 mb-3">
              <div className="card-body px-xl-3">
                <button
                  type="button"
                  className="btn w-100 text-start btn-collapse"
                  onClick={() => setCardOpen5(!cardOpen5)}
                  aria-controls="collapse5"
                  aria-expanded={cardOpen5}
                >
                  <div className="tc-8 fs-18 fw-bold px-3">ข้อมูล USER</div>
                </button>
                <Collapse in={cardOpen5}>
                  <div id="collapse5">
                    <UserInfo
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      empID={empID}
                      setModalEmail={setModalEmail}
                      setModalChangePassword={setModalChangePassword}
                      setModalChangePin={setModalChangePin}
                      formdisabled={formdisabled}
                    />
                  </div>
                </Collapse>
              </div>
            </div>
            <div className="py-10"></div>
            <div className="pb-4 pe-3">
              <div
                className="card card-st-3 mb-4 fixed z-[999] bottom-0 w-[calc(100%-265px)]  !bg-[#FFF] drop-shadow"
                style={{ minWidth: "1000px" }}
              >
                <div className="card-body px-xl-3 flex items-center">
                  {!values.empWorkEndDate && empID && (
                    <Button
                      type="button"
                      className="btn btn-st-3 !bg-[#F95057] !border-0 w-[180px] !rounded-lg !text-white px-4"
                      onClick={() => {
                        setModalConfirmCancelEmp(true);
                      }}
                    >
                      พนักงานลาออก
                    </Button>
                  )}
                  {values.empWorkEndDate && empID && (
                    <Button
                      type="button"
                      className="btn btn-st-3 !bg-[#2DC76D] !border-0 w-[180px] !rounded-lg !text-white px-4"
                      onClick={() => {
                        setModalConfirmUnCancelEmp(true);
                      }}
                    >
                      ยกเลิกการลาออก
                    </Button>
                  )}
                  {!values.empWorkEndDate && (
                    <div className="ml-auto">
                      {formdisabled ? (
                        <Button
                          type="button"
                          className="btn-st-3 mx-3"
                          onClick={() => setFormdisabled(false)}
                        >
                          แก้ไข
                        </Button>
                      ) : (
                        <div className="text-end pt-3 btn-group-control">
                          <Button
                            type="button"
                            className="btn-st-6 me-3 btn"
                            onClick={() => setModalcancel(true)}
                          >
                            ยกเลิก
                          </Button>
                          <Button type="submit" className="btn-st-3">
                            บันทึก
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <ConfirmCancelModal
        show={modalcancel}
        onHide={() => setModalcancel(false)}
      />
      <ConfirmCancelEmpModal
        show={modalConfirmCancelEmp}
        empID={empID}
        onHide={() => {
          setModalConfirmCancelEmp(false);
        }}
        onSuccess={() => {
          setModalConfirmCancelEmp(false);
          findEmpData(empID);
          setTextsuccess("บันทึกข้อมูลสำเร็จ");
          setModalsuccess(true);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }}
      />
      <ConfirmUnCancelEmpModal
        show={modalConfirmUnCancelEmp}
        empID={empID}
        onHide={() => {
          setModalConfirmUnCancelEmp(false);
        }}
        onSuccess={() => {
          setModalConfirmUnCancelEmp(false);
          findEmpData(empID);
          setTextsuccess("บันทึกข้อมูลสำเร็จ");
          setModalsuccess(true);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }}
      />
      <SuccessModal
        show={modalsuccess}
        onHide={() => {
          setModalsuccess(false);
        }}
        textsuccess={textsuccess}
      />

      <ChangeEmailModal
        modal={modalEmail}
        setModal={setModalEmail}
        formaccount={formdata}
        empID={empID}
        onSubmit={(values) => {
          setFormEmail(values.email);
          setModalConfirmChangeEmail(true);
        }}
      />
      <ConfirmChangeEmailModal
        show={modalConfirmChangeEmail}
        onHide={() => setModalConfirmChangeEmail(false)}
        onConfirm={async () => {
          let result = await SubmitChangeEmail(dispatch, empID, {
            email: formEmail,
          });
          if (result) {
            findEmpData(empID);
            setModalConfirmChangeEmail(false);
            setModalsuccess(true);
          }
        }}
      />

      <ChangePasswordModal
        modal={modalChangePassword}
        setModal={setModalChangePassword}
        onSubmit={(values) => {
          setFormPassword(values.password);
          setModalConfirmChangePassword(true);
        }}
      />
      <ConfirmChangePasswordModal
        show={modalConfirmChangePassword}
        onHide={() => setModalConfirmChangePassword(false)}
        onConfirm={async () => {
          let result = await SubmitChangePassword(dispatch, empID, {
            password: formPassword,
          });
          if (result) {
            findEmpData(empID);
            setModalConfirmChangePassword(false);
            setModalsuccess(true);
          }
        }}
      />

      <ChangePinModal
        modal={modalChangePin}
        setModal={setModalChangePin}
        onSubmit={(values) => {
          setFormPin(values.pin);
          setModalConfirmChangePin(true);
        }}
      />
      <ConfirmChangePinModal
        show={modalConfirmChangePin}
        onHide={() => setModalConfirmChangePin(false)}
        onConfirm={async () => {
          let result = await SubmitChangePin(dispatch, empID, {
            empPin: formPin,
          });
          if (result) {
            findEmpData(empID);
            setModalConfirmChangePin(false);
            setModalsuccess(true);
          }
        }}
      />
    </div>
  );
}
