import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import SettingReceivePoint from './components/SettingReceivePoint';
import SettingUsagePoint from './components/SettingUsagePoint';
export function MarketingPoint() {
  const [btnMenu, setBtnMenu] = useState(1);

  return (
    <div>
      <nav aria-label="breadcrumb" className="mt-2">
        <ol className="breadcrumb fs-16 ps-0">
          <li className="breadcrumb-item ">
            <a href="#" className="tc-7  text-decoration-none">
              เครื่องมือการตลาด
            </a>
          </li>
          <li className="breadcrumb-item active tc-1 " aria-current="page">
            {' '}
            จัดการคะแนน{' '}
          </li>
        </ol>
      </nav>

      <div className="mb-4">
        <Button
          type="button"
          className={
            btnMenu == 1 ? 'btn-category px-4 active' : 'btn-category px-4'
          }
          onClick={() => {
            setBtnMenu(1);
          }}
        >
          ตั้งค่าการให้คะแนน{' '}
        </Button>
        <Button
          type="button"
          className={
            btnMenu == 0 ? 'btn-category px-4 active' : 'btn-category px-4'
          }
          onClick={() => {
            setBtnMenu(0);
          }}
        >
          ตั้งค่าการใช้คะแนน{' '}
        </Button>
      </div>

      {btnMenu == 1 ? <SettingReceivePoint /> : <SettingUsagePoint />}
    </div>
  );
}
