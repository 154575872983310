import React from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useBreadcrumb } from "../../../hooks/useBreadcrumb";
import Breadcrumb from "../../../components/Breadcrumb";

export function Category() {
  const { businessSlug } = useParams();
  const breadcrumbConfig = [
    { label: "หน้าหลัก", path: "/" },
    { label: "สินค้าและบริการ", path: "/product/category" },
    { label: "จัดการหมวดหมู่สินค้า" },
  ];
  useBreadcrumb(breadcrumbConfig);

  return (
    <div>
      <Breadcrumb />
      <div className="fs-24 tc-7  mb-4 lh-1">จัดการหมวดหมู่สินค้า </div>
      <div className="mb-3">
        <NavLink
          to={`/${businessSlug}/product/category`}
          className="btn btn-category me-3"
        >
          หมวดหมู่หลัก
        </NavLink>
        <NavLink
          to={`/${businessSlug}/product/subcategory`}
          className="btn btn-category"
        >
          หมวดหมูย่อย
        </NavLink>
      </div>
    </div>
  );
}
