import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useGlobalTable } from "../../../hooks/useGlobalTable";
import { createTableColumns } from "../../../utils/createTableColumns";
import { AllderTable } from "../../../components/AllderTable";
import {
  MainCategoryModal,
  ConfirmDeleteModal,
  SuccessModal,
} from "./components/modals";
import { Category } from "./Category";
import { productCategoryHandler } from "../../../redux/handlers";

const INITIAL_SELECTED_CATEGORY = {
  id: "",
  code: "",
  name: "",
  productSubCategory: [],
  product: [],
  is_display: true,
  is_active: true,
};

const INITIAL_MODAL_STATE = {
  open: false,
  x: 0,
  y: 0,
  width: 470,
  pin: false,
  mode: "normal",
};

const MODAL_HEIGHTS = {
  FORM: 540,
  CONFIRM: 320,
  SUCCESS: 300,
};

const MODAL_STATES = {
  CREATE: { ...INITIAL_MODAL_STATE, height: MODAL_HEIGHTS.FORM },
  EDIT: { ...INITIAL_MODAL_STATE, height: MODAL_HEIGHTS.FORM },
  CONFIRM: { ...INITIAL_MODAL_STATE, height: MODAL_HEIGHTS.CONFIRM },
  SUCCESS: { ...INITIAL_MODAL_STATE, height: MODAL_HEIGHTS.SUCCESS },
};

export function MainCategoryManagement() {
  const dispatch = useDispatch();
  const { businessSlug } = useParams();
  const [selectedCategory, setSelectedCategory] = useState(
    INITIAL_SELECTED_CATEGORY
  );
  const [successMsg, setSuccessMsg] = useState("");
  const [modalCreate, setModalCreate] = useState(MODAL_STATES.CREATE);
  const [modalEdit, setModalEdit] = useState(MODAL_STATES.EDIT);
  const [modalConfirmDelete, setModalConfirmDelete] = useState(
    MODAL_STATES.CONFIRM
  );
  const [modalSuccess, setModalSuccess] = useState(MODAL_STATES.SUCCESS);

  const fetchCategories = useCallback(
    (params) =>
      dispatch(
        productCategoryHandler.getProductCategories({
          business_slug: businessSlug,
          ...params,
        })
      ),
    [businessSlug, dispatch]
  );

  const {
    data: categories,
    loading,
    totalRows,
    paginationModel,
    setPaginationModel,
    handleSort,
    debouncedSearch,
    refreshData: refreshCategories,
    filterParams,
  } = useGlobalTable({
    fetchData: fetchCategories,
    defaultSort: "created_at",
    defaultOrder: "desc",
    defaultPageSize: 20,
  });

  const handleCreateCategory = async (values) => {
    try {
      await dispatch(
        productCategoryHandler.createProductCategory({
          businessSlug,
          code: values.categoryCode,
          name: values.categoryName,
          isDisplay: values.isDisplay,
          date: new Date().toISOString(),
        })
      );
      refreshCategories();
      setSuccessMsg("บันทึกหมวดหมู่สำเร็จ");
      setModalCreate((prev) => ({ ...prev, open: false }));
      setModalSuccess((prev) => ({ ...prev, open: true }));
      setSelectedCategory(INITIAL_SELECTED_CATEGORY);
    } catch (error) {
      console.error("Error creating category:", error);
    }
  };

  const handleUpdateCategory = async (values) => {
    try {
      await dispatch(
        productCategoryHandler.updateProductCategory(values.id, {
          businessSlug,
          code: values.categoryCode,
          name: values.categoryName,
          isDisplay: values.isDisplay,
          date: new Date().toISOString(),
        })
      );
      refreshCategories();
      setSuccessMsg("แก้ไขหมวดหมู่สำเร็จ");
      setModalEdit((prev) => ({ ...prev, open: false }));
      setModalSuccess((prev) => ({ ...prev, open: true }));
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  const handleDeleteCategory = async () => {
    try {
      await dispatch(
        productCategoryHandler.deleteProductCategory(selectedCategory.id)
      );
      refreshCategories();
      setSuccessMsg("ลบหมวดหมู่สำเร็จ");
      setModalConfirmDelete((prev) => ({ ...prev, open: false }));
      setModalSuccess((prev) => ({ ...prev, open: true }));
      setSelectedCategory(INITIAL_SELECTED_CATEGORY);
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const handleExport = async () => {
    try {
      await dispatch(
        productCategoryHandler.exportProductCategoriesToExcel(
          businessSlug,
          filterParams.s
        )
      );
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const handleEditClick = async (row) => {
    try {
      const response = await dispatch(
        productCategoryHandler.getProductCategoryById(row.id)
      );
      if (response) {
        setSelectedCategory({
          id: response.id,
          code: response.code,
          name: response.name,
          is_display: response.is_display,
          created_at: response.created_at,
          userBusinessGroup: response.userBusinessGroup,
          productSubCategory: row.productSubCategory || [],
          product: row.product || [],
        });
        setModalEdit((prev) => ({ ...prev, open: true }));
      }
    } catch (error) {
      console.error("Error fetching category details:", error);
    }
  };

  const columns = createTableColumns([
    {
      field: "code",
      headerName: "รหัสหมวดหมู่หลัก",
      width: 170,
      sortable: true,
      renderCell: ({ row }) => (
        <div
          style={{
            color: "#2DC76D",
            fontWeight: 500,
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={() => handleEditClick(row)}
        >
          {row.code}
        </div>
      ),
    },
    {
      field: "name",
      headerName: "ชื่อหมวดหมู่หลัก",
      width: 340,
      sortable: true,
      flex: 1,
      renderCell: ({ row }) => (
        <div style={{ fontWeight: 500 }}>{row.name}</div>
      ),
    },
    {
      field: "productSubCategory",
      headerName: "จำนวนหมวดหมู่ย่อย",
      width: 180,
      sortable: false,
      renderCell: ({ row }) => <div>{row.productSubCategory?.length || 0}</div>,
    },
    {
      field: "product",
      headerName: "จำนวนรายการ",
      width: 180,
      sortable: false,
      renderCell: ({ row }) => <div>{row.product?.length || 0}</div>,
    },
    {
      field: "actions",
      headerName: "จัดการ",
      width: 120,
      sortable: false,      
      renderCell: ({ row }) => (
        <div>
          <Button
            type="button"
            className="btn-edit-st-1"
            onClick={() => handleEditClick(row)}
          />
        </div>
      ),
    },
  ]);

  return (
    <div>
      <Category />

      <div className="card card-st-2">
        <div className="card-body px-0 py-4">
          <div className="text-lg font-bold mb-4 lh-1 px-4 mb-4 text-[#2DC76D]">
            หมวดหมู่หลัก
          </div>
          <div className="d-flex align-items-center px-4 mb-4">
            <button
              className="btn btn-export-st-1 me-auto"
              onClick={handleExport}
            >
              Export
            </button>
            <div className="me-3">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหาชื่อหมวดหมู่"
                onChange={(e) => debouncedSearch(e.target.value)}
              />
            </div>
            <Button
              type="button"
              className="btn btn-st-7"
              onClick={() => {
                setModalCreate((prev) => ({ ...prev, open: true }));
              }}
            >
              เพิ่มหมวดหมู่
            </Button>
          </div>

          <AllderTable
            data={categories}
            loading={loading}
            columns={columns}
            totalRows={totalRows}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            handleSort={handleSort}
          />
        </div>
      </div>

      <MainCategoryModal
        modal={modalCreate}
        setModal={setModalCreate}
        onHide={() => setModalCreate((prev) => ({ ...prev, open: false }))}
        onSubmit={handleCreateCategory}
        mode="create"
      />

      <MainCategoryModal
        modal={modalEdit}
        setModal={setModalEdit}
        initialData={selectedCategory}
        onHide={() => setModalEdit((prev) => ({ ...prev, open: false }))}
        onSubmit={handleUpdateCategory}
        onDelete={() =>
          setModalConfirmDelete((prev) => ({ ...prev, open: true }))
        }
        mode="edit"
      />

      <ConfirmDeleteModal
        modal={modalConfirmDelete}
        setModal={setModalConfirmDelete}
        categoryData={selectedCategory}
        onHide={() =>
          setModalConfirmDelete((prev) => ({ ...prev, open: false }))
        }
        onConfirm={handleDeleteCategory}
      />

      <SuccessModal
        modal={modalSuccess}
        setModal={setModalSuccess}
        title="จัดการหมวดหมู่หลัก"
        msg={successMsg}
        onHide={() => setModalSuccess((prev) => ({ ...prev, open: false }))}
      />
    </div>
  );
}
