import Form from "react-bootstrap/Form";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { masterHandler } from "../../../../../redux/handlers";

const Province = (props) => {
  const dispatch = useDispatch();
  const provinces = useSelector((state) => state.master.provinces);
  const { required = false, errors = {}, touched = {} } = props;

  const handleDistrict = async (provinceId) => {
    try {
      await dispatch(masterHandler.getDistricts(provinceId));
    } catch (error) {
      console.error("Error loading sub-districts:", error);
    }
  };

  return (
    <Form.Group className="mb-2 form-st-3 pb-1">
      <Form.Label className="mb-1">
        จังหวัด  <span className="text-danger ms-1">*</span>
      </Form.Label>
      <Select
        as="select"
        name="provinceId"
        className="w-100 select-st-1"
        options={
          provinces?.map((p) => ({
            value: p.id.toString(),
            label: p.name_th,
          })) || []
        }
        placeholder="เลือกจังหวัด"
        isDisabled={props.isReadOnly}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor:
              touched.provinceId && errors.provinceId
                ? "#dc3545"
                : baseStyles.borderColor,
            "&:hover": {
              borderColor:
                touched.provinceId && errors.provinceId
                  ? "#dc3545"
                  : baseStyles["&:hover"]?.borderColor,
            },
          }),
        }}
        onChange={(e) => {
          props.setFieldValue("provinceId", e.value);
          handleDistrict(e.value);
          props.setFieldValue("districtId", "");
          props.setFieldValue("potalcode", "");
          props.setFieldValue("subDistrictId", "");
        }}
        value={
          provinces
            ?.filter((p) => p.id == props.values.provinceId)
            .map((p) => ({
              value: p.id.toString(),
              label: p.name_th,
            })) || null
        }
      />
      {touched.provinceId && errors.provinceId && (
        <div className="invalid-feedback d-block">{errors.provinceId}</div>
      )}
    </Form.Group>
  );
};

export default Province;
