export const NETWORK_CONNECTION_MESSAGE =
  "Cannot connect to server, Please try again.";
export const NOT_CONNECT_NETWORK_CODE = 500;

export const ERROR_CODES = {
  NOT_VERIFIED: "NOT_HAS_VERIFY_EMAIL",
  USER_NOT_FOUND: "USER_NOT_FOUND",
  INVALID_REFRESH_TOKEN: "INVALID_REFRESH_TOKEN",
};

export const ERROR_MESSAGES = {
  USER_NOT_FOUND: "ไม่พบบัญชีผู้ใช้นี้ในระบบ กรุณาตรวจสอบอีกครั้ง",
  IS_GOOGLE_ACCOUNT: "บัญชีนี้เป็นบัญชี Google กรุณาเข้าสู่ระบบด้วย Google",
  NOT_HAS_VERIFY_EMAIL: "กรุณายืนยันอีเมลก่อนเข้าใช้งาน",
  INVALID_PASSWORD: "รหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
  EMAIL_ALREADY_EXIST: "อีเมลนี้ถูกใช้งานแล้ว กรุณาใช้อีเมลอื่น",
  EMAIL_HAS_ALREADY: "อีเมลนี้ถูกใช้งานแล้ว กรุณาใช้อีเมลอื่น",
  PHONE_NUMBER_ALREADY_EXIST: "เบอร์โทรนี้ถูกใช้งานแล้ว กรุณาใช้เบอร์อื่น",
  SLUG_ALREADY_USED: "เนื่องจาก slug นี้ถูกใช้งานแล้ว กรุณากรอกใหม่อีกครั้ง",
  BUSINESS_NOT_FOUND: "ไม่พบข้อมูลธุรกิจที่ค้นหา",
  BRANCH_CODE_ALREADY_EXISTS:
    "รหัสสาขานี้มีในระบบอยู่แล้ว กรุณาลองรหัสสาขาใหม่",
  DEFAULT: "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
  NETWORK_ERROR:
    "ไม่สามารถเชื่อมต่อกับระบบได้ กรุณาตรวจสอบการเชื่อมต่ออินเทอร์เน็ต",
  SERVER_ERROR: "เกิดข้อผิดพลาดจากระบบ กรุณาลองใหม่ภายหลัง",
  ALREADY_HAS_CODE: "Code นี้มีในระบบอยู่แล้ว กรุณาลอง Code ใหม่",
  PRODUCT_SKU_ALREADY_EXISTS:
    "รหัสสินค้านี้มีในระบบอยู่แล้ว กรุณาลองรหัสสินค้าใหม่",
};
