import React from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { masterHandler } from "../../../../../redux/handlers";
export const ContactInfo = ({
  values,
  handleChange,
  setFieldValue,
  errors,
  optionsRelationship,
}) => {
  const dispatch = useDispatch();
  const provinces = useSelector((state) => state.master.provinces);
  const districts = useSelector((state) => state.master.districts);
  const subDistricts = useSelector((state) => state.master.subDistricts);

  return (
    <div className="pt-3 px-3">
      <div className="row gx-xl-4">
        {/* Address */}
        <div className="col-xl-12 col-md-12">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">ที่อยู่</Form.Label>
            <Form.Control
              type="text"
              name="empContactAddress"
              className="form-control"
              placeholder="ที่อยู่"
              value={values.empContactAddress}
              onChange={handleChange}
              isInvalid={!!errors.empContactAddress}
            />
          </Form.Group>
        </div>

        {/* Province */}
        <div className="col-xl-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">จังหวัด</Form.Label>
            <Select
              name="empContactProvinceId"
              onChange={(e) => {
                dispatch(masterHandler.getDistricts(e.value));
                setFieldValue("empContactProvinceId", e.value);
                setFieldValue("empContactDistrictId", "");
                setFieldValue("empContactSubDistrictId", "");
                setFieldValue("empContactPostalCode", "");
              }}
              className={
                !!errors.empContactProvinceId
                  ? "w-100 select-st-1 isInvalid"
                  : "w-100 select-st-1"
              }
              options={
                provinces?.map((p) => ({
                  value: p.id.toString(),
                  label: p.name_th,
                })) || []
              }
              placeholder="เลือกจังหวัด"
              value={
                provinces
                  ?.filter((p) => p.id == values.empContactProvinceId)
                  .map((p) => ({
                    value: p.id.toString(),
                    label: p.name_th,
                  })) || null
              }
            />
            {errors.empContactProvinceId && (
              <div className="invalid-feedback d-block">
                {errors.empContactProvinceId}
              </div>
            )}
          </Form.Group>
        </div>

        {/* District */}
        <div className="col-xl-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">อำเภอ/เขต</Form.Label>
            <Select
              name="empContactDistrictId"
              onChange={(e) => {
                dispatch(masterHandler.getSubDistricts(e.value));
                setFieldValue("empContactDistrictId", e.value);
                setFieldValue("empContactSubDistrictId", "");
                setFieldValue("empContactPostalCode", "");
              }}
              className={
                !!errors.empContactDistrictId
                  ? "w-100 select-st-1 isInvalid"
                  : "w-100 select-st-1"
              }
              options={
                districts?.map((d) => ({
                  value: d.id.toString(),
                  label: d.name_th,
                })) || []
              }
              placeholder="กรอก อำเภอ/เขต"
              value={
                districts
                  ?.filter((d) => d.id == values.empContactDistrictId)
                  .map((d) => ({
                    value: d.id.toString(),
                    label: d.name_th,
                  })) || null
              }
              isDisabled={!values.empContactProvinceId}
            />
            {errors.empContactDistrictId && (
              <div className="invalid-feedback d-block">
                {errors.empContactDistrictId}
              </div>
            )}
          </Form.Group>
        </div>

        {/* Subdistrict */}
        <div className="col-xl-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">แขวง/ตำบล</Form.Label>
            <Select
              name="empContactSubDistrictId"
              onChange={(e) => {
                setFieldValue("empContactSubDistrictId", e.value);
                const subDistrict = subDistricts?.find((s) => s.id == e.value);
                if (subDistrict) {
                  setFieldValue("empContactPostalCode", subDistrict.zip_code);
                }
              }}
              className={
                !!errors.empContactSubDistrictId
                  ? "w-100 select-st-1 isInvalid"
                  : "w-100 select-st-1"
              }
              options={
                subDistricts?.map((s) => ({
                  value: s.id.toString(),
                  label: s.name_th,
                  zip_code: s.zip_code,
                })) || []
              }
              placeholder="เลือกแขวง/ตำบล"
              value={
                subDistricts
                  ?.filter((s) => s.id == values.empContactSubDistrictId)
                  .map((s) => ({
                    value: s.id.toString(),
                    label: s.name_th,
                  })) || null
              }
              isDisabled={!values.empContactDistrictId}
            />
            {errors.empContactSubDistrictId && (
              <div className="invalid-feedback d-block">
                {errors.empContactSubDistrictId}
              </div>
            )}
          </Form.Group>
        </div>

        {/* Postal Code */}
        <div className="col-xl-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">รหัสไปรษณีย์</Form.Label>
            <Form.Control
              type="text"
              name="empContactPostalCode"
              className="form-control"
              placeholder="กรอกรหัสไปรษณีย์"
              value={values.empContactPostalCode}
              disabled
              isInvalid={!!errors.empContactPostalCode}
            />
            <Form.Control.Feedback type="invalid">
              {errors.empContactPostalCode}
            </Form.Control.Feedback>
          </Form.Group>
        </div>

        {/* Rest of the contact fields remain the same */}
        <div className="col-xxl-2 col-xl-3 col-md-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">E-mail</Form.Label>
            <Form.Control
              type="text"
              name="empContactEmail"
              className="form-control"
              placeholder="E-mail"
              value={values.empContactEmail}
              onChange={handleChange}
              isInvalid={!!errors.empContactEmail}
            />
          </Form.Group>
        </div>

        <div className="col-xxl-3 col-xl-3 col-md-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">
              เบอร์โทรศัพท์ <span className="text-danger ms-1">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="empContactPhoneNumber"
              className="form-control"
              placeholder="เบอร์โทรศัพท์"
              value={values.empContactPhoneNumber}
              onChange={handleChange}
              isInvalid={!!errors.empContactPhoneNumber}
            />
            <Form.Control.Feedback type="invalid">
              {errors.empContactPhoneNumber}
            </Form.Control.Feedback>
          </Form.Group>
        </div>

        <div className="col-xxl-2 col-xl-3 col-md-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">Line</Form.Label>
            <Form.Control
              type="text"
              name="empContactLine"
              className="form-control"
              placeholder="Line"
              value={values.empContactLine}
              onChange={handleChange}
              isInvalid={!!errors.empContactLine}
            />
          </Form.Group>
        </div>

        <div className="col-xxl-3 col-xl-3 col-md-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">Facebook</Form.Label>
            <Form.Control
              type="text"
              name="empContactFacebook"
              className="form-control"
              placeholder="Facebook"
              value={values.empContactFacebook}
              onChange={handleChange}
              isInvalid={!!errors.empContactFacebook}
            />
          </Form.Group>
        </div>

        <div className="col-xxl-2 col-xl-3 col-md-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">Instagram</Form.Label>
            <Form.Control
              type="text"
              name="empContactIg"
              className="form-control"
              placeholder="Instagram"
              value={values.empContactIg}
              onChange={handleChange}
              isInvalid={!!errors.empContactIg}
            />
          </Form.Group>
        </div>

        <div className="col-xl-4 col-md-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">บุคคลติดต่อในกรณีฉุกเฉิน</Form.Label>
            <Form.Control
              type="text"
              name="empContactEmergency"
              className="form-control"
              placeholder="บุคคลติดต่อในกรณีฉุกเฉิน"
              value={values.empContactEmergency}
              onChange={handleChange}
              isInvalid={!!errors.empContactEmergency}
            />
          </Form.Group>
        </div>

        <div className="col-xl-4 col-md-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">
              เบอร์โทรศัพท์ผู้ติดต่อฉุกเฉิน
            </Form.Label>
            <Form.Control
              type="text"
              name="empContactEmergencyPhoneNumber"
              className="form-control"
              placeholder="เบอร์โทรศัพท์"
              value={values.empContactEmergencyPhoneNumber}
              onChange={handleChange}
              isInvalid={!!errors.empContactEmergencyPhoneNumber}
            />
          </Form.Group>
        </div>

        <div className="col-xl-4 col-md-6">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">ความสัมพันธ์</Form.Label>
            <Select
              name="empContactRelationshipId"
              onChange={(e) =>
                setFieldValue("empContactRelationshipId", e.value)
              }
              className="w-100 select-st-1"
              options={optionsRelationship}
              placeholder="ระบุความสัมพันธ์"
              value={optionsRelationship?.filter(
                (item) => item.value === values.empContactRelationshipId
              )}
            />
          </Form.Group>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
