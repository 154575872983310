import { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { businessHandler, masterHandler } from "../../../redux/handlers";
import { useDispatch } from "react-redux";
import BranchInfoForm, { schema } from "./components/forms/BranchInfoForm";
import useInitialData from "./hooks/useInitialData";
import formatBusinessName from "./lib/formatBusinessName";
import React from "react";
import POSManagementForm from "./components/forms/POSManagementForm";
import { useBreadcrumb } from "../../../hooks/useBreadcrumb";
import Breadcrumb from "../../../components/Breadcrumb";

export function BranchForm() {
  const dispatch = useDispatch();
  const { businessSlug, branchID } = useParams();
  const { branch, setBranch } = useInitialData(dispatch, businessSlug);
  const [posShow, setPosShow] = useState(false);
  const location = useLocation();
  const [isReadOnly, setIsReadOnly] = useState(false);
  const breadcrumbConfig = [
    { label: "หน้าหลัก", path: "/" },
    { label: "จัดการสาขา", path: "/branchs" },
    { label: branchID ? "แก้ไขการสาขา" : "สร้างสาขา" },
  ];
  useBreadcrumb(breadcrumbConfig);

  useEffect(() => {
    // Check if current path contains /setting/info/
    const isSettingInfoPath = location.pathname.includes("/setting/info/");
    setIsReadOnly(isSettingInfoPath);
  }, [location.pathname]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        await Promise.all([
          dispatch(masterHandler.getProvinces()),
          dispatch(masterHandler.getTaxBranches()),
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const findBusiness = async () => {
      const business = await dispatch(
        businessHandler.getBusinessBySlug(businessSlug)
      );
      if (business.business) {
        setBranch({
          businessName: formatBusinessName(business.business),
        });
      }
    };

    fetchInitialData();
    findBusiness();
  }, [dispatch, businessSlug]);

  useEffect(() => {
    if (branchID) {
      setPosShow(true);
    }
  }, [branchID]);

  return (
    <div className="p-8">
      {!isReadOnly && <Breadcrumb />}
      <BranchInfoForm
        initialValues={branch}
        businessSlug={businessSlug}
        isReadOnly={isReadOnly}
      />
      {posShow === true && (
        <POSManagementForm
          businessSlug={businessSlug}
          isReadOnly={isReadOnly}
        />
      )}
    </div>
  );
}
