import React from "react";

const POSDeviceCard = ({ device, onEdit }) => {
  return (
    <div>
      <button className="btn btn-pos " onClick={onEdit}>
        <div className="text-center tc-8 mb-1">{device?.posName}</div>
        <div className="text-center fs-14 tc-11 mb-2">{device?.dateCreate}</div>
        <div
          className={
            device?.posStatus === "มีการซื้อขายแล้ว"
              ? "text-center pos-status tc-8 active"
              : "text-center pos-status tc-8"
          }
        >
          {device?.posStatus}
        </div>
      </button>
    </div>
  );
};

export default POSDeviceCard;
