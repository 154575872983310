import { env } from "../../env";

export const domain = env.REACT_APP_API_URL;

export const STATUS = {
  OK: "OK",
  NO: "NO",
  TOKEN: "TOKEN",
  YES: "YES",
};

export const ENDPOINT = {
  BUSINESS_AUTH: "bo/business-auth",
  BUSINESS_ROLE: "bo/business-role",
  PERMISSION: "bo/bo-permission",
  USER_BUSINESS_GROUP: "bo/user-business-group",
  BRANCH: "bo/branch",
  BRANCH_BANK: "branch-bank-setting",
  BRANCH_CREDIT: "branch-credit-setting",
  BRANCH_DELIVERY: "branch-delivery-setting",
  BRANCH_KITCHEN: "branch-kitchen",
  BRANCH_KITCHEN_PRODUCT: "branch-kitchen-product",
  BRANCH_KITCHEN_CATEGORY: "branch-kitchen-product-sub-category",
  BRANCH_PAYMENT: "branch-payment-setting",
  BRANCH_POS: "branch-pos",
  BRANCH_PRODUCT: "branch-product",
  BRANCH_PRODUCT_LOG: "branch-product-log",
  BUSINESS: "bo/business",
  CODE_SETTING: "bo/code-setting",
  BRANCH_EMPLOYEE_ROLE: "branch-employee-role",
  BRANCH_ROLE: "branch-role",
  EMPLOYEE_DEPARTMENT: "/bo/employee-department",
  EMPLOYEE_POSITION: "/bo/employee-position",
  KITCHEN: "branch-kitchen",
  PRINTER: "branch-printer",
  PRODUCT: "bo/product",
  PRODUCT_ANNOTATION: "bo/product-annotation",
  PRODUCT_ANNOTATION_ITEM: "bo/product-annotation-item",
  PRODUCT_ANNOTATION_RELATION: "bo/product-annotation-relation",
  PRODUCT_CATEGORY: "bo/product-category",
  PRODUCT_LOG: "bo/product-log",
  PRODUCT_COUNT_UNIT: "bo/product-count-unit",
  PRODUCT_OPTION_STANDARD: "/bo/product-option",
  PRODUCT_OPTION_STANDARD_ITEM: "/bo/product-option-item",
  PRODUCT_OPTION_STANDARD_RELATION: "/bo/product-option-relation",
  PRODUCT_SALE_UNIT: "/bo/product-sale-unit",
  PRODUCT_SPECIFIC_OPTION_RELATION: "bo/product-specific-option-relation",
  PRODUCT_SPECIFIC_OPTION_RELATION_ITEM:
    "bo/product-specific-option-relation-item",
  PRODUCT_SUB_CATEGORY: "bo/product-sub-category",

  MASTER: {
    ACCOUNT_TYPE: "account-type",
    BANK: "bank",
    CODE_SETTING_DAY: "code-setting-day",
    CODE_SETTING_MONTH: "code-setting-month",
    CODE_SETTING_YEAR: "code-setting-year",
    COUNT_UNIT_TYPE: "count-unit-type",
    COUNTER_SETTING: "counter-setting-master",
    CURRENCY: "currency",
    DISTRICT: "district",
    EMPLOYEE_STATUS: "employee-status",
    EMPLOYEE_TYPE: "employee-type",
    ENTITY: "entity-type",
    ENTITY_STRUCTURE: "entity-structure",
    GENDER: "gender",
    MARITAL: "marital",
    MILITARY: "military",
    PREFIX_NAME: "prefix-name",
    PROVINCE: "province",
    RELATIONSHIP: "relationship",
    RESTAURANT: "restaurant-type",
    ROUNDING_SETTING: "rounding-setting",
    SALARY_TYPE: "salary-type",
    SHOP_PRODUCT: "shop-product-category",
    SUB_DISTRICT: "sub-district",
    TAX_BRANCH: "tax-branch",
    VAT: "vat-calculation",
    VAT_TYPE: "vat-type",
  },
};
