import { ENDPOINT } from '../../constants/server/api';
import { fetchApiData } from '../../utils/apiUtils';
import * as productStandard from '../slices/productStandard.slice';
export const createProductOption = (requestData) => async (dispatch) => {
  const result = await fetchApiData({
    method: 'post',
    endpoint: `${ENDPOINT.PRODUCT_OPTION_STANDARD}`,
    data: requestData,
    dispatch,
  });
  if (result) {
    dispatch(productStandard.setCreateResponse(result));
    return result;
  }
};
export const createProductOptionItem = (requestData) => async (dispatch) => {
  const result = await fetchApiData({
    method: 'post',
    endpoint: `${ENDPOINT.PRODUCT_OPTION_STANDARD_ITEM}`,
    data: requestData,
    dispatch,
  });
  if (result) {
    dispatch(productStandard.setCreateResponse(result));
    return result;
  }
};

export const getProductOptionById = (id) => async (dispatch) => {
  const data = await fetchApiData({
    method: 'get',
    endpoint: `${ENDPOINT.PRODUCT_OPTION_STANDARD}/${id}`,
    dispatch,
  });
  if (data) {
    dispatch(productStandard.getById(data));
    return data;
  }
};

export const getListProductOptionItemByid = (params) => async (dispatch) => {
  const data = await fetchApiData({
    method: 'get',
    endpoint: `${ENDPOINT.PRODUCT_OPTION_STANDARD_ITEM}`,
    params: params,
    dispatch,
  });
  if (data) {
    dispatch(productStandard.setListItem(data));
    return data;
  }
};

export const updateProductOption = (id, requestData) => async (dispatch) => {
  const result = await fetchApiData({
    method: 'put',
    endpoint: `${ENDPOINT.PRODUCT_OPTION_STANDARD}/${id}`,
    data: requestData,
    dispatch,
  });
  if (result) {
    return result;
  }
};

export const updateProductOptionItem =
  (id, requestData) => async (dispatch) => {
    const result = await fetchApiData({
      method: 'put',
      endpoint: `${ENDPOINT.PRODUCT_OPTION_STANDARD_ITEM}/${id}`,
      data: requestData,
      dispatch,
    });
    if (result) {
      return result;
    }
  };

export const deleteProductOption = (id) => async (dispatch) => {
  const result = await fetchApiData({
    method: 'delete',
    endpoint: `${ENDPOINT.PRODUCT_OPTION_STANDARD}/${id}`,
    dispatch,
  });
  dispatch(productStandard.deleteItem(id));
  if (result) {
    return result;
  }
};
export const deleteProductOptionItem = (id) => async (dispatch) => {
  const result = await fetchApiData({
    method: 'delete',
    endpoint: `${ENDPOINT.PRODUCT_OPTION_STANDARD_ITEM}/${id}`,
    dispatch,
  });
  dispatch(productStandard.deleteListItem(id));
  if (result) {
    return result;
  }
};

export const paginationProductOption = (params) => async (dispatch) => {
  const data = await fetchApiData({
    method: 'get',
    endpoint: `${ENDPOINT.PRODUCT_OPTION_STANDARD}/pagination/list`,
    params: {
      business_slug: params.business_slug,
      page: params.page,
      perPage: params.per_page,
      s: params.s,
      sortBy: params.sortBy,
      sortOrder: params.sortOrder,
    },
    dispatch,
  });
  if (data) {
    dispatch(productStandard.setData(data));
    return data;
  }
};

export const downloadProductOptionExcel =
  (business_slug, search) => async (dispatch) => {
    try {
      const result = await fetchApiData({
        method: 'post',
        endpoint: `${ENDPOINT.PRODUCT_OPTION_STANDARD}/excel`,
        params: {
          business_slug: business_slug,
          s: search || null,
        },
        dispatch,
        responseType: 'blob',
      });

      if (result) {
        const url = window.URL.createObjectURL(new Blob([result]));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `product-options-standard.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error('Failed to download Excel file:', error);
    }
  };
