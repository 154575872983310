import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useLayoutEffect,
  useRef,
} from "react";
import ReactDOM, { render, createPortal } from "react-dom";
import { Rnd } from "react-rnd";

const RenderInWindow = (props) => {
  const [container, setContainer] = useState(null);
  const newWindow = useRef(null);

  useEffect(() => {
    setContainer(document.createElement("div"));
  }, []);

  const close = () => {
    setContainer(null);
    props.setModal((prev) => ({ ...prev, mode: "normal", open: false }));
  };

  useEffect(() => {
    if (container) {
      newWindow.current = window.open(
        "",
        "",
        "width=600,height=400,left=200,top=200"
      );
      container.classList.add("p-4");

      if (newWindow.current) {
        newWindow.current.document.body.appendChild(container);
        newWindow.current.document.head.innerHTML =
          window.document.head.innerHTML;

        const curWindow = newWindow.current;
        curWindow.addEventListener("beforeunload", close);

        return () => {
          curWindow.close();
          curWindow.removeEventListener("beforeunload", close);
        };
      }
    }
  }, [container]);

  return container && createPortal(props.children, container);
};

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  const innerWidth = (window.innerWidth + ((window.innerWidth * 25.5) / 100));
  const innerHeight = (window.innerHeight + ((window.innerHeight * 25.5) / 100));
  useLayoutEffect(() => {
    function updateSize() {
      setSize([innerWidth, innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return size;
}

const defaultModalConfig = {
  mode: "normal",
  open: false,
  width: 800,
  height: 600,
  x: 0,
  y: 0,
  modalname: "default-modal",
  pin: false,
};

export function ModalAllder({
  modal = defaultModalConfig,
  setModal,
  hbtn = true,
  htitle = "",
  children,
  ...props
}) {
  const [WindowWidth, WindowHeight] = useWindowSize();
  const [prevModal, setPrevModal] = useState(null);
  const [windowpopShow, setWindowpopShow] = useState(false);

  const getCenterModal = useCallback(() => {
    if (modal && setModal) {
      const modalWidth = modal?.width || defaultModalConfig.width;
      const modalHeight = modal?.height || defaultModalConfig.height;

      const centerX = Math.max(0, Math.floor((WindowWidth - modalWidth) / 2));
      const centerY = Math.max(0, Math.floor((WindowHeight - modalHeight) / 2));

      setModal((prev) => ({
        ...prev,
        x: centerX,
        y: centerY,
      }));
    }
  }, [WindowWidth, WindowHeight, setModal, modal]);

  // Effect for window resize and initial position
  useEffect(() => {
    if (modal?.modalname) {
      const savedModal = localStorage.getItem(modal.modalname);
      if (savedModal) {
        setModal(JSON.parse(savedModal));
      } else {
        getCenterModal();
      }
    }
  }, [WindowWidth, WindowHeight]);

  // Effect for modal open state
  useEffect(() => {
    if (modal?.open && !modal?.pin) {
      getCenterModal();
    }
  }, [modal?.open]);

  // Effect for pinned state
  useEffect(() => {
    if (modal?.pin && modal.modalname) {
      localStorage.setItem(modal.modalname, JSON.stringify(modal));
    }
  }, [modal]);

  const setFitscreen = () => {
    setPrevModal({ ...modal });
    setModal((prev) => ({
      ...prev,
      width: WindowWidth,
      height: WindowHeight,
      mode: "fit",
      x: 0,
      y: 0,
    }));
  };

  const setPin = () => {
    if (!modal?.pin) {
      setModal((prev) => ({ ...prev, pin: true }));
      if (modal?.modalname) {
        localStorage.setItem(
          modal.modalname,
          JSON.stringify({ ...modal, pin: true })
        );
      }
    } else {
      setModal((prev) => ({ ...prev, pin: false }));
      if (modal?.modalname) {
        localStorage.removeItem(modal.modalname);
      }
    }
  };

  if (!modal || !setModal) {
    return null;
  }

  const modalStyle = {
    border: "solid 1px #ddd",
    background: "#FFF",
    zIndex: modal.open ? 1100 : "hidden",
    borderRadius: "10px",
    // overflow: "hidden",
    visibility: modal.open ? "visible" : "hidden",
    position: "fixed",
  };

  const headerStyle = {
    backgroundColor: "#2DC76D",
    height: "45px",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    padding: "10px",
    cursor: "move",
  };

  return (
    <>
      {modal.mode === "popup" ? (
        <RenderInWindow setModal={setModal} setWindowpopShow={setWindowpopShow}>
          {children}
        </RenderInWindow>
      ) : (
        <div
          className="tg-bp3-dialog-resizable-draggable"
          style={{
            top: 0,
            left: 0,
            position: "fixed",
            zIndex: 1500,
            pointerEvents: modal.open ? "auto" : "none",
          }}
        >
          <Rnd
            style={modalStyle}
            size={{
              width: modal.open ? modal.width : 0,
              height: modal.open ? modal.height : 0,
            }}
            position={{ x: modal.x, y: modal.y }}
            onResizeStop={(e, direction, ref, delta, position) => {
              setModal((prev) => ({
                ...prev,
                width: parseInt(ref.style.width),
                height: parseInt(ref.style.height),
                x: position.x,
                y: position.y,
              }));
            }}
            onDragStop={(e, d) => {
              setModal((prev) => ({ ...prev, x: d.x, y: d.y }));
            }}
            maxHeight={WindowHeight}
            maxWidth={WindowWidth - 20}
            dragHandleClassName="dragHandle"
          // bounds="window"
          >
            <div
              className="pagezoom d-flex align-items-center dragHandle"
              style={headerStyle}
            >
              <div className="text-white fs-18 me-auto pt-1">{htitle}</div>

              {hbtn && (
                <>
                  <button
                    type="button"
                    className="btn btn-modal-export mx-2"
                    onClick={() =>
                      setModal((prev) => ({ ...prev, mode: "popup" }))
                    }
                  />
                  <button
                    type="button"
                    className={`btn btn-modal-pin mx-2 ${modal.pin ? "active" : ""
                      }`}
                    onClick={setPin}
                  />
                  <button
                    type="button"
                    className="btn btn-modal-window mx-2"
                    onClick={() => {
                      if (modal.mode === "normal") {
                        setFitscreen();
                      } else {
                        setModal((prev) => ({
                          ...prev,
                          width: prevModal.width,
                          height: prevModal.height,
                          mode: "normal",
                          x: prevModal.x,
                          y: prevModal.y,
                        }));
                      }
                    }}
                  />
                </>
              )}

              <button
                type="button"
                className="btn btn-modal-close"
                onClick={() => setModal((prev) => ({ ...prev, open: false }))}
              />
            </div>

            <div
              style={{
                height: modal.open ? `${parseFloat(modal.height) - 45}px` : 0,
                overflowY: "auto",
              }}
            >
              <div className="p-4 pagezoom">{children}</div>
            </div>
          </Rnd>
        </div>
      )}
    </>
  );
}

export default ModalAllder;
