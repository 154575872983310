import * as productBranchAction from "../slices/productBranch.slice";
import { ENDPOINT, STATUS } from "../../constants/server/api";
import { fetchApiData } from "../../utils/apiUtils";

export const getBranchProducts = (params) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.BRANCH_PRODUCT}/pagination/list`,
    params: {
      branch_id: params.branch_id,
      product_category_id: params.product_category_id,
      is_already_sale: params.is_already_sale,
      page: params.page,
      perPage: params.per_page || 20,
      s: params.s,
      sortBy: params.sortBy,
      sortOrder: params.sortOrder,
    },
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const getAllBranchProducts = (branchId) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.BRANCH_PRODUCT}/all/list`,
    params: {
      branch_id: branchId,
    },
    dispatch,
  });
  return data;
};

export const getBranchProductById = (id) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.BRANCH_PRODUCT}/${id}`,
    dispatch,
  });
  dispatch(productBranchAction.setProductBranch(data));
  return data;
};

export const createBranchProduct = (branchProductData) => async (dispatch) => {
  const data = await fetchApiData({
    method: "post",
    endpoint: ENDPOINT.BRANCH_PRODUCT,
    data: {
      branch_id: branchProductData.branch_id,
      productIds: branchProductData.productIds,
    },
    dispatch,
  });
  return data;
};

export const updateBranchProduct = (id, updateData) => async (dispatch) => {
  const data = await fetchApiData({
    method: "put",
    endpoint: `${ENDPOINT.BRANCH_PRODUCT}/${id}`,
    data: {
      data: {
        is_available: updateData.is_available,
        is_visible: updateData.is_visible,
        is_excluded_from_promotions: updateData.is_excluded_from_promotions,
        is_recommended: updateData.is_recommended,
        price: updateData.price,
        price_delivery: updateData.price_delivery,
        cost: updateData.cost,
        product_vat_type: updateData.product_vat_type,
      },
    },
    dispatch,
  });
  return data;
};

export const deleteBranchProduct = (id) => async (dispatch) => {
  await fetchApiData({
    method: "delete",
    endpoint: `${ENDPOINT.BRANCH_PRODUCT}/${id}`,
    dispatch,
  });
  return STATUS.OK;
};

export const exportBranchProductsToExcel =
  (branchId, searchTerm) => async (dispatch) => {
    const data = await fetchApiData({
      method: "post",
      endpoint: `${ENDPOINT.BRANCH_PRODUCT}/excel`,
      params: {
        branch_id: branchId,
        s: searchTerm || "",
      },
      responseType: "blob",
      dispatch,
    });

    if (data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "รายการสินค้าสาขา.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }
  };

export const getBranchProductLogs = (params) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENDPOINT.BRANCH_PRODUCT_LOG}/pagination/list`,
    params: {
      branch_product_id: params.branch_product_id,
      type: params.type,
      start_date: params.start_date || "2024-01-01",
      end_date: params.end_date || "2026-01-01",
      page: params.page,
      perPage: params.per_page,
      sortBy: params.sortBy,
      sortOrder: params.sortOrder,
    },
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const exportBranchProductLogsToExcel = (params) => async (dispatch) => {
  const data = await fetchApiData({
    method: "post",
    endpoint: `${ENDPOINT.BRANCH_PRODUCT_LOG}/excel`,
    params: {
      branch_product_id: params.branch_product_id,
      type: params.type,
      start_date: params.start_date || "2024-01-01",
      end_date: params.end_date || "2026-01-01",
    },
    responseType: "blob",
    dispatch,
  });

  if (data) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "ประวัติการเปลี่ยนแปลงสินค้า.xlsx");
    document.body.appendChild(link);
    link.remove();
    window.URL.revokeObjectURL(url);
  }
};

// New APIs for specific options and annotations

export const updateSpecificOptionRelation = (id, data) => async (dispatch) => {
  const response = await fetchApiData({
    method: "put",
    endpoint: `${ENDPOINT.BRANCH_PRODUCT}/update-specific-option-relation/${id}`,
    data: {
      data: {
        product_id: data.product_id,
        product_specific_option_id: data.product_specific_option_id,
        product_specific_option_item_id: data.product_specific_option_item_id,
        price: data.price,
        is_show: data.is_show,
      },
    },
    dispatch,
  });
  return response;
};

export const updateSpecificOptionRelationToggleHidden =
  (id, data) => async (dispatch) => {
    const response = await fetchApiData({
      method: "put",
      endpoint: `${ENDPOINT.BRANCH_PRODUCT}/update-specific-option-relation-toggle-show/${id}`,
      data: {
        data: data,
      },
      dispatch,
    });
    return response;
  };

export const updateOptionRelation = (id, data) => async (dispatch) => {
  const response = await fetchApiData({
    method: "put",
    endpoint: `${ENDPOINT.BRANCH_PRODUCT}/update-option-relation/${id}`,
    data: {
      data: {
        product_id: data.product_id,
        product_option_id: data.product_option_id,
        product_option_item_id: data.product_option_item_id,
        price: data.price,
        is_show: data.is_show,
      },
    },
    dispatch,
  });
  return response;
};

export const updateOptionRelationToggleHidden =
  (id, data) => async (dispatch) => {
    const response = await fetchApiData({
      method: "put",
      endpoint: `${ENDPOINT.BRANCH_PRODUCT}/update-option-relation-toggle-show/${id}`,
      data: {
        data: data,
      },
      dispatch,
    });
    return response;
  };

export const updateIsSpecificOption =
  (id, isSpecificOption) => async (dispatch) => {
    const response = await fetchApiData({
      method: "put",
      endpoint: `${ENDPOINT.BRANCH_PRODUCT}/update-is-specific-option/${id}`,
      data: {
        data: {
          is_specific_option: isSpecificOption,
        },
      },
      dispatch,
    });
    return response;
  };

export const updateIsOption = (id, isOption) => async (dispatch) => {
  const response = await fetchApiData({
    method: "put",
    endpoint: `${ENDPOINT.BRANCH_PRODUCT}/update-is-option/${id}`,
    data: {
      data: {
        is_option: isOption,
      },
    },
    dispatch,
  });
  return response;
};

export const updateIsAnnotation = (id, isAnnotation) => async (dispatch) => {
  const response = await fetchApiData({
    method: "put",
    endpoint: `${ENDPOINT.BRANCH_PRODUCT}/update-is-annotation/${id}`,
    data: {
      data: {
        is_annotation: isAnnotation,
      },
    },
    dispatch,
  });
  return response;
};

export const updateAnnotationRelationToggleHidden =
  (id, data) => async (dispatch) => {
    const response = await fetchApiData({
      method: "put",
      endpoint: `${ENDPOINT.BRANCH_PRODUCT}/update-annotation-relation-toggle-show/${id}`,
      data: {
        data: data,
      },
      dispatch,
    });
    return response;
  };
