import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import ButtonHideOption from "../ButtonHideOption";
import ButtonShowOption from "../ButtonShowOption";
import { ProductOptionModal } from "../modals";
import { productBranchHandler } from "../../../../../redux/handlers";

export function OptionStandard() {
  const dispatch = useDispatch();
  const { productID } = useParams();
  const product = useSelector((state) => state.product.product);
  const productBranch = useSelector(
    (state) => state.productBranch.productBranch
  );

  const [isOptionEnabled, setIsOptionEnabled] = useState(false);
  const [standardOptions, setStandardOptions] = useState([]);
  const [selectedOptionDetail, setSelectedOptionDetail] = useState({
    id: null,
    optionDetail: null,
  });
  const [editModalConfig, setEditModalConfig] = useState({
    open: false,
    x: 0,
    y: 0,
    width: 500,
    height: 420,
    pin: false,
    mode: "normal",
    modalname: "modal_edit_option_product",
  });

  useEffect(() => {
    setIsOptionEnabled(productBranch?.data?.is_option ?? product?.is_option);
    if (product?.productOptionRelation) {
      const baseOptions = product.productOptionRelation.map((relation) => {
        const option = relation.productOption;
        return {
          id: option.id,
          name: option.name,
          selecttype: option.is_multiple_select,
          isVisible: option.is_display,
          product_option_detail: option.productOptionItem.map((item) => ({
            id: item.id,
            name: item.name,
            price: item.price,
            isVisible: true,
            stock: null,
          })),
        };
      });

      if (productBranch?.data?.product_option_relation) {
        const branchOptions = productBranch.data.product_option_relation;

        const updatedOptions = baseOptions.map((baseOption) => {
          const matchingBranchOptions = branchOptions.filter(
            (branchOption) => baseOption.id === branchOption.product_option_id
          );

          if (matchingBranchOptions.length > 0) {
            return {
              ...baseOption,
              product_option_detail: baseOption.product_option_detail.map(
                (detail) => {
                  const matchingBranchOption = matchingBranchOptions.find(
                    (bo) => bo.product_option_item_id === detail.id
                  );

                  if (matchingBranchOption) {
                    return {
                      ...detail,
                      price: matchingBranchOption.price,
                      isVisible: matchingBranchOption.is_show,
                    };
                  }
                  return detail;
                }
              ),
            };
          }
          return baseOption;
        });

        setStandardOptions(updatedOptions);
      } else {
        setStandardOptions(baseOptions);
      }
    }
  }, [product, productBranch]);

  const handleOptionEnableToggle = async (newStatus) => {
    try {
      await dispatch(productBranchHandler.updateIsOption(productID, newStatus));
      setIsOptionEnabled(newStatus);
    } catch (error) {
      console.error("Error updating option status:", error);
    }
  };

  const handleOptionVisibilityToggle = async (optionId, isVisible) => {
    try {
      const targetOption = standardOptions.find(
        (option) => option.id === optionId
      );
      if (!targetOption) return;

      const updatePayload = targetOption.product_option_detail.map(
        (optionDetail) => ({
          product_id: productID,
          product_option_id: optionId,
          product_option_item_id: optionDetail.id,
          price: optionDetail.price,
          is_show: !isVisible,
        })
      );

      await dispatch(
        productBranchHandler.updateOptionRelationToggleHidden(
          productID,
          updatePayload
        )
      );

      setStandardOptions((currentOptions) =>
        currentOptions.map((option) =>
          option.id === optionId
            ? {
                ...option,
                isVisible: isVisible,
                product_option_detail: option.product_option_detail.map(
                  (detail) => ({
                    ...detail,
                    isVisible: isVisible,
                  })
                ),
              }
            : option
        )
      );
    } catch (error) {
      console.error("Error updating option visibility:", error);
    }
  };

  const handleOptionItemVisibilityToggle = async (
    id,
    optionId,
    itemId,
    isVisible
  ) => {
    try {
      const targetOption = standardOptions.find(
        (option) => option.id === optionId
      );
      if (!targetOption) return;

      const targetDetail = targetOption.product_option_detail.find(
        (detail) => detail.id === itemId
      );
      if (!targetDetail) return;

      const updatePayload = {
        id: id,
        product_id: productID,
        product_option_id: optionId,
        product_option_item_id: itemId,
        price: targetDetail.price,
        is_show: !isVisible,
      };

      await dispatch(
        productBranchHandler.updateOptionRelation(productID, updatePayload)
      );

      setStandardOptions((currentOptions) =>
        currentOptions.map((option) =>
          option.id === optionId
            ? {
                ...option,
                product_option_detail: option.product_option_detail.map(
                  (detail) =>
                    detail.id === itemId
                      ? {
                          ...detail,
                          isVisible: isVisible,
                        }
                      : detail
                ),
              }
            : option
        )
      );
    } catch (error) {
      console.error("Error updating option item visibility:", error);
    }
  };

  const handleOptionDetailUpdate = async (updatedOptionData) => {
    try {
      const updatePayload = {
        product_id: productID,
        product_option_id: updatedOptionData.id,
        product_option_item_id: updatedOptionData.optionDetail.id,
        price: Number(updatedOptionData.optionDetail.price),
        is_show: !updatedOptionData.optionDetail.isVisible,
      };

      await dispatch(
        productBranchHandler.updateOptionRelation(productID, updatePayload)
      );

      setStandardOptions((currentOptions) =>
        currentOptions.map((option) =>
          option.id === updatedOptionData.id
            ? {
                ...option,
                product_option_detail: option.product_option_detail.map(
                  (detail) =>
                    detail.id === updatedOptionData.optionDetail.id
                      ? {
                          ...detail,
                          price: updatedOptionData.optionDetail.price,
                          name: updatedOptionData.optionDetail.name,
                          isVisible: updatedOptionData.optionDetail.isVisible,
                        }
                      : detail
                ),
              }
            : option
        )
      );

      setEditModalConfig((currentConfig) => ({
        ...currentConfig,
        open: false,
      }));
    } catch (error) {
      console.error("Error updating option details:", error);
    }
  };

  return (
    <div className="card card-st-3 mb-3">
      <div className="card-body px-xl-3">
        <div className="pt-2 pb-1 px-3">
          <Form.Check
            type="switch"
            id="custom-sd"
            label="กำหนดตัวเลือกมาตรฐาน "
            name="optionstandardStatus"
            className="custom-switch-st-1 s1 mb-1"
            checked={isOptionEnabled}
            onChange={() => handleOptionEnableToggle(!isOptionEnabled)}
          />
          <span className="ms-2" style={{ color: "#858796" }}>
            ( ใช้ข้อมูลจากตัวเลือกมาตรฐานที่สร้างไว้ )
          </span>
        </div>

        {isOptionEnabled && (
          <div className="px-5 py-4">
            <div className="ms-xl-4 mt-2 ps-2">
              {standardOptions?.map((optionGroup, groupIndex) => (
                <div
                  className="row gx-4 align-items-center mb-5"
                  key={groupIndex}
                >
                  <div className="col-xl-6 col-6">
                    <Form.Group className="mb-2 form-st-3 pb-1">
                      <Form.Label className="mb-1">ตัวเลือกมาตราฐาน</Form.Label>
                      <Form.Control
                        type="text"
                        name="note"
                        className="form-control"
                        placeholder="ตัวเลือกมาตราฐาน"
                        value={optionGroup.name}
                        disabled={true}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xl-4 col-6">
                    <Form.Check
                      type="switch"
                      id={`custom-switch-stardard${groupIndex}`}
                      label="เลือกได้มากกว่า 1 รายการ"
                      name={`selecttype[${groupIndex}]`}
                      className="custom-switch-st-1 tc-10 s1 mb-1"
                      checked={optionGroup.selecttype}
                      disabled
                    />
                  </div>
                  <div className="col-xl-2 col-6">
                    {optionGroup.isVisible ? (
                      <ButtonShowOption
                        handleClick={() =>
                          handleOptionVisibilityToggle(optionGroup.id, false)
                        }
                        label={true}
                      />
                    ) : (
                      <ButtonHideOption
                        handleClick={() =>
                          handleOptionVisibilityToggle(optionGroup.id, true)
                        }
                        label={true}
                      />
                    )}
                  </div>

                  {optionGroup.product_option_detail?.map(
                    (optionItem, itemIndex) => (
                      <div
                        className="col-xl-6 row gx-4 align-items-center"
                        key={itemIndex}
                      >
                        <div className="col">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">รายการ</Form.Label>
                            <Form.Control
                              type="text"
                              name="listName"
                              className="form-control"
                              placeholder="รายการ"
                              value={optionItem.name}
                              disabled={true}
                            />
                          </Form.Group>
                        </div>
                        <div className="col">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">ราคา</Form.Label>
                            <Form.Control
                              type="text"
                              name="note"
                              className="form-control"
                              placeholder="ราคา"
                              value={optionItem.price}
                              disabled={true}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-auto">
                          <div
                            className="d-flex align-items-center"
                            style={{ width: "180px" }}
                          >
                            <button
                              type="button"
                              className="btn d-block btn-edit-st-1 mr-2"
                              onClick={() => {
                                setSelectedOptionDetail({
                                  id: optionGroup.id,
                                  optionDetail: optionItem,
                                });
                                setEditModalConfig((currentConfig) => ({
                                  ...currentConfig,
                                  open: true,
                                }));
                              }}
                            />
                            {optionItem.isVisible ? (
                              <ButtonShowOption
                                handleClick={() =>
                                  handleOptionItemVisibilityToggle(
                                    optionGroup.id,
                                    optionItem.id,
                                    false
                                  )
                                }
                                label={false}
                              />
                            ) : (
                              <ButtonHideOption
                                handleClick={() =>
                                  handleOptionItemVisibilityToggle(
                                    optionGroup.id,
                                    optionItem.id,
                                    true
                                  )
                                }
                                label={false}
                              />
                            )}
                            <button
                              type="button"
                              className="btn btn-setting-st-2 ml-2"
                              onClick={() => {}}
                            />
                            <span
                              className="fs-14 ml-2"
                              style={{
                                color: optionItem.stock ? "#2DC76D" : "#B6BBC6",
                                lineHeight: "24px",
                              }}
                            >
                              {optionItem.stock
                                ? "ตั้งค่าแล้ว"
                                : "ยังไม่ได้ตั้งค่า"}
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <ProductOptionModal
        modal={editModalConfig}
        setModal={setEditModalConfig}
        setListOptionstandard={setStandardOptions}
        productOptionDetail={selectedOptionDetail}
        setProductOptionDetail={setSelectedOptionDetail}
        productId={productID}
        onSubmit={handleOptionDetailUpdate}
      />
    </div>
  );
}

export default OptionStandard;
