import { userBusinessGroupHandler } from "../redux/handlers";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

export function NotFound() {
  const dispatch = useDispatch();
  const getSlug = () => {
    const urlParts = window.location.href.split("/").filter(Boolean);
    return urlParts[2];
  };
  const slug = getSlug();
  const token = localStorage.getItem("accessToken");
  const [isShowGoAllderCafe, setIsShowGoAllderCafe] = useState(false);
  const [isShowGoLoginPage, setIsShowGoLoginPage] = useState(false);
  const [isShowGoBackPage, setIsShowGoBackPage] = useState(false);

  useEffect(() => {
    checkBusinessGuardAccess();
  }, []);
  const checkBusinessGuardAccess = async () => {
    let response = null;
    response = await dispatch(
      userBusinessGroupHandler.checkBusinessGuard(slug)
    );

    if (!response?.business) {
      setIsShowGoAllderCafe(true);
      return;
    } else if (!token && response.business) {
      setIsShowGoLoginPage(true);
    } else {
      setIsShowGoBackPage(true);
    }
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center min-vh-100 bg-light">
      <div className="text-center py-5 px-28 bg-white rounded shadow">
        <h1 className="display-1 fw-bold text-primary mb-4">404</h1>
        <h2 className="h3 mb-4">Page Not Found</h2>
        <p className="text-muted mb-4">ขออภัย ไม่พบหน้าที่คุณต้องการ</p>
        {isShowGoAllderCafe && (
          <Link
            to={`https://alldercafe.com`}
            className="btn btn-primary px-4 py-2"
          >
            กลับสู่หน้าแรก
          </Link>
        )}
        {isShowGoLoginPage && (
          <Link to={`/${slug}/login`} className="btn btn-primary px-4 py-2">
            เข้าสู่ระบบ
          </Link>
        )}
        {isShowGoBackPage && (
          <Link to={`/${slug}`} className="btn btn-primary px-4 py-2">
            กลับสู่หน้าหลัก
          </Link>
        )}
      </div>
    </div>
  );
}
