import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useGlobalTable } from "../../../hooks/useGlobalTable";
import { createTableColumns } from "../../../utils/createTableColumns";
import { AllderTable } from "../../../components/AllderTable";
import {
  SubCategoryModal,
  ConfirmDeleteSubCATModal,
  SuccessModal,
} from "./components/modals";
import { Category } from "./Category";
import { productCategoryHandler } from "../../../redux/handlers";

const INITIAL_SELECTED_SUBCATEGORY = {
  id: "",
  code: "",
  name: "",
  productCategoryId: "",
  categoryName: "",
  product: [],
  is_display: true,
  is_active: true,
};

const INITIAL_MODAL_STATE = {
  open: false,
  x: 0,
  y: 0,
  width: 470,
  pin: false,
  mode: "normal",
};

const MODAL_HEIGHTS = {
  FORM: 600,
  CONFIRM: 320,
  SUCCESS: 300,
};

const MODAL_STATES = {
  CREATE: { ...INITIAL_MODAL_STATE, height: MODAL_HEIGHTS.FORM },
  EDIT: { ...INITIAL_MODAL_STATE, height: MODAL_HEIGHTS.FORM },
  CONFIRM: { ...INITIAL_MODAL_STATE, height: MODAL_HEIGHTS.CONFIRM },
  SUCCESS: { ...INITIAL_MODAL_STATE, height: MODAL_HEIGHTS.SUCCESS },
};

export function SubCategoryManagement() {
  const dispatch = useDispatch();
  const { businessSlug } = useParams();
  const [categories, setCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    INITIAL_SELECTED_SUBCATEGORY
  );
  const [successMsg, setSuccessMsg] = useState("");
  const [modalCreate, setModalCreate] = useState(MODAL_STATES.CREATE);
  const [modalEdit, setModalEdit] = useState(MODAL_STATES.EDIT);
  const [modalConfirmDelete, setModalConfirmDelete] = useState(
    MODAL_STATES.CONFIRM
  );
  const [modalSuccess, setModalSuccess] = useState(MODAL_STATES.SUCCESS);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await dispatch(
          productCategoryHandler.getAllProductCategories(businessSlug)
        );
        if (response) {
          setCategories(response);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [businessSlug, dispatch]);

  const fetchSubCategories = useCallback(
    (params) =>
      dispatch(
        productCategoryHandler.getProductSubCategories({
          business_slug: businessSlug,
          ...params,
        })
      ),
    [businessSlug, dispatch]
  );

  const {
    data: subCategories,
    loading,
    totalRows,
    paginationModel,
    setPaginationModel,
    handleSort,
    debouncedSearch,
    refreshData: refreshSubCategories,
    filterParams,
  } = useGlobalTable({
    fetchData: fetchSubCategories,
    defaultSort: "created_at",
    defaultOrder: "desc",
    defaultPageSize: 20,
  });

  const handleCreateSubCategory = async (values) => {
    try {
      await dispatch(
        productCategoryHandler.createProductSubCategory({
          businessSlug,
          productCategoryId: values.productCategoryId,
          code: values.subcategoryCode,
          name: values.subcategoryName,
          isDisplay: values.isDisplay,
          date: new Date().toISOString(),
        })
      );
      refreshSubCategories();
      setSuccessMsg("บันทึกหมวดหมู่ย่อยสำเร็จ");
      setModalCreate((prev) => ({ ...prev, open: false }));
      setModalSuccess((prev) => ({ ...prev, open: true }));
      setSelectedSubCategory(INITIAL_SELECTED_SUBCATEGORY);
    } catch (error) {
      console.error("Error creating subcategory:", error);
    }
  };

  const handleUpdateSubCategory = async (values) => {
    try {
      await dispatch(
        productCategoryHandler.updateProductSubCategory(values.id, {
          businessSlug,
          productCategoryId: values.productCategoryId,
          code: values.subcategoryCode,
          name: values.subcategoryName,
          isDisplay: values.isDisplay,
          date: new Date().toISOString(),
        })
      );
      refreshSubCategories();
      setSuccessMsg("แก้ไขหมวดหมู่ย่อยสำเร็จ");
      setModalEdit((prev) => ({ ...prev, open: false }));
      setModalSuccess((prev) => ({ ...prev, open: true }));
    } catch (error) {
      console.error("Error updating subcategory:", error);
    }
  };

  const handleDeleteSubCategory = async () => {
    try {
      await dispatch(
        productCategoryHandler.deleteProductSubCategory(selectedSubCategory.id)
      );
      refreshSubCategories();
      setSuccessMsg("ลบหมวดหมู่ย่อยสำเร็จ");
      setModalConfirmDelete((prev) => ({ ...prev, open: false }));
      setModalSuccess((prev) => ({ ...prev, open: true }));
      setSelectedSubCategory(INITIAL_SELECTED_SUBCATEGORY);
    } catch (error) {
      console.error("Error deleting subcategory:", error);
    }
  };

  const handleExport = async () => {
    try {
      await dispatch(
        productCategoryHandler.exportProductSubCategoriesToExcel(
          businessSlug,
          filterParams.s
        )
      );
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const handleEditClick = async (row) => {
    try {
      const response = await dispatch(
        productCategoryHandler.getProductSubCategoryById(row.id)
      );
      if (response) {
        setSelectedSubCategory({
          id: response.id,
          code: response.code,
          name: response.name,
          is_display: response.is_display,
          created_at: response.created_at,
          userBusinessGroup: response.userBusinessGroup,
          productCategory: row.productCategory || {},
          product: row.product || [],
        });
        setModalEdit((prev) => ({ ...prev, open: true }));
      }
    } catch (error) {
      console.error("Error fetching subcategory details:", error);
    }
  };

  const columns = createTableColumns([
    {
      field: "code",
      headerName: "รหัสหมวดหมู่ย่อย",
      width: 170,
      sortable: true,
      renderCell: ({ row }) => (
        <div
          style={{
            color: "#2DC76D",
            fontWeight: 500,
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={() => handleEditClick(row)}
        >
          {row.code}
        </div>
      ),
    },
    {
      field: "name",
      headerName: "ชื่อหมวดหมู่ย่อย",
      flex: 1,
      width: 140,
      sortable: true,
      renderCell: ({ row }) => (
        <div style={{ color: "#2DC76D", fontWeight: 500 }}>{row.name}</div>
      ),
    },
    {
      field: "productCategory",
      headerName: "ชื่อหมวดหมู่หลัก",
      width: 180,
      sortable: true,
      renderCell: ({ row }) => <div>{row.productCategory?.name || ""}</div>,
    },
    {
      field: "product",
      headerName: "จำนวนรายการ",
      width: 180,
      sortable: false,
      renderCell: ({ row }) => <div>{row.product?.length || 0}</div>,
    },
    {
      field: "actions",
      headerName: "จัดการ",
      width: 120,
      sortable: false,
      renderCell: ({ row }) => (
        <div>
          <Button
            type="button"
            className="btn-edit-st-1"
            onClick={() => handleEditClick(row)}
          />
        </div>
      ),
    },
  ]);

  return (
    <div>
      <Category />
      <div className="card card-st-2">
        <div className="card-body px-0 py-4">
          <div className="text-lg font-bold mb-4 lh-1 px-4 mb-4 text-[#2DC76D]">
            หมวดหมู่ย่อย
          </div>
          <div className="d-flex align-items-center px-4 mb-4">
            <button
              className="btn btn-export-st-1 me-auto"
              onClick={handleExport}
            >
              Export
            </button>
            <div className="me-3">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหาชื่อหมวดหมู่ย่อย"
                onChange={(e) => debouncedSearch(e.target.value)}
              />
            </div>
            <Button
              type="button"
              className="btn btn-st-7"
              onClick={() =>
                setModalCreate((prev) => ({ ...prev, open: true }))
              }
            >
              เพิ่มหมวดหมู่ย่อย
            </Button>
          </div>

          <AllderTable
            data={subCategories}
            loading={loading}
            columns={columns}
            totalRows={totalRows}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            handleSort={handleSort}
          />
        </div>
      </div>

      <SubCategoryModal
        modal={modalCreate}
        setModal={setModalCreate}
        onHide={() => setModalCreate((prev) => ({ ...prev, open: false }))}
        onSubmit={handleCreateSubCategory}
        mode="create"
        categoryOptions={categories.map((cat) => ({
          value: cat.id,
          label: cat.code,
        }))}
      />

      <SubCategoryModal
        modal={modalEdit}
        setModal={setModalEdit}
        initialData={selectedSubCategory}
        onHide={() => setModalEdit((prev) => ({ ...prev, open: false }))}
        onSubmit={handleUpdateSubCategory}
        onDelete={() =>
          setModalConfirmDelete((prev) => ({ ...prev, open: true }))
        }
        mode="edit"
        categoryOptions={categories.map((cat) => ({
          value: cat.id,
          label: cat.code,
        }))}
      />

      <ConfirmDeleteSubCATModal
        modal={modalConfirmDelete}
        setModal={setModalConfirmDelete}
        categoryData={selectedSubCategory}
        onHide={() =>
          setModalConfirmDelete((prev) => ({ ...prev, open: false }))
        }
        onConfirm={handleDeleteSubCategory}
      />

      <SuccessModal
        modal={modalSuccess}
        setModal={setModalSuccess}
        title="จัดการหมวดหมู่ย่อย"
        msg={successMsg}
        onHide={() => setModalSuccess((prev) => ({ ...prev, open: false }))}
      />
    </div>
  );
}
